import { CrownIcon } from '../../../../../media/icons';
import { getUserActivationsRoute } from '../../../../user/entry';
import HeaderOption from '../../option';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PrivilegeOption = props => {
  return _jsx(HeaderOption, {
    icon: CrownIcon,
    to: getUserActivationsRoute({}),
    ...props
  });
};
export default PrivilegeOption;