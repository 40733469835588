import { Typography } from '@mui/material';
import { EOrderServicesTag } from 'data/api';
import { useNsiBookingOrderStatusesQuery, useNsiGeneralOrderStatusesQuery } from 'data/api/nsi';
import { useGetUserOrdersCountQuery, useGetUserOrdersQuery } from 'data/api/user';
import ContentLoader from 'presentation/components/common/loader';
import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import useRtkQueryCachedPageableData from 'presentation/features/general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { EPaginationBehaviour } from 'presentation/features/general/pageable/types';
import usePageableList from 'presentation/features/general/pageable/usePageableList';
import OrderStub from 'presentation/features/order/components/stub';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import NoAnyOrderStub from '../../../order/components/noAnyOrderStub';
import UserOrdersTabs from '../../components/ordersTabs';
import { getUserOrderRoute, getUserOrdersRoute } from '../../entry';
import UserOrders from './component';
import UserOrdersLayout from './layout';
import { getUserOrdersStatus } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const pageSize = 10;
const Layout = UserOrdersLayout;
const UserOrdersContainer = props => {
  const {
    guid,
    tab
  } = props;
  const history = useHistory();
  const {
    data: generalOrderStatuses,
    isFetching: isGeneralOrderStatusesFetching
  } = useNsiGeneralOrderStatusesQuery();
  const {
    data: bookingOrderStatuses,
    isFetching: isBookingOrderStatusesFetching
  } = useNsiBookingOrderStatusesQuery();
  const {
    data: ordersCount,
    isFetching: isOrdersCountFetching
  } = useGetUserOrdersCountQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const statuses = useMemo(() => getUserOrdersStatus(tab), [tab]);
  const argsStorage = usePageableArgsInRedux({
    guid,
    defaultState: {
      guid,
      statuses,
      page: 1,
      pageSize
    }
  });
  const {
    data: orders,
    isFetching: isOrdersFetching,
    isPreparing,
    loadMore
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    cacheProvider: useRtkQueryCachedPageableData({
      guid,
      tag: EOrderServicesTag.Orders,
      payload: argsStorage.currentState
    }),
    argsStorage,
    useQuery: useGetUserOrdersQuery
  });
  const onOpenUserRoute = route => {
    history.replace(route);
  };
  const onChangeTab = newTab => {
    onOpenUserRoute(getUserOrdersRoute({
      guid: uuidv4(),
      tab: newTab
    }));
  };
  const onOrderClick = id => {
    history.push(getUserOrderRoute({
      id
    }));
  };
  const noAnyOrders = !isOrdersCountFetching && ordersCount === 0;
  const isFetching = isGeneralOrderStatusesFetching || isBookingOrderStatusesFetching || isOrdersCountFetching || isOrdersFetching;
  const noData = !isFetching && orders && orders.length === 0;
  if (isOrdersCountFetching && ordersCount === undefined) {
    return _jsx(Layout, {
      loader: _jsx(ContentLoader, {})
    });
  }
  if (noAnyOrders) {
    return _jsx(Layout, {
      header: _jsx(Typography, {
        textAlign: "center",
        variant: "h1",
        children: "\u0417\u0430\u043A\u0430\u0437\u044B"
      }),
      stub: _jsx(NoAnyOrderStub, {})
    });
  }
  return _jsx(Layout, {
    header: "\u0417\u0430\u043A\u0430\u0437\u044B",
    toolbar: _jsx(UserOrdersTabs, {
      tab: tab,
      onChangeTab: onChangeTab
    }),
    loader: isFetching && !orders && _jsx(ContentLoader, {}),
    stub: noData && _jsx(OrderStub, {}),
    children: orders && generalOrderStatuses && bookingOrderStatuses && _jsx(UserOrders, {
      orders: orders,
      generalOrderStatuses: generalOrderStatuses,
      bookingOrderStatuses: bookingOrderStatuses,
      isFetching: isFetching || isPreparing,
      onLoadNextPage: loadMore,
      onOrderClick: onOrderClick
    })
  });
};
export default UserOrdersContainer;