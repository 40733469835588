import { Typography } from '@mui/material';
import ClaimMessage from 'presentation/features/offer/components/claim/message';
import { getOfferActivationErrorText } from '../../../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimBusinessErrorMessage = _ref => {
  let {
    error
  } = _ref;
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: getOfferActivationErrorText(error)
    })
  });
};