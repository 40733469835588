import BookingOfferScreen from 'presentation/screen/bookingOffer';
import BookingOfferOrder from 'presentation/screen/bookingOfferOrder';
import BookingOffersScreen from 'presentation/screen/bookingOffers';
import BookingOffersSearchResultsScreen from 'presentation/screen/bookingOffersSearch';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routing } from './routes';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.list,
      component: BookingOffersScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.search,
      component: BookingOffersSearchResultsScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: BookingOfferScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.order,
      component: BookingOfferOrder
    }), _jsx(Redirect, {
      to: routing.list
    })]
  });
};
export default BookingOfferEntry;