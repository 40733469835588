import BannerBadge from 'presentation/components/common/badges/banner';
import { SeeAndSunIcon } from 'presentation/media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferBadge = _ref => {
  let {
    children
  } = _ref;
  return _jsx(BannerBadge, {
    icon: SeeAndSunIcon,
    children: children
  });
};
export default BookingOfferBadge;