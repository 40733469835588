import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { HeaderUserAnonymous } from 'presentation/features/header/components/user/anonymous';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import HeaderMenu from '../components/menu';
import { HeaderUserShort } from '../components/user';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const HeaderUserInfoAdapter = () => {
  const location = useLocation();
  const {
    login
  } = useAuth();
  const {
    user
  } = useAuthUser();
  const handlers = useContextHandlers();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const onOpenMenu = useCallback(event => {
    setMenuAnchorEl(event?.currentTarget ?? document.body);
  }, []);
  const onCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);
  const onLocationClick = useCallback(() => {
    onCloseMenu();
    handlers.showChangeLocationDialog();
  }, [handlers, onCloseMenu]);
  useEffect(() => {
    onCloseMenu();
  }, [location, onCloseMenu]);
  return useMemo(() => user ? _jsxs(_Fragment, {
    children: [_jsx(HeaderUserShort, {
      user: user,
      onClick: onOpenMenu
    }), _jsx(HeaderMenu, {
      anchorEl: menuAnchorEl,
      user: user,
      open: !!menuAnchorEl,
      onOpen: onOpenMenu,
      onClose: onCloseMenu,
      onLocationClick: onLocationClick
    })]
  }) : _jsx(HeaderUserAnonymous, {
    onClick: login
  }), [user, onOpenMenu, menuAnchorEl, onCloseMenu, onLocationClick, login]);
};
export default HeaderUserInfoAdapter;