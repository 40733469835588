import { useGetTradeOfferCategoriesQuery, useGetTradeOfferCategoriesUsedQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tradeOfferListCategoryIdSelector } from '../store/selectors';
const useCategoriesData = () => {
  const categoryId = useSelector(tradeOfferListCategoryIdSelector);
  const {
    data: categories,
    isFetching: isCategoriesFetching,
    error: categoriesFetchError
  } = useGetTradeOfferCategoriesQuery({});
  const {
    data: categoriesUsedData,
    error: categoriesUsedFetchError,
    isFetching: isCategoriesUsedFetching
  } = useGetTradeOfferCategoriesUsedQuery({
    //запрашиваем листовые только если находимся внутри категории
    onlyLeafCategories: !!categoryId
  });
  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
    }
    if (categoriesUsedFetchError) {
      ErrorHandler.handleHttpError(categoriesUsedFetchError);
    }
  }, [categoriesFetchError, categoriesUsedFetchError]);
  const isFetching = isCategoriesFetching || isCategoriesUsedFetching;
  let subCategories = null;

  //если категория указана, то ищем из набора используемых
  if (categoryId) {
    subCategories = categoriesUsedData?.length ? categoriesUsedData.filter(category => category.parentId === categoryId) : null;
  } else {
    //иначе берём все рутовые
    subCategories = categoriesUsedData?.length ? categoriesUsedData.filter(category => !category.parentId).sort((c1, c2) => c1.name.localeCompare(c2.name)) : null;
  }
  return {
    categories,
    subCategories,
    isCategoriesFetching: isFetching,
    categoriesFetchError
  };
};
export default useCategoriesData;