import { useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AppSvgIcon from '../../../../components/common/icon';
import ContentLoader from '../../../../components/common/loader';
import { MPFab } from '../../../../theme/ui-kit/button';
import OptionBadge from '../badge';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Option = _ref => {
  let {
    to,
    active,
    icon,
    notificationsCount,
    isLoading
  } = _ref;
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  return _jsx(OptionBadge, {
    badgeContent: notificationsCount,
    showZero: false,
    children: _jsxs(MPFab, {
      color: "secondaryLight",
      size: "medium",
      variant: "squared",
      component: RouterLink,
      to: to,
      disabled: isLoading,
      children: [_jsx(AppSvgIcon, {
        icon: icon,
        fontSize: isLgDown ? 'large' : 'medium',
        color: active ? 'primary' : 'inherit'
      }), isLoading && _jsx(ContentLoader, {
        position: "absolute"
      })]
    })
  });
};
export default Option;