import { Typography } from '@mui/material';
import { EDateTimeFormat } from 'domain/model/formats';
import { utcToLocalTimeZone } from 'presentation/utils/date';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemLabel = _ref => {
  let {
    codeTypeName,
    appointmentDate
  } = _ref;
  return _jsxs(Typography, {
    variant: "body2",
    children: [codeTypeName, ' ', appointmentDate && `от ${utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}`]
  });
};
export default ActivationListItemLabel;