import { Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from '../../components/common/splitter';
import AboutTextSimpleComponent from '../../features/about/textSimple/component';
import BackgroundContainer from '../backgroundContainer';
import { EParentGridAreas } from '../bookingDetail/types';
import { FitContainer, ParentLayoutGridArea } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AboutTextSimpleLayout = _ref => {
  let {
    title,
    close,
    children
  } = _ref;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return _jsx(FitContainer, {
    disableGutters: isMobile,
    children: _jsx(ParentLayoutGridArea, {
      area: EParentGridAreas.Inner,
      children: _jsx(BackgroundContainer, {
        close: close,
        variant: "main",
        children: _jsxs(MPGrid, {
          container: true,
          item: true,
          direction: "column",
          sm: 8,
          children: [title && _jsxs(MPGrid, {
            item: true,
            children: [_jsx(Typography, {
              variant: "h1",
              children: title
            }), _jsx(Splitter, {
              size: isMobile ? 2 : 5
            })]
          }), children && _jsx(MPGrid, {
            item: true,
            children: _jsx(AboutTextSimpleComponent, {
              children: children
            })
          })]
        })
      })
    })
  });
};