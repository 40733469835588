import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferClaimPendingWaitingMessage = () => {
  return _jsx(ClaimMessage, {
    type: "waiting",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u0413\u043E\u0442\u043E\u0432\u0438\u043C \u0432\u0430\u0448 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442"
    })
  });
};