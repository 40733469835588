import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../../../media/icons';
import AppSvgIcon from '../icon';
import { CollapseText, Content, IconWrapper, Toggle } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const ExpandableText = props => {
  const {
    children,
    expanded = false,
    ellipsis = true,
    lines = 2,
    more,
    less,
    timeout = 300,
    variant = 'body1',
    withOutButton = false,
    onExpand,
    ...other
  } = props;
  const refContent = useRef(null);
  const [isExpandable, setIsExpandable] = useState(false);
  const {
    typography
  } = useTheme();
  const lineHeight = +String(typography[variant].lineHeight || typography.body1.lineHeight).replace(/\D/g, '');
  const minHeight = lines * lineHeight;
  const MoreComponent = more ? more : 'посмотреть полностью';
  const LessComponent = less ? less : 'скрыть';
  const handleToggleExpanded = () => {
    onExpand?.();
  };

  // Инициализируем высоту блока при смене
  useEffect(() => {
    if (refContent.current !== null) {
      refContent.current.style.display = 'block';
      setIsExpandable(refContent.current.getBoundingClientRect().height > minHeight);
    }
  }, [lines, minHeight, refContent]);

  // Переключаем ellipsis
  useEffect(() => {
    if (!expanded && ellipsis) {
      setTimeout(() => {
        if (refContent.current !== null) {
          refContent.current.style.display = '-webkit-box';
        }
      }, timeout);
    } else {
      if (refContent.current !== null) {
        refContent.current.style.display = 'block';
      }
    }
  }, [ellipsis, expanded, timeout]);
  return _jsxs(_Fragment, {
    children: [_jsx(CollapseText, {
      in: expanded,
      collapsedSize: minHeight,
      timeout: timeout,
      onClick: () => {
        if (withOutButton) {
          handleToggleExpanded();
        }
      },
      children: _jsx(Content, {
        ref: refContent,
        lines: lines,
        expanded: expanded,
        variant: variant,
        ...other,
        children: children
      })
    }), isExpandable && !withOutButton && _jsxs(Toggle, {
      onClick: handleToggleExpanded,
      color: 'secondary',
      children: [_jsx(IconWrapper, {
        expanded: expanded,
        children: _jsx(AppSvgIcon, {
          icon: ArrowDownIcon,
          color: 'secondary',
          fontSize: 'small'
        })
      }), expanded ? LessComponent : MoreComponent]
    })]
  });
};