import { useMediaQuery } from '@mui/material';
import { EBaseOfferType, ECmsBannerLinkObjectType, ECmsCollectionLinkObjectType } from 'domain/model/enums';
import AppImage from 'presentation/components/common/images/common';
import { useMemo } from 'react';
import { BannerIconBackground, CorpOfferIcon, TradeOfferIcon } from '../../../../media/icons';
import { ImageWrapper, LogoBackground, LogoIconWrapper, LogoWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CmsComponentBanner = _ref => {
  let {
    banner,
    objectType,
    showIcon,
    onClick,
    Caption
  } = _ref;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const Logo = useMemo(() => {
    /**
     * определяется первично по типу линка в баннере
     * если по нему не удалось определить, то используем objectType
     */

    let result = null;
    if (showIcon) {
      switch (banner.linkObjectType) {
        case ECmsBannerLinkObjectType.TradeOffer:
        case ECmsBannerLinkObjectType.TradeOfferCategory:
          result = TradeOfferIcon;
          break;
        case ECmsBannerLinkObjectType.CorpOffer:
        case ECmsBannerLinkObjectType.CorpOfferCategory:
          result = CorpOfferIcon;
          break;
        default:
          result = null;
      }
      if (!result) {
        switch (objectType) {
          case EBaseOfferType.TradeOffer:
          case ECmsCollectionLinkObjectType.TradeOffer:
            result = TradeOfferIcon;
            break;
          case EBaseOfferType.CorpOffer:
          case ECmsCollectionLinkObjectType.CorpOffer:
            result = CorpOfferIcon;
            break;
          default:
            result = null;
        }
      }
    }
    return result;
  }, [banner.linkObjectType, objectType, showIcon]);
  return _jsxs(Wrapper, {
    height: banner.height,
    onClick: onClick,
    children: [_jsx(ImageWrapper, {
      children: banner.desktopImage && _jsx(AppImage, {
        src: isSmDown ? banner.mobileImage : banner.desktopImage
      })
    }), Caption, Logo && _jsxs(LogoWrapper, {
      children: [_jsx(LogoBackground, {
        children: _jsx(BannerIconBackground, {})
      }), _jsx(LogoIconWrapper, {
        children: _jsx(Logo, {
          fontSize: isMdDown ? 'micro' : 'medium'
        })
      })]
    })]
  });
};
export default CmsComponentBanner;