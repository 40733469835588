import useFavoritesCounter from 'presentation/features/favorite/counter/useCounter';
import FavoritesOption from '../../components/options/favorites/favorites';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FavoritesOptionAdapter = () => {
  const {
    count,
    isLoading
  } = useFavoritesCounter();
  return _jsx(FavoritesOption, {
    notificationsCount: count,
    isLoading: isLoading
  });
};
export default FavoritesOptionAdapter;