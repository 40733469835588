import CartDetailsScreen from 'presentation/screen/cart';
import { LastOrdersScreen } from 'presentation/screen/cart/lastOrders';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.cart;
const routing = {
  root,
  lastOrders: `${root}/last-orders`
};
export const getCartRootRoute = () => routing.root;
export const getLastOrderRoute = () => routing.lastOrders;
const CartEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.root,
      component: CartDetailsScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.lastOrders,
      component: LastOrdersScreen
    }), _jsx(Redirect, {
      to: routing.root
    })]
  });
};
export default CartEntry;