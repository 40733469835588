import { Typography } from '@mui/material';
import PromotionTypeText from 'presentation/features/offer/components/claim/obtainingMethod';
import { EDateTimeFormat } from '../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../utils/date';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TradeOfferClaimPromotionDateActivateNow = () => {
  return _jsx(Typography, {
    variant: "body2",
    color: "textSuccess",
    children: "\u043F\u043E\u043B\u0443\u0447\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u0447\u0442\u043E"
  });
};
const ClaimPromotionDate = props => {
  const {
    appointmentDate
  } = props;
  return _jsxs(Typography, {
    variant: "body2",
    children: ["\u043E\u0442 ", utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)]
  });
};
const TradeOfferPromotionTypeAdapter = () => {
  const {
    tradeOffer,
    isActivationReceivedNow,
    activation,
    isReceiptDateSupported
  } = useTradeOfferClaimContext();
  if (!tradeOffer) {
    return null;
  }
  return _jsxs(Wrapper, {
    children: [_jsx(PromotionTypeText, {
      promotionType: tradeOffer.promotionType
    }), isReceiptDateSupported && isActivationReceivedNow && _jsx(TradeOfferClaimPromotionDateActivateNow, {}), isReceiptDateSupported && !isActivationReceivedNow && activation.lastActivation?.appointmentDate && _jsx(ClaimPromotionDate, {
      appointmentDate: activation.lastActivation.appointmentDate
    })]
  });
};
export default TradeOfferPromotionTypeAdapter;