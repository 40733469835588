import usePageableArgsInRedux from '../../../../general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import { useGetTransactionsQuery } from 'api/user';
import usePageableList from '../../../../general/pageable/usePageableList';
import { EPaginationBehaviour } from '../../../../general/pageable/types';
import { useCallback } from 'react';
import UserFinancesBonusesContainer from '../bonuses';
import useRtkQueryCachedPageableData from '../../../../general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { EUserServicesTag } from 'data/api';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const pageSize = 10;
export const UserFinanceHistoryBonusesAdapter = props => {
  const {
    guid
  } = props;
  const argsStorage = usePageableArgsInRedux({
    guid,
    defaultState: {
      page: 1,
      pageSize
    }
  });
  const {
    data,
    loadMore,
    isEmpty,
    isFetching,
    totalCount
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    useQuery: useGetTransactionsQuery,
    argsStorage,
    queryOptions: {
      refetchOnMountOrArgChange: true
    },
    cacheProvider: useRtkQueryCachedPageableData({
      guid,
      tag: EUserServicesTag.BTTransactions,
      payload: argsStorage.currentState
    })
  });
  const onLoadMore = useCallback(() => {
    loadMore?.();
  }, [loadMore]);
  return _jsx(UserFinancesBonusesContainer, {
    data: data,
    isEmpty: isEmpty,
    totalCount: totalCount,
    isFetching: isFetching,
    onLoadMore: onLoadMore
  });
};