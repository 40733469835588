import { useGetCmsLandingPageQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import CmsContainerContainer from '../../cms/container/container';
import { LazyContentWrapper } from '../../general/lazyContentWrapper';
import { LazyContentItem } from '../../general/lazyContentWrapper/item';
import { Wrapper } from './controls';
import PartnerWindowLayout from './layout';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = PartnerWindowLayout;
const LandingWindowContainer = _ref => {
  let {
    guid
  } = _ref;
  const {
    data,
    isFetching: isFetching,
    error,
    isSuccess
  } = useGetCmsLandingPageQuery(guid);
  const history = useHistory();
  const {
    defaultRoute
  } = useAuthUser();
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  useEffect(() => {
    if ((isSuccess || error) && !data?.length) {
      history.replace(defaultRoute);
    }
  }, [history, defaultRoute, data, isSuccess, error]);
  return /*key нужен для полноценного рефреша, так как рендер сложный асинхронный*/_jsx(Layout, {
    children: _jsx(LazyContentWrapper, {
      guid: guid,
      children: listSize => _jsxs(_Fragment, {
        children: [_jsx(Wrapper, {
          children: data?.slice(0, listSize + 1).map((container, i) => _jsx("div", {
            children: _jsx(LazyContentItem, {
              elementSize: 33,
              guid: guid,
              containerId: container.id,
              isLoaded: i !== listSize,
              children: onContainerDataLoaded => _jsx(CmsContainerContainer, {
                guid: guid,
                container: container,
                onContainerDataLoaded: onContainerDataLoaded
              })
            })
          }, container.id))
        }), isFetching && _jsx(ContentLoader, {})]
      })
    })
  }, guid);
};
export default LandingWindowContainer;