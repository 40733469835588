import { useHistory } from 'react-router';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import BookingOfferTabs from '../tabs';
import { addSearchParamToLocation } from '../../../../../../routing';
import { EBookingUrlParam } from '../../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferDetailsTabsAdapter = _ref => {
  let {
    tab,
    id
  } = _ref;
  const history = useHistory();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const onChangeTab = (event, newTab) => {
    history.replace(addSearchParamToLocation({
      location: history.location,
      param: EBookingUrlParam.Tab,
      value: newTab
    }));
  };
  if (!bookingOffer) {
    return null;
  }
  return _jsx(BookingOfferTabs, {
    tab: tab,
    id: id,
    bookingOffer: bookingOffer,
    onChange: onChangeTab
  });
};
export default BookingOfferDetailsTabsAdapter;