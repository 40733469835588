import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import OfferListAside from '../../../components/listAside';
import OfferSubCategories from '../../../components/subCategories';
import BookingOfferListFilter from '../../components/filter';
import { getBookingOffersSearchRoute } from '../../routes';
import useCategoriesData from '../hooks/useCategoriesData';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferAllServicesSelector, bookingOfferIsFilterFetchingSelector, bookingOfferListFilterDataSelector, bookingOfferListPriceFilterSelector, bookingOfferListServicesFilterSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BookingOfferListAsideAdapter = _ref => {
  let {
    filterCount,
    categoryId
  } = _ref;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const {
    onChangePriceRange,
    onSelectService,
    onResetFilters
  } = useContextHandlers();
  const {
    subCategories,
    isCategoriesFetching
  } = useCategoriesData();
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);
  const allServices = useSelector(bookingOfferAllServicesSelector);
  const {
    priceRange: availableRange,
    services: availableServices
  } = useSelector(bookingOfferListFilterDataSelector);
  const services = useSelector(bookingOfferListServicesFilterSelector);
  const priceFilter = useSelector(bookingOfferListPriceFilterSelector);
  const linkFactory = category => getBookingOffersSearchRoute({
    categoryId: category.id
  });
  if (isMdUp && categoryId) {
    return _jsx(BookingOfferListFilter, {
      isFilterFetching: isFilterFetching,
      allServices: allServices,
      services: services,
      availableServices: availableServices,
      availableRange: availableRange,
      priceFilter: priceFilter,
      filterCount: filterCount,
      onChangePriceRange: onChangePriceRange,
      onSelectService: onSelectService,
      onResetFilters: onResetFilters
    });
  }
  if (subCategories?.length && !isCategoriesFetching) {
    return _jsx(OfferListAside, {
      children: _jsx(OfferSubCategories, {
        categories: subCategories,
        linkFactory: linkFactory
      })
    });
  }
};