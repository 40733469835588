import { getUserProfileRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemSettings } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuSettingsAdapter = () => {
  const history = useHistory();
  const onClick = () => {
    history.push(getUserProfileRoute());
  };
  return _jsx(UserMenuItemSettings, {
    onClick: onClick
  });
};