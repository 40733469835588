import useHistoryExtensions from '../../../../../hooks/useHistoryExtensions';
import { getUserOrdersRoute } from '../../../entry';
const useOrderDetails = () => {
  const {
    gotoPrevIndependentLocation
  } = useHistoryExtensions();
  const goBack = () => gotoPrevIndependentLocation(getUserOrdersRoute({}));
  return {
    goBack
  };
};
export default useOrderDetails;