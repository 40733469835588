import { ELocaleShort } from '../../../../types';
export const filterAlhpabetByLocale = locale => letter => {
  if (locale === ELocaleShort.En) {
    return new RegExp('[A-z]').test(letter);
  }
  return RegExp('[А-я]').test(letter);
};
export const specialCharactersAndDigitsRegEx = /^[0-9@~`!#$%^&*()_=+№\\';:"/?>.<,-]/;
export const filterDigitsAndSpecials = letter => {
  return specialCharactersAndDigitsRegEx.test(letter);
};
export const charIsAllowed = (char, allowedAlphabet) => {
  return allowedAlphabet.includes(char);
};