import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getProductOffersFilterRoute } from '../../entry';
import { productOfferEmptyFilter } from '../utils';
export const useProductOfferFilterInLocation = () => {
  const location = useLocation();
  const history = useHistory();
  const onFilterApplyValues = useCallback(filter => {
    history.replace(getProductOffersFilterRoute({
      location,
      filter
    }));
  }, [location, history]);
  const onFilterClearValues = useCallback(() => {
    history.replace(getProductOffersFilterRoute({
      location,
      filter: productOfferEmptyFilter
    }));
  }, [location, history]);
  return {
    onFilterApplyValues,
    onFilterClearValues
  };
};