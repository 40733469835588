import { v4 as uuidv4 } from 'uuid';
export const rootRouting = {
  root: '/',
  tradeOffer: '/trade-offers',
  aspOffer: '/asp-offers',
  corpOffer: '/corp-offers',
  bookingOffer: '/booking-offers',
  partner: '/partners',
  user: '/user',
  about: '/about',
  legal: '/legal',
  payment: '/payment',
  cart: '/cart',
  product: '/products',
  data: '/data',
  cms: '/cms',
  search: '/search'
};
export const getHomepageRoute = props => {
  const newGuid = props?.guid ?? uuidv4();
  return {
    pathname: rootRouting.root,
    search: '',
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const addStateToLocation = props => {
  const {
    location,
    state
  } = props;
  const {
    pathname,
    search,
    state: prevState,
    hash,
    key
  } = location;
  const newState = typeof prevState === 'object' ? {
    ...prevState,
    ...state
  } : {
    ...state
  };
  return {
    pathname,
    search,
    state: newState,
    hash,
    key
  };
};
export const addSearchParamToLocation = props => {
  const {
    location,
    param,
    value
  } = props;
  const newSearchParams = new URLSearchParams(location.search);
  newSearchParams.set(param, value);
  return {
    ...location,
    search: newSearchParams.toString()
  };
};
export default rootRouting;