import ProductOfferScreen from 'presentation/screen/productOffer';
import ProductOfferListScreen from 'presentation/screen/productOffers';
import { Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { EGlobalUrlParam } from '../../header/utils';
import { getNonFilterSearch } from './filter/utils';
import { EProductOfferUrlParam } from './utils';
import { routing } from './routes';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const getProductOfferListRoute = props => {
  const newGuid = props?.guid ?? uuidv4();
  return {
    pathname: routing.root,
    search: '',
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const getProductListRoute = () => routing.root;
export const getProductOfferDetailsRoute = _ref => {
  let {
    id,
    tab
  } = _ref;
  const params = new URLSearchParams();
  if (tab) {
    params.append(EProductOfferUrlParam.Tab, tab);
  }
  return `${routing.details.replace(':id', id)}?${params.toString()}`;
};
export const getProductOffersSearchRoute = props => {
  const {
    name,
    categoryId,
    guid
  } = props;
  const newGuid = guid ?? uuidv4();
  const params = new URLSearchParams();
  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }
  if (categoryId) {
    params.append(EProductOfferUrlParam.Category, categoryId);
  }
  return {
    pathname: routing.root,
    search: params.toString(),
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const getProductOffersFilterRoute = props => {
  const {
    location,
    filter
  } = props;
  const params = new URLSearchParams(getNonFilterSearch(location.search));
  Object.entries(filter).forEach(_ref2 => {
    let [filterId, value] = _ref2;
    if (value !== null) params.append(filterId, value.toString());
  });
  return {
    ...location,
    search: params.toString()
  };
};
const ProductOfferEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.root,
      component: ProductOfferListScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: ProductOfferScreen
    })]
  });
};
export default ProductOfferEntry;