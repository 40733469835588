import { Typography } from '@mui/material';
import { paginationSizeVariant } from 'domain/model/constants';
import { MPMenuItem } from 'presentation/theme/ui-kit/menu/item';
import { useMemo } from 'react';
import { Select } from './controls';
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const variants = paginationSizeVariant;
const minPageSize = variants[0];
const PageSizeSelect = props => {
  const {
    totalCount,
    value,
    onChange,
    children
  } = props;
  const selectorContent = useMemo(() => _jsx(Select, {
    fullWidth: false,
    variant: "filled",
    value: value,
    onChange: onChange,
    children: variants.map(variant => _jsx(MPMenuItem, {
      value: variant,
      children: _jsxs(Typography, {
        variant: "body1",
        children: ["\u041F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0442\u044C \u043F\u043E ", variant]
      })
    }, variant))
  }), [value, onChange]);
  if (totalCount <= minPageSize) return null;
  if (children) return _jsx(_Fragment, {
    children: children(selectorContent)
  });
  return selectorContent;
};
export default PageSizeSelect;