import { EAddressLevel } from 'domain/model/enums';
import { AddressHelper } from 'utils/address';
import AddressSingleSelector from './index';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionLabelDefault = address => new AddressHelper(address).getLocalityFullPath();
const AddressCitySingleSelector = props => {
  return _jsx(AddressSingleSelector, {
    ...props,
    getOptionLabel: props.getOptionLabel ?? getOptionLabelDefault,
    level: EAddressLevel.Value5
  });
};
export default AddressCitySingleSelector;