import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { SidebarWrapper } from '../controls';
import ActivationListItemLabel from '../label/title';
import ActivationWidgetButton from '../widgetButton';
import { useRef } from 'react';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemWidgetSidebar = props => {
  const {
    appointmentDate,
    expireDate,
    codeTypeName,
    activation,
    srcDoc
  } = props;
  const wrapperRef = useRef(null);
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      direction: "column",
      children: [_jsxs(MPGrid, {
        item: true,
        children: [_jsx(ActivationListItemLabel, {
          codeTypeName: codeTypeName,
          appointmentDate: appointmentDate
        }), expireDate && _jsx(ActivationListItemExpireDate, {
          expireDate: expireDate
        })]
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(ActivationWidgetButton, {
          wrapperRef: wrapperRef,
          activation: activation,
          srcDoc: srcDoc
        })
      })]
    })
  });
};
export default ActivationListItemWidgetSidebar;