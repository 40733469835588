import { SlotsGrid } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ClaimContentLayout = _ref => {
  let {
    children
  } = _ref;
  return _jsx(SlotsGrid, {
    children: children
  });
};
export default ClaimContentLayout;