import { Typography } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferDetailsOnlyTitle = props => {
  const {
    title
  } = props;
  return _jsx(Typography, {
    variant: "h3",
    component: "h1",
    children: title
  });
};
export default OfferDetailsOnlyTitle;