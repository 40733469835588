import { SystemEventContentBuilderLayout, useSystemEvents } from 'features/systemEvent';
import { useMemo } from 'react';
import { SystemEventDialogLayout } from '../layouts/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SystemEventsComponentsFlowAdapter = _ref => {
  let {
    event
  } = _ref;
  const {
    onNext,
    onClose
  } = useSystemEvents();
  const {
    payload,
    wrapper: Wrapper,
    title: Title,
    content: Content,
    actions: Actions
  } = event ?? {};
  const wrapper = useMemo(() => Wrapper ? props => _jsx(Wrapper, {
    payload: payload,
    onNext: onNext,
    onClose: onClose,
    ...props
  }) : undefined, [Wrapper, onClose, onNext, payload]);
  return _jsx(SystemEventDialogLayout, {
    open: true,
    onClose: onClose,
    disableEscapeKeyDown: event?.required,
    children: _jsx(SystemEventContentBuilderLayout, {
      wrapper: wrapper,
      title: Title && _jsx(Title, {
        payload: payload,
        onNext: onNext,
        onClose: onClose
      }),
      actions: Actions && _jsx(Actions, {
        payload: payload,
        onNext: onNext,
        onClose: onClose
      }),
      children: Content && _jsx(Content, {
        payload: payload,
        onNext: onNext,
        onClose: onClose
      })
    })
  });
};