import axios from 'axios';
import { createCancelToken } from '../utils';
const agreement = {
  all: _ref => {
    let {
      signal
    } = _ref;
    return {
      url: `/users/current/agreements`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  sign: _ref2 => {
    let {
      data
    } = _ref2;
    return {
      url: `/users/current/agreements`,
      method: 'POST',
      data
    };
  }
};
export default agreement;