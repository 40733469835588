import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { BalancesWrapper, ContentWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserFinancesLayout = props => {
  const {
    balances,
    history,
    actions,
    loader,
    children,
    tabs
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      children: _jsxs(ContentWrapper, {
        children: [_jsx(Typography, {
          variant: "h1",
          children: "\u041C\u043E\u0439 \u0431\u0430\u043B\u0430\u043D\u0441"
        }), _jsx(Splitter, {
          size: 3
        }), balances && _jsxs(_Fragment, {
          children: [_jsx(BalancesWrapper, {
            children: balances
          }), _jsx(Splitter, {
            size: 4
          })]
        }), history && _jsxs(_Fragment, {
          children: [_jsx(Typography, {
            variant: "h1",
            children: "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u043E\u043F\u0435\u0440\u0430\u0446\u0438\u0439"
          }), _jsx(Splitter, {
            size: 3
          }), tabs, _jsx(Splitter, {
            size: 3
          }), history, _jsx(Splitter, {
            size: 5
          })]
        }), actions, children, loader]
      })
    })
  });
};