import AppImage from 'presentation/components/common/images/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskLogo = _ref => {
  let {
    partnerDesk
  } = _ref;
  const {
    image
  } = partnerDesk;
  if (!image?.path) {
    return null;
  }
  return _jsx(AppImage, {
    src: image,
    alt: ""
  });
};
export default PartnerDeskLogo;