import axios from 'axios';
import { createCancelToken } from './utils';
/**
 * АПИ по работе с cms
 */
const cms = {
  banner: {
    one: _ref => {
      let {
        id
      } = _ref;
      return {
        url: `/cms/banners/${id}`,
        method: 'GET'
      };
    },
    byIds: _ref2 => {
      let {
        ids,
        signal
      } = _ref2;
      const params = new URLSearchParams();
      ids?.forEach(id => params.append('bannerIds', id));
      return {
        url: `/cms/banners`,
        method: 'GET',
        params,
        cancelToken: signal && createCancelToken(axios, signal)
      };
    }
  },
  collection: {
    one: _ref3 => {
      let {
        id
      } = _ref3;
      return {
        url: `/cms/collections/${id}`,
        method: 'GET'
      };
    },
    byIds: _ref4 => {
      let {
        ids,
        signal
      } = _ref4;
      const params = new URLSearchParams();
      ids?.forEach(id => params.append('collectionIds', id));
      return {
        url: `/cms/collections`,
        method: 'GET',
        params: {
          params,
          cancelToken: signal && createCancelToken(axios, signal)
        }
      };
    }
  }
};
export default cms;