import { EOfferListType } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersTextHeader from '../../../components/textHeader';
import TradeOfferListDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOfferListGuidSelector, tradeOfferListUpcomingArgsSelector } from '../store/selectors';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Upcoming;
export const TradeOfferListDataUpcomingAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(tradeOfferListGuidSelector);
  const args = useSelector(tradeOfferListUpcomingArgsSelector);
  const handlers = useContextHandlers();
  const header = useMemo(() => _jsxs(OffersTextHeader, {
    variant: "h2",
    children: ["\u0421\u043A\u043E\u0440\u043E \u0431\u0443\u0434\u0443\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B", _jsx(Splitter, {
      size: 3
    })]
  }), []);
  return useMemo(() => guid !== currentGuid || !args ? null : _jsx(TradeOfferListDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    header: header,
    onChangePage: handlers.onChangePage,
    onShowCard: handlers.onShowCard
  }), [args, currentGuid, guid, handlers.onChangePage, handlers.onShowCard, header]);
};