import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { ECatalogTab } from 'presentation/features/catalog/types';
import { getAspOffersListRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/routes';
import { getProductOfferListRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersListRoute } from 'presentation/features/offer/trade/routes';
import LandingLink from 'presentation/features/sectionLinks/components/link';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppCategories, EAppFeature } from 'presentation/types';
import { useMemo } from 'react';
import { PartitionIcon } from '../../../components/common/partitionIcon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const useLinks = () => {
  const {
    hasFeature
  } = useTechConfig();
  const {
    isCorpUser
  } = useAuthUser();
  const hasTradeOffers = hasFeature(EAppFeature.Trade);
  const hasCorpOffers = isCorpUser;
  const hasProductOffers = hasFeature(EAppFeature.Product);
  const hasBookingOffers = hasFeature(EAppFeature.Booking);
  const hasAspOffers = hasFeature(EAppFeature.Asp) && isCorpUser;
  return useMemo(() => {
    const links = [];
    if (hasCorpOffers) {
      links.push(_jsx(LandingLink, {
        to: getCorpOffersListRoute({}),
        icon: _jsx(PartitionIcon, {
          type: EAppCategories.CorpOffers
        }),
        label: EAppCategories.CorpOffers,
        value: ECatalogTab.CorpOffers
      }, 'corp'));
    }
    if (hasTradeOffers) {
      links.push(_jsx(LandingLink, {
        to: getTradeOffersListRoute(),
        icon: _jsx(PartitionIcon, {
          type: EAppCategories.TradeOffers
        }),
        label: EAppCategories.TradeOffers,
        value: ECatalogTab.TradeOffers
      }, 'trade'));
    }
    if (hasProductOffers) {
      links.push(_jsx(LandingLink, {
        to: getProductOfferListRoute(),
        icon: _jsx(PartitionIcon, {
          type: EAppCategories.ProductOffers
        }),
        label: EAppCategories.ProductOffers,
        value: ECatalogTab.ProductOffers
      }, 'product'));
    }
    if (hasBookingOffers) {
      links.push(_jsx(LandingLink, {
        to: getBookingOffersListRoute(),
        icon: _jsx(PartitionIcon, {
          type: EAppCategories.BookingOffers
        }),
        label: EAppCategories.BookingOffers,
        value: ECatalogTab.BookingOffers
      }, 'service'));
    }
    if (hasAspOffers) {
      links.push(_jsx(LandingLink, {
        to: getAspOffersListRoute(),
        icon: _jsx(PartitionIcon, {
          type: EAppCategories.AspOffers
        }),
        label: EAppCategories.AspOffers,
        value: ECatalogTab.AspOffers
      }, 'asp'));
    }
    return links;
  }, [hasCorpOffers, hasTradeOffers, hasProductOffers, hasBookingOffers, hasAspOffers]);
};
export default useLinks;