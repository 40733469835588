import { Link as MUILink, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderLink = _ref => {
  let {
    text,
    component = RouterLink,
    target = '_self',
    ...rest
  } = _ref;
  return _jsx(MUILink, {
    color: "textSecondary",
    variant: "body2",
    underline: "none",
    target: target,
    component: component,
    ...rest,
    children: _jsx(Typography, {
      variant: "body2",
      noWrap: true,
      children: text
    })
  });
};
export default HeaderLink;