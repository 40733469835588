import { FilterIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../icon';
import { StyledBadge } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FilterBadge = _ref => {
  let {
    active,
    ...others
  } = _ref;
  return _jsx(StyledBadge, {
    badgeContent: active,
    color: 'primary',
    children: _jsx(AppSvgIcon, {
      icon: FilterIcon,
      ...others
    })
  });
};
export default FilterBadge;