import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'pageable/args',
  initialState: {},
  reducers: {
    pageableArgsPut: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        key,
        data
      } = payload;
      const currentData = state[key];
      if (!deepEqual(currentData, data)) {
        state[key] = data;
      }
    },
    pageableArgsPutPartial: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        key,
        data
      } = payload;
      state[key] = {
        ...state[key],
        ...data
      };
    }
  }
});
export const {
  pageableArgsPut,
  pageableArgsPutPartial
} = slice.actions;
export default slice.reducer;
const deepEqual = function (x, y) {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;
    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }
    return true;
  } else return false;
};