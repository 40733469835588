import useAspOfferCategoriesData from './useAspOfferCategoriesData';
import useAspOfferDetailsData from './useAspOfferDetailsData';
import useAspOfferPartnerDeskData from './useAspOfferPartnerDeskData';
const useAspOfferDetailsFetchingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesFetching
  } = useAspOfferCategoriesData();
  const {
    isAspOfferFetching
  } = useAspOfferDetailsData(id);
  const {
    isPartnerDeskFetching
  } = useAspOfferPartnerDeskData(id);
  return isCategoriesFetching || isAspOfferFetching || isPartnerDeskFetching;
};
export default useAspOfferDetailsFetchingStatus;