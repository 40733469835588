import { Hidden, Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import React from 'react';
import CodeTitle from '../../../../../components/common/codeTitle/title';
import { Bold } from '../../../../user/details/activations/controls';
import { SidebarWrapper } from '../controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemUpcomingSidebar = props => {
  const {
    offerName,
    offerCode,
    startDate,
    subscription,
    onOfferClick
  } = props;
  const {
    x2
  } = useGridSpacing();
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: x2,
      direction: "column",
      children: [_jsx(Hidden, {
        smUp: true,
        children: _jsx(MPGrid, {
          item: true,
          children: _jsx(Bold, {
            onClick: onOfferClick,
            children: offerName
          })
        })
      }), _jsxs(MPGrid, {
        item: true,
        children: [_jsx(CodeTitle, {
          code: offerCode
        }), _jsxs(Typography, {
          color: "error",
          children: ["\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E", ' ', startDate && _jsxs(Typography, {
            component: "span",
            noWrap: true,
            children: ["\u0441 ", moment(startDate).format(EDateFormat.Human), " \u0433\u043E\u0434\u0430"]
          })]
        })]
      }), subscription && _jsx(MPGrid, {
        item: true,
        children: subscription
      })]
    })
  });
};
export default ActivationListItemUpcomingSidebar;