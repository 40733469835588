import { Text } from '../components/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutTextSimpleComponent = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Text, {
    children: children
  });
};
export default AboutTextSimpleComponent;