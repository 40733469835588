import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { ButtonLink } from 'presentation/components/common/buttons/link';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartRemoveButton = props => {
  return _jsx(ButtonLink, {
    startIcon: _jsx(ClearIcon, {
      color: "secondary"
    }),
    ...props,
    children: _jsxs(Typography, {
      color: "textPrimary",
      children: ["\u0423\u0434\u0430\u043B\u0438\u0442\u044C ", props.count || '']
    })
  });
};
export default CartRemoveButton;