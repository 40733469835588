import apiDefinition from 'data/openApi';
import ContentLoader from 'presentation/components/common/loader';
import { MPAvatarEditor } from 'presentation/theme/ui-kit/avatarEditor';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { useRef, useState } from 'react';
import Notifier from 'system/notifier';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const width = 150;
const height = 150;
const quality = 0.8;
const UserAvatarEditorContainer = props => {
  const {
    source,
    onChange,
    onClose
  } = props;
  const [isFetching, setIsFetching] = useState(false);
  const editorRef = useRef(null);
  const onSaveInternal = () => {
    const editor = editorRef.current;
    if (editor) {
      setIsFetching(() => true);
      editor.getImage().toBlob(blob => {
        if (blob) {
          const file = new File([blob], 'image.png');
          apiDefinition.files.upload(file).then(_ref => {
            let {
              data
            } = _ref;
            return onChange(data);
          }).catch(e => {
            Notifier.getInstance().addError(`Произошла ошибка при загрузке файла: ${e.message}`);
          }).finally(() => {
            setIsFetching(() => false);
          });
        } else {
          Notifier.getInstance().addError('Не удалось обработать изображение');
          setIsFetching(() => false);
        }
      }, 'image/jpeg', quality);
    } else {
      Notifier.getInstance().addError('Не удалось обратиться к редактору изображения');
    }
  };
  return _jsx(MPConfirmDialog, {
    open: !!source,
    maxWidth: "xs",
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onSaveInternal,
        children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"
      }), _jsx(MPButton, {
        variant: "text",
        color: "brand",
        size: "large",
        fullWidth: true,
        disabled: isFetching,
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C"
      })]
    }),
    children: _jsxs(Wrapper, {
      children: [source && _jsx(MPAvatarEditor, {
        editorRef: editorRef,
        image: source,
        width: width,
        height: height
      }), isFetching && _jsx(ContentLoader, {})]
    })
  });
};
export default UserAvatarEditorContainer;