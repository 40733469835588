import { EOfferStatus, EProductOfferSortType } from 'domain/model/enums';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EProductOfferServicesTag } from './index';
const defaultProductOfferListStatuses = [EOfferStatus.Active, EOfferStatus.OutOfStock];
export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductOfferCategories: builder.query({
      transformResponse: response => response.data,
      query: () => {
        return apiDefinition.product.categories({});
      }
    }),
    getProductOfferCategory: builder.query({
      transformResponse: response => response.data,
      query: id => {
        return apiDefinition.product.category({
          id
        });
      }
    }),
    getProductOfferCategoriesUsed: builder.query({
      transformResponse: response => {
        return response.data;
      },
      query: _ref => {
        let {
          name,
          city,
          onlyLeafCategories,
          ...other
        } = _ref;
        return apiDefinition.user.product.categoriesUsed({
          query: name,
          city,
          statuses: defaultProductOfferListStatuses,
          onlyLeafCategories,
          ...other
        });
      }
    }),
    getProductOfferCategoriesUsedTree: builder.query({
      transformResponse: response => categoriesBuildTree(response.data),
      query: _ref2 => {
        let {
          city
        } = _ref2;
        return apiDefinition.user.product.categoriesUsed({
          city,
          statuses: defaultProductOfferListStatuses,
          onlyLeafCategories: false
        });
      }
    }),
    getProductOfferSearch: builder.query({
      keepUnusedDataFor: 0,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          search,
          pageSize
        } = _ref3;
        const {
          name
        } = search;
        return apiDefinition.user.product.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultProductOfferListStatuses,
          sort: [EProductOfferSortType.ByNovelty]
        });
      }
    }),
    getProductOfferList: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref4 => {
        let {
          search,
          page,
          pageSize,
          sort,
          ...filters
        } = _ref4;
        const {
          name,
          categories,
          partnerId
        } = search;
        return apiDefinition.user.product.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses: defaultProductOfferListStatuses,
          partnerId,
          categories,
          ...filters
        });
      },
      providesTags: [EProductOfferServicesTag.List]
    }),
    getProductOffersCount: builder.query({
      transformResponse: response => {
        return response.data[0]?.count ?? 0;
      },
      query: _ref5 => {
        let {
          search
        } = _ref5;
        const {
          name,
          categories
        } = search;
        return apiDefinition.user.product.count({
          query: name,
          statuses: defaultProductOfferListStatuses,
          categories
        });
      }
    }),
    getProductOfferDetails: builder.query({
      query: _ref6 => {
        let {
          id
        } = _ref6;
        return apiDefinition.user.product.one({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: (result, error, args) => [{
        type: EProductOfferServicesTag.Details,
        id: args.id
      }, EProductOfferServicesTag.Details]
    }),
    getProductOfferVariations: builder.query({
      transformResponse: response => {
        return response.data;
      },
      query: _ref7 => {
        let {
          productDeskId
        } = _ref7;
        return apiDefinition.user.product.variations({
          productDeskId
        });
      }
    })
  })
});
export const {
  useGetProductOfferListQuery,
  useGetProductOffersCountQuery,
  useGetProductOfferCategoriesQuery,
  useGetProductOfferCategoryQuery,
  useGetProductOfferCategoriesUsedQuery,
  useGetProductOfferDetailsQuery,
  useGetProductOfferCategoriesUsedTreeQuery,
  useGetProductOfferSearchQuery,
  useGetProductOfferVariationsQuery
} = productApi;