import { Zoom } from '@mui/material';
import { IconOff, IconOn } from 'presentation/components/common/buttons/cardFavorite/controls';
import MPSwitchIconButton from 'presentation/theme/ui-kit/button/switchIcon/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const IconOnInternal = _ref => {
  let {
    active
  } = _ref;
  return _jsx(Zoom, {
    in: active,
    children: _jsx(IconOn, {
      color: "brand"
    })
  });
};
const IconOffInternal = _ref2 => {
  let {
    active
  } = _ref2;
  return _jsx(Zoom, {
    in: active,
    children: _jsx(IconOff, {})
  });
};
const CardFavoriteButton = props => {
  const {
    active,
    inProgress,
    onClick
  } = props;
  return _jsx(MPSwitchIconButton, {
    active: active,
    inProgress: inProgress,
    icons: {
      on: _jsx(IconOnInternal, {
        active: active
      }),
      off: _jsx(IconOffInternal, {
        active: !active
      })
    },
    onClick: onClick
  });
};
export default CardFavoriteButton;