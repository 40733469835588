import { ConfirmPhoneEventContainer } from './container';
import { confirmPhoneEventType } from './types';
export const createEvent = (payload, onSuccess) => {
  return {
    required: true,
    type: confirmPhoneEventType,
    component: ConfirmPhoneEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload,
    onSuccess
  };
};