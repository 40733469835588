import { EDeliveryType, EProductOrderPaymentType } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { InfoLink } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SidebarInfo = _ref => {
  let {
    product,
    deliveryTypes,
    paymentTypes,
    onClickPayment,
    onClickDelivery
  } = _ref;
  const deliveryType = deliveryTypes.find(dt => dt.id === product.deliveryType);
  const paymentType = paymentTypes.find(dt => dt.id === product.paymentType);
  let deliveryInfo = deliveryType ? `Есть ${deliveryType.name.toLowerCase()}` : null;
  if (deliveryType?.id === EDeliveryType.Other) {
    deliveryInfo = 'Информация о доставке';
  }
  let paymentInfo = paymentType ? `Оплата ${paymentType.name.toLowerCase()}` : null;
  if (paymentType?.id === EProductOrderPaymentType.Other) {
    paymentInfo = 'Информация об оплате';
  }
  return _jsxs(MPGrid, {
    item: true,
    container: true,
    spacing: 1.5,
    flexDirection: "column",
    children: [deliveryInfo && _jsx(MPGrid, {
      item: true,
      children: _jsx(InfoLink, {
        onClick: onClickDelivery,
        children: deliveryInfo
      })
    }), paymentInfo && _jsx(MPGrid, {
      item: true,
      children: _jsx(InfoLink, {
        onClick: onClickPayment,
        children: paymentInfo
      })
    })]
  });
};
export default SidebarInfo;