import { InputAdornment } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import { isArray } from 'lodash';
import moment from 'moment-timezone';
import { CloseIcon } from 'presentation/media/icons';
import { MPIconButton } from 'presentation/theme/ui-kit/button';
import { MPFormInput, MPMaskedInput } from 'presentation/theme/ui-kit/input';
import { permanentOptions, regularOptions, StyledPicker } from '../configuration';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const format = EDateFormat.DisplayDefault;
const mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const MPSingleDatePicker = props => {
  const {
    error,
    helperText,
    size,
    color,
    disabled,
    label,
    ...pickerProps
  } = props;
  const onChangeInternal = selectedDates => {
    if (selectedDates === null) {
      return pickerProps.onChange?.(null);
    }

    //ничего не делаем если значение не изменилось
    if ((isArray(selectedDates) && selectedDates.length === 0 || !selectedDates) && !pickerProps.value) {
      return;
    }
    const result = isArray(selectedDates) ? selectedDates?.[0]?.toDate() : selectedDates.toDate() ?? null;
    return pickerProps.onChange?.(result);
  };
  const onChangeCustom = value => {
    return pickerProps.onChange?.(moment(value, format).toDate());
  };
  return _jsx(StyledPicker, {
    ...regularOptions,
    ...pickerProps,
    value: pickerProps.value ?? [],
    onChange: onChangeInternal,
    multiple: false,
    range: false,
    containerStyle: {
      width: '100%'
    },
    format: format,
    render: (val, openCalendar) => {
      return _jsx(MPFormInput, {
        label: label,
        fullWidth: true,
        value: pickerProps.value ? moment(pickerProps.value.toString()).format(format) : '',
        size: size,
        color: color,
        disabled: disabled,
        error: error,
        helperText: helperText,
        InputProps: {
          endAdornment: pickerProps.onChange && pickerProps.value && !disabled && _jsx(InputAdornment, {
            position: "end",
            children: _jsx(MPIconButton, {
              size: "small",
              onClick: event => {
                event.stopPropagation();
                pickerProps.onChange?.(null);
              },
              children: _jsx(CloseIcon, {
                fontSize: "small"
              })
            })
          }),
          inputComponent: props => _jsx(MPMaskedInput, {
            ...props,
            mask: mask,
            showMask: false
          })
        },
        onBlur: event => onChangeCustom(event.target.value),
        onClick: disabled ? undefined : openCalendar
      });
    },
    shadow: true,
    ...permanentOptions
  });
};