import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import LandingWindowScreen from 'presentation/screen/landing';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.root;
const routing = {
  root
};
export const getLandingRoute = props => {
  const {
    guid
  } = props;
  const params = new URLSearchParams();
  const newGuid = guid ?? uuidv4();
  return {
    pathname: routing.root,
    search: params.toString(),
    state: {
      guid: newGuid
    },
    hash: ''
  };
};

/**
 * точка входа для лэндинга, по сути root
 */
const LandingEntry = () => {
  const {
    defaultRoute
  } = useAuthUser();
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.root,
      component: LandingWindowScreen
    }), _jsx(Redirect, {
      to: defaultRoute
    })]
  });
};
export default LandingEntry;