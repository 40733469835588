import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { EOfferActivateError, EOfferActivationStatus } from 'domain/model/enums';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { useCallback, useEffect, useState } from 'react';
import OfferNdflHelpDialog from '../../../components/dialogs/ndfl';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import CorpOfferGetCertDialog from '../../details/dialogs/getCert';
import CorpOfferGetNewCertDialog from '../../details/dialogs/getNewCert';
import useCorpOfferClaimContext from '../provider/useCorpOfferClaimContext';
import CorpOfferClaimDialogsContext from './context';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferClaimDialogsProvider = props => {
  const {
    children,
    corpOffer,
    onShowAllOffers
  } = props;
  const {
    activation,
    onActivate
  } = useCorpOfferClaimContext();
  const {
    publish
  } = useSystemEventBus();
  const [ndflHelpDialogVisible, setNdflHelpDialogVisible] = useState(false);
  const [unavailableDialogVisible, setUnavailableDialogVisible] = useState(false);
  const [certificateDialogVisible, setCertificateDialogVisible] = useState(false);
  const [newCertificateDialogVisible, setNewCertificateDialogVisible] = useState(false);
  const onOpenNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(true), [setNdflHelpDialogVisible]);
  const onCloseNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(false), [setNdflHelpDialogVisible]);
  const onOpenUnavailableDialog = useCallback(() => setUnavailableDialogVisible(true), [setUnavailableDialogVisible]);
  const onCloseUnavailableDialog = useCallback(() => setUnavailableDialogVisible(false), [setUnavailableDialogVisible]);
  const onOpenCertificateDialog = useCallback(() => setCertificateDialogVisible(true), [setCertificateDialogVisible]);
  const onCloseCertificateDialog = useCallback(() => setCertificateDialogVisible(false), [setCertificateDialogVisible]);
  const onOpenNewCertificateDialog = useCallback(() => setNewCertificateDialogVisible(true), [setNewCertificateDialogVisible]);
  const onCloseNewCertificateDialog = useCallback(() => setNewCertificateDialogVisible(false), [setNewCertificateDialogVisible]);
  const onActivateCertificate = useCallback(() => {
    onCloseCertificateDialog();
    onCloseNewCertificateDialog();
    onActivate();
  }, [onActivate, onCloseCertificateDialog, onCloseNewCertificateDialog]);
  const openCertificateDialog = useCallback(() => {
    if (activation.lastActivation?.status === EOfferActivationStatus.Approved || activation.lastActivation?.status === EOfferActivationStatus.Given) {
      onOpenNewCertificateDialog();
    } else {
      onOpenCertificateDialog();
    }
  }, [activation.lastActivation?.status, onOpenNewCertificateDialog, onOpenCertificateDialog]);
  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      publish(createEventNeedFillProfile({
        message: activation.activationError.message
      }));
    }
  }, [activation.activationError, publish]);
  const value = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openNdflHelpDialog: onOpenNdflHelpDialog,
    openCertificateDialog,
    onShowAllOffers
  };
  return _jsxs(CorpOfferClaimDialogsContext.Provider, {
    value: value,
    children: [children, _jsx(OfferNdflHelpDialog, {
      open: ndflHelpDialogVisible,
      description: corpOffer.ndflDescription,
      onClose: onCloseNdflHelpDialog
    }), _jsx(OfferUnavailableDialog, {
      open: unavailableDialogVisible,
      onShowOtherOffers: onShowAllOffers,
      onClose: onCloseUnavailableDialog
    }), _jsx(CorpOfferGetCertDialog, {
      open: certificateDialogVisible,
      price: corpOffer.price,
      offerName: corpOffer.name,
      offerEndDate: corpOffer.endDate,
      activationExpiryDays: corpOffer.activationExpiryDays ?? 0,
      onGetCertificate: onActivateCertificate,
      onClose: onCloseCertificateDialog
    }), _jsx(CorpOfferGetNewCertDialog, {
      open: newCertificateDialogVisible,
      price: corpOffer.price,
      offerEndDate: corpOffer.endDate,
      activationExpiryDays: corpOffer.activationExpiryDays ?? 0,
      onGetCertificate: onActivateCertificate,
      onClose: onCloseNewCertificateDialog
    })]
  });
};
export default CorpOfferClaimDialogsProvider;