import useTradeOfferCategoriesData from './useTradeOfferCategoriesData';
import useTradeOfferDetailsData from './useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from './useTradeOfferPartnerDeskData';
const useTradeOfferDetailsFetchingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesFetching
  } = useTradeOfferCategoriesData();
  const {
    isTradeOfferFetching
  } = useTradeOfferDetailsData(id);
  const {
    isPartnerDeskFetching
  } = useTradeOfferPartnerDeskData(id);
  return isCategoriesFetching || isTradeOfferFetching || isPartnerDeskFetching;
};
export default useTradeOfferDetailsFetchingStatus;