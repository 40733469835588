import { EGlobalUrlParam } from 'presentation/features/header/utils';
import { ESearchUrlParam } from 'presentation/types';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
import { ETradeOfferUrlParam } from './utils';
const root = rootRouting.tradeOffer;
export const routing = {
  list: root,
  details: `${root}/:id`,
  search: `${root}/search`
};
export const getTradeOfferSearchResultsRoute = props => {
  const {
    guid,
    query
  } = props;
  const newGuid = guid ?? uuidv4();
  const searchParams = new URLSearchParams();
  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }
  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const getTradeOfferDetailsRoute = _ref => {
  let {
    id,
    tab,
    guid,
    from
  } = _ref;
  const params = new URLSearchParams();
  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(ETradeOfferUrlParam.Tab, tab);
  }
  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
      from
    },
    hash: ''
  };
};
export const getTradeOffersSearchRoute = props => {
  const {
    name,
    categoryId,
    guid,
    partnerId
  } = props;
  const newGuid = guid ?? uuidv4();
  const params = new URLSearchParams();
  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }
  if (categoryId) {
    params.append(ETradeOfferUrlParam.Category, categoryId);
  }
  if (partnerId) {
    params.append(ETradeOfferUrlParam.PartnerId, partnerId);
  }
  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const getTradeOffersListRoute = props => {
  const newGuid = props?.guid ?? uuidv4();
  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid
    },
    hash: ''
  };
};