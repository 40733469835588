import OfferHeaderTitle from '../headerTitle';
import OffersTextHeader from '../textHeader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OffersCommonHeader = props => {
  const {
    icon,
    children
  } = props;
  return _jsx(OfferHeaderTitle, {
    icon: icon,
    title: _jsx(OffersTextHeader, {
      children: children
    })
  });
};
export default OffersCommonHeader;