import { MPButton } from 'presentation/theme/ui-kit/button';
import { useCallback } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { useWrapper } from './wrapper';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ChangeCityEventActions = _ref => {
  let {
    onNext
  } = _ref;
  const {
    value,
    onSaveValue
  } = useWrapper();
  const onApply = useCallback(() => {
    if (value) {
      onSaveValue(value);
      onNext();
    }
  }, [value, onSaveValue, onNext]);
  return _jsxs(ConfirmGroupButtons, {
    children: [_jsx(MPButton, {
      fullWidth: true,
      disabled: !value,
      color: "brand",
      size: "large",
      onClick: onApply,
      children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
    }), _jsx(MPButton, {
      color: "brand",
      variant: "text",
      fullWidth: true,
      size: "large",
      onClick: onNext,
      children: "\u041E\u0442\u043C\u0435\u043D\u0430"
    })]
  });
};