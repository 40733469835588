import { CloseIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DataFilterView = props => {
  const {
    values,
    onClear,
    onDelete
  } = props;
  return _jsx(Wrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      alignItems: "center",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(MPFab, {
          color: "black",
          size: "micro",
          onClick: onClear,
          children: _jsx(CloseIcon, {})
        })
      }), values.map(_ref => {
        let {
          id,
          name
        } = _ref;
        return _jsx(MPGrid, {
          item: true,
          children: id && _jsx(MPChip, {
            label: name,
            color: "black",
            variant: "filled",
            onDelete: () => onDelete(id)
          })
        }, id);
      })]
    })
  });
};
export default DataFilterView;