import { useAlphabet } from 'presentation/features/partnerDesk/filter/alphabetical/hooks';
import { useCallback, useMemo } from 'react';
import { useGetPartnerDesksQuery } from '../../../../../data/api/partner';
import { EPaginationBehaviour } from '../../../general/pageable/types';
import usePageableList from '../../../general/pageable/usePageableList';
import usePartnerDesksPageableArgsInRedux from '../hooks/usePartnerDesksPageableArgsInRedux';
import PartnerDeskListContext from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskListProvider = props => {
  const {
    guid,
    children
  } = props;
  const {
    isAlphabetLoading,
    localeAlphabet
  } = useAlphabet();
  const argsStorage = usePartnerDesksPageableArgsInRedux(guid);
  const {
    currentState,
    savePartial
  } = argsStorage;
  const currentFilter = useMemo(() => ({
    patternSearch: currentState.query ?? null,
    firstCharacterSearch: currentState.name ?? null
  }), [currentState.query, currentState.name]);
  const {
    data,
    totalCount,
    isEmpty,
    isFetching,
    loadMore
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPageSize,
    useQuery: useGetPartnerDesksQuery,
    argsStorage,
    queryOptions: {
      skip: !localeAlphabet?.length
    }
  });
  const onLoadMore = useCallback(() => {
    loadMore?.();
  }, [loadMore]);
  const onChangeFilter = useCallback(newFilter => {
    savePartial?.({
      query: newFilter.patternSearch,
      name: newFilter.firstCharacterSearch
    });
  }, [savePartial]);
  const hasPartners = !!data && data.length > 0;
  const isShowMoreDisplayed = hasPartners && totalCount > data?.length;
  const value = {
    data,
    currentFilter,
    isEmpty: isEmpty && !isAlphabetLoading,
    isFetching,
    isShowMoreDisplayed,
    onChangeFilter,
    onLoadMore
  };
  return _jsx(PartnerDeskListContext.Provider, {
    value: value,
    children: children
  });
};
export default PartnerDeskListProvider;