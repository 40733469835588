import AuthProtect from 'presentation/features/auth/protect';
import { useMemo } from 'react';
import { Redirect, Route } from 'react-router';
import { Feature } from '../feature';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AppRoute = _ref => {
  let {
    protect,
    feature,
    defaultRoute = '/',
    ...routeProps
  } = _ref;
  const route = useMemo(() => {
    const ToRender = routeProps.render || routeProps.component;
    return protect ? _jsx(Route, {
      ...routeProps,
      component: undefined,
      render: props => _jsx(AuthProtect, {
        children: ToRender ? _jsx(ToRender, {
          ...props
        }) : null
      })
    }) : _jsx(Route, {
      ...routeProps
    });
  }, [protect, routeProps]);
  return feature ? _jsx(Feature, {
    feature: feature,
    stubComponent: _jsx(Redirect, {
      to: defaultRoute
    }),
    children: route
  }) : route;
};