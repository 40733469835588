import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { partnerDeskDetailsBookingOffersCountSelector, partnerDeskDetailsBookingOffersIsErrorSelector, partnerDeskDetailsBookingOffersIsFetchingSelector } from '../../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskDetailsTabBookingOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsBookingOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsBookingOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsBookingOffersIsErrorSelector);
  return useMemo(() => !count && !isFetching && !isError ? _jsx("div", {
    children: "\u0422\u043E\u0440\u0433\u043E\u0432\u044B\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u043F\u043E\u043A\u0430 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442"
  }) : null, [count, isFetching, isError]);
};
export default PartnerDeskDetailsTabBookingOffersNoData;