import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPPhoneInput } from '../../../../theme/ui-kit/input';
import { UserProfileEditAttribute } from './attribute';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserProfileEditAttributePhone = props => {
  const {
    value,
    validation,
    isFetching,
    onChange
  } = props;
  return _jsxs(UserProfileEditAttribute, {
    ...props,
    children: [_jsx(Typography, {
      children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430"
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(MPPhoneInput, {
      value: value,
      label: 'Новый номер телефона',
      error: !!validation?.hasError,
      helperText: validation?.message,
      disabled: isFetching,
      onChange: onChange
    })]
  });
};