export const productOfferEmptyFilter = {
  minPrice: null,
  maxPrice: null
};
export const priceRangeMinValue = 1;
export const priceRangeMaxValue = 100000;

/**
 * Функция для заполнения фильтров параметрами из search или "пустыми" значениями
 */
export const parseFiltersFromSearch = searchParams => {
  const filters = {
    ...productOfferEmptyFilter
  };
  const search = new URLSearchParams(searchParams);
  Object.keys(productOfferEmptyFilter).forEach(filterId => {
    const filterValueFromSearch = search.get(filterId);
    if (filterValueFromSearch) {
      Object.assign(filters, {
        [filterId]: filterValueFromSearch
      });
    }
  });
  return filters;
};

/**
 * Функция для "отсечения" параметров фильтра из search
 * @param searchParams string
 */
export const getNonFilterSearch = searchParams => {
  const search = new URLSearchParams(searchParams);
  Object.keys(productOfferEmptyFilter).forEach(item => search.delete(item));
  return search.toString();
};