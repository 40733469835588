import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { SystemEventsDefaultActions } from 'features/systemEvent/components';
import { useCallback, useEffect, useState } from 'react';
import { SystemEventsContext } from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const checkInterval = 3000;
const warn = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return console.warn('EventBus [system]', ...args);
};
const calculateState = prev => {
  const events = prev.events;
  const activeFlow = prev.activeFlow;
  if (events.length) {
    if (!activeFlow) {
      //объединяем события по их настройкам
      //как только достигли событие, которое не может быть приклеено к предыдущему - завершаем формирование флоу
      //как только достигли событие, к которому не может быть приклеено следующее - завершаем формирование флоу
      const mergedEvents = [];
      let i;
      for (i = 0; i < events.length; i++) {
        if (!mergedEvents.length) {
          mergedEvents.push(events[i]);
          continue;
        }
        const lastMergedEvent = mergedEvents[mergedEvents.length - 1];
        if (lastMergedEvent.mergeWithNext && events[i].mergeWithPrev) {
          mergedEvents.push(events[i]);
        } else {
          break;
        }
      }

      // запомним оставшиеся события
      const rightEvents = events.slice(i);
      const newFlow = {
        activeIndex: 0,
        events: mergedEvents,
        required: true
      };
      return {
        events: rightEvents,
        activeFlow: newFlow
      };
    } else {
      const newFlow = {
        ...activeFlow,
        events: [...activeFlow.events, ...events]
      };
      return {
        ...prev,
        events: [],
        activeFlow: newFlow
      };
    }
  }
  return prev;
};
export const SystemEventsProvider = _ref => {
  let {
    children
  } = _ref;
  const [state, setState] = useState({
    events: [],
    activeFlow: null
  });
  const onNext = useCallback(result => {
    setState(prev => {
      const activeFlow = prev.activeFlow;

      //переключаем на следующее событие или завершает текущий флоу
      if (activeFlow) {
        //дёргаем калбэк ивента о выполнении
        const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
        //игнорируем штатный event по currentTarget (чтобы всякие клики на контролы, которые event засылают, не сломали нам onSuccess)
        if (activeEvent?.onSuccess && result !== undefined && !result.currentTarget) {
          activeEvent.onSuccess(result);
        }
        if (activeFlow.activeIndex < activeFlow.events.length - 1) {
          const newFlow = {
            ...activeFlow,
            activeIndex: activeFlow.activeIndex + 1
          };
          return {
            ...prev,
            activeFlow: newFlow
          };
        } else {
          return {
            ...prev,
            activeFlow: null
          };
        }
      }
      return prev;
    });
  }, []);
  const onClose = useCallback(() => {
    setState(prev => {
      /**
       * если для события указан flowId, то при его отмене нужно отменить и все остальные
       * для этого переключаем activeIndex на индекс последнего события с нашим flowId (после чего onNext переключит еще на +1)
       */
      const activeFlow = prev.activeFlow;
      if (activeFlow) {
        const activeEvent = activeFlow.events?.[activeFlow.activeIndex];
        if (activeEvent?.flowId) {
          let i;
          for (i = activeFlow.activeIndex; i < activeFlow.events.length; i++) {
            if (activeFlow.events[i].flowId !== activeEvent?.flowId) {
              break;
            }
          }
          const newFlow = {
            ...activeFlow,
            activeIndex: i - 1
          };
          return {
            ...prev,
            activeFlow: newFlow
          };
        }
      }
      return prev;
    });
    onNext();
  }, [onNext]);
  useSystemEventBus(newEvent => {
    setState(prev => {
      //контролируем уникальность - если неправильный код кинул одно и то же событие несколько раз - учитываем только первое
      const duplicate = prev.events.find(event => event.type === newEvent.type) || prev.activeFlow?.events?.find(event => event.type === newEvent.type);
      if (duplicate) {
        warn(`duplicated event type '${newEvent.type}' has ignored`);
        return prev;
      }
      const events = [...prev.events];
      let index = prev.events.length;

      //обработаем вставку в определенное место, после какого-то типа события
      if (newEvent.hardInsertAfterType) {
        const afterIndex = prev.events.findIndex(event => event.type === newEvent.hardInsertAfterType);
        if (afterIndex !== -1) {
          index = afterIndex + 1;
        }
      }
      const eventToInsert = newEvent.component || newEvent.feature ? newEvent : {
        ...newEvent,
        actions: newEvent.actions ?? SystemEventsDefaultActions
      };

      //вставляем
      events.splice(index, 0, eventToInsert);

      //пересчитываем состояние
      return calculateState({
        ...prev,
        events
      });
    });
  });
  useEffect(() => {
    //для того, чтобы не задолбать юзера, добавляем пересчет состояния в интервал
    //если за время предыдущего пересчета накопятся события, то сформируется новый флоу
    const intervalId = setInterval(() => {
      setState(calculateState);
    }, checkInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return _jsx(SystemEventsContext.Provider, {
    value: {
      ...state,
      onNext,
      onClose
    },
    children: children
  });
};