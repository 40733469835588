import StubCommon from 'presentation/components/common/stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOffersStub = props => {
  const {
    isEmptySearch
  } = props;
  return isEmptySearch ? _jsx(StubCommon, {
    title: "\u041A\xA0\u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, \u0432\xA0\u0432\u0430\u0448\u0435\u043C \u0433\u043E\u0440\u043E\u0434\u0435 \u043D\u0435\u0442\xA0\u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439 \u043F\u043E\xA0\u0442\u043E\u0432\u0430\u0440\u0430\u043C.",
    text: "\u041D\u043E \u043C\u044B \u043F\u043E\u0441\u0442\u0430\u0440\u0430\u0435\u043C\u0441\u044F \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u0443 \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044E"
  }) : _jsx(StubCommon, {
    title: "\u041F\u043E \u0432\u0430\u0448\u0435\u043C\u0443 \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
    text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043E\u043A\u0440\u0430\u0442\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441 \u0438\u043B\u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u0433\u043E \u043F\u043E-\u0434\u0440\u0443\u0433\u043E\u043C\u0443"
  });
};
export default ProductOffersStub;