import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { AspOfferClaimArchivedMessage } from './archived';
import { AspOfferClaimPausedMessage } from './paused';
import { AspOfferClaimUnavailableMessage } from './unavailable';
import { AspOfferClaimUpcomingMessage } from './upcoming';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AspOfferClaimStateMessagesAdapter = () => {
  const {
    aspOffer,
    isActivationReceivedNow,
    isActivationAvailable
  } = useAspOfferClaimContext();
  return useMemo(() => {
    if (!aspOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return _jsx(AspOfferClaimUnavailableMessage, {});
      } else {
        return _jsxs(_Fragment, {
          children: [aspOffer.status === EOfferStatus.Upcoming && _jsx(AspOfferClaimUpcomingMessage, {
            startDate: aspOffer.startDate
          }), aspOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && _jsx(AspOfferClaimPausedMessage, {}), aspOffer.status === EOfferStatus.Archived && _jsx(AspOfferClaimArchivedMessage, {})]
        });
      }
    }
  }, [aspOffer, isActivationAvailable, isActivationReceivedNow]);
};