import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PartnerGrid = props => {
  const {
    columnsCount = 5,
    mdColumnsCount = 3,
    smColumnsCount = 2,
    xsColumnsCount = 2,
    gap = 2,
    children
  } = props;
  return _jsx(Wrapper, {
    columnsCount: columnsCount,
    mdColumnsCount: mdColumnsCount,
    smColumnsCount: smColumnsCount,
    xsColumnsCount: xsColumnsCount,
    gap: gap,
    children: children
  });
};