import HttpClient from 'data/network/http';
import { EHeaders } from 'data/network/types';
import Notifier from 'system/notifier';
export let interceptorId = null;
const httpClient = HttpClient.getInstance();
export const applyInterceptor = targetParamsEncoded => {
  if (!interceptorId && targetParamsEncoded) {
    interceptorId = httpClient.applyInterceptor(config => {
      const headers = config.headers ?? {};
      try {
        if (targetParamsEncoded) {
          headers[EHeaders.TargetValue] = targetParamsEncoded;
        }
      } catch (e) {
        Notifier.getInstance().addError('Ошибка при обработке параметров превью режима');
      }
      return config;
    }, e => {
      return Promise.reject(e);
    });
  }
};
export const clearInterceptor = () => {
  if (!interceptorId) {
    return;
  }
  httpClient.ejectInterceptor(interceptorId);
};