import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferClaimSubscriptionDisabled = _ref => {
  let {
    isFetching,
    onSubscribe
  } = _ref;
  return _jsxs(MPButton, {
    disabled: isFetching,
    fullWidth: true,
    size: "large",
    color: "primary",
    variant: "outlined",
    onClick: onSubscribe,
    children: [_jsx(Typography, {
      variant: "body1",
      children: "\u0423\u0432\u0435\u0434\u043E\u043C\u0438\u0442\u044C \u043E \u043F\u043E\u044F\u0432\u043B\u0435\u043D\u0438\u0438 \u043F\u043E \u044D\u043B. \u043F\u043E\u0447\u0442\u0435 "
    }), isFetching && _jsx(ContentLoader, {
      size: 15
    })]
  });
};
export default OfferClaimSubscriptionDisabled;