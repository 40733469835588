import { ChangeEmailContainer } from 'presentation/features/auth/changeEmail/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ChangeAdapter = _ref => {
  let {
    withSuccessStep,
    onSuccess,
    onClose
  } = _ref;
  return _jsx(ChangeEmailContainer, {
    withSuccessStep: withSuccessStep,
    onSuccess: onSuccess,
    onClose: onClose
  });
};