import { useTheme } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Logo = () => {
  const {
    logo
  } = useTheme();
  return _jsx("img", {
    src: logo,
    alt: ""
  });
};
export default Logo;