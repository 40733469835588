import { useGetProductOfferVariationsQuery } from 'data/api/productOffer';
import { ProductAttributeType } from 'domain/model/productOffer';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { getProductOfferDetailsRoute } from '../../entry';
import FileAttribute from './attribute/file';
import Attribute from './attribute/primitive';
import { LoaderWrapper, Wrapper } from './controls';
import AttributeGroup from './group';
import { AttributesGroupSection } from './group/controls';
import makeMergedAttributes from './utils/prepareModelData';
import makeGroupedByValueAttributes from './utils/prepareViewData';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
// const DebugDataView = ({ data }: { data: any }) => {
//   return <pre style={{ fontSize: '13px', lineHeight: '14px' }}>{JSON.stringify(data, null, 2)}</pre>;
// };

const ProductOfferAttributes = _ref => {
  let {
    productId,
    selectedVariant,
    deskId
  } = _ref;
  const {
    data: variants
  } = useGetProductOfferVariationsQuery({
    productDeskId: deskId
  });
  const history = useHistory();
  const [initialAttributesDone, setInitialAttributesDone] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const mergedAttributeVariants = useMemo(() => {
    if (!variants) return null;
    return makeMergedAttributes(variants);
  }, [variants]);
  const unitedByValueAttributes = useMemo(() => {
    if (!mergedAttributeVariants) {
      return null;
    }
    return makeGroupedByValueAttributes(mergedAttributeVariants);
  }, [mergedAttributeVariants]);

  // инициализация
  useEffect(() => {
    if (!unitedByValueAttributes) {
      return;
    }
    const currentVariantAttributes = unitedByValueAttributes.reduce((acc, ga) => {
      const value = ga.valuesGroup.find(vg => vg.productIds.includes(productId));
      if (value) return [...acc, value];
      // пропускаем если не найден productId в productIds
      return acc;
    }, []);

    // инициализируем первый атрибут
    setSelectedAttributes(currentVariantAttributes);
    setInitialAttributesDone(true);
  }, [unitedByValueAttributes, initialAttributesDone, productId]);
  if (!unitedByValueAttributes || !variants) {
    return _jsx(Wrapper, {
      children: _jsx(LoaderWrapper, {
        children: _jsx(ContentLoader, {
          size: '1.5rem'
        })
      })
    });
  }
  return _jsx(Wrapper, {
    children: unitedByValueAttributes.map((g, gIndex) => {
      const isFirst = gIndex === 0;
      // рендерим следующий элемент если предыдущий выбран, кроме файлов
      if (!isFirst && !selectedAttributes[gIndex - 1] && g.attribute.type !== ProductAttributeType.file) {
        return null;
      }
      return _jsx(AttributeGroup, {
        selectedVariant: selectedVariant,
        group: g,
        children: group => {
          return _jsxs(_Fragment, {
            children: [_jsx(AttributesGroupSection, {
              children: group.valuesGroup.filter(vg => vg.attribute.type !== ProductAttributeType.file).map(vg => {
                const isSelected = selectedAttributes?.find(sa => {
                  return sa.values?.[0].value === vg?.values?.[0].value;
                });
                const linkedVariants = variants.filter(v => {
                  return vg.productIds.includes(v.id);
                });
                const isEveryVariantsOutOfStock = linkedVariants.every(lv => {
                  return !lv.stock || lv.stock === 0;
                });
                const onClickAttribute = () => {
                  // const firstAttributes = selectedAttributes[0].productIds;
                  // убираем данный атрибут

                  const withoutCurrentGroupAttributes = selectedAttributes.filter(sa => sa.attribute.id !== group.attribute.id);

                  // get intersection
                  const filteredArray = withoutCurrentGroupAttributes.map(sa => sa.productIds)
                  // флатим, все равно даже если будет больше одного совпадения, массив будет состоять
                  // из одинаковых значений
                  .flat().filter(value => vg.productIds.includes(value));
                  history.replace(
                  // в случае если выбран такой второй атрибут, для которого нет пересечения с первым,
                  // то просто берем первый доступный вариант
                  getProductOfferDetailsRoute({
                    id: filteredArray[0] || vg.productIds[0]
                  }));
                };
                const previousAttribute = selectedAttributes[gIndex - 1];
                const prevAttributeConsistsAnyCurrentProductId = !isFirst ? vg.productIds.some(pi => {
                  return previousAttribute?.productIds.includes(pi);
                }) : true;
                return prevAttributeConsistsAnyCurrentProductId ? _jsx(Attribute, {
                  productIds: vg.productIds,
                  onClickAttribute: onClickAttribute,
                  selected: !!isSelected,
                  isOutOfStock: isEveryVariantsOutOfStock,
                  children: vg?.values?.[0].value
                }, vg?.values?.[0].value) : null;
              })
            }), _jsx(AttributesGroupSection, {
              children: group.valuesGroup.filter(vg => vg.attribute.type === ProductAttributeType.file && vg.values?.length).map(vg => {
                return _jsx(FileAttribute, {
                  label: vg.attribute.name,
                  href: vg.values?.[0]?.value ?? ''
                }, vg.attribute.id);
              })
            }), _jsx(Splitter, {
              size: 2
            })]
          });
        }
      }, g.attribute.id);
    })
  });
};
export default ProductOfferAttributes;