import { MPButton } from 'presentation/theme/ui-kit/button';
import React from 'react';
import { MPConfirmDialog } from '../../../../../../theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferDeliveryInfoDialog = _ref => {
  let {
    open,
    info,
    onClose
  } = _ref;
  return _jsx(MPConfirmDialog, {
    open: open,
    maxWidth: "xs",
    title: "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435",
    onClose: onClose,
    buttons: _jsx(MPButton, {
      size: "large",
      fullWidth: true,
      color: "brand",
      variant: "contained",
      onClick: onClose,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    }),
    children: info
  });
};
export default ProductOfferDeliveryInfoDialog;