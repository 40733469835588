import store from 'data/store/store';
import { EOfferListType, EOfferStatus, EOfferType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getOffersListQueryParams } from '../../../utils';
import { getTradeOffersSearchRoute } from '../../routes';
import { tradeOffersDefaultParams } from '../../utils';
import { tradeOfferListSelector } from '../store/selectors';
import { tradeOffersSetArgs, tradeOffersSetIsNewFetching, tradeOffersSetIsNewSearchFetching, tradeOffersSetPage, tradeOffersSetPageSize, tradeOffersSetSort, tradeOffersStartSession } from '../store/slice';
const emptyParams = tradeOffersDefaultParams;
const useTradeOfferListHandlers = props => {
  const {
    guid,
    name,
    categoryId,
    partnerId
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    webAnalytics
  } = useWebAnalytics();
  const getCurrentState = useCallback(() => {
    return tradeOfferListSelector(store.getState());
  }, []);
  const onChangeSort = useCallback(sort => {
    dispatch(tradeOffersSetIsNewFetching(false));
    dispatch(tradeOffersSetIsNewSearchFetching(false));
    dispatch(tradeOffersSetSort({
      type: EOfferListType.Common,
      sort
    }));
    dispatch(tradeOffersSetSort({
      type: EOfferListType.Upcoming,
      sort
    }));
  }, [dispatch]);
  const onChangePageSize = useCallback((type, pageSize) => {
    dispatch(tradeOffersSetIsNewFetching(false));
    dispatch(tradeOffersSetIsNewSearchFetching(false));
    dispatch(tradeOffersSetPageSize({
      type,
      pageSize
    }));
  }, [dispatch]);
  const onChangePage = useCallback((type, page) => {
    dispatch(tradeOffersSetIsNewFetching(false));
    dispatch(tradeOffersSetIsNewSearchFetching(false));
    dispatch(tradeOffersSetPage({
      type,
      page
    }));
  }, [dispatch]);
  const onChangeCategory = useCallback(category => {
    dispatch(tradeOffersSetIsNewFetching(true));
    dispatch(tradeOffersSetIsNewSearchFetching(false));
    history.push(getTradeOffersSearchRoute({
      name,
      categoryId: category.id,
      guid,
      partnerId: partnerId
    }));
  }, [dispatch, history, guid, name, partnerId]);
  const onReturnToTopCategory = useCallback(id => {
    dispatch(tradeOffersSetIsNewFetching(true));
    dispatch(tradeOffersSetIsNewSearchFetching(false));
    history.push(getTradeOffersSearchRoute({
      name,
      categoryId: id,
      guid,
      partnerId: partnerId
    }));
  }, [dispatch, history, guid, name, partnerId]);
  const onShowCard = useCallback(tradeOffer => {
    webAnalytics.offerShowInList(tradeOffer.id);
  }, [webAnalytics]);
  useEffect(() => {
    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentName = state.common.search?.name;
    const currentCategory = state.common.search?.categories?.[0];
    const currentPartnerId = state.common.search?.partnerId;
    dispatch(tradeOffersStartSession({
      guid
    }));
    const isGuidChanged = currentGuid !== guid;
    const isNameChanged = (currentName || null) !== (name || null);
    const isCategoryChanged = (currentCategory || null) !== (categoryId || null);
    const isPartnerIdChanged = (currentPartnerId || null) !== (partnerId || null);
    let commonArgs = state.common;
    let upcomingArgs = state.upcoming;
    if (isGuidChanged) {
      commonArgs = emptyParams;
      upcomingArgs = emptyParams;
    }
    dispatch(tradeOffersSetArgs({
      type: EOfferListType.Common,
      args: getOffersListQueryParams({
        name,
        categories: categoryId ? [categoryId] : null,
        partnerId,
        sort: commonArgs.sort,
        page: commonArgs.page,
        pageSize: commonArgs.pageSize
      }, EOfferType.Trade)
    }));
    dispatch(tradeOffersSetArgs({
      type: EOfferListType.Upcoming,
      args: getOffersListQueryParams({
        name,
        categories: categoryId ? [categoryId] : null,
        partnerId,
        sort: upcomingArgs.sort,
        page: upcomingArgs.page,
        pageSize: upcomingArgs.pageSize,
        statuses: [EOfferStatus.Upcoming]
      }, EOfferType.Trade)
    }));
    if (isGuidChanged || isCategoryChanged || isPartnerIdChanged) {
      dispatch(tradeOffersSetIsNewFetching(true));
    } else {
      dispatch(tradeOffersSetIsNewFetching(false));
    }
    if (isGuidChanged || isNameChanged) {
      dispatch(tradeOffersSetIsNewSearchFetching(true));
    } else {
      dispatch(tradeOffersSetIsNewSearchFetching(false));
    }
  }, [dispatch, categoryId, guid, name, getCurrentState, partnerId]);
  return {
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onChangeCategory,
    onReturnToTopCategory,
    onShowCard
  };
};
export default useTradeOfferListHandlers;