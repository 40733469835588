import { EOfferStatus } from '../../../../../../../domain/model/enums';
import OfferClaimHelp from '../../../../components/claim/help';
import useCorpOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const NdflAdapter = () => {
  const {
    corpOffer
  } = useCorpOfferClaimContext();
  const {
    openNdflHelpDialog
  } = useCorpOfferClaimDialogs();
  const isVisible = corpOffer?.status === EOfferStatus.Active;
  if (!corpOffer?.hasNdfl) {
    return null;
  }
  return isVisible ? _jsx(OfferClaimHelp, {
    text: 'Возможно, оплата НДФЛ',
    onClick: openNdflHelpDialog
  }) : null;
};
export default NdflAdapter;