import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { MPDialog } from 'presentation/theme/ui-kit/dialog/common';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPConfirmDialog = props => {
  const {
    title,
    text,
    buttons,
    children,
    ...others
  } = props;
  return _jsxs(MPDialog, {
    fullWidth: true,
    keepMounted: true,
    title: title,
    ...others,
    children: [(text || children) && _jsxs(DialogContent, {
      children: [text && _jsx(DialogContentText, {
        children: text
      }), children]
    }), buttons && _jsx(DialogActions, {
      children: buttons
    })]
  });
};