import { SideMenuWrapper } from 'presentation/components/common/wrappers/menu';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { CategoryLink } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Subcategories = _ref => {
  let {
    categories,
    onCategoryClick
  } = _ref;
  return _jsx(SideMenuWrapper, {
    elevation: 1,
    children: _jsx(MPGrid, {
      container: true,
      direction: "column",
      children: categories.map(c => _jsx(CategoryLink, {
        onCategoryClick: onCategoryClick,
        id: c.id,
        children: c.name
      }, c.id))
    })
  });
};
export default Subcategories;