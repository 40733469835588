import { Link, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useGetCmsCollectionQuery } from 'data/api/cms';
import { useGetPartnerDeskQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import CmsCollectionContainer from 'presentation/features/cms/collection/container';
import PartnerDeskHeader from 'presentation/features/partnerDesk/components/header';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import CmsCollectionSummaryLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = CmsCollectionSummaryLayout;

/**
 * фича полной информации о коллекции: информация о партнёре, состав коллекции
 */
const CmsCollectionSummaryContainer = _ref => {
  let {
    id,
    guid
  } = _ref;
  const history = useHistory();
  const {
    hasPrevIndependentLocation
  } = useHistoryExtensions();
  const {
    data: collection,
    isFetching: isCollectionFetching,
    error: collectionFetchError
  } = useGetCmsCollectionQuery({
    guid,
    id
  });
  const {
    data: partnerDesk,
    isFetching: isPartnerDeskFetching,
    error: partnerDeskFetchError
  } = useGetPartnerDeskQuery(collection?.partner?.id ?? '', {
    skip: !collection?.partner?.id
  });
  const header = useMemo(() => partnerDesk && _jsxs("div", {
    children: [_jsx(PartnerDeskHeader, {
      partnerDesk: partnerDesk
    }), _jsx(Splitter, {
      size: 3
    })]
  }), [partnerDesk]);
  const StubComponent = useMemo(() => _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    direction: "column",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "h3",
        component: "h1",
        children: "\u041A \u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0431\u043E\u043B\u044C\u0448\u0435 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B"
      })
    }), hasPrevIndependentLocation && _jsx(MPGrid, {
      item: true,
      children: _jsx(Link, {
        color: "primary",
        underline: "none",
        onClick: history.goBack,
        children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430\u0437\u0430\u0434"
      })
    })]
  }), [history, hasPrevIndependentLocation]);
  const isFetching = isCollectionFetching || isPartnerDeskFetching;
  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
    }
    if (collectionFetchError) {
      ErrorHandler.handleHttpError(collectionFetchError);
    }
  }, [collectionFetchError, partnerDeskFetchError]);
  return _jsx(Layout, {
    loader: isFetching && _jsx(ContentLoader, {}),
    children: _jsx(CmsCollectionContainer, {
      collectionId: id,
      guid: guid,
      header: header,
      stub: StubComponent
    })
  });
};
export default CmsCollectionSummaryContainer;