import AspOfferBreadcrumbs from '../../components/breadcrumbs';
import useAspOfferCategoriesData from '../hooks/useAspOfferCategoriesData';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferBreadcrumbAdapter = props => {
  const {
    id
  } = props;
  const {
    categories
  } = useAspOfferCategoriesData();
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  return _jsx(AspOfferBreadcrumbs, {
    offerCategories: aspOffer?.categories ?? null,
    dictionary: categories ?? null
  });
};