import { StyledSplitter } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** Сплиттер контента */
const Splitter = _ref => {
  let {
    size = 2,
    variant = 'horizontal',
    relative
  } = _ref;
  return _jsx(StyledSplitter, {
    size: size,
    variant: variant,
    relative: relative
  });
};
export default Splitter;