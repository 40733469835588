import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OfferPayButton = props => {
  const {
    isFetching,
    disabled,
    onPay
  } = props;
  return _jsxs(MPButton, {
    fullWidth: true,
    size: "large",
    color: "brand",
    disabled: disabled || isFetching,
    onClick: onPay,
    children: ["\u041A\u0443\u043F\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
  });
};