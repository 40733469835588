import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { PriceRange } from 'presentation/components/common/price';
import { MPSlider } from '../../../../theme/ui-kit/slider';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const defaultRange = {
  min: 0,
  max: 9999999
};
export const OfferFilterPrice = _ref => {
  let {
    isDisabled,
    min = defaultRange.min,
    max = defaultRange.max,
    hasDebounce = true,
    debounceTimeout = 500,
    value,
    value: {
      minPrice,
      maxPrice
    },
    onChange,
    ...other
  } = _ref;
  const [currentRange, setCurrentRange] = useState([minPrice ?? min, maxPrice ?? max]);
  const [currentMinPrice, currentMaxPrice] = currentRange;
  const isFixedRange = min + max + (minPrice ?? 0) + (maxPrice ?? 0) === min * 4;
  const handleChange = useCallback(_ref2 => {
    let {
      minPrice: updatedMin,
      maxPrice: updatedMax,
      from,
      to,
      valuePriority = false
    } = _ref2;
    const price = {
      minPrice: updatedMin,
      maxPrice: updatedMax
    };
    const isRangeEqual = from === to;
    const range = [...(valuePriority && typeof updatedMin === 'number' && updatedMax ? [updatedMin, updatedMax] : currentRange)];
    if (updatedMin < from) {
      price.minPrice = from;
      range[0] = price.minPrice;
    } else if (updatedMin > to - 1 && !isRangeEqual) {
      price.minPrice = to - 1;
      range[0] = price.minPrice;
    }
    if (updatedMax > to) {
      price.maxPrice = to;
      range[1] = price.maxPrice;
    } else if (updatedMax < from + 1 && !isRangeEqual) {
      price.maxPrice = from + 1;
      range[1] = price.maxPrice;
    }
    setCurrentRange(range);
    if (isFixedRange || price.minPrice === minPrice && price.maxPrice === maxPrice) {
      return;
    }
    onChange(price);
  }, [currentRange, isFixedRange, maxPrice, minPrice, onChange]);
  const debouncedChange = useMemo(() => hasDebounce ? debounce(handleChange, debounceTimeout) : handleChange, [hasDebounce, debounceTimeout, handleChange]);
  useEffect(() => {
    return () => {
      if (hasDebounce) {
        debouncedChange.cancel();
      }
    };
  }, [hasDebounce, debouncedChange]);
  useEffect(() => {
    if (minPrice === currentMinPrice && maxPrice === currentMaxPrice) {
      return;
    }
    setCurrentRange([minPrice ?? min, maxPrice ?? max]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice, maxPrice]);
  const updateDebouncedValues = function (value) {
    let valuePriority = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    // отдаёт наружу нормированное значение
    debouncedChange({
      minPrice: value[0],
      maxPrice: value[1],
      from: min,
      to: max,
      valuePriority
    });
  };
  const handleChangeSlider = (event, value) => {
    if (!Array.isArray(value) || isFixedRange) {
      return;
    }
    setCurrentRange(value);
  };
  const handleChangeInputs = (from, to) => {
    if (isFixedRange) {
      return;
    }
    setCurrentRange([from, to]);
  };
  const handleBLur = () => {
    if (isFixedRange) {
      return;
    }
    updateDebouncedValues(currentRange);
  };
  return _jsxs(Wrapper, {
    children: [_jsx(PriceRange, {
      isDisabled: isDisabled,
      valueFrom: currentMinPrice,
      valueTo: currentMaxPrice,
      onChange: handleChangeInputs,
      onBlur: handleBLur
    }), _jsx(MPSlider, {
      disabled: isDisabled,
      min: isFixedRange ? min - 1 : min,
      max: max,
      value: isFixedRange ? [min - 1, max] : currentRange,
      color: "brand",
      onChange: handleChangeSlider,
      onMouseUp: handleBLur,
      onChangeCommitted: handleBLur,
      ...other
    })]
  });
};