import { Typography } from '@mui/material';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const InActive = _jsxs(_Fragment, {
  children: [_jsx(Typography, {
    variant: "body1",
    children: "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0434\u0430\u043D\u043D\u044B\u0439 \u043E\u0431\u044A\u0435\u043A\u0442 \u0432\u0440\u0435\u043C\u0435\u043D\u043D\u043E \u043D\u0435 \u043E\u0431\u0441\u043B\u0443\u0436\u0438\u0432\u0430\u0435\u0442\u0441\u044F."
  }), _jsx(Typography, {
    variant: "body1",
    children: "\u0412\u044B \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0441\u043D\u043E\u0432\u0430 \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0443\u0441\u043B\u0443\u0433\u0430\u043C\u0438 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u043E\u0441\u043B\u0435 \u0432\u043E\u0437\u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F \u0435\u0433\u043E \u0440\u0430\u0431\u043E\u0442\u044B."
  })]
});
export default InActive;