import AppImage from 'presentation/components/common/images/common';
import { NoImageIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../components/common/icon';
import { Card, CardTitle, Stub, WrapperImage } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StubImage = () => _jsx(Stub, {
  children: _jsx(AppSvgIcon, {
    viewBox: "0 0 25 25",
    sx: {
      fontSize: 70
    },
    icon: NoImageIcon
  })
});
const PartnerDeskCard = _ref => {
  let {
    title,
    image,
    onClick
  } = _ref;
  return _jsxs(Card, {
    elevation: 0,
    onClick: onClick,
    children: [_jsx(WrapperImage, {
      children: image ? _jsx(AppImage, {
        src: image
      }) : _jsx(StubImage, {})
    }), _jsx(CardTitle, {
      variant: "body2",
      children: title
    })]
  });
};
export default PartnerDeskCard;