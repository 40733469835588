import { EOfferStatus, ETradeOfferSortType } from 'domain/model/enums';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ETradeOfferServicesTag } from './index';
const defaultTradeOfferActiveStatuses = [EOfferStatus.Active];
const defaultTradeOfferValidStatuses = [EOfferStatus.Active, EOfferStatus.Upcoming];
export const tradeOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getTradeOfferCategories: builder.query({
      transformResponse: response => response.data,
      query: _ref => {
        let {
          ids
        } = _ref;
        return apiDefinition.tradeOffer.categories({
          ids
        });
      }
    }),
    getTradeOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref2 => {
        let {
          name,
          onlyLeafCategories
        } = _ref2;
        return apiDefinition.user.trade.categoriesUsed({
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          onlyLeafCategories
        });
      }
    }),
    getTradeOfferCategoriesUsedTree: builder.query({
      transformResponse: response => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.trade.categoriesUsed({
          statuses: defaultTradeOfferValidStatuses,
          onlyLeafCategories: false
        });
      }
    }),
    getTradeOfferList: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          search,
          page,
          pageSize,
          sort = []
        } = _ref3;
        const {
          name,
          categories,
          statuses = defaultTradeOfferActiveStatuses,
          partnerId
        } = search;
        return apiDefinition.user.trade.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId
        });
      },
      providesTags: [ETradeOfferServicesTag.List]
    }),
    getTradeOffersCount: builder.query({
      transformResponse: response => {
        return response.data[0]?.count ?? 0;
      },
      query: _ref4 => {
        let {
          search
        } = _ref4;
        const {
          name,
          categories
        } = search;
        return apiDefinition.user.trade.count({
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          categories
        });
      }
    }),
    getTradeOfferSearch: builder.query({
      keepUnusedDataFor: 0,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref5 => {
        let {
          search,
          pageSize
        } = _ref5;
        const {
          name
        } = search;
        return apiDefinition.user.trade.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultTradeOfferValidStatuses,
          sort: [ETradeOfferSortType.Default]
        });
      }
    }),
    getTradeOfferDetails: builder.query({
      query: _ref6 => {
        let {
          id
        } = _ref6;
        return apiDefinition.user.trade.one({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: (result, error, args) => [{
        type: ETradeOfferServicesTag.Details,
        id: args.id
      }, ETradeOfferServicesTag.Details]
    })
  })
});
export const {
  useGetTradeOfferListQuery,
  useGetTradeOffersCountQuery,
  useGetTradeOfferSearchQuery,
  useGetTradeOfferCategoriesQuery,
  useGetTradeOfferCategoriesUsedQuery,
  useGetTradeOfferCategoriesUsedTreeQuery,
  useGetTradeOfferDetailsQuery
} = tradeOfferApi;