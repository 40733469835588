import AppProviders from 'appProviders';
import 'browser';
import SentryClient from 'integration/sentry/client';
import { locale } from 'locale';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import AppConfiguration from 'presentation/features/general/config';
import { WebAnalyticsConfigurator } from 'presentation/features/webAnalytics';
import { EAppFeature } from 'presentation/types';
import { createRoot } from 'react-dom/client';
import { AppConfigurator } from 'system/appConfigurator';
import App from './app/app';
import reportWebVitals from './reportWebVitals';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const appConfigurator = AppConfigurator.getInstance();
SentryClient.getInstance().init(appConfigurator.getOptions().sentry);

// Если включена аналитика то вызываем конфигуратор
if (appConfigurator.hasFeature(EAppFeature.WebAnalytics)) {
  WebAnalyticsConfigurator.getInstance();
}
moment.locale(locale.short);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(AppConfiguration, {
  children: _jsx(AppProviders, {
    children: _jsx(App, {})
  })
}));
reportWebVitals();