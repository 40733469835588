import { ChangeCityContext } from './types';
import { useContext, useState } from 'react';
import useUserLocalSettings from 'hooks/useUserLocalSettings';
import { AddressHelper } from 'utils/address';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ChangeCityEventWrapper = _ref => {
  let {
    children
  } = _ref;
  const {
    setCity
  } = useUserLocalSettings();
  const [city, setLocalCity] = useState(null);
  const onSaveValue = value => {
    setCity(value.id, new AddressHelper(value).getLocalitySimpleName());
  };
  const value = {
    value: city,
    onChangeValue: setLocalCity,
    onSaveValue
  };
  return _jsx(ChangeCityContext.Provider, {
    value: value,
    children: children
  });
};
export const useWrapper = () => useContext(ChangeCityContext);