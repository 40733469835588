import { UnitsIcon } from '../../../../../media/icons';
import { toCurrency } from '../../../../../utils/currency';
import { OriginalPrice, Price, PriceWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferListItemPrice = props => {
  const {
    price,
    originalPrice
  } = props;
  const isOriginalPriceVisible = originalPrice !== null && originalPrice > price;
  return _jsxs(PriceWrapper, {
    children: [_jsx(UnitsIcon, {}), _jsx(Price, {
      variant: "h2",
      component: "div",
      children: toCurrency(price)
    }), isOriginalPriceVisible && _jsx(OriginalPrice, {
      variant: "body2",
      color: "textSecondary",
      children: toCurrency(originalPrice)
    })]
  });
};
export default CorpOfferListItemPrice;