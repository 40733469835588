import { createSlice } from '@reduxjs/toolkit';
import { EOfferType } from 'domain/model/enums';
const setupCollection = (collection, offerType, payload) => {
  const newCollection = {
    ...collection
  };
  const collectionTyped = [...collection[offerType]];
  const existedIndex = collectionTyped.findIndex(c => c.id === payload.id);
  if (existedIndex < 0) {
    collectionTyped.push({
      ...payload
    });
  } else {
    collectionTyped.splice(existedIndex, 1, {
      ...collectionTyped[existedIndex],
      ...payload
    });
  }
  newCollection[offerType] = [...collectionTyped];
  return newCollection;
};
const slice = createSlice({
  name: 'collection',
  initialState: {
    collections: []
  },
  reducers: {
    collectionSetState: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid,
        ...offerPayload
      } = payload;
      const collectionIndex = state.collections.findIndex(item => item.guid === guid);
      if (collectionIndex < 0) {
        const newCollection = {
          guid,
          [EOfferType.Corp]: [],
          [EOfferType.Trade]: []
        };
        newCollection[payload.offerType].push(offerPayload);
        state.collections.push(newCollection);
      } else {
        const collections = [...state.collections];
        const newCollection = setupCollection(collections[collectionIndex], payload.offerType, offerPayload);
        collections.splice(collectionIndex, 1, newCollection);
        state.collections = collections;
      }
    }
  }
});
export const {
  collectionSetState
} = slice.actions;
export default slice.reducer;