import { useGetCmsCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import { ECmsCollectionLinkObjectType } from 'domain/model/enums';
import React, { useEffect } from 'react';
import { CmsCollectionLayout } from '../layouts';
import { ECmsCollectionPlace } from '../types';
import { CmsCorpOfferCollectionCommonAdapter } from './adapters/corpOffersCommon';
import { CmsCorpOfferCollectionSelfAdapter } from './adapters/corpOffersSelf';
import { CmsTradeOfferCollectionCommonAdapter } from './adapters/tradeOffersCommon';
import { CmsTradeOfferCollectionSelfAdapter } from './adapters/tradeOffersSelf';
import { CmsPartnerCollection } from './partners';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/**
 * фича отображения состава коллекции
 */
const CmsCollectionContainer = _ref => {
  let {
    collectionId,
    header,
    footer,
    guid,
    stub,
    place,
    onDataLoaded
  } = _ref;
  const {
    data: collection,
    error
  } = useGetCmsCollectionQuery({
    guid,
    id: collectionId
  });
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  if (!collection) return null;
  const TradeOffersAdapter = place === ECmsCollectionPlace.Self ? CmsTradeOfferCollectionSelfAdapter : CmsTradeOfferCollectionCommonAdapter;
  const CorpOffersAdapter = place === ECmsCollectionPlace.Self ? CmsCorpOfferCollectionSelfAdapter : CmsCorpOfferCollectionCommonAdapter;
  return _jsxs(_Fragment, {
    children: [collection.linkObjectType === ECmsCollectionLinkObjectType.TradeOffer && _jsx(TradeOffersAdapter, {
      id: collection.id,
      guid: guid,
      layout: CmsCollectionLayout,
      header: header,
      footer: footer,
      stub: stub,
      onDataLoaded: onDataLoaded
    }), collection.linkObjectType === ECmsCollectionLinkObjectType.CorpOffer && _jsx(CorpOffersAdapter, {
      id: collection.id,
      guid: guid,
      layout: CmsCollectionLayout,
      header: header,
      footer: footer,
      stub: stub,
      onDataLoaded: onDataLoaded
    }), collection.linkObjectType === ECmsCollectionLinkObjectType.Partner && _jsx(CmsPartnerCollection, {
      id: collection.id,
      guid: guid,
      layout: CmsCollectionLayout,
      header: header,
      footer: footer,
      onDataLoaded: onDataLoaded
    })]
  });
};
export default CmsCollectionContainer;