import CheckIcon from '@mui/icons-material/Check';
import { Fade, Typography } from '@mui/material';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import { ContentWrapper, ControlButtons, Footer, Header, HeaderInfo, ListOrders } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const LastOrdersLayout = props => {
  const {
    title,
    description,
    buttons,
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      children: _jsxs(ContentWrapper, {
        children: [_jsxs(Header, {
          children: [_jsx(Typography, {
            variant: "h3",
            children: title
          }), _jsx(CheckIcon, {
            color: "success"
          })]
        }), _jsx(HeaderInfo, {
          variant: "body1",
          children: description
        }), _jsx(ListOrders, {
          children: children
        }), _jsx(Footer, {
          children: _jsx(ControlButtons, {
            children: buttons
          })
        })]
      })
    })
  });
};
export default LastOrdersLayout;