import CommonHelpDialog from './common';
import { dialogContent } from './constants';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferHelpDialog = _ref => {
  let {
    promotionType,
    open,
    onClose
  } = _ref;
  return _jsx(CommonHelpDialog, {
    open: open,
    title: dialogContent[promotionType].title,
    text: dialogContent[promotionType].text,
    onClose: onClose
  });
};
export default AspOfferHelpDialog;