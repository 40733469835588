import { useEmailEditQuery } from '../../../../hooks/useEmailEditQuery';
import ChangeEmailSuccessDialog from '../successDialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserSuccessEmailUpdateAdapter = props => {
  const {
    onNext
  } = props;
  const [, {
    data: emailEditData
  }] = useEmailEditQuery();
  const email = emailEditData?.newEmail ?? '';
  return _jsx(ChangeEmailSuccessDialog, {
    open: true,
    email: email,
    onClose: onNext
  });
};