import AppImageLazy from '../../../../components/common/images/lazy';
import { CardActions, CardColumn, CardContent, CardDescription, CardDetails, CardFavoriteButtonWrapper, CardHeader, CardRow, CoverImageViewWrapper } from './controls';
import OfferTags from '../tags';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OfferListItem = props => {
  const {
    images,
    header,
    description,
    info,
    favorite,
    direction = 'column',
    children,
    tags
  } = props;
  const Card = direction === 'column' ? CardColumn : CardRow;
  return _jsxs(Card, {
    elevation: 0,
    children: [_jsxs(CoverImageViewWrapper, {
      children: [_jsx(AppImageLazy, {
        src: images?.[0]?.path ?? ''
      }), favorite && _jsx(CardFavoriteButtonWrapper, {
        children: favorite
      }), tags && _jsx(OfferTags, {
        tags: tags
      })]
    }), _jsxs(CardDetails, {
      children: [_jsxs(CardContent, {
        children: [header && _jsx(CardHeader, {
          children: header
        }), typeof description === 'string' ? _jsx(CardDescription, {
          variant: "body2",
          children: description
        }) : _jsx(_Fragment, {
          children: description
        }), children]
      }), info && _jsx(CardActions, {
        children: info
      })]
    })]
  });
};
export default OfferListItem;
export { LogoWrapper } from './controls';