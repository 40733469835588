import { Card, Content, Title, Wrapper } from './controls';
import { Typography } from '@mui/material';
import AppSvgIcon from 'components/common/icon';
import { UnitsIcon } from 'media/icons';
import { ContentLimiter } from 'components/common/contentLimit';
import { ContentLimiterTooltipLayout } from 'components/common/contentLimit/layouts';
import { formatDateBenefitTransactions, getLabelUserBTTransactionLabel, getAmountByTypeTransaction } from '../../../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BenefitTransactionItem = props => {
  const {
    data,
    isGroup
  } = props;
  const label = getLabelUserBTTransactionLabel(data.type, !!data.isExpiration);
  const labelDate = formatDateBenefitTransactions(data.date);
  const amount = getAmountByTypeTransaction(data.type, data.amount);
  return _jsxs(Wrapper, {
    children: [!isGroup && _jsx(Typography, {
      variant: 'h3',
      children: labelDate
    }), _jsxs(Card, {
      children: [_jsx(AppSvgIcon, {
        icon: UnitsIcon,
        fontSize: 'large'
      }), _jsxs(Content, {
        children: [_jsxs(Title, {
          variant: 'subtitle1',
          children: [_jsx("span", {
            children: label
          }), " ", _jsx("span", {
            children: amount
          })]
        }), data.name && _jsx(ContentLimiter, {
          layout: ContentLimiterTooltipLayout,
          children: data.name
        })]
      })]
    })]
  });
};