import AvatarEditor from 'react-avatar-editor';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPAvatarEditor = _ref => {
  let {
    editorRef,
    ...props
  } = _ref;
  return _jsx(AvatarEditor, {
    ref: editorRef,
    ...props
  });
};