import rootRouting from '../../../../../../routing';
import { HomeSimpleIcon } from '../../../../../media/icons';
import HeaderOption from '../../option';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HomepageOption = props => {
  return _jsx(HeaderOption, {
    icon: HomeSimpleIcon,
    to: rootRouting.root,
    ...props
  });
};
export default HomepageOption;