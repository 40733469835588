import { Typography } from '@mui/material';
import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { EOfferActivateError } from 'domain/model/enums';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback, useEffect, useState } from 'react';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import { getPersonalPromotionInstrumentalLabel, getTradeOfferPromotionLabel } from '../../../utils';
import TradeOfferHelpDialog from '../../components/dialogs/help';
import useTradeOfferClaimContext from '../provider/useTradeOfferClaimContext';
import { ETradeOfferClaimDialogTag } from '../types';
import TradeOfferClaimDialogsContext from './context';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ConfirmDialogButtons = _ref => {
  let {
    onAccept,
    onReject
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    children: [_jsx(MPGrid, {
      item: true,
      xs: true,
      children: _jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: onAccept,
        children: "\u0414\u0430"
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(MPButton, {
        color: "brand",
        size: "large",
        onClick: onReject,
        variant: "outlined",
        children: "\u041D\u0435\u0442"
      })
    })]
  });
};
const TradeOfferClaimDialogsProvider = props => {
  const {
    children,
    tradeOffer,
    onShowAllOffers
  } = props;
  const {
    activation,
    stopTimer,
    onActivate
  } = useTradeOfferClaimContext();
  const {
    publish
  } = useSystemEventBus();
  const [reactivationDialogVisible, setReactivationDialogVisible] = useState(false);
  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog
  } = useDialogInHistory({
    tag: ETradeOfferClaimDialogTag.Help
  });
  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog
  } = useDialogInHistory({
    tag: ETradeOfferClaimDialogTag.Unavailable
  });
  const label = getTradeOfferPromotionLabel(tradeOffer.promotionType);
  const instrumentalLabel = getPersonalPromotionInstrumentalLabel(tradeOffer.promotionType);
  const openReactivationDialog = useCallback(() => setReactivationDialogVisible(true), [setReactivationDialogVisible]);
  const onActivateInternal = useCallback(() => {
    setReactivationDialogVisible(false);
    stopTimer();
    onActivate();
  }, [stopTimer, onActivate]);
  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      publish(createEventNeedFillProfile({
        message: activation.activationError.message
      }));
    }
  }, [activation.activationError, publish]);
  const value = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openReactivationDialog,
    openHelpDialog: onOpenHelpDialog,
    onActivate,
    onShowAllOffers
  };
  return _jsxs(TradeOfferClaimDialogsContext.Provider, {
    value: value,
    children: [children, _jsx(OfferUnavailableDialog, {
      open: isUnavailableDialogOpened,
      onShowOtherOffers: onShowAllOffers,
      onClose: onCloseUnavailableDialog
    }), _jsx(TradeOfferHelpDialog, {
      promotionType: tradeOffer.promotionType,
      open: isHelpDialogOpened,
      onClose: onCloseHelpDialog
    }), _jsx(MPConfirmDialog, {
      maxWidth: "xs",
      title: `Получение нового ${instrumentalLabel.toLowerCase()}`,
      open: reactivationDialogVisible,
      onClose: () => setReactivationDialogVisible(false),
      buttons: _jsx(ConfirmDialogButtons, {
        onAccept: onActivateInternal,
        onReject: () => setReactivationDialogVisible(false)
      }),
      children: _jsxs(Typography, {
        color: "textPrimary",
        component: "span",
        children: ["\u0423 \u0432\u0430\u0441 \u0435\u0441\u0442\u044C ", label.toLowerCase(), ". \u0425\u043E\u0442\u0438\u0442\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439?"]
      })
    })]
  });
};
export default TradeOfferClaimDialogsProvider;