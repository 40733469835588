import CorpOfferClaim from './component';
import CorpOfferClaimProviders from './providers';
import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { useHistory } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferClaimContainer = props => {
  const {
    corpOffer
  } = props;
  const history = useHistory();
  const onShowAllOffers = () => {
    if (corpOffer) {
      history.push(getPartnerDeskDetailsRoute({
        id: corpOffer.partner.id,
        tab: EPartnerDeskDetailsTab.CorpOffers
      }));
    }
  };
  return _jsx(CorpOfferClaimProviders, {
    corpOffer: corpOffer,
    onShowAllOffers: onShowAllOffers,
    children: _jsx(CorpOfferClaim, {})
  });
};
export default CorpOfferClaimContainer;