import moment from 'moment/moment';
const disabledObject = {
  disabled: true
};

/**
 * Возвращает функцию mapDays для календаря в зависимости от текущих ограничений.
 * Не учитывает одновременно положительные limitPastSelection и limitFutureSelection.
 */
export const useLimitedDays = _ref => {
  let {
    limitPastSelection,
    limitFutureSelection
  } = _ref;
  const today = limitPastSelection ? moment().startOf('day') : moment().endOf('day');
  const hasLimitedSelection = limitPastSelection || limitFutureSelection;
  return hasLimitedSelection ? _ref2 => {
    let {
      date
    } = _ref2;
    const day = moment(date.toDate());
    const compareDay = limitPastSelection ? day.endOf('day') : day.startOf('day');
    const isDisabled = limitPastSelection ? compareDay.isBefore(today) : compareDay.isAfter(today);
    if (isDisabled) {
      return disabledObject;
    }
    return undefined;
  } : undefined;
};