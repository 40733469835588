import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EOfferType } from 'domain/model/enums';
import { createFavoriteProvideHasChangesSelector } from 'presentation/features/favorite/provide/store/selectors';
import { favoritesProvideClear } from 'presentation/features/favorite/provide/store/slice';
import { getUserFavoritesFirstActiveTab, getUserFavoritesTabList } from '../utils';
import { useUserFavoritesTabsData } from './useTabsData';
export const useUserFavoritesTabs = props => {
  const {
    guid,
    tab,
    isCorpUser
  } = props;
  const dispatch = useDispatch();
  const {
    isFetching,
    tradeOffersCount,
    corpOffersCount,
    productOffersCount,
    bookingOffersCount
  } = useUserFavoritesTabsData();
  const hasTradeOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Trade));
  const hasCorpOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Corp));
  const hasProductOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Product));
  const hasBookingOffersChanges = useSelector(createFavoriteProvideHasChangesSelector(guid, EOfferType.Booking));
  const tabList = useMemo(() => getUserFavoritesTabList({
    isFetching,
    isCorpUser,
    hasTradeOffersChanges,
    hasCorpOffersChanges,
    hasProductOffersChanges,
    hasBookingOffersChanges,
    tradeOffersCount,
    corpOffersCount,
    productOffersCount,
    bookingOffersCount
  }), [isFetching, isCorpUser, hasTradeOffersChanges, hasCorpOffersChanges, hasProductOffersChanges, hasBookingOffersChanges, tradeOffersCount, corpOffersCount, productOffersCount, bookingOffersCount]);
  const tabToDisplay = useMemo(() => getUserFavoritesFirstActiveTab(tab, tabList), [tabList, tab]);
  useEffect(() => {
    return () => {
      dispatch(favoritesProvideClear({
        guid
      }));
    };
  }, [dispatch, guid]);
  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    productOffersCount,
    bookingOffersCount,
    tabToDisplay,
    tabList
  };
};