import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { userApi } from 'data/api/user';
export const getUserFavoritesTradeOffersIsFetchingSelector = guid => store => userApi.endpoints.getUserFavoritesTradeOffers.select({
  guid
})(store).status === QueryStatus.pending;
export const getUserFavoritesCorpOffersIsFetchingSelector = guid => store => userApi.endpoints.getUserFavoritesCorpOffers.select({
  guid
})(store).status === QueryStatus.pending;
export const getUserFavoritesProductOffersIsFetchingSelector = guid => store => userApi.endpoints.getUserFavoritesProductOffers.select({
  guid
})(store).status === QueryStatus.pending;
export const getUserFavoritesBookingsOffersIsFetchingSelector = guid => store => userApi.endpoints.getUserFavoritesBookingsOffers.select({
  guid
})(store).status === QueryStatus.pending;
export const getUserFavoritesAllOffersIsFetchingSelector = guid => store => userApi.endpoints.getUserFavoritesOffers.select({
  guid
})(store).status === QueryStatus.pending;
export const getUserFavoritesOffersIsFetchingSelector = guid => createSelector(getUserFavoritesTradeOffersIsFetchingSelector(guid), getUserFavoritesCorpOffersIsFetchingSelector(guid), getUserFavoritesProductOffersIsFetchingSelector(guid), getUserFavoritesBookingsOffersIsFetchingSelector(guid), getUserFavoritesAllOffersIsFetchingSelector(guid), (tradeOffersIsFetching, corpOffersIsFetching, productOffersIsFetching, bookingOffersIsFetching, allOffersIsFetching) => {
  return tradeOffersIsFetching || corpOffersIsFetching || productOffersIsFetching || bookingOffersIsFetching || allOffersIsFetching;
});

/** @deprecated не оптимален */
export const getUserFavoritesSelector = () => store => {
  const request = userApi.endpoints.getUserFavorites.select()(store);
  return request.data;
};
export const userFavoritesSelector = store => {
  const request = userApi.endpoints.getUserFavorites.select()(store);
  return request.data;
};
export const createUserIsOfferFavoriteSelector = createSelector(userFavoritesSelector, (store, offerId) => offerId, (store, offerId, offerType) => offerType, (favorites, offerId, offerType) => {
  return !!favorites?.[offerType]?.find(f => f === offerId);
});
export const userIsOfferFavoriteSelector = (offerId, offerType) => store => createUserIsOfferFavoriteSelector(store, offerId, offerType);