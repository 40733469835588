import { Hidden, Typography, useMediaQuery } from '@mui/material';
import Splitter from '../../../../components/common/splitter';
import useGridSpacing from '../../../../theme/hooks/useGridSpacing';
import { MPGrid } from '../../../../theme/ui-kit/grid';
import { BottomSection, Wrapper as StyledWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartSidebarLayout = props => {
  const {
    title,
    discount,
    cost,
    createOrdersButton,
    help,
    children
  } = props;
  const {
    x3
  } = useGridSpacing();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const Wrapper = isMdUp ? StyledWrapper : BottomSection;
  return _jsxs(Wrapper, {
    children: [_jsxs(MPGrid, {
      container: true,
      spacing: x3,
      children: [_jsxs(MPGrid, {
        item: true,
        xs: 5,
        md: 12,
        children: [_jsx(Typography, {
          children: title
        }), _jsx(Hidden, {
          mdDown: true,
          children: _jsx(Splitter, {
            size: x3
          })
        }), isSmUp && _jsxs(_Fragment, {
          children: [_jsx(Typography, {
            color: "textSecondary",
            children: discount
          }), _jsx(Splitter, {
            size: 1
          })]
        }), _jsx(Typography, {
          variant: "h3",
          children: cost
        })]
      }), _jsxs(MPGrid, {
        item: true,
        xs: 7,
        md: 12,
        children: [createOrdersButton, isSmUp && _jsxs(_Fragment, {
          children: [_jsx(Splitter, {
            size: 1
          }), help]
        })]
      })]
    }), children]
  });
};
export default CartSidebarLayout;