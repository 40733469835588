import { combineReducers } from '@reduxjs/toolkit';
import relatedList from 'presentation/features/offer/asp/relatedList/store/slice';
import details from './details/store/slice';
import list from './list/store/slice';
import claim from './claim/store/slice';
export default combineReducers({
  list,
  relatedList,
  details,
  claim
});