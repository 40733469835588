import { LocationIcon } from '../../../media/icons';
import { MPLink } from '../../../theme/ui-kit/link';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserLocation = props => {
  const {
    location,
    icon,
    onClick
  } = props;
  return _jsx(Wrapper, {
    onClick: onClick,
    children: _jsx(MPLink, {
      variant: "body2",
      color: "textSecondary",
      underline: "none",
      startIcon: icon ?? LocationIcon,
      children: location
    })
  });
};
export default UserLocation;