import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import ClaimMessage from 'presentation/features/offer/components/claim/message';
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferClaimUpcomingMessage = props => {
  const {
    startDate
  } = props;
  return _jsx(ClaimMessage, {
    type: "info",
    children: _jsxs(Typography, {
      variant: "body2",
      color: "black",
      children: ["\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", startDate && _jsxs(_Fragment, {
        children: ["\u0441 ", moment(startDate).format(EDateFormat.Human), " \u0433\u043E\u0434\u0430"]
      })]
    })
  });
};