import { useGetPartnerAspOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { partnerDeskDetailsAspOffersIsNewFetchingSelector } from 'presentation/features/partnerDesk/details/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import OfferListItemContainer from '../../../../offer/listItem/container';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskAspOffersDataContainer = props => {
  const {
    type,
    guid,
    args,
    header,
    onChangePage,
    onShowCard
  } = props;
  const {
    isAuthenticated
  } = useAuth();
  const isNewFetching = useSelector(partnerDeskDetailsAspOffersIsNewFetchingSelector);
  const {
    page,
    partnerId
  } = args;
  const {
    data,
    error,
    isSuccess,
    isFetching
  } = useGetPartnerAspOfferListQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const aspOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasOffers = !!aspOffers && aspOffers.length > 0;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  return _jsxs("div", {
    children: [header, _jsx("div", {
      children: _jsx(CardPageableList, {
        totalCount: totalCount,
        onLoadMore: onLoadMore,
        children: aspOffers.map(aspOffer => _jsx(InView, {
          active: isSuccess && !isFetching,
          sessionKey: sessionKey,
          data: aspOffer,
          onShow: onShowCard,
          children: _jsx(OfferListItemContainer, {
            offer: aspOffer,
            favorite: isAuthenticated && {
              guid,
              partnerId
            }
          }, aspOffer.id)
        }, aspOffer.id))
      })
    })]
  });
};
export default PartnerDeskAspOffersDataContainer;