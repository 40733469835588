import { Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { MPTab } from '../../../../theme/ui-kit/tab';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const LandingLink = _ref => {
  let {
    label,
    ...props
  } = _ref;
  const isDownXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return _jsx(MPTab, {
    component: RouterLink,
    ...props,
    size: 'small',
    iconPosition: "start",
    label: _jsx(Typography, {
      variant: isDownXs ? 'body2' : 'body1',
      children: label
    })
  });
};
export default LandingLink;