import { Typography } from '@mui/material';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { useEffect, useRef } from 'react';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CategorySelectLeaf = _ref => {
  let {
    category,
    selected,
    scrollToCategory,
    onClick
  } = _ref;
  const selectedRef = useRef(null);
  useEffect(() => {
    if (selectedRef?.current) {
      scrollToCategory?.(selectedRef.current);
    }
  }, [scrollToCategory]);
  return _jsx(Wrapper, {
    underline: "none",
    color: "text.primary",
    onClick: onClick ? () => onClick(category) : undefined,
    children: _jsx(Typography, {
      ref: selected ? selectedRef : null,
      component: "span",
      color: selected ? 'primary' : 'inherit',
      children: category.name
    })
  });
};
export default CategorySelectLeaf;