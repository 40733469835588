import { LogoWrapper } from '../../../components/listItem';
import { InfoWrapper } from './controls';
import { AddressHelper } from '../../../../../../utils/address';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';
import { LogoImageView } from 'components/common/images/cover';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingListItemInfo = props => {
  const {
    address,
    partner
  } = props;
  const logo = partner?.logo;
  return _jsxs(InfoWrapper, {
    children: [address && _jsx(CardCaption, {
      variant: "body2",
      color: "textSecondary",
      children: new AddressHelper(address).getLocalitySimpleName()
    }), logo?.path && _jsx(LogoWrapper, {
      children: _jsx(LogoImageView, {
        id: logo.path
      })
    })]
  });
};
export default BookingListItemInfo;