import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import useTechConfig from '../../../hooks/useTechConfig';
import { EAppFeature } from '../../../types';
import AboutPersonalDataAgreementPublicLink from '../components/personalDataAgreementLink/public';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutFeedBack = props => {
  const {
    data,
    isSending,
    validation,
    onChangeAttribute
  } = props;
  const {
    hasFeature
  } = useTechConfig();
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    direction: "column",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "body2",
        children: "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u043D\u0430\u043C, \u0435\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 \u0435\u0441\u0442\u044C \u0447\u0435\u043C \u043F\u043E\u0434\u0435\u043B\u0438\u0442\u044C\u0441\u044F. \u042D\u0442\u043E \u0441\u0434\u0435\u043B\u0430\u0435\u0442 \u043D\u0430\u0441 \u043B\u0443\u0447\u0448\u0435"
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(MPFormInput, {
        label: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F \u0438 \u0438\u043C\u044F",
        value: data.name ?? '',
        error: !!validation?.name?.hasError,
        helperText: validation?.name?.message,
        disabled: isSending,
        onChange: event => onChangeAttribute('name', event.target.value)
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(MPFormInput, {
        label: "\u042D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430\u044F \u043F\u043E\u0447\u0442\u0430",
        value: data.email ?? '',
        error: !!validation?.email?.hasError,
        helperText: validation?.email?.message,
        disabled: isSending,
        onChange: event => onChangeAttribute('email', event.target.value)
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(MPFormInput, {
        multiline: true,
        inputProps: {
          maxLength: 1000
        },
        rows: 5,
        label: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435",
        value: data.message ?? '',
        error: !!validation?.message?.hasError,
        helperText: validation?.message?.message,
        disabled: isSending,
        onChange: event => onChangeAttribute('message', event.target.value)
      })
    }), hasFeature(EAppFeature.PersonalDataAgreement) && _jsx(MPGrid, {
      item: true,
      children: _jsxs(Typography, {
        variant: "caption",
        children: ["\u041D\u0430\u0436\u0438\u043C\u0430\u044F \u043A\u043D\u043E\u043F\u043A\u0443 \xAB\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C\xBB, \u044F \u0434\u0430\u044E \u0441\u0432\u043E\u0435 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043C\u043E\u0438\u0445 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u0432 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0438 \u0441 \u0424\u0435\u0434\u0435\u0440\u0430\u043B\u044C\u043D\u044B\u043C \u0437\u0430\u043A\u043E\u043D\u043E\u043C \u043E\u0442 27.07.2006 \u0433. \u2116152-\u0424\u0417 \xAB\u041E \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445\xBB, \u043D\u0430 \u0443\u0441\u043B\u043E\u0432\u0438\u044F\u0445 \u0438 \u0434\u043B\u044F \u0446\u0435\u043B\u0435\u0439, \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u043D\u044B\u0445 \u0432", ' ', _jsx(AboutPersonalDataAgreementPublicLink, {
          color: "primary",
          underline: "hover",
          children: "\u0421\u043E\u0433\u043B\u0430\u0441\u0438\u0438 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445"
        })]
      })
    })]
  });
};
export default AboutFeedBack;