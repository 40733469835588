import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo, useState } from 'react';
import HorizontalScroller, { HorizontalScrollerItem } from '../../horizontalScroll';
import AppSvgIcon from '../../icon';
import { BreadcrumbChain } from './chain';
import { BreadcrumbCollapse } from './controls';
import { BreadcrumbExpandChipContent } from './expandChip/content';
import { BreadcrumbItem } from './item';
import { breadcrumbTreeExpandTitle } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BreadcrumbTree = props => {
  const {
    items,
    title
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [breadcrumbExpand, setBreadcrumbExpand] = useState(false);
  const onExpandSet = () => setBreadcrumbExpand(!breadcrumbExpand);
  const expandCount = items.length - 1;
  const expandTitle = breadcrumbTreeExpandTitle(expandCount);
  const isExpandable = items.length > 1;
  const iconComponent = useMemo(() => {
    return breadcrumbExpand ? _jsx(AppSvgIcon, {
      fontSize: "micro",
      icon: ExpandLess
    }) : _jsx(AppSvgIcon, {
      fontSize: "micro",
      icon: ExpandMore
    });
  }, [breadcrumbExpand]);
  if (items.length === 0) {
    console.error('Breadcrumb items is empty');
    return null;
  }
  return _jsxs(HorizontalScroller, {
    gap: 8,
    children: [title && _jsx(HorizontalScrollerItem, {
      children: _jsx(BreadcrumbItem, {
        name: title
      })
    }, 'title'), _jsx(HorizontalScrollerItem, {
      children: _jsxs(MPGrid, {
        container: true,
        item: true,
        spacing: 1,
        wrap: "nowrap",
        direction: "column",
        children: [_jsxs(MPGrid, {
          item: true,
          container: true,
          spacing: 0.5,
          alignItems: "center",
          children: [_jsx(MPGrid, {
            item: true,
            children: items[0] && _jsx(BreadcrumbChain, {
              items: items[0]
            })
          }), isExpandable && _jsx(MPGrid, {
            item: true,
            children: _jsx(BreadcrumbExpandChipContent, {
              count: expandCount,
              isExpand: isMdUp,
              onClick: onExpandSet,
              title: expandTitle,
              icon: iconComponent
            })
          })]
        }), _jsx(BreadcrumbCollapse, {
          in: breadcrumbExpand,
          timeout: "auto",
          children: _jsx(MPGrid, {
            container: true,
            spacing: 1,
            direction: "column",
            alignItems: "baseline",
            children: items.slice(1).map((item, index) => _jsx(MPGrid, {
              item: true,
              children: _jsx(BreadcrumbChain, {
                hideRootCrumb: true,
                items: item
              }, `${index}_${item[1].id}`)
            }, `${index}_${item[1].id}`))
          })
        })]
      })
    }, 'items')]
  });
};