import { Link as MUILink, Typography, useMediaQuery } from '@mui/material';
import CardFavoriteButton from 'presentation/components/common/buttons/cardFavorite/button';
import { CoverImageView } from 'presentation/components/common/images/cover';
import { CloseIcon, RefreshIcon } from 'presentation/media/icons';
import MPSwitchIconButton from 'presentation/theme/ui-kit/button/switchIcon/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from 'presentation/utils/currency';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProductOfferCartCounterContainer from '../../cartCounter/container';
import { DiscountValue, OriginalPrice } from '../../controls';
import { getProductOfferDetailsRoute } from '../../entry';
import { EProductOfferFavoritePosition, formatToSale } from '../../utils';
import ProductOfferCartButtonLabelForCard from '../addToCartButton/labels/forCard';
import { Card, CardActions, CardContent, CardDescription, CardDetails, CardFavoriteButtonWrapper, CartActions, CoverImageViewWrapper, SwitchIconButtonWrapper } from './controls';
import { LoggedUserAdapter } from '../../../../auth/provider/loggedUserAdapter';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferCard = props => {
  const {
    toRemove,
    imageThumb = true,
    favorite,
    product,
    children
  } = props;
  const {
    price,
    originalPrice,
    images,
    salePercent
  } = product;
  const image = images?.[0] ?? null;
  const [mouseOnCard, setMouseOnCard] = useState(false);
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const favoritePosition = favorite?.position ?? EProductOfferFavoritePosition.Preview;
  const showFavorite = favorite?.showAlways || mouseOnCard || favorite?.inProgress || favorite?.active;
  const isOriginalPriceVisible = originalPrice && originalPrice > price;
  return _jsx(MUILink, {
    component: Link,
    to: getProductOfferDetailsRoute({
      id: product.id
    }),
    underline: "none",
    children: _jsxs(Card, {
      elevation: 0,
      toRemove: toRemove,
      onMouseEnter: () => setMouseOnCard(true),
      onMouseLeave: () => setMouseOnCard(false),
      children: [_jsxs(CardActions, {
        children: [_jsxs(CoverImageViewWrapper, {
          children: [_jsx(CoverImageView, {
            border: false,
            topRounded: false,
            bottomRounded: false,
            thumb: imageThumb,
            fit: "contain",
            id: image?.path ?? null
          }), favorite && showFavorite && (favoritePosition === EProductOfferFavoritePosition.Preview || !isSmDown) && _jsx(CardFavoriteButtonWrapper, {
            children: _jsx(CardFavoriteButton, {
              ...favorite
            })
          })]
        }), _jsxs(CardDetails, {
          children: [_jsxs(CardContent, {
            children: [_jsxs(MPGrid, {
              container: true,
              spacing: 1,
              wrap: "nowrap",
              alignItems: "baseline",
              children: [_jsx(MPGrid, {
                item: true,
                zero: true,
                children: _jsx(CardDescription, {
                  variant: "body1",
                  children: product.name
                })
              }), favorite && favoritePosition === EProductOfferFavoritePosition.Details && isSmDown && _jsx(MPGrid, {
                item: true,
                children: _jsx(SwitchIconButtonWrapper, {
                  children: _jsx(MPSwitchIconButton, {
                    ...favorite,
                    icons: {
                      on: CloseIcon,
                      off: RefreshIcon
                    }
                  })
                })
              })]
            }), children]
          }), _jsx(CardActions, {
            children: _jsxs(MPGrid, {
              container: true,
              spacing: isSmDown ? 0.5 : 1,
              alignItems: "center",
              children: [_jsx(MPGrid, {
                item: true,
                zero: isOriginalPriceVisible ? 'auto' : true,
                sm: 'auto',
                children: _jsx(Typography, {
                  variant: isSmDown ? 'subtitle1' : 'h4',
                  children: toRubCurrency(price)
                })
              }), isOriginalPriceVisible && _jsxs(MPGrid, {
                item: true,
                container: true,
                zero: 12,
                sm: 'auto',
                children: [_jsx(MPGrid, {
                  item: true,
                  zero: 'auto',
                  children: _jsx(OriginalPrice, {
                    variant: isSmDown ? 'body2' : 'body1',
                    color: "textSecondary",
                    children: toRubCurrency(originalPrice)
                  })
                }), salePercent && _jsx(MPGrid, {
                  item: true,
                  zero: 'auto',
                  children: _jsx(DiscountValue, {
                    variant: isSmDown ? 'body2' : 'body1',
                    color: "primary.main",
                    children: formatToSale(salePercent)
                  })
                })]
              })]
            })
          })]
        })]
      }), _jsx(LoggedUserAdapter, {
        children: _jsx(CartActions, {
          children: _jsx(ProductOfferCartCounterContainer, {
            product: product,
            label: ProductOfferCartButtonLabelForCard
          })
        })
      })]
    })
  });
};
export default ProductOfferCard;