import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { EAppFeature, ESearchUrlParam } from 'presentation/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { EOfferType } from '../../../domain/model/enums';
import SearchResultsContainer from '../../features/search/offers/container';
import useTechConfig from '../../hooks/useTechConfig';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchResultsScreen = () => {
  const location = useLocation();
  const {
    hasFeature
  } = useTechConfig();
  const {
    isCorpUser
  } = useAuthUser();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get(ESearchUrlParam.Query);
  const offerType = useMemo(() => {
    const result = [];
    if (hasFeature(EAppFeature.Trade)) {
      result.push(EOfferType.Trade);
    }
    if (hasFeature(EAppFeature.Booking)) {
      result.push(EOfferType.Booking);
    }
    if (hasFeature(EAppFeature.Product)) {
      result.push(EOfferType.Product);
    }
    if (hasFeature(EAppFeature.Asp)) {
      result.push(EOfferType.Asp);
    }
    if (isCorpUser) {
      result.push(EOfferType.Corp);
    }
    return result;
  }, [hasFeature, isCorpUser]);
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(SearchResultsContainer, {
        guid: guid,
        query: query,
        offerType: offerType
      })
    })
  });
};
export default SearchResultsScreen;