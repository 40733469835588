import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'header',
  initialState: {
    visible: true,
    bottomNavigationVisible: true,
    dialogs: {
      changeLocation: false,
      catalog: false
    }
  },
  reducers: {
    headerStartSession: state => {
      state.dialogs = {
        changeLocation: false,
        catalog: false
      };
    },
    headerSetDialogState: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        name,
        value
      } = payload;
      state.dialogs[name] = value;
    },
    headerToggleDialogState: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.dialogs[payload] = !state.dialogs[payload];
    },
    headerSetVisible: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.visible = payload;
    },
    bottomNavigationSetVisible: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      state.bottomNavigationVisible = payload;
    }
  }
});
export const {
  headerStartSession,
  headerSetDialogState,
  headerToggleDialogState,
  headerSetVisible,
  bottomNavigationSetVisible
} = slice.actions;
export default slice.reducer;