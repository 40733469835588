import { Link, Typography } from '@mui/material';
import { getFileDownloadEndpoint } from 'data/openApi/utils';
import LightButton from 'presentation/components/common/buttons/light';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FileAttribute = props => {
  const {
    label,
    href
  } = props;
  const hrefOut = href.startsWith('http') ? href : getFileDownloadEndpoint({
    id: href
  });
  return _jsx(Link, {
    href: hrefOut,
    component: LightButton,
    underline: "none",
    size: "small",
    children: _jsx(Typography, {
      variant: "body2",
      children: label
    })
  });
};
export default FileAttribute;