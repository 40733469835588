import axios from 'axios';
import { useLazyGetUserAgreementsQuery, useSignUserAgreementsMutation } from 'data/api/agreements';
import { ENetworkErrorCode } from 'data/network/types';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContentLoader from '../../../components/common/loader';
import SignAgreementsDialog from '../components/signAgreementsDialog';
import { BackdropWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const LegalAgreementContainer = _ref => {
  let {
    children
  } = _ref;
  const {
    logOut
  } = useAuth();
  const {
    tokenParsed
  } = useAuthToken();
  const dispatch = useDispatch();
  const [triggerFetchUserAgreements, agreementsToSignResult] = useLazyGetUserAgreementsQuery();
  const {
    data: agreementsToSign,
    isFetching: isAgreementsListFetching
  } = agreementsToSignResult;
  const [triggerSignAgreements, {
    isLoading: isAgreementsListSigning
  }] = useSignUserAgreementsMutation();
  const open = !!agreementsToSign?.length;
  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use(undefined, error => {
      if (error?.response?.status === 403 && error?.response?.data?.code === ENetworkErrorCode.MissingUserAgreement) {
        triggerFetchUserAgreements({});
      }
      return Promise.reject(error);
    });
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [tokenParsed, dispatch, triggerFetchUserAgreements]);
  const onAccept = async () => {
    if (!tokenParsed || !agreementsToSign) {
      return;
    }
    const docLinks = agreementsToSign.map(a => ({
      id: a.doc.id
    }));
    try {
      await triggerSignAgreements({
        data: {
          docs: docLinks
        }
      });
    } catch {
      console.error('User agreements sign is failed');
    }
  };
  const onReject = () => {
    logOut();
  };
  return _jsxs(_Fragment, {
    children: [children, isAgreementsListFetching && _jsx(BackdropWrapper, {
      children: _jsx(ContentLoader, {})
    }), open && _jsx(SignAgreementsDialog, {
      agreements: agreementsToSign ?? [],
      open: true,
      isAccepting: isAgreementsListSigning,
      onAccept: onAccept,
      onReject: onReject
    })]
  });
};
export default LegalAgreementContainer;