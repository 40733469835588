import { LinearProgress } from '@mui/material';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const GlobalLoaderContainer = props => {
  const {
    headerHeight
  } = useHeaderParams();
  return _jsx(Wrapper, {
    top: headerHeight,
    children: _jsx(LinearProgress, {
      variant: "indeterminate",
      ...props
    })
  });
};
export default GlobalLoaderContainer;