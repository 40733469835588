import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Typography } from '@mui/material';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BookingSlotItem = _ref => {
  let {
    title,
    width = 6,
    children
  } = _ref;
  return _jsxs(MPGrid, {
    item: true,
    xs: width,
    children: [_jsx(Typography, {
      variant: "body2",
      color: "secondary",
      children: title
    }), _jsx(Typography, {
      mb: 1,
      variant: "body1",
      children: children
    })]
  });
};