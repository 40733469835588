import { Typography } from '@mui/material';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferDetailsTitleAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  if (!bookingOffer) {
    return null;
  }
  return _jsx(Typography, {
    variant: "h1",
    children: bookingOffer.name
  });
};
export default BookingOfferDetailsTitleAdapter;