import AppSvgIcon from '../../../../../components/common/icon';
import { ClockIcon, WarningTriangleIcon } from '../../../../../media/icons';
import { InfoWrapper, WithIconWrapper, WithIconWrapperContent } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const WarnContainer = _ref => {
  let {
    children
  } = _ref;
  return _jsxs(WithIconWrapper, {
    children: [_jsx(AppSvgIcon, {
      fontSize: "small",
      color: "warning",
      icon: WarningTriangleIcon
    }), _jsx(WithIconWrapperContent, {
      children: children
    })]
  });
};
const WaitingContainer = _ref2 => {
  let {
    children
  } = _ref2;
  return _jsxs(WithIconWrapper, {
    children: [_jsx(AppSvgIcon, {
      fontSize: "micro",
      color: "success",
      icon: ClockIcon
    }), _jsx(WithIconWrapperContent, {
      children: children
    })]
  });
};
const InfoContainer = _ref3 => {
  let {
    children
  } = _ref3;
  return _jsx(InfoWrapper, {
    children: children
  });
};
const ClaimMessage = _ref4 => {
  let {
    children,
    type
  } = _ref4;
  let Container = InfoContainer;
  switch (type) {
    case 'warn':
      Container = WarnContainer;
      break;
    case 'info':
      Container = InfoContainer;
      break;
    case 'waiting':
      Container = WaitingContainer;
      break;
  }
  return _jsx(Container, {
    children: children
  });
};
export default ClaimMessage;