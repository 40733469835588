import CmsCollectionScreen from 'presentation/screen/cms/collection';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.data;
const routing = {
  root,
  cmsCollection: `${root}/collection/:id`
};
export const getDataCmsCollectionRoute = props => {
  const {
    id,
    guid
  } = props;
  const params = new URLSearchParams();
  const newGuid = guid ?? uuidv4();
  return {
    pathname: routing.cmsCollection.replace(':id', id),
    search: params.toString(),
    state: {
      guid: newGuid
    },
    hash: ''
  };
};

/**
 * точка входа для данных общего назначения, без явной привязки к предметной области
 * скрины рисующиеся отсюда максимально автономны
 */
const DataEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.cmsCollection,
      component: CmsCollectionScreen
    }), _jsx(Redirect, {
      to: rootRouting.root
    })]
  });
};
export default DataEntry;