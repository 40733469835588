import { createSlice } from '@reduxjs/toolkit';
import { EOfferListType } from 'domain/model/enums';
import { aspOffersDefaultParams } from '../../utils';
const emptyParams = aspOffersDefaultParams;
const slice = createSlice({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    common: emptyParams,
    upcoming: emptyParams
  },
  reducers: {
    aspOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      state.guid = guid;
    },
    aspOffersSetArgs: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        type,
        args
      } = payload;
      state[type] = args;
    },
    aspOffersSetPageSize: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      const {
        type,
        pageSize
      } = payload;
      state[type].pageSize = pageSize;
    },
    aspOffersSetSort: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      const {
        type,
        sort
      } = payload;
      state[type].sort = sort;
    },
    aspOffersSetPage: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        type,
        page
      } = payload;
      state[type].page = page;
    },
    aspOffersSetIsNewFetching: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.isNewFetching = payload;
    },
    aspOffersSetIsNewSearchFetching: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      state.isNewSearchFetching = payload;
    }
  }
});
export const {
  aspOffersSetPageSize,
  aspOffersSetPage,
  aspOffersStartSession,
  aspOffersSetSort,
  aspOffersSetIsNewFetching,
  aspOffersSetIsNewSearchFetching,
  aspOffersSetArgs
} = slice.actions;
export default slice.reducer;