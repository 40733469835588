import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from '../../../../utils/currency';
import { OriginalPrice, Price, SalePercent } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartListItemFinanceInfo = _ref => {
  let {
    price,
    originalPrice,
    salePercent
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    children: [_jsx(MPGrid, {
      item: true,
      xs: 12,
      children: _jsx(Price, {
        variant: "h3",
        children: toRubCurrency(price)
      })
    }), originalPrice ? _jsx(MPGrid, {
      item: true,
      xs: "auto",
      children: _jsx(OriginalPrice, {
        color: "textSecondary",
        children: toRubCurrency(originalPrice)
      })
    }) : null, _jsx(MPGrid, {
      item: true,
      xs: 0.5
    }), salePercent ? _jsx(MPGrid, {
      item: true,
      xs: true,
      children: _jsxs(SalePercent, {
        color: "primary.main",
        children: ["-", salePercent, "%"]
      })
    }) : null]
  });
};
export default CartListItemFinanceInfo;