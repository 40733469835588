import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useState } from 'react';
import HeaderLink from './link';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Feedback = () => {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  return _jsxs(_Fragment, {
    children: [_jsx(HeaderLink, {
      text: "\u0424\u043E\u0440\u043C\u0430 \u043E\u0431\u0440\u0430\u0442\u043D\u043E\u0439 \u0441\u0432\u044F\u0437\u0438",
      component: "div",
      onClick: () => setFeedbackVisible(true)
    }), _jsx(AboutFeedbackContainer, {
      open: feedbackVisible,
      onClose: () => setFeedbackVisible(false)
    })]
  });
};
export default Feedback;