import HttpClient from 'data/network/http';
import { useDispatch, useSelector } from 'react-redux';
import { authProviderTokenParsedSelector, authProviderTokenSelector } from './store/selectors';
import { authProviderSetToken } from './store/slice';
export const useAuthToken = () => {
  const dispatch = useDispatch();
  const token = useSelector(authProviderTokenSelector);
  const tokenParsed = useSelector(authProviderTokenParsedSelector);
  const refreshToken = async () => {
    await HttpClient.getInstance().getAuthService()?.updateToken(99999)?.then(result => {
      if (result) {
        const newToken = HttpClient.getInstance().getAuthService()?.token;
        const newTokenParsed = HttpClient.getInstance().getAuthService()?.tokenParsed;
        if (newToken && newTokenParsed) {
          dispatch(authProviderSetToken({
            token: newToken ?? null,
            parsed: newTokenParsed
          }));
        }
      }
    });
  };
  return {
    token,
    tokenParsed,
    refreshToken
  };
};