import CmsContainerContainer from '../../../../cms/container/container';
import { LazyContentWrapper } from '../../../../general/lazyContentWrapper';
import { LazyContentItem } from '../../../../general/lazyContentWrapper/item';
import PartnerDeskDetailsWindowLayout from './layout';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskDetailsWindowLayout;
export const PartnerDeskDetailsTabPartnerWindow = _ref => {
  let {
    partnerWindow,
    guid,
    partnerId
  } = _ref;
  // delete when back-end make filter
  const partnerWindowList = partnerWindow.filter(item => item.status === 'ACTIVE');
  return _jsx(Layout, {
    children: _jsx(LazyContentWrapper, {
      guid: guid,
      children: listSize => _jsx(_Fragment, {
        children: partnerWindowList?.slice(0, listSize + 1).map((container, i) => _jsx("div", {
          children: _jsx(LazyContentItem, {
            elementSize: 33,
            guid: guid,
            containerId: container.id,
            isLoaded: i !== listSize,
            children: onContainerDataLoaded => _jsx(CmsContainerContainer, {
              context: {
                partner: {
                  id: partnerId
                }
              },
              guid: guid,
              container: container,
              onContainerDataLoaded: onContainerDataLoaded
            })
          })
        }, container.id))
      })
    })
  });
};