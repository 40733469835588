import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'provider',
  initialState: {
    isAuthenticated: false,
    isLoading: true,
    token: null,
    tokenParsed: null
  },
  reducers: {
    authProviderSetState: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.isAuthenticated = payload.isAuthenticated;
      state.isLoading = payload.isLoading;
    },
    authProviderSetAuthenticated: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.isAuthenticated = payload;
    },
    authProviderSetToken: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.token = payload.token;
      state.tokenParsed = payload.parsed;
    }
  }
});
export const {
  authProviderSetState,
  authProviderSetAuthenticated,
  authProviderSetToken
} = slice.actions;
export default slice.reducer;