import { EOfferActivateError, EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { CorpOfferClaimBalanceNotEnoughWarnMessage } from './balanceNotEnough';
import { CorpOfferClaimBusinessErrorMessage } from './businessError';
import { CorpOfferClaimCodesEndsMessage } from './codesEnds';
import { CurrentActivateErrrorPausedOfferWarnMessage } from './currentActivateErrrorPausedOffer';
import { CorpOfferClaimPendingWarnMessage } from './pendingWarn';
import { CorpOfferClaimRejectedWarnMessage } from './rejectedWarn';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimWarnMessagesAdapter = () => {
  const {
    corpOffer,
    isActivationReceivedNow,
    activation
  } = useCorpOfferClaimContext();
  const isCodesEnded = (corpOffer?.notUsedOfferCount ?? 0) === 0;
  const {
    isUserBalanceNotEnough
  } = activation;
  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      return _jsxs(_Fragment, {
        children: [activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && _jsx(CorpOfferClaimBusinessErrorMessage, {
          error: activation.activationError
        }), isCodesEnded && isActivationReceivedNow && _jsx(CorpOfferClaimCodesEndsMessage, {
          promotionType: corpOffer.promotionType
        }), activation.lastActivation?.status === EOfferActivationStatus.Pending && _jsx(CorpOfferClaimPendingWarnMessage, {}), (corpOffer?.status === EOfferStatus.Active || isActivationReceivedNow) && isUserBalanceNotEnough && !isCodesEnded && _jsx(CorpOfferClaimBalanceNotEnoughWarnMessage, {
          paymentType: corpOffer.paymentType
        }), !isActivationReceivedNow && corpOffer?.status !== EOfferStatus.Archived && activation?.lastActivation?.status === EOfferActivationStatus.Rejected && _jsx(CorpOfferClaimRejectedWarnMessage, {}), isActivationReceivedNow && corpOffer?.status === EOfferStatus.Paused && activation?.lastActivation?.status === EOfferActivationStatus.Rejected && _jsx(CurrentActivateErrrorPausedOfferWarnMessage, {})]
      });
    }
  }, [corpOffer, activation.activationError, activation.lastActivation?.status, isCodesEnded, isActivationReceivedNow, isUserBalanceNotEnough]);
};