import { Hidden, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const PartnerDeskDetailsTabToolbarLayout = props => {
  const {
    title,
    sort
  } = props;
  return _jsxs(MPGrid, {
    container: true,
    alignItems: "center",
    justifyContent: "space-between",
    children: [_jsx(Hidden, {
      mdDown: true,
      children: _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          variant: "h1",
          component: "h2",
          children: title
        })
      })
    }), sort && _jsx(MPGrid, {
      item: true,
      children: sort
    })]
  });
};