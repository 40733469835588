import { svgIconClasses } from '@mui/material';
import { darken } from '@mui/material/styles';
import { disabled, focused, selected } from './constants';
const buttons = baseTheme => ({
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained'
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: baseTheme.shape.borderRadius * 1.2,
        fontSize: baseTheme.typography.button.fontSize,
        lineHeight: baseTheme.typography.button.lineHeight,
        fontWeight: baseTheme.typography.button.fontWeight
      },
      sizeSmall: {
        fontSize: baseTheme.typography.body2.fontSize,
        lineHeight: baseTheme.typography.body2.lineHeight,
        fontWeight: baseTheme.typography.body2.fontWeight,
        padding: baseTheme.spacing(0.75, 1.5),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(0.75, 1.5)
        }
      },
      outlined: {
        borderWidth: '2px',
        borderColor: 'inherit',
        [`&:hover, & ${selected}, & ${focused}`]: {
          borderWidth: '2px',
          borderColor: 'inherit'
        },
        [`&${disabled}`]: {
          borderWidth: '2px',
          borderColor: 'transparent',
          backgroundColor: `${baseTheme.palette.action.disabled}`
        }
      },
      outlinedSizeSmall: {
        padding: baseTheme.spacing(0.5, 1.25),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(0.5, 1.25)
        }
      },
      sizeMedium: {
        fontSize: baseTheme.typography.body2.fontSize,
        lineHeight: baseTheme.typography.body2.lineHeight,
        fontWeight: baseTheme.typography.body2.fontWeight,
        padding: baseTheme.spacing(1.25, 1.5),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1.25, 1.5)
        }
      },
      outlinedSizeMedium: {
        padding: baseTheme.spacing(1, 1.25),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1, 1.25)
        }
      },
      sizeLarge: {
        padding: baseTheme.spacing(1.5, 3.5),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1.5, 3.5)
        }
      },
      outlinedSizeLarge: {
        padding: baseTheme.spacing(1.25, 3.25),
        [baseTheme.breakpoints.between('xs', 'xl')]: {
          padding: baseTheme.spacing(1.25, 3.25)
        }
      },
      outlinedWhite: {
        color: baseTheme.palette.white.contrastText
      },
      textWhite: {
        color: baseTheme.palette.white.contrastText
      },
      outlinedBlack: {
        color: baseTheme.palette.black.main
      },
      textBlack: {
        color: baseTheme.palette.black.contrastText
      },
      containedSecondary: {
        background: baseTheme.palette.secondary.A600,
        color: baseTheme.palette.white.contrastText,
        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.secondary.A600, 0.1)
        }
      }
    }
  },
  MuiFab: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: _ref => {
        let {
          ownerState
        } = _ref;
        const styles = {
          overflow: 'hidden'
        };
        if (ownerState.disableElevation) {
          return {
            ...styles,
            boxShadow: 'none !important'
          };
        }
        return styles;
      },
      squared: {
        borderRadius: baseTheme.shape.borderRadius * 1.2
      },
      primaryLight: {
        background: baseTheme.palette.primary.A400,
        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.primary.A400, 0.1)
        },
        [`& .${svgIconClasses.root}`]: {
          color: baseTheme.palette.primary.main
        }
      },
      secondaryLight: {
        background: baseTheme.palette.secondary.A600,
        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.secondary.A600, 0.1)
        }
      },
      secondarySuperLight: {
        background: baseTheme.palette.secondary.A400,
        [`&:hover, & ${selected}, & ${focused}`]: {
          background: darken(baseTheme.palette.secondary.A400, 0.1)
        }
      },
      sizeMicro: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        minHeight: '32px'
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: _ref2 => {
        let {
          ownerState
        } = _ref2;
        const styles = {};
        if (ownerState.disablePadding) {
          return {
            ...styles,
            padding: 0
          };
        }
        return styles;
      }
    }
  }
});
export default buttons;