import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferType } from 'domain/model/enums';
import { EAppCategories } from 'presentation/types';
export const searchOffersDefaultParams = {
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null,
  offerType: [EOfferType.Corp, EOfferType.Trade, EOfferType.Booking],
  //нет Asp потому что это только клиентски тип
  query: null
};
export const searchTitleByOfferType = {
  [EOfferType.Trade]: EAppCategories.TradeOffers,
  [EOfferType.Asp]: EAppCategories.AspOffers,
  [EOfferType.Corp]: EAppCategories.CorpOffers,
  [EOfferType.Booking]: EAppCategories.BookingOffers,
  [EOfferType.Product]: EAppCategories.ProductOffers
};