import { Badge } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserBadge = _ref => {
  let {
    balance,
    children
  } = _ref;
  return _jsx(Badge, {
    overlap: "circular",
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    max: Infinity,
    badgeContent: balance,
    children: children
  });
};
export default UserBadge;