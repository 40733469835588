import { Tab, Tabs } from '@mui/material';
import { EUserOrdersTab } from '../../details/orders/utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserOrdersTabs = _ref => {
  let {
    tab,
    onChangeTab
  } = _ref;
  return _jsxs(Tabs, {
    value: tab,
    textColor: "secondary",
    variant: "scrollable",
    onChange: (event, newTab) => onChangeTab(newTab),
    children: [_jsx(Tab, {
      label: "\u0412 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0435",
      value: EUserOrdersTab.Active
    }), _jsx(Tab, {
      label: "\u0412\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u043D\u044B\u0435",
      value: EUserOrdersTab.Completed
    }), _jsx(Tab, {
      label: "\u041E\u0442\u043C\u0435\u043D\u0435\u043D\u043D\u044B\u0435",
      value: EUserOrdersTab.Cancelled
    })]
  });
};
export default UserOrdersTabs;