import { ECorpOfferSortType, EOfferStatus } from 'domain/model/enums';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ECorpOfferServicesTag } from './index';
const defaultCorpOfferActiveStatuses = [EOfferStatus.Active];
const defaultCorpOfferValidStatuses = [EOfferStatus.Active, EOfferStatus.Upcoming];
const corpOfferCategoriesTransformer = categories => categories; /*.filter(item => !!item.parentId)*/

export const corpOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getCorpOfferCategories: builder.query({
      transformResponse: response => corpOfferCategoriesTransformer(response.data),
      query: _ref => {
        let {
          ids
        } = _ref;
        return apiDefinition.corpOffer.categories({
          ids
        });
      }
    }),
    getCorpOfferCategoriesUsed: builder.query({
      transformResponse: response => corpOfferCategoriesTransformer(response.data),
      query: _ref2 => {
        let {
          name,
          onlyLeafCategories
        } = _ref2;
        return apiDefinition.user.corp.categoriesUsed({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories
        });
      }
    }),
    getCorpOfferCategoriesUsedTree: builder.query({
      transformResponse: response => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.corp.categoriesUsed({
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories: false
        });
      }
    }),
    getCorpOfferList: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          search,
          page,
          pageSize,
          sort
        } = _ref3;
        const {
          name,
          categories,
          statuses = defaultCorpOfferActiveStatuses,
          partnerId
        } = search;
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId
        });
      },
      providesTags: [ECorpOfferServicesTag.List]
    }),
    getCorpOffersCount: builder.query({
      transformResponse: response => {
        return response.data[0]?.count ?? 0;
      },
      query: _ref4 => {
        let {
          search
        } = _ref4;
        const {
          name,
          categories
        } = search;
        return apiDefinition.user.corp.count({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          categories
        });
      }
    }),
    getCorpOfferSearch: builder.query({
      keepUnusedDataFor: 0,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref5 => {
        let {
          search,
          pageSize
        } = _ref5;
        const {
          name
        } = search;
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          sort: [ECorpOfferSortType.Default]
        });
      }
    }),
    getCorpOfferDetails: builder.query({
      query: _ref6 => {
        let {
          id
        } = _ref6;
        return apiDefinition.user.corp.one({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: (result, error, args) => [{
        type: ECorpOfferServicesTag.Details,
        id: args.id
      }, ECorpOfferServicesTag.Details]
    })
  })
});
export const {
  useGetCorpOfferListQuery,
  useGetCorpOffersCountQuery,
  useGetCorpOfferSearchQuery,
  useGetCorpOfferCategoriesQuery,
  useGetCorpOfferCategoriesUsedQuery,
  useGetCorpOfferCategoriesUsedTreeQuery,
  useGetCorpOfferDetailsQuery
} = corpOfferApi;