import { useContext, useEffect } from 'react';
import { useGetBookingOfferCategoriesQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
const useBookingOfferCategoriesData = () => {
  const {
    setError
  } = useContext(RenderManagerErrorContext);
  const {
    data: categories,
    isFetching: isCategoriesFetching,
    isLoading: isCategoriesLoading,
    error: categoriesFetchError
  } = useGetBookingOfferCategoriesQuery({});
  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
      setError(categoriesFetchError);
    }
  }, [categoriesFetchError, setError]);
  return {
    categories,
    isCategoriesFetching,
    categoriesFetchError,
    isCategoriesLoading
  };
};
export default useBookingOfferCategoriesData;