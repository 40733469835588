import { useAppDispatch } from 'data/store/store';
import { useEffect, useState } from 'react';
const useWatchApiMutations = _ref => {
  let {
    deps,
    api,
    endpoints
  } = _ref;
  const dispatch = useAppDispatch();
  const [processes, setProcesses] = useState(0);
  const stringDeps = JSON.stringify(deps);
  useEffect(() => {
    setProcesses(prev => prev + 1);
    const promisesList = dispatch(api.util.getRunningMutationsThunk()).filter(mp => endpoints.includes(mp.arg.endpointName));
    const promises = Promise.all(promisesList);
    promises.then(() => {
      setProcesses(prev => prev - 1);
    });
  }, [dispatch, stringDeps, api, endpoints]);
  return processes !== 0;
};
export default useWatchApiMutations;