import axios from 'axios';
import { createCancelToken, getAddressEndpoint } from 'data/openApi/utils';
import { EAddressLevel } from 'domain/model/enums';
const countDefault = 20;
const addressApi = {
  cityQuery: _ref => {
    let {
      name,
      locations,
      signal
    } = _ref;
    const params = new URLSearchParams();
    if (name) {
      params.append('q', name);
    }
    params.append('maxCount', countDefault.toString(10));
    locations?.forEach(l => params.append('locations', JSON.stringify(l)));
    params.append('fromLevel', EAddressLevel.Value5.toString(10));
    params.append('toLevel', EAddressLevel.Value6.toString(10));
    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    });
  },
  addressQuery: _ref2 => {
    let {
      query,
      count = countDefault,
      level,
      fromLevel,
      toLevel,
      locations,
      signal,
      cancelToken
    } = _ref2;
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    params.append('maxCount', count.toString(10));
    locations?.forEach(l => params.append('locations', JSON.stringify(l)));
    if (level) {
      if (level === EAddressLevel.Value4) {
        params.append('fromLevel', EAddressLevel.Value5.toString(10));
        params.append('toLevel', EAddressLevel.Value4.toString(10));
      } else {
        params.append('fromLevel', level.toString(10));
        params.append('toLevel', level.toString(10));
      }
    } else if (fromLevel || toLevel) {
      if (fromLevel) {
        params.append('fromLevel', fromLevel.toString(10));
      }
      if (toLevel) {
        params.append('toLevel', toLevel.toString(10));
      }
    }
    return axios.get(`${getAddressEndpoint()}/addresses/suggest`, {
      params,
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal))
    });
  },
  addressByIpQuery: _ref3 => {
    let {
      signal,
      cancelToken
    } = _ref3;
    return axios.get(`${getAddressEndpoint()}/iplocate`, {
      cancelToken: cancelToken ?? (signal && createCancelToken(axios, signal))
    });
  }
};
export default addressApi;