import CorpOfferScreen from 'presentation/screen/corpOffer';
import CorpOffersScreen from 'presentation/screen/corpOffers';
import CorpOffersSearchResultsScreen from 'presentation/screen/corpOffersSearch';
import { Route, Switch } from 'react-router-dom';
import { routing } from './routes';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.list,
      component: CorpOffersScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.search,
      component: CorpOffersSearchResultsScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: CorpOfferScreen
    })]
  });
};
export default CorpOfferEntry;