import { createSelector } from '@reduxjs/toolkit';
import { aspOfferApi } from 'data/api/aspOffer';
export const aspOfferListGuidSelector = store => store.aspOffer.list.guid;
export const aspOfferListSelector = store => store.aspOffer.list;
export const aspOfferListCommonArgsSelector = store => store.aspOffer.list.common;
export const aspOfferListUpcomingArgsSelector = store => store.aspOffer.list.upcoming;
export const aspOfferListNameSelector = store => store.aspOffer.list.common?.search?.name ?? null;
export const aspOfferListSortSelector = store => store.aspOffer.list.common?.sort ?? null;
export const aspOfferListCategoryIdSelector = store => store.aspOffer.list.common?.search?.categories?.[0] ?? null;
export const aspOfferListSearchIsEmptySelector = store => !store.aspOffer.list.common?.search?.name && !store.aspOffer.list.common?.search?.categories?.length;
export const aspOfferListSearchByNameIsEmptySelector = store => !store.aspOffer.list.common?.search?.name;
export const aspOfferListCommonCountSelector = store => {
  const args = store.aspOffer.list.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const aspOfferListUpcomingCountSelector = store => {
  const args = store.aspOffer.list.upcoming;
  if (!args) {
    return 0;
  }
  const {
    data
  } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const aspOfferListCountSelector = createSelector(aspOfferListCommonCountSelector, aspOfferListUpcomingCountSelector, (commonCount, upcomingCount) => commonCount + upcomingCount);
export const aspOfferListCommonIsErrorSelector = store => {
  const args = store.aspOffer.list.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return isError;
};
export const aspOfferListUpcomingIsErrorSelector = store => {
  const args = store.aspOffer.list.upcoming;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return isError;
};
export const aspOfferListIsErrorSelector = createSelector(aspOfferListCommonIsErrorSelector, aspOfferListUpcomingIsErrorSelector, (commonIsError, upcomingIsError) => commonIsError || upcomingIsError);
export const aspOfferListCommonIsFetchingSelector = store => {
  const args = store.aspOffer.list.common;
  return args ? aspOfferApi.endpoints.getAspOfferList.select(args)(store).isLoading : false;
};
export const aspOfferListUpcomingIsFetchingSelector = store => {
  const args = store.aspOffer.list.upcoming;
  return args ? aspOfferApi.endpoints.getAspOfferList.select(args)(store).isLoading : false;
};
export const aspOfferListIsFetchingSelector = createSelector(aspOfferListCommonIsFetchingSelector, aspOfferListUpcomingIsFetchingSelector, (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching);
export const aspOfferListIsNewFetchingSelector = createSelector(aspOfferListIsFetchingSelector, store => store.aspOffer.list.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);
export const aspOfferListIsNewSearchFetchingSelector = createSelector(aspOfferListIsFetchingSelector, store => store.aspOffer.list.isNewSearchFetching, (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching);
export const aspOfferListNoDataSelector = createSelector(aspOfferListCountSelector, aspOfferListIsFetchingSelector, aspOfferListIsErrorSelector, (count, isFetching, isError) => count === 0 && !isFetching && !isError);