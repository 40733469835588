import { MPTopPushDialog } from 'presentation/theme/ui-kit/dialog';
import { ContentWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CatalogLayout = props => {
  const {
    tabs,
    children,
    ...dialogProps
  } = props;
  return _jsx(MPTopPushDialog, {
    ...dialogProps,
    children: _jsxs(Wrapper, {
      children: [tabs, _jsx(ContentWrapper, {
        children: children
      })]
    })
  });
};