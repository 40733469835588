import { Tab, useMediaQuery } from '@mui/material';
import { EPartnerType } from 'domain/model/enums';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabAboutCompany } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { OfferTabs } from '../../../components/tabs/controls';
import { ECorpOfferDetailsTab } from '../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferTabs = props => {
  const {
    tab,
    corpOffer,
    partnerDesk,
    onChange
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return _jsxs(MPGrid, {
    container: true,
    spacing: isMdUp ? 3 : 2,
    children: [_jsx(MPGrid, {
      item: true,
      zero: 12,
      children: _jsx(TabsWrapper, {
        children: _jsxs(OfferTabs, {
          value: tab,
          textColor: "secondary",
          variant: "scrollable",
          onChange: onChange,
          children: [_jsx(Tab, {
            label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435",
            value: ECorpOfferDetailsTab.Description
          }), partnerDesk.contacts && corpOffer.partner.type !== EPartnerType.InnerPartner && _jsx(Tab, {
            label: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B",
            value: ECorpOfferDetailsTab.Contacts
          }), partnerDesk.aboutCompany && corpOffer.partner.type !== EPartnerType.InnerPartner && _jsx(Tab, {
            label: "\u041E \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438",
            value: ECorpOfferDetailsTab.AboutCompany
          })]
        })
      })
    }), _jsxs(MPGrid, {
      item: true,
      zero: 12,
      children: [tab === ECorpOfferDetailsTab.Description && _jsx(OfferDetailsTabDescription, {
        description: corpOffer.description
      }), tab === ECorpOfferDetailsTab.Contacts && corpOffer.partner.type !== EPartnerType.InnerPartner && partnerDesk.contacts && _jsx(OfferDetailsTabContacts, {
        contacts: partnerDesk.contacts
      }), tab === ECorpOfferDetailsTab.AboutCompany && corpOffer.partner.type !== EPartnerType.InnerPartner && partnerDesk.aboutCompany && _jsx(PartnerDeskDetailsTabAboutCompany, {
        about: partnerDesk.aboutCompany
      })]
    })]
  });
};
export default CorpOfferTabs;