const fontWeightRegular = 400;
const fontWeightMedium = 500;
const brandBookTypography = {
  fontWeightRegular,
  fontWeightMedium,
  fontFamily: 'Rubik',
  h1: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: fontWeightMedium
  },
  h2: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: fontWeightMedium
  },
  h3: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: fontWeightMedium
  },
  subtitle1: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: fontWeightMedium
  },
  body1: {
    fontSize: '17px',
    lineHeight: '24px',
    fontWeight: fontWeightRegular
  },
  body2: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: fontWeightRegular
  },
  p3: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: fontWeightRegular
  },
  p2Medium: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: fontWeightMedium
  },
  p3Medium: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: fontWeightMedium
  },
  p4: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: fontWeightRegular
  }
};
export default brandBookTypography;