export const fetchableDefault = {
  isFetching: false,
  isFetched: false,
  isFailed: false,
  isCancelled: false
};
export const fetchableFetching = {
  ...fetchableDefault,
  isFetching: true
};
export const fetchableFetched = {
  ...fetchableDefault,
  isFetched: true
};
export const fetchableFailed = {
  ...fetchableDefault,
  isFailed: true
};