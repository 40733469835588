import { BadgeContentWrapper } from '../../../../features/offer/corp/details/adapters/carousel/controls';
import AppSvgIcon from '../../icon';
import { BannerIconBackground } from '../../../../media/icons';
import { StyledBadge } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BadgeContent = props => {
  const {
    backgroundColor = 'white',
    icon
  } = props;
  return _jsxs(BadgeContentWrapper, {
    backgroundColor: backgroundColor,
    children: [_jsx(AppSvgIcon, {
      icon: BannerIconBackground
    }), _jsx(AppSvgIcon, {
      fontSize: "medium",
      icon: icon
    })]
  });
};
const BannerBadge = _ref => {
  let {
    backgroundColor,
    icon,
    children
  } = _ref;
  return _jsx(StyledBadge, {
    badgeContent: _jsx(BadgeContent, {
      icon: icon,
      backgroundColor: backgroundColor
    }),
    children: children
  });
};
export default BannerBadge;