import AddressCitySingleSelector from 'presentation/components/common/address/singleSelector/city';
import { UserProfileEditAttribute } from './attribute';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserProfileEditAttributeLocality = props => {
  const {
    label,
    value,
    validation,
    isFetching,
    onChange
  } = props;
  return _jsx(UserProfileEditAttribute, {
    ...props,
    children: _jsx(AddressCitySingleSelector, {
      fullWidth: true,
      label: label,
      value: value,
      error: !!validation?.hasError,
      helperText: validation?.message,
      disabled: isFetching,
      onChange: onChange
    })
  });
};