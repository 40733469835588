import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ContentLoader from 'presentation/components/common/loader';
import { bookingOfferIsFilterFetchingSelector, bookingOfferListIsFetchingSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Loader = _jsx(ContentLoader, {
  position: "fixed"
});
export const BookingOfferListLoaderAdapter = _ref => {
  let {
    categoryId
  } = _ref;
  const isFetching = useSelector(bookingOfferListIsFetchingSelector);
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);
  return useMemo(() => isFetching || categoryId && isFilterFetching ? Loader : null, [isFetching, isFilterFetching, categoryId]);
};