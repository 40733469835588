import { useMediaQuery } from '@mui/material';
import { CartIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../../../components/common/icon';
import { productOfferAddToCartLabel, productOfferInCartLabel } from '../constants';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferCartButtonLabelForCard = _ref => {
  let {
    isEmpty
  } = _ref;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  if (isEmpty) return _jsx(_Fragment, {
    children: productOfferAddToCartLabel
  });
  return _jsx(_Fragment, {
    children: isSmDown ? _jsx(AppSvgIcon, {
      icon: CartIcon
    }) : productOfferInCartLabel
  });
};
export default ProductOfferCartButtonLabelForCard;