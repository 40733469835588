import { useGetCorpOfferListQuery } from 'data/api/corpOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { corpOfferListIsNewFetchingSelector } from 'presentation/features/offer/corp/list/store/selectors';
import { ERenderSource } from 'presentation/types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OfferListItemContainer from '../../listItem/container';
import useCategoriesData from './hooks/useCategoriesData';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferListDataContainer = props => {
  const {
    type,
    guid,
    args,
    header,
    onChangePage,
    onShowCard
  } = props;
  const isNewFetching = useSelector(corpOfferListIsNewFetchingSelector);
  const {
    page
  } = args;
  const {
    isAuthenticated
  } = useAuth();
  const {
    subCategories
  } = useCategoriesData();
  const {
    data,
    error,
    isSuccess,
    isFetching
  } = useGetCorpOfferListQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const corpOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasOffers = !!corpOffers && corpOffers.length > 0;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  const hasSubCategories = !!subCategories?.length;
  return _jsxs("div", {
    children: [header, _jsx(CardPageableList, {
      span: hasSubCategories ? 4 : 3,
      lgSpan: hasSubCategories ? 6 : 4,
      totalCount: totalCount,
      onLoadMore: onLoadMore,
      children: corpOffers.map(corpOffer => _jsx(InView, {
        active: isSuccess && !isFetching,
        sessionKey: sessionKey,
        data: corpOffer,
        onShow: onShowCard,
        children: _jsx(OfferListItemContainer, {
          offer: corpOffer,
          favorite: isAuthenticated && {
            guid
          },
          renderFrom: ERenderSource.OfferList
        }, corpOffer.id)
      }, corpOffer.id))
    })]
  });
};
export default CorpOfferListDataContainer;