import { LoggedUserAdapter } from 'presentation/features/auth/provider/loggedUserAdapter';
import { UserProfileActionsAdapter, UserProfileBehaviorAdapter, UserProfileCommonAdapter, UserProfileCorpLinkedAdapter, UserProfileEmailAdapter, UserProfileLoaderAdapter, UserProfileLocalityAdapter, UserProfilePasswordAdapter, UserProfilePhoneAdapter } from './adapters';
import UserProfileLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Layout = UserProfileLayout;
const UserProfileContainerInternal = _ref => {
  let {
    user
  } = _ref;
  return _jsxs(Layout, {
    title: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
    subTitle: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C",
    loader: _jsx(UserProfileLoaderAdapter, {}),
    corpLinked: _jsx(UserProfileCorpLinkedAdapter, {}),
    actions: _jsx(UserProfileActionsAdapter, {})
    /*notifications={<UserProfileNotificationsAdapter />} временно отключено в рамках редизайна*/,
    children: [_jsx(UserProfileCommonAdapter, {
      user: user
    }), _jsx(UserProfilePhoneAdapter, {
      user: user
    }), _jsx(UserProfileEmailAdapter, {
      user: user
    }), _jsx(UserProfileLocalityAdapter, {
      user: user
    }), _jsx(UserProfilePasswordAdapter, {
      user: user
    })]
  });
};
const UserProfileContainer = () => {
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileBehaviorAdapter, {}), _jsx(LoggedUserAdapter, {
      component: UserProfileContainerInternal
    })]
  });
};
export default UserProfileContainer;