import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const RenderManagerErrorProvider = _ref => {
  let {
    children
  } = _ref;
  const [error, setError] = useState(null);
  const setErrorProvider = useCallback(error => {
    setError(error);
  }, []);
  const clearError = useCallback(() => {
    setError(null);
  }, []);
  useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);
  const value = useMemo(() => ({
    setError: setErrorProvider,
    clearError,
    error
  }), [clearError, error, setErrorProvider]);
  return _jsx(RenderManagerErrorContext.Provider, {
    value: value,
    children: children
  });
};
export default RenderManagerErrorProvider;