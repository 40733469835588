import { useSearchOffersQuery } from 'data/api/search';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferShortDiscriminator } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { ERenderSource } from 'presentation/types';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../auth/provider/useAuth';
import OfferListItemContainer from '../../offer/listItem/container';
import { searchOffersIsFetchingSelector, searchOffersIsNewFetchingSelector } from './store/selectors';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SearchOffersDataContainer = props => {
  const {
    guid,
    args,
    onLoadMore,
    onShowCard
  } = props;
  const {
    isAuthenticated
  } = useAuth();
  const isFetching = useSelector(searchOffersIsFetchingSelector);
  const isNewFetching = useSelector(searchOffersIsNewFetchingSelector);
  const {
    data,
    error,
    isSuccess
  } = useSearchOffersQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasOffers = !!offers && offers.length > 0;
  const isMoreFetching = !!offers && isFetching;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  return _jsx("div", {
    children: _jsx(CardPageableList, {
      span: 3,
      lgSpan: 4,
      totalCount: totalCount,
      disableLoadMore: isMoreFetching,
      onLoadMore: onLoadMore,
      children: offers.map(offer => _jsxs(Fragment, {
        children: [offer.discriminator === EOfferShortDiscriminator.Trade && _jsx(InView, {
          active: isSuccess && !isFetching,
          sessionKey: sessionKey,
          data: offer,
          onShow: onShowCard,
          children: _jsx(OfferListItemContainer, {
            offer: offer,
            favorite: isAuthenticated && {
              guid
            },
            renderFrom: ERenderSource.OfferSearch
          }, offer.id)
        }), offer.discriminator === EOfferShortDiscriminator.Corp && _jsx(InView, {
          active: isSuccess && !isFetching,
          sessionKey: sessionKey,
          data: offer,
          onShow: onShowCard,
          children: _jsx(OfferListItemContainer, {
            offer: offer,
            favorite: isAuthenticated && {
              guid
            },
            renderFrom: ERenderSource.OfferSearch
          }, offer.id)
        }), offer.discriminator === EOfferShortDiscriminator.Booking && _jsx(InView, {
          active: isSuccess && !isFetching,
          sessionKey: sessionKey,
          data: offer,
          onShow: onShowCard,
          children: _jsx(OfferListItemContainer, {
            offer: offer,
            favorite: isAuthenticated && {
              guid
            },
            renderFrom: ERenderSource.OfferSearch
          }, offer.id)
        }), offer.discriminator === EOfferShortDiscriminator.Product && _jsx("div", {
          children: "\u0442\u043E\u0432\u0430\u0440, \u043D\u0435 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442\u0441\u044F"
        })]
      }, offer.id))
    })
  });
};
export default SearchOffersDataContainer;