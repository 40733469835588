import CmsPreviewScreen from 'presentation/screen/cms/preview';
import { Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const root = rootRouting.cms;
const routing = {
  root,
  test: `${root}/test`
};
const CmsEntry = () => {
  return _jsx(Switch, {
    children: _jsx(Route, {
      exact: true,
      path: routing.test,
      component: CmsPreviewScreen
    })
  });
};
export default CmsEntry;