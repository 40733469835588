import { Typography } from '@mui/material';
import { EReasonInitiator } from '../../../../../../../domain/model/enums';
import { ContentLimiter } from '../../../../../../components/common/contentLimit';
import { ContentLimiterDialogLayout } from '../../../../../../components/common/contentLimit/layouts';
import { WebWindowIcon } from '../../../../../../media/icons';
import { LinkMore, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const getLabelInitiator = initiator => {
  switch (initiator) {
    case EReasonInitiator.Customer:
      return 'Заказ отменён вами';
    case EReasonInitiator.Partner:
      return 'Заказ отменен менеджером объекта';
  }
};
const getReasonComment = (initiator, order, reasons) => {
  let reason;
  switch (initiator) {
    case EReasonInitiator.Customer:
      reason = reasons.find(c => c.id === order.customerCancelReason?.id);
      if (reason?.name?.toLowerCase() === 'другое') {
        return order.lastStatusComment;
      } else {
        return reason?.name;
      }
    case EReasonInitiator.Partner:
      reason = reasons.find(c => c.id === order.cancelReason?.id);
      if (reason?.name?.toLowerCase() === 'другое') {
        return order.lastStatusComment;
      } else {
        return reason?.name;
      }
  }
};
const StatusComment = _ref => {
  let {
    order,
    reasons
  } = _ref;
  const initiator = order.customerCancelReason ? EReasonInitiator.Customer : EReasonInitiator.Partner;
  const comment = getReasonComment(initiator, order, reasons);
  const initiatorLabel = getLabelInitiator(initiator);
  return _jsx(Wrapper, {
    children: _jsx(ContentLimiter, {
      layout: ContentLimiterDialogLayout,
      layoutProps: {
        rows: 3,
        title: 'Причина отмены',
        contentProps: {
          textAlign: 'center'
        },
        moreText: _jsxs(LinkMore, {
          children: [_jsx(WebWindowIcon, {}), " \u043F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u043F\u043E\u043B\u043D\u043E\u0441\u0442\u044C\u044E"]
        })
      },
      children: _jsxs(Typography, {
        children: [initiatorLabel, ': ', comment]
      })
    })
  });
};
export default StatusComment;