import { useContextHandlers } from '../../hooks/useContextHandlers';
import Close from '../../components/close';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CloseAdapter = () => {
  const {
    goBack
  } = useContextHandlers();
  return _jsx(Close, {
    onClick: goBack
  });
};
export default CloseAdapter;