import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import PartnerDeskCard from '../../components/card';
import { PartnerGrid } from '../../components/grid';
import { getPartnerDeskDetailsRoute } from '../../entry';
import usePartnerDeskList from '../provider/usePartnerDeskList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskListDataAdapter = () => {
  const history = useHistory();
  const {
    data
  } = usePartnerDeskList();
  const onOpenPartnerDeskDetails = useCallback(partnerDesk => {
    history.push(getPartnerDeskDetailsRoute({
      id: partnerDesk.id
    }));
  }, [history]);
  return useMemo(() => data && data.length > 0 ? _jsx(PartnerGrid, {
    children: data.map(item => item.partner ? _jsx(PartnerDeskCard, {
      title: item.partner.name,
      image: item.partner.logo,
      onClick: () => onOpenPartnerDeskDetails(item)
    }, item.partnerId) : null)
  }) : null, [data, onOpenPartnerDeskDetails]);
};
export default PartnerDeskListDataAdapter;