import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import AspOfferListContainer from 'presentation/features/offer/asp/list/container';
import { EAspOfferUrlParam } from 'presentation/features/offer/asp/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOffersScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(EAspOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(EAspOfferUrlParam.PartnerId) ?? null;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(AspOfferListContainer, {
        name: name,
        categoryId: categoryId,
        partnerId: partnerId,
        guid: guid
      })
    })
  });
};
export default AspOffersScreen;