import * as Sentry from '@sentry/react';
export default class SentryClient {
  static getInstance() {
    if (!SentryClient.instance) {
      SentryClient.instance = new SentryClient();
    }
    return SentryClient.instance;
  }
  constructor() {}
  options = null;
  authService = null;
  init(options) {
    this.options = options;
    if (!options) {
      console.info(`Sentry client not started, options is empty`);
      return;
    }
    if (options?.enabled && options?.project) {
      const release = options?.project + (options?.release ? `@${options?.release}` : '');
      console.log('options.xhrTracking', options.xhrTracking);
      Sentry.init({
        ...options,
        release,
        integrations: [Sentry.browserTracingIntegration({
          instrumentPageLoad: false,
          instrumentNavigation: false
        }), Sentry.breadcrumbsIntegration({
          dom: false,
          history: false,
          console: false,
          fetch: false,
          xhr: true,
          sentry: false
        })],
        beforeSend: event => {
          const user = this.getUserForCapture();
          if (user) {
            event.user = user;
          }
          return event;
        },
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr') {
            //исключаем запроса для облачных метрик
            if (breadcrumb.data?.url?.indexOf('.yandex.') !== -1) {
              return null;
            }
            if (breadcrumb.data?.status_code &&
            //исключаем 200-е ответы, чтобы не были огромные логи
            breadcrumb.data?.status_code >= 200 && breadcrumb.data?.status_code <= 299) {
              return null;
            }
            //добавляем message из ответа и data из запроса
            const newBreadcrumb = {
              ...breadcrumb,
              data: {
                ...breadcrumb.data,
                request: hint?.input?.toString()?.slice(0, 1024),
                response: hint?.xhr?.responseText?.toString()?.slice(0, 300)
              }
            };
            return newBreadcrumb;
          }
          return breadcrumb;
        },
        tracesSampleRate: 1.0
      });
      if (options?.tags) {
        Sentry.setTags(options.tags);
      }
      console.info(`Sentry client init with release '${release}'`);
    } else {
      console.info(`Sentry client is not active. Check the env variables: sentryEnabled, sentryProject`);
    }
  }
  isActive() {
    return this.options?.enabled ?? false;
  }
  setAuthService(authService) {
    this.authService = authService;
  }
  getCaptureUserAttributes() {
    return this.options?.captureUserAttributes ?? null;
  }
  getUserForCapture() {
    if (this.options?.enabled) {
      const captureUserAttributes = this.getCaptureUserAttributes();
      const user = this.authService?.tokenParsed;
      if (captureUserAttributes?.length) {
        if (user) {
          const context = captureUserAttributes.reduce((next, prev) => {
            const key = prev;
            if (key === 'id') {
              next[prev] = user?.sid;
            }
            if (key === 'email') {
              next[prev] = user?.email;
            }
            return next;
          }, {});
          return context;
        } else {
          return {
            id: 'anonymous'
          };
        }
      }
    }
    return null;
  }
  captureException(exception, context) {
    if (this.options?.enabled) {
      if (typeof exception === 'string') {
        Sentry.captureException(new Error(exception), context);
      } else {
        Sentry.captureException(exception, context);
      }
    } else {
      console.warn('Sentry client is not active. Check env params');
    }
  }
}