import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import CountInput from 'presentation/components/common/countInput';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferModalNumber = _ref => {
  let {
    count,
    onChange
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx(Splitter, {
      size: 1
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "body2",
        color: "textSecondary",
        children: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E"
      })
    }), _jsx(Splitter, {
      size: 1.5
    }), _jsx(CountInput, {
      isInputDisabled: true,
      fullWidth: true,
      min: 0,
      size: "small",
      value: count,
      onIncrement: onChange,
      onDecrement: onChange
    }), _jsx(Splitter, {
      size: 2
    })]
  });
};
export default BookingOfferModalNumber;