import useCorpOfferCategoriesData from './useCorpOfferCategoriesData';
import useCorpOfferDetailsData from './useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from './useCorpOfferPartnerDeskData';
const useCorpOfferDetailsFetchingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    categoriesIsFetching: isCategoriesFetching
  } = useCorpOfferCategoriesData();
  const {
    isCorpOfferFetching
  } = useCorpOfferDetailsData(id);
  const {
    isPartnerDeskFetching
  } = useCorpOfferPartnerDeskData(id);
  return isCategoriesFetching || isCorpOfferFetching || isPartnerDeskFetching;
};
export default useCorpOfferDetailsFetchingStatus;