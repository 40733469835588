import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { UserMenuItemLocality } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuLocalityAdapter = props => {
  const {
    onLocationClick
  } = props;
  const {
    settings
  } = useUserLocalSettings();
  const userLocation = settings.city?.name ?? null;
  return _jsx(UserMenuItemLocality, {
    locality: userLocation,
    onClick: onLocationClick
  });
};