import { backdropClasses } from '@mui/material';
const drawers = () => ({
  MuiDrawer: {
    styleOverrides: {
      root: {
        [`.${backdropClasses.root}`]: {
          background: 'rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(2.5px)'
        }
      }
    }
  }
});
export default drawers;