import { useHistory } from 'react-router';
import { getCorpOfferDetailsRoute } from '../../routes';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from '../hooks/useCorpOfferPartnerDeskData';
import CorpOfferTabs from '../tabs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferDetailsTabsAdapter = _ref => {
  let {
    guid,
    id,
    tab
  } = _ref;
  const history = useHistory();
  const onChangeTab = (event, newTab) => {
    history.replace(getCorpOfferDetailsRoute({
      id,
      tab: newTab,
      guid
    }));
  };
  const {
    corpOffer
  } = useCorpOfferDetailsData(id);
  const {
    partnerDesk
  } = useCorpOfferPartnerDeskData(id);
  if (!corpOffer || !partnerDesk) {
    return null;
  }
  return _jsx(CorpOfferTabs, {
    tab: tab,
    corpOffer: corpOffer,
    partnerDesk: partnerDesk,
    onChange: onChangeTab
  });
};
export default CorpOfferDetailsTabsAdapter;