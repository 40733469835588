import { EOfferStatus, EOfferType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tradeOffersCollectionSelector } from '../store/selectors';
import { collectionSetState } from '../store/slice';
import { CmsTradeOfferCollection } from '../tradeOffers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * основной адаптер для коллекции ТП
 */
export const CmsTradeOfferCollectionCommonAdapter = props => {
  const {
    id,
    guid
  } = props;
  const {
    webAnalytics
  } = useWebAnalytics();
  const dispatch = useDispatch();
  const pageSize = 24;
  const collectionState = useSelector(tradeOffersCollectionSelector(guid, id));
  const visibleCount = collectionState?.visibleCount ?? pageSize;
  const statuses = [EOfferStatus.Active];
  const onLoadMore = useCallback(() => {
    dispatch(collectionSetState({
      guid,
      offerType: EOfferType.Trade,
      id,
      visibleCount: visibleCount + pageSize
    }));
  }, [dispatch, guid, id, visibleCount, pageSize]);
  const onShowCard = useCallback(offer => webAnalytics.offerShow(offer.id), [webAnalytics]);
  return _jsx(CmsTradeOfferCollection, {
    ...props,
    statuses: statuses,
    visibleCount: visibleCount,
    onLoadMore: onLoadMore,
    onShowCard: onShowCard
  });
};