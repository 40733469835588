import { createSlice } from '@reduxjs/toolkit';
import { bookingOffersDefaultParams } from '../../utils';
import { bookingOfferListFilterFetch } from './fetch';
import { EBookingOfferListRequestType } from './types';
import { getAdjustedPrice, getRequestTypes } from './utils';
const {
  priceRange,
  services,
  ...defaultData
} = bookingOffersDefaultParams;
const slice = createSlice({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    isDefaultServiceSet: false,
    categoryId: null,
    data: defaultData,
    filter: {
      services,
      priceRange
    },
    services: null,
    filterData: {
      priceRange,
      services: null,
      leavingServiceId: null,
      isFetched: false,
      isFailed: false,
      isCancelled: false,
      isFetching: true
    }
  },
  reducers: {
    bookingOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      state.guid = guid;
    },
    bookingOffersSetPageSize: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.data.pageSize = payload;
    },
    bookingOffersSetSort: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.data.sort = payload;
    },
    bookingOffersSetSearch: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      state.data.search = payload;
    },
    bookingOffersSetFilterServices: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      state.filter.services = payload;
    },
    bookingOffersSetFilterPriceRange: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.filter.priceRange = payload;
      state.data.minPrice = payload.minPrice;
      state.data.maxPrice = payload.maxPrice;
    },
    bookingOffersSetPage: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      state.data.page = payload;
    },
    bookingOffersSetIsNewFetching: (state, _ref8) => {
      let {
        payload
      } = _ref8;
      state.isNewFetching = payload;
    },
    bookingOffersSetIsNewSearchFetching: (state, _ref9) => {
      let {
        payload
      } = _ref9;
      state.isNewSearchFetching = payload;
    },
    bookingOffersSetIsDefaultServiceSet: (state, _ref10) => {
      let {
        payload
      } = _ref10;
      state.isDefaultServiceSet = payload;
    },
    bookingOffersSetCategoryId: (state, _ref11) => {
      let {
        payload
      } = _ref11;
      state.categoryId = payload;
      if (payload) {
        state.data.search.categories = [payload];
      } else {
        state.data.search.categories = null;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(bookingOfferListFilterFetch.pending, (state, action) => {
      state.filterData = {
        ...state.filterData,
        leavingServiceId: action.meta.arg.requestType === EBookingOfferListRequestType.All ? null : state.filterData.leavingServiceId,
        isFetching: true,
        isFetched: false,
        isFailed: false
      };
    }).addCase(bookingOfferListFilterFetch.fulfilled, (state, _ref12) => {
      let {
        payload: {
          leavingServiceId,
          requestType,
          allServices,
          services,
          priceRange,
          priceRangeRequested
        }
      } = _ref12;
      const {
        isAll,
        isReset,
        isServices
      } = getRequestTypes(requestType);
      const currentFilterDataRange = {
        ...state.filterData.priceRange
      };
      state.filterData = {
        isFetching: false,
        isFetched: true,
        isFailed: false,
        leavingServiceId: isAll ? leavingServiceId : state.filterData.leavingServiceId,
        priceRange: isAll || isServices || isReset ? priceRange : state.filterData.priceRange,
        services
      };

      // Обновление данных стейта в момент isFetched:true для корректного выполнения запроса rtk списка карточек

      if (isAll && allServices) {
        state.services = allServices;
        const adjustedPriceRange = priceRangeRequested ?? priceRange;
        // Здесь и условие ниже
        // Обновление в стейте фильтров state.filter.* при наличии категории Проживание для корректного выполнения запроса карточек,
        // т.к. rtk привязан к дублированным аргументам в стейте для последующего соответствия данных кэшам rtk в селекторах
        state.filter.priceRange = {
          minPrice: getAdjustedPrice('minPrice', priceRange, adjustedPriceRange),
          maxPrice: getAdjustedPrice('maxPrice', priceRange, adjustedPriceRange)
        };
      }
      if (isAll && leavingServiceId && !state.isDefaultServiceSet && (!state.filter.services || state.filter.services.length === 0)) {
        state.filter.services = [leavingServiceId];
      }

      // Синхронизация фильтра цен с обновлённым диапазоном
      if (isServices || isReset) {
        const {
          minPrice: selectedMin,
          maxPrice: selectedMax
        } = state.data;
        const {
          minPrice: filterMin,
          maxPrice: filterMax
        } = state.filter.priceRange;

        // Ничего не выбрано, замена
        if (!selectedMin || !selectedMax) {
          state.filter.priceRange = priceRange;
        } else {
          if (priceRange.minPrice && selectedMin < priceRange.minPrice) {
            state.filter.priceRange.minPrice = priceRange.minPrice;
          }
          if (priceRange.maxPrice && selectedMax > priceRange.maxPrice) {
            state.filter.priceRange.maxPrice = priceRange.maxPrice;
          }

          // Выравнивание активных выбранных значений, если предыдущие равны минимуму/максимуму
          if (filterMin === currentFilterDataRange.minPrice) {
            state.filter.priceRange.minPrice = state.filterData.priceRange.minPrice;
          }
          if (filterMax === currentFilterDataRange.maxPrice) {
            state.filter.priceRange.maxPrice = state.filterData.priceRange.maxPrice;
          }
        }
      }
    }).addCase(bookingOfferListFilterFetch.rejected, state => {
      state.filterData = {
        isFetching: false,
        isFetched: false,
        isFailed: true,
        priceRange,
        services: null,
        allServices: null,
        leavingServiceId: null
      };
    });
  }
});
export const {
  bookingOffersSetPageSize,
  bookingOffersSetPage,
  bookingOffersStartSession,
  bookingOffersSetSort,
  bookingOffersSetSearch,
  bookingOffersSetIsNewFetching,
  bookingOffersSetIsNewSearchFetching,
  bookingOffersSetIsDefaultServiceSet,
  bookingOffersSetCategoryId,
  bookingOffersSetFilterServices,
  bookingOffersSetFilterPriceRange
} = slice.actions;
export default slice.reducer;