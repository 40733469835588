import { aspOfferApi } from 'data/api/aspOffer';
export const aspOfferRelatedListArgsSelector = store => store.aspOffer.relatedList.args;
export const aspOfferRelatedListIsEmptySelector = store => {
  const args = store.aspOffer.relatedList.args;
  if (!args) {
    return true;
  }
  const {
    data
  } = aspOfferApi.endpoints.getAspOfferList.select(args)(store);
  return (data?.data?.length ?? 0) === 0;
};