export const breadcrumbTreeExpandTitle = count => `+ ${count}`;
const getParents = (child, parents, dictionary, linkFactory) => {
  const parent = dictionary.find(item => item.id === child.parentId);
  return parent ? getParents(parent, [{
    ...parent,
    link: linkFactory?.(parent)
  }, ...parents], dictionary) : parents;
};
const compare = (index, a, b) => {
  if (index > a.length - 1) return -1;
  const sort = a[index].name.localeCompare(b[index]?.name ?? '');
  return sort === 0 ? compare(++index, a, b) : sort;
};
export const getBreadcrumbChains = (categories, dictionary, linkFactory) => {
  return categories.map(category => getParents(category, [{
    ...category,
    link: linkFactory?.(category)
  }], dictionary, linkFactory)).sort((a, b) => compare(0, a, b));
};