import { Stack, Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AspOfferClaimPendingInfoMessage = () => {
  return _jsx(ClaimMessage, {
    type: "info",
    children: _jsxs(Stack, {
      spacing: 1,
      children: [_jsx(Typography, {
        variant: "body2",
        color: "black",
        children: "\u041E\u043F\u043E\u0432\u0435\u0441\u0442\u0438\u043C \u0432\u0430\u0441 \u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043C \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u043D\u0430 \u043F\u043E\u0447\u0442\u0443 \u043A\u0430\u043A \u0442\u043E\u043B\u044C\u043A\u043E \u0432\u0441\u0435 \u0431\u0443\u0434\u0435\u0442 \u0433\u043E\u0442\u043E\u0432\u043E."
      }), _jsx(Typography, {
        variant: "body2",
        color: "black",
        children: "\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u043C\u043E\u0436\u043D\u043E \u0431\u0443\u0434\u0435\u0442 \u0441\u043A\u0430\u0447\u0430\u0442\u044C \u0432 \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \xAB\u041C\u043E\u0438 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u0438\xBB."
      })]
    })
  });
};