import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useHistory } from 'react-router';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ForbiddenMessage = _ref => {
  let {
    goToBack,
    goToMain
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    children: [_jsx(MPGrid, {
      item: true,
      zero: 12,
      children: _jsx(Typography, {
        variant: "subtitle2",
        color: "error",
        children: "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430"
      })
    }), _jsx(MPGrid, {
      item: true,
      xs: 12,
      children: _jsx(Typography, {
        children: "\u0412\u044B \u043D\u0435 \u0438\u043C\u0435\u0435\u0442\u0435 \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043A \u0434\u0430\u043D\u043D\u043E\u0439 \u0444\u0443\u043D\u043A\u0446\u0438\u0438"
      })
    }), _jsx(MPGrid, {
      item: true,
      zero: 6,
      container: true,
      justifyContent: "flex-end",
      children: _jsx(MPButton, {
        fullWidth: false,
        onClick: goToBack,
        children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430\u0437\u0430\u0434"
      })
    }), _jsx(MPGrid, {
      item: true,
      zero: 6,
      container: true,
      justifyContent: "flex-start",
      children: _jsx(MPButton, {
        fullWidth: false,
        onClick: goToMain,
        children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E"
      })
    })]
  });
};
const ForbiddenComponent = props => {
  const history = useHistory();
  const {
    children = _jsx(ForbiddenMessage, {
      goToBack: () => history.goBack(),
      goToMain: () => history.replace('/')
    })
  } = props;
  return _jsx(Wrapper, {
    children: children
  });
};
export default ForbiddenComponent;