import useTechConfig from '../../../hooks/useTechConfig';
export const Feature = _ref => {
  let {
    feature,
    children,
    stubComponent
  } = _ref;
  const {
    hasFeature
  } = useTechConfig();
  return hasFeature(feature) ? children : stubComponent ? stubComponent : null;
};