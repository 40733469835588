import useBookingOfferCategoriesData from './useBookingOfferCategoriesData';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';
const useBookingOfferDetailsFetchingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesFetching
  } = useBookingOfferCategoriesData();
  const {
    isBookingOfferFetching
  } = useBookingOfferDetailsData(id);
  return isCategoriesFetching || isBookingOfferFetching;
};
export default useBookingOfferDetailsFetchingStatus;