import { getNewArrayExcludingValue } from 'utils/array';
import { api, EUserServicesTag } from '../index';
import { favoritesCountMap, userApi } from '../user';
// выполнение апдейтов признака favorite в офферах
export const createOfferFavoritePatches = _ref => {
  let {
    id,
    type,
    favorite,
    state,
    dispatch
  } = _ref;
  // создаём набор патчей
  return api.util.selectInvalidatedBy(state, [EUserServicesTag.FavoritesCount]).map(cache => {
    const {
      originalArgs,
      endpointName
    } = cache;
    return dispatch(userApi.util.updateQueryData(endpointName, originalArgs, draft => {
      const dataObject = draft;
      const typeCount = dataObject[favoritesCountMap[type]];
      if (favorite) {
        Object.assign(dataObject, {
          ...dataObject,
          [type]: [...dataObject[type], id],
          [favoritesCountMap[type]]: typeCount + 1,
          totalCount: dataObject.totalCount + 1
        });
      } else {
        const removed = getNewArrayExcludingValue(dataObject[type], id);
        if (removed === dataObject[type]) {
          return;
        }
        Object.assign(dataObject, {
          ...dataObject,
          [type]: removed,
          [favoritesCountMap[type]]: typeCount - 1,
          totalCount: dataObject.totalCount - 1
        });
      }
    }));
  });
};