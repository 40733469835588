import { Text } from '../../components/controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutPersonalDataPolicyStep4 = () => {
  return _jsxs(_Fragment, {
    children: [_jsx(Text, {
      children: "9. \u041F\u0440\u0430\u0432\u043E\u0432\u044B\u043C\u0438 \u043E\u0441\u043D\u043E\u0432\u0430\u043D\u0438\u044F\u043C\u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u043D\u0430 \u043E\u0441\u043D\u043E\u0432\u0430\u043D\u0438\u0438 \u043A\u043E\u0442\u043E\u0440\u044B\u0445 \u0434\u043E\u043F\u0443\u0441\u043A\u0430\u0435\u0442\u0441\u044F \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0432 \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB, \u044F\u0432\u043B\u044F\u044E\u0442\u0441\u044F:"
    }), _jsx(Text, {
      children: "1) \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0441 \u0443\u0447\u0435\u0442\u043E\u043C \u0442\u0440\u0435\u0431\u043E\u0432\u0430\u043D\u0438\u0439, \u043F\u0440\u0435\u0434\u0443\u0441\u043C\u043E\u0442\u0440\u0435\u043D\u043D\u044B\u0445 \u0437\u0430\u043A\u043E\u043D\u043E\u0434\u0430\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u043E\u043C \u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438 \u0434\u043B\u044F \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044E\u0449\u0435\u0439 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445;"
    }), _jsx(Text, {
      children: "2) \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u0435 \u0446\u0435\u043B\u0435\u0439, \u043F\u0440\u0435\u0434\u0443\u0441\u043C\u043E\u0442\u0440\u0435\u043D\u043D\u044B\u0445 \u043C\u0435\u0436\u0434\u0443\u043D\u0430\u0440\u043E\u0434\u043D\u044B\u043C \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u043E\u043C \u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438 \u0438\u043B\u0438 \u0437\u0430\u043A\u043E\u043D\u043E\u043C, \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u0435\u043D\u0438\u0435 \u0438 \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435 \u0432\u043E\u0437\u043B\u043E\u0436\u0435\u043D\u043D\u044B\u0445 \u043D\u0430 \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB \u0437\u0430\u043A\u043E\u043D\u043E\u0434\u0430\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u043E\u043C \u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438 \u0438 \u0423\u0441\u0442\u0430\u0432\u043E\u043C \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB \u0444\u0443\u043D\u043A\u0446\u0438\u0439, \u043F\u043E\u043B\u043D\u043E\u043C\u043E\u0447\u0438\u0439 \u0438 \u043E\u0431\u044F\u0437\u0430\u043D\u043D\u043E\u0441\u0442\u0435\u0439;"
    }), _jsx(Text, {
      children: "3) \u0441\u0443\u0434\u0435\u0431\u043D\u044B\u0435 \u0430\u043A\u0442\u044B, \u0430\u043A\u0442\u044B \u0434\u0440\u0443\u0433\u043E\u0433\u043E \u043E\u0440\u0433\u0430\u043D\u0430 \u0438\u043B\u0438 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u043D\u043E\u0433\u043E \u043B\u0438\u0446\u0430, \u043F\u043E\u0434\u043B\u0435\u0436\u0430\u0449\u0438\u0435 \u0438\u0441\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044E \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB \u0432 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0438 \u0441 \u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u044F\u043C\u0438 \u0437\u0430\u043A\u043E\u043D\u043E\u0434\u0430\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u0430 \u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438 \u043E\u0431 \u0438\u0441\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u043C \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0435;"
    }), _jsx(Text, {
      children: "4) \u0434\u043E\u0433\u043E\u0432\u043E\u0440, \u0441\u0442\u043E\u0440\u043E\u043D\u043E\u0439 \u043A\u043E\u0442\u043E\u0440\u043E\u0433\u043E \u043B\u0438\u0431\u043E \u0432\u044B\u0433\u043E\u0434\u043E\u043F\u0440\u0438\u043E\u0431\u0440\u0435\u0442\u0430\u0442\u0435\u043B\u0435\u043C \u0438\u043B\u0438 \u043F\u043E\u0440\u0443\u0447\u0438\u0442\u0435\u043B\u0435\u043C \u043F\u043E \u043A\u043E\u0442\u043E\u0440\u043E\u043C\u0443 \u044F\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u0441\u0443\u0431\u044A\u0435\u043A\u0442 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0434\u043E\u0433\u043E\u0432\u043E\u0440, \u0437\u0430\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044B\u0439 \u043F\u043E \u0438\u043D\u0438\u0446\u0438\u0430\u0442\u0438\u0432\u0435 \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0438\u043B\u0438 \u0434\u043E\u0433\u043E\u0432\u043E\u0440, \u043F\u043E \u043A\u043E\u0442\u043E\u0440\u043E\u043C\u0443 \u0441\u0443\u0431\u044A\u0435\u043A\u0442 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0431\u0443\u0434\u0435\u0442 \u044F\u0432\u043B\u044F\u0442\u044C\u0441\u044F \u0432\u044B\u0433\u043E\u0434\u043E\u043F\u0440\u0438\u043E\u0431\u0440\u0435\u0442\u0430\u0442\u0435\u043B\u0435\u043C \u0438\u043B\u0438 \u043F\u043E\u0440\u0443\u0447\u0438\u0442\u0435\u043B\u0435\u043C;"
    }), _jsx(Text, {
      children: "5) \u043E\u0431\u0435\u0441\u043F\u0435\u0447\u0435\u043D\u0438\u0435 \u0438 (\u0438\u043B\u0438) \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u0435\u043D\u0438\u0435 \u0437\u0430\u0449\u0438\u0442\u044B \u0436\u0438\u0437\u043D\u0438, \u0437\u0434\u043E\u0440\u043E\u0432\u044C\u044F \u0438\u043B\u0438 \u0438\u043D\u044B\u0445 \u0436\u0438\u0437\u043D\u0435\u043D\u043D\u043E \u0432\u0430\u0436\u043D\u044B\u0445 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u043E\u0432 \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u0435\u0441\u043B\u0438 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u044F \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u043D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E;"
    }), _jsx(Text, {
      children: "6) \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u0440\u0430\u0432 \u0438 \u0437\u0430\u043A\u043E\u043D\u043D\u044B\u0445 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u043E\u0432 \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB \u0438\u043B\u0438 \u0442\u0440\u0435\u0442\u044C\u0438\u0445 \u043B\u0438\u0446 \u043B\u0438\u0431\u043E \u0434\u043B\u044F \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F \u043E\u0431\u0449\u0435\u0441\u0442\u0432\u0435\u043D\u043D\u043E \u0437\u043D\u0430\u0447\u0438\u043C\u044B\u0445 \u0446\u0435\u043B\u0435\u0439 \u043F\u0440\u0438 \u0443\u0441\u043B\u043E\u0432\u0438\u0438, \u0447\u0442\u043E \u043F\u0440\u0438 \u044D\u0442\u043E\u043C \u043D\u0435 \u043D\u0430\u0440\u0443\u0448\u0430\u044E\u0442\u0441\u044F \u043F\u0440\u0430\u0432\u0430 \u0438 \u0441\u0432\u043E\u0431\u043E\u0434\u044B \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445;"
    }), _jsx(Text, {
      children: "7) \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0439 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0436\u0443\u0440\u043D\u0430\u043B\u0438\u0441\u0442\u0430 \u0438 (\u0438\u043B\u0438) \u0437\u0430\u043A\u043E\u043D\u043D\u043E\u0439 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u043C\u0430\u0441\u0441\u043E\u0432\u043E\u0439 \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u0438 \u043B\u0438\u0431\u043E \u043D\u0430\u0443\u0447\u043D\u043E\u0439, \u043B\u0438\u0442\u0435\u0440\u0430\u0442\u0443\u0440\u043D\u043E\u0439 \u0438\u043B\u0438 \u0438\u043D\u043E\u0439 \u0442\u0432\u043E\u0440\u0447\u0435\u0441\u043A\u043E\u0439 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438 \u043F\u0440\u0438 \u0443\u0441\u043B\u043E\u0432\u0438\u0438, \u0447\u0442\u043E \u043F\u0440\u0438 \u044D\u0442\u043E\u043C \u043D\u0435 \u043D\u0430\u0440\u0443\u0448\u0430\u044E\u0442\u0441\u044F \u043F\u0440\u0430\u0432\u0430 \u0438 \u0437\u0430\u043A\u043E\u043D\u043D\u044B\u0435 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u044B \u0441\u0443\u0431\u044A\u0435\u043A\u0442\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445;"
    }), _jsx(Text, {
      children: "8) \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0432 \u0441\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u0447\u0435\u0441\u043A\u0438\u0445 \u0438\u043B\u0438 \u0438\u043D\u044B\u0445 \u0438\u0441\u0441\u043B\u0435\u0434\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0445 \u0446\u0435\u043B\u044F\u0445 \u043F\u0440\u0438 \u0443\u0441\u043B\u043E\u0432\u0438\u0438 \u0438\u0445 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E\u0433\u043E \u043E\u0431\u0435\u0437\u043B\u0438\u0447\u0438\u0432\u0430\u043D\u0438\u044F;"
    }), _jsx(Text, {
      children: "9) \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0430 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0432 \u0446\u0435\u043B\u044F\u0445 \u043E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D\u0438\u044F \u0438\u043B\u0438 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E\u0433\u043E \u0440\u0430\u0441\u043A\u0440\u044B\u0442\u0438\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u0438 \u0432 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0438 \u0441 \u0437\u0430\u043A\u043E\u043D\u043E\u0434\u0430\u0442\u0435\u043B\u044C\u0441\u0442\u0432\u043E\u043C \u0420\u043E\u0441\u0441\u0438\u0439\u0441\u043A\u043E\u0439 \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u0438."
    })]
  });
};
export default AboutPersonalDataPolicyStep4;