import { Tab, Tabs } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPTabs = Tabs;
export const MPTab = props => {
  return _jsx(Tab, {
    ...props,
    style: {
      display: props.hidden ? 'none' : 'static'
    }
  });
};