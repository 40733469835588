import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { loggerMiddleware } from 'system/middleware';
import { api } from '../api';
import { rootReducer } from './reducers';
const isProductionMode = process.env.NODE_ENV === 'production';
const isTestMode = process.env.NODE_ENV === 'test';
const projectMiddlewares = !isProductionMode && !isTestMode ? [loggerMiddleware] : [];

// для получения отдельных сторов (полезно для тестов)
export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: gDM => gDM().concat(api.middleware).concat(projectMiddlewares),
    devTools: !isProductionMode && !isTestMode
  });
};
const store = setupStore({});
export const useAppDispatch = useDispatch;
export default store;
setupListeners(store.dispatch);