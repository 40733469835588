import CancelAdapter from 'presentation/features/header/adapters/cancel';
import CatalogAdapter from './adapters/catalog';
import CatalogButtonAdapter from './adapters/catalogButton';
import HeaderLinksAdapter from './adapters/links';
import HeaderLocationAdapter from './adapters/location';
import HeaderLogoAdapter from './adapters/logo';
import MobileHeaderOptionsAdapter from './adapters/mobileOptions/mobileOptions';
import HeaderOptionsAdapter from './adapters/options';
import SearchAdapter from './adapters/search';
import Header from './component';
import { HeaderContext } from './context';
import HeaderDialogs from './dialogs';
import useHeader from './hooks/useHeader';
import BalancesAdapter from './adapters/balances';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderContainer = () => {
  const handlers = useHeader();
  return _jsx(HeaderContext.Provider, {
    value: handlers,
    children: _jsx(Header, {
      logo: _jsx(HeaderLogoAdapter, {}),
      catalogButton: _jsx(CatalogButtonAdapter, {}),
      catalog: _jsx(CatalogAdapter, {}),
      search: _jsx(SearchAdapter, {}),
      cancel: _jsx(CancelAdapter, {}),
      links: _jsx(HeaderLinksAdapter, {}),
      dialogs: _jsx(HeaderDialogs, {}),
      balances: _jsx(BalancesAdapter, {}),
      location: _jsx(HeaderLocationAdapter, {}),
      options: _jsx(HeaderOptionsAdapter, {}),
      mobileOptions: _jsx(MobileHeaderOptionsAdapter, {})
    })
  });
};
export default HeaderContainer;