import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import PartnerDeskDetailsContainer from 'presentation/features/partnerDesk/details/container';
import { EPartnerDeskUrlParam } from 'presentation/features/partnerDesk/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation, useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskScreen = () => {
  const location = useLocation();
  const {
    id
  } = useParams();
  const {
    isCorpUser
  } = useAuthUser();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EPartnerDeskUrlParam.Tab) ?? null;
  const categoryId = searchParams.get(EPartnerDeskUrlParam.Category) ?? null;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(PartnerDeskDetailsContainer, {
        guid: guid,
        id: id,
        tab: tab,
        categoryId: categoryId,
        isCorpUser: isCorpUser,
        isTradeOfferFavoritesAllowed: true,
        isCorpOfferFavoritesAllowed: true,
        isProductOfferFavoritesAllowed: true,
        isBookingOfferFavoritesAllowed: true
      })
    })
  });
};
export default PartnerDeskScreen;