import { SectionTitleStyled } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SectionTitle = props => {
  const {
    gutterBottom = true,
    color = 'textSecondary',
    variant = 'body2',
    ...others
  } = props;
  return _jsx(SectionTitleStyled, {
    variant: variant,
    color: color,
    gutterBottom: gutterBottom,
    ...others
  });
};
export default SectionTitle;