import axios from 'axios';
import { createCancelToken } from './utils';
/**
 * АПИ по работе с НСИ
 */
const nsi = {
  cancelOrderCustomerReasonTypes: _ref => {
    let {
      signal
    } = _ref;
    return {
      url: `/customer/order/cancel-reason-types`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  cancelOrderReasonTypes: _ref2 => {
    let {
      signal
    } = _ref2;
    return {
      url: `/orders/cancel-reason-types`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default nsi;