import { Typography } from '@mui/material';
import { SystemEventContentBuilderLayout } from 'features/systemEvent';
import { phoneFormatView } from 'presentationUtils/phone';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { InfoText } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfirmStepAdapter = _ref => {
  let {
    phone,
    onSubmit,
    onChange,
    onCancel
  } = _ref;
  return _jsx(SystemEventContentBuilderLayout, {
    title: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
    actions: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: () => onSubmit(phone),
        children: "\u0414\u0430, \u044D\u0442\u043E \u043C\u043E\u0439 \u043D\u043E\u043C\u0435\u0440"
      }), _jsx(MPButton, {
        color: "brand",
        variant: "text",
        fullWidth: true,
        size: "large",
        onClick: onChange,
        children: "\u0423\u043A\u0430\u0437\u0430\u0442\u044C \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430"
      })]
    }),
    children: _jsxs(InfoText, {
      variant: "body2",
      children: ["\u041D\u043E\u043C\u0435\u0440", ' ', _jsx(Typography, {
        variant: "subtitle2",
        component: "span",
        children: phoneFormatView(phone)
      }), ' ', "\u0432\u0430\u0448? \u041C\u0435\u043D\u0435\u0434\u0436\u0435\u0440 \u043F\u0435\u0440\u0435\u0437\u0432\u043E\u043D\u0438\u0442 \u043D\u0430 \u043D\u0435\u0433\u043E \u0434\u043B\u044F \u0443\u0442\u043E\u0447\u043D\u0435\u043D\u0438\u044F \u0434\u0435\u0442\u0430\u043B\u0435\u0439 \u043F\u043E \u0437\u0430\u043A\u0430\u0437\u0443."]
    })
  });
};