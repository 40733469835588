import { CmsContainerShowAllLink } from '../../components/showAllLink';
import { getPartnerDeskListRoute } from '../../../partnerDesk/entry';

/**
 * адаптер который добавляет в хедер кнопку "Посмотреть всё"
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CmsPartnersCollectionShowAllAdapter = () => {
  const link = getPartnerDeskListRoute({});
  return _jsx(CmsContainerShowAllLink, {
    to: link
  });
};