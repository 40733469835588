import SmartPhoneFixedBar from 'presentation/components/common/smartPhoneFixedBar';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferSmartPhoneFixedBar = props => {
  const {
    text,
    favorite,
    onBack
  } = props;
  return _jsx(SmartPhoneFixedBar, {
    onBack: onBack,
    text: text,
    favorite: favorite
  });
};
export default OfferSmartPhoneFixedBar;