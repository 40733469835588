import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Children } from 'react';
import { FooterWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const FooterGroup = props => {
  const {
    label,
    footer,
    children
  } = props;
  return _jsxs(Wrapper, {
    children: [_jsxs(MPGrid, {
      container: true,
      spacing: 1,
      direction: "column",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          component: "div",
          variant: "subtitle2",
          children: label
        })
      }), Children.toArray(children).map((child, index) => _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          component: "div",
          variant: "body2",
          children: child
        })
      }, index))]
    }), footer && _jsx(FooterWrapper, {
      children: footer
    })]
  });
};
export default FooterGroup;