/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, createRequest } from './http-client';
export const CustomersApi = {
  /**
   * No description
   * * @tags Customers
   * @name LikeOffer
   * @summary Добавить оффер в избранное
   * @request POST:/customers/current/favorites/offers
   * @secure
   */
  likeOffer: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/favorites/offers`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name DislikeOffer
   * @summary Удалить оффер из избранного
   * @request DELETE:/customers/current/favorites/offers/{offerId}
   * @secure
   */
  dislikeOffer: (baseURL, offerId, signal) => createRequest({
    baseURL,
    path: `/customers/current/favorites/offers/${offerId}`,
    method: 'DELETE',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name SubscribeToOffer
   * @summary Подписаться на появление предложения в доступе
   * @request POST:/customers/current/subscriptions/offers
   * @secure
   */
  subscribeToOffer: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/subscriptions/offers`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name UnsubscribeFromOffer
   * @summary Удалить подписку на предложение
   * @request DELETE:/customers/current/subscriptions/offers/{offerId}
   * @secure
   */
  unsubscribeFromOffer: (baseURL, offerId, signal) => createRequest({
    baseURL,
    path: `/customers/current/subscriptions/offers/${offerId}`,
    method: 'DELETE',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerTradeOffers
   * @summary Получение списка торговых предложений(ТП) для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/trade-offers
   * @secure
   */
  listCustomerTradeOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/trade-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerTradeOffers
   * @summary Получение списка торговых предложений(ТП) для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/trade-offers
   * @secure
   */
  listCustomerTradeOffersTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/trade-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerTradeOfferById
   * @summary Получение ТП по id
   * @request GET:/customers/current/trade-offers/{tradeOfferId}
   * @secure
   */
  getCustomerTradeOfferById: (baseURL, tradeOfferId, signal) => createRequest({
    baseURL,
    path: `/customers/current/trade-offers/${tradeOfferId}`,
    method: 'GET',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerTradeOfferCategories
   * @summary Получение списка категорий ТП для покупателя
   * @request GET:/customers/current/trade-offers/categories
   * @secure
   */
  listCustomerTradeOfferCategories: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/trade-offers/categories`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerOfferActivationAvailability
   * @summary Проверка доступности активации для предложения
   * @request GET:/customers/current/offers/{offerId}/activation-availability
   * @secure
   */
  getCustomerOfferActivationAvailability: (baseURL, _ref, signal) => {
    let {
      offerId,
      ...query
    } = _ref;
    return createRequest({
      baseURL,
      path: `/customers/current/offers/${offerId}/activation-availability`,
      method: 'GET',
      query: query,
      format: 'json',
      signal
    });
  },
  /**
   * No description
   * * @tags Customers
   * @name ListCurrentCustomerFavoriteOffers
   * @summary Получение списка избранных предложений текущего пользователя
   * @request GET:/customers/current/offers/favorite
   * @secure
   */
  listCurrentCustomerFavoriteOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/offers/favorite`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCurrentCustomerFavoriteOffers
   * @summary Получение списка избранных предложений текущего пользователя
   * @request GET:/customers/current/offers/favorite
   * @secure
   */
  listCurrentCustomerFavoriteOffersTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/offers/favorite`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCurrentCustomerSubscribedOffers
   * @summary Получение списка предложений, на которые подписался текущий пользователь
   * @request GET:/customers/current/offers/subscribed
   * @secure
   */
  listCurrentCustomerSubscribedOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/offers/subscribed`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerOffers
   * @summary Получение списка предложений с фильтром
   * @request GET:/customers/current/offers
   * @secure
   */
  listCustomerOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerCorpOffers
   * @summary Получение списка КП для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/corp-offers
   * @secure
   */
  listCustomerCorpOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/corp-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerCorpOffers
   * @summary Получение списка КП для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/corp-offers
   * @secure
   */
  listCustomerCorpOffersTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/corp-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerCorpOfferById
   * @summary Получение КП по id
   * @request GET:/customers/current/corp-offers/{corpOfferId}
   * @secure
   */
  getCustomerCorpOfferById: (baseURL, corpOfferId, signal) => createRequest({
    baseURL,
    path: `/customers/current/corp-offers/${corpOfferId}`,
    method: 'GET',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerCorpOfferCategories
   * @summary Получение списка категорий КП по фильтрам для покупателя
   * @request GET:/customers/current/corp-offers/categories
   * @secure
   */
  listCustomerCorpOfferCategories: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/corp-offers/categories`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerProductOffers
   * @summary Получение списка товаров для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/product-offers
   * @secure
   */
  listCustomerProductOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/product-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerProductOffers
   * @summary Получение списка товаров для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/product-offers
   * @secure
   */
  listCustomerProductOffersTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/product-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerProductOfferById
   * @summary Получение товара по id
   * @request GET:/customers/current/product-offers/{productOfferId}
   * @secure
   */
  getCustomerProductOfferById: (baseURL, productOfferId, signal) => createRequest({
    baseURL,
    path: `/customers/current/product-offers/${productOfferId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerProductOfferCategories
   * @summary Получение списка категорий товаров по фильтрам для покупателя
   * @request GET:/customers/current/product-offers/categories
   * @secure
   */
  listCustomerProductOfferCategories: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/product-offers/categories`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListBannersForCurrentUser
   * @summary Список баннеров для текущего пользователя
   * @request GET:/customers/current/banners
   * @secure
   */
  listBannersForCurrentUser: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/banners`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListBonusTransactions
   * @summary Список транзакций бонусного счета покупателя
   * @request GET:/customers/{userId}/bonuses/transactions
   * @secure
   */
  listBonusTransactions: (baseURL, _ref2, signal) => {
    let {
      userId,
      ...query
    } = _ref2;
    return createRequest({
      baseURL,
      path: `/customers/${userId}/bonuses/transactions`,
      method: 'GET',
      query: query,
      format: 'json',
      signal
    });
  },
  /**
   * No description
   * * @tags Customers
   * @name GetBonusTransactionById
   * @summary Транзакции бонусного счета покупателя
   * @request GET:/customers/{userId}/bonuses/transactions/{transactionId}
   * @secure
   */
  getBonusTransactionById: (baseURL, transactionId, userId, signal) => createRequest({
    baseURL,
    path: `/customers/${userId}/bonuses/transactions/${transactionId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ActivatePersonalPromotion
   * @summary Активировать предложение
   * @request POST:/customers/current/activations
   * @secure
   */
  activatePersonalPromotion: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/activations`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetPersonalPromotionActivations
   * @summary Получить список всех своих активаций
   * @request GET:/customers/current/activations
   * @secure
   */
  getPersonalPromotionActivations: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/activations`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name GetPersonalPromotionActivations
   * @summary Получить список всех своих активаций
   * @request GET:/customers/current/activations
   * @secure
   */
  getPersonalPromotionActivationsTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/activations`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetPersonalPromotionActivationById
   * @summary Получение заявки на активацию привилегии
   * @request GET:/customers/current/activations/{activationId}
   * @secure
   */
  getPersonalPromotionActivationById: (baseURL, activationId, signal) => createRequest({
    baseURL,
    path: `/customers/current/activations/${activationId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * @description Если такой ваучер выдан пользователю Разрешён только самому пользователю и
   * * @tags Customers
   * @name DownloadOfferCert
   * @summary Файл с правом на привилегию(сертификат/ваучер)
   * @request GET:/customers/current/activations/{activationId}/cert
   * @secure
   */
  downloadOfferCert: (baseURL, activationId, signal) => createRequest({
    baseURL,
    path: `/customers/current/activations/${activationId}/cert`,
    method: 'GET',
    signal
  }),
  /**
   * @description Создать заказ в Витрине
   * * @tags Customers
   * @name CreateCustomerOrders
   * @summary Сделать заказ
   * @request POST:/customers/current/orders
   * @secure
   */
  createCustomerOrders: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/orders`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerOrders
   * @summary Список заказов покупателя
   * @request GET:/customers/current/orders
   * @secure
   */
  listCustomerOrders: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/orders`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerOrderById
   * @summary Получение заказа покупателя
   * @request GET:/customers/current/orders/{orderId}
   * @secure
   */
  getCustomerOrderById: (baseURL, orderId, signal) => createRequest({
    baseURL,
    path: `/customers/current/orders/${orderId}`,
    method: 'GET',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name PostCustomerCommandForOrder
   * @summary Допустимые команды к сущности
   * @request POST:/customers/current/orders/{orderId}
   * @secure
   */
  postCustomerCommandForOrder: (baseURL, orderId, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/orders/${orderId}`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetBasket
   * @summary Получение всей корзины
   * @request GET:/customers/current/basket
   * @secure
   */
  getBasket: (baseURL, signal) => createRequest({
    baseURL,
    path: `/customers/current/basket`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * @description Добавление товарного предложения в корзину
   * * @tags Customers
   * @name AddOfferToBasket
   * @summary Добавление товарного предложения в корзину
   * @request POST:/customers/current/basket/items
   * @secure
   */
  addOfferToBasket: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/basket/items`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetBasketItemById
   * @summary Получение элемента корзины
   * @request GET:/customers/current/basket/items/{basketItem}
   * @secure
   */
  getBasketItemById: (baseURL, basketItem, signal) => createRequest({
    baseURL,
    path: `/customers/current/basket/items/${basketItem}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * @description удаление элемента корзины
   * * @tags Customers
   * @name DeleteBasketItem
   * @summary удаление элемента корзины
   * @request DELETE:/customers/current/basket/items/{basketItem}
   * @secure
   */
  deleteBasketItem: (baseURL, basketItem, signal) => createRequest({
    baseURL,
    path: `/customers/current/basket/items/${basketItem}`,
    method: 'DELETE',
    signal
  }),
  /**
   * @description изменение элемента корзины
   * * @tags Customers
   * @name UpdateBasketItem
   * @summary изменение элемента корзины
   * @request PUT:/customers/current/basket/items/{basketItem}
   * @secure
   */
  updateBasketItem: (baseURL, basketItem, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/basket/items/${basketItem}`,
    method: 'PUT',
    body: data,
    type: ContentType.Json,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListPartnerFirstSymbols
   * @summary Получение списка первых символов названия партнеров из выборки партнеров, доступных на Витрине
   * @request GET:/customers/current/partner-desks/name-map
   * @secure
   */
  listPartnerFirstSymbols: (baseURL, signal) => createRequest({
    baseURL,
    path: `/customers/current/partner-desks/name-map`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * @description Карточки выдаются с предустановленными фильтрами: - партнер активен - карточка активна (заполнены все обязательные поля)
   * * @tags Customers
   * @name ListCustomerPartnerDesks
   * @summary Список карточек партнеров.
   * @request GET:/customers/current/partner-desks
   * @secure
   */
  listCustomerPartnerDesks: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/partner-desks`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * @description Сортировка: * по популярности - rating(по умолчанию) * сначала дешевле - minPrice (ASC|DESC) * сначала новые   - new (ASC|DESC)
   * * @tags Customers
   * @name ListCustomerBookingOffers
   * @summary Получение списка объектов букинга для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/booking-offers
   * @secure
   */
  listCustomerBookingOffers: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * @description Сортировка: * по популярности - rating(по умолчанию) * сначала дешевле - minPrice (ASC|DESC) * сначала новые   - new (ASC|DESC)
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerBookingOffers
   * @summary Получение списка объектов букинга для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/booking-offers
   * @secure
   */
  listCustomerBookingOffersTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-offers`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerBookingOfferById
   * @summary Получение объекта букинга по id
   * @request GET:/customers/current/booking-offers/{bookingOfferId}
   * @secure
   */
  getCustomerBookingOfferById: (baseURL, bookingOfferId, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-offers/${bookingOfferId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingServiceCategories
   * @summary Получение списка категорий услуг букинга для текущего покупателя
   * @request GET:/customers/current/booking-services/categories
   * @secure
   */
  listCustomerBookingServiceCategories: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-services/categories`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingOffersCategories
   * @summary Получение списка категорий предложений букинга для текущего покупателя
   * @request GET:/customers/current/booking-offers/categories
   * @secure
   */
  listCustomerBookingOffersCategories: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-offers/categories`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingPriceRange
   * @summary Получение минимальной и максимальной цены услуг в предложениях букинга
   * @request GET:/customers/current/booking-price-range
   * @secure
   */
  listCustomerBookingPriceRange: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/booking-price-range`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name ListCustomerSocialPackages
   * @summary Получение списка соц пакетов текущего покупателя
   * @request GET:/customers/current/social-packages
   * @secure
   */
  listCustomerSocialPackages: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/social-packages`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerSocialPackages
   * @summary Получение списка соц пакетов текущего покупателя
   * @request GET:/customers/current/social-packages
   * @secure
   */
  listCustomerSocialPackagesTyped: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customers/current/social-packages`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name GetCustomerSocialPackageById
   * @summary Получение соцПакета по id
   * @request GET:/customers/current/social-packages/{socialPackageId}
   * @secure
   */
  getCustomerSocialPackageById: (baseURL, socialPackageId, signal) => createRequest({
    baseURL,
    path: `/customers/current/social-packages/${socialPackageId}`,
    method: 'GET',
    signal
  }),
  /**
   * No description
   * * @tags Customers
   * @name HandleCustomerSocialPackageCommand
   * @summary Допустимые для пользователя действия по отношению к СВОЕМУ соц пакету
   * @request POST:/customers/current/social-packages/{socialPackageId}
   * @secure
   */
  handleCustomerSocialPackageCommand: (baseURL, socialPackageId, data, signal) => createRequest({
    baseURL,
    path: `/customers/current/social-packages/${socialPackageId}`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    signal
  })
};