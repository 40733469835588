import axios from 'axios';
import { createCancelToken } from './utils';
/**
 * АПИ по работе с торговыми предложениями
 */
const tradeOffer = {
  categories: _ref => {
    let {
      signal,
      ids
    } = _ref;
    const params = new URLSearchParams();
    ids?.forEach(id => params.append('category', id));
    return {
      url: '/trade-offer-categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  category: _ref2 => {
    let {
      signal,
      id
    } = _ref2;
    return {
      url: `/trade-offer-categories/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default tradeOffer;