import { Typography } from '@mui/material';
import { EDateTimeFormat } from '../../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../../utils/date';
import { getCorpOfferPromotionLabel } from '../../../../../utils';
import useCorpOfferClaimContext from '../../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PromotionTypeText = _ref => {
  let {
    promotionType
  } = _ref;
  return _jsx(Typography, {
    variant: "body2",
    color: "black",
    children: getCorpOfferPromotionLabel(promotionType)
  });
};
const ClaimPromotionDateActivateNow = () => {
  return _jsx(Typography, {
    variant: "body2",
    color: "textSuccess",
    children: "\u043F\u043E\u043B\u0443\u0447\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u0447\u0442\u043E"
  });
};
const ClaimPromotionDate = props => {
  const {
    appointmentDate
  } = props;
  return _jsxs(Typography, {
    variant: "body2",
    children: ["\u043E\u0442 ", utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)]
  });
};
const CorpOfferPromotionTypeAdapter = () => {
  const {
    corpOffer,
    isActivationReceivedNow,
    activation
  } = useCorpOfferClaimContext();
  if (!corpOffer) {
    return null;
  }
  return _jsxs(Wrapper, {
    children: [_jsx(PromotionTypeText, {
      promotionType: corpOffer.promotionType
    }), isActivationReceivedNow && _jsx(ClaimPromotionDateActivateNow, {}), !isActivationReceivedNow && activation.lastActivation?.appointmentDate && _jsx(ClaimPromotionDate, {
      appointmentDate: activation.lastActivation.appointmentDate
    })]
  });
};
export default CorpOfferPromotionTypeAdapter;