import { buildAuthIDPBindProfileUrl } from '@mp-npm/mp-auth-client';
import { useGetCurrentUserQuery } from 'data/api/user';
import { EUserRole } from 'domain/model/enums';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { getAllowedRoles, getUserDefaultRoute } from 'presentation/features/user/utils';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { authCheckIdpLinkPage } from 'presentation/utils/auth';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppConfigurator } from 'system/appConfigurator';
import { v4 as uuid } from 'uuid';
import HttpClient from '../../../../data/network/http';
import { authProviderTokenParsedAccessSelector } from './store/selectors';
const hasRole = roles => role => roles?.some(allowedRole => role === allowedRole) ?? false;
export const useAuthUser = () => {
  const {
    hasFeature
  } = useTechConfig();
  const {
    tokenParsed
  } = useAuthToken();
  const accessRoles = useSelector(authProviderTokenParsedAccessSelector);
  const roles = useMemo(() => getAllowedRoles(accessRoles ?? []), [accessRoles]);
  const defaultRoute = useMemo(() => getUserDefaultRoute(hasRole(roles), hasFeature), [hasFeature, roles]);
  const isCorpUser = roles.some(r => r === EUserRole.Corp);
  const {
    data: user
  } = useGetCurrentUserQuery({}, {
    skip: !roles?.length
  });
  const bindRzhdProfile = () => {
    if (!tokenParsed) {
      return;
    }
    const {
      realm,
      idpRzhdPortal,
      clientId,
      url: authServiceUrl
    } = AppConfigurator.getInstance().getApiAuthService();
    const {
      sid: sessionState
    } = tokenParsed;
    const url = buildAuthIDPBindProfileUrl({
      authUrl: authServiceUrl,
      nonce: uuid(),
      sessionState: sessionState ?? '',
      idpId: idpRzhdPortal,
      clientId,
      realm,
      authCheckIdpLinkPage
    });
    console.log('Запрос на привязку профиля стороннего IDP', url);
    const newWindow = window.open(url, 'bindRzhdProfile', 'width=500,height=600,menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes');
    if (!newWindow) {
      console.warn('В браузере установлен запрет на открытие всплывающих окон, будет выполнена попытка синхронной авторизации');
      window.location.href = url;
    }
  };
  const onResetPassword = async () => {
    const fn = HttpClient.getInstance().getAuthService()?.onResetPassword;
    if (!fn) {
      return Promise.reject(new Error('Функция не определена'));
    }
    if (user?.id) {
      return fn(user.id);
    } else {
      return Promise.reject(new Error('Текущий пользователь не определен'));
    }
  };
  return {
    user: user ?? null,
    userId: user?.id ?? null,
    roles,
    isCorpUser,
    defaultRoute,
    bindRzhdProfile,
    onResetPassword
  };
};