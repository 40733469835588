import { HeaderTitleWrapper, HeaderWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CmsCollectionLayout = props => {
  const {
    footer,
    header,
    showAll,
    children
  } = props;
  return _jsxs(Wrapper, {
    children: [(header || showAll) && _jsxs(HeaderWrapper, {
      children: [_jsx(HeaderTitleWrapper, {
        children: header
      }), showAll]
    }), children, footer]
  });
};