import { EBannerPartition } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BannersMainTopContainer from '../../../../banners/mainTop/container';
import { tradeOfferListSearchIsEmptySelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferListBannersAdapter = _ref => {
  let {
    guid
  } = _ref;
  const isEmptySearch = useSelector(tradeOfferListSearchIsEmptySelector);
  return useMemo(() => isEmptySearch ? _jsx(BannersMainTopContainer, {
    guid: guid,
    partition: EBannerPartition.TradeOffers
  }) : null, [guid, isEmptySearch]);
};