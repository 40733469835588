import UserMenuContainer from 'presentation/features/user/menu/container';
import { useMemo } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderUserMenuAdapter = props => {
  const {
    onLocationClick
  } = props;
  return useMemo(() => _jsx(UserMenuContainer, {
    onLocationClick: onLocationClick
  }), [onLocationClick]);
};
export default HeaderUserMenuAdapter;