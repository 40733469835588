import { Typography } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ClaimTitle = _ref => {
  let {
    text
  } = _ref;
  return _jsx(MPChip, {
    size: "micro",
    label: _jsx(Typography, {
      fontWeight: 500,
      variant: "body2",
      children: text
    }),
    color: "warning"
  });
};
export default ClaimTitle;