import { Text } from './controls';
import { getSiteCaption } from '../../../../utils';
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutCookiesHowToOptOutInfo = () => {
  return _jsx(_Fragment, {
    children: _jsxs(Text, {
      children: ["\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u0435\u0440\u0435\u0434 \u0437\u0430\u0445\u043E\u0434\u043E\u043C \u043D\u0430 \u0441\u0430\u0439\u0442 \u043E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u0441\u0432\u043E\u0439 \u0444\u0430\u0439\u043B\u044B Cookies, \u043F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043E\u0431 \u044D\u0442\u043E\u043C - \u0432 \u0441\u043F\u0440\u0430\u0432\u043A\u0435 \u0432\u0430\u0448\u0435\u0433\u043E \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0430. \u0412 \u0442\u0430\u043A\u043E\u043C \u0441\u043B\u0443\u0447\u0430\u0435 \u043D\u0430\u0448 \u0432\u0435\u0431-\u0441\u0430\u0439\u0442 \u0431\u0443\u0434\u0435\u0442 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u0442\u0435 Cookies, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0441\u0442\u0440\u043E\u0433\u043E \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u044B \u0434\u043B\u044F \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0432\u0435\u0431-\u0441\u0430\u0439\u0442\u0430 \u0438 \u043F\u0440\u0435\u0434\u043B\u0430\u0433\u0430\u0435\u043C\u044B\u0445 \u0438\u043C \u0443\u0441\u043B\u0443\u0433, \u0441\u0435\u0440\u0432\u0438\u0441\u043E\u0432. ", getSiteCaption(), " \u043E\u0441\u0442\u0430\u0432\u043B\u044F\u0435\u0442 \u0437\u0430 \u0441\u043E\u0431\u043E\u0439 \u043F\u0440\u0430\u0432\u043E \u043E\u0431\u043D\u043E\u0432\u043B\u044F\u0442\u044C \u0434\u0430\u043D\u043D\u0443\u044E \u043F\u043E\u043B\u0438\u0442\u0438\u043A\u0443."]
    })
  });
};
export default AboutCookiesHowToOptOutInfo;