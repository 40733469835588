import { useMemo, useState } from 'react';
import CategorySelectItemWrapper from '../selectItemWrapper';
import CategorySelectLeaf from '../selectLeaf';
import { FirstLeaf } from './controls';
import CategorySelectShowMore from './showMore';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
//вывод категорий дуплексом - родитель и под ним все дети, причем с возможностью свернуть/развернуть детей
const CategorySelectDouble = props => {
  const {
    category,
    initialVisibleCount,
    selected,
    isInSelectedPath,
    onClick,
    scrollToCategory
  } = props;
  const childrenCount = category.children?.length ?? 0;
  const isSelectedFirstLeaf = useMemo(() => isInSelectedPath(category), [category, isInSelectedPath]);

  //необходимость развернуть список категорий в случае если выбранная категория скрыта
  const needExpand = isSelectedFirstLeaf && selected && category.children?.findIndex(item => item.id === selected.id) > initialVisibleCount;
  const [visibleCount, setVisibleCount] = useState(needExpand ? childrenCount : initialVisibleCount);
  const onExpandOrCollapse = () => {
    if (visibleCount === childrenCount) {
      setVisibleCount(initialVisibleCount);
    } else {
      setVisibleCount(childrenCount);
    }
  };
  const visibleItems = category.children?.slice(0, visibleCount);
  const expanded = visibleCount > initialVisibleCount;
  return _jsxs("div", {
    children: [_jsx(FirstLeaf, {
      children: _jsx(CategorySelectItemWrapper, {
        children: _jsx(CategorySelectLeaf, {
          onClick: onClick,
          category: category
        })
      })
    }), visibleItems?.map(child => _jsx(CategorySelectItemWrapper, {
      children: _jsx(CategorySelectLeaf, {
        category: child,
        selected: isInSelectedPath(child),
        scrollToCategory: scrollToCategory,
        onClick: onClick
      })
    }, child.id)), category.children?.length > initialVisibleCount && _jsx(CategorySelectItemWrapper, {
      onClick: () => null,
      children: _jsx(CategorySelectShowMore, {
        expanded: expanded,
        onExpandOrCollapse: onExpandOrCollapse
      })
    })]
  });
};
export default CategorySelectDouble;