import { Hidden, Typography } from '@mui/material';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import CodeTitle from '../../../../../components/common/codeTitle/title';
import { Bold } from '../../../../user/details/activations/controls';
import { CommentTitle, CommentWrapper, SidebarWrapper } from '../controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemPendingSidebar = props => {
  const {
    offerName,
    offerCode,
    comment,
    onOfferClick
  } = props;
  const {
    x2
  } = useGridSpacing();
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: x2,
      direction: "column",
      children: [_jsx(Hidden, {
        smUp: true,
        children: _jsx(MPGrid, {
          item: true,
          children: _jsx(Bold, {
            onClick: onOfferClick,
            children: offerName
          })
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(CodeTitle, {
          code: offerCode
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          gutterBottom: true,
          children: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043D\u043E"
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(CommentWrapper, {
          children: _jsxs(MPGrid, {
            container: true,
            spacing: x2,
            alignItems: "center",
            children: [_jsx(MPGrid, {
              item: true,
              children: _jsx(CommentTitle, {
                children: "\u0417\u0430\u044F\u0432\u043A\u0430 \u043E\u0431\u0440\u0430\u0431\u0430\u0442\u044B\u0432\u0430\u0435\u0442\u0441\u044F"
              })
            }), comment && _jsx(MPGrid, {
              item: true,
              children: _jsx(Typography, {
                variant: "body2",
                children: comment
              })
            })]
          })
        })
      })]
    })
  });
};
export default ActivationListItemPendingSidebar;