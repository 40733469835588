import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferType } from 'domain/model/enums';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getOffersListQueryParams } from 'presentation/features/offer/utils';
export let ECorpOfferUrlParam = function (ECorpOfferUrlParam) {
  ECorpOfferUrlParam["Tab"] = "tab";
  ECorpOfferUrlParam[ECorpOfferUrlParam["Category"] = ECatalogUrlParam.Category] = "Category";
  ECorpOfferUrlParam["PartnerId"] = "partnerId";
  return ECorpOfferUrlParam;
}({});
export const corpOffersDefaultParams = getOffersListQueryParams({
  name: null,
  categories: null,
  partnerId: null,
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null
}, EOfferType.Corp);