import validator from 'validator';
import { EValidationMessages, EValidationType } from '../../utils/validation';
function validateObject(object, rules) {
  let results = {};
  let hasErrors = false;
  Object.keys(rules).forEach(key => {
    if (!rules[key]) {
      return;
    }
    const value = object[key];
    const {
      required,
      validator: customValidator,
      requiredMessage
    } = rules[key];
    let resultItem = null;
    let isRequired;
    if (typeof required === 'function') {
      isRequired = required(object, value);
    } else {
      isRequired = required ?? false;
    }
    if (isRequired && (value === null || value === undefined || typeof value === 'string' && validator.isEmpty(value))) {
      resultItem = {
        type: EValidationType.Error,
        hasError: true,
        message: requiredMessage ?? EValidationMessages.Empty
      };
    } else {
      if (customValidator) {
        resultItem = customValidator(object, value);
      }
    }
    if (results && resultItem) {
      results[key] = resultItem;
      if (resultItem.hasError) hasErrors = true;
    }
  });
  if (Object.keys(results).length === 0) {
    results = null;
  }
  return {
    results,
    isValid: !hasErrors
  };
}
export default validateObject;