import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { AboutTextSimpleLayout } from 'presentation/layouts/aboutSimple';
import useBookingOfferDetailsLoadingStatus from '../hooks/useBookingOfferDetailsLoadingStatus';
import BookingOfferBreadcrumbAdapter from '../adapters/breadcrumb';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferSimpleLayout = _ref => {
  let {
    id,
    title,
    close,
    children
  } = _ref;
  const isBookingOfferLoading = useBookingOfferDetailsLoadingStatus({
    id
  });
  return _jsx(Fade, {
    in: true,
    children: _jsxs(DefaultContentWrapper, {
      children: [_jsx(BookingOfferBreadcrumbAdapter, {
        id: id
      }), isBookingOfferLoading ? null : _jsx(AboutTextSimpleLayout, {
        title: title,
        close: close,
        children: children
      })]
    })
  });
};
export default BookingOfferSimpleLayout;