import { IconTooltip, IconWrapper, Text, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CmsContainerHeader = _ref => {
  let {
    text,
    infoText,
    Icon
  } = _ref;
  return _jsxs(Wrapper, {
    children: [Icon && _jsx(IconTooltip, {
      disabled: !text,
      title: infoText,
      children: _jsx(IconWrapper, {
        children: Icon
      })
    }), _jsx(Text, {
      variant: text ? 'h1' : 'body2',
      children: text ?? infoText
    })]
  });
};
export default CmsContainerHeader;