import { RequiredActionConfirmCodeContainer } from '../../../requiredAction/confirmCode/container';
import { useCodeConfirm } from '../hooks/useCodeConfirm';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CodeConfirmationAdapter = props => {
  const {
    onClose,
    onNext
  } = props;
  const {
    email,
    source,
    onConfirmed,
    onGetNewCode
  } = useCodeConfirm();
  const onSuccess = () => {
    onConfirmed();
    onNext();
  };
  return source && _jsx(RequiredActionConfirmCodeContainer, {
    email: email,
    source: source,
    onSuccess: onSuccess,
    onGetNewCode: onGetNewCode,
    onClose: onClose
  });
};