import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OfferFilterPrice } from '../../../components/filterPrice';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { ProductFilterItem } from '../item';
import { productOfferFilterMaxPriceSelector, productOfferFilterMinPriceSelector, productOfferFilterPriceRangeSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductFilterPriceAdapter = () => {
  const handlers = useContextHandlers();
  const priceRange = useSelector(productOfferFilterPriceRangeSelector);
  const minPrice = useSelector(productOfferFilterMinPriceSelector);
  const maxPrice = useSelector(productOfferFilterMaxPriceSelector);
  return useMemo(() => _jsx(ProductFilterItem, {
    label: "\u0426\u0435\u043D\u0430",
    isActive: true,
    children: _jsx(OfferFilterPrice, {
      min: minPrice,
      max: maxPrice,
      value: priceRange,
      onChange: handlers.onChangePrice
    })
  }), [minPrice, maxPrice, priceRange, handlers.onChangePrice]);
};
export default ProductFilterPriceAdapter;