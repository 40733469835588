import { useCallback, useEffect, useMemo, useRef } from 'react';
import { eventBusDomLabel } from './utils';
const debug = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return console.debug('EventBus [core]', ...args);
};
/**
 * @description шина событий
 */
export const useEventBus = () => {
  const listeners = useMemo(() => ({}), []);
  const element = useRef();
  useEffect(() => {
    const nodeIterator = document.createNodeIterator(document.body, NodeFilter.SHOW_COMMENT);
    const comment = nodeIterator.nextNode();
    if (comment && 'data' in comment && comment.data === eventBusDomLabel) {
      element.current = comment;
    }
  }, []);
  const error = useCallback(() => {
    throw new Error('EventBus not found');
  }, []);
  const publish = useCallback(function (eventName) {
    if (element.current) {
      for (var _len2 = arguments.length, payloads = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        payloads[_key2 - 1] = arguments[_key2];
      }
      payloads.forEach(payload => {
        const event = new CustomEvent(eventName, {
          detail: payload
        });
        debug('publish', event);
        element.current.dispatchEvent(event);
      });
    } else {
      error();
    }
  }, [error]);
  const subscribe = useCallback((eventName, callback) => {
    if (element.current) {
      let callbackEvent;
      if (eventName in listeners) {
        callbackEvent = listeners[eventName];
      } else {
        callbackEvent = event => {
          callback(event.detail);
        };
        listeners[eventName] = callbackEvent;
      }
      element.current.addEventListener(eventName, callbackEvent);
    } else {
      error();
    }
  }, [error, listeners]);
  const unsubscribe = useCallback(eventName => {
    element.current?.removeEventListener(eventName, listeners[eventName] || null);
  }, [listeners]);
  return {
    publish,
    subscribe,
    unsubscribe
  };
};