import { UserMenuItem } from 'presentation/features/user/components/menuItem';
import { LogoutIcon } from 'presentation/media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemLogout = _ref => {
  let {
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: "\u0412\u044B\u0439\u0442\u0438",
    icon: _jsx(LogoutIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};