import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'details',
  initialState: {
    guid: null
  },
  reducers: {
    tradeOfferDetailsStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      state.guid = guid;
    }
  }
});
export const {
  tradeOfferDetailsStartSession
} = slice.actions;
export default slice.reducer;