import { Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPLink } from 'presentation/theme/ui-kit/link';
import { useEffect, useRef, useState } from 'react';
import DangerouslyHtmlWrapper from '../../../../components/common/wrappers/dangerouslyHtml';
import { WebWindowIcon } from '../../../../media/icons';
import { ContainerLimiter, DangerouslyHtmlWrapperInner, Wrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferConditions = _ref => {
  let {
    onClickExtend,
    title,
    text
  } = _ref;
  const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const upXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const contentElementRef = useRef(null);
  const rowCount = upXl ? 10 : upLg ? 6 : 8;
  const [isOverflowed, setIsOverflowed] = useState(false);
  useEffect(() => {
    const el = contentElementRef.current;
    if (el) {
      const {
        scrollWidth,
        offsetWidth,
        scrollHeight,
        offsetHeight
      } = el;
      setIsOverflowed(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
    }
  }, []);
  return _jsxs(Wrapper, {
    children: [_jsx(Typography, {
      variant: "body1",
      fontWeight: "bold",
      children: title
    }), _jsx(Splitter, {
      size: 1
    }), _jsx(ContainerLimiter, {
      transparentSmooth: true,
      rowCount: rowCount,
      isOverflowed: isOverflowed,
      children: _jsx(DangerouslyHtmlWrapper, {
        ref: contentElementRef,
        children: text && _jsx(DangerouslyHtmlWrapperInner, {
          rowCount: rowCount,
          dangerouslySetInnerHTML: {
            __html: text
          }
        })
      })
    }), isOverflowed && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 1
      }), _jsx(MPLink, {
        startIcon: WebWindowIcon,
        onClick: onClickExtend,
        children: _jsx(Typography, {
          variant: "body2",
          children: "\u043F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u043F\u043E\u043B\u043D\u043E\u0441\u0442\u044C\u044E"
        })
      })]
    })]
  });
};
export default OfferConditions;