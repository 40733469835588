import { InfoWrapper } from './controls';
import { LogoWrapper } from '../../../components/listItem';
import { CardCaption } from '../../../components/listItem/controls';
import { LogoImageView } from 'components/common/images/cover';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferListItemInfo = props => {
  const {
    partner
  } = props;
  const {
    name: partnerName,
    logo
  } = partner;
  return _jsxs(InfoWrapper, {
    children: [_jsx(CardCaption, {
      variant: "body2",
      color: "textSecondary",
      children: partnerName
    }), logo?.path && _jsx(LogoWrapper, {
      children: _jsx(LogoImageView, {
        id: logo.path
      })
    })]
  });
};
export default CorpOfferListItemInfo;