export const productOfferFilterValuesSelector = store => store.productOffer.filter.filter ?? store.productOffer.filter.priceRangeTo;
export const productOfferFilterMinPriceSelector = _ref => {
  let {
    productOffer: {
      filter
    }
  } = _ref;
  return filter.filter?.minPrice ?? filter.priceRangeFrom;
};
export const productOfferFilterMaxPriceSelector = _ref2 => {
  let {
    productOffer: {
      filter
    }
  } = _ref2;
  return filter.filter?.maxPrice ?? filter.priceRangeTo;
};
export const productOfferFilterPriceRangeSelector = _ref3 => {
  let {
    productOffer: {
      filter: {
        priceRangeFrom,
        priceRangeTo
      }
    }
  } = _ref3;
  return {
    minPrice: priceRangeFrom,
    maxPrice: priceRangeTo
  };
};