import { Typography } from '@mui/material';
import { useAuth } from 'features/auth/provider/useAuth';
import useEffectAfterMount from 'hooks/useEffectAfterMount';
import useHistoryExtensions from 'hooks/useHistoryExtensions';
import { useHistory } from 'react-router';
import { getHomepageRoute } from 'routing';
import { MPButton } from 'theme/ui-kit/button';
import { MPConfirmDialog } from 'theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NeedLoginEventContainer = _ref => {
  let {
    onNext
  } = _ref;
  const {
    login
  } = useAuth();
  const {
    location
  } = useHistory();
  const {
    gotoPrevIndependentLocation
  } = useHistoryExtensions();
  const onCloseInternal = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    gotoPrevIndependentLocation(getHomepageRoute());
  };
  useEffectAfterMount(() => {
    onNext();
    //не добавляем onNext в зависимости, иначе может криво работать
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return _jsx(MPConfirmDialog, {
    title: "\u0412\u043E\u0439\u0434\u0438\u0442\u0435 \u0432 \u043F\u0440\u043E\u0444\u0438\u043B\u044C",
    open: true,
    maxWidth: "xs",
    disableEscapeKeyDown: true,
    onClose: onCloseInternal,
    buttons: _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: login,
      children: "\u0412\u043E\u0439\u0442\u0438"
    }),
    children: _jsx(Typography, {
      children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0438\u0440\u0443\u0439\u0442\u0435\u0441\u044C, \u0447\u0442\u043E\u0431\u044B \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0432\u0441\u0435\u043C\u0438 \u0444\u0443\u043D\u043A\u0446\u0438\u044F\u043C\u0438 \u0412\u0438\u0442\u0440\u0438\u043D\u044B"
    })
  });
};