import ScreenLayout from 'presentation/layouts/screen';
import BookingOfferOrderContainer from '../../features/offer/booking/order/container';
import { useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferOrder = () => {
  const {
    id
  } = useParams();
  return _jsx(ScreenLayout, {
    footer: true,
    children: _jsx(BookingOfferOrderContainer, {
      id: id
    })
  });
};
export default BookingOfferOrder;