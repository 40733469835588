import { tabClasses, typographyClasses } from '@mui/material';
import { disabled, selected } from './constants';
const tabs = baseTheme => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 'auto',
        borderRadius: baseTheme.shape.borderRadius * 1.2,
        background: baseTheme.palette.background.tabs
      },
      indicator: {
        width: '100%',
        height: '100%',
        borderRadius: baseTheme.shape.borderRadius * 1.2
      }
    }
  },
  MuiTab: {
    defaultProps: {
      size: 'default'
    },
    variants: [{
      props: {
        size: 'default'
      },
      style: {
        [`&.${tabClasses.root}`]: {
          padding: baseTheme.spacing(1, 2)
        }
      }
    }, {
      props: {
        size: 'small'
      },
      style: {
        [`&.${tabClasses.root}`]: {
          padding: baseTheme.spacing(0.5, 1, 0.5, 0.5)
        }
      }
    }],
    styleOverrides: {
      root: {
        minHeight: 'auto',
        zIndex: 1,
        borderRadius: baseTheme.shape.borderRadius * 1.2,
        transition: 'color 0.5s',
        color: baseTheme.palette.text.primary,
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight
      },
      selected: {
        [`& .${typographyClasses.root}`]: {
          transition: 'color 0.5s'
        }
      },
      textColorPrimary: {
        [`&${selected}`]: {
          color: baseTheme.palette.primary.contrastText,
          [`& .${typographyClasses.root}`]: {
            transition: 'color 0.5s',
            color: baseTheme.palette.primary.contrastText
          }
        },
        [`&${disabled}`]: {
          [`& .${typographyClasses.root}`]: {
            color: 'inherit'
          }
        }
      },
      textColorSecondary: {
        [`&${selected}`]: {
          color: baseTheme.palette.secondary.contrastText,
          [`& .${typographyClasses.root}`]: {
            transition: 'color 0.5s',
            color: baseTheme.palette.secondary.contrastText
          }
        }
      }
    }
  }
});
export default tabs;