import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { getAspOffersSearchRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersSearchRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOffersSearchRoute } from 'presentation/features/offer/corp/routes';
import { getProductOffersSearchRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersSearchRoute } from 'presentation/features/offer/trade/routes';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppCategories, EAppFeature } from 'presentation/types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { offerSectionToTabMap } from '../../header/utils';
import { catalogSetTab, catalogSetTabs } from '../store/slice';
import { ECatalogTab } from '../types';
const useCatalogHandlers = _ref => {
  let {
    onClose,
    matchedOfferSectionPath
  } = _ref;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    hasFeature
  } = useTechConfig();
  const {
    isCorpUser
  } = useAuthUser();
  const onChangeTab = useCallback(tab => {
    dispatch(catalogSetTab(tab));
  }, [dispatch]);
  const onSelectTradeOffersCategory = useCallback(category => {
    history.push(getTradeOffersSearchRoute({
      categoryId: category.id
    }));
  }, [history]);
  const onSelectAspOffersCategory = useCallback(category => {
    history.push(getAspOffersSearchRoute({
      categoryId: category.id
    }));
  }, [history]);
  const onSelectCorpOffersCategory = useCallback(category => {
    history.push(getCorpOffersSearchRoute({
      categoryId: category.id
    }));
  }, [history]);
  const onSelectProductOffersCategory = useCallback(category => {
    history.push(getProductOffersSearchRoute({
      categoryId: category.id
    }));
  }, [history]);
  const onSelectBookingOffersCategory = useCallback(category => {
    history.push(getBookingOffersSearchRoute({
      categoryId: category.id
    }));
  }, [history]);
  useEffect(() => {
    const tabs = [];
    if (isCorpUser) {
      tabs.push({
        label: EAppCategories.CorpOffers,
        value: ECatalogTab.CorpOffers
      });
    }
    if (hasFeature(EAppFeature.Trade)) {
      tabs.push({
        label: EAppCategories.TradeOffers,
        value: ECatalogTab.TradeOffers
      });
    }
    if (hasFeature(EAppFeature.Product)) {
      tabs.push({
        label: EAppCategories.ProductOffers,
        value: ECatalogTab.ProductOffers
      });
    }
    if (hasFeature(EAppFeature.Booking)) {
      tabs.push({
        label: EAppCategories.BookingOffers,
        value: ECatalogTab.BookingOffers
      });
    }
    if (isCorpUser && hasFeature(EAppFeature.Asp)) {
      tabs.push({
        label: EAppCategories.AspOffers,
        value: ECatalogTab.AspOffers
      });
    }
    dispatch(catalogSetTabs(tabs));
    if (!tabs.length) {
      return;
    }
    const matchedWithPathTab = offerSectionToTabMap.find(s => s.path === matchedOfferSectionPath);
    matchedWithPathTab ? dispatch(catalogSetTab(matchedWithPathTab.tab)) : dispatch(catalogSetTab(tabs[0].value));
  }, [dispatch, isCorpUser, hasFeature, matchedOfferSectionPath]);
  return {
    onClose,
    onChangeTab,
    onSelectTradeOffersCategory,
    onSelectAspOffersCategory,
    onSelectCorpOffersCategory,
    onSelectProductOffersCategory,
    onSelectBookingOffersCategory
  };
};
export default useCatalogHandlers;