import { Fade, useMediaQuery, useTheme } from '@mui/material';
import { CloseWrapper, EOrderGridAreas, OrderLayoutGrid, OrderLayoutGridArea, Wrapper } from './controls';
import { BackgroundContainer } from './controls';
import BannerBadge from 'presentation/components/common/badges/banner';
import { useOrderPrepare } from '../hooks/useOrderPrepare';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OrderLayout = props => {
  const {
    smartphoneFixedBar,
    options,
    close,
    icon,
    details
  } = props;
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const theme = useTheme();
  useOrderPrepare();
  return _jsx(Fade, {
    in: true,
    children: _jsxs(Wrapper, {
      children: [isLgDown && smartphoneFixedBar, !isLgDown && _jsx(CloseWrapper, {
        children: close
      }), _jsx(BannerBadge, {
        icon: icon,
        backgroundColor: theme.palette.background.default,
        children: _jsx(BackgroundContainer, {
          children: _jsxs(OrderLayoutGrid, {
            children: [options && _jsx(OrderLayoutGridArea, {
              area: EOrderGridAreas.Options,
              children: options
            }), _jsx(OrderLayoutGridArea, {
              area: EOrderGridAreas.Details,
              children: details
            })]
          })
        })
      })]
    })
  });
};
export default OrderLayout;