import { Text } from '../../components/controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutPublicOfferEntryInfo = () => {
  return _jsxs(_Fragment, {
    children: [_jsx(Text, {
      children: "7.1. \u041E\u0444\u0435\u0440\u0442\u0430 \u0432\u0441\u0442\u0443\u043F\u0430\u0435\u0442 \u0432 \u0441\u0438\u043B\u0443 \u0441 \u0434\u0430\u0442\u044B \u0435\u0435 \u043F\u0443\u0431\u043B\u0438\u043A\u0430\u0446\u0438\u0438 \u0434\u043E \u043C\u043E\u043C\u0435\u043D\u0442\u0430 \u0435\u0435 \u043E\u0442\u0437\u044B\u0432\u0430 \u0420\u0416\u0414."
    }), _jsx(Text, {
      children: "7.2. \u0420\u0416\u0414 \u043E\u0441\u0442\u0430\u0432\u043B\u044F\u0435\u0442 \u0437\u0430 \u0441\u043E\u0431\u043E\u0439 \u043F\u0440\u0430\u0432\u043E \u0432\u043D\u0435\u0441\u0442\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u0432 \u0443\u0441\u043B\u043E\u0432\u0438\u044F \u041E\u0444\u0435\u0440\u0442\u044B \u0438 (\u0438\u043B\u0438) \u043E\u0442\u043E\u0437\u0432\u0430\u0442\u044C \u041E\u0444\u0435\u0440\u0442\u0443 \u0432 \u043B\u044E\u0431\u043E\u0439 \u043C\u043E\u043C\u0435\u043D\u0442 \u043F\u043E \u0441\u0432\u043E\u0435\u043C\u0443 \u0443\u0441\u043C\u043E\u0442\u0440\u0435\u043D\u0438\u044E. \u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u0432 \u041E\u0444\u0435\u0440\u0442\u0443 \u0432\u0441\u0442\u0443\u043F\u0430\u044E\u0442 \u0432 \u0441\u0438\u043B\u0443 \u0441 \u043C\u043E\u043C\u0435\u043D\u0442\u0430 \u0440\u0430\u0437\u043C\u0435\u0449\u0435\u043D\u0438\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u043D\u043E\u0433\u043E \u0442\u0435\u043A\u0441\u0442\u0430 \u041E\u0444\u0435\u0440\u0442\u044B \u0432 \u0441\u0435\u0442\u0438 \u0418\u043D\u0442\u0435\u0440\u043D\u0435\u0442 \u043F\u043E \u0430\u0434\u0440\u0435\u0441\u0443 https://mp.rzd.ru, \u0435\u0441\u043B\u0438 \u0438\u043D\u043E\u0439 \u0441\u0440\u043E\u043A \u0432\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0439 \u0432 \u0441\u0438\u043B\u0443 \u043D\u0435 \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u043F\u0440\u0438 \u0442\u0430\u043A\u043E\u043C \u0440\u0430\u0437\u043C\u0435\u0449\u0435\u043D\u0438\u0438."
    })]
  });
};
export default AboutPublicOfferEntryInfo;