import { useEffect, useState } from 'react';
import { secondsToHms } from '../../../../utils/date';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const Countdown = props => {
  const {
    seconds = 0,
    active,
    stop
  } = props;
  const [left, setLeft] = useState(seconds);
  useEffect(() => {
    let localLeft = left;
    const tick = () => {
      if (!active) return;
      if (localLeft <= 0) {
        stop();
      } else {
        localLeft--;
        setLeft(localLeft);
      }
    };
    const timerID = setInterval(tick, 1000);
    return () => clearInterval(timerID);
  }, [left, active, stop]);
  useEffect(() => {
    setLeft(seconds);
  }, [seconds]);
  return _jsx(_Fragment, {
    children: secondsToHms(left)
  });
};
export default Countdown;