import HttpClient from 'data/network/http';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import LoginLayout from './layout';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = LoginLayout;
const LoginContainer = () => {
  const authService = HttpClient.getInstance().getAuthService();
  useEffect(() => {
    if (!authService?.authenticated) {
      authService?.login();
    }
  }, [authService]);
  return _jsx(Layout, {
    children: _jsx(ContentLoader, {})
  });
};
export default LoginContainer;