import { Typography } from '@mui/material';
import { DownloadLabel, StyledDottedButton, StyledDownloadIcon, TextLabel } from './controls';
import ContentLoader from '../../../../components/common/loader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OfferPromotionDownload = props => {
  const {
    label,
    isFetching,
    onClick
  } = props;
  return _jsxs(StyledDottedButton, {
    fullWidth: true,
    disabled: isFetching,
    variant: "outlined",
    onClick: onClick,
    children: [_jsx(TextLabel, {
      color: "textPrimary",
      align: "left",
      children: label
    }), _jsxs(DownloadLabel, {
      children: [_jsx(StyledDownloadIcon, {
        fontSize: "micro"
      }), _jsx(Typography, {
        variant: "body2",
        children: "\u0441\u043A\u0430\u0447\u0430\u0442\u044C"
      })]
    }), isFetching && _jsx(ContentLoader, {})]
  });
};