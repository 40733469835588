import { useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import HeaderUserMenuAdapter from '../../adapters/userMenu';
import { HeaderUserFull } from '../user';
import { Menu, SwipeableDrawer, UserInfoWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserMenuWrapper = _ref => {
  let {
    drawer,
    open,
    anchorEl,
    children,
    onClose,
    onOpen
  } = _ref;
  const isMdOnly = useMediaQuery(theme => theme.breakpoints.only('md'));
  if (drawer) {
    return _jsx(SwipeableDrawer, {
      open: open,
      keepMounted: true,
      disableSwipeToOpen: false,
      anchor: "right",
      onClose: onClose,
      onOpen: onOpen,
      children: children
    });
  }
  const menuPositionProps = {};
  if (isMdOnly) {
    menuPositionProps.anchorOrigin = {
      vertical: 'top',
      horizontal: 'right'
    };
    menuPositionProps.transformOrigin = {
      vertical: 'bottom',
      horizontal: 'right'
    };
  } else {
    menuPositionProps.anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right'
    };
    menuPositionProps.transformOrigin = {
      vertical: 'top',
      horizontal: 'right'
    };
  }
  return _jsx(Menu, {
    open: open,
    keepMounted: true,
    anchorEl: anchorEl,
    onClose: onClose,
    ...menuPositionProps,
    children: children
  });
};
const HeaderMenu = props => {
  const {
    user,
    open,
    anchorEl,
    onClose,
    onOpen,
    onLocationClick
  } = props;
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const userInfo = useMemo(() => _jsx(UserInfoWrapper, {
    children: _jsx(HeaderUserFull, {
      user: user
    })
  }), [user]);
  return _jsxs(UserMenuWrapper, {
    drawer: isMdDown,
    open: open,
    anchorEl: anchorEl,
    onClose: onClose,
    onOpen: onOpen,
    children: [userInfo, _jsx(Splitter, {
      size: isMdDown ? 2 : 1.5
    }), _jsx(HeaderUserMenuAdapter, {
      onLocationClick: isLgDown ? onLocationClick : null
    })]
  });
};
export default HeaderMenu;