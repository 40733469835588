import { Typography } from '@mui/material';
import moment from 'moment';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import { pluralize } from '../../../../../../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferGetCertDialog = _ref => {
  let {
    open,
    price,
    offerName,
    offerEndDate,
    activationExpiryDays,
    onGetCertificate,
    onClose
  } = _ref;
  const activationExpiryDate = moment(offerEndDate).endOf('day').add(activationExpiryDays, 'day');
  return _jsxs(MPConfirmDialog, {
    open: open,
    title: "\u041E\u0431\u043C\u0435\u043D\u044F\u0442\u044C \u0431\u0430\u043B\u043B\u044B \u043D\u0430 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442?",
    closeButtonVisible: true,
    maxWidth: "xs",
    onClose: onClose,
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        color: "brand",
        size: "large",
        fullWidth: true,
        onClick: onGetCertificate,
        children: "\u0414\u0430, \u043E\u0431\u043C\u0435\u043D\u044F\u0442\u044C"
      }), _jsx(MPButton, {
        color: "brand",
        size: "large",
        fullWidth: true,
        variant: "text",
        onClick: onClose,
        children: "\u041D\u0435\u0442"
      })]
    }),
    children: [_jsxs(Typography, {
      variant: "body2",
      children: ["\u041E\u0431\u043C\u0435\u043D\u044F\u0442\u044C ", price, " ", pluralize(price, ['балл', 'балла', 'баллов']), " \u043D\u0430 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \xAB", offerName, "\xBB?"]
    }), _jsx(Typography, {
      variant: "body2",
      fontWeight: "bold",
      children: "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435!"
    }), _jsxs(Typography, {
      variant: "body2",
      children: ["\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D \u0434\u043E ", moment(activationExpiryDate).format(EDateFormat.Human), " \u0433\u043E\u0434\u0430."]
    })]
  });
};
export default CorpOfferGetCertDialog;