import { FormControlLabel, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Fragment, useMemo } from 'react';
import CartItemsList from '../cartItemsList';
import CartRemoveButton from '../cartRemoveButton';
import { StyledCheckbox } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartItemsByPartners = props => {
  const {
    items,
    selected,
    onToggleSelected,
    isCartItemEnabled,
    onRemoveItems,
    onOpenProductDetails,
    onAddOrRemoveFavorite,
    isFavoriteFetching
  } = props;
  const {
    x1,
    x2
  } = useGridSpacing();
  const itemsByPartner = useMemo(() => {
    return items.reduce((prev, next) => {
      const existedPartnerIndex = prev.findIndex(p => p.partner.id === next.offer.partner.id);
      if (existedPartnerIndex !== -1) {
        const result = [...prev];
        result[existedPartnerIndex].items.push(next);
        return result;
      } else {
        return [...prev, {
          partner: next.offer.partner,
          items: [next]
        }];
      }
    }, []);
  }, [items]);
  const isAllSelected = items => selected.length > 0 && items.every(item => selected.some(s => s.id === item.id));
  const onToggleAllSelected = items => {
    //если все выделены то переключаем состояние всех, иначе переключаем тех кто не выбран
    if (isAllSelected(items)) {
      onToggleSelected(items);
    } else {
      onToggleSelected(items.filter(item => !selected.some(s => s.id === item.id)));
    }
  };
  return _jsx(MPGrid, {
    container: true,
    spacing: x1,
    alignItems: "center",
    children: itemsByPartner.map(item => _jsxs(Fragment, {
      children: [_jsxs(MPGrid, {
        item: true,
        xs: 12,
        children: [_jsx(Typography, {
          variant: "h4",
          children: item.partner.name
        }), _jsx(Splitter, {
          size: x1
        })]
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(FormControlLabel, {
          control: _jsx(StyledCheckbox, {
            color: "primary",
            checked: isAllSelected(item.items),
            onChange: () => onToggleAllSelected(item.items)
          }),
          label: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0432\u0441\u0451"
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(CartRemoveButton, {
          onClick: () => onRemoveItems(item.items),
          count: item.items.length
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(CartItemsList, {
          isFavoriteFetching: isFavoriteFetching,
          items: item.items,
          selected: selected,
          onToggleSelected: onToggleSelected,
          isCartItemEnabled: isCartItemEnabled,
          onOpenProductDetails: onOpenProductDetails,
          onAddOrRemoveFavorite: onAddOrRemoveFavorite
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(Splitter, {
          size: x2
        })
      })]
    }, item.partner.id))
  });
};
export default CartItemsByPartners;