import { EOfferListType } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCorpOffersContextHandlers } from '../../../hooks/useCorpOffersContextHandlers';
import { partnerDeskDetailsCorpOffersUpcomingArgsSelector, partnerDeskDetailsGuidSelector } from '../../../store/selectors';
import PartnerDeskTabTextHeader from '../../header';
import PartnerDeskCorpOffersDataContainer from '../dataContainer';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Upcoming;
const PartnerDeskCorpOffersUpcomingAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsCorpOffersUpcomingArgsSelector);
  const handlers = useCorpOffersContextHandlers();
  const header = useMemo(() => _jsxs(PartnerDeskTabTextHeader, {
    children: ["\u0421\u043A\u043E\u0440\u043E \u0431\u0443\u0434\u0443\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B", _jsx(Splitter, {
      size: 3
    })]
  }), []);
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskCorpOffersDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    header: header,
    onChangePage: handlers.onChangeCorpOffersPage,
    onShowCard: handlers.onShowCorpOffersCard
  }), [args, currentGuid, guid, handlers.onChangeCorpOffersPage, handlers.onShowCorpOffersCard, header]);
};
export default PartnerDeskCorpOffersUpcomingAdapter;