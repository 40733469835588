import ClaimMessage from '../../../../../components/claim/message';
import { useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ContainerLimiter } from 'presentation/features/offer/components/conditions/controls';
import { Text } from './controls';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { MPLink } from '../../../../../../../theme/ui-kit/link';
import { DocIcon } from '../../../../../../../media/icons';
import Splitter from 'presentation/components/common/splitter';
import { MPConfirmDialog } from '../../../../../../../theme/ui-kit/dialog';
import { MPButton } from '../../../../../../../theme/ui-kit/button';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimRejectedInfoMessage = props => {
  const {
    activationComment
  } = props;
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const rowCount = 10;
  const contentElementRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  useEffect(() => {
    const el = contentElementRef.current;
    if (el) {
      const {
        scrollWidth,
        offsetWidth,
        scrollHeight,
        offsetHeight
      } = el;
      setIsOverflowed(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
    }
  }, []);
  return _jsxs(ClaimMessage, {
    type: "info",
    children: [_jsxs(Stack, {
      children: [_jsx(ContainerLimiter, {
        rowCount: rowCount,
        children: _jsx(DangerouslyHtmlWrapper, {
          ref: contentElementRef,
          children: _jsx(Text, {
            variant: "body2",
            color: "black",
            rowCount: rowCount,
            children: activationComment
          })
        })
      }), isOverflowed && _jsxs(_Fragment, {
        children: [_jsx(Splitter, {
          size: 1
        }), _jsx(MPLink, {
          startIcon: DocIcon,
          onClick: onOpen,
          children: _jsx(Typography, {
            variant: "body2",
            children: "\u043F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u043F\u043E\u043B\u043D\u043E\u0441\u0442\u044C\u044E"
          })
        })]
      })]
    }), _jsx(MPConfirmDialog, {
      open: open,
      title: "\u041E\u0442\u043A\u0430\u0437",
      maxWidth: "xs",
      buttons: _jsx(MPButton, {
        size: "large",
        fullWidth: true,
        color: "brand",
        variant: "contained",
        onClick: onClose,
        children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
      }),
      onClose: onClose,
      children: _jsx(Typography, {
        children: activationComment
      })
    })]
  });
};