import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBookingOffersContextHandlers } from '../../../hooks/useBookingOffersContextHandlers';
import { partnerDeskDetailsBookingOffersArgsSelector, partnerDeskDetailsGuidSelector } from '../../../store/selectors';
import PartnerDeskBookingOffersDataContainer from '../dataContainer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PartnerDeskBookingOffersAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsBookingOffersArgsSelector);
  const handlers = useBookingOffersContextHandlers();
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskBookingOffersDataContainer, {
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangeBookingOffersPage,
    onShowCard: handlers.onShowBookingOffersCard
  }), [args, currentGuid, guid, handlers.onChangeBookingOffersPage, handlers.onShowBookingOffersCard]);
};