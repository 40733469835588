import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
const userThemeLogoSelector = store => userApi.endpoints.getCurrentUser.select({})(store).data?.org?.logo?.path;
const userThemeFaviconSelector = store => userApi.endpoints.getCurrentUser.select({})(store).data?.org?.favicon?.path;
const userThemeColorBrandSelector = store => userApi.endpoints.getCurrentUser.select({})(store).data?.org?.colorBrand;
const userThemeColorPrimarySelector = store => userApi.endpoints.getCurrentUser.select({})(store).data?.org?.colorPrimary;
export const userThemeSelector = createSelector(userThemeLogoSelector, userThemeFaviconSelector, userThemeColorBrandSelector, userThemeColorPrimarySelector, (logo, favicon, colorBrand, colorPrimary) => {
  let color = colorBrand || colorPrimary ? {} : undefined;
  if (colorBrand) {
    color = {
      ...color,
      brand: {
        main: colorBrand
      }
    };
  }
  if (colorPrimary) {
    color = {
      ...color,
      primary: {
        main: colorPrimary
      }
    };
  }
  let result = null;
  if (color || logo || favicon) {
    result = {
      description: '',
      logo,
      favicon,
      color
    };
  }
  return result;
});