import { SystemEventContentBuilderLayout, useSystemEvents } from 'features/systemEvent';
import { Fragment, useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SystemEventDialogLayout } from '../layouts/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * @experimental
 */
export const SystemEventsComponentsSwipeFlowAdapter = _ref => {
  let {
    activeIndex,
    events
  } = _ref;
  const {
    onNext,
    onClose
  } = useSystemEvents();
  const event = events?.[activeIndex];
  const [swiper, setSwiper] = useState(null);
  const {
    payload,
    title: Title,
    wrapper: Wrapper
  } = event ?? {};
  const currentEventWrapper = useMemo(() => Wrapper ? props => _jsx(Wrapper, {
    payload: payload,
    onNext: onNext,
    onClose: onClose,
    ...props
  }) : props => _jsx(Fragment, {
    ...props
  }), [Wrapper, onClose, onNext, payload]);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex, swiper]);
  return _jsx(SystemEventDialogLayout, {
    open: true,
    title: Title && _jsx(Title, {
      payload: payload,
      onNext: onNext,
      onClose: onClose
    }),
    disableEscapeKeyDown: event?.required,
    onClose: onClose,
    children: _jsx(Swiper, {
      slidesPerView: 1,
      allowTouchMove: false,
      noSwiping: true,
      loop: false,
      freeMode: true,
      onSwiper: setSwiper,
      children: events.map((_ref2, index) => {
        let {
          type,
          payload,
          content: Content,
          actions: Actions
        } = _ref2;
        return _jsx(SwiperSlide, {
          children: index <= activeIndex &&
          //рисуются только слайды до текущего, чтобы не срабатывал раньше времени mount в будущих
          _jsx(SystemEventContentBuilderLayout, {
            wrapper: currentEventWrapper,
            title: Title && _jsx(Title, {
              payload: payload,
              onNext: onNext,
              onClose: onClose
            }),
            actions: Actions && _jsx(Actions, {
              payload: payload,
              onNext: onNext,
              onClose: onClose
            }),
            children: Content && _jsx(Content, {
              payload: payload,
              onNext: onNext,
              onClose: onClose
            })
          })
        }, type);
      })
    })
  });
};