import { Typography } from '@mui/material';
import { toRubCurrency } from 'presentation/utils/currency';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EditIcon, TrashIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { calcPrice } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingSidebarSlotActions = _ref => {
  let {
    unitType,
    price,
    slots,
    index,
    slotIndex,
    onChange,
    onDelete
  } = _ref;
  const clickValue = typeof slotIndex === 'number' ? [index, slotIndex] : [index];
  return _jsxs(MPGrid, {
    container: true,
    spacing: 1,
    justifyContent: "space-between",
    alignItems: "center",
    children: [_jsxs(MPGrid, {
      item: true,
      display: "flex",
      gap: 1,
      children: [_jsx(MPFab, {
        color: "secondarySuperLight",
        size: "small",
        variant: "squared",
        onClick: () => onChange(clickValue),
        children: _jsx(EditIcon, {
          fontSize: "small",
          color: "primary"
        })
      }), _jsx(MPFab, {
        color: "secondarySuperLight",
        size: "small",
        variant: "squared",
        onClick: () => onDelete(clickValue),
        children: _jsx(TrashIcon, {
          fontSize: "small",
          color: "primary"
        })
      })]
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "subtitle1",
        children: toRubCurrency(calcPrice(unitType, price, slots))
      })
    })]
  });
};
export default BookingSidebarSlotActions;