import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import { EOfferStatus } from '../../../../../../../domain/model/enums';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { Wrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const ActivityEndDateAdapter = () => {
  const {
    aspOffer,
    activation
  } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }
  const isVisible = aspOffer.status === EOfferStatus.Active && !activation.isLastActivationPending;
  if (!isVisible) {
    return null;
  }
  return _jsx(Wrapper, {
    children: _jsxs(Typography, {
      variant: "body2",
      children: ["\u0410\u043A\u0442\u0438\u0432\u043D\u043E \u0434\u043E ", moment(aspOffer.endDate).format(EDateFormat.Human)]
    })
  });
};
export default ActivityEndDateAdapter;