import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { PlusIcon } from '../../../../../../media/icons';
import { MPLink } from '../../../../../../theme/ui-kit/link';
import useCorpOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferClaimPromotionNew = props => {
  const {
    disabled,
    activationIsFetching,
    onOpenReactivationDialog
  } = props;
  const isUnavailable = activationIsFetching || disabled;
  return _jsxs(MPLink, {
    color: isUnavailable ? 'secondary.main' : 'primary.main',
    iconSize: "small",
    startIcon: PlusIcon,
    onClick: isUnavailable ? undefined : onOpenReactivationDialog,
    children: [_jsx(Typography, {
      variant: "body1",
      role: "button",
      children: "\u043E\u0431\u043C\u0435\u043D\u044F\u0442\u044C \u043D\u0430 \u043D\u043E\u0432\u044B\u0439"
    }), activationIsFetching && _jsx(ContentLoader, {
      size: 15
    })]
  });
};
export const CorpOfferClaimPromotionNewAdapter = () => {
  const {
    corpOffer,
    activation
  } = useCorpOfferClaimContext();
  const {
    isActivationAvailable
  } = activation;
  const {
    openCertificateDialog
  } = useCorpOfferClaimDialogs();
  const {
    isReactivationSupported,
    activationIsFetching
  } = activation;
  const isCodesEnded = (corpOffer?.notUsedOfferCount ?? 0) === 0;
  const isNotExternal = corpOffer?.promotionType !== ECorpOfferPromotionType.ExternalCorpCertificate;
  return useMemo(() => corpOffer?.status === EOfferStatus.Active && !isCodesEnded && isReactivationSupported && isActivationAvailable && (activation.lastActivation?.status === EOfferActivationStatus.Approved || activation.lastActivation?.status === EOfferActivationStatus.Given) && isNotExternal ? _jsx(CorpOfferClaimPromotionNew, {
    disabled: activation?.isUserBalanceNotEnough,
    activationIsFetching: activationIsFetching,
    onOpenReactivationDialog: openCertificateDialog
  }) : null, [corpOffer?.status, isCodesEnded, isReactivationSupported, isActivationAvailable, activation?.isUserBalanceNotEnough, activation.lastActivation?.status, isNotExternal, activationIsFetching, openCertificateDialog]);
};