import { Fade, Typography } from '@mui/material';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { StubImage } from './controls';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OfferDetailsErrorLayout = _ref => {
  let {
    toolbar,
    text,
    stubImage
  } = _ref;
  return _jsxs(_Fragment, {
    children: [toolbar && _jsx(ScreenNavWrapper, {
      children: toolbar
    }), _jsx(Fade, {
      in: true,
      children: _jsxs(Wrapper, {
        children: [_jsx(Typography, {
          variant: "h2",
          component: "h1",
          textAlign: "center",
          children: text
        }), stubImage && _jsx(StubImage, {
          src: stubImage,
          alt: ""
        })]
      })
    })]
  });
};
export default OfferDetailsErrorLayout;