import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { TradeOfferClaimArchivedMessage } from './archived';
import { TradeOfferClaimPausedMessage } from './paused';
import { TradeOfferClaimUnavailableMessage } from './unavailable';
import { TradeOfferClaimUpcomingMessage } from './upcoming';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimInfoMessagesAdapter = () => {
  const {
    tradeOffer,
    isActivationReceivedNow,
    isActivationAvailable
  } = useTradeOfferClaimContext();
  return useMemo(() => {
    if (!tradeOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return _jsx(TradeOfferClaimUnavailableMessage, {});
      } else {
        return _jsxs(_Fragment, {
          children: [tradeOffer.status === EOfferStatus.Upcoming && _jsx(TradeOfferClaimUpcomingMessage, {
            startDate: tradeOffer.startDate
          }), tradeOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && _jsx(TradeOfferClaimPausedMessage, {}), tradeOffer.status === EOfferStatus.Archived && _jsx(TradeOfferClaimArchivedMessage, {})]
        });
      }
    }
  }, [tradeOffer, isActivationAvailable, isActivationReceivedNow]);
};