import { Typography } from '@mui/material';
import Splitter from '../../../../components/common/splitter';
import { StyledTab, StyledTabs } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutTabsComponent = _ref => {
  let {
    value,
    tabs,
    title,
    onChange
  } = _ref;
  return _jsx(StyledTabs, {
    textColor: "secondary",
    value: value?.id ?? tabs[0].id,
    orientation: "vertical",
    indicatorColor: "secondary",
    children: tabs.map((tab, index) => _jsx(StyledTab, {
      label: index === 0 ? _jsxs(_Fragment, {
        children: [_jsx(Typography, {
          variant: 'subtitle2',
          component: 'span',
          color: 'black',
          children: title
        }), ' ', _jsx(Splitter, {
          size: 2
        }), _jsx(Typography, {
          variant: 'subtitle2',
          children: tab.name
        })]
      }) : _jsx(Typography, {
        variant: 'subtitle2',
        children: tab.name
      }),
      value: tab.id,
      onClick: () => {
        onChange(tab);
      }
    }, tab.id))
  });
};
export default AboutTabsComponent;