import { Typography } from '@mui/material';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferDetailsTitleAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  if (!aspOffer) {
    return null;
  }
  return _jsx(Typography, {
    variant: "h1",
    children: aspOffer.name
  });
};