import CarouselDefaultNavigation from './component';
import { useCallback, useMemo, useRef } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const useGetDefaultCarouselNavigation = props => {
  const {
    childrenCount,
    swiper,
    navigationEnabled
  } = props;
  const buttonPrevRef = useRef(null);
  const buttonNextRef = useRef(null);
  const onPrevClick = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);
  const onNextClick = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);
  return useMemo(() => navigationEnabled && childrenCount > 1 ? _jsx(CarouselDefaultNavigation, {
    swiper: swiper,
    buttonPrevRef: buttonPrevRef,
    onPrevClick: onPrevClick,
    onNextClick: onNextClick,
    buttonNextRef: buttonNextRef
  }) : null, [childrenCount, onNextClick, onPrevClick, swiper, navigationEnabled]);
};
export default useGetDefaultCarouselNavigation;