import { EOrderShortDiscriminator } from '../../../../../domain/model/enums';
import BookingOfferOrderAdapter from './adapters/bookingOfferOrder';
import ProductOfferOrderAdapter from './adapters/productOfferOrder';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OrderListItem = props => {
  const {
    order,
    bookingOrderStatuses,
    generalOrderStatuses
  } = props;
  return _jsxs(_Fragment, {
    children: [order.discriminator === EOrderShortDiscriminator.BookingOrderShort && _jsx(BookingOfferOrderAdapter, {
      ...props,
      orderStatuses: bookingOrderStatuses,
      order: order
    }), order.discriminator === EOrderShortDiscriminator.ProductOrderShort && _jsx(ProductOfferOrderAdapter, {
      ...props,
      orderStatuses: generalOrderStatuses,
      order: order
    })]
  });
};
export default OrderListItem;