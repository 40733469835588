import { EBannerPartition, EBannerPlace } from 'domain/model/enums';
export const bannersSpaceBetween = 24;
export const bannersPerView = 1;
export const bannersAutoplayDelay = 5000;
export const getBannerTopPlaceByPartition = partition => {
  switch (partition) {
    case EBannerPartition.TradeOffers:
      return EBannerPlace.TradeOffersListPageTop;
    case EBannerPartition.CorpOffers:
      return EBannerPlace.CorpOffersListPageTop;
    case EBannerPartition.ProductOffers:
      return EBannerPlace.ProductOffersListPageTop;
  }
};