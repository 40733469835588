import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getCorpOffersListRoute, getCorpOffersSearchRoute } from '../../routes';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferBreadcrumbs = props => {
  const {
    offerCategories,
    dictionary
  } = props;
  const getBreadcrumbLink = category => getCorpOffersSearchRoute({
    categoryId: category.id
  });
  const startCategory = {
    id: '',
    name: EAppCategories.CorpOffers,
    parentId: null,
    link: getCorpOffersListRoute()
  };
  return _jsx(OfferBreadcrumbs, {
    startCategory: startCategory,
    offerCategories: offerCategories ?? null,
    dictionary: dictionary,
    linkFactory: getBreadcrumbLink
  });
};
export default CorpOfferBreadcrumbs;