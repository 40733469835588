import { getWsServicesEndpoint } from '../../../data/openApi/utils';
import { AppConfigurator } from '../../../system/appConfigurator';
const useWsClient = () => {
  const isWsEnabled = AppConfigurator.getInstance().getApiWsEnabled();
  const wsEndpoint = getWsServicesEndpoint();
  const apiWs = AppConfigurator.getInstance().getApiWs();
  const debug = AppConfigurator.getInstance().isDebug();
  return {
    isWsEnabled,
    wsEndpoint,
    apiWs,
    debug
  };
};
export default useWsClient;