import { useState } from 'react';
import { MPButton } from '../../../../../../theme/ui-kit/button';
import TradeOfferClaimWidgetDialogAdapter from '../widgetModal';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TradeOfferClaimWidgetPromotion = _ref => {
  let {
    wrapperRef,
    title,
    srcDoc
  } = _ref;
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => setOpen(false);
  return _jsxs(_Fragment, {
    children: [_jsx(MPButton, {
      color: "brand",
      fullWidth: true,
      size: "medium",
      onClick: onOpen,
      children: "\u041E\u0442\u043A\u0440\u044B\u0442\u044C"
    }), _jsx(TradeOfferClaimWidgetDialogAdapter, {
      title: title,
      srcDoc: srcDoc,
      wrapperRef: wrapperRef,
      onClose: onClose,
      open: open
    })]
  });
};
export default TradeOfferClaimWidgetPromotion;