import { useOfferFavoriteSimple } from '../hooks/useOfferFavorite';
import AppSvgIcon from '../../../components/common/icon';
import { IconFavoriteFilled } from '../../../media/icons';
import { Typography } from '@mui/material';
import ContentLoader from '../../../components/common/loader';
import { ButtonLink } from '../../../components/common/buttons/link';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OfferFavoriteLinkContainer = props => {
  const {
    guid,
    offerId,
    offerType,
    partnerId
  } = props;
  const {
    isFavorite,
    isFetching,
    onToggle
  } = useOfferFavoriteSimple({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId
    }
  });
  return _jsxs(ButtonLink, {
    startIcon: _jsx(AppSvgIcon, {
      color: isFavorite ? 'brand' : 'secondary',
      icon: IconFavoriteFilled
    }),
    onClick: onToggle,
    disabled: isFetching,
    children: [_jsx(Typography, {
      children: isFavorite ? 'В избранном' : 'В избранное'
    }), isFetching && _jsx(ContentLoader, {
      size: 20
    })]
  });
};