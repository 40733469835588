export const historyExtensionsGetLocationCollector = store => store.historyExtensions.collector;
export const historyExtensionsGetPrevLocation = store => {
  const collector = store.historyExtensions.collector;
  if (collector.length === 0) return null;
  return collector[collector.length - 1];
};
export const historyExtensionsGetPrevIndependentLocationSelector = store => {
  const collector = store.historyExtensions.collector;
  if (collector.length === 0) return null;
  let index = collector.length - 1;
  const lastLocation = collector[index];
  while (index >= 0) {
    if (collector[index].location.pathname !== lastLocation.location.pathname) return collector[index].location;
    index--;
  }
  return null;
};