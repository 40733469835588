import { useGetTradeOfferListQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import useCategoriesData from 'presentation/features/offer/trade/list/hooks/useCategoriesData';
import { tradeOfferListIsNewFetchingSelector } from 'presentation/features/offer/trade/list/store/selectors';
import { ERenderSource } from 'presentation/types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OfferListItemContainer from '../../listItem/container';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TradeOfferListDataContainer = props => {
  const {
    type,
    guid,
    args,
    header,
    onChangePage,
    onShowCard
  } = props;
  const isNewFetching = useSelector(tradeOfferListIsNewFetchingSelector);
  const {
    page
  } = args;
  const {
    isAuthenticated
  } = useAuth();
  const {
    subCategories
  } = useCategoriesData();
  const {
    data,
    error,
    isSuccess,
    isFetching
  } = useGetTradeOfferListQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const tradeOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasSubCategories = !!subCategories?.length;
  const hasOffers = !!tradeOffers && tradeOffers.length > 0;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  return _jsxs("div", {
    children: [header, _jsx(CardPageableList, {
      span: hasSubCategories ? 4 : 3,
      lgSpan: hasSubCategories ? 6 : 4,
      totalCount: totalCount,
      onLoadMore: onLoadMore,
      children: tradeOffers.map(tradeOffer => _jsx(InView, {
        active: isSuccess && !isFetching,
        sessionKey: sessionKey,
        data: tradeOffer,
        onShow: onShowCard,
        children: _jsx(OfferListItemContainer, {
          offer: tradeOffer,
          favorite: isAuthenticated && {
            guid
          },
          renderFrom: ERenderSource.OfferList
        }, tradeOffer.id)
      }, tradeOffer.id))
    })]
  });
};
export default TradeOfferListDataContainer;