import { Container } from '@mui/material';
import { ContentWrapper, Footer, Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FooterLayout = props => {
  const {
    children
  } = props;
  return _jsx(Wrapper, {
    children: _jsx(Footer, {
      children: _jsx(Container, {
        children: _jsx(ContentWrapper, {
          children: children
        })
      })
    })
  });
};
export default FooterLayout;