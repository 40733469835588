import { Link, Typography } from '@mui/material';
import ErrorHandler from 'data/network/errorHandler';
import { RequiredActionConfirmCodeContainer } from 'features/requiredAction/confirmCode/container';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useEmailEditQuery } from 'presentation/hooks/useEmailEditQuery';
import { useCallback, useEffect, useState } from 'react';
import { api, EUserServicesTag } from 'data/api';
import { useDispatch } from 'react-redux';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ChangeEmail = props => _jsx(Link, {
  color: "primary",
  component: Typography,
  underline: "hover",
  ...props,
  children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u043E\u0447\u0442\u0443"
});
export const ConfirmAdapter = _ref => {
  let {
    onClose,
    onSuccess,
    onChangeEmail
  } = _ref;
  const dispatch = useDispatch();
  const {
    tokenParsed,
    refreshToken
  } = useAuthToken();
  const [source, setSource] = useState(null);
  const [updateCurrentUserEmail, {
    isLoading: isFetching,
    error
  }] = useEmailEditQuery();
  const email = tokenParsed?.email ?? '';
  const onGetCode = useCallback(() => updateCurrentUserEmail({
    email
  }).unwrap().then(setSource).catch(console.error), [email, updateCurrentUserEmail]);
  const onConfirmed = async () => {
    // обновление токена (для подхвата нового признака о подтвержденной почте)
    await refreshToken();
    dispatch(api.util.invalidateTags([EUserServicesTag.Current]));
    onSuccess();
  };
  useEffect(() => {
    onGetCode();
  }, [onGetCode]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
      setSource(null);
      onClose?.();
    }
  }, [error, onClose]);
  return _jsxs(_Fragment, {
    children: [source && _jsx(RequiredActionConfirmCodeContainer, {
      email: email,
      source: source,
      onSuccess: onConfirmed,
      onGetNewCode: onGetCode,
      back: _jsx(ChangeEmail, {
        onClick: onChangeEmail
      }),
      onClose: onClose
    }), isFetching && _jsx(ContentLoader, {})]
  });
};