import { ETradeOfferPromotionType } from 'domain/model/enums';
export const dialogContent = {
  [ETradeOfferPromotionType.Asp]: {
    title: 'Как использовать сертификат',
    text: ''
  },
  [ETradeOfferPromotionType.Digift]: {
    title: 'Как использовать сертификат',
    text: ''
  }
};