import { useGetPartnerTestPageQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import { LazyContentWrapper } from '../../general/lazyContentWrapper';
import { LazyContentItem } from '../../general/lazyContentWrapper/item';
import CmsContainerContainer from '../container/container';
import { Wrapper } from './controls';
import CmsPreviewLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = CmsPreviewLayout;
const CmsPreviewPartnerContainer = _ref => {
  let {
    partner,
    guid
  } = _ref;
  const {
    data = null,
    isFetching,
    error
  } = useGetPartnerTestPageQuery({
    partnerId: partner.id
  });
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  if (isFetching) {
    return _jsx(ContentLoader, {});
  }
  return _jsxs(Layout, {
    children: [_jsx(LazyContentWrapper, {
      guid: guid,
      children: listSize => _jsx(Wrapper, {
        children: data?.slice(0, listSize + 1).map((container, i) => _jsx("div", {
          children: _jsx(LazyContentItem, {
            elementSize: 33,
            guid: guid,
            containerId: container.id,
            isLoaded: i !== listSize,
            children: onContainerDataLoaded => _jsx(CmsContainerContainer, {
              guid: guid,
              container: container,
              onContainerDataLoaded: onContainerDataLoaded
            })
          })
        }, container.id))
      })
    }), isFetching && _jsx(ContentLoader, {})]
  });
};
export default CmsPreviewPartnerContainer;