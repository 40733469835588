import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import LegalAgreementContainer from 'presentation/features/legal/agreement/container';
import CheckSignsContainer from 'presentation/features/legal/checkSigns/container';
import { useMemo } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AppDecorators = _ref => {
  let {
    children
  } = _ref;
  const {
    isAuthenticated
  } = useAuth();
  const decorators = useMemo(() => {
    return [isAuthenticated && _jsx(LegalAgreementContainer, {}, 'legalAgreement'), isAuthenticated && _jsx(CheckSignsContainer, {}, 'checkSigns')].filter(item => !!item);
  }, [isAuthenticated]);
  return _jsx(RenderDecoratorContainer, {
    decorators: decorators,
    children: children
  });
};
export default AppDecorators;