import { ECorpOfferPromotionType, EOfferActivationStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useState } from 'react';
import useCopied from '../../../hooks/useCopied';
import { createDownloadRequest } from '../../../hooks/useFileDownload';
import { getOfferPromotionDownloadUrl } from '../utils';
const useTakeOfferActivations = () => {
  const {
    webAnalytics
  } = useWebAnalytics();
  const [fileDownloading, setFileDownloading] = useState(false);
  const {
    onCopy,
    onNeedCopy,
    needCopy,
    copied,
    copy
  } = useCopied();
  const isTaked = copied;
  const isTaking = fileDownloading;
  const takeOfferActivation = useCallback(activation => {
    switch (activation.promotion.type) {
      case ETradeOfferPromotionType.AccessCode:
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.PublicPromocode:
        webAnalytics.tradeOfferActivateByCopy(activation.offer.id);
        webAnalytics.offerTakeActivation(activation.offer.id);
        break;
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        break;
      case ETradeOfferPromotionType.Voucher:
      case ECorpOfferPromotionType.CorpCertificate:
      case ECorpOfferPromotionType.ExternalCorpCertificate:
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        webAnalytics.offerTakeActivation(activation.offer.id);
        break;
    }
  }, [webAnalytics]);
  const onTakeActivation = useCallback(async activation => {
    switch (activation.promotion.type) {
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.AccessCode:
        await copy(activation.promotion.code);
        onCopy();
        break;
      case ETradeOfferPromotionType.PublicPromocode:
        await copy(activation.promotion.publicPromotionValue);
        onCopy();
        break;
      case ETradeOfferPromotionType.Voucher:
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
      case ECorpOfferPromotionType.CorpCertificate:
      case ECorpOfferPromotionType.ExternalCorpCertificate:
        {
          if (activation.status !== EOfferActivationStatus.Approved && activation.status !== EOfferActivationStatus.Given) {
            return;
          }
          const src = getOfferPromotionDownloadUrl(activation);
          if (!src) {
            return;
          }
          setFileDownloading(true);
          createDownloadRequest({
            src
          }).finally(() => setFileDownloading(false));
          break;
        }
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        break;
    }
    takeOfferActivation(activation);
  }, [copy, onCopy, takeOfferActivation]);
  return {
    onTakeActivation,
    isTaking,
    onNeedCopy,
    needCopy,
    isTaked,
    onCopy
  };
};
export default useTakeOfferActivations;