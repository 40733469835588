import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsPreviewLayout = props => {
  const {
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      children: children
    })
  });
};
export default CmsPreviewLayout;