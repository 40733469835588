import { Fade } from '@mui/material';
import { Wrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskOffersTabLayout = props => {
  const {
    toolbar,
    stub,
    loader,
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsxs(Wrapper, {
      children: [toolbar, children, loader, stub]
    })
  });
};
export default PartnerDeskOffersTabLayout;