import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import useCorpOfferDetailsData from 'presentation/features/offer/corp/details/hooks/useCorpOfferDetailsData';
import CorpOfferClaimContainer from '../../claim/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferDetailSidebarAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    user
  } = useAuthUser();
  const {
    corpOffer
  } = useCorpOfferDetailsData(id);
  if (!corpOffer) {
    return null;
  }
  return user ? _jsx(CorpOfferClaimContainer, {
    corpOffer: corpOffer
  }) : null;
};
export default CorpOfferDetailSidebarAdapter;