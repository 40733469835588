import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferListAside = _ref => {
  let {
    isFetching,
    children
  } = _ref;
  return _jsx(Wrapper, {
    children: isFetching === true ? '' : children
  });
};
export default OfferListAside;