import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsBookingOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskDetailsTabBookingOffersNoData from './noData';
import { PartnerDeskBookingOffersAdapter, PartnerDeskBookingOffersCategoriesAdapter } from './adapters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PartnerDeskDetailsTabBookingOffers = props => {
  const {
    guid
  } = props;
  return _jsx(PartnerDeskOffersTabLayout, {
    toolbar: _jsx(PartnerDeskBookingOffersCategoriesAdapter, {}),
    stub: _jsx(PartnerDeskDetailsTabBookingOffersNoData, {}),
    loader: _jsx(GeneralLoaderFixedAdapter, {
      selector: partnerDeskDetailsBookingOffersIsFetchingSelector
    }),
    children: _jsx(PartnerDeskBookingOffersAdapter, {
      guid: guid
    })
  });
};