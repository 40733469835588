import moment from 'moment-timezone';
import { EOrderByDateType } from 'domain/model/enums';
import { EDateFormat } from 'domain/model/formats';
import { toRubCurrency } from '../../../../../utils/currency';
export const calcPrice = (unitType, price, slots) => {
  if (unitType === EOrderByDateType.Period) {
    return slots.reduce((total, _ref) => {
      let {
        startDate,
        endDate,
        qty
      } = _ref;
      const diff = moment(endDate, EDateFormat.Server).diff(moment(startDate, EDateFormat.Server), 'days');
      return total + diff * price * qty;
    }, 0);
  }
  if (unitType === EOrderByDateType.Days) {
    return slots.reduce((total, _ref2) => {
      let {
        qty
      } = _ref2;
      return total + qty * price;
    }, 0);
  }
  if (unitType === EOrderByDateType.None) {
    return slots[0].qty * price;
  }
  return 0;
};
export const calcTotalPrice = cart => cart ? toRubCurrency(cart.reduce((total, _ref3) => {
  let {
    priceUnit,
    orderItem
  } = _ref3;
  const item = priceUnit.priceItems?.find(_ref4 => {
    let {
      id
    } = _ref4;
    return id === orderItem.priceItem.id;
  });
  return total + (item ? calcPrice(priceUnit.orderByDateType, item.price ?? 0, priceUnit.orderByDateType === EOrderByDateType.None ? [{
    qty: orderItem.qty
  }] : orderItem.slots) : 0);
}, 0)) : '';