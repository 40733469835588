import { Typography } from '@mui/material';
import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import OffersCommonHeader from 'presentation/features/offer/components/commonHeader';
import { searchTitleByOfferType } from 'presentation/features/search/utils';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import { useSelector } from 'react-redux';
import { searchOffersCountSelector, searchOffersOfferTypeSelector } from '../store/selectors';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchOffersHeaderAdapter = () => {
  const {
    x2
  } = useGridSpacing();
  const count = useSelector(searchOffersCountSelector);
  const offerType = useSelector(searchOffersOfferTypeSelector);
  const isCommonHeaderVisible = offerType?.length === 1;
  return count ? _jsxs(MPGrid, {
    container: true,
    spacing: x2,
    direction: "column",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsxs(Typography, {
        variant: "body2",
        children: ["\u041D\u0430\u0439\u0434\u0435\u043D\u043E ", count, " ", pluralize(count, ['результат', 'результата', 'результатов'])]
      })
    }), _jsx(MPGrid, {
      item: true,
      children: isCommonHeaderVisible && _jsx(OffersCommonHeader, {
        icon: _jsx(PartitionIcon, {
          type: offerType[0]
        }),
        children: searchTitleByOfferType[offerType[0]]
      })
    })]
  }) : null;
};
export default SearchOffersHeaderAdapter;