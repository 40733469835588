import { MPTab } from 'presentation/theme/ui-kit/tab';
import { ELocaleShort } from 'presentation/types';
import { Tabs } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CountryTabs = _ref => {
  let {
    value,
    onChange,
    ...other
  } = _ref;
  return _jsx(Tabs, {
    value: value,
    onChange: (event, value) => onChange(value),
    ...other,
    children: Object.values(ELocaleShort).map(item => _jsx(MPTab, {
      label: item.toUpperCase(),
      value: item
    }, item))
  });
};