import { Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from '../../../../../../utils/currency';
import { DiscountValue, OriginalPrice } from '../../../controls';
import { formatToSale } from '../../../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Prices = _ref => {
  let {
    price,
    originalPrice,
    salePercent
  } = _ref;
  const isOriginalPriceVisible = originalPrice && price && originalPrice > price;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return _jsxs(MPGrid, {
    item: true,
    container: true,
    zero: "auto",
    spacing: 1,
    alignItems: isMdUp ? 'flex-start' : 'center',
    children: [_jsx(MPGrid, {
      item: true,
      md: 12,
      children: _jsx(Typography, {
        variant: "h3",
        children: toRubCurrency(price)
      })
    }), isOriginalPriceVisible && _jsxs(MPGrid, {
      container: true,
      item: true,
      zero: "auto",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(OriginalPrice, {
          variant: "body1",
          color: "textSecondary",
          children: toRubCurrency(originalPrice)
        })
      }), salePercent && _jsx(MPGrid, {
        item: true,
        children: _jsx(DiscountValue, {
          variant: "body1",
          color: "primary.main",
          children: formatToSale(salePercent)
        })
      })]
    })]
  });
};
export default Prices;