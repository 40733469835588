import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'catalog',
  initialState: {
    tab: null,
    tabs: []
  },
  reducers: {
    catalogSetTab: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.tab = payload;
    },
    catalogSetTabs: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.tabs = payload;
    }
  }
});
export const {
  catalogSetTab,
  catalogSetTabs
} = slice.actions;
export default slice.reducer;