import { useMediaQuery } from '@mui/material';
import ProductOfferListFilterAdapter from 'presentation/features/offer/product/list/adapters/filter';
import { FilterWrapper } from 'presentation/features/offer/product/list/controls';
import { useProductOfferListCategoriesData } from 'presentation/features/offer/product/list/hooks/useProductOfferListCategoriesData';
import ProductListSidebar from 'presentation/features/offer/product/list/sidebar';
import { productOfferListCategoryIdSelector } from 'presentation/features/offer/product/list/store/selectors';
import Subcategories from 'presentation/features/offer/product/list/subcategories';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferListAsideAdapter = () => {
  const categoryId = useSelector(productOfferListCategoryIdSelector);
  const handlers = useContextHandlers();
  const {
    isCurrentCategoryLeaf,
    selectedSubcategories
  } = useProductOfferListCategoriesData();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSubcategoriesVisible = !!categoryId && selectedSubcategories.length > 0;
  const isFilterVisible = !!categoryId && !isCurrentCategoryLeaf && isMdUp;

  /*const onFilterApply = useCallback(
    (filter: PriceRangeFilter) => {
      dispatch(productOffersSetFilter(filter));
    },
    [dispatch]
  );*/

  if (!categoryId) {
    return null;
  }
  return _jsxs(_Fragment, {
    children: [isSubcategoriesVisible && _jsx(ProductListSidebar, {
      children: _jsx(Subcategories, {
        onCategoryClick: handlers.onChangeCategory // TODO(@Protopopov Ruslan): move to link.to
        ,
        categories: selectedSubcategories
      })
    }), isFilterVisible && _jsx(ProductListSidebar, {
      children: _jsx(FilterWrapper, {
        children: _jsx(ProductOfferListFilterAdapter, {})
      })
    })]
  });
};
export default ProductOfferListAsideAdapter;