import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useMemo } from 'react';
import HeaderLinks from '../components/links';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderLinksAdapter = () => {
  const {
    isAuthenticated
  } = useAuth();
  return useMemo(() => _jsx(HeaderLinks, {
    isAuthenticated: isAuthenticated,
    reverseDirection: true
  }), [isAuthenticated]);
};
export default HeaderLinksAdapter;