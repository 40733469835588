import { Typography } from '@mui/material';
import { LogoImageView } from 'components/common/images/cover';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { LogoImageViewWrapper, PartnerWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferPartnerInfo = props => {
  const {
    partner,
    onClick
  } = props;
  const {
    logo
  } = partner;
  return _jsx(PartnerWrapper, {
    onClick: onClick,
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      alignItems: "center",
      children: [logo?.path && _jsx(MPGrid, {
        item: true,
        children: _jsx(LogoImageViewWrapper, {
          children: _jsx(LogoImageView, {
            id: logo.path
          })
        })
      }), _jsxs(MPGrid, {
        item: true,
        container: true,
        flexDirection: "column",
        zero: true,
        spacing: 0.25,
        children: [_jsx(MPGrid, {
          item: true,
          zero: true,
          children: _jsx(Typography, {
            children: partner.name
          })
        }), _jsx(MPGrid, {
          item: true,
          zero: true,
          children: _jsx(Typography, {
            variant: "caption",
            color: "secondary",
            children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u043F\u0430\u0440\u0442\u043D\u0435\u0440\u0430"
          })
        })]
      })]
    })
  });
};
export default OfferPartnerInfo;