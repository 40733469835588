import { Fade } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferDetailsTabContactsLayout = props => {
  const {
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(MPGrid, {
      container: true,
      item: true,
      spacing: 5,
      children: children
    })
  });
};
export default OfferDetailsTabContactsLayout;