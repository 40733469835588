import { Fade } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { ServiceSelector } from '../serviceSelector';
import { ChipWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferDetailsTabServices = _ref => {
  let {
    selectedId,
    serviceCategories,
    services,
    cart,
    onSelect,
    onChangeServices
  } = _ref;
  return _jsx(Fade, {
    in: true,
    children: _jsxs("div", {
      children: [_jsxs(ChipWrapper, {
        children: [_jsx(MPChip, {
          label: "\u0412\u0441\u0435",
          variant: selectedId ? 'outlined' : 'filled',
          color: selectedId ? 'white' : 'black',
          onClick: () => onSelect(null)
        }), serviceCategories.map(_ref2 => {
          let {
            id,
            name
          } = _ref2;
          return _jsx(MPChip, {
            label: name,
            variant: id === selectedId ? 'filled' : 'outlined',
            color: id === selectedId ? 'black' : 'white',
            onClick: () => onSelect(id)
          }, id);
        })]
      }), services?.map(service => _jsx(ServiceSelector, {
        ...service,
        cart: cart,
        onChange: onChangeServices
      }, service.id))]
    })
  });
};
export default BookingOfferDetailsTabServices;