import { cacheApi } from 'data/api/cache';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EPaginationBehaviour } from '../../types';
import { pageableDataProcessChunk } from '../utils';
/**
 * хук для работы с данными пагинированного списка в рамках rtk qeury
 * добавляет данные в специфичный query - getCachedData
 * время жизни сохраняемых данных устанавливается в getCachedData (иначе берется из настройки rtk query, штатно там 60s при отсутствии активных подписок)
 */
const useRtkQueryCachedPageableData = _ref => {
  let {
    guid,
    tag,
    payload
  } = _ref;
  const {
    page,
    pageSize,
    ...payloadData
  } = payload ?? {};
  const dispatch = useDispatch();

  //используем чистые параметры запроса без пагинации в построении ключа кэша
  const args = {
    guid,
    ...payloadData,
    tag
  };
  const argsString = JSON.stringify(args);
  const cachedData = cacheApi.endpoints.getCachedData.useQueryState(args, {
    selectFromResult: state => state?.currentData ?? null
  });
  const cachePage = cachedData?.page;

  //хук инициализирует запрос, далее отписывается
  //это нужно для того, чтобы rtk query следил за жизнью кэша
  //если этого не сделать то кэш будет вечный, так как данные мы просто по селектору достаём
  useEffect(() => {
    const subscription = dispatch(cacheApi.endpoints.getCachedData.initiate(JSON.parse(argsString)));
    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, argsString]);
  const upsertQueryData = useCallback(cache => {
    const key = JSON.parse(argsString);
    const modifier = () => dispatch(cacheApi.util.upsertQueryData('getCachedData', key, cache));

    //придётся дождаться пока отработает запрос, так как мы его инициировали через initiate, потому что если его не дождаться то кэш затрётся null-ом
    const activeThunk = dispatch(cacheApi.util.getRunningQueryThunk('getCachedData', key));
    if (activeThunk) {
      activeThunk?.then(modifier);
    } else {
      modifier();
    }
  }, [dispatch, argsString]);
  const clearCache = useCallback(() => {
    dispatch(cacheApi.util.updateQueryData('getCachedData', JSON.parse(argsString), draft => {
      if (draft) {
        Object.assign(draft, null);
      }
    }));
  }, [dispatch, argsString]);
  const setCache = useCallback(function (response) {
    let behaviour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EPaginationBehaviour.IncrementPage;
    pageableDataProcessChunk({
      chunk: response,
      currentCachePage: cachePage,
      currentCacheData: cachedData?.data ?? null,
      behaviour,
      upsertCallback: upsertQueryData
    });
  }, [cachePage, cachedData?.data, upsertQueryData]);
  return {
    cache: cachedData,
    setCache,
    clearCache
  };
};
export default useRtkQueryCachedPageableData;