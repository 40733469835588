import PartnerLink from 'presentation/components/common/partnerLink';
import Splitter from 'presentation/components/common/splitter';
import ActivationListItemExpireDate from 'presentation/features/activation/components/sidebar/expiryDate/title';
import OfferPromotionCopy from 'presentation/features/offer/components/promotionCopy';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
// import OfferPromotionCopy from '../../../../offer/components/promotionCopy';
import { SidebarWrapper } from '../controls';
import ActivationListItemLabel from '../label/title';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemCodeSidebar = props => {
  const {
    appointmentDate,
    code,
    expireDate,
    codeTypeName,
    partnerLink,
    onPartnerLinkClick,
    onCopy,
    copied,
    needCopy,
    onNeedCopy
  } = props;
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      direction: "column",
      children: [_jsxs(MPGrid, {
        item: true,
        children: [_jsx(ActivationListItemLabel, {
          codeTypeName: codeTypeName,
          appointmentDate: appointmentDate
        }), expireDate && _jsx(ActivationListItemExpireDate, {
          expireDate: expireDate
        })]
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(OfferPromotionCopy, {
          label: code,
          onCopy: onCopy,
          onNeedCopy: onNeedCopy,
          needCopy: needCopy,
          copied: copied
        })
      }), _jsxs(MPGrid, {
        item: true,
        children: [_jsx(Splitter, {
          variant: "horizontal",
          size: 1
        }), _jsx(PartnerLink, {
          link: partnerLink,
          onClick: onPartnerLinkClick
        })]
      })]
    })
  });
};
export default ActivationListItemCodeSidebar;