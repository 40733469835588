import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimUnavailableMessage = () => {
  return _jsx(ClaimMessage, {
    type: "info",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E \u0432 \u0432\u0430\u0448\u0435\u043C \u0440\u0435\u0433\u0438\u043E\u043D\u0435 \u0438\u043B\u0438 \u043F\u0440\u0435\u0434\u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u0442\u043E\u043B\u044C\u043A\u043E \u0434\u043B\u044F \u043A\u043E\u0440\u043F\u043E\u0440\u0430\u0442\u0438\u0432\u043D\u044B\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439"
    })
  });
};