import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import CorpOfferDetailsContainer from 'presentation/features/offer/corp/details/container';
import { ECorpOfferDetailsTab } from 'presentation/features/offer/corp/details/utils';
import { ECorpOfferUrlParam } from 'presentation/features/offer/corp/utils';
import { ERenderSource } from 'presentation/types';
import { useLocation, useParams } from 'react-router';
import ScreenLayout from '../../layouts/screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferScreen = () => {
  const location = useLocation();
  const from = location.state?.from ?? ERenderSource.Any;
  const {
    id
  } = useParams();
  const tab = new URLSearchParams(location.search).get(ECorpOfferUrlParam.Tab) ?? ECorpOfferDetailsTab.Description;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(CorpOfferDetailsContainer, {
        id: id,
        guid: guid,
        tab: tab,
        from: from
      })
    })
  });
};
export default CorpOfferScreen;