import { useCallback, useEffect, useState } from 'react';
import { getElementPageTopOffset } from '../utils/dom';
const useScrollToElement = _ref => {
  let {
    adjusmentTop = 0
  } = _ref;
  const [callScrollWatcher, setCallScrollWatcher] = useState(Symbol());
  const [currentEl, setCurrenEl] = useState(null);
  useEffect(() => {
    if (!currentEl) {
      return;
    }
    const elementTopOffset = getElementPageTopOffset(currentEl);
    window.scrollTo({
      top: elementTopOffset - adjusmentTop,
      behavior: 'smooth'
    });
  }, [adjusmentTop, callScrollWatcher, currentEl]);
  const scrollTo = useCallback(el => {
    setCallScrollWatcher(Symbol());
    setCurrenEl(el);
  }, []);
  return {
    scrollTo
  };
};
export default useScrollToElement;