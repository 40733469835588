import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import OfferListLayout from 'presentation/layouts/offerList';
import CommonRenderManager from '../../../../components/common/renderManager/container';
import CorpOfferBonusUnavailableAdapter from '../components/bonusUnavailableStub';
import { CorpOfferListAsideAdapter, CorpOfferListBannersAdapter, CorpOfferListDataCommonAdapter, CorpOfferListDataUpcomingAdapter, CorpOfferListLoaderAdapter, CorpOfferListSortSelectAdapter, CorpOfferListStubAdapter, CorpOffersHeaderAdapter } from './adapters';
import { CorpOfferListContext } from './context';
import useCorpOfferListHandlers from './hooks/useCorpOfferList';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = OfferListLayout;
const Header = _jsx(CorpOffersHeaderAdapter, {});
const Aside = _jsx(CorpOfferListAsideAdapter, {});
const Stub = _jsx(CorpOfferListStubAdapter, {});
const SortSelect = _jsx(CorpOfferListSortSelectAdapter, {});
const CorpOfferListContainer = props => {
  const {
    guid,
    name,
    categoryId,
    partnerId
  } = props;
  const handlers = useCorpOfferListHandlers({
    guid,
    name,
    categoryId,
    partnerId
  });
  return _jsx(CorpOfferListContext.Provider, {
    value: handlers,
    children: _jsx(RenderDecoratorContainer, {
      decorators: [_jsx(CommonRenderManager, {
        loader: _jsx(CorpOfferListLoaderAdapter, {}),
        stub: CorpOfferListStubAdapter
      }, 'loader'), _jsx(CorpOfferBonusUnavailableAdapter, {}, 'bonusUnavailable')],
      children: _jsxs(Layout, {
        banners: _jsx(CorpOfferListBannersAdapter, {
          guid: guid
        }),
        header: Header,
        aside: Aside,
        controls: SortSelect,
        stub: Stub,
        children: [_jsx(CorpOfferListDataCommonAdapter, {
          guid: guid
        }), _jsx(CorpOfferListDataUpcomingAdapter, {
          guid: guid
        })]
      })
    })
  });
};
export default CorpOfferListContainer;