import { Typography } from '@mui/material';
import { EOfferActivateError, EOfferStatus } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { PlusIcon } from 'media/icons';
import { MPLink } from 'theme/ui-kit/link';
import { getIsTradeOfferPersonalPromotionType } from '../../../utils';
import useTradeOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TradeOfferClaimPromotionNew = props => {
  const {
    promotionDayLimitError,
    activationIsFetching,
    onOpenReactivationDialog
  } = props;
  const isUnavailable = promotionDayLimitError || activationIsFetching;
  return _jsxs(MPLink, {
    color: "primary.main",
    iconSize: "small",
    startIcon: PlusIcon,
    onClick: isUnavailable ? undefined : onOpenReactivationDialog,
    children: [_jsx(Typography, {
      variant: "body1",
      role: "button",
      children: "\u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439"
    }), activationIsFetching && _jsx(ContentLoader, {
      size: 15
    })]
  });
};
export const TradeOfferClaimPromotionNewAdapter = () => {
  const {
    tradeOffer,
    isActivationAvailable,
    activation,
    isTimerActive
  } = useTradeOfferClaimContext();
  const {
    openReactivationDialog
  } = useTradeOfferClaimDialogs();
  const {
    lastActivation,
    isReactivationSupported,
    activationIsFetching
  } = activation;
  const lastPromotion = lastActivation?.promotion;
  const isCodesEnded = (tradeOffer?.notUsedOfferCount ?? 0) === 0;
  const promotionDayLimitError = activation?.activationError?.type === EOfferActivateError.PromotionDayCountLimit;
  return useMemo(() => tradeOffer?.status === EOfferStatus.Active && lastPromotion && !isCodesEnded && isReactivationSupported && isActivationAvailable && !(isTimerActive && lastActivation?.appointmentDate) && getIsTradeOfferPersonalPromotionType(tradeOffer.promotionType) ? _jsx(TradeOfferClaimPromotionNew, {
    activationIsFetching: activationIsFetching,
    promotionDayLimitError: promotionDayLimitError,
    onOpenReactivationDialog: openReactivationDialog
  }) : null, [tradeOffer?.status, tradeOffer?.promotionType, lastActivation, lastPromotion, promotionDayLimitError, isActivationAvailable, isReactivationSupported, isCodesEnded, isTimerActive, activationIsFetching, openReactivationDialog]);
};