import { useSystemEventBus } from '@privilege-frontend/eventBus';
import store from 'data/store/store';
import { EOrderByDateType } from 'domain/model/enums';
import { currentUserIsEmailVerifiedSelector } from 'features/user/current/store/selectors';
import { createEvent as confirmEmailEvent } from 'features/user/events/confirmEmail';
import { createEvent as confirmPhoneEvent } from 'features/user/events/confirmPhone';
import { createEvent } from 'features/user/events/needLogin';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isDefined } from 'utils/array';
import ErrorHandler from '../../../../../../data/network/errorHandler';
import { ERenderSource } from '../../../../../types';
import { useAuth } from '../../../../auth/provider/useAuth';
import { useWebAnalytics } from '../../../../webAnalytics';
import { getBookingOffersDetailsOrderRoute, getBookingOffersListRoute } from '../../routes';
import { bookingOfferDetailsCartSelector } from '../store/selectors';
import { bookingOfferDetailsAddToCart, bookingOfferDetailsClearCart, bookingOfferDetailsSetModal } from '../store/slice';
import { sortByDate } from '../utils';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';
const useBookingOfferDetails = (id, from) => {
  const {
    webAnalytics
  } = useWebAnalytics();
  const dispatch = useDispatch();
  const {
    publishFlow
  } = useSystemEventBus();
  const history = useHistory();
  const {
    isAuthenticated,
    login
  } = useAuth();
  const {
    publish
  } = useSystemEventBus();
  const isUserEmailVerified = useSelector(currentUserIsEmailVerifiedSelector);
  const {
    gotoPrevIndependentLocation
  } = useHistoryExtensions();
  const {
    createOrder,
    isOrderCreating,
    errorCreateOrder
  } = useBookingOfferDetailsData(id);
  const [customerComment, setCustomerComment] = useState('');
  const changeCustomerComment = value => {
    setCustomerComment(value);
  };
  const cart = useSelector(bookingOfferDetailsCartSelector);
  const getCart = useCallback(() => {
    const cart = bookingOfferDetailsCartSelector(store.getState());
    return cart?.[id] ?? null;
  }, [id]);
  const onBack = useCallback(() => {
    gotoPrevIndependentLocation(getBookingOffersListRoute());
  }, [gotoPrevIndependentLocation]);

  // Объединение услуг и тарифов
  const mergeServices = useCallback(_ref => {
    let {
      priceUnit,
      orderItem,
      cart,
      serviceTariffInCart
    } = _ref;
    const updatedCart = [...cart];
    const serviceTariffIndex = serviceTariffInCart && cart.indexOf(serviceTariffInCart);
    const unit = {
      priceUnit,
      orderItem
    };
    const isTariffExist = typeof serviceTariffIndex === 'number';

    // тариф услуги с датами существует в корзине, одинаковые суммируются
    if (priceUnit?.orderByDateType !== EOrderByDateType.None && serviceTariffInCart) {
      const cartSlots = [...serviceTariffInCart.orderItem.slots];
      orderItem.slots.forEach(item => {
        const existingItem = serviceTariffInCart.orderItem.slots.find(cartItem => {
          const sameStartDates = cartItem.startDate === item.startDate;
          if (priceUnit?.orderByDateType === EOrderByDateType.Days) {
            return sameStartDates;
          } else {
            return sameStartDates && cartItem.endDate === item.endDate;
          }
        });
        if (existingItem) {
          const existedData = cartSlots[cartSlots.indexOf(existingItem)];
          cartSlots[cartSlots.indexOf(existingItem)] = {
            ...existedData,
            qty: existedData.qty + item.qty
          };
        } else {
          cartSlots.push(item);
        }
      });
      if (isTariffExist && updatedCart[serviceTariffIndex]) {
        unit.orderItem.slots = cartSlots;
        updatedCart[serviceTariffIndex] = unit;
        updatedCart[serviceTariffIndex].orderItem.slots.sort(sortByDate);
      }
    }

    // аналогично для бездат
    if (priceUnit?.orderByDateType === EOrderByDateType.None && isTariffExist) {
      const currentData = updatedCart[serviceTariffIndex];
      updatedCart[serviceTariffIndex] = {
        ...currentData,
        orderItem: {
          ...currentData.orderItem,
          qty: (currentData.orderItem.qty ?? 0) + (orderItem.qty ?? 0)
        }
      };
    }
    return updatedCart;
  }, []);
  const mergePeriodServicesOnEdit = useCallback(_ref2 => {
    let {
      index,
      cart,
      orderItem
    } = _ref2;
    const updatedCart = [...cart];
    const changedSlot = orderItem.slots[index[1]];
    const hasSameValue = changedSlot && updatedCart[index[0]]?.orderItem.slots.find((cartItem, cartIndex) => {
      return cartIndex !== index[1] && cartItem.startDate === changedSlot.startDate && cartItem.endDate === changedSlot.endDate;
    });
    if (hasSameValue) {
      updatedCart[index[0]].orderItem.slots = updatedCart[index[0]].orderItem.slots.map((cartItem, cartIndex) => {
        if (cartIndex === index[1]) {
          return null;
        }
        return cartItem.startDate === changedSlot.startDate && cartItem.endDate === changedSlot.endDate ? {
          ...cartItem,
          qty: cartItem.qty + changedSlot.qty
        } : cartItem;
      }).filter(isDefined);
    }
    return updatedCart;
  }, []);
  const editCart = useCallback(_ref3 => {
    let {
      index,
      priceUnit,
      orderItem,
      cart
    } = _ref3;
    let updatedCart = [];
    updatedCart = cart.map((item, mapIndex) => {
      if (mapIndex === index[0]) {
        const isNoDates = priceUnit.orderByDateType === EOrderByDateType.None;
        // проверка на удаление
        const isNotEmpty = isNoDates ? (orderItem.qty ?? 0) > 0 : orderItem.slots.length > 0;
        return isNotEmpty ? {
          priceUnit,
          orderItem: isNoDates ? orderItem : {
            ...orderItem,
            slots: orderItem.slots.sort(sortByDate)
          }
        } : null;
      }
      return item;
    }).filter(isDefined);

    // Мерж при совпадении дат после обновления сервисов типа Range
    if (priceUnit.orderByDateType === EOrderByDateType.Period) {
      updatedCart = mergePeriodServicesOnEdit({
        index,
        orderItem,
        cart: updatedCart
      });
    }
    return updatedCart;
  }, [mergePeriodServicesOnEdit]);
  const updateCart = useCallback(props => {
    const {
      priceUnit,
      orderItem
    } = props;
    const cart = getCart();
    const serviceInCart = cart?.find(_ref4 => {
      let {
        priceUnit: cartUnit
      } = _ref4;
      return cartUnit.id === priceUnit.id;
    });
    const serviceTariffInCart = cart?.find(_ref5 => {
      let {
        priceUnit: cartUnit,
        orderItem: {
          priceItem: {
            id: cartPriceItemId
          }
        }
      } = _ref5;
      return cartUnit.id === priceUnit.id && cartPriceItemId === orderItem.priceItem.id;
    });
    let updatedCart = [];

    // Редактирование
    if (typeof props.index === 'object' && cart) {
      updatedCart = editCart({
        ...props,
        cart
      });
    } else if (cart && serviceInCart) {
      // Добавление нового тарифа в существующую услугу
      if (!serviceTariffInCart) {
        updatedCart = [...cart];
        updatedCart.push({
          priceUnit,
          orderItem
        });
        // Мерж тарифов услуги
      } else {
        updatedCart = mergeServices({
          cart,
          serviceTariffInCart,
          priceUnit,
          orderItem
        });
      }
      // Добавление новой услуги с тарифом
    } else {
      updatedCart = [...(cart ?? []), {
        priceUnit,
        orderItem
      }];
    }
    dispatch(bookingOfferDetailsAddToCart({
      offerId: id,
      items: updatedCart
    }));
  }, [dispatch, editCart, getCart, id, mergeServices]);
  const onClearOfferCart = useCallback(() => {
    dispatch(bookingOfferDetailsClearCart(id));
  }, [dispatch, id]);
  const onChangeServicesSelection = _ref6 => {
    let {
      priceItemId,
      priceUnit,
      isIncrement
    } = _ref6;
    if (!isAuthenticated) {
      login();
      return;
    }
    const cart = getCart();
    const byUnit = cart?.filter(item => item.priceUnit?.id === priceUnit.id);

    // Модалки с датами
    if (priceUnit.orderByDateType !== EOrderByDateType.None) {
      dispatch(bookingOfferDetailsSetModal({
        priceUnit,
        orderItem: {
          priceItem: {
            id: priceItemId
          },
          qty: null,
          slots: []
        }
      }));
      return;
    }

    // Без дат
    dispatch(bookingOfferDetailsSetModal({
      priceUnit,
      orderItem: {
        priceItem: {
          id: priceItemId
        },
        qty: isIncrement || !byUnit || !byUnit.length ? 1 : byUnit[0].orderItem.qty,
        slots: []
      }
    }));
  };
  const onDeleteService = index => {
    const cart = getCart();
    const selection = cart?.[index[0]];
    if (!selection) {
      return;
    }
    let slots = [];
    if (typeof index[1] === 'number') {
      slots = [...selection.orderItem.slots];
      slots.splice(index[1], 1);
    }
    updateCart({
      ...selection,
      index,
      orderItem: {
        priceItem: selection.orderItem.priceItem,
        qty: 0,
        slots
      }
    });
  };
  const onApplyModalChanges = props => {
    const cart = getCart();
    const {
      priceUnit,
      orderItem
    } = props || {};
    if (props && priceUnit && orderItem) {
      const byItem = cart?.filter(item => item.orderItem.priceItem.id === orderItem.priceItem.id);
      if (priceUnit?.orderByDateType === EOrderByDateType.None && byItem?.length && cart) {
        updateCart({
          priceUnit,
          orderItem,
          index: priceUnit.orderByDateType === EOrderByDateType.None && !props.index ? undefined : [cart.indexOf(byItem[0])]
        });
      } else {
        updateCart(props);
      }
    }
    dispatch(bookingOfferDetailsSetModal(null));
  };
  const onUpdateModal = index => {
    const cart = getCart();
    const selection = cart?.[index[0]];
    if (!selection) {
      return;
    }
    dispatch(bookingOfferDetailsSetModal({
      ...selection,
      index
    }));
  };
  const handleOrder = async userPhone => {
    if (!cart || !Array.isArray(cart[id])) {
      return;
    }

    // оформляем заказ
    try {
      const order = await createOrder({
        customerComment,
        customerPhone: userPhone,
        items: cart[id]?.map(_ref7 => {
          let {
            orderItem,
            priceUnit
          } = _ref7;
          const slots = orderItem.slots?.map(_ref8 => {
            let {
              startDate,
              endDate,
              qty
            } = _ref8;
            return {
              startDate: typeof startDate === 'string' ? startDate : '',
              endDate: typeof endDate === 'string' ? endDate : '',
              qty
            };
          });
          return {
            priceItem: orderItem.priceItem,
            ...(priceUnit.orderByDateType === EOrderByDateType.None ? {
              qty: orderItem.qty,
              slots: null
            } : {
              qty: null,
              slots
            })
          };
        }) ?? []
      }).unwrap();
      history.push(getBookingOffersDetailsOrderRoute({
        id: order.id
      }));
    } catch (error) {
      ErrorHandler.handleHttpError(error);
      return;
    }
    onClearOfferCart();
  };
  const tryHandleOrder = () => {
    const events = [];
    if (isUserEmailVerified === false) {
      events.push(confirmEmailEvent({}));
    }

    //передаём калбэк для автоматического создания заказа после ивента ввода телефона
    events.push(confirmPhoneEvent({}, handleOrder));
    publishFlow(events);
  };
  useEffect(() => {
    if (!isAuthenticated) {
      publish(createEvent({}));
    }
  }, [isAuthenticated, publish]);
  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);
  return {
    onBack,
    onClearOfferCart,
    onChangeServicesSelection,
    onDeleteService,
    onApplyModalChanges,
    onUpdateModal,
    handleOrder,
    tryHandleOrder,
    isOrderCreating,
    errorCreateOrder,
    customerComment,
    changeCustomerComment
  };
};
export default useBookingOfferDetails;