import { useState } from 'react';
import { IsPhoneExistsDialog } from './exists';
import { IsPhoneNotExistsDialog } from './notExists';
import { useUpdateUserPhone } from './hooks/useUpdateUserPhone';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const RequiredActionUpdateUserPhoneContainer = _ref => {
  let {
    onSuccess,
    onClose
  } = _ref;
  const {
    phone,
    isFetching,
    onUpdate
  } = useUpdateUserPhone({
    onSuccess,
    onClose
  });
  const [editMode, setEditMode] = useState(() => !phone);
  const onChange = () => setEditMode(true);
  if (editMode) {
    return _jsx(IsPhoneNotExistsDialog, {
      phone: phone,
      isFetching: isFetching,
      onSubmit: onUpdate,
      onCancel: onClose
    });
  }
  return _jsx(IsPhoneExistsDialog, {
    phone: phone,
    onSubmit: onUpdate,
    onChange: onChange,
    onCancel: onClose
  });
};
export default RequiredActionUpdateUserPhoneContainer;