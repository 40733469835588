import { ECatalogTab } from 'presentation/features/catalog/types';
import { useSelector } from 'react-redux';
import { useGetBookingOfferCategoriesQuery } from 'data/api/bookingOffer';
import { catalogTabSelector } from '../store/selectors';
import { CatalogOffersContainer } from '../components/catalogContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CatalogBookingOffersTabAdapter = _ref => {
  let {
    selected
  } = _ref;
  const tab = useSelector(catalogTabSelector);
  const {
    onSelectBookingOffersCategory
  } = useContextHandlers();
  const isActiveTab = tab === ECatalogTab.BookingOffers;
  const response = useGetBookingOfferCategoriesQuery({
    guid: 'catalog-booking-offers'
  }, {
    skip: !isActiveTab
  });
  return isActiveTab ? _jsx(CatalogOffersContainer, {
    ...response,
    onSelect: onSelectBookingOffersCategory,
    selected: selected
  }) : null;
};