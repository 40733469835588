import { useMediaQuery } from '@mui/material';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/routes';
import SearchControlContainer from 'presentation/features/search/control/container';
import { useRouteMatch } from 'react-router';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchAdapter = () => {
  const handlers = useContextHandlers();
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isBookingSection = !!useRouteMatch([getBookingOffersListRoute({}).pathname]);
  const onClick = isSmUp ? undefined : handlers.showCatalog;
  const placeholder = isBookingSection ? 'Куда вы хотите поехать?' : 'Искать предложения';
  return _jsx(SearchControlContainer, {
    placeholder: placeholder
    /*suggestions={SearchSuggestionsContainer}*/,
    onClick: onClick
  });
};
export default SearchAdapter;