import openApi from 'data/openApi';
import { userThemeSelector } from 'presentation/features/user/theme/store/selectors';
import { useSelector } from 'react-redux';
const useUserTheme = () => {
  const theme = useSelector(userThemeSelector);
  return {
    ...theme,
    logo: theme?.logo ? openApi.files.getImageUrl(theme?.logo) : undefined,
    favicon: theme?.favicon ? openApi.files.getImageUrl(theme?.favicon) : undefined
  };
};
export default useUserTheme;