import { CatalogAspOffersTabAdapter, CatalogBehaviorAdapter, CatalogBookingOffersTabAdapter, CatalogCorpOffersTabAdapter, CatalogProductOffersTabAdapter, CatalogTabsAdapter, CatalogTradeOffersTabAdapter } from './adapters';
import { CatalogContext } from './context';
import useCatalogHandlers from './hooks/useCatalog';
import { CatalogLayout } from './layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = CatalogLayout;
export const CatalogContainer = props => {
  const {
    open,
    selected,
    onClose,
    matchedOfferSectionPath
  } = props;
  const handlers = useCatalogHandlers({
    onClose,
    matchedOfferSectionPath
  });
  const catalogProps = {
    selected
  };
  return _jsxs(CatalogContext.Provider, {
    value: handlers,
    children: [_jsx(CatalogBehaviorAdapter, {}), _jsxs(Layout, {
      open: open,
      tabs: _jsx(CatalogTabsAdapter, {}),
      onClose: onClose,
      children: [_jsx(CatalogTradeOffersTabAdapter, {
        ...catalogProps
      }), _jsx(CatalogCorpOffersTabAdapter, {
        ...catalogProps
      }), _jsx(CatalogProductOffersTabAdapter, {
        ...catalogProps
      }), _jsx(CatalogBookingOffersTabAdapter, {
        ...catalogProps
      }), _jsx(CatalogAspOffersTabAdapter, {
        ...catalogProps
      })]
    })]
  });
};