import axios from 'axios';
import { createCancelToken } from '../utils';
const landing = {
  window: props => {
    const {
      signal
    } = props;
    return {
      url: `/customers/current/containers`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  windowTest: props => {
    const {
      signal
    } = props;
    const params = new URLSearchParams();
    params.append('page', '0');
    params.append('size', '99999');
    return {
      url: `/customers/current/containers`,
      cancelToken: signal && createCancelToken(axios, signal),
      method: 'GET',
      params
    };
  }
};
export default landing;