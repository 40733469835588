import { Chip } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import { BlankChip, DeleteIconWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DeleteIcon = _jsx(CloseIcon, {});
export const MPChip = props => {
  const Component = props.label ? Chip : BlankChip;

  // NOTE(@Protopopov Ruslan): chip не поддерживает endIcon нативно, так что вставляем иконку в слот deleteIcon
  const applyEndIconProps = () => {
    if (props.onDelete) {
      const DeleteIconComponent = _jsx(DeleteIconWrapper, {
        children: DeleteIcon
      });
      return {
        deleteIcon: DeleteIconComponent,
        onDelete: props.onDelete
      };
    }
    if (props.endIcon) {
      return {
        deleteIcon: props.endIcon,
        onDelete: () => null
      };
    }
  };
  return _jsx(Component, {
    deleteIcon: DeleteIcon,
    ...props,
    ...applyEndIconProps()
  });
};