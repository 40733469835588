import { createSlice } from '@reduxjs/toolkit';
import { partnerDeskOffersListEmptyParams } from '../utils';
const emptyOffersParams = partnerDeskOffersListEmptyParams;
const slice = createSlice({
  name: 'details',
  initialState: {
    guid: null,
    tradeOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams
    },
    aspOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams
    },
    corpOffers: {
      isNewFetching: true,
      common: emptyOffersParams,
      upcoming: emptyOffersParams
    },
    productOffers: {
      isNewFetching: true,
      common: emptyOffersParams
    },
    bookingOffers: {
      isNewFetching: true,
      list: emptyOffersParams
    }
  },
  reducers: {
    partnerDeskStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.guid = payload;
    },
    partnerDeskTradeOffersSetPageSize: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        type,
        pageSize
      } = payload;
      state.tradeOffers[type].pageSize = pageSize;
    },
    partnerDeskTradeOffersSetPage: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      const {
        type,
        page
      } = payload;
      state.tradeOffers[type].page = page;
    },
    partnerDeskTradeOffersSetSort: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      const {
        type,
        sort
      } = payload;
      state.tradeOffers[type].sort = sort;
    },
    partnerDeskTradeOffersSetArgs: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        type,
        args
      } = payload;
      state.tradeOffers[type] = args;
    },
    partnerDeskTradeOffersSetIsNewFetching: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.tradeOffers.isNewFetching = payload;
    },
    partnerDeskAspOffersSetPageSize: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      const {
        type,
        pageSize
      } = payload;
      state.aspOffers[type].pageSize = pageSize;
    },
    partnerDeskAspOffersSetPage: (state, _ref8) => {
      let {
        payload
      } = _ref8;
      const {
        type,
        page
      } = payload;
      state.aspOffers[type].page = page;
    },
    partnerDeskAspOffersSetSort: (state, _ref9) => {
      let {
        payload
      } = _ref9;
      const {
        type,
        sort
      } = payload;
      state.aspOffers[type].sort = sort;
    },
    partnerDeskAspOffersSetArgs: (state, _ref10) => {
      let {
        payload
      } = _ref10;
      const {
        type,
        args
      } = payload;
      state.aspOffers[type] = args;
    },
    partnerDeskAspOffersSetIsNewFetching: (state, _ref11) => {
      let {
        payload
      } = _ref11;
      state.aspOffers.isNewFetching = payload;
    },
    partnerDeskCorpOffersSetPageSize: (state, _ref12) => {
      let {
        payload
      } = _ref12;
      const {
        type,
        pageSize
      } = payload;
      state.corpOffers[type].pageSize = pageSize;
    },
    partnerDeskCorpOffersSetPage: (state, _ref13) => {
      let {
        payload
      } = _ref13;
      const {
        type,
        page
      } = payload;
      state.corpOffers[type].page = page;
    },
    partnerDeskCorpOffersSetSort: (state, _ref14) => {
      let {
        payload
      } = _ref14;
      const {
        type,
        sort
      } = payload;
      state.corpOffers[type].sort = sort;
    },
    partnerDeskCorpOffersSetArgs: (state, _ref15) => {
      let {
        payload
      } = _ref15;
      const {
        type,
        args
      } = payload;
      state.corpOffers[type] = args;
    },
    partnerDeskCorpOffersSetIsNewFetching: (state, _ref16) => {
      let {
        payload
      } = _ref16;
      state.corpOffers.isNewFetching = payload;
    },
    partnerDeskProductOffersSetPageSize: (state, _ref17) => {
      let {
        payload
      } = _ref17;
      state.productOffers.common.pageSize = payload;
    },
    partnerDeskProductOffersSetPage: (state, _ref18) => {
      let {
        payload
      } = _ref18;
      state.productOffers.common.page = payload;
    },
    partnerDeskProductOffersSetSort: (state, _ref19) => {
      let {
        payload
      } = _ref19;
      state.productOffers.common.sort = payload;
    },
    partnerDeskProductOffersSetArgs: (state, _ref20) => {
      let {
        payload
      } = _ref20;
      state.productOffers.common = payload;
    },
    partnerDeskProductOffersSetIsNewFetching: (state, _ref21) => {
      let {
        payload
      } = _ref21;
      state.productOffers.isNewFetching = payload;
    },
    partnerDeskBookingOffersSetPageSize: (state, _ref22) => {
      let {
        payload
      } = _ref22;
      state.bookingOffers.list.pageSize = payload;
    },
    partnerDeskBookingOffersSetPage: (state, _ref23) => {
      let {
        payload
      } = _ref23;
      state.bookingOffers.list.page = payload;
    },
    partnerDeskBookingOffersSetSort: (state, _ref24) => {
      let {
        payload
      } = _ref24;
      state.bookingOffers.list.sort = payload;
    },
    partnerDeskBookingOffersSetArgs: (state, _ref25) => {
      let {
        payload
      } = _ref25;
      state.bookingOffers.list = payload;
    },
    partnerDeskBookingOffersSetIsNewFetching: (state, _ref26) => {
      let {
        payload
      } = _ref26;
      state.bookingOffers.isNewFetching = payload;
    }
  }
});
export const {
  partnerDeskStartSession,
  partnerDeskTradeOffersSetPage,
  partnerDeskTradeOffersSetPageSize,
  partnerDeskTradeOffersSetSort,
  partnerDeskTradeOffersSetArgs,
  partnerDeskTradeOffersSetIsNewFetching,
  partnerDeskAspOffersSetPage,
  partnerDeskAspOffersSetPageSize,
  partnerDeskAspOffersSetSort,
  partnerDeskAspOffersSetArgs,
  partnerDeskAspOffersSetIsNewFetching,
  partnerDeskCorpOffersSetPage,
  partnerDeskCorpOffersSetPageSize,
  partnerDeskCorpOffersSetSort,
  partnerDeskCorpOffersSetArgs,
  partnerDeskCorpOffersSetIsNewFetching,
  partnerDeskProductOffersSetPage,
  partnerDeskProductOffersSetPageSize,
  partnerDeskProductOffersSetSort,
  partnerDeskProductOffersSetArgs,
  partnerDeskProductOffersSetIsNewFetching,
  partnerDeskBookingOffersSetPage,
  partnerDeskBookingOffersSetPageSize,
  partnerDeskBookingOffersSetSort,
  partnerDeskBookingOffersSetArgs,
  partnerDeskBookingOffersSetIsNewFetching
} = slice.actions;
export default slice.reducer;