import React from 'react';
import useGridSpacing from '../../../hooks/useGridSpacing';
import { MPGrid } from '../../grid';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const flexDirectionMap = {
  vertical: 'column',
  horizontal: 'row'
};
const ConfirmGroupButtons = props => {
  const {
    children,
    variant = 'vertical'
  } = props;
  const {
    x2
  } = useGridSpacing();
  const childrenToRender = React.Children.toArray(children);
  const flexDirection = flexDirectionMap[variant];
  return _jsx(MPGrid, {
    container: true,
    spacing: x2,
    flexDirection: flexDirection,
    children: childrenToRender.map((c, i) => _jsx(MPGrid, {
      item: true,
      zero: true,
      children: c
    }, i))
  });
};
export default ConfirmGroupButtons;