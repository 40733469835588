import { FormHelperText } from '@mui/material';
import { EUserGender } from 'domain/model/enums';
import { MPTab } from 'presentation/theme/ui-kit/tab';
import { GenderTabs } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const UserGenderSelector = props => {
  const {
    value,
    disabled,
    error = false,
    helperText,
    onChange
  } = props;
  return _jsxs(_Fragment, {
    children: [_jsxs(GenderTabs, {
      value: value,
      error: error,
      onChange: (event, value) => onChange(value),
      children: [_jsx(MPTab, {
        label: "\u041C",
        value: EUserGender.Male,
        disabled: disabled
      }), _jsx(MPTab, {
        label: "\u0416",
        value: EUserGender.Female,
        disabled: disabled
      })]
    }), error && _jsx(FormHelperText, {
      error: true,
      children: helperText
    })]
  });
};