import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'provide',
  initialState: {
    guid: null,
    selected: []
  },
  reducers: {
    cartDetailsStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid,
        items
      } = payload;
      const sessionGuid = state.guid;
      if (sessionGuid !== guid) {
        state.guid = guid;
        state.selected = [];
      }

      //удаляем из выбранных те которых уже нет в корзине
      state.selected = state.selected.filter(s => items.some(item => item.id === s.id));
    },
    cartDetailsToggleSelected: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.selected = payload.reduce((prev, next) => {
        const existedIndex = prev.findIndex(p => p.id === next.id);
        if (existedIndex === -1) {
          return [...prev, next];
        } else {
          const result = [...prev];
          result.splice(existedIndex, 1);
          return result;
        }
      }, [...state.selected]);
    },
    cartDetailsSelect: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.selected = payload;
    }
  }
});
export const {
  cartDetailsStartSession,
  cartDetailsToggleSelected,
  cartDetailsSelect
} = slice.actions;
export default slice.reducer;