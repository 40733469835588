import { Fade, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ButtonWrapper, CopyLabel, Overlay, StyledCopyIcon, StyledDottedButton, TextLabel } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferPromotionCopy = _ref => {
  let {
    label,
    onCopy,
    copied,
    needCopy,
    onNeedCopy,
    ...buttonProps
  } = _ref;
  return _jsx(ButtonWrapper, {
    onMouseEnter: () => onNeedCopy(true),
    onMouseLeave: () => onNeedCopy(false),
    children: _jsx(CopyToClipboard, {
      text: label,
      onCopy: onCopy,
      children: _jsxs(StyledDottedButton, {
        variant: "outlined",
        fullWidth: true,
        copied: copied,
        ...buttonProps,
        children: [_jsx(TextLabel, {
          color: "textPrimary",
          children: label
        }), _jsxs(CopyLabel, {
          children: [_jsx(StyledCopyIcon, {
            fontSize: "micro"
          }), _jsx(Typography, {
            variant: "body2",
            color: "primary.main",
            children: "\u0441\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C"
          })]
        }), buttonProps.children, _jsx(Fade, {
          in: copied && !needCopy,
          children: _jsx(Overlay, {
            children: _jsx(Typography, {
              color: "success.contrastText",
              variant: "body1",
              fontWeight: 300,
              children: "\u041A\u043E\u0434 \u0441\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D"
            })
          })
        })]
      })
    })
  });
};
export default OfferPromotionCopy;