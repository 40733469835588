import { useGetProductOfferCategoriesUsedTreeQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import CategorySelectRootContainer from 'presentation/features/category/select';
import CategorySelectDoubleContainer from 'presentation/features/category/select/double';
import { ViewGridIcon } from 'presentation/media/icons';
import { useEffect } from 'react';
import useProductOffersCatalog from './useProductOffersCatalog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CatalogProductOffersContainer = props => {
  const {
    selected
  } = props;
  const {
    city,
    onChangeCategory
  } = useProductOffersCatalog();
  const {
    data: tree,
    isFetching,
    error
  } = useGetProductOfferCategoriesUsedTreeQuery({
    city
  });
  const treeOut = [{
    id: '',
    name: 'Все товары',
    parentId: null,
    image: _jsx(ViewGridIcon, {}),
    children: []
  }, ...(tree ?? [])];
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  return _jsx(CategorySelectRootContainer, {
    tree: treeOut,
    isFetching: isFetching,
    selected: selected,
    onSelect: onChangeCategory,
    children: _ref => {
      let {
        childTree,
        selectedCategory,
        expanded,
        isExpanded
      } = _ref;
      return childTree && _jsx(CategorySelectDoubleContainer, {
        categories: childTree,
        selectedCategory: selectedCategory,
        expanded: expanded,
        onSelect: onChangeCategory,
        isSelectedOrExpanded: isExpanded
      });
    }
  });
};