import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
export const sortByDate = (_ref, _ref2) => {
  let {
    startDate: s1
  } = _ref;
  let {
    startDate: s2
  } = _ref2;
  const f1 = moment(s1, EDateFormat.Server);
  const f2 = moment(s2, EDateFormat.Server);
  if (f1.isBefore(f2)) {
    return -1;
  } else if (f1.isAfter(f2)) {
    return 1;
  }
  return 0;
};