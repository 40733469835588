import React from 'react';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsContainerWrapperGrid = props => {
  const {
    mobileColumnsCount,
    children
  } = props;
  const childrenCount = React.Children.count(children);
  return _jsx(Wrapper, {
    mobileColumnsCount: mobileColumnsCount ?? childrenCount,
    children: children
  });
};
export default CmsContainerWrapperGrid;