import useBusinessConfig from '../../../../hooks/useBusinessConfig';
export const buildLinkByProxy = (link, proxyUrl) => {
  if (link && proxyUrl) {
    const params = new URLSearchParams({
      url: link,
      rule: location.hostname
    });
    return `${proxyUrl}?${params}`;
  } else {
    return link;
  }
};

/**
 * компонент который рендерит children, преобразуя ссылку в новую ссылку через прокси
 * прокси в данном случае - сервис, который осуществляет редирект запроса на правильный корпоративный ресурс в случае если необходимо
 */
const CorpLinkProxy = _ref => {
  let {
    link,
    children
  } = _ref;
  const {
    corpLinkProxyUrl
  } = useBusinessConfig();
  return children(buildLinkByProxy(link, corpLinkProxyUrl));
};
export default CorpLinkProxy;