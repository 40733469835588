import Carousel3d from 'presentation/components/common/carousels/3d';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CmsCarouselBanner = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Wrapper, {
    children: _jsx(Carousel3d, {
      autoplay: {
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      children: children
    })
  });
};