import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ProductOfferListDataContainer from '../dataContainer';
import { useProductOfferListCategoriesData } from '../hooks/useProductOfferListCategoriesData';
import { productOfferListCategoryIdSelector, productOfferListCommonArgsSelector, productOfferListFilterSelector, productOfferListGuidSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ProductOfferListCommonAdapter = props => {
  const {
    guid
  } = props;
  const args = useSelector(productOfferListCommonArgsSelector);
  const categoryId = useSelector(productOfferListCategoryIdSelector);
  const currentGuid = useSelector(productOfferListGuidSelector);
  const searchFilter = useSelector(productOfferListFilterSelector);
  const {
    isCurrentCategoryLeaf,
    selectedSubcategories
  } = useProductOfferListCategoriesData();
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSubcategoriesVisible = !!categoryId && selectedSubcategories.length > 0;
  const isFilterVisible = !!categoryId && !isCurrentCategoryLeaf && isMdUp;
  const isFilterStubVisible = !!categoryId && !isCurrentCategoryLeaf;
  const isMoreColumnsDislayed = !isSubcategoriesVisible && !isFilterVisible;
  return useMemo(() =>
  //  проверка на разность guid при апдейте компонента
  //  если разные - не рендерим, чтобы не показывать старые данные
  guid !== currentGuid || !args ? null : _jsx(ProductOfferListDataContainer, {
    ...props,
    guid: currentGuid,
    filterValues: searchFilter,
    isFilterStubVisible: isFilterStubVisible,
    isMoreColumnsDislayed: isMoreColumnsDislayed,
    args: args
  }), [args, currentGuid, guid, isFilterStubVisible, isMoreColumnsDislayed, props, searchFilter]);
};
export default ProductOfferListCommonAdapter;