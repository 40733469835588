import CmsPreviewLandingContainer from './landing';
import CmsPreviewPartnerContainer from './partner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsPreviewContainer = _ref => {
  let {
    context,
    guid
  } = _ref;
  return context.partner ? _jsx(CmsPreviewPartnerContainer, {
    guid: guid,
    partner: context.partner
  }) : _jsx(CmsPreviewLandingContainer, {
    guid: guid
  });
};
export default CmsPreviewContainer;