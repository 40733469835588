import { Link } from '@mui/material';
import UserAttributeLayout from 'presentation/layouts/userAttribute';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = UserAttributeLayout;
const UserProfileViewAttribute = props => {
  const {
    label,
    icon,
    info,
    value,
    actionText = 'Изменить',
    onAction
  } = props;
  const action = onAction && _jsx(Link, {
    component: "div",
    color: "primary",
    variant: "caption",
    onClick: onAction,
    children: actionText
  });
  return _jsx(Layout, {
    label: label,
    icon: icon,
    action: action,
    info: info,
    children: value
  });
};
export default UserProfileViewAttribute;