import ContentLoader from 'presentation/components/common/loader';
import { useState } from 'react';
import { MPFormInput } from '../input';
import { Autocomplete, LoaderWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function MPAutocompleteSingleSelect(props) {
  const {
    options,
    value,
    onChangeValue,
    onSearchValue,
    getOptionLabel,
    label,
    error,
    helperText,
    isLoading,
    disabled,
    inputProps,
    noOptionsText,
    inputValue
  } = props;
  const [internalInputValue, setInputValue] = useState(inputValue ?? null);
  return _jsx(Autocomplete, {
    clearOnBlur: true,
    disabled: disabled,
    value: value,
    inputValue: internalInputValue || '',
    options: options,
    noOptionsText: noOptionsText,
    getOptionLabel: getOptionLabel ?? (option => option.name ?? ''),
    onChange: (event, newValue, reason) => {
      if (typeof newValue !== 'string') {
        onChangeValue(newValue);
      } else {
        if (reason === 'createOption') {
          if (options.length > 0) {
            onChangeValue(options[0]);
          } else {
            setInputValue(null);
            onChangeValue(null);
          }
        }
      }
    },
    onInputChange: (event, newValue) => {
      setInputValue(newValue);
      onSearchValue(newValue);
    },
    renderInput: params => _jsx(MPFormInput, {
      ...params,
      InputProps: {
        ...params.InputProps,
        endAdornment: isLoading ? _jsx(LoaderWrapper, {
          children: _jsx(ContentLoader, {
            size: 23
          })
        }) : _jsx(_Fragment, {
          children: params.InputProps.endAdornment
        })
      },
      inputProps: {
        ...params.inputProps,
        ...inputProps,
        autoComplete: 'none',
        'aria-autocomplete': 'none'
      },
      label: label,
      error: error,
      helperText: helperText
    })
  });
}