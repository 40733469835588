import { useRef, useState } from 'react';
import { DialogContent, Typography } from '@mui/material';
import { getMapsConfig } from 'presentation/utils';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { DialogContainer, MapClicker, MapContainer, StyledIframe } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferMapAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    unavailableReason
  } = getMapsConfig();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const [isModalVisible, setModalVisible] = useState(false);
  const mapRef = useRef(null);
  const {
    name,
    position
  } = bookingOffer?.address ?? {};
  const url = `https://yandex.ru/map-widget/v1/?ll=${position?.lon}%2C${position?.lat}&text=${encodeURI(name ?? '')}&z=12&mode=whatshere`;
  if (!bookingOffer?.address || !name || !position?.lat || !position?.lon) {
    return null;
  }
  const handleShowModal = () => {
    if (unavailableReason) {
      return;
    }
    setModalVisible(true);
  };
  return _jsxs(MapContainer, {
    container: true,
    isError: !!unavailableReason,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    children: [!unavailableReason && _jsxs(_Fragment, {
      children: [_jsx(StyledIframe, {
        allowFullScreen: true,
        loading: "eager",
        src: url,
        ref: mapRef
      }), _jsx(MapClicker, {
        onClick: handleShowModal
      })]
    }), !!unavailableReason && _jsx(Typography, {
      variant: "body2",
      children: unavailableReason
    }), _jsx(DialogContainer, {
      closeButtonVisible: true,
      maxWidth: "xl",
      open: isModalVisible,
      onClose: () => setModalVisible(false),
      children: _jsx(DialogContent, {
        children: _jsx(StyledIframe, {
          isOpened: true,
          allowFullScreen: true,
          src: url
        })
      })
    })]
  });
};
export default BookingOfferMapAdapter;