import { AppConfigurator } from 'system/appConfigurator';
import { EAppFeature } from '../../../../types';
import TechConfigContext from '../contexts/techConfigContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TechConfigProvider = _ref => {
  let {
    children
  } = _ref;
  const isDebug = AppConfigurator.getInstance().isDebug();
  const isGuestModeDisabled = !AppConfigurator.getInstance().hasFeature(EAppFeature.GuestMode);
  const hasFeature = feature => {
    return AppConfigurator.getInstance().hasFeature(feature);
  };
  const state = {
    isDebug,
    hasFeature,
    isGuestModeDisabled
  };
  return _jsx(TechConfigContext.Provider, {
    value: state,
    children: children
  });
};
export default TechConfigProvider;