import { createSlice } from '@reduxjs/toolkit';
import { EOfferListType } from 'domain/model/enums';
import { tradeOffersDefaultParams } from 'presentation/features/offer/trade/utils';
const emptyParams = tradeOffersDefaultParams;
const slice = createSlice({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    common: emptyParams,
    upcoming: emptyParams
  },
  reducers: {
    tradeOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      state.guid = guid;
    },
    tradeOffersSetArgs: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        type,
        args
      } = payload;
      state[type] = args;
    },
    tradeOffersSetPageSize: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      const {
        type,
        pageSize
      } = payload;
      state[type].pageSize = pageSize;
    },
    tradeOffersSetSort: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      const {
        type,
        sort
      } = payload;
      state[type].sort = sort;
    },
    tradeOffersSetPage: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        type,
        page
      } = payload;
      state[type].page = page;
    },
    tradeOffersSetIsNewFetching: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.isNewFetching = payload;
    },
    tradeOffersSetIsNewSearchFetching: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      state.isNewSearchFetching = payload;
    }
  }
});
export const {
  tradeOffersSetPageSize,
  tradeOffersSetPage,
  tradeOffersStartSession,
  tradeOffersSetSort,
  tradeOffersSetIsNewFetching,
  tradeOffersSetIsNewSearchFetching,
  tradeOffersSetArgs
} = slice.actions;
export default slice.reducer;