import { TradeOfferIcon } from '../../../../../../media/icons';
import BannerBadge from 'presentation/components/common/badges/banner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferBadge = _ref => {
  let {
    children
  } = _ref;
  return _jsx(BannerBadge, {
    icon: TradeOfferIcon,
    children: children
  });
};
export default TradeOfferBadge;