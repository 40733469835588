import { SlotsGridArea } from '../layout/controls';
import { EAspOfferClaimSlotNameArea } from '../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferClaimPriceSlot = _ref => {
  let {
    children
  } = _ref;
  return _jsx(SlotsGridArea, {
    area: EAspOfferClaimSlotNameArea.price,
    children: children
  });
};
export default AspOfferClaimPriceSlot;