import { EOfferStatus } from '../../../../../../../domain/model/enums';
import OfferClaimHelp from '../../../../components/claim/help';
import { dialogContent } from '../../../components/dialogs/help/constants';
import useAspOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HelpAdapter = () => {
  const {
    aspOffer,
    activation
  } = useAspOfferClaimContext();
  const {
    openHelpDialog
  } = useAspOfferClaimDialogs();
  if (!aspOffer) {
    return null;
  }
  const text = dialogContent[aspOffer.promotionType].title;
  const isVisible = aspOffer.status === EOfferStatus.Active && !activation.isLastActivationPending;
  return isVisible ? _jsx(OfferClaimHelp, {
    text: text,
    onClick: openHelpDialog
  }) : null;
};
export default HelpAdapter;