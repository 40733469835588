import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserFavoritesOffersIsFetchingSelector } from '../store/selectors';
import UserFavoritesStub from '../stub';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserFavoritesStubAdapter = _ref => {
  let {
    countGuid,
    tabsCount
  } = _ref;
  const isFetching = useSelector(getUserFavoritesOffersIsFetchingSelector(countGuid));
  return useMemo(() => !isFetching && !tabsCount ? _jsx(UserFavoritesStub, {}) : null, [isFetching, tabsCount]);
};
export default UserFavoritesStubAdapter;