import { ENoticeStatus } from 'domain/model/enums';
import { addNotice } from 'presentation/features/general/notifier/store/slice';
class Notifier {
  static getInstance() {
    if (!Notifier.instance) {
      Notifier.instance = new Notifier();
    }
    return Notifier.instance;
  }
  constructor() {}
  dispatch = null;
  getDispatch() {
    return this.dispatch;
  }
  init(dispatch) {
    this.dispatch = dispatch;
  }
  addNotice(status, text) {
    const dispatch = this.getDispatch();
    if (dispatch) {
      dispatch(addNotice({
        status,
        text
      }));
    }
  }
  addError(text) {
    this.addNotice(ENoticeStatus.Error, text);
  }
  addWarning(text) {
    this.addNotice(ENoticeStatus.Warning, text);
  }
  addInfo(text) {
    this.addNotice(ENoticeStatus.Info, text);
  }
  addSuccess(text) {
    this.addNotice(ENoticeStatus.Success, text);
  }
}
export default Notifier;