import CorpOfferClaimProvider from './provider/provider';
import CorpOfferClaimDialogsProvider from './dialogProvider/provider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferClaimProviders = props => {
  const {
    children,
    corpOffer,
    onShowAllOffers
  } = props;
  return _jsx(CorpOfferClaimProvider, {
    id: corpOffer.id,
    promotionType: corpOffer.promotionType,
    children: _jsx(CorpOfferClaimDialogsProvider, {
      corpOffer: corpOffer,
      onShowAllOffers: onShowAllOffers,
      children: children
    })
  });
};
export default CorpOfferClaimProviders;