import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { StyledDottedButton } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimCertificateActivation = props => {
  const {
    disabled,
    isFetching,
    onBuy
  } = props;
  return _jsxs(StyledDottedButton, {
    disabled: disabled,
    fetching: isFetching,
    fullWidth: true,
    onClick: onBuy,
    children: [_jsx(Typography, {
      variant: "h2",
      color: isFetching ? 'transparent' : undefined,
      children: "\u041E\u0431\u043C\u0435\u043D\u044F\u0442\u044C"
    }), isFetching && _jsx(ContentLoader, {})]
  });
};