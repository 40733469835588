import ContentLoader from 'presentation/components/common/loader';
import { StyledButton } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferAddToCartButton = props => {
  const {
    isEmpty,
    label,
    isFetching,
    ...others
  } = props;
  const LabelComponent = label;
  return _jsxs(StyledButton, {
    active: !isEmpty,
    color: isEmpty ? 'primary' : 'success',
    fullWidth: false,
    ...others,
    children: [_jsx(LabelComponent, {
      isEmpty: isEmpty
    }), isFetching && _jsx(ContentLoader, {
      size: "1.5rem"
    })]
  });
};
export default ProductOfferAddToCartButton;