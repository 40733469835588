import { ThumbSwiperWrapper } from './controls';
import { Swiper } from 'swiper/react';
import { FreeMode, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CarouselThumbs = props => {
  const {
    onSwiper,
    swiperProps,
    children,
    thumbScrollMode
  } = props;
  const thumbSwiperScrollModeProps = thumbScrollMode ? {
    direction: 'horizontal',
    mousewheel: true,
    scrollbar: true
  } : {};
  return _jsx(ThumbSwiperWrapper, {
    withScroll: thumbScrollMode,
    children: _jsx(Swiper, {
      ...swiperProps,
      ...thumbSwiperScrollModeProps,
      onSwiper: onSwiper,
      freeMode: true,
      watchSlidesProgress: true,
      modules: [...(swiperProps?.modules ?? [FreeMode, Thumbs, Navigation, Pagination, Mousewheel, Scrollbar])],
      children: children
    })
  });
};
export default CarouselThumbs;