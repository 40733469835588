import { Wrapper } from './controls';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferHeaderTitle = props => {
  const {
    icon,
    title
  } = props;
  return _jsxs(Wrapper, {
    children: [icon, title]
  });
};
export default OfferHeaderTitle;