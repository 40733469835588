import { StyledAppImage, StyledLink } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OrderImage = _ref => {
  let {
    fileLink,
    to
  } = _ref;
  return _jsx(StyledLink, {
    to: to,
    children: _jsx(StyledAppImage, {
      src: fileLink.path
    })
  });
};
export default OrderImage;