const defaultOptions = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER
};
export const filterValue = function (inputString) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultOptions;
  let newValue;
  const {
    min,
    max
  } = options;
  typeof inputString === 'string' ? newValue = +inputString.replace(/\D/g, '') : newValue = inputString;
  if (isNaN(newValue)) {
    return 0;
  }
  return Math.min(Math.max(newValue, min), max);
};