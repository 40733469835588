/*type ProductOfferDetailsFavoriteOnlyAdapterProps = {
  readonly id: UUID;
};*/

const ProductOfferDetailsFavoriteOnlyAdapter = () => {
  /*const { productOffer } = useProductOfferDetailsData(id);
   const {
    isFetching: isFavoriteFetching,
    isFavorite,
    onToggle,
  } = useOfferFavorite({
    guid: id,
    payload: {
      id,
      offerType: EOfferType.Product,
      partnerId: productOffer?.partner?.id,
    },
  });
   return (
    <OfferDetailsFavoriteOnly
      favoritesAllowed
      isFavorite={isFavorite}
      isAddToFavoriteFetching={isFavoriteFetching}
      onAddToFavorite={onToggle}
    />
  );*/
  return null;
};
export default ProductOfferDetailsFavoriteOnlyAdapter;