import { DialogTitle } from '@mui/material';
import { DialogTitleWrapper, DialogWrapper, FilterWrapper, StyledDialogContent } from 'presentation/features/offer/product/filter/dialog/controls';
import { MPDialog } from '../../../../../theme/ui-kit/dialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductFilterDialog = _ref => {
  let {
    open,
    onClose
  } = _ref;
  return _jsxs(MPDialog, {
    PaperComponent: DialogWrapper,
    open: open,
    onClose: onClose,
    children: [_jsx(DialogTitleWrapper, {
      children: _jsx(DialogTitle, {
        children: "\u0424\u0438\u043B\u044C\u0442\u0440\u044B"
      })
    }), _jsx(StyledDialogContent, {
      children: _jsx(FilterWrapper, {
        children: "Filter"
      })
    })]
  });
};
export default ProductFilterDialog;