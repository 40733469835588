import { useCallback, useEffect, useRef, useState } from 'react';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import useScrollToElement from '../../../hooks/useScrollToElement';
const useScrollToSections = _ref => {
  let {
    topOffset
  } = _ref;
  const [expandedItem, setExpandedItem] = useState(null);
  const {
    headerHeight
  } = useHeaderParams();
  const [current, setCurrent] = useState(null);
  const sectionsRefs = useRef([]);
  const setCurrentSection = useCallback(section => {
    setCurrent(section);
  }, []);
  const {
    scrollTo
  } = useScrollToElement({
    adjusmentTop: headerHeight + (topOffset ?? 0)
  });
  const setSectionRef = (element, id) => {
    if (sectionsRefs.current.find(s => s.element === element)) {
      return;
    }
    sectionsRefs.current = [...sectionsRefs.current, {
      element,
      id
    }];
  };
  const onSectionToggle = item => {
    if (item.id === expandedItem?.id) setExpandedItem(null);else setExpandedItem(item);
  };
  useEffect(() => {
    if (!expandedItem) {
      return;
    }
    const currentEl = sectionsRefs.current.find(s => expandedItem.id === s.id);
    setCurrentSection(expandedItem);
    scrollTo(currentEl?.element);
  }, [expandedItem, scrollTo, setCurrentSection]);
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);
  return {
    current,
    sectionsRefs,
    expandedItem,
    scrollTo,
    onSectionToggle,
    setSectionRef,
    setCurrentSection
  };
};
export default useScrollToSections;