import { SlotsGridArea } from '../layout/controls';
import { ETradeOfferClaimSlotNameArea } from '../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimActivationSlot = _ref => {
  let {
    children
  } = _ref;
  return _jsx(SlotsGridArea, {
    area: ETradeOfferClaimSlotNameArea.activation,
    children: children
  });
};
export default TradeOfferClaimActivationSlot;