import StubCommon from 'presentation/components/common/stub/common';
import { activationsStub } from 'presentation/media/images';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ActivationsStub = _ref => {
  let {
    text = 'Здесь будут храниться ваши сертификаты, ваучеры, промокоды и др.'
  } = _ref;
  return _jsx(StubCommon, {
    title: text,
    image: activationsStub
  });
};
export default ActivationsStub;