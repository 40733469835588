import { useState } from 'react';
import { useCopyToClipboard } from './useCopyToClipboard';
const useCopied = () => {
  const [, copy] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  const [needCopy, setNeedCopy] = useState(false);
  const onNeedCopy = value => setNeedCopy(value);
  const onCopy = () => {
    setCopied(true);
    setNeedCopy(false);
  };
  return {
    copied,
    needCopy,
    onNeedCopy,
    onCopy,
    copy
  };
};
export default useCopied;