import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { partnerDeskDetailsCorpOffersCountSelector, partnerDeskDetailsCorpOffersIsErrorSelector, partnerDeskDetailsCorpOffersIsFetchingSelector } from '../../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskDetailsTabCorpOffersNoData = () => {
  const count = useSelector(partnerDeskDetailsCorpOffersCountSelector);
  const isFetching = useSelector(partnerDeskDetailsCorpOffersIsFetchingSelector);
  const isError = useSelector(partnerDeskDetailsCorpOffersIsErrorSelector);
  return useMemo(() => !count && !isFetching && !isError ? _jsx("div", {
    children: "\u041A\u043E\u0440\u043F\u043E\u0440\u0430\u0442\u0438\u0432\u043D\u044B\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u043F\u043E\u043A\u0430 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442"
  }) : null, [count, isFetching, isError]);
};
export default PartnerDeskDetailsTabCorpOffersNoData;