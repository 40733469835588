import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsAspOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskAspOffersCategoriesAdapter from './adapters/categories';
import PartnerDeskAspOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskAspOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabAspOffersNoData from './noData';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskOffersTabLayout;
export const PartnerDeskDetailsTabAspOffers = props => {
  const {
    guid
  } = props;
  return _jsxs(Layout, {
    toolbar: _jsx(PartnerDeskAspOffersCategoriesAdapter, {}),
    stub: _jsx(PartnerDeskDetailsTabAspOffersNoData, {}),
    loader: _jsx(GeneralLoaderFixedAdapter, {
      selector: partnerDeskDetailsAspOffersIsFetchingSelector
    }),
    children: [_jsx(PartnerDeskAspOffersCommonAdapter, {
      guid: guid
    }), _jsx(PartnerDeskAspOffersUpcomingAdapter, {
      guid: guid
    })]
  });
};