import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskCorpOffersCategoriesAdapter from 'presentation/features/partnerDesk/details/tabs/corpOffers/adapters/categories';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsCorpOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskCorpOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskCorpOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabCorpOffersNoData from './noData';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskOffersTabLayout;
export const PartnerDeskDetailsTabCorpOffers = props => {
  const {
    guid
  } = props;
  return _jsxs(Layout, {
    toolbar: _jsx(PartnerDeskCorpOffersCategoriesAdapter, {}),
    stub: _jsx(PartnerDeskDetailsTabCorpOffersNoData, {}),
    loader: _jsx(GeneralLoaderFixedAdapter, {
      selector: partnerDeskDetailsCorpOffersIsFetchingSelector
    }),
    children: [_jsx(PartnerDeskCorpOffersCommonAdapter, {
      guid: guid
    }), _jsx(PartnerDeskCorpOffersUpcomingAdapter, {
      guid: guid
    })]
  });
};