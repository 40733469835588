import { isArray } from 'lodash';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';
import { useLimitedDays } from '../hooks';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPRangeCalendarPicker = _ref => {
  let {
    limitPastSelection,
    limitFutureSelection,
    ...props
  } = _ref;
  const onChangeInternal = selectedDates => {
    if (selectedDates === null) {
      return props.onChange?.(null);
    }
    const result = !isArray(selectedDates) ? {
      startDate: selectedDates.toDate(),
      endDate: null
    } : {
      startDate: selectedDates?.[0]?.toDate() ?? null,
      endDate: selectedDates?.[1]?.toDate() ?? null
    };
    return props.onChange?.(result);
  };
  const mapDays = useLimitedDays({
    limitPastSelection,
    limitFutureSelection
  });
  return _jsx(StyledCalendar, {
    ...regularOptions,
    ...props,
    onChange: onChangeInternal,
    multiple: false,
    mapDays: mapDays,
    range: true,
    ...permanentOptions
  });
};