import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { getOfferCodesEndsText } from '../../../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimCodesEndsMessage = props => {
  const {
    promotionType
  } = props;
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: getOfferCodesEndsText(promotionType)
    })
  });
};