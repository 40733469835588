import HorizontalScroller, { HorizontalScrollerItem } from 'presentation/components/common/horizontalScroll';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { useCallback, useMemo } from 'react';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskOffersCategories = props => {
  const {
    categories,
    categoryId,
    onChange
  } = props;
  const isSelected = useCallback(c => {
    return (c?.id ?? null) === categoryId || categories.length === 1;
  }, [categoryId, categories]);
  const onChangeInternal = useCallback(c => {
    if ((c?.id ?? null) !== categoryId) {
      onChange(c);
    }
  }, [categoryId, onChange]);
  const selectedIndex = categoryId ? categories?.findIndex(c => c.id === categoryId) : 0;
  return useMemo(() => categories.length >= 2 ? _jsxs(HorizontalScroller, {
    gap: 8,
    initialSelected: selectedIndex,
    children: [categories.length >= 2 && _jsx(HorizontalScrollerItem, {
      children: _jsx(MPChip, {
        label: "\u0412\u0441\u0435",
        color: "black",
        variant: isSelected(null) ? 'filled' : 'outlined',
        onClick: () => onChangeInternal(null)
      }, 'all')
    }, 'all'), categories?.map(c => _jsx(HorizontalScrollerItem, {
      children: _jsx(MPChip, {
        label: c.name,
        color: "black",
        variant: isSelected(c) ? 'filled' : 'outlined',
        onClick: () => onChangeInternal(c)
      }, c.id)
    }, c.id))]
  }) : null, [categories, isSelected, selectedIndex, onChangeInternal]);
};
export default PartnerDeskOffersCategories;