import { Fade } from '@mui/material';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PreviewSnackbar = _ref => {
  let {
    onClick
  } = _ref;
  return _jsx(Fade, {
    in: true,
    children: _jsx(Wrapper, {
      onClick: onClick,
      children: "\u0412\u044B\u0439\u0442\u0438 \u0438\u0437 \u041F\u0440\u0435\u0432\u044C\u044E-\u0440\u0435\u0436\u0438\u043C\u0430"
    })
  });
};
export default PreviewSnackbar;