import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { useAuth } from 'features/auth/provider/useAuth';
import { createEvent } from 'features/user/events/needLogin';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { ERenderSource } from 'presentation/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addSearchParamToLocation } from 'routing';
import { getTradeOffersListRoute } from '../../routes';
import { tradeOfferDetailsStartSession } from '../store/slice';
const useTradeOfferDetails = _ref => {
  let {
    id,
    guid,
    from
  } = _ref;
  const {
    webAnalytics
  } = useWebAnalytics();
  const {
    isAuthenticated
  } = useAuth();
  const {
    publish
  } = useSystemEventBus();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    gotoPrevIndependentLocation
  } = useHistoryExtensions();
  const onBack = () => {
    gotoPrevIndependentLocation(getTradeOffersListRoute());
  };
  const onChangeTab = newTab => {
    history.replace(addSearchParamToLocation({
      location: history.location,
      param: ETradeOfferUrlParam.Tab,
      value: newTab
    }));
  };
  useEffect(() => {
    if (!isAuthenticated) {
      publish(createEvent({}));
    }
  }, [isAuthenticated, publish]);
  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);
  useEffect(() => {
    dispatch(tradeOfferDetailsStartSession({
      guid
    }));
  }, [dispatch, guid]);
  return {
    onBack,
    onChangeTab
  };
};
export default useTradeOfferDetails;