import { getCartRootRoute } from 'presentation/features/cart/entry';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { useHistory } from 'react-router';
import { UserMenuItemCart } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuCartAdapter = () => {
  const history = useHistory();
  const {
    hasFeature
  } = useTechConfig();
  const isCartAllowed = hasFeature(EAppFeature.Product);
  const onClick = () => {
    history.push(getCartRootRoute());
  };
  return isCartAllowed ? _jsx(UserMenuItemCart, {
    onClick: onClick
  }) : null;
};