import ContentLoader from 'presentation/components/common/loader';
import OfferClaimLayout from '../../../../layouts/offerClaimLayout';
import { CorpOfferClaimActivationAdapter } from './adapters/activation';
import ActivityEndDateAdapter from './adapters/activityEndDate';
import { CorpOfferClaimLeftCodesAdapter } from './adapters/leftCodes';
import { CorpOfferClaimInfoMessagesAdapter } from './adapters/messages/infoMessages';
import CorpOfferPromotionTypeAdapter from './adapters/messages/obtainingMethod';
import { CorpOfferClaimWarnMessagesAdapter } from './adapters/messages/warnMessages';
import NdflAdapter from './adapters/ndfl';
import OfferCodeAdapter from './adapters/offerCode';
import { CorpOfferClaimPriceAdapter } from './adapters/price';
import { CorpOfferClaimPromotionAdapter } from './adapters/promotion';
import { CorpOfferClaimPromotionNewAdapter } from './adapters/promotionNew';
import { CorpOfferClaimActivationSlot, CorpOfferClaimInfoMessagesSlot, CorpOfferClaimNewPromotionSlot, CorpOfferClaimPriceSlot, CorpOfferClaimPromotionSlot, CorpOfferClaimPromotionTypeSlot, CorpOfferClaimWarnMessagesSlot } from './slots';
// import { CorpOfferClaimSubscriptionAdapter } from './adapters/subscription';
import useCorpOfferClaimContext from './provider/useCorpOfferClaimContext';
import ClaimContentLayout from './layout/content';
import ClaimInfoLayout from './layout/info';
import { useRef } from 'react';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CorpOfferClaim = () => {
  const {
    isFetching
  } = useCorpOfferClaimContext();
  const ref = useRef();
  return isFetching ? _jsx(ContentLoader, {}) : _jsx(OfferClaimLayout, {
    ref: ref,
    content: _jsxs(ClaimContentLayout, {
      children: [_jsx(CorpOfferClaimPromotionTypeSlot, {
        children: _jsx(CorpOfferPromotionTypeAdapter, {})
      }), _jsx(CorpOfferClaimPriceSlot, {
        children: _jsx(CorpOfferClaimPriceAdapter, {})
      }), _jsx(CorpOfferClaimActivationSlot, {
        children: _jsx(CorpOfferClaimActivationAdapter, {})
      }), _jsx(CorpOfferClaimPromotionSlot, {
        children: _jsx(CorpOfferClaimPromotionAdapter, {})
      }), _jsx(CorpOfferClaimNewPromotionSlot, {
        children: _jsx(CorpOfferClaimPromotionNewAdapter, {})
      }), _jsx(CorpOfferClaimWarnMessagesSlot, {
        children: _jsx(CorpOfferClaimWarnMessagesAdapter, {})
      }), _jsx(CorpOfferClaimInfoMessagesSlot, {
        children: _jsx(CorpOfferClaimInfoMessagesAdapter, {})
      })]
    })
    // <SlotsGridArea
    //   area={ESlotNameArea.subscriptions}
    //   key={ESlotNameArea.subscriptions}
    // >
    //   <CorpOfferSidebarSubscriptionAdapter />
    // </SlotsGridArea>,
    ,
    skirt: _jsxs(ClaimInfoLayout, {
      help: _jsx(NdflAdapter, {}, 'ndfl'),
      children: [_jsx(OfferCodeAdapter, {}, 'code'), _jsx(ActivityEndDateAdapter, {}, 'endDate'), _jsx(CorpOfferClaimLeftCodesAdapter, {}, 'leftCodes')]
    })
  });
};
export default CorpOfferClaim;