import { CardPageableList } from 'presentation/components/common/cardPageableList';
import ActivationListItemAdapter from './adapters/listItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserActivations = props => {
  const {
    activations,
    totalCount,
    isFetching,
    onLoadNextPage,
    onOfferClick,
    onPartnerLinkClick,
    onOpenHelpDialog,
    getActivationCodeTypeName
  } = props;
  return _jsx(CardPageableList, {
    totalCount: totalCount,
    isFetching: isFetching,
    span: 6,
    lgSpan: 12,
    mdSpan: 12,
    smSpan: 12,
    onLoadMore: onLoadNextPage,
    children: activations.map(activation => _jsx(ActivationListItemAdapter, {
      activation: activation,
      getActivationCodeTypeName: getActivationCodeTypeName,
      onOfferClick: () => onOfferClick(activation.offer),
      onPartnerLinkClick: () => onPartnerLinkClick(activation),
      onOpenHelpDialog: onOpenHelpDialog
    }, activation.id))
  });
};
export default UserActivations;