import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { toRubCurrency } from '../../../../utils/currency';
import CartListItemShort from '../cartItem/short';
import { InfoBlock, ItemWrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const CartOrderListItem = _ref => {
  let {
    order,
    onOpenProductDetails
  } = _ref;
  const ordersAmount = useMemo(() => order.items?.reduce((prev, current) => (current.qty ?? 0) + prev, 0), [order]);
  return _jsxs(_Fragment, {
    children: [_jsxs(InfoBlock, {
      children: [_jsxs(Typography, {
        variant: "h4",
        children: ["\u0417\u0430\u043A\u0430\u0437 \u2116", order.number]
      }), order.partner && _jsxs(Typography, {
        variant: "body1",
        children: ["\u041F\u0430\u0440\u0442\u043D\u0435\u0440: ", order.partner.name]
      }), _jsxs(Typography, {
        variant: "body1",
        children: ["\u0422\u043E\u0432\u0430\u0440\u043E\u0432: ", ordersAmount]
      }), _jsxs(Typography, {
        variant: "subtitle2",
        children: ["\u0418\u0442\u043E\u0433\u043E: ", toRubCurrency(order.cost)]
      })]
    }), order.items?.map(item => _jsx(ItemWrapper, {
      children: _jsx(CartListItemShort, {
        data: item,
        onOpenProductDetails: () => onOpenProductDetails(item)
      })
    }, item.offer?.id))]
  });
};