import { Char } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AlphabeticalSearchItem = props => {
  const {
    selected,
    disabled,
    children,
    onClick
  } = props;
  return _jsx(Char, {
    fullWidth: false,
    disabled: disabled,
    variant: selected ? 'contained' : 'text',
    color: selected ? 'black' : 'white',
    size: "large",
    onClick: onClick,
    children: children
  });
};
export default AlphabeticalSearchItem;