import { useSelector } from 'react-redux';
import { productOfferListCommonDataCountSelector, productOfferListCommonIsErrorSelector, productOfferListCommonIsFetchingSelector, productOfferListHasSomeFilters, productOfferListSearchIsEmptySelector } from '../store/selectors';
import ProductOffersStub from '../stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(productOfferListSearchIsEmptySelector);
  const count = useSelector(productOfferListCommonDataCountSelector);
  const isSomeFilters = useSelector(productOfferListHasSomeFilters);
  const isFetching = useSelector(productOfferListCommonIsFetchingSelector);
  const isError = useSelector(productOfferListCommonIsErrorSelector);
  if (count === 0 && !isFetching && !isSomeFilters && !isError) {
    return _jsx(ProductOffersStub, {
      isEmptySearch: isEmptySearch
    });
  }
  return null;
};
export default ProductOfferListStubAdapter;