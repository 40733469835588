import { InvalidStepAdapter } from './';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const InvalidFlowAdapter = _ref => {
  let {
    phone,
    isFetching,
    onSubmit
  } = _ref;
  return _jsx(InvalidStepAdapter, {
    phone: phone,
    isFetching: isFetching,
    onSubmit: onSubmit
  });
};