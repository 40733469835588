import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CartProductsNotFoundDialog = _ref => {
  let {
    open,
    onClose
  } = _ref;
  return _jsx(MPConfirmDialog, {
    open: open,
    title: "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u043D\u043E \u043D\u0435\u043A\u043E\u0442\u043E\u0440\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u043D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438",
    maxWidth: "xs",
    buttons: _jsx(MPButton, {
      size: "large",
      fullWidth: true,
      color: "brand",
      variant: "contained",
      onClick: onClose,
      children: "\u041F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u043A\u043E\u0440\u0437\u0438\u043D\u0443"
    }),
    onClose: onClose,
    children: _jsx(Typography, {
      children: "\u0421\u043E\u0441\u0442\u0430\u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u044B \u0438\u0437\u043C\u0435\u043D\u0438\u043B\u0441\u044F, \u0442\u0430\u043A \u043A\u0430\u043A \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u0442\u043E\u0432\u0430\u0440\u0430 \u0431\u043E\u043B\u044C\u0448\u0435 \u043D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438 \u0438\u043B\u0438 \u0435\u0433\u043E \u043D\u0435\u0434\u043E\u0441\u0442\u0430\u0442\u043E\u0447\u043D\u043E\u0433\u043E \u0434\u043B\u044F \u0432\u0430\u0448\u0435\u0433\u043E \u0437\u0430\u043A\u0430\u0437\u0430"
    })
  });
};
export default CartProductsNotFoundDialog;