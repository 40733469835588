import { useGetTradeOfferDetailsQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useContext, useEffect } from 'react';
const useTradeOfferDetailsData = id => {
  const {
    setError
  } = useContext(RenderManagerErrorContext);
  const {
    data: tradeOffer,
    error: tradeOfferFetchError,
    isError: isTradeOfferError,
    isLoading: isTradeOfferLoading,
    isFetching: isTradeOfferFetching,
    isSuccess: isTradeOfferFetched
  } = useGetTradeOfferDetailsQuery({
    id
  }, {
    refetchOnMountOrArgChange: true
  });
  useEffect(() => {
    if (tradeOfferFetchError) {
      ErrorHandler.handleHttpError(tradeOfferFetchError);
      setError(tradeOfferFetchError);
    }
  }, [setError, tradeOfferFetchError]);
  return {
    tradeOffer,
    tradeOfferFetchError,
    isTradeOfferError,
    isTradeOfferFetching,
    isTradeOfferLoading,
    isTradeOfferFetched
  };
};
export default useTradeOfferDetailsData;