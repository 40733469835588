import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskListLayout = props => {
  const {
    filter,
    showMore,
    children,
    stub,
    loader
  } = props;
  useVisibleSectionLinks();
  return _jsx(Fade, {
    in: true,
    children: _jsxs(DefaultContentWrapper, {
      children: [_jsx(Typography, {
        variant: "h1",
        children: "\u041F\u0430\u0440\u0442\u043D\u0451\u0440\u044B"
      }), _jsx(Splitter, {
        size: 3
      }), filter, _jsx(Splitter, {
        size: 3
      }), children, _jsx(Splitter, {
        size: 3
      }), showMore, stub, loader]
    })
  });
};
export default PartnerDeskListLayout;