import { Typography } from '@mui/material';
import { EPaymentType } from 'domain/model/enums';
import ClaimMessage from '../message';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OfferClaimBalanceNotEnough = _ref => {
  let {
    paymentType
  } = _ref;
  let currencyLabel = 'баланса';
  switch (paymentType) {
    case EPaymentType.AspRzdRub:
      currencyLabel = 'рублей АСП';
      break;
    case EPaymentType.BenefitRzdPoint:
      currencyLabel = 'баллов';
      break;
    case EPaymentType.Rub:
    case EPaymentType.Free:
      break;
  }
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsxs(Typography, {
      variant: "body2",
      color: "black",
      children: ["\u0423 \u0432\u0430\u0441 \u043D\u0435\u0434\u043E\u0441\u0442\u0430\u0442\u043E\u0447\u043D\u043E ", currencyLabel]
    })
  });
};