import ProductFilterActionsAdapter from './adapter/actions';
import ProductFilterBehaviorAdapter from './adapter/behavior';
import ProductFilterPriceAdapter from './adapter/filterPrice';
import { ProductOfferFilterContext } from './context';
import { useProductOfferFilter } from './hooks/useProductOfferFilter';
import ProductFilterLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = ProductFilterLayout;
const ProductFilterContainer = props => {
  const {
    onChangeFilter
  } = props;
  const handlers = useProductOfferFilter();
  return _jsxs(ProductOfferFilterContext.Provider, {
    value: handlers,
    children: [_jsx(ProductFilterBehaviorAdapter, {
      onChangeFilter: onChangeFilter
    }), _jsx(Layout, {
      actions: _jsx(ProductFilterActionsAdapter, {}),
      children: _jsx(ProductFilterPriceAdapter, {})
    })]
  });
};
export default ProductFilterContainer;