import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import useProductOfferPartnerDeskData from 'presentation/features/offer/product/details/hooks/useProductOfferPartnerDeskData';
import ProductOfferTabs from 'presentation/features/offer/product/details/tabs';
import { getProductOfferDetailsRoute } from 'presentation/features/offer/product/entry';
import React from 'react';
import { useHistory } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductDetailsTabsAdapter = props => {
  const {
    tab,
    characteristicTabRef,
    id
  } = props;
  const history = useHistory();
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  const {
    partnerDesk
  } = useProductOfferPartnerDeskData(id);
  const changeTab = newTab => {
    history.replace(getProductOfferDetailsRoute({
      id,
      tab: newTab
    }));
  };
  const onChangeTab = (event, newTab) => {
    changeTab(newTab);
  };
  if (!productOffer || !partnerDesk) {
    return null;
  }
  return _jsx(ProductOfferTabs, {
    productOffer: productOffer,
    tab: tab,
    characteristicTabRef: characteristicTabRef,
    onChange: onChangeTab,
    partnerDesk: partnerDesk
  });
};
export default ProductDetailsTabsAdapter;