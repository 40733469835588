import ContentLoader from 'presentation/components/common/loader';
import { userProfileIsFetchingSelector } from 'presentation/features/user/details/profile/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserProfileLoaderAdapter = () => {
  const isFetching = useSelector(userProfileIsFetchingSelector);
  return useMemo(() => isFetching && _jsx(ContentLoader, {
    position: "fixed"
  }), [isFetching]);
};