import ContentLoader from 'presentation/components/common/loader';
import OfferListLayout from 'presentation/layouts/offerList';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import BookingOfferOrderDetailsAdapter from '../../../user/details/order/booking/adapters/details';
import useBookingOfferOrderData from './hooks/useBookingOfferOrderData';
import BookingOfferOrderHeader from '../components/order/header';
import BookingOfferOrderButtons from '../components/order/buttons';
import { OrderDetailsWrapper, OrderLayoutWrapper } from '../components/order/controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferOrderContainer = _ref => {
  let {
    id
  } = _ref;
  const {
    order,
    isFetching
  } = useBookingOfferOrderData(id);
  return _jsx(MPGrid, {
    container: true,
    justifyContent: "center",
    children: _jsx(OrderLayoutWrapper, {
      children: _jsx(OfferListLayout, {
        breadcrumb: null,
        header: BookingOfferOrderHeader,
        aside: null,
        stub: null,
        loader: isFetching ? _jsx(ContentLoader, {
          position: "fixed"
        }) : null,
        children: !!order && _jsxs(_Fragment, {
          children: [_jsx(OrderDetailsWrapper, {
            children: _jsx(BookingOfferOrderDetailsAdapter, {
              order: order
            })
          }), _jsx(BookingOfferOrderButtons, {})]
        })
      })
    })
  });
};
export default BookingOfferOrderContainer;