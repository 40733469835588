import { useEffect } from 'react';
import { useGetUserFavoritesQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
export const useUserFavoritesTabsData = () => {
  const {
    data,
    isFetching,
    error
  } = useGetUserFavoritesQuery();
  const tradeOffersCount = data?.tradeOffersCount ?? 0;
  const corpOffersCount = data?.corpOffersCount ?? 0;
  const productOffersCount = data?.productOffersCount ?? 0;
  const bookingOffersCount = data?.bookingOffersCount ?? 0;
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    productOffersCount,
    bookingOffersCount
  };
};