import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const GeneralLoaderFixedAdapter = _ref => {
  let {
    selector
  } = _ref;
  const isFetching = useSelector(selector);
  return useMemo(() => isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null, [isFetching]);
};
export default GeneralLoaderFixedAdapter;