import axios from 'axios';
import Notifier from 'system/notifier';
import { EHeaders, ENetworkErrorCode } from './types';
export default class HttpClient {
  static getInstance() {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }
    return HttpClient.instance;
  }
  authService = null;
  constructor() {}
  dispatch = null;
  init(dispatch) {
    if (dispatch) {
      this.dispatch = dispatch;
    }
    this.initAxios();
  }
  initAxios() {
    axios.defaults.headers.common[EHeaders.AppId] = 'coms';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.paramsSerializer = {
      ...axios.defaults.paramsSerializer,
      indexes: null
    };
    this.initTokenInterceptor();
  }
  setAuthService(authService, onResetPassword) {
    this.authService = authService;
    this.authService.onResetPassword = onResetPassword;
  }
  getAuthService() {
    return this.authService;
  }
  initTokenInterceptor() {
    axios.interceptors.request.use(config => {
      const headers = config.headers ?? {};
      if (this.authService?.token) {
        headers.Authorization = `Bearer ${this.authService.token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });
    axios.interceptors.response.use(undefined, error => {
      const logOut = () => {
        setTimeout(() => {
          if (this.authService?.authenticated) {
            this.authService.logout({
              redirectUri: window.location.origin
            });
          }
        }, 3000);
      };
      const status = error?.response?.status;
      const code = error?.response?.data?.code;
      switch (status) {
        case 401:
          {
            if (this.authService?.authenticated) {
              this.authService?.updateToken(99999999999999).then(success => {
                if (success) {
                  console.debug('Token was updated');
                } else {
                  Notifier.getInstance().addError('Необходима авторизация');
                  logOut();
                }
              }).catch(updateTokenError => {
                Notifier.getInstance().addError('Необходима авторизация');
                console.warn(`Не удалось обновить токен безопасности: ${updateTokenError?.message}`);
                logOut();
              });
            }
            break;
          }
        case 403:
          {
            if (code === ENetworkErrorCode.UserBlocked) {
              Notifier.getInstance().addError('Текущий пользователь был заблокирован');
              logOut();
            }
            break;
          }
      }
      return Promise.reject(error);
    });
  }
  applyInterceptor(fullfilledCallback, rejectedCallback) {
    return axios.interceptors.request.use(fullfilledCallback, rejectedCallback);
  }
  ejectInterceptor(interceptorId) {
    return axios.interceptors.request.eject(interceptorId);
  }
}