import { FavoriteOutlineIcon } from '../../../../../media/icons';
import { getUserFavoritesRoute } from '../../../../user/entry';
import HeaderOption from '../../option';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const FavoritesOption = props => {
  return _jsx(HeaderOption, {
    icon: FavoriteOutlineIcon,
    to: getUserFavoritesRoute({}),
    ...props
  });
};
export default FavoritesOption;