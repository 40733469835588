import AspOfferClaimDialogsProvider from './dialogProvider/provider';
import AspOfferClaimProvider from './provider/provider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferClaimProviders = props => {
  const {
    guid,
    children,
    aspOffer,
    isActivationAllowed,
    onShowAllOffers,
    onPartnerButtonClick
  } = props;
  return _jsx(AspOfferClaimProvider, {
    id: aspOffer.id,
    guid: guid,
    isActivationAllowed: isActivationAllowed,
    promotionType: aspOffer.promotionType,
    onPartnerButtonClick: onPartnerButtonClick,
    children: _jsx(AspOfferClaimDialogsProvider, {
      aspOffer: aspOffer,
      onShowAllOffers: onShowAllOffers,
      children: children
    })
  });
};
export default AspOfferClaimProviders;