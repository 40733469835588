import { MPGrid } from 'presentation/theme/ui-kit/grid';
import SidebarAttribute from '../attribute';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SidebarAttributes = _ref => {
  let {
    attributes
  } = _ref;
  return _jsx(MPGrid, {
    container: true,
    spacing: 1,
    flexDirection: "column",
    children: attributes.map(a => _jsx(MPGrid, {
      item: true,
      children: _jsx(SidebarAttribute, {
        name: a.label,
        value: a.value
      })
    }, a.value))
  });
};
export default SidebarAttributes;