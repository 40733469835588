import { useState } from 'react';
import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { pluralize } from 'presentation/utils';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import SmartPhoneFixedBar from '../../../components/smartPhoneFixedBar';
import { TypographyEllipsis } from '../typographyEllipsis';
import { ButtonNoWrap, SmartPhoneFixedBarBottomWrapper, SmartPhoneFixedBarWrapper, SmartphoneSlotsGrid } from './controls';
import FooterRegister from 'presentation/features/footerRegister/footerRegister';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BlankButton = _jsx(MPButton, {
  fullWidth: true,
  disabled: true,
  children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043C\u0438\u043D\u0438\u043C\u0443\u043C \u043E\u0434\u043D\u0443 \u0443\u0441\u043B\u0443\u0433\u0443"
}, 'disabled');
const pluralizes = ['услуга', 'услуги', 'услуг'];
const BookingSidebarSmartPhone = _ref => {
  let {
    slots,
    cart,
    totalPrice
  } = _ref;
  const [showModalCart, setShowModalCart] = useState(false);
  return _jsx(_Fragment, {
    children: showModalCart ? _jsxs(SmartPhoneFixedBarWrapper, {
      children: [_jsx(SmartPhoneFixedBar, {
        text: "\u041E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u0438\u0435 \u0437\u0430\u043A\u0430\u0437\u0430",
        onBack: () => setShowModalCart(false)
      }), _jsxs(SmartphoneSlotsGrid, {
        children: [slots, _jsx(Splitter, {
          size: 3
        })]
      })]
    }) : _jsx(FooterRegister, {
      name: "bookingSidebarSmartPhoneFooter",
      children: footerRef => {
        return _jsx(SmartPhoneFixedBarBottomWrapper, {
          ref: footerRef,
          children: cart && cart.length > 0 ? _jsxs(MPGrid, {
            container: true,
            spacing: 1,
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "nowrap",
            children: [_jsxs(MPGrid, {
              item: true,
              children: [_jsxs(Typography, {
                variant: "caption",
                children: [cart.length, " ", pluralize(cart.length, pluralizes)]
              }), _jsx(TypographyEllipsis, {
                variant: "caption",
                children: "\u041F\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043B\u044C\u043D\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C"
              }), _jsx(Splitter, {
                size: 0.5
              }), _jsx(Typography, {
                variant: "h3",
                children: totalPrice
              })]
            }), _jsx(MPGrid, {
              item: true,
              children: _jsx(ButtonNoWrap, {
                color: "brand",
                size: "large",
                onClick: () => setShowModalCart(true),
                children: "\u041A \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u0438\u044E"
              })
            })]
          }) : BlankButton
        });
      }
    })
  });
};
export default BookingSidebarSmartPhone;