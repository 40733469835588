import { EOfferActivateError, EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { TradeOfferClaimCodesEndsMessage } from './codesEnds';
import { TradeOfferClaimErrorMessage } from './error';
import { TradeOfferClaimReactivationMessage } from './reactivation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimWarnMessagesAdapter = () => {
  const {
    tradeOffer,
    isActivationReceivedNow,
    activation,
    isTimerActive,
    stopTimer
  } = useTradeOfferClaimContext();
  const isCodesEnded = (tradeOffer?.notUsedOfferCount ?? 0) === 0;
  const isTradeOfferPersonalPromotionType = tradeOffer?.promotionType !== ETradeOfferPromotionType.Widget && tradeOffer?.promotionType !== ETradeOfferPromotionType.PublicPromocode && tradeOffer?.promotionType !== ETradeOfferPromotionType.ReferralLink && tradeOffer?.promotionType !== ETradeOfferPromotionType.Asp && tradeOffer?.promotionType !== ETradeOfferPromotionType.Digift;
  return useMemo(() => {
    if (!tradeOffer) {
      return null;
    } else {
      return _jsxs(_Fragment, {
        children: [activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && _jsx(TradeOfferClaimErrorMessage, {
          error: activation.activationError
        }), isCodesEnded && isActivationReceivedNow && isTradeOfferPersonalPromotionType && _jsx(TradeOfferClaimCodesEndsMessage, {
          promotionType: tradeOffer.promotionType
        }), tradeOffer.status === EOfferStatus.Active && activation.lastActivation?.promotion && !isCodesEnded && isTimerActive && activation.isReactivationSupported && activation.lastActivation?.appointmentDate && _jsx(TradeOfferClaimReactivationMessage, {
          date: activation.lastActivation?.appointmentDate,
          stop: stopTimer
        })]
      });
    }
  }, [tradeOffer, activation.activationError, activation.lastActivation?.promotion, activation.lastActivation?.appointmentDate, activation.isReactivationSupported, isCodesEnded, isActivationReceivedNow, isTradeOfferPersonalPromotionType, isTimerActive, stopTimer]);
};