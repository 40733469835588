import axios from 'axios';
import { createCancelToken } from './utils';
/**
 * АПИ по работе с продуктами
 */
const productOffer = {
  /** Получение категории товаров по id **/
  category: _ref => {
    let {
      id,
      signal
    } = _ref;
    return {
      url: `/product-categories/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  /** Получение списка категорий товаров **/
  categories: _ref2 => {
    let {
      signal
    } = _ref2;
    return {
      url: '/product-categories',
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default productOffer;