import { isArray } from 'lodash';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';
import { useLimitedDays } from '../hooks';
import { sortDates } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPMultipleCalendarPicker = _ref => {
  let {
    limitPastSelection,
    limitFutureSelection,
    ...props
  } = _ref;
  const onChangeInternal = selectedDates => {
    if (selectedDates === null) {
      return props.onChange?.(null);
    }
    const result = !isArray(selectedDates) ? [selectedDates.toDate()] : selectedDates.map(item => item.toDate());
    return props.onChange?.(result.sort(sortDates));
  };
  const mapDays = useLimitedDays({
    limitPastSelection,
    limitFutureSelection
  });
  return _jsx(StyledCalendar, {
    ...regularOptions,
    ...props,
    onChange: onChangeInternal,
    multiple: true,
    mapDays: mapDays,
    range: false,
    ...permanentOptions
  });
};