import { ControlButtons, FilterList, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductFilterLayout = props => {
  const {
    title,
    actions,
    children
  } = props;
  return _jsxs(Wrapper, {
    children: [title, _jsx(FilterList, {
      children: children
    }), actions && _jsx(ControlButtons, {
      children: actions
    })]
  });
};
export default ProductFilterLayout;