import { Hidden, Typography } from '@mui/material';
import { ArrowRightIcon } from 'presentation/media/icons';
import { Link as RouterLink } from 'react-router-dom';
import { LabelWrapper, Link } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Label = () => {
  return _jsxs(LabelWrapper, {
    children: [_jsx(Hidden, {
      mdDown: true,
      children: _jsx(Typography, {
        variant: "body2",
        children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432\u0441\u0451"
      })
    }), _jsx(ArrowRightIcon, {
      fontSize: "small"
    })]
  });
};
export const CmsContainerShowAllLink = _ref => {
  let {
    to
  } = _ref;
  return _jsx(Link, {
    component: RouterLink,
    label: _jsx(Label, {}),
    color: "white",
    clickable: true,
    to: to
  });
};