import OfferClaimSubscriptionDisabled from './disabled';
import OfferClaimSubscriptionEnabled from './enabled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferClaimSubscription = props => {
  const {
    enabled,
    isFetching,
    onSubscribe,
    onUnSubscribe
  } = props;
  return enabled ? _jsx(OfferClaimSubscriptionEnabled, {
    isFetching: isFetching,
    onUnSubscribe: onUnSubscribe
  }) : _jsx(OfferClaimSubscriptionDisabled, {
    isFetching: isFetching,
    onSubscribe: onSubscribe
  });
};
export default OfferClaimSubscription;