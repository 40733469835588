import { ECatalogTab } from 'presentation/features/catalog/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CatalogProductOffersContainer } from '../productOffers/container';
import { catalogTabSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CatalogProductOffersTabAdapter = props => {
  const {
    selected
  } = props;
  const tab = useSelector(catalogTabSelector);
  return useMemo(() => tab === ECatalogTab.ProductOffers ? _jsx(CatalogProductOffersContainer, {
    selected: selected
  }) : null, [tab, selected]);
};