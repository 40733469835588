import { EBalanceType, EPaymentType } from 'domain/model/enums';
import { AspCurrencyIcon, UnitsIcon } from 'presentation/media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getPaymentIcon = type => {
  switch (type) {
    case EPaymentType.AspRzdRub:
    case EBalanceType.ASP:
      return AspCurrencyIcon;
    case EPaymentType.BenefitRzdPoint:
    case EBalanceType.Bonuses:
      return UnitsIcon;
    case EPaymentType.Free:
    case EPaymentType.Rub:
      return null;
  }
};
export const PaymentIcon = _ref => {
  let {
    type,
    ...others
  } = _ref;
  const Component = getPaymentIcon(type);
  return Component ? _jsx(Component, {
    ...others
  }) : null;
};