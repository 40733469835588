import 'overlayscrollbars/css/OverlayScrollbars.css';
import CategorySelectCommonLayout from 'presentation/features/category/select/common/layout';
import CategorySelectLeaf from '../../components/selectLeaf';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = CategorySelectCommonLayout;

//вывод категорий простым списком в один уровень
const CategorySelectCommonContainer = props => {
  const {
    categories,
    selected,
    onSelect
  } = props;
  return _jsx(Layout, {
    children: categories.map(item => _jsx(CategorySelectLeaf, {
      category: item,
      selected: item.id === selected,
      onClick: onSelect
    }, item.id))
  });
};
export default CategorySelectCommonContainer;