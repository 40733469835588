import { EValidationType, validationRegexp } from 'presentation/utils/validation';
import { useState } from 'react';
import validator from 'validator';
import validateObject from '../../../../hooks/validation/utils';
import { useEmailEditQuery } from '../../../../hooks/useEmailEditQuery';
export const emailValidation = {
  email: {
    required: true,
    requiredMessage: 'Необходимо указать корректный адрес электронной почты',
    validator: (object, value) => {
      if (!value) return null;
      const isEmail = validator.isEmail(value);
      if (!isEmail || !validationRegexp.email.test(value)) {
        return {
          type: EValidationType.Error,
          hasError: true,
          message: 'Необходимо указать корректный адрес электронной почты'
        };
      } else {
        return null;
      }
    }
  }
};
const rules = {
  email: emailValidation.email
};
export const useUserProfileEmailEdit = () => {
  const [validation, setValidation] = useState(null);
  const [updateCurrentUserEmail, {
    isLoading: isFetching
  }] = useEmailEditQuery();
  const onUpdate = email => {
    const validation = validateObject({
      email
    }, rules);
    const isValid = validation.isValid;
    setValidation(validation.results);
    if (isValid) {
      return updateCurrentUserEmail({
        email
      }).unwrap().then(result => !!result).catch(error => {
        setValidation({
          email: {
            hasError: true,
            type: EValidationType.Error,
            message: error.data?.message || 'Произошла неизвестная ошибка'
          }
        });
        return Promise.resolve(false);
      });
    }
    return Promise.resolve(false);
  };
  return {
    isFetching,
    validation,
    onUpdate,
    setValidation
  };
};