import BackButton from '../../../../../features/offer/components/back';
import { DialogBackButtonWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPDialogBackButton = _ref => {
  let {
    label = 'Назад',
    onClick
  } = _ref;
  return _jsx(DialogBackButtonWrapper, {
    children: _jsx(BackButton, {
      onClick: onClick,
      label: label
    })
  });
};