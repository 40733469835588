import { useSelector } from 'react-redux';
import { tradeOfferListNoDataSelector, tradeOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import TradeOffersStub from '../stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(tradeOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(tradeOfferListNoDataSelector);
  if (noData) {
    return _jsx(TradeOffersStub, {
      isEmptySearch: isEmptySearch
    });
  }
  return null;
};