import axios from 'axios';
import HttpClient from '../network/http';
import { getFilesEndpoint } from './utils';
const cacheControlOn = 'private, max-age=2592000, must-revalidate';

/**
 * АПИ по работе с файлами
 */
const files = {
  upload: function (file) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const formData = new FormData();
    formData.append('file', file);
    const reqConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return axios.post(`${getFilesEndpoint()}/preload`, formData, reqConfig);
  },
  getImageUrl: (id, isPrivate) => {
    const token = HttpClient.getInstance().getAuthService()?.token;
    const params = new URLSearchParams();
    params.append('path', id);
    params.append('cache-control', cacheControlOn);
    if (isPrivate && token) params.append('access_token', token);
    return `${getFilesEndpoint()}/files?${params}`;
  }
};
export default files;