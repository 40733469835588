import ProductOfferListAsideAdapter from 'presentation/features/offer/product/list/adapters/aside';
import ProductOfferListLayout from 'presentation/features/offer/product/list/layout';
import ProductOfferListBannersAdapter from './adapters/banners';
import ProductOfferListBreadcrumbsAdapter from './adapters/breadcrumbs';
import ProductOfferListCommonAdapter from './adapters/dataCommon';
import ProductOfferListLoaderAdapter from './adapters/loader';
import ProductOfferListStubAdapter from './adapters/stub';
import { ProductOfferListContext } from './context';
import { ProductOfferHeaderContainer } from './header/container';
import { useProductOfferList } from './hooks/useProductOfferList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = ProductOfferListLayout;
const ProductOfferListContainer = props => {
  const {
    name: nameParam,
    categoryId: categoryIdParam,
    guid
  } = props;
  const handlers = useProductOfferList({
    guid,
    name: nameParam,
    categoryId: categoryIdParam
  });
  return _jsx(ProductOfferListContext.Provider, {
    value: handlers,
    children: _jsx(Layout, {
      banners: _jsx(ProductOfferListBannersAdapter, {
        guid: guid
      }),
      breadcrumbs: _jsx(ProductOfferListBreadcrumbsAdapter, {}),
      header: _jsx(ProductOfferHeaderContainer, {}),
      aside: _jsx(ProductOfferListAsideAdapter, {}),
      stub: _jsx(ProductOfferListStubAdapter, {}),
      loader: _jsx(ProductOfferListLoaderAdapter, {}),
      children: _jsx(ProductOfferListCommonAdapter, {
        guid: guid,
        isAddToCartFetching: id => !!id
      })
    })
  });
};
export default ProductOfferListContainer;