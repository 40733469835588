import { Fade } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OrderListItem from '../../../order/components/listItem';
import { LoadMoreWrapper, OrderList } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserOrders = props => {
  const {
    orders,
    generalOrderStatuses,
    isFetching,
    onLoadNextPage,
    onOrderClick,
    bookingOrderStatuses
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsxs("div", {
      children: [orders && orders.length > 0 && _jsx(OrderList, {
        container: true,
        spacing: 2,
        direction: "column",
        children: orders.map(order => _jsx(MPGrid, {
          item: true,
          children: _jsx(OrderListItem, {
            order: order,
            generalOrderStatuses: generalOrderStatuses,
            bookingOrderStatuses: bookingOrderStatuses,
            onClick: () => onOrderClick(order.id ?? '')
          })
        }, order.id))
      }), onLoadNextPage && _jsx(LoadMoreWrapper, {
        children: _jsxs(MPButton, {
          color: "white",
          size: "small",
          fullWidth: true,
          disabled: isFetching,
          onClick: onLoadNextPage,
          children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451", isFetching && _jsx(ContentLoader, {
            size: 20
          })]
        })
      })]
    })
  });
};
export default UserOrders;