import { OfferTagsWrapper } from './controls';
import OfferTag from '../tag';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OfferTags = props => {
  const {
    tags
  } = props;
  return _jsx(OfferTagsWrapper, {
    children: tags.map(t => _jsx(OfferTag, {
      offerTag: t
    }, t.name))
  });
};
export default OfferTags;