import CardFavoriteButton from '../../../components/common/buttons/cardFavorite/button';
import { useOfferFavoriteSimple, useOfferFavoriteStored } from '../hooks/useOfferFavorite';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const OfferFavoriteCommonContainerSimple = props => {
  const {
    guid,
    offerId,
    offerType,
    partnerId
  } = props;
  const {
    isFavorite,
    isFetching,
    onToggle
  } = useOfferFavoriteSimple({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId
    }
  });
  return _jsx(CardFavoriteButton, {
    active: isFavorite,
    inProgress: isFetching,
    onClick: onToggle
  });
};
export const OfferFavoriteCommonContainerStored = props => {
  const {
    guid,
    offerId,
    offerType,
    partnerId
  } = props;
  const {
    isFavorite,
    isFetching,
    onToggle
  } = useOfferFavoriteStored({
    guid,
    payload: {
      id: offerId,
      offerType: offerType,
      partnerId
    }
  });
  return _jsx(CardFavoriteButton, {
    active: isFavorite,
    inProgress: isFetching,
    onClick: onToggle
  });
};
export const OfferFavoriteCommonContainer = props => {
  const {
    stored
  } = props;
  return stored ? _jsx(OfferFavoriteCommonContainerStored, {
    ...props
  }) : _jsx(OfferFavoriteCommonContainerSimple, {
    ...props
  });
};