import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { CounterWrapper, TextWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserMenuItem = _ref => {
  let {
    label,
    icon,
    count,
    ...others
  } = _ref;
  return _jsxs(MenuItem, {
    ...others,
    children: [_jsx(ListItemIcon, {
      children: icon
    }), _jsx(TextWrapper, {
      primary: label
    }), !!count && _jsx(CounterWrapper, {
      children: _jsx(Typography, {
        color: "text.secondary",
        children: count
      })
    })]
  });
};