/** факт доступности элемента корзины для оформления заказа */

import { EOfferStatus } from 'domain/model/enums';
export const isCartItemEnabled = item => {
  const count = item.offer.stock ?? 0;
  return item.offer.status === EOfferStatus.Active && count > 0;
};
export const isCartItemStockOutOfQuantity = item => {
  const count = item.offer.stock ?? 0;
  return item.quantity > count;
};