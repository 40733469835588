import { useMemo } from 'react';
import HeaderOptions from '../../components/options';
import { EHeaderOptionType } from '../../components/options/types';
import useHeaderOptions from '../useHeaderOptions';
import HeaderUserInfoAdapter from '../userInfo';
import { MobileOptionsWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const MobileHeaderOptionsAdapter = () => {
  const options = useHeaderOptions();
  options.push({
    type: EHeaderOptionType.User,
    component: _jsx(HeaderUserInfoAdapter, {})
  });
  return useMemo(() => _jsx(MobileOptionsWrapper, {
    children: _jsx(HeaderOptions, {
      options: options
    })
  }), [options]);
};
export default MobileHeaderOptionsAdapter;