import { Price, PriceWrapper } from './controls';
import { toRubCurrency } from '../../../../../utils/currency';
import { Ellipsis } from '../../../../../components/common/ellipsis';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingListItemPrice = props => {
  const {
    priceItem
  } = props;
  return _jsxs(PriceWrapper, {
    children: ["\u043E\u0442", _jsx(Price, {
      children: toRubCurrency(priceItem.price)
    }), _jsx(Ellipsis, {
      as: "span",
      children: priceItem.unit?.name?.toLocaleLowerCase() ?? ''
    })]
  });
};
export default BookingListItemPrice;