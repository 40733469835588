import { Typography } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../media/icons';
import { BreadcrumbItemWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BreadcrumbItem = _ref => {
  let {
    name,
    link,
    hidden
  } = _ref;
  return _jsx(BreadcrumbItemWrapper, {
    hidden: hidden,
    children: _jsx(MPChip, {
      label: _jsx(Typography, {
        variant: "body2",
        children: name
      }),
      size: 'micro',
      clickable: !!link,
      component: Link,
      icon: _jsx(ArrowLeftIcon, {}),
      to: link ?? ''
    })
  });
};