import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferListIsFetchingSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferListLoaderAdapter = () => {
  const isFetching = useSelector(corpOfferListIsFetchingSelector);
  return useMemo(() => isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null, [isFetching]);
};