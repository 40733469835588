import { Hidden } from '@mui/material';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FirstLevelWrapper, List, RootWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CategorySelectLayout = props => {
  const {
    backButton,
    firstLevel,
    loader,
    scrollbarRef,
    hasContainerDivider = true,
    children
  } = props;
  return _jsxs(_Fragment, {
    children: [_jsx(Hidden, {
      smUp: true,
      children: backButton
    }), _jsx(RootWrapper, {
      hasBorder: hasContainerDivider,
      children: _jsx(OverlayScrollbarsComponent, {
        options: {
          scrollbars: {
            autoHide: 'leave'
          },
          overflowBehavior: {
            x: 'hidden',
            y: 'scroll'
          }
        },
        children: _jsx(List, {
          children: children
        })
      })
    }), firstLevel && _jsx(FirstLevelWrapper, {
      children: _jsx(OverlayScrollbarsComponent, {
        ref: scrollbarRef,
        options: {
          scrollbars: {
            autoHide: 'leave'
          }
        },
        children: firstLevel
      })
    }), loader]
  });
};
export default CategorySelectLayout;