import { useSelector } from 'react-redux';
import { SearchIcon } from '../../../../media/icons';
import HeaderAction from '../../components/action';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { headerCatalogDialogVisibleSelector } from '../../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchOptionAdapter = () => {
  const handlers = useContextHandlers();
  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  return _jsx(HeaderAction, {
    icon: SearchIcon,
    active: catalogVisible,
    onClick: handlers.toggleCatalog
  });
};
export default SearchOptionAdapter;