import SearchResultsScreen from 'presentation/screen/search/results';
import { ESearchUrlParam } from 'presentation/types';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.search;
const routing = {
  results: root
};
export const getSearchResultsRoute = props => {
  const {
    guid,
    query
  } = props;
  const newGuid = guid ?? uuidv4();
  const searchParams = new URLSearchParams();
  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }
  return {
    pathname: `${routing.results}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
const SearchEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.results,
      component: SearchResultsScreen
    }), _jsx(Redirect, {
      to: routing.results
    })]
  });
};
export default SearchEntry;