import { useMemo } from 'react';
import UserLocation from '../../../components/common/location';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderLocationAdapter = _ref => {
  let {
    ...others
  } = _ref;
  const {
    settings
  } = useUserLocalSettings();
  const handlers = useContextHandlers();
  const userLocation = settings.city?.name ?? null;
  return useMemo(() => userLocation ? _jsx(UserLocation, {
    ...others,
    location: userLocation,
    onClick: handlers.showChangeLocationDialog
  }) : null, [handlers.showChangeLocationDialog, others, userLocation]);
};
export default HeaderLocationAdapter;