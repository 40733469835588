import { Hidden } from '@mui/material';
import { useGetUserBannersQuery } from 'data/api/user';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import BannersMainTopLayout from 'presentation/features/banners/mainTop/layout';
import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import { normalizedLink } from 'presentation/utils';
import { useRef } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { bannersAutoplayDelay, bannersPerView, bannersSpaceBetween, getBannerTopPlaceByPartition } from '../utils';
import { ButtonNext, ButtonPrev, Carousel, Image, ImageWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = BannersMainTopLayout;
const BannersMainTopContainer = _ref => {
  let {
    guid,
    partition
  } = _ref;
  const place = getBannerTopPlaceByPartition(partition);
  const buttonPrevRef = useRef(null);
  const buttonNextRef = useRef(null);
  const {
    settings: {
      city
    }
  } = useUserLocalSettings();
  const cityId = city?.fiasId ?? null;
  const {
    data: banners
  } = useGetUserBannersQuery({
    guid,
    place,
    cityId
  }, {
    refetchOnMountOrArgChange: false
  });
  const isBannersEmpty = !banners || banners.length === 0;
  return !isBannersEmpty ? _jsx(Layout, {
    children: _jsxs(Carousel, {
      modules: [Navigation, Pagination, Autoplay],
      spaceBetween: bannersSpaceBetween,
      slidesPerView: bannersPerView,
      loop: banners.length > 1,
      autoplay: {
        delay: bannersAutoplayDelay
      },
      navigation: {
        prevEl: buttonPrevRef.current,
        nextEl: buttonNextRef.current
      },
      pagination: {
        clickable: false
      },
      onResize: swiper => {
        swiper.navigation.init();
      },
      children: [banners.map(_ref2 => {
        let {
          id,
          url,
          desktopFormatImage,
          name
        } = _ref2;
        return _jsx(SwiperSlide, {
          children: _jsx(CorpLinkProxy, {
            link: url ? normalizedLink(url) : '',
            children: linkByProxy => _jsx(ImageWrapper, {
              target: "_blank",
              href: linkByProxy,
              children: desktopFormatImage && _jsx(Image, {
                src: desktopFormatImage.path,
                alt: name
              })
            })
          })
        }, id);
      }), banners.length > 1 && _jsxs(Hidden, {
        lgDown: true,
        children: [_jsx(ButtonPrev, {
          ref: buttonPrevRef,
          children: _jsx(ArrowLeftIcon, {
            color: "secondary",
            fontSize: "large"
          })
        }), _jsx(ButtonNext, {
          ref: buttonNextRef,
          children: _jsx(ArrowRightIcon, {
            color: "secondary",
            fontSize: "large"
          })
        })]
      })]
    })
  }) : null;
};
export default BannersMainTopContainer;