import { cacheKeys } from 'data/api';
import { cartApi, cartApiEndpointsList, useGetCartQuery } from 'data/api/cart';
import { useCreateUserOrdersMutation } from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { EOrderCreateError } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Notifier from 'system/notifier';
import useWatchApiMutations from '../../../hooks/useWatchApiMutations';
import { toRubCurrency } from '../../../utils/currency';
import CartProductsNotFoundDialog from '../components/dialogs/productsNotFound';
import CartOrderCreateHelp from '../components/orderCreateHelp';
import { getLastOrderRoute } from '../entry';
import { isCartItemStockOutOfQuantity } from '../utils';
import CartSidebarLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Layout = CartSidebarLayout;
const CartSidebarContainer = _ref => {
  let {
    items
  } = _ref;
  const history = useHistory();
  const mutationsLoading = useWatchApiMutations({
    deps: [items],
    api: cartApi,
    endpoints: cartApiEndpointsList
  });
  const [productsNotFoundDialogVisible, setProductsNotFoundDialogVisible] = useState(false);
  const {
    refetch: cartRefetch
  } = useGetCartQuery();
  const [createOrders, {
    isLoading
  }] = useCreateUserOrdersMutation(cacheKeys.order);
  const data = useMemo(() => {
    return items.reduce((prev, next) => {
      const cost = Math.abs(next.offer.price * next.quantity);
      const discount = Math.abs(next.offer.price - (next.offer.originalPrice ?? 0)) * next.quantity;
      return {
        count: prev.count + next.quantity,
        cost: prev.cost + cost,
        discount: prev.discount + discount
      };
    }, {
      count: 0,
      cost: 0,
      discount: 0
    });
  }, [items]);
  const showProductsNotFoundDialogVisible = useCallback(() => setProductsNotFoundDialogVisible(true), []);
  const hideProductsNotFoundDialogVisible = useCallback(() => setProductsNotFoundDialogVisible(false), []);
  const parseOrderCreateError = useCallback(error => {
    const type = error.code;
    switch (type) {
      case EOrderCreateError.ProductNotFound:
        ErrorHandler.handleBusinessError(error);
        cartRefetch();
        showProductsNotFoundDialogVisible();
        break;
      default:
        Notifier.getInstance().addError('При создании заказов возникла неизвестная ошибка');
    }
  }, [cartRefetch, showProductsNotFoundDialogVisible]);
  const parseOrderCreateErrorResponse = useCallback(response => {
    switch (response.status) {
      case businessErrorCode:
        if (!response.data.code) {
          ErrorHandler.handleHttpError(response);
        } else {
          parseOrderCreateError(response.data);
        }
        return;
      default:
        {
          ErrorHandler.handleHttpError(response);
        }
    }
  }, [parseOrderCreateError]);
  const onOrderCreate = () => {
    createOrders(items).unwrap().then(() => history.push(getLastOrderRoute())).catch(e => parseOrderCreateErrorResponse(e));
  };
  const {
    cost,
    count,
    discount
  } = data;
  const someItemsMoreThanStock = items.some(i => isCartItemStockOutOfQuantity(i));
  const canCreateOrders = !isLoading && count > 0 && !someItemsMoreThanStock;
  return _jsxs(_Fragment, {
    children: [_jsx(Layout, {
      title: `Товаров: ${count}`,
      discount: discount > 0 && `Скидка: ${toRubCurrency(discount)}`,
      cost: toRubCurrency(cost),
      createOrdersButton: _jsxs(MPButton, {
        fullWidth: true,
        disabled: !canCreateOrders || mutationsLoading,
        onClick: onOrderCreate,
        children: ["\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437\u044B", isLoading && _jsx(ContentLoader, {})]
      }),
      help: _jsx(CartOrderCreateHelp, {})
    }), _jsx(CartProductsNotFoundDialog, {
      open: productsNotFoundDialogVisible,
      onClose: hideProductsNotFoundDialogVisible
    })]
  });
};
export default CartSidebarContainer;