import { Container, Fade, Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { ImageWrapper } from 'presentation/components/common/stub/common/controls';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import React from 'react';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const maxWidth = 'md';
const StubCommon = _ref => {
  let {
    title = 'Данные не найдены',
    text,
    image
  } = _ref;
  const isUpMaxWidth = useMediaQuery(theme => theme.breakpoints.up(maxWidth));
  const {
    x4,
    x7
  } = useGridSpacing();
  return _jsx(Fade, {
    in: true,
    children: _jsxs(Container, {
      maxWidth: maxWidth,
      fixed: isUpMaxWidth,
      children: [_jsx(Splitter, {
        size: x7
      }), _jsxs(MPGrid, {
        container: true,
        spacing: x4,
        direction: "column",
        alignItems: "center",
        justifyContent: "center",
        children: [_jsx(MPGrid, {
          item: true,
          children: title && _jsx(_Fragment, {
            children: typeof title !== 'string' ? _jsx(_Fragment, {
              children: title
            }) : _jsx(Typography, {
              variant: "h2",
              align: "center",
              children: title
            })
          })
        }), text && _jsx(MPGrid, {
          item: true,
          children: _jsx(_Fragment, {
            children: typeof text !== 'string' ? _jsx(_Fragment, {
              children: text
            }) : _jsx(Typography, {
              align: "center",
              children: text
            })
          })
        }), image && _jsxs(MPGrid, {
          item: true,
          children: [_jsx(Splitter, {
            size: 5
          }), typeof image === 'string' && _jsx(ImageWrapper, {
            children: _jsx("img", {
              src: image,
              alt: "stub"
            })
          })]
        })]
      })]
    })
  });
};
export default StubCommon;