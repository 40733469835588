import OfferListLayout from 'presentation/layouts/offerList';
import { AspOfferListAsideAdapter, AspOfferListDataCommonAdapter, AspOfferListDataUpcomingAdapter, AspOfferListLoaderAdapter, AspOfferListSortSelectAdapter, AspOfferListStubAdapter, AspOffersHeaderAdapter } from './adapters';
import { AspOfferListContext } from './context';
import useAspOfferListHandlers from './hooks/useAspOfferList';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = OfferListLayout;
const Header = _jsx(AspOffersHeaderAdapter, {});
const Aside = _jsx(AspOfferListAsideAdapter, {});
const Stub = _jsx(AspOfferListStubAdapter, {});
const Loader = _jsx(AspOfferListLoaderAdapter, {});
const SortSelect = _jsx(AspOfferListSortSelectAdapter, {});
const AspOfferListContainer = props => {
  const {
    guid,
    name,
    categoryId,
    partnerId
  } = props;
  const handlers = useAspOfferListHandlers({
    guid,
    name,
    categoryId,
    partnerId
  });
  return _jsx(AspOfferListContext.Provider, {
    value: handlers,
    children: _jsxs(Layout, {
      header: Header,
      aside: Aside,
      controls: SortSelect,
      stub: Stub,
      loader: Loader,
      children: [_jsx(AspOfferListDataCommonAdapter, {
        guid: guid
      }), _jsx(AspOfferListDataUpcomingAdapter, {
        guid: guid
      })]
    })
  });
};
export default AspOfferListContainer;