import { CatalogContainer } from 'presentation/features/catalog/container';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getAspOffersListRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/routes';
import { getTradeOffersListRoute } from 'presentation/features/offer/trade/routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { getProductOfferListRoute } from '../../offer/product/entry';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { headerCatalogDialogVisibleSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CatalogAdapter = () => {
  const location = useLocation();
  const matchedOfferSectionRoute = useRouteMatch([getBookingOffersListRoute({}).pathname, getTradeOffersListRoute({}).pathname, getCorpOffersListRoute({}).pathname, getProductOfferListRoute({}).pathname, getAspOffersListRoute({}).pathname]);
  const handlers = useContextHandlers();
  const selectedCategory = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const selected = searchParams.get(ECatalogUrlParam.Category) ?? null;
    return selected;
  }, [location.search]);
  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  return _jsx(CatalogContainer, {
    matchedOfferSectionPath: matchedOfferSectionRoute?.path ?? null,
    open: catalogVisible,
    selected: selectedCategory,
    onClose: handlers.hideCatalog
  });
};
export default CatalogAdapter;