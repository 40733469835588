import ContentLoader from 'presentation/components/common/loader';
import useOrderDetailsFetchingStatus from '../hooks/useOrderDetailsFetchingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OrderDetailsLoaderAdapter = _ref => {
  let {
    id
  } = _ref;
  const isFetching = useOrderDetailsFetchingStatus({
    id
  });
  return isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null;
};
export default OrderDetailsLoaderAdapter;