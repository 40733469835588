import { BreadcrumbTree, getBreadcrumbChains } from 'presentation/components/common/breadcrumb/expanded';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferBreadcrumbs = props => {
  const {
    startCategory,
    offerCategories,
    dictionary,
    linkFactory
  } = props;
  if (!offerCategories || !dictionary) {
    return null;
  }
  const breadcrumbs = getBreadcrumbChains(offerCategories, dictionary, linkFactory).map(item => {
    if (startCategory) {
      return [startCategory, ...item];
    } else {
      return item;
    }
  });
  return _jsx(BreadcrumbTree, {
    items: breadcrumbs
  });
};
export default OfferBreadcrumbs;