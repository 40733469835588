import { ETradeOfferSortType, tradeOfferSortType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../../offer/components/sortSelect';
import { useAspOffersContextHandlers } from '../../hooks/useAspOffersContextHandlers';
import { PartnerDeskDetailsTabToolbarLayout } from '../../layouts';
import { partnerDeskDetailsAspOffersSortSelector } from '../../store/selectors';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsTabName } from '../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const items = Object.values(tradeOfferSortType);
const PartnerDeskDetailsTabAspOffersToolbar = () => {
  const sort = useSelector(partnerDeskDetailsAspOffersSortSelector);
  const handlers = useAspOffersContextHandlers();
  return useMemo(() => _jsx(PartnerDeskDetailsTabToolbarLayout, {
    title: getPartnerDeskDetailsTabName(EPartnerDeskDetailsTab.AspOffers),
    sort: _jsx(OffersSortSelect, {
      value: sort,
      items: items,
      defaultValue: ETradeOfferSortType.Default,
      onChange: handlers.onChangeAspOffersSort
    })
  }), [sort, handlers.onChangeAspOffersSort]);
};
export default PartnerDeskDetailsTabAspOffersToolbar;