import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferDetailStub = _ref => {
  let {
    text
  } = _ref;
  return _jsx(OfferDetailsErrorLayout, {
    text: text
  });
};
export default TradeOfferDetailStub;