import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { Tab, Tabs } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EProductOfferDetailsTab } from '../utils';
import Splitter from 'presentation/components/common/splitter';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import OfferDetailsTabCharacteristics from '../../../components/detailsTabs/characteristics';
import React from 'react';
import { PartnerDeskDetailsTabContacts } from 'presentation/features/partnerDesk/details/tabs';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferTabs = props => {
  const {
    productOffer,
    tab,
    characteristicTabRef,
    onChange,
    partnerDesk
  } = props;
  return _jsxs(TabsWrapper, {
    children: [_jsxs(Tabs, {
      value: tab,
      textColor: "secondary",
      variant: "scrollable",
      onChange: onChange,
      scrollButtons: false,
      children: [_jsx(Tab, {
        label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435",
        value: EProductOfferDetailsTab.Description
      }), _jsx(Tab, {
        ref: characteristicTabRef,
        label: "\u0425\u0430\u0440\u0430\u043A\u0442\u0435\u0440\u0438\u0441\u0442\u0438\u043A\u0438",
        value: EProductOfferDetailsTab.Characteristics
      }), _jsx(Tab, {
        label: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B",
        value: EProductOfferDetailsTab.Contacts
      })]
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsxs(MPGrid, {
      item: true,
      zero: 12,
      children: [tab === EProductOfferDetailsTab.Description && _jsx(OfferDetailsTabDescription, {
        description: productOffer.description
      }), tab === EProductOfferDetailsTab.Characteristics && _jsx(OfferDetailsTabCharacteristics, {
        text: productOffer.otherAttributes ?? ''
      }), tab === EProductOfferDetailsTab.Contacts && partnerDesk.contacts && _jsx(PartnerDeskDetailsTabContacts, {
        contacts: partnerDesk.contacts
      })]
    })]
  });
};
export default ProductOfferTabs;