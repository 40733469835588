import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ChangeEmailDialog = props => {
  const {
    label,
    value,
    validation,
    isFetching,
    primaryText,
    onChange,
    onClose,
    onSave
  } = props;
  return _jsx(MPConfirmDialog, {
    open: true,
    title: label,
    maxWidth: "xs",
    scroll: "body",
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsxs(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onSave,
        children: [primaryText, isFetching && _jsx(ContentLoader, {})]
      }), _jsx(MPButton, {
        color: "brand",
        size: "large",
        variant: "text",
        fullWidth: true,
        disabled: isFetching,
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    onClose: onClose,
    children: _jsx(MPFormInput, {
      label: label,
      value: value ?? '',
      error: !!validation?.hasError,
      helperText: validation?.message,
      disabled: isFetching,
      onChange: event => onChange(event.target.value)
    })
  });
};