import ClaimTitle from '../../../../components/claim/title';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimTitleAdapter = () => {
  const {
    tradeOffer
  } = useTradeOfferClaimContext();
  if (!tradeOffer) {
    return null;
  }
  return _jsx(ClaimTitle, {
    text: tradeOffer.title
  });
};
export default TradeOfferClaimTitleAdapter;