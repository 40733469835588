import { useSelector } from 'react-redux';
import ContentLoader from 'presentation/components/common/loader';
import { getUserFavoritesOffersIsFetchingSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserFavoritesLoaderAdapter = _ref => {
  let {
    guid
  } = _ref;
  const isFetching = useSelector(getUserFavoritesOffersIsFetchingSelector(guid));
  return isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null;
};
export default UserFavoritesLoaderAdapter;