import base64url from 'base64url';
import usePrevious from 'presentation/hooks/usePrevious';
import { applyInterceptor, clearInterceptor } from 'presentation/screen/cms/hooks/previewMode/useClearGlobalPreviewInterceptor';
import { useCmsTargetSearchParams } from 'presentation/screen/cms/hooks/useCmsSearchParams';
import useWebStoredTargetParam from 'presentation/screen/cms/hooks/useWebStoredTargetParam';
import { previewModeTargetParamSelector } from 'presentation/screen/cms/store/selectors';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// при наличии target параметра в search, парсит его и включает режим превью - некоторые server response будут реагировать
// на новый заголовок "x-target-value", который автоматически добавляется во все request
export const useGlobalPreviewMode = () => {
  const {
    pathname
  } = useLocation();
  const prevPathname = usePrevious(pathname);
  const targetParamsEncoded = useSelector(previewModeTargetParamSelector);
  const targetParams = useCmsTargetSearchParams();
  const hasTargetParams = Object.keys(targetParams).length !== 0;
  const stringifiedTargetParams = JSON.stringify(targetParams);
  const encodedTargetHeader = base64url.encode(stringifiedTargetParams);
  const {
    setTargetParamsEncodedHandler: setTargetParamsEncoded
  } = useWebStoredTargetParam(hasTargetParams ? encodedTargetHeader : null);

  // обновляем ls
  useEffect(() => {
    if (hasTargetParams && targetParamsEncoded !== null) {
      setTargetParamsEncoded(encodedTargetHeader);
    }
  }, [encodedTargetHeader, hasTargetParams, setTargetParamsEncoded, targetParamsEncoded]);

  // снова возвращаем превью мод, если перешли со страницы без таргет параметров на страницу с их наличием
  useEffect(() => {
    if (prevPathname !== pathname && hasTargetParams) {
      setTargetParamsEncoded(encodedTargetHeader);
    }
  }, [encodedTargetHeader, hasTargetParams, pathname, prevPathname, setTargetParamsEncoded]);
  const ejectInterceptor = useCallback(clearInterceptor, []);

  // сброс ls, интерсептора, refresh
  const clear = useCallback(function () {
    let forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    ejectInterceptor();
    setTargetParamsEncoded(null);
    if (!forceRefresh) {
      return;
    }
    window.location.href = window.location.pathname;
  }, [ejectInterceptor, setTargetParamsEncoded]);
  applyInterceptor(targetParamsEncoded);
  const isHeaderApplied = !!targetParamsEncoded;
  return {
    clear,
    isHeaderApplied
  };
};