import { Typography } from '@mui/material';
import { ArrowLeftIcon } from 'presentation/media/icons';
import { IconWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CategoryCatalogBackButton = _ref => {
  let {
    text,
    onClick
  } = _ref;
  return _jsxs(Wrapper, {
    onClick: onClick,
    children: [_jsx(IconWrapper, {
      variant: "squared",
      size: "small",
      children: _jsx(ArrowLeftIcon, {
        color: "inherit"
      })
    }), _jsx(Typography, {
      noWrap: true,
      children: text
    })]
  });
};
export default CategoryCatalogBackButton;