import { useUpdateCurrentUserMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { userProfileValidation } from 'presentation/features/user/validation';
import useValidation from 'presentation/hooks/validation/useValidation';
import { useEffect, useState } from 'react';
const getValue = (attribute, user, initialValue) => initialValue !== undefined ? initialValue : user[attribute];
export const useUserProfileAttributeEdit = props => {
  const {
    user,
    attribute,
    initialValue
  } = props;
  const [value, setValue] = useState(() => getValue(attribute, user, initialValue));
  const [updateCurrentUser, {
    error: updateCurrentUserError,
    isSuccess: isUpdated,
    isLoading: isUpdating,
    reset: updateCurrentUserReset
  }] = useUpdateCurrentUserMutation();
  const data = {
    ...user,
    [attribute]: value
  };
  const {
    validate,
    validationResult: validation,
    resetValidationResult
  } = useValidation({
    object: data,
    rules: {
      [attribute]: userProfileValidation[attribute]
    }
  });
  const update = () => {
    const isValid = validate();
    if (isValid) {
      return updateCurrentUser({
        id: user.id,
        data
      }).unwrap().then(result => result === 200);
    }
    return Promise.resolve(false);
  };
  const reset = () => {
    resetValidationResult();
    setValue(getValue(attribute, user, initialValue));
  };
  useEffect(() => {
    setValue(getValue(attribute, user, initialValue));
  }, [attribute, user, initialValue]);
  useEffect(() => {
    if (isUpdated) {
      updateCurrentUserReset();
    }
  }, [isUpdated, updateCurrentUserReset]);
  useEffect(() => {
    if (updateCurrentUserError) {
      ErrorHandler.handleHttpError(updateCurrentUserError);
    }
  }, [updateCurrentUserError]);
  return {
    value,
    isUpdating,
    validation,
    update,
    reset,
    onChange: setValue
  };
};