import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import CorpOfferBonusUnavailableAdapter from 'presentation/features/offer/corp/components/bonusUnavailableStub';
import CorpOfferBreadcrumbAdapter from 'presentation/features/offer/corp/details/adapters/breadcrumb';
import CorpOfferDetailsPartnerInfoAdapter from 'presentation/features/offer/corp/details/adapters/partnerInfo';
import CorpOfferDetailSidebarAdapter from 'presentation/features/offer/corp/details/adapters/sidebar';
import CorpOfferDetailsTabsAdapter from 'presentation/features/offer/corp/details/adapters/tabs';
import CorpOfferDetailsTitleAdapter from 'presentation/features/offer/corp/details/adapters/title';
import OfferDetailsLayout from '../../../../layouts/offerDetail';
import CorpOfferCarouselAdapter from './adapters/carousel';
import CorpOfferCloseAdapter from './adapters/close';
import CorpOfferConditionsAdapter from './adapters/condition';
import CorpOfferDetailsFavoriteAdapter from './adapters/favorite';
import CorpOfferDetailsLoaderAdapter from './adapters/loader';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import CorpOfferDetailStub from './adapters/stub';
import { CorpOfferDetailContext } from './context';
import useCorpOfferDetails from './hooks/useCorpOfferDetails';
import useCorpOfferDetailsLoadingStatus from './hooks/useCorpOfferDetailsLoadingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DetailsLayout = OfferDetailsLayout;
const CorpOfferDetailsContainer = _ref => {
  let {
    guid,
    id,
    tab,
    from
  } = _ref;
  const handlers = useCorpOfferDetails({
    guid,
    id,
    from
  });
  return _jsx(CorpOfferDetailContext.Provider, {
    value: handlers,
    children: _jsx(RenderManagerErrorProvider, {
      children: _jsx(RenderDecoratorContainer, {
        decorators: [_jsx(CommonRenderManager, {
          loader: _jsx(CorpOfferDetailsLoaderAdapter, {
            id: id
          }),
          stub: CorpOfferDetailStub
        }, 'loader'), _jsx(CorpOfferBonusUnavailableAdapter, {}, 'bonusUnavailable')],
        children: _jsx(DetailsLayout, {
          id: id,
          image: _jsx(CorpOfferCarouselAdapter, {
            id: id
          }),
          favorite: _jsx(CorpOfferDetailsFavoriteAdapter, {
            guid: guid,
            id: id
          }),
          description: _jsx(CorpOfferConditionsAdapter, {
            id: id
          }),
          breadcrumb: _jsx(CorpOfferBreadcrumbAdapter, {
            id: id
          }),
          smartphoneFixedBar: _jsx(SmartPhoneFixedBarAdapter, {
            guid: guid,
            id: id
          }),
          partnerInfo: _jsx(CorpOfferDetailsPartnerInfoAdapter, {
            id: id
          }),
          sidebar: _jsx(CorpOfferDetailSidebarAdapter, {
            id: id
          }),
          tabs: _jsx(CorpOfferDetailsTabsAdapter, {
            id: id,
            guid: guid,
            tab: tab
          }),
          title: _jsx(CorpOfferDetailsTitleAdapter, {
            id: id
          }),
          close: _jsx(CorpOfferCloseAdapter, {}),
          useLoading: useCorpOfferDetailsLoadingStatus
        })
      })
    })
  });
};
export default CorpOfferDetailsContainer;