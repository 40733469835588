import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
export const useLocalValue = initialValue => {
  const [localValue, setLocalValue] = useState(initialValue);
  const [prevInitialValue, setPrevInitialValue] = useState(initialValue);
  if (initialValue !== prevInitialValue) {
    setPrevInitialValue(initialValue);
    setLocalValue(initialValue);
  }
  return {
    localValue,
    setLocalValue
  };
};
export const useInputDebounce = props => {
  const {
    initialValue,
    delay = 1000,
    nullIsValidValue = false,
    cancelToken = null,
    onChange
  } = props;
  const {
    localValue,
    setLocalValue
  } = useLocalValue(initialValue);
  const handleChange = useMemo(() => debounce(newValue => onChange(newValue), delay), [delay, onChange]);
  useEffect(() => {
    if (localValue !== initialValue && (localValue || nullIsValidValue)) {
      handleChange(localValue);
    }
  }, [handleChange, initialValue, localValue, nullIsValidValue]);
  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [cancelToken, handleChange]);
  return [localValue, setLocalValue];
};