import { ETradeOfferSortType, tradeOfferSortType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../components/sortSelect';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { aspOfferListIsNewSearchFetchingSelector, aspOfferListNoDataSelector, aspOfferListSortSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const items = Object.values(tradeOfferSortType);
export const AspOfferListSortSelectAdapter = () => {
  const handlers = useContextHandlers();
  const sort = useSelector(aspOfferListSortSelector);
  const noData = useSelector(aspOfferListNoDataSelector);
  const isNewSearchFetching = useSelector(aspOfferListIsNewSearchFetchingSelector);
  return useMemo(() => !noData && !isNewSearchFetching && _jsx(OffersSortSelect, {
    value: sort,
    items: items,
    defaultValue: ETradeOfferSortType.Default,
    onChange: handlers.onChangeSort
  }), [sort, noData, isNewSearchFetching, handlers.onChangeSort]);
};