import MainFooterContainer from 'presentation/features/footer/container';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ScreenLayout = props => {
  const {
    footer,
    children
  } = props;
  return _jsxs(_Fragment, {
    children: [children, typeof footer === 'boolean' ? _jsx(MainFooterContainer, {}) : footer]
  });
};
export default ScreenLayout;