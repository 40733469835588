import { Fade } from '@mui/material';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerWindowLayout = props => {
  const {
    links,
    children
  } = props;
  useVisibleSectionLinks();
  return _jsx(Fade, {
    in: true,
    children: _jsxs(DefaultContentWrapper, {
      children: [links, children]
    })
  });
};
export default PartnerWindowLayout;