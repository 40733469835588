import { Link } from '@mui/material';
import Logo from '../../../../components/common/logo';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderTitle = props => {
  const {
    onClickLogo
  } = props;
  return _jsx(Wrapper, {
    children: _jsx(Link, {
      onClick: onClickLogo,
      children: _jsx(Logo, {})
    })
  });
};
export default HeaderTitle;