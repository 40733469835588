import ProductOfferDetailsContainer from 'presentation/features/offer/product/details/container';
import { EProductOfferDetailsTab } from 'presentation/features/offer/product/details/utils';
import { EProductOfferUrlParam } from 'presentation/features/offer/product/utils';
import { useLocation, useParams } from 'react-router';
import ScreenLayout from '../../layouts/screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferScreen = () => {
  const location = useLocation();
  const {
    id
  } = useParams();
  const tab = new URLSearchParams(location.search).get(EProductOfferUrlParam.Tab) ?? EProductOfferDetailsTab.Characteristics;
  return _jsx(ScreenLayout, {
    footer: true,
    children: _jsx(ProductOfferDetailsContainer, {
      id: id,
      tab: tab,
      canFavoriteActions: true
    })
  });
};
export default ProductOfferScreen;