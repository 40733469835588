import AppSvgIcon from '../../../../components/common/icon';
import { CloseIcon, MenuIcon } from '../../../../media/icons';
import { StyledCatalogButton } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CatalogButton = props => {
  const {
    open,
    text = 'Каталог',
    disabled = false,
    onClick,
    showIconOnly
  } = props;
  const icon = open ? _jsx(AppSvgIcon, {
    fontSize: "medium",
    icon: CloseIcon
  }) : _jsx(AppSvgIcon, {
    fontSize: "medium",
    icon: MenuIcon
  });
  const iconOnly = !text || showIconOnly;
  return _jsx(StyledCatalogButton, {
    size: "large",
    color: "brand",
    disabled: disabled,
    onClick: onClick,
    children: iconOnly ? icon : _jsxs(_Fragment, {
      children: [icon, text]
    })
  });
};
export default CatalogButton;