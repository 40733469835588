import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import UserDetailsToolbarLayout from 'presentation/layouts/userDetailsToolbar';
import { ContentWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserActivationsLayout = props => {
  const {
    header,
    toolbar,
    loader,
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsxs(Wrapper, {
      children: [_jsx(Typography, {
        variant: "h1",
        children: header
      }), _jsx(Splitter, {
        size: 3
      }), toolbar && _jsxs(UserDetailsToolbarLayout, {
        children: [toolbar, _jsx(Splitter, {
          size: 3
        })]
      }), _jsx(ContentWrapper, {
        children: children
      }), loader]
    })
  });
};
export default UserActivationsLayout;