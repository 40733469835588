import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserOrdersContainer from 'presentation/features/user/details/orders/container';
import { EUserOrdersTab } from 'presentation/features/user/details/orders/utils';
import { EUserOrdersUrlParam } from 'presentation/features/user/entry';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserOrdersScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EUserOrdersUrlParam.Tab) ?? EUserOrdersTab.Active;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(UserOrdersContainer, {
        guid: guid,
        tab: tab
      })
    })
  });
};
export default UserOrdersScreen;