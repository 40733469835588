import { Typography } from '@mui/material';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { CheckIcon } from 'presentation/media/icons';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { useMemo } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserProfileCorpLinkedAdapter = () => {
  const {
    isCorpUser
  } = useAuthUser();
  return useMemo(() => isCorpUser ? _jsx(MPChip, {
    icon: _jsx(CheckIcon, {
      fontSize: "small"
    }),
    label: _jsx(Typography, {
      variant: "caption",
      children: "\u0420\u0430\u0431\u043E\u0442\u043D\u0438\u043A \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB"
    }),
    size: "small",
    color: "success"
  }) : null, [isCorpUser]);
};