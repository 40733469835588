import LegalPersonalDataAgreementScreen from 'presentation/screen/public/personalDataAgreement';
import LegalPersonalDataPolicyScreen from 'presentation/screen/public/personalDataPolicy';
import { Redirect, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';
import { EAppFeature } from '../../types';
import { RouteByFeature } from '../general/routing/routeByFeature';
import LegalTermsOfUseScreen from 'presentation/screen/public/termsOfUse';
import LegalCookiesScreen from '../../screen/public/cookies';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.legal;
const routing = {
  root,
  personalDataPolicy: `${root}/personal-data-policy`,
  personalDataAgreement: `${root}/personal-data-agreement`,
  termsOfUse: `${root}/terms-of-use`,
  cookies: `${root}/cookies`
};
export const getLegalPersonalDataPolicyRoute = () => routing.personalDataPolicy;
export const getLegalPersonalDataAgreementRoute = () => routing.personalDataAgreement;
export const getLegalTermsOfUseRoute = () => routing.termsOfUse;
export const getLegalCookiesRoute = () => routing.cookies;
const LegalEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(RouteByFeature, {
      feature: EAppFeature.PersonalDataPolicy,
      exact: true,
      path: routing.personalDataPolicy,
      component: LegalPersonalDataPolicyScreen
    }), _jsx(RouteByFeature, {
      feature: EAppFeature.PersonalDataAgreement,
      exact: true,
      path: routing.personalDataAgreement,
      component: LegalPersonalDataAgreementScreen
    }), _jsx(RouteByFeature, {
      feature: EAppFeature.TermsOfUse,
      exact: true,
      path: routing.termsOfUse,
      component: LegalTermsOfUseScreen
    }), _jsx(RouteByFeature, {
      feature: EAppFeature.Cookies,
      exact: true,
      path: routing.cookies,
      component: LegalCookiesScreen
    }), _jsx(Redirect, {
      to: rootRouting.root
    })]
  });
};
export default LegalEntry;