import PartnerLink from '../../../../../../components/common/partnerLink';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimReferralLinkPromotion = props => {
  const {
    link,
    onClick
  } = props;
  return _jsx(PartnerLink, {
    link: link,
    onClick: onClick
  });
};