import { userApi } from 'data/api/user';
import { EUserStatus } from 'domain/model';
export const currentUserIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.id ?? null;
};
export const currentUserLocalityIdSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.locality?.id ?? null;
};
export const currentUserBalancesSelector = store => {
  const {
    data: value
  } = userApi.endpoints.getUserBalance.select()(store);
  return value ?? null;
};
export const currentUserIsEmailVerifiedSelector = store => {
  return userApi.endpoints.getCurrentUser.select({})(store).data?.emailVerified ?? null;
};
export const currentUserIsStatusEnabledSelector = store => {
  const status = userApi.endpoints.getCurrentUser.select({})(store).data?.status;
  return status ? status === EUserStatus.Enabled : null;
};