import { Typography } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import { getLeftSeconds } from '../../../../../../utils/date';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimLeftPeriod = _ref => {
  let {
    endDate
  } = _ref;
  return _jsxs(Typography, {
    variant: "body2",
    children: ["\u0414\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0434\u043E ", moment(endDate).locale('ru').format(EDateFormat.Human)]
  });
};
export const TradeOfferClaimLeftPeriodAdapter = () => {
  const {
    tradeOffer
  } = useTradeOfferClaimContext();
  const endDate = tradeOffer ? moment(tradeOffer.endDate).endOf('day') : null;
  const leftSeconds = endDate ? getLeftSeconds(endDate.endOf('day')) : null;
  const isActiveOfferByTime = leftSeconds && leftSeconds > 0;
  return useMemo(() => tradeOffer?.endDate && tradeOffer?.status === EOfferStatus.Active && isActiveOfferByTime ? _jsx(TradeOfferClaimLeftPeriod, {
    endDate: tradeOffer.endDate
  }) : null, [tradeOffer?.endDate, tradeOffer?.status, isActiveOfferByTime]);
};