import { Fragment } from 'react';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const HeaderOptions = _ref => {
  let {
    options
  } = _ref;
  return _jsx(_Fragment, {
    children: options.map(option => _jsx(Fragment, {
      children: option.component
    }, option.type))
  });
};
export default HeaderOptions;