import axios from 'axios';
import { createCancelToken } from '../utils';
const cart = {
  getCart: _ref => {
    let {
      signal
    } = _ref;
    return {
      url: `/customers/current/basket`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  getCartItem: _ref2 => {
    let {
      signal,
      id
    } = _ref2;
    return {
      url: `/customers/current/basket/items/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  deleteCartItem: _ref3 => {
    let {
      signal,
      id
    } = _ref3;
    return {
      url: `/customers/current/basket/items/${id}`,
      method: 'DELETE',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  updateCartItem: _ref4 => {
    let {
      signal,
      cartItemId,
      productId,
      quantity
    } = _ref4;
    return {
      url: `/customers/current/basket/items/${cartItemId}`,
      method: 'PUT',
      data: {
        offer: productId,
        quantity
      },
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  addToCart: _ref5 => {
    let {
      signal,
      quantity,
      productId
    } = _ref5;
    return {
      url: `/customers/current/basket/items`,
      method: 'POST',
      data: {
        quantity,
        offer: productId
      },
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default cart;