import { Typography } from '@mui/material';
import { getTradeOfferPromotionLabel } from '../../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PromotionTypeText = _ref => {
  let {
    promotionType
  } = _ref;
  return _jsx(Typography, {
    variant: "body2",
    color: "black",
    children: getTradeOfferPromotionLabel(promotionType)
  });
};
export default PromotionTypeText;