/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { createRequest } from './http-client';
export const BenefitTransactionsApi = {
  /**
   * @description Получение списка своих транзакций в АИС Льготах
   * * @tags BenefitTransactions
   * @name GetUserBenefitTransactions
   * @request GET:/users/current/transactions
   * @secure
   */
  getUserBenefitTransactions: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/users/current/transactions`,
    method: 'GET',
    query: query,
    signal
  })
};