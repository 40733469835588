import { useGetCorpOfferCategoriesQuery } from 'data/api/corpOffer';
import { useGetTradeOfferCategoriesQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { ECmsBannerLinkObjectType } from 'domain/model/enums';
import { useEffect } from 'react';
export const useGetCmsCategoryBannerList = banners => {
  const corpOfferBanners = banners.filter(banner => banner.linkObjectType === ECmsBannerLinkObjectType.CorpOfferCategory);
  const tradeOfferBanners = banners.filter(banner => banner.linkObjectType === ECmsBannerLinkObjectType.TradeOfferCategory);
  const {
    data: corpOffersCategories = [],
    error: corpOfferError
  } = useGetCorpOfferCategoriesQuery({
    ids: corpOfferBanners.map(banner => banner.linkObjectId)
  }, {
    skip: !corpOfferBanners.length
  });
  const {
    data: tradeOffersCategories = [],
    error: tradeOfferError
  } = useGetTradeOfferCategoriesQuery({
    ids: tradeOfferBanners.map(banner => banner.linkObjectId)
  }, {
    skip: !tradeOfferBanners.length
  });
  useEffect(() => {
    if (corpOfferError) {
      ErrorHandler.handleHttpError(corpOfferError);
    }
    if (tradeOfferError) {
      ErrorHandler.handleHttpError(tradeOfferError);
    }
  }, [tradeOfferError, corpOfferError]);
  const getCategoryName = (categories, bannerId) => {
    const category = categories.find(item => item.id === bannerId);
    return category?.name ?? '';
  };
  const payload = [...tradeOfferBanners.map(banner => ({
    name: getCategoryName(tradeOffersCategories, banner.linkObjectId),
    ...banner
  })), ...corpOfferBanners.map(banner => ({
    name: getCategoryName(corpOffersCategories, banner.linkObjectId),
    ...banner
  }))];
  return payload;
};