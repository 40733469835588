import TradeOfferBreadcrumbs from '../../components/breadcrumbs';
import useTradeOfferCategoriesData from '../hooks/useTradeOfferCategoriesData';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferBreadcrumbAdapter = props => {
  const {
    id
  } = props;
  const {
    categories
  } = useTradeOfferCategoriesData();
  const {
    tradeOffer
  } = useTradeOfferDetailsData(id);
  return _jsx(TradeOfferBreadcrumbs, {
    offerCategories: tradeOffer?.categories ?? null,
    dictionary: categories ?? null
  });
};
export default TradeOfferBreadcrumbAdapter;