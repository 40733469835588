import { Fade, Hidden, Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButtonsGroup } from 'presentation/theme/ui-kit/button/group';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import { useSelector } from 'react-redux';
import { productOfferListIsNewSearchFetchingSelector } from '../../store/selectors';
import { SelectWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOffersSearchHeader = props => {
  const {
    category,
    count,
    name,
    categories,
    sortSelectComponent,
    filterComponent,
    onChangeCategory
  } = props;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    x1
  } = useGridSpacing();
  const isNewSearchFetching = useSelector(productOfferListIsNewSearchFetchingSelector);
  const CategoryName = () => {
    if (categories.length === 0) {
      return null;
    }
    return _jsxs(MPGrid, {
      container: true,
      spacing: x1,
      alignItems: "center",
      children: [_jsx(MPGrid, {
        item: true,
        zero: 12,
        sm: 6,
        children: _jsx(Typography, {
          variant: "h3",
          component: "h2",
          children: categories[0].name
        })
      }), _jsxs(MPGrid, {
        item: true,
        zero: 12,
        sm: 6,
        children: [_jsx(Hidden, {
          smUp: true,
          children: _jsx(Splitter, {
            size: 1.5
          })
        }), _jsxs(MPGrid, {
          container: true,
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: isSmDown ? 'space-between' : 'flex-end',
          spacing: 2,
          children: [_jsx(MPGrid, {
            item: true,
            children: sortSelectComponent
          }), _jsx(Hidden, {
            mdUp: true,
            children: _jsx(MPGrid, {
              item: true,
              children: filterComponent
            })
          })]
        })]
      })]
    });
  };
  const CategoryList = () => {
    if (isNewSearchFetching) {
      return null;
    }
    return _jsxs(MPGrid, {
      container: true,
      spacing: x1,
      justifyContent: "space-between",
      alignItems: "center",
      children: [_jsxs(MPGrid, {
        item: true,
        children: [_jsxs(Typography, {
          variant: "h3",
          component: "h2",
          children: [count === 1 ? 'Найден ' : 'Найдено ', " ", count, ' ', pluralize(count, ['результат', 'результата', 'результатов']), categories.length > 0 && ` в ${categories.length} ${pluralize(categories.length, ['категории', 'категориях', 'категориях'])}`]
        }), _jsx(Splitter, {
          size: 1
        })]
      }), _jsxs(SelectWrapper, {
        item: true,
        children: [_jsx(Hidden, {
          smUp: true,
          children: _jsx(Splitter, {
            size: 1
          })
        }), sortSelectComponent]
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(MPButtonsGroup, {
          color: "secondary",
          exclusive: true,
          items: categories,
          selected: category ? [category] : categories,
          onChange: _ref => {
            let [value] = _ref;
            return onChangeCategory(value);
          }
        })
      })]
    });
  };
  return _jsx(Fade, {
    in: true,
    children: _jsx("div", {
      children: !name ? _jsx(CategoryName, {}) : _jsx(CategoryList, {})
    })
  });
};
export default ProductOffersSearchHeader;