import { createTheme } from '@mui/material';
import overrideTheme from './override';
import breakpoints from './override/breakpoints';
const baseTheme = createTheme({
  breakpoints
});
export const theme = overrideTheme(baseTheme);
export const createAppTheme = externalTheme => {
  if (externalTheme) {
    return overrideTheme(baseTheme, externalTheme);
  }
  return theme;
};
console.debug('original theme', theme);