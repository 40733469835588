import { CloseFab } from './controls';
import { CloseIcon } from '../../../../../../media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Close = _ref => {
  let {
    onClick
  } = _ref;
  return _jsx(CloseFab, {
    size: "medium",
    variant: "circular",
    onClick: onClick,
    children: _jsx(CloseIcon, {})
  });
};
export default Close;