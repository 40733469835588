import axios from 'axios';
import { EOfferType, EPaymentType } from 'domain/model/enums';
import { createCancelToken } from '../utils';
const search = {
  offers: props => {
    const {
      query,
      offerTypes,
      page,
      pageSize,
      sort,
      signal
    } = props;
    const params = new URLSearchParams({
      page: (page - 1).toString(10),
      size: pageSize.toString(10)
    });
    if (query) {
      params.append('q', query);
    }
    if (sort) {
      sort.forEach(item => params.append('sort', item));
    }

    /**
     * дальше логика по подготовке offerType и paymentType
     * offerType типа Asp только клиентский, для него реальный фильтр нужен Trade + продаваемый за рубли АСП
     * offerType типа Trade предполагает фильтр по paymentType бесплатный, иначе сюда АСП тоже войдут
     */
    const finalOfferTypes = [];
    const finalPaymentTypes = [];
    if (offerTypes?.length === 1) {
      switch (offerTypes[0]) {
        case EOfferType.Trade:
          finalOfferTypes.push(offerTypes[0]);
          finalPaymentTypes.push(EPaymentType.Free);
          break;
        case EOfferType.Asp:
          finalOfferTypes.push(EOfferType.Trade);
          finalPaymentTypes.push(EPaymentType.AspRzdRub);
          break;
        case EOfferType.Booking:
        case EOfferType.Corp:
        case EOfferType.Product:
          finalOfferTypes.push(offerTypes[0]);
          break;
      }
    } else {
      finalOfferTypes.push(...(offerTypes ?? []));
    }
    if (finalOfferTypes) {
      finalOfferTypes.forEach(item => params.append('offerType', item));
    }
    if (finalPaymentTypes) {
      finalPaymentTypes.forEach(item => params.append('paymentType', item));
    }
    return {
      url: `/customers/current/offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default search;