import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'provide',
  initialState: {},
  reducers: {
    favoritesProvideClear: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      delete state[guid];
    },
    favoritesProvideChangeProcess: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        guid,
        process
      } = payload;
      if (!state[guid]) {
        state[guid] = {
          processes: []
        };
      }
      const sessionState = state[guid];
      if (sessionState) {
        const currentProcess = sessionState.processes.find(p => p.id === process.id);
        if (currentProcess) {
          currentProcess.action = process.action;
          currentProcess.id = process.id;
          currentProcess.isFetching = process.isFetching;
          currentProcess.isFetched = process.isFetched;
          currentProcess.isFailed = process.isFailed;
          currentProcess.isCancelled = process.isCancelled;
          currentProcess.offerType = process.offerType;
        } else {
          sessionState.processes.push(process);
        }
      }
    }
  }
});
export const {
  favoritesProvideClear,
  favoritesProvideChangeProcess
} = slice.actions;
export default slice.reducer;