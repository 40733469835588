import { EAddressLevel, EAddressOption } from 'domain/model/enums';
import { uniqBy } from 'lodash';
export class AddressHelper {
  constructor(source, separator) {
    this.address = JSON.parse(JSON.stringify(source));
    this.separator = separator;
    this.optimizeLocality();
  }

  // оптимизация населенного пункта, если нет нас пункта и города то добавляется синтетический город на основе региона
  optimizeLocality() {
    const hasLocalityObjects = this.address.hierarchy?.some(item => item.level.id >= EAddressLevel.Value5 && item.level.id <= EAddressLevel.Value6) ?? false;
    const regionObject = this.address.hierarchy?.find(item => item.level.id === EAddressLevel.Value1);
    if (!hasLocalityObjects && regionObject != null) {
      this.address.hierarchy?.splice(1, 0, {
        ...regionObject,
        level: {
          ...regionObject.level,
          id: EAddressLevel.Value5
        }
      });
    }
  }

  // получение полного адреса по иерархии
  getFullPath(props) {
    return AddressStringBuilder.full(this.address).withSeparator(this.separator).withoutMunicipality().withOptions(...(props?.options ?? [])).toString();
  }

  // получение полного адреса по иерархии с почтовым индексом
  getFullPathWithPostalCode() {
    return this.getFullPath({
      options: [EAddressOption.PostalCode]
    });
  }

  // получение наименования населенного пункта начиная с региона
  getLocalityFullPath() {
    return AddressStringBuilder.fromRegionToSettlement(this.address).withSeparator(this.separator).withoutMunicipality().toString();
  }

  // получение наименования населенного пункта без региона и образований
  getLocalityShortPath() {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).toString();
  }

  // получение наименования населенного пункта без региона и образований и без города/района (если это посёлок)
  // отличается от getLocalityFullPath тем, что в locality входит всё из ГОРОД+НАС_ПУНКТ, а адреса очень сложные, и вдруг будет и то и то, в данном случае учитывается последний элемент
  getLastLocalityShortPath() {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toString();
  }

  // получение наименования населенного пункта без региона и образований и без имени типа
  getLocalitySimpleName() {
    return AddressStringBuilder.locality(this.address).withSeparator(this.separator).lastLevel().toSimpleString();
  }

  // получение наименования улицы начиная с региона
  getStreetFullPath() {
    return AddressStringBuilder.fromRegionToStreet(this.address).withSeparator(this.separator).withoutMunicipality().toString();
  }
}
export class AddressStringBuilder {
  defaultSeparator = ', ';
  separator = this.defaultSeparator;
  options = [];
  // получение иерархии по уровню [С, ПО]
  static getHierarchyObjects(address, fromLevel, toLevel) {
    //получаем объекты иерархии адреса
    let hierarchyObjects = address.hierarchy?.filter(item => item.level.id >= fromLevel && item.level.id <= toLevel)?.filter(item => !!item) ?? [];
    //сортируем по уровню для правильного порядка
    hierarchyObjects = hierarchyObjects.sort((o1, o2) => o1.level.id - o2.level.id);
    //убираем дублирующие id, это может быть к примеру для городов федерального значения
    return uniqBy(hierarchyObjects, 'id');
  }

  // полный адрес
  static full(address) {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Value1, EAddressLevel.Value17);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до посёлка
  static fromRegionToSettlement(address) {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Value1, EAddressLevel.Value6);
    return new AddressStringBuilder(address, objects);
  }

  // от региона до улицы
  static fromRegionToStreet(address) {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Value1, EAddressLevel.Value8);
    return new AddressStringBuilder(address, objects);
  }

  // населенный пункт (от города до посёлка)
  static locality(address) {
    const objects = AddressStringBuilder.getHierarchyObjects(address, EAddressLevel.Value5, EAddressLevel.Value6);
    return new AddressStringBuilder(address, objects);
  }

  // получение адресных "опций" - отдельные специфические элементы из всей структуры адреса
  static parseOptions(address) {
    const options = {};
    if (address.postalCode) {
      options[EAddressOption.PostalCode] = address.postalCode;
    }
    return options;
  }
  constructor(address, objects) {
    this.source = [...objects];
    this.sourceOptions = AddressStringBuilder.parseOptions(address);
    this.objects = objects;
    this.defaultString = address.shortName || address.name || '';
  }

  // добавление опции - почтовый индекс
  withPostalCode() {
    return this.withOptions(EAddressOption.PostalCode);
  }

  // изменение доп опций
  withOptions() {
    for (var _len = arguments.length, options = new Array(_len), _key = 0; _key < _len; _key++) {
      options[_key] = arguments[_key];
    }
    options.map(option => {
      const value = this.sourceOptions[option];
      if (value) {
        this.options.push(value);
      }
    });
    return this;
  }

  // изменение сепаратора для вывода в строку
  withSeparator(separator) {
    this.separator = separator || this.defaultSeparator;
    return this;
  }

  // восстановление дефолтных настроек
  restore() {
    this.objects = [...this.source];
    this.options = [];
    this.separator = this.defaultSeparator;
    return this;
  }

  // конвертация в строку
  toString() {
    let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      shortName: true
    };
    return props.shortName ? this.toShortString() : this.toFullString();
  }

  // конвертация в строку с короткими наименованиями
  toShortString() {
    const strings = this.objects.map(item => item.shortName ?? item.name ?? null);
    const commonString = strings.join(this.separator) || this.defaultString;
    return [...this.options, commonString].join(this.separator);
  }

  // конвертация в строку с длинными наименованиями
  toFullString() {
    const strings = this.objects.map(item => item.name ?? item.shortName ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // конвертация в строку с короткими наименованиями (без имен типов)
  toSimpleString() {
    const strings = this.objects.map(item => item.values?.[0]?.value ?? null);
    return [...this.options, ...strings].join(this.separator) || this.defaultString;
  }

  // исключение уровней
  withoutLevels() {
    for (var _len2 = arguments.length, levels = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      levels[_key2] = arguments[_key2];
    }
    this.objects = this.objects.filter(item => item?.level && !levels.includes(item.level.id)) ?? [];
    return this;
  }

  // исключение муниципалитетов
  withoutMunicipality() {
    return this.withoutLevels(EAddressLevel.Value3);
  }

  // оставляем односложное наименовании в текущей иерархии (ищем начиная с последней до первой, останавливаемся на том где есть shortName)
  lastLevel() {
    const lastLevel = this.objects.reverse().find(item => !!item.shortName || !!item.name);
    this.objects = lastLevel ? [lastLevel] : [];
    return this;
  }
}