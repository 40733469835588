import { Link } from '@mui/material';
import { MPButton } from '../../../theme/ui-kit/button';
import CorpLinkProxy from '../links/proxy';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerLink = props => {
  const {
    link,
    onClick
  } = props;
  return _jsx(CorpLinkProxy, {
    link: link ?? '',
    children: linkByProxy => _jsx(MPButton, {
      href: linkByProxy,
      component: Link,
      target: "_blank",
      color: "brand",
      fullWidth: true,
      onClick: onClick,
      children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0441\u0430\u0439\u0442 \u043F\u0430\u0440\u0442\u043D\u0451\u0440\u0430"
    })
  });
};
export default PartnerLink;