import axios from 'axios';
import { EOfferActivationStatus } from 'domain/model/enums';
import { createCancelToken, getBaseEndpoint, getUserQueryParams } from './utils';
export const activationStatuses = [EOfferActivationStatus.Pending, EOfferActivationStatus.Rejected, EOfferActivationStatus.Approved, EOfferActivationStatus.Given];

/**
 * АПИ по работе с активациями предложений
 */
const activation = {
  all: props => {
    const {
      signal,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    activationStatuses.forEach(status => params.append('status', status));
    return {
      url: `/customers/current/activations`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  byOfferId: props => {
    const {
      signal,
      offerId,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    params.append('offerIds', offerId);
    activationStatuses.forEach(status => params.append('status', status));
    return {
      url: `/customers/current/activations`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  count: props => {
    return activation.all({
      ...props,
      page: 1,
      pageSize: 1
    });
  },
  one: props => {
    const {
      id,
      signal
    } = props;
    return {
      url: `/customers/current/activations/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  create: props => {
    const {
      offerId
    } = props;
    return {
      url: `/customers/current/activations`,
      method: 'POST',
      data: {
        offerId
      }
    };
  },
  certificateDownloadUrl: _ref => {
    let {
      activationId
    } = _ref;
    return `${getBaseEndpoint()}/customers/current/activations/${activationId}/cert`;
  }
};
export default activation;