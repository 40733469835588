import { useState } from 'react';
import { MailIcon } from 'media/icons';
import { ChangeEmailContainer } from 'features/auth/changeEmail/container';
import UserProfileViewAttribute from '../../../components/attributeView';
import { MPChip } from 'theme/ui-kit/chip';
import { createEvent as confirmEmailEvent } from 'features/user/events/confirmEmail';
import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const label = 'Электронная почта';
export const UserProfileEmailAdapter = _ref => {
  let {
    user
  } = _ref;
  const {
    publish
  } = useSystemEventBus();
  const [editMode, setEditMode] = useState(false);
  const hideEdit = () => setEditMode(false);
  const showEdit = () => setEditMode(true);
  const onConfirm = () => {
    publish(confirmEmailEvent({}));
  };
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: label,
      value: user.email,
      icon: _jsx(MailIcon, {
        color: "secondary"
      }),
      info: !user.emailVerified && _jsx(MPChip, {
        variant: "filled",
        color: "warning",
        label: "\u041F\u043E\u0447\u0442\u0430 \u043D\u0435 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0430",
        size: "micro"
      }),
      actionText: user.emailVerified ? 'Изменить' : 'Подтвердить',
      onAction: user.emailVerified ? showEdit : onConfirm
    }), editMode && _jsx(ChangeEmailContainer, {
      onSuccess: hideEdit,
      onClose: hideEdit
    })]
  });
};