import { useMediaQuery } from '@mui/material';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import ContentLoader from 'presentation/components/common/loader';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import CategoryCatalogBackButton from '../components/backButton';
import CategorySelectBranch from '../components/selectBranch';
import CategorySelectLayout from './layout';
import { useCategorySelect } from './useCategorySelect';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = CategorySelectLayout;
const hasTouchscreen = 'ontouchstart' in window;
const CategorySelectRootContainer = props => {
  const {
    tree,
    selected,
    isFetching,
    onSelect,
    showSelected,
    children
  } = props;
  const overlayScrollbarRef = useRef();
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const hasChildren = useMemo(() => tree?.some(_ref => {
    let {
      children
    } = _ref;
    return children.length > 0;
  }), [tree]);
  const {
    expanded,
    expandedPath,
    expandedFirstLevel,
    setExpanded,
    selectedCategory,
    isExpanded
  } = useCategorySelect({
    tree,
    selected,
    showSelected
  });
  const showChildren = category => {
    if (category.children?.length) {
      const expandedPathIndex = expandedPath?.findIndex(item => item.id === category.id) ?? -1;
      if (expandedPathIndex !== 0) {
        setExpanded(category);
      }
    } else {
      setExpanded(null);
    }
  };
  const toggleChildren = category => {
    if (category.children?.length === 0) {
      onSelect(category);
    } else {
      showChildren(category);
    }
  };
  const backTo = category => {
    let newExpanded;
    const expandedPathIndex = expandedPath?.findIndex(item => item.id === category.id) ?? -1;
    switch (expandedPathIndex) {
      //нажали на возврат на первом уровне
      case 0:
        newExpanded = null;
        break;
      //нажали на возврат на уровне более первого
      default:
        newExpanded = expandedPath?.[expandedPathIndex - 1] ?? null;
        break;
    }
    setExpanded(newExpanded);
  };
  const scrollToCategory = useCallback(el => {
    const overlayInstance = overlayScrollbarRef.current?.osInstance();
    if (el) {
      overlayInstance?.scroll({
        y: el.offsetTop - 50
      }, 100);
    } else {
      overlayInstance?.scroll({
        y: 0
      }, 100);
    }
  }, [overlayScrollbarRef]);

  //сброс прокрутки при переходе в другую категорию
  useEffect(() => {
    scrollToCategory(null);
  }, [expanded, scrollToCategory]);
  const firstLevelChildren = expandedFirstLevel?.children ?? null;

  //в мобилке на экран выводим состав выбранной категории, иначе основное дерево
  const rootTree = isSmDown && expanded ? expanded.children : tree;
  return _jsx(Layout, {
    hasContainerDivider: hasChildren,
    scrollbarRef: overlayScrollbarRef,
    backButton: expanded && _jsx(CategoryCatalogBackButton, {
      text: expanded.name,
      onClick: () => backTo(expanded)
    }),
    firstLevel: firstLevelChildren && children({
      childTree: firstLevelChildren,
      selectedCategory,
      expanded,
      setExpanded,
      isExpanded
    }),
    loader: isFetching && _jsx(ContentLoader, {
      position: "fixed"
    }),
    children: rootTree?.map(category => _jsx(CategorySelectBranch, {
      category: category,
      selected: isExpanded(category),
      onClick: !hasTouchscreen ? () => onSelect(category) : () => toggleChildren(category),
      onMouseEnter: !hasTouchscreen && (() => showChildren(category))
    }, category.id))
  });
};
export default CategorySelectRootContainer;