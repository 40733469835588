import useBookingOfferCategoriesData from './useBookingOfferCategoriesData';
import useBookingOfferDetailsData from './useBookingOfferDetailsData';
const useBookingOfferDetailsLoadingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesLoading
  } = useBookingOfferCategoriesData();
  const {
    isBookingOfferLoading
  } = useBookingOfferDetailsData(id);
  return isCategoriesLoading || isBookingOfferLoading;
};
export default useBookingOfferDetailsLoadingStatus;