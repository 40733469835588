import { useEffect } from 'react';
//разово фиксирует показ элемента, разовость обеспечена атрибутом sessionKey
const useIsVisibleOnce = (ref, options, active, data, onShow) => {
  useEffect(() => {
    if (!active || !onShow) {
      return;
    }
    const observer = new IntersectionObserver(_ref => {
      let [entry] = _ref;
      if (entry.isIntersecting) {
        onShow(data);
        observer.unobserve(ref.current);
        observer.disconnect();
      }
    }, options);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, options, data, active, onShow]);
};
export default useIsVisibleOnce;