import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useHistory } from 'react-router';
import { getUserOrdersRoute } from '../../../../user/entry';
import { getBookingOffersListRoute } from '../../routes';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferOrderButtons = () => {
  const history = useHistory();
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    flexDirection: "column",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsx(MPButton, {
        fullWidth: true,
        size: "large",
        color: "brand",
        onClick: () => history.push(getBookingOffersListRoute()),
        children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u043E\u0431\u044A\u0435\u043A\u0442\u044B \u043E\u0442\u0434\u044B\u0445\u0430"
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(MPButton, {
        fullWidth: true,
        size: "large",
        variant: "text",
        color: "brand",
        onClick: () => history.push(getUserOrdersRoute({})),
        children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0437\u0430\u043A\u0430\u0437\u0430\u043C"
      })
    })]
  });
};
export default BookingOfferOrderButtons;