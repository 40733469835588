import { parseEnv } from 'utils/env';

/**
 * Класс для конфигурации Веб аналитики
 * Такой как Яндекс метрика, гугл аналитика и т.д.
 */
export class WebAnalyticsConfigurator {
  constructor() {}
  options = (() => WebAnalyticsConfigurator.init())();
  static getInstance() {
    if (!WebAnalyticsConfigurator.instance) {
      WebAnalyticsConfigurator.instance = new WebAnalyticsConfigurator();
    }
    return WebAnalyticsConfigurator.instance;
  }
  static loadEnv() {
    const isTestMode = process.env.NODE_ENV === 'test';
    if (isTestMode) {
      return null;
    }
    const request = new XMLHttpRequest();
    const staticConfigUrl = '/config/analytics.web.env';
    let staticOptions = null;
    try {
      request.open('GET', staticConfigUrl, false);
      request.send();
      if (request.status === 200) {
        staticOptions = parseEnv(request.responseText);
        console.log('analytics web static options', staticOptions);
      }
      if (!staticOptions || !Object.keys(staticOptions).length) {
        console.log(`Not found analytics web static config '${staticConfigUrl}'`);
        return null;
      }
      return {
        ...staticOptions
      };
    } catch (e) {
      console.log(`error connect '${staticConfigUrl}'`);
      return null;
    }
  }
  static init() {
    const env = WebAnalyticsConfigurator.loadEnv();
    return {
      debug: (env?.debug || 'false') === 'true',
      yandex: {
        enabled: (env?.yaEnabled || 'false') === 'true',
        id: env?.yaId ?? '',
        clickmap: (env?.yaClickmap || 'false') === 'true',
        webvisor: (env?.yaWebvisor || 'false') === 'true',
        goals: {
          partnerOpenUrlId: env?.yaGoalPartnerOpenUrlId ?? '',
          tradeOfferActivateId: env?.yaGoalTradeOfferActivateId ?? '',
          tradeOfferReactivateId: env?.yaGoalTradeOfferReactivateId ?? '',
          tradeOfferActivateByCopyId: env?.yaGoalTradeOfferActivateByCopyId ?? ''
        }
      },
      selfHosted: {
        enabled: (env?.shEnabled || 'false') === 'true',
        goals: {
          offerViewInSearchId: env?.shGoalOfferViewInSearchId ?? '',
          offerViewInListId: env?.shGoalOfferViewInListId ?? '',
          offerViewId: env?.shGoalOfferViewId ?? '',
          offerShowInSearchId: env?.shGoalOfferShowInSearchId ?? '',
          offerShowInListId: env?.shGoalOfferShowInListId ?? '',
          offerShowId: env?.shGoalOfferShowId ?? '',
          offerReceiveClickedId: env?.shGoalOfferReceiveClickedId ?? '',
          offerTakeActivationId: env?.shGoalOfferTakeActivationId ?? '',
          offerAddToFavorites: env?.shGoalObjectAddToFavoritesId ?? '',
          jumpToPartnerSiteId: env?.shJumpToPartnerSiteId ?? ''
        },
        buffer: {
          timeout: parseInt(env?.shBufferTimeout?.trim() || '3000'),
          goals: env?.shBufferGoals?.trim()?.split(',') ?? []
        }
      }
    };
  }
  getOptions() {
    return this.options;
  }
  isDebugMode() {
    return this.options.debug;
  }
  getYandexConfig() {
    return this.options.yandex;
  }
  getSelfHostedConfig() {
    return this.options.selfHosted;
  }
  getGoals() {
    return {
      ...this.getYandexConfig().goals,
      ...this.getSelfHostedConfig().goals
    };
  }
}