import TradeOfferIframe from 'presentation/features/offer/trade/components/iframe';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimWidgetAdapter = props => {
  const {
    wrapperRef: widgetWrapperRef,
    srcDoc
  } = props;
  return _jsx(TradeOfferIframe, {
    wrapperRef: widgetWrapperRef,
    srcDoc: srcDoc
  });
};