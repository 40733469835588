import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'sectionLinks',
  initialState: {
    visible: false
  },
  reducers: {
    sectionLinksSetVisible: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.visible = payload;
    }
  }
});
export const {
  sectionLinksSetVisible
} = slice.actions;
export default slice.reducer;