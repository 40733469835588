import axios from 'axios';
import { createCancelToken } from './utils';
import { getUserQueryParams } from './utils';
/**
 * АПИ по работе с компаниями
 */
const partner = {
  desk: {
    one: _ref => {
      let {
        id
      } = _ref;
      return {
        url: `/partner-desks/${id}`,
        method: 'GET'
      };
    },
    all: props => {
      const {
        name,
        signal,
        ...queryParams
      } = props;
      const params = getUserQueryParams(queryParams);
      if (name?.length) {
        name.forEach(n => {
          params.append('partnerName', n);
        });
      }
      return {
        url: `/customers/current/partner-desks`,
        method: 'GET',
        params,
        cancelToken: signal && createCancelToken(axios, signal)
      };
    },
    collection: props => {
      const {
        id,
        signal
      } = props;
      return {
        url: `/customers/current/partner-desks/collections/${id}`,
        method: 'GET',
        cancelToken: signal && createCancelToken(axios, signal)
      };
    }
  },
  page: _ref2 => {
    let {
      partnerId
    } = _ref2;
    const params = new URLSearchParams();
    params.append('partnerId', partnerId);
    return {
      url: `/customers/current/containers`,
      method: 'GET',
      params
    };
  },
  nameMap: () => {
    return {
      url: `/customers/current/partner-desks/name-map`,
      method: 'GET'
    };
  },
  pageTest: _ref3 => {
    let {
      partnerId
    } = _ref3;
    const params = new URLSearchParams();
    params.append('partnerId', partnerId);
    return {
      url: `/customers/current/containers`,
      method: 'GET',
      params
    };
  }
};
export default partner;