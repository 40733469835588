import { useGetUserFavoritesTradeOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { useEffect } from 'react';
import OfferListItemContainer from '../../../../offer/listItem/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFavoritesTradeOffersContainer = _ref => {
  let {
    guid,
    queryGuid
  } = _ref;
  const {
    data,
    error: tradeOffersError
  } = useGetUserFavoritesTradeOffersQuery({
    guid: queryGuid
  });
  const tradeOffers = data?.data;
  useEffect(() => {
    if (tradeOffersError) {
      ErrorHandler.handleHttpError(tradeOffersError);
    }
  }, [tradeOffersError]);
  if (!tradeOffers) {
    return null;
  }
  return _jsx(CardList, {
    children: tradeOffers.map(offer => _jsx(OfferListItemContainer, {
      offer: offer,
      favorite: {
        guid,
        stored: true /*чтобы не скрывался таб после убирания всех сердечек*/
      }
    }, offer.id))
  });
};