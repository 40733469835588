import { SocketContext } from '@mp-npm/mp-stomp-client';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ENotificationSocketHeader } from './types';
const useNotificationSocket = _ref => {
  let {
    onMessageReceive
  } = _ref;
  const [guid] = useState(uuidv4());
  const {
    user
  } = useAuthUser();
  const topic = `/user/${user?.id}/notification`;
  const {
    isConnected,
    onSubscribe,
    onUnsubscribe
  } = useContext(SocketContext);
  const onMessageReceiveInternal = useCallback((msg, header) => {
    const type = header[ENotificationSocketHeader.MessageType];
    onMessageReceive({
      ...msg,
      type
    }, type);
  }, [onMessageReceive]);
  useEffect(() => {
    if (isConnected) {
      onSubscribe(guid, topic, onMessageReceiveInternal);
      return () => {
        onUnsubscribe(guid, topic);
      };
    }
  }, [guid, topic, onMessageReceiveInternal, isConnected, onSubscribe, onUnsubscribe]);
};
export default useNotificationSocket;