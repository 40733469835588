import { Typography } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OrderStub = props => {
  const {
    alignCenter = false
  } = props;
  return _jsx(Typography, {
    width: "100%",
    textAlign: alignCenter ? 'center' : 'left',
    color: "textSecondary",
    children: "\u0417\u0434\u0435\u0441\u044C \u0431\u0443\u0434\u0435\u0442 \u0445\u0440\u0430\u043D\u0438\u0442\u044C\u0441\u044F \u0438\u0441\u0442\u043E\u0440\u0438\u044F \u0432\u0430\u0448\u0438\u0445 \u0437\u0430\u043A\u0430\u0437\u043E\u0432"
  });
};
export default OrderStub;