import { defaultNetworkErrorStubText } from 'presentation/utils/constants';
import useRenderManagerError from './hooks/useRenderManagerError';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CommonRenderManager = props => {
  const {
    error
  } = useRenderManagerError();
  const {
    loader,
    stub: StubComponent,
    children
  } = props;
  if (error) {
    return _jsx(StubComponent, {
      text: defaultNetworkErrorStubText
    });
  }
  return _jsxs(_Fragment, {
    children: [children, loader]
  });
};
export default CommonRenderManager;