import { Typography } from '@mui/material';
import ClaimMessage from 'presentation/features/offer/components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimRejectedWarnMessage = () => {
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u041E\u0442\u043A\u0430\u0437"
    })
  });
};