import { getFileDownloadEndpoint } from 'data/openApi/utils';
import profileStub from 'presentation/media/images/profileStub.png';
import { MPAvatar } from 'presentation/theme/ui-kit/avatar';
import { getAvatarNameAbbreviation } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserAvatar = _ref => {
  let {
    user,
    size,
    variant
  } = _ref;
  const fullName = [user.lastName, user.firstName].filter(item => !!item).join(' ');
  const url = user.photo?.path ? getFileDownloadEndpoint({
    id: user.photo.path,
    private: true
  }) : profileStub;
  const content = !url && (getAvatarNameAbbreviation(fullName) ?? 'NA');
  return _jsx(MPAvatar, {
    size: size,
    variant: variant,
    description: fullName,
    url: url,
    children: content
  });
};