import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getPersonFullName } from 'presentation/utils';
import { HeaderUserShort } from '../short';
import { Email, Text } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderUserFull = props => {
  const {
    user,
    isFetching
  } = props;
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    wrap: "nowrap",
    children: [_jsx(MPGrid, {
      item: true,
      xs: 2.5,
      children: _jsx(HeaderUserShort, {
        size: "small",
        user: user,
        isFetching: isFetching
      })
    }), _jsxs(MPGrid, {
      item: true,
      xs: 9.5,
      zero: true,
      children: [_jsx(Text, {
        children: getPersonFullName(user)
      }), _jsx(Splitter, {
        size: 0.5
      }), _jsx(Email, {
        color: "text.secondary",
        variant: "body2",
        component: "div",
        title: user.email,
        children: user.email
      })]
    })]
  });
};