import BusinessConfigProvider from './providers/businessConfigProdiver';
import TechConfigProvider from './providers/techConfigProdiver';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AppConfiguration = _ref => {
  let {
    children
  } = _ref;
  return _jsx(TechConfigProvider, {
    children: _jsx(BusinessConfigProvider, {
      children: children
    })
  });
};
export default AppConfiguration;