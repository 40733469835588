import { Typography, useMediaQuery } from '@mui/material';
import OfferClaimLayout from '../../../../../layouts/offerClaimLayout';
import ClaimContent from '../claim/content';
import useBookingSidebarSlots from './slots';
import BookingSidebarSmartPhone from './smartPhone';
import { calcTotalPrice } from './utils';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingSidebar = _ref => {
  let {
    offerId,
    cart,
    ...other
  } = _ref;
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const totalPrice = calcTotalPrice(cart);
  const slots = useBookingSidebarSlots({
    cart,
    totalPrice,
    ...other
  });
  return _jsxs(_Fragment, {
    children: [_jsx(OfferClaimLayout, {
      content: isMdDown ? null : _jsx(ClaimContent, {
        children: slots
      }),
      skirt: _jsxs(Typography, {
        variant: "body2",
        children: ["\u041E\u0431\u044A\u0435\u043A\u0442: ", offerId]
      }, 'id')
    }), isMdDown && _jsx(BookingSidebarSmartPhone, {
      slots: slots,
      cart: cart,
      totalPrice: totalPrice
    })]
  });
};
export default BookingSidebar;