import { Dialog, Typography, useMediaQuery } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../components/common/icon';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import { CloseButton, ContentWrapper, Drawer, DrawerContainer } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ModalContentWrapper = _ref => {
  let {
    children,
    isOpen,
    onClose
  } = _ref;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    headerHeight
  } = useHeaderParams();
  return isSmDown ? _jsx(Drawer, {
    variant: "temporary",
    elevation: 0,
    disableAutoFocus: true,
    disableEnforceFocus: true,
    anchor: "right",
    open: isOpen,
    offsetTop: headerHeight,
    onClose: onClose,
    onOpen: () => null,
    children: _jsx(DrawerContainer, {
      children: children
    })
  }) : _jsx(Dialog, {
    open: isOpen,
    onClose: onClose,
    children: _jsxs(ContentWrapper, {
      children: [_jsx(Typography, {
        variant: "h2",
        children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437?"
      }), _jsx(CloseButton, {
        onClick: onClose,
        children: _jsx(AppSvgIcon, {
          icon: CloseIcon
        })
      }), children]
    })
  });
};