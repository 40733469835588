import { Badge } from '@mui/material';
import { FilterIcon } from 'presentation/media/icons';
import { MPButton } from 'presentation/theme/ui-kit/button';
import AppSvgIcon from '../../icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DataFilterButton = _ref => {
  let {
    count,
    onClick
  } = _ref;
  return _jsx(MPButton, {
    size: "small",
    color: "white",
    endIcon: _jsx(Badge, {
      color: "brand",
      badgeContent: count,
      invisible: count === 0,
      children: _jsx(AppSvgIcon, {
        icon: FilterIcon,
        color: "black"
      })
    }),
    onClick: onClick,
    children: "\u0424\u0438\u043B\u044C\u0442\u0440"
  });
};
export default DataFilterButton;