const backdrops = () => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'rgba(0, 0, 0, 0.30)',
        backdropFilter: 'blur(2.5px)'
      },
      invisible: {
        background: 'inherit',
        backdropFilter: 'none'
      }
    }
  }
});
export default backdrops;