import useCorpOfferCategoriesData from './useCorpOfferCategoriesData';
import useCorpOfferDetailsData from './useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from './useCorpOfferPartnerDeskData';
const useCorpOfferDetailsLoadingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesLoading
  } = useCorpOfferCategoriesData();
  const {
    isCorpOfferLoading
  } = useCorpOfferDetailsData(id);
  const {
    isPartnerDeskLoading
  } = useCorpOfferPartnerDeskData(id);
  return isCategoriesLoading || isCorpOfferLoading || isPartnerDeskLoading;
};
export default useCorpOfferDetailsLoadingStatus;