import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import OfferDetailsLayout from 'presentation/layouts/offerDetail';
import { AspOfferBreadcrumbAdapter, AspOfferCarouselAdapter, AspOfferCloseAdapter, AspOfferConditionsAdapter, AspOfferDetailsFavoriteAdapter, AspOfferDetailsLoaderAdapter, AspOfferDetailsSidebarAdapter, AspOfferDetailsTabsAdapter, AspOfferDetailsTitleAdapter, AspOfferDetailStub, AspOfferPartnerInfoAdapter, SmartPhoneFixedBarAdapter } from './adapters';
import { AspOfferDetailContext } from './context';
import useAspOfferDetails from './hooks/useAspOfferDetails';
import useAspOfferDetailsLoadingStatus from './hooks/useAspOfferDetailsLoadingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = OfferDetailsLayout;
const AspOfferDetailsContainer = _ref => {
  let {
    id,
    guid,
    tab,
    from
  } = _ref;
  const handlers = useAspOfferDetails({
    id,
    guid,
    from
  });
  return _jsx(AspOfferDetailContext.Provider, {
    value: handlers,
    children: _jsx(RenderManagerErrorProvider, {
      children: _jsx(CommonRenderManager, {
        loader: _jsx(AspOfferDetailsLoaderAdapter, {
          id: id
        }),
        stub: AspOfferDetailStub,
        children: _jsx(Layout, {
          id: id,
          favorite: _jsx(AspOfferDetailsFavoriteAdapter, {
            id: id,
            guid: guid
          }),
          description: _jsx(AspOfferConditionsAdapter, {
            id: id
          }),
          sidebar: _jsx(AspOfferDetailsSidebarAdapter, {
            guid: guid,
            id: id
          }),
          smartphoneFixedBar: _jsx(SmartPhoneFixedBarAdapter, {
            guid: guid,
            id: id
          }),
          tabs: _jsx(AspOfferDetailsTabsAdapter, {
            guid: guid,
            tab: tab,
            id: id
          }),
          partnerInfo: _jsx(AspOfferPartnerInfoAdapter, {
            id: id
          }),
          title: _jsx(AspOfferDetailsTitleAdapter, {
            id: id
          }),
          breadcrumb: _jsx(AspOfferBreadcrumbAdapter, {
            id: id
          }),
          image: _jsx(AspOfferCarouselAdapter, {
            id: id
          }),
          close: _jsx(AspOfferCloseAdapter, {})
          /*related={<AspOfferDetailsRelatedAdapter id={id} />} сделано на будущее, пока отключено*/,
          useLoading: useAspOfferDetailsLoadingStatus
        })
      })
    })
  });
};
export default AspOfferDetailsContainer;