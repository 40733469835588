import { AddressHelper } from 'utils/address';
import { MPAutocompleteSingleSelect } from '../../../../theme/ui-kit/autocomplete/single';
import useAddressSelector from '../useAddressSelector';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionLabelDefault = address => new AddressHelper(address).getFullPath();
const filterOptionsDefault = options => options;
const AddressSingleSelector = props => {
  const {
    query,
    error,
    disabled,
    helperText,
    count,
    onlyValidPostalCode,
    label,
    level,
    fromLevel,
    toLevel,
    value,
    noOptionsText,
    locations,
    filterOptions = filterOptionsDefault,
    filterBy,
    sortBy,
    groupBy,
    onChange,
    getOptionLabel = getOptionLabelDefault,
    ...others
  } = props;
  const {
    suggestions,
    isLoading,
    onSearch
  } = useAddressSelector(props);
  return _jsx(MPAutocompleteSingleSelect, {
    ...others,
    inputValue: query,
    value: value,
    options: suggestions,
    label: label,
    noOptionsText: noOptionsText,
    isLoading: isLoading,
    error: error,
    disabled: disabled,
    helperText: helperText,
    filterOptions: filterOptions,
    groupBy: groupBy?.(suggestions),
    getOptionLabel: getOptionLabel,
    onSearchValue: onSearch,
    onChangeValue: onChange
  });
};
export default AddressSingleSelector;