import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import CorpOfferListContainer from 'presentation/features/offer/corp/list/container';
import { ECorpOfferUrlParam } from 'presentation/features/offer/corp/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { ETradeOfferUrlParam } from '../../features/offer/trade/utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOffersScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ECorpOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(ETradeOfferUrlParam.PartnerId) ?? null;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(CorpOfferListContainer, {
        name: name,
        categoryId: categoryId,
        partnerId: partnerId,
        guid: guid
      })
    })
  });
};
export default CorpOffersScreen;