import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPartnerDesksLoadingSelector, getPartnersNameMapLoadingSelector } from '../../filter/store/selectors';
import usePartnerDesksPageableArgsInRedux from '../hooks/usePartnerDesksPageableArgsInRedux';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskListLoaderAdapter = _ref => {
  let {
    guid
  } = _ref;
  const argsStorage = usePartnerDesksPageableArgsInRedux(guid);
  const isPartnerDesksLoading = useSelector(getPartnerDesksLoadingSelector(argsStorage.currentState));
  const isPartnersNameMapLoading = useSelector(getPartnersNameMapLoadingSelector);
  return useMemo(() => isPartnerDesksLoading || isPartnersNameMapLoading ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null, [isPartnerDesksLoading, isPartnersNameMapLoading]);
};
export default PartnerDeskListLoaderAdapter;