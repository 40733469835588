import { useFixedFooterParams } from 'presentation/hooks/useFixedFooterParams';
import { useHeaderParams } from 'presentation/hooks/useHeaderParams';
import { generateAsideStyles } from 'presentation/layouts/offerList/utils';
import { useCallback, useEffect, useState } from 'react';
const OfferListLayoutAsideBehavior = _ref => {
  let {
    page,
    content,
    element
  } = _ref;
  const pageCurrent = page.current;
  const contentCurrent = content.current;
  const current = element.current;
  const {
    headerHeight
  } = useHeaderParams();
  const {
    footerHeight
  } = useFixedFooterParams();
  const [tick, setTick] = useState(0);
  const listen = useCallback(initialTop => {
    if (current && contentCurrent) {
      const parent = current.parentElement;
      if (parent && current.offsetWidth) {
        parent.style.width = `${current.offsetWidth}px`;
        parent.style.minHeight = `${current.offsetHeight}px`;
      }
      const styles = generateAsideStyles({
        initialTop,
        asideElement: current,
        contentElement: contentCurrent,
        headerHeight,
        footerHeight
      });
      if (styles) {
        Object.keys(styles).forEach(key => {
          if (styles[key]) {
            current.style[key] = styles[key];
          }
        });
      }
    }
  }, [contentCurrent, current, footerHeight, headerHeight]);
  useEffect(() => {
    const observer = new MutationObserver(mutationsList => {
      const validMutations = mutationsList.filter(m => m.target?.className?.indexOf('TouchRipple') === -1);
      if (validMutations.length) {
        setTick(prev => prev + 1);
      }
    });
    if (pageCurrent) {
      observer.observe(pageCurrent, {
        childList: true,
        subtree: true
      });
      return () => observer.disconnect();
    }
  }, [pageCurrent]);
  useEffect(() => {
    if (current) {
      const initialTop = current.getBoundingClientRect().top;
      const internalListen = () => listen(initialTop);
      document.addEventListener('scroll', internalListen, {
        passive: false
      });
      document.addEventListener('resize', internalListen, {
        passive: false
      });
      internalListen();
      return () => {
        document.removeEventListener('scroll', internalListen);
        document.removeEventListener('resize', internalListen);
      };
    }
    // чтобы не ругался на tick
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, headerHeight, listen, tick]);
  useEffect(() => {
    //сбрасываем параметры парента на случай анмаунта
    return () => {
      const parent = current?.parentElement;
      if (parent) {
        parent.style.width = 'inherit';
        parent.style.minHeight = 'inherit';
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current?.parentElement, tick]);
  return null;
};
export default OfferListLayoutAsideBehavior;