import { useSystemEvents } from '../hooks';
import { SystemEventsComponentsFlowAdapter } from './componentsFlow';
import { SystemEventsComponentsSwipeFlowAdapter } from './componentsSwipeFlow';
import { SystemEventsFeatureFlowAdapter } from './featureFlow';
import { SystemEventsComponentFlowAdapter } from 'features/systemEvent/adapters/componentFlow';
import { SystemEventsComponentSwipeFlowAdapter } from 'features/systemEvent/adapters/componentSwipeFlow';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const componentSwipeEnabled = true;
const componentsSwipeEnabled = true;
export const SystemEventsActiveFlowAdapter = () => {
  const {
    activeFlow
  } = useSystemEvents();
  if (!activeFlow) {
    return null;
  }

  //попробуем составить набор событий на базе единого компонента, если они есть, то рисуем адаптер со swiper-ом для красоты
  if (componentSwipeEnabled) {
    const componentFlow = [];
    for (const e of activeFlow.events) {
      if (!e.component) {
        break;
      }
      componentFlow.push(e);
    }
    if (componentFlow.length > 0 && activeFlow.activeIndex <= componentFlow.length - 1) {
      return _jsx(SystemEventsComponentSwipeFlowAdapter, {
        activeIndex: activeFlow.activeIndex,
        events: componentFlow
      });
    }
  }

  //попробуем составить набор событий на базе сборных компонентов, если они есть, то рисуем адаптер со swiper-ом для красоты
  if (componentsSwipeEnabled) {
    const componentsFlow = [];
    for (const e of activeFlow.events) {
      if (e.feature || e.component) {
        break;
      }
      componentsFlow.push(e);
    }
    if (componentsFlow.length > 0 && activeFlow.activeIndex <= componentsFlow.length - 1) {
      return _jsx(SystemEventsComponentsSwipeFlowAdapter, {
        activeIndex: activeFlow.activeIndex,
        events: componentsFlow
      });
    }
  }

  //иначе рисуем отдельный адаптер исходя из типа
  const event = activeFlow.events?.[activeFlow.activeIndex];
  return event?.feature ? _jsx(SystemEventsFeatureFlowAdapter, {
    event: event
  }) : event?.component ? _jsx(SystemEventsComponentFlowAdapter, {
    event: event
  }) : _jsx(SystemEventsComponentsFlowAdapter, {
    event: event
  });
};