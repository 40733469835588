import { useGetPartnerAspOfferCategoriesUsedQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferStatus } from 'domain/model/enums';
import PartnerDeskOffersCategories from 'presentation/features/partnerDesk/details/tabs/categories';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAspOffersContextHandlers } from '../../../hooks/useAspOffersContextHandlers';
import { partnerDeskDetailsAspOffersCommonArgsSelector } from '../../../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const statuses = [EOfferStatus.Active, EOfferStatus.Upcoming];
const PartnerDeskAspOffersCategoriesAdapter = () => {
  const {
    onChangeAspOffersCategory
  } = useAspOffersContextHandlers();
  const {
    partnerId,
    categoryId
  } = useSelector(partnerDeskDetailsAspOffersCommonArgsSelector);
  const {
    data,
    error
  } = useGetPartnerAspOfferCategoriesUsedQuery({
    partnerId,
    statuses
  });
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  return data ? _jsx(PartnerDeskOffersCategories, {
    categoryId: categoryId ?? null,
    categories: data,
    onChange: onChangeAspOffersCategory
  }) : null;
};
export default PartnerDeskAspOffersCategoriesAdapter;