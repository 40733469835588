import { useHistory } from 'react-router';
import { useAuth } from '../../../auth/provider/useAuth';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import { useWebAnalytics } from '../../../webAnalytics';
import AspOfferClaim from './component';
import AspOfferClaimProviders from './providers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferClaimContainer = props => {
  const {
    aspOffer,
    partnerDesk,
    guid
  } = props;
  const {
    isAuthenticated
  } = useAuth();
  const history = useHistory();
  const {
    webAnalytics
  } = useWebAnalytics();
  if (!aspOffer) {
    return null;
  }
  const onPartnerButtonClick = () => {
    if (aspOffer?.partnerId) {
      webAnalytics.openPartnerUrl(aspOffer?.partnerId);
      webAnalytics.offerJumpToPartnerSite(aspOffer.id);
    }
  };
  const onShowAllOffers = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({
        id: partnerDesk.id,
        tab: EPartnerDeskDetailsTab.AspOffers
      }));
    }
  };
  return _jsx(AspOfferClaimProviders, {
    aspOffer: aspOffer,
    onShowAllOffers: onShowAllOffers,
    isActivationAllowed: isAuthenticated,
    onPartnerButtonClick: onPartnerButtonClick,
    guid: guid,
    children: _jsx(AspOfferClaim, {})
  });
};
export default AspOfferClaimContainer;