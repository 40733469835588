import { useMediaQuery } from '@mui/material';
import DataFilterButton from 'presentation/components/common/dataFilter/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { bookingOfferListNoDataSelector } from '../store/selectors';
import { BookingOfferListMobileFilterAdapter } from './filterMobile';
import { BookingOfferListSortSelectAdapter } from './sortSelect';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BookingOfferListControlAdapter = props => {
  const {
    categoryId,
    filterCount
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const noData = useSelector(bookingOfferListNoDataSelector);
  const [isShowFilter, setShowFilter] = useState(false);
  return _jsxs(MPGrid, {
    container: true,
    spacing: 1,
    justifyContent: "space-between",
    alignItems: "center",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsx(BookingOfferListSortSelectAdapter, {
        ...props
      })
    }), !isMdUp && categoryId && (!noData || !!filterCount) && _jsx(MPGrid, {
      item: true,
      children: _jsx(DataFilterButton, {
        count: filterCount,
        onClick: () => setShowFilter(true)
      })
    }), !isMdUp && isShowFilter && _jsx(BookingOfferListMobileFilterAdapter, {
      filterCount: filterCount,
      onBack: () => setShowFilter(false)
    })]
  });
};