import { FavoriteOutlineIcon } from 'presentation/media/icons';
import { UserMenuItem } from '../../../components/menuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemFavorites = _ref => {
  let {
    count,
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435",
    count: count,
    icon: _jsx(FavoriteOutlineIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};