import { useEffect, useState } from 'react';
import CountInput from '../../../../components/common/countInput';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CartCounter = props => {
  const {
    size,
    count,
    max,
    disabled = false,
    loading = false,
    isEmpty,
    onChange,
    onDecrement,
    onIncrement
  } = props;
  const [stayOpened, setStayOpened] = useState(false);
  const [localCount, setLocalCount] = useState(count);
  useEffect(() => {
    setLocalCount(count);
  }, [count]);
  const handleIncrement = () => {
    onIncrement();
  };
  const handleDecrement = () => {
    if (isEmpty) {
      return;
    }
    onDecrement();
  };
  const handleChangeCount = value => {
    setLocalCount(value);
  };
  const onBlur = () => {
    if (localCount !== count) {
      onChange(localCount);
    }
    setStayOpened(false);
  };
  const onPressEnter = e => {
    if (e.key === 'Enter' && localCount !== count) {
      onChange(localCount);
    }
  };
  return _jsx(CountInput, {
    min: 0,
    max: max,
    size: size,
    disabled: disabled,
    loading: loading,
    value: count,
    isEmpty: isEmpty,
    stayOpened: stayOpened,
    onKeyPress: onPressEnter,
    onIncrement: handleIncrement,
    onDecrement: handleDecrement,
    onBlur: onBlur,
    onChangeCount: handleChangeCount
  });
};
export default CartCounter;