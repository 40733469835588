import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { headerCatalogDialogVisibleSelector } from '../../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CancelAdapter = () => {
  const handlers = useContextHandlers();
  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  return catalogVisible && _jsx(Typography, {
    color: "primary",
    onClick: handlers.hideCatalog,
    children: "\u041E\u0442\u043C\u0435\u043D\u0430"
  });
};
export default CancelAdapter;