import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'details',
  initialState: {
    cart: null,
    modal: null
  },
  reducers: {
    bookingOfferDetailsAddToCart: (state, _ref) => {
      let {
        payload: {
          offerId,
          items
        }
      } = _ref;
      if (!state.cart) {
        state.cart = {};
      }
      state.cart[offerId] = items;
    },
    bookingOfferDetailsClearCart: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      if (!state.cart || !state.cart[payload]) {
        return;
      }
      state.cart[payload] = null;
    },
    bookingOfferDetailsClearCarts: state => {
      state.cart = null;
    },
    bookingOfferDetailsSetModal: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.modal = payload;
    }
  }
});
export const {
  bookingOfferDetailsAddToCart,
  bookingOfferDetailsClearCart,
  bookingOfferDetailsClearCarts,
  bookingOfferDetailsSetModal
} = slice.actions;
export default slice.reducer;