import useAspOfferDetailsData from '../../hooks/useAspOfferDetailsData';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { CarouselWrapper } from './controls';
import { aspOfferTag, offerImageAspectRatio } from '../../../../utils';
import OfferTags from '../../../../components/tags';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AspOfferCarouselAdapter = props => {
  const {
    id
  } = props;
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };
  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  const images = aspOffer?.images ?? [];
  const thumbChildren = images.map((image, i) => _jsx(AppImage, {
    onClick: () => {},
    src: image.path
  }, i));
  const handleChangeActiveIndex = useCallback(index => {
    if (activeSlide !== index) {
      setActiveSlide(index);
    }
  }, [activeSlide]);
  const thumbSwiperProps = useMemo(() => ({
    slidesPerView: 4,
    spaceBetween: 10,
    onClick: () => {},
    loop: true
  }), []);
  const mainSwiperProps = useMemo(() => ({
    pagination: !isSmUp,
    navigation: false,
    spaceBetween: 10,
    onClick: onMediaClick,
    loop: true
  }), [isSmUp]);
  const tags = useMemo(() => [...(aspOffer?.tags ?? []), aspOfferTag], [aspOffer?.tags]);
  if (!aspOffer?.images?.length) {
    return null;
  }
  return _jsxs(CarouselWrapper, {
    children: [_jsx(CarouselGallery, {
      swipersGap: 10,
      thumbSwiperContainerHeight: 102,
      thumbChildren: thumbChildren,
      showThumbs: true,
      onActiveIndexChange: handleChangeActiveIndex,
      thumbSwiperProps: thumbSwiperProps,
      mainSwiperProps: mainSwiperProps,
      aspectRatio: offerImageAspectRatio,
      defaultCarouselOverlay: tags && _jsx(OfferTags, {
        tags: tags
      }),
      children: images.map((image, i) => _jsx(AppImage, {
        onClick: () => {},
        src: image.path
      }, i))
    }), _jsx(MediaPreviewDialog, {
      imageLinks: images,
      initSlide: activeSlide,
      open: previewDialogOpen,
      onClose: onCloseDialog
    })]
  });
};