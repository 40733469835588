import TradeOfferClaimProvider from './provider/provider';
import TradeOfferClaimDialogsProvider from './dialogProvider/provider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimProviders = props => {
  const {
    guid,
    children,
    tradeOffer,
    isActivationAllowed,
    onShowAllOffers,
    onPartnerButtonClick
  } = props;
  return _jsx(TradeOfferClaimProvider, {
    id: tradeOffer.id,
    guid: guid,
    isActivationAllowed: isActivationAllowed,
    promotionType: tradeOffer.promotionType,
    onPartnerButtonClick: onPartnerButtonClick,
    children: _jsx(TradeOfferClaimDialogsProvider, {
      tradeOffer: tradeOffer,
      onShowAllOffers: onShowAllOffers,
      children: children
    })
  });
};
export default TradeOfferClaimProviders;