import { Typography } from '@mui/material';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferDetailsTitleAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    tradeOffer
  } = useTradeOfferDetailsData(id);
  if (!tradeOffer) {
    return null;
  }
  return _jsx(Typography, {
    variant: "h1",
    children: tradeOffer.name
  });
};
export default TradeOfferDetailsTitleAdapter;