import { useMediaQuery } from '@mui/material';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import CategorySelectDoubleLayout from 'presentation/features/category/select/double/layout';
import { useCallback, useEffect, useRef } from 'react';
import CategorySelectDouble from '../../components/selectDouble';
import { categoryArrayToColumns } from '../../utils';
import { ColumnWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = CategorySelectDoubleLayout;

//вывод категорий дуплексом - родитель и под ним все дети, причем с возможностью свернуть/развернуть детей
const CategorySelectDoubleContainer = props => {
  const {
    categories,
    selectedCategory,
    expanded,
    isSelectedOrExpanded,
    onSelect
  } = props;
  const overlayScrollbarRef = useRef();
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const scrollToCategory = useCallback(el => {
    const overlayInstance = overlayScrollbarRef.current?.osInstance();
    if (el) {
      overlayInstance?.scroll({
        y: el.offsetTop - 50
      }, 100);
    } else {
      overlayInstance?.scroll({
        y: 0
      }, 100);
    }
  }, [overlayScrollbarRef]);

  //сброс прокрутки при переходе в другую категорию
  useEffect(() => {
    scrollToCategory(null);
  }, [expanded, scrollToCategory]);
  let columnsCount = 4;
  if (isLgDown) {
    columnsCount = 3;
  }
  if (isMdDown) {
    columnsCount = 2;
  }
  if (isSmDown) {
    columnsCount = 1;
  }
  const categoriesByColumns = categories ? categoryArrayToColumns(categories, columnsCount) : null;
  return _jsx(Layout, {
    children: categoriesByColumns?.map((children, index) => _jsx(ColumnWrapper, {
      children: children.map(child => _jsx(CategorySelectDouble, {
        category: child,
        selected: selectedCategory,
        isInSelectedPath: isSelectedOrExpanded,
        initialVisibleCount: 4,
        onClick: onSelect,
        scrollToCategory: scrollToCategory
      }, child.id))
    }, index))
  });
};
export default CategorySelectDoubleContainer;