import ScreenLayout from 'presentation/layouts/screen';
import { EBalanceType } from 'domain/model';
import RequiredGuidInLocationContainer from 'features/general/locationGuid/container';
import UserFinancesEntryContainer from 'features/user/details/finances/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserBonusesFinancesScreen = () => {
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(UserFinancesEntryContainer, {
        guid: guid,
        tab: EBalanceType.Bonuses
      })
    })
  });
};
export default UserBonusesFinancesScreen;