import { useGetUserFavoritesQuery } from 'data/api/user';
const useFavoritesCounter = () => {
  const {
    data,
    isLoading
  } = useGetUserFavoritesQuery();
  return {
    isLoading,
    count: data?.totalCount ?? 0
  };
};
export default useFavoritesCounter;