import { useLocation } from 'react-router';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserFavoritesContainer from 'presentation/features/user/details/favorites/container';
import { EUserFavoritesTab, EUserFavoritesUrlParam } from 'presentation/features/user/details/favorites/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserFavoritesScreen = () => {
  const {
    isCorpUser
  } = useAuthUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get(EUserFavoritesUrlParam.Tab) ?? EUserFavoritesTab.ProductOffers;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(UserFavoritesContainer, {
        guid: guid,
        tab: tab,
        isCorpUser: isCorpUser
      })
    })
  });
};
export default UserFavoritesScreen;