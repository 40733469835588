import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPPhoneInput } from 'presentation/theme/ui-kit/input';
import { isValidPhoneFormat } from 'presentation/utils/phone';
import { useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import ContentLoader from '../../../components/common/loader';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NoPhoneText = _ref => {
  let {
    phone
  } = _ref;
  return _jsxs(_Fragment, {
    children: ["\u0412\u0430\u0448 \u043D\u043E\u043C\u0435\u0440", ' ', _jsx(Typography, {
      display: "inline",
      variant: "subtitle1",
      children: phone
    }), ' ', "\u0432\u0432\u0435\u0434\u0435\u043D \u0432 \u043D\u0435\u0432\u0435\u0440\u043D\u043E\u043C \u0444\u043E\u0440\u043C\u0430\u0442\u0435.", _jsx(Typography, {
      children: "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430 \u0437\u0430\u043D\u043E\u0432\u043E."
    })]
  });
};
export const InvalidPhoneText = () => {
  return 'Введите ваш номер телефона, чтобы менеджер перезвонил для уточнения деталей по заказу';
};
export const IsPhoneNotExistsDialog = _ref2 => {
  let {
    phone,
    isFetching,
    onSubmit,
    onCancel
  } = _ref2;
  const [localPhone, setLocalPhone] = useState('');
  const [error, setError] = useState(null);
  const isValidFormat = phone && isValidPhoneFormat(phone);
  const onChangeInternal = value => {
    setError(null);
    setLocalPhone(value);
  };
  const onSubmitInternal = () => {
    const isValidPhone = localPhone && isValidPhoneFormat(localPhone);
    if (isValidPhone) {
      onSubmit(localPhone);
      setError(null);
    } else {
      setError('Введите корректный номер телефона');
    }
  };
  return _jsxs(MPConfirmDialog, {
    title: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
    open: true,
    disableEscapeKeyDown: true,
    maxWidth: 'xs',
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsxs(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onSubmitInternal,
        children: ["\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
      }), _jsx(MPButton, {
        color: "brand",
        variant: "text",
        fullWidth: true,
        size: "large",
        disabled: isFetching,
        onClick: onCancel,
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    onClose: onCancel,
    children: [_jsxs(Typography, {
      color: "textPrimary",
      variant: "body1",
      children: [!phone && _jsx(NoPhoneText, {
        phone: phone
      }), !isValidFormat && _jsx(InvalidPhoneText, {})]
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(MPPhoneInput, {
      value: localPhone ?? '',
      helperText: error,
      error: !!error,
      disabled: isFetching,
      onChange: onChangeInternal
    })]
  });
};