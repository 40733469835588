import CartDetailsContainer from 'presentation/features/cart/details/container';
import ScreenLayout from 'presentation/layouts/screen';
import { v4 as uuidv4 } from 'uuid';

//гуид корзины постоянный, чтобы она была типа стейтфулл по своему состоянию
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const guid = uuidv4();
const CartDetailsScreen = () => {
  return _jsx(ScreenLayout, {
    footer: true,
    children: _jsx(CartDetailsContainer, {
      guid: guid
    })
  });
};
export default CartDetailsScreen;