import store from 'data/store/store';
import { EOfferListType, EOfferStatus } from 'domain/model/enums';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useUserLocalSettings from '../../../../hooks/useUserLocalSettings';
import { partnerDeskDetailsStateSelector } from '../store/selectors';
import { partnerDeskAspOffersSetArgs, partnerDeskAspOffersSetIsNewFetching, partnerDeskAspOffersSetPage, partnerDeskAspOffersSetPageSize, partnerDeskAspOffersSetSort, partnerDeskBookingOffersSetArgs, partnerDeskBookingOffersSetIsNewFetching, partnerDeskBookingOffersSetPage, partnerDeskBookingOffersSetPageSize, partnerDeskBookingOffersSetSort, partnerDeskCorpOffersSetArgs, partnerDeskCorpOffersSetIsNewFetching, partnerDeskCorpOffersSetPage, partnerDeskCorpOffersSetPageSize, partnerDeskCorpOffersSetSort, partnerDeskProductOffersSetArgs, partnerDeskProductOffersSetIsNewFetching, partnerDeskProductOffersSetPage, partnerDeskProductOffersSetPageSize, partnerDeskProductOffersSetSort, partnerDeskStartSession, partnerDeskTradeOffersSetArgs, partnerDeskTradeOffersSetIsNewFetching, partnerDeskTradeOffersSetPage, partnerDeskTradeOffersSetPageSize, partnerDeskTradeOffersSetSort } from '../store/slice';
import { EPartnerDeskDetailsTab, getPartnerOffersListQueryParams, partnerDeskOffersListEmptyParams } from '../utils';
const emptyOffersParams = partnerDeskOffersListEmptyParams;
const usePartnerDeskDetailsHandlers = props => {
  const {
    guid,
    partnerId,
    categoryId
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    webAnalytics
  } = useWebAnalytics();
  const {
    city
  } = useUserLocalSettings().settings;
  const getCurrentState = useCallback(() => {
    return partnerDeskDetailsStateSelector(store.getState());
  }, []);
  const onChangeTab = useCallback(tab => {
    history.replace(getPartnerDeskDetailsRoute({
      guid,
      id: partnerId,
      tab
    }));
  }, [history, guid, partnerId]);

  // corp offers
  const onChangeCorpOffersSort = useCallback(sort => {
    dispatch(partnerDeskCorpOffersSetSort({
      type: EOfferListType.Common,
      sort
    }));
    dispatch(partnerDeskCorpOffersSetSort({
      type: EOfferListType.Upcoming,
      sort
    }));
  }, [dispatch]);
  const onChangeCorpOffersPageSize = useCallback((type, pageSize) => {
    dispatch(partnerDeskCorpOffersSetPageSize({
      type,
      pageSize
    }));
  }, [dispatch]);
  const onChangeCorpOffersPage = useCallback((type, page) => {
    dispatch(partnerDeskCorpOffersSetPage({
      type,
      page
    }));
  }, [dispatch]);
  const onChangeCorpOffersCategory = useCallback(category => {
    history.replace(getPartnerDeskDetailsRoute({
      guid,
      id: partnerId,
      categoryId: category?.id,
      tab: EPartnerDeskDetailsTab.CorpOffers
    }));
  }, [guid, partnerId, history]);
  const onShowCorpOffersCard = useCallback(corpOffer => {
    webAnalytics.offerShow(corpOffer.id);
  }, [webAnalytics]);

  // trade offers

  const onChangeTradeOffersSort = useCallback(sort => {
    dispatch(partnerDeskTradeOffersSetSort({
      type: EOfferListType.Common,
      sort
    }));
    dispatch(partnerDeskTradeOffersSetSort({
      type: EOfferListType.Upcoming,
      sort
    }));
  }, [dispatch]);
  const onChangeTradeOffersPageSize = useCallback((type, pageSize) => {
    dispatch(partnerDeskTradeOffersSetPageSize({
      type,
      pageSize
    }));
  }, [dispatch]);
  const onChangeTradeOffersPage = useCallback((type, page) => {
    dispatch(partnerDeskTradeOffersSetPage({
      type,
      page
    }));
  }, [dispatch]);
  const onChangeTradeOffersCategory = useCallback(category => {
    history.replace(getPartnerDeskDetailsRoute({
      guid,
      id: partnerId,
      categoryId: category?.id,
      tab: EPartnerDeskDetailsTab.TradeOffers
    }));
  }, [guid, partnerId, history]);
  const onShowTradeOffersCard = useCallback(tradeOffer => {
    webAnalytics.offerShow(tradeOffer.id);
  }, [webAnalytics]);

  // asp offers

  const onChangeAspOffersSort = useCallback(sort => {
    dispatch(partnerDeskAspOffersSetSort({
      type: EOfferListType.Common,
      sort
    }));
    dispatch(partnerDeskAspOffersSetSort({
      type: EOfferListType.Upcoming,
      sort
    }));
  }, [dispatch]);
  const onChangeAspOffersPageSize = useCallback((type, pageSize) => {
    dispatch(partnerDeskAspOffersSetPageSize({
      type,
      pageSize
    }));
  }, [dispatch]);
  const onChangeAspOffersPage = useCallback((type, page) => {
    dispatch(partnerDeskAspOffersSetPage({
      type,
      page
    }));
  }, [dispatch]);
  const onChangeAspOffersCategory = useCallback(category => {
    history.replace(getPartnerDeskDetailsRoute({
      guid,
      id: partnerId,
      categoryId: category?.id,
      tab: EPartnerDeskDetailsTab.AspOffers
    }));
  }, [guid, partnerId, history]);
  const onShowAspOffersCard = useCallback(aspOffer => {
    webAnalytics.offerShow(aspOffer.id);
  }, [webAnalytics]);

  // product offers

  const onChangeProductOffersSort = useCallback(sort => {
    dispatch(partnerDeskProductOffersSetSort(sort));
  }, [dispatch]);
  const onChangeProductOffersPageSize = useCallback(pageSize => {
    dispatch(partnerDeskProductOffersSetPageSize(pageSize));
  }, [dispatch]);
  const onChangeProductOffersPage = useCallback(page => {
    dispatch(partnerDeskProductOffersSetPage(page));
  }, [dispatch]);

  // booking offers

  const onChangeBookingOffersSort = useCallback(sort => {
    dispatch(partnerDeskBookingOffersSetSort(sort));
  }, [dispatch]);
  const onChangeBookingOffersPageSize = useCallback(pageSize => {
    dispatch(partnerDeskBookingOffersSetPageSize(pageSize));
  }, [dispatch]);
  const onChangeBookingOffersPage = useCallback(page => {
    dispatch(partnerDeskBookingOffersSetPage(page));
  }, [dispatch]);
  const onChangeBookingOffersCategory = useCallback(category => {
    history.replace(getPartnerDeskDetailsRoute({
      guid,
      id: partnerId,
      categoryId: category?.id,
      tab: EPartnerDeskDetailsTab.BookingOffers
    }));
  }, [guid, partnerId, history]);
  const onShowBookingOffersCard = useCallback(bookingOffer => {
    webAnalytics.offerShow(bookingOffer.id);
  }, [webAnalytics]);
  useEffect(() => {
    dispatch(partnerDeskStartSession(guid));
    const state = getCurrentState();
    const currentGuid = state.guid;
    const isGuidChanged = currentGuid !== guid;

    // trade

    let tradeCommonArgs = state.tradeOffers.common;
    let tradeUpcomingArgs = state.tradeOffers.upcoming;
    if (isGuidChanged) {
      tradeCommonArgs = emptyOffersParams;
      tradeUpcomingArgs = emptyOffersParams;
    }
    dispatch(partnerDeskTradeOffersSetArgs({
      type: EOfferListType.Common,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: tradeCommonArgs.sort,
        page: tradeCommonArgs.page,
        pageSize: tradeCommonArgs.pageSize,
        statuses: [EOfferStatus.Active]
      })
    }));
    dispatch(partnerDeskTradeOffersSetArgs({
      type: EOfferListType.Upcoming,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: tradeUpcomingArgs.sort,
        page: tradeUpcomingArgs.page,
        pageSize: tradeUpcomingArgs.pageSize,
        statuses: [EOfferStatus.Upcoming]
      })
    }));

    // trade

    let aspCommonArgs = state.aspOffers.common;
    let aspUpcomingArgs = state.aspOffers.upcoming;
    if (isGuidChanged) {
      aspCommonArgs = emptyOffersParams;
      aspUpcomingArgs = emptyOffersParams;
    }
    dispatch(partnerDeskAspOffersSetArgs({
      type: EOfferListType.Common,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: aspCommonArgs.sort,
        page: aspCommonArgs.page,
        pageSize: aspCommonArgs.pageSize,
        statuses: [EOfferStatus.Active]
      })
    }));
    dispatch(partnerDeskAspOffersSetArgs({
      type: EOfferListType.Upcoming,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: aspUpcomingArgs.sort,
        page: aspUpcomingArgs.page,
        pageSize: aspUpcomingArgs.pageSize,
        statuses: [EOfferStatus.Upcoming]
      })
    }));

    // corp

    let corpCommonArgs = state.corpOffers.common;
    let corpUpcomingArgs = state.corpOffers.upcoming;
    if (isGuidChanged) {
      corpCommonArgs = emptyOffersParams;
      corpUpcomingArgs = emptyOffersParams;
    }
    dispatch(partnerDeskCorpOffersSetArgs({
      type: EOfferListType.Common,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: corpCommonArgs.sort,
        page: corpCommonArgs.page,
        pageSize: corpCommonArgs.pageSize,
        statuses: [EOfferStatus.Active]
      })
    }));
    dispatch(partnerDeskCorpOffersSetArgs({
      type: EOfferListType.Upcoming,
      args: getPartnerOffersListQueryParams({
        partnerId,
        categoryId,
        sort: corpUpcomingArgs.sort,
        page: corpUpcomingArgs.page,
        pageSize: corpUpcomingArgs.pageSize,
        statuses: [EOfferStatus.Upcoming]
      })
    }));

    // product

    let productArgs = state.productOffers.common;
    if (isGuidChanged) {
      productArgs = emptyOffersParams;
    }
    dispatch(partnerDeskProductOffersSetArgs(getPartnerOffersListQueryParams({
      partnerId,
      sort: productArgs.sort,
      page: productArgs.page,
      pageSize: productArgs.pageSize
    })));

    // booking

    let bookingArgs = state.bookingOffers.list;
    if (isGuidChanged) {
      bookingArgs = emptyOffersParams;
    }
    dispatch(partnerDeskBookingOffersSetArgs(getPartnerOffersListQueryParams({
      ...bookingArgs,
      partnerId,
      categoryId
    })));
    if (isGuidChanged) {
      dispatch(partnerDeskTradeOffersSetIsNewFetching(true));
      dispatch(partnerDeskAspOffersSetIsNewFetching(true));
      dispatch(partnerDeskCorpOffersSetIsNewFetching(true));
      dispatch(partnerDeskProductOffersSetIsNewFetching(true));
      dispatch(partnerDeskBookingOffersSetIsNewFetching(true));
    } else {
      dispatch(partnerDeskTradeOffersSetIsNewFetching(false));
      dispatch(partnerDeskAspOffersSetIsNewFetching(false));
      dispatch(partnerDeskCorpOffersSetIsNewFetching(false));
      dispatch(partnerDeskProductOffersSetIsNewFetching(false));
      dispatch(partnerDeskBookingOffersSetIsNewFetching(false));
    }
  }, [dispatch, guid, partnerId, categoryId, city?.name, getCurrentState]);
  return {
    onChangeTab,
    onChangeCorpOffersSort,
    onChangeCorpOffersPageSize,
    onChangeCorpOffersPage,
    onChangeCorpOffersCategory,
    onShowCorpOffersCard,
    onChangeTradeOffersSort,
    onChangeTradeOffersPageSize,
    onChangeTradeOffersPage,
    onChangeTradeOffersCategory,
    onShowTradeOffersCard,
    onChangeAspOffersSort,
    onChangeAspOffersPageSize,
    onChangeAspOffersPage,
    onChangeAspOffersCategory,
    onShowAspOffersCard,
    onChangeProductOffersSort,
    onChangeProductOffersPageSize,
    onChangeProductOffersPage,
    onChangeBookingOffersSort,
    onChangeBookingOffersPageSize,
    onChangeBookingOffersPage,
    onChangeBookingOffersCategory,
    onShowBookingOffersCard
  };
};
export default usePartnerDeskDetailsHandlers;