import { Typography } from '@mui/material';
import { nbsp } from 'presentation/utils/constants';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const FooterCopyRight = () => {
  return _jsxs(Typography, {
    color: "textSecondary",
    variant: "caption",
    children: ["\xA9 2021 \u2013 ", new Date().getFullYear(), " \u041E\u0410\u041E", nbsp, "\xAB\u0420\u0416\u0414\xBB. \u0412\u0441\u0435 \u043F\u0440\u0430\u0432\u0430 \u0437\u0430\u0449\u0438\u0449\u0435\u043D\u044B."]
  });
};
export default FooterCopyRight;