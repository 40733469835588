import { Hidden, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EAppCategories } from 'presentation/types';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const ProductOffersCommonHeader = _ref => {
  let {
    sortSelectComponent
  } = _ref;
  const {
    x5
  } = useGridSpacing();
  return _jsxs(_Fragment, {
    children: [_jsxs(MPGrid, {
      container: true,
      alignItems: "center",
      children: [_jsx(MPGrid, {
        item: true,
        zero: 12,
        sm: true,
        children: _jsx(Typography, {
          variant: "h2",
          component: "h1",
          children: EAppCategories.ProductOffers
        })
      }), _jsxs(MPGrid, {
        item: true,
        children: [_jsx(Hidden, {
          smUp: true,
          children: _jsx(Splitter, {
            size: 1.5
          })
        }), sortSelectComponent]
      })]
    }), _jsx(Splitter, {
      size: x5
    })]
  });
};