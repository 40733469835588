import { EOfferStatus } from 'domain/model/enums';
import { WarnMessage } from 'presentation/components/common/messages';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import CartProviderContainer from '../../../cart/cartProvider/container';
import CartCounter from '../../../cart/components/counter';
import { getCartRootRoute } from '../../../cart/entry';
import ProductOfferAddToCartButton from '../components/addToCartButton';
import ProductOfferOutOfStockCartButton from '../components/outOfStockCartButton';
import { ControlsWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferCartCounterContainer = props => {
  const {
    product,
    stretch,
    size = 'small',
    label
  } = props;
  const history = useHistory();
  const {
    stock,
    status
  } = product;
  const isOutOfStock = stock === 0 || status === EOfferStatus.OutOfStock;
  const onOpenCart = useCallback(() => history.push(getCartRootRoute()), [history]);
  const onClickPrevent = e => {
    e.preventDefault();
  };
  const getSizeCounter = () => {
    switch (size) {
      case 'small':
        return 'micro';
      case 'medium':
        return 'small';
      case 'large':
        return 'medium';
    }
  };
  return _jsx(CartProviderContainer, {
    product: product,
    children: _ref => {
      let {
        cartItem,
        isItemFetching,
        decrement,
        increment,
        updateItem,
        isOutOfQuantity
      } = _ref;
      const quantity = cartItem?.quantity ?? 0;
      const isEmpty = quantity === 0;
      const onClick = () => {
        if (isItemFetching) {
          return;
        }
        if (quantity) {
          onOpenCart();
        } else {
          increment();
        }
      };
      return _jsxs(Wrapper, {
        onClick: onClickPrevent,
        children: [_jsxs(ControlsWrapper, {
          stretch: stretch,
          children: [isOutOfStock ? _jsx(ProductOfferOutOfStockCartButton, {
            size: size,
            label: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438"
          }) : _jsx(ProductOfferAddToCartButton, {
            isEmpty: isEmpty,
            isFetching: isEmpty && isItemFetching,
            label: label,
            size: size,
            disabled: isEmpty && isItemFetching,
            onClick: onClick
          }), _jsx(CartCounter, {
            count: quantity,
            isEmpty: isEmpty,
            loading: isItemFetching,
            disabled: isItemFetching,
            max: stock ?? 0,
            size: getSizeCounter(),
            onDecrement: decrement,
            onIncrement: increment,
            onChange: count => updateItem(count)
          })]
        }), _jsx(WarnMessage, {
          show: isOutOfQuantity,
          textAlign: "right",
          text: `Есть: ${cartItem?.offer?.stock}`
        })]
      });
    }
  });
};
export default ProductOfferCartCounterContainer;