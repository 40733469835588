import { containerClasses } from '@mui/material';
const container = baseTheme => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        [`:not(.${containerClasses.fixed}):not(.${containerClasses.disableGutters})`]: {
          [baseTheme.breakpoints.up('xs')]: {
            maxWidth: '100%',
            paddingLeft: baseTheme.spacing(1.5),
            paddingRight: baseTheme.spacing(1.5)
          },
          [baseTheme.breakpoints.up('sm')]: {
            paddingLeft: baseTheme.spacing(2),
            paddingRight: baseTheme.spacing(2)
          },
          [baseTheme.breakpoints.up('lg')]: {
            paddingLeft: baseTheme.spacing(4.5),
            paddingRight: baseTheme.spacing(4.5)
          },
          [baseTheme.breakpoints.up('xl')]: {
            maxWidth: '1280px',
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    }
  }
});
export default container;