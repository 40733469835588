import SortSelect from 'presentation/components/common/selects/sort';
import { sortCollectionSplitter } from '../../../../utils/constants';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OffersSortSelect = _ref => {
  let {
    value,
    onChange,
    defaultValue,
    items,
    renderValue
  } = _ref;
  return _jsx(SortSelect, {
    items: items,
    value: value?.join(sortCollectionSplitter) ?? defaultValue,
    renderValue: renderValue ? renderValue : value => items.find(item => item.id === value)?.name,
    onChange: newValue => onChange(newValue.split(sortCollectionSplitter))
  });
};
export default OffersSortSelect;