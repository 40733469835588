import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTradeOffersContextHandlers } from '../../../hooks/useTradeOffersContextHandlers';
import { partnerDeskDetailsGuidSelector, partnerDeskDetailsTradeOffersCommonArgsSelector } from '../../../store/selectors';
import PartnerDeskTradeOffersDataContainer from '../dataContainer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Common;
const PartnerDeskTradeOffersCommonAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsTradeOffersCommonArgsSelector);
  const handlers = useTradeOffersContextHandlers();
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskTradeOffersDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangeTradeOffersPage,
    onShowCard: handlers.onShowTradeOffersCard
  }), [args, currentGuid, guid, handlers.onChangeTradeOffersPage, handlers.onShowTradeOffersCard]);
};
export default PartnerDeskTradeOffersCommonAdapter;