import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { addStateToLocation } from 'routing';
import { v4 as uuidv4 } from 'uuid';
const RequiredGuidInLocationContainer = _ref => {
  let {
    children
  } = _ref;
  const history = useHistory();
  const location = useLocation();
  const guid = location.state?.guid;
  useEffect(() => {
    if (!guid) {
      history.replace(addStateToLocation({
        location,
        state: {
          guid: uuidv4()
        }
      }));
    }
  }, [guid, history, location]);
  if (!guid) {
    console.warn('location.state.guid отсутствует. Убедитесь что при переходе по ссылке генерируется location.guid.state, иначе странице будет перерендерена заново и может вызвать мерцание (Предпупреждение не относится к кейсам, когда на страницу заходят впервые или делают рефереш)');
    return null;
  }
  return children(guid);
};
export default RequiredGuidInLocationContainer;