import { ArrowLeftIcon } from '../../../../media/icons';
import { BackIconWrapper, StyledBackButton, Text } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BackButton = _ref => {
  let {
    label,
    onClick
  } = _ref;
  return _jsx(StyledBackButton, {
    size: "small",
    color: "secondary",
    startIcon: _jsx(BackIconWrapper, {
      variant: "squared",
      component: "div",
      size: "small",
      disableRipple: true,
      children: _jsx(ArrowLeftIcon, {
        color: "inherit"
      })
    }),
    onClick: onClick,
    children: _jsx(Text, {
      children: label
    })
  });
};
export default BackButton;