import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import PartnerDeskListContainer from 'presentation/features/partnerDesk/list/container';
import ScreenLayout from '../../layouts/screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDesksScreen = () => {
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(PartnerDeskListContainer, {
        guid: guid
      })
    })
  });
};
export default PartnerDesksScreen;