import { useMediaQuery } from '@mui/material';
import { BalancesIcon } from 'presentation/media/icons';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { UserBalanceValue } from '../../balanceValue';
import { UserMenuItem } from '../../menuItem';
import { BalancesWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserMenuItemFinances = _ref => {
  let {
    balances,
    onClick
  } = _ref;
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  return _jsxs("div", {
    children: [_jsx(UserMenuItem, {
      label: "\u041C\u043E\u0439 \u0431\u0430\u043B\u0430\u043D\u0441",
      icon: _jsx(BalancesIcon, {
        color: "black"
      }),
      onClick: onClick
    }), isLgDown && _jsx(BalancesWrapper, {
      children: balances && balances.length > 0 && _jsx(MPGrid, {
        container: true,
        spacing: 0.5,
        direction: "column",
        children: balances.map(balance => _jsx(MPGrid, {
          item: true,
          children: _jsx(UserBalanceValue, {
            type: balance.type,
            value: balance.balance
          })
        }, balance.type))
      })
    })]
  });
};