import CitySelectDialog from 'presentation/components/common/dialogs/citySelect';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AddressHelper } from 'utils/address';
import useUserLocalSettings from '../../hooks/useUserLocalSettings';
import { useContextHandlers } from './hooks/useContextHandlers';
import { headerCityChangeDialogVisibleSelector } from './store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderDialogs = () => {
  const {
    setCity
  } = useUserLocalSettings();
  const cityChangeDialogVisible = useSelector(headerCityChangeDialogVisibleSelector);
  const handlers = useContextHandlers();
  const onChangeCity = useCallback(city => {
    setCity(city.id, new AddressHelper(city).getLocalityShortPath());
  }, [setCity]);
  return _jsx(CitySelectDialog, {
    visible: cityChangeDialogVisible,
    onChangeCity: onChangeCity,
    onClose: handlers.hideChangeLocationDialog
  });
};
export default HeaderDialogs;