import axios from 'axios';
import { getBaseEndpoint } from 'data/openApi/utils';
/**
 * АПИ по работе с событиями, инициированные пользователем
 */
const userEvent = {
  byOffersQuery: _ref => {
    let {
      data
    } = _ref;
    return axios.request({
      url: `${getBaseEndpoint()}/offers/events`,
      method: 'POST',
      data: data.map(_ref2 => {
        let {
          id,
          typeCode
        } = _ref2;
        return {
          offer: {
            id
          },
          typeCode
        };
      })
    });
  }
};
export default userEvent;