import { createSlice } from '@reduxjs/toolkit';
const dialogsInitialState = {
  updateUserPhone: false
};
const slice = createSlice({
  name: 'claim',
  initialState: {
    dialogs: dialogsInitialState
  },
  reducers: {
    aspOfferClaimClear: state => {
      state.dialogs = dialogsInitialState;
    },
    aspOfferClaimChangeDialogState: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        name,
        data
      } = payload;
      state.dialogs[name] = data;
    }
  }
});
export const {
  aspOfferClaimClear,
  aspOfferClaimChangeDialogState
} = slice.actions;
export default slice.reducer;