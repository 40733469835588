import axios from 'axios';
import { createCancelToken, getBonusesEndpoint } from '../utils';
import agreement from './agreement';
import aspOffer from './aspOffer';
import bookingOffer from './bookingOffer';
import cart from './cart';
import corpOffer from './corpOffer';
import landing from './landing';
import order from './order';
import productOffer from './productOffer';
import search from './search';
import tradeOffer from './tradeOffer';
/**
 * АПИ по работе с пользователями
 */
const user = {
  balance: _ref => {
    let {
      signal,
      cache = true
    } = _ref;
    const params = new URLSearchParams();
    params.append('cache', cache.toString());
    const baseURL = getBonusesEndpoint();
    return {
      baseURL,
      url: `/users/current/balance`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  current: _ref2 => {
    let {
      signal
    } = _ref2;
    return {
      url: `/users/current/userinfo`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  one: _ref3 => {
    let {
      id,
      signal
    } = _ref3;
    return {
      url: `/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  update: _ref4 => {
    let {
      id,
      data
    } = _ref4;
    return {
      url: `/users/${id}`,
      method: 'PUT',
      data
    };
  },
  updateEmail: _ref5 => {
    let {
      email
    } = _ref5;
    return {
      url: `/users/current/email`,
      method: 'POST',
      email,
      data: {
        email
      }
    };
  },
  sendCode: _ref6 => {
    let {
      code,
      otpId
    } = _ref6;
    return {
      url: `/users/current/otp/${otpId}`,
      method: 'POST',
      code,
      data: {
        code,
        otpId
      }
    };
  },
  changeLocation: _ref7 => {
    let {
      userId,
      location
    } = _ref7;
    return {
      url: `/users/${userId}/location`,
      method: 'PATCH',
      data: {
        location
      }
    };
  },
  resetPassword: _ref8 => {
    let {
      id
    } = _ref8;
    return {
      url: `/users/${id}/password`,
      method: 'PATCH'
    };
  },
  addOfferToFavorites: _ref9 => {
    let {
      id
    } = _ref9;
    return {
      url: `/customers/current/favorites/offers`,
      method: 'POST',
      data: {
        offerId: id
      }
    };
  },
  removeOfferFromFavorites: _ref10 => {
    let {
      id
    } = _ref10;
    return {
      url: `/customers/current/favorites/offers/${id}`,
      method: 'DELETE'
    };
  },
  feedback: _ref11 => {
    let {
      data
    } = _ref11;
    return {
      url: `/feedback`,
      method: 'POST',
      data
    };
  },
  assignCorpRole: () => {
    return {
      url: `/users/current/corpLink`,
      method: 'PATCH'
    };
  },
  banners: props => {
    const {
      localityId,
      place,
      signal
    } = props;
    const params = new URLSearchParams();
    if (localityId) {
      params.append('localityId', localityId);
    }
    if (place) {
      params.append('bannerPlaceCodes', place);
    }
    return {
      url: `/customers/current/banners`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  offerAvailability: _ref12 => {
    let {
      offerId,
      reasons,
      signal
    } = _ref12;
    const params = new URLSearchParams();
    if (reasons) {
      reasons.forEach(reason => params.append('reasons', reason));
    }
    return {
      url: `/customers/current/offers/${offerId}/activation-availability`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  subscribeToOffer: _ref13 => {
    let {
      offerId
    } = _ref13;
    return {
      url: `/customers/current/subscriptions/offers`,
      method: 'POST',
      data: {
        offerId
      }
    };
  },
  unSubscribeFromOffer: _ref14 => {
    let {
      offerId
    } = _ref14;
    return {
      url: `/customers/current/subscriptions/offers/${offerId}`,
      method: 'DELETE'
    };
  },
  favorites: props => {
    const {
      offerType,
      signal
    } = props;
    const params = new URLSearchParams();
    if (offerType) {
      offerType.forEach(t => params.append('offerType', t));
    }
    return {
      url: `/customers/current/offers/favorite`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  product: productOffer,
  trade: tradeOffer,
  booking: bookingOffer,
  corp: corpOffer,
  asp: aspOffer,
  landing,
  cart,
  order,
  agreement,
  search
};
export default user;