import { HelpWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ClaimInfoLayout = _ref => {
  let {
    children,
    help
  } = _ref;
  return _jsxs(_Fragment, {
    children: [help && _jsx(HelpWrapper, {
      children: help
    }), children]
  });
};
export default ClaimInfoLayout;