import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from '../../../theme/ui-kit/dialog';
import { IconWrapper, SuccessFeedBackIcon } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const SuccessFeedBack = props => {
  const {
    open,
    onClose
  } = props;
  return _jsx(MPConfirmDialog, {
    open: open,
    maxWidth: "xs",
    title: _jsxs(Typography, {
      variant: "inherit",
      align: "center",
      children: ["\u0421\u043F\u0430\u0441\u0438\u0431\u043E!", '\u000a', "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u043E"]
    }),
    buttons: _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: onClose,
      children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430 \u0432\u0438\u0442\u0440\u0438\u043D\u0443"
    }),
    onClose: onClose,
    children: _jsx(IconWrapper, {
      children: _jsx(SuccessFeedBackIcon, {
        fontSize: "huge"
      })
    })
  });
};
export default SuccessFeedBack;