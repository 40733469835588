import { Typography } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import moment from 'moment-timezone';
import { EDateFormat } from '../../../../../../../domain/model/formats';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const ActivityEndDateAdapter = () => {
  const {
    corpOffer
  } = useCorpOfferClaimContext();
  if (!corpOffer) {
    return null;
  }
  if (corpOffer.status !== EOfferStatus.Active && corpOffer.status !== EOfferStatus.Upcoming) {
    return null;
  }
  return _jsx(Wrapper, {
    children: _jsxs(Typography, {
      variant: "body2",
      children: ["\u0410\u043A\u0442\u0438\u0432\u043D\u043E \u0434\u043E ", moment(corpOffer.endDate).format(EDateFormat.Human)]
    })
  });
};
export default ActivityEndDateAdapter;