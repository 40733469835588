import axios from 'axios';
import addressApi from 'data/openApi/address';
import { EAddressLevel } from 'domain/model/enums';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../../data/network/errorHandler';
const useAddressSelector = props => {
  const {
    count,
    onlyValidPostalCode,
    level,
    fromLevel = EAddressLevel.Value1,
    toLevel = EAddressLevel.Value11,
    locations,
    filterBy,
    sortBy
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onSearch = debounce(newValue => setSearch(newValue), 500);
  useEffect(() => {
    if (search) {
      const cancelCallback = axios.CancelToken.source();
      const cancelToken = cancelCallback.token;
      setIsLoading(true);
      addressApi.addressQuery({
        query: search,
        count,
        level,
        fromLevel,
        toLevel,
        locations,
        cancelToken
      }).then(_ref => {
        let {
          data
        } = _ref;
        let newSuggestions = data.filter(item => !onlyValidPostalCode || item.postalCode !== null).map(item => item);
        if (filterBy) {
          newSuggestions = newSuggestions.filter(filterBy);
        }
        if (sortBy) {
          newSuggestions = newSuggestions.sort(sortBy);
        }
        setSuggestions(newSuggestions);
      }).catch(e => {
        ErrorHandler.handleHttpError(e, e.response);
      }).finally(() => {
        setIsLoading(false);
      });
      return () => {
        cancelCallback.cancel();
      };
    }
  }, [count, filterBy, fromLevel, level, locations, onlyValidPostalCode, search, sortBy, toLevel]);
  return {
    suggestions,
    isLoading,
    onSearch
  };
};
export default useAddressSelector;