import { useGetUserBalanceQuery } from 'data/api/user';
import { getBonusConfig } from 'presentation/utils';
import { useCallback, useEffect, useState } from 'react';
import CurrentUserBalanceContext from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
//todo asp когда появится баланс АСП, нужно будет избавиться или переписать
const CurrentUserBalanceProvider = props => {
  const {
    user,
    isCorpUser,
    children
  } = props;
  const [contextValue, setContextValue] = useState({
    value: null,
    isFetching: false,
    refreshBalance: () => null
  });
  const skipRequest = !isCorpUser || !user?.pernr || !!getBonusConfig()?.unavailableReason;
  const {
    data: bonusesBalance = null,
    isFetching: bonusesBalanceIsFetching,
    error: bonusesFetchError,
    refetch: refreshBonusesBalanceInternal,
    isUninitialized: bonusesBalanceisUninitialized
  } = useGetUserBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 0.5 * 60 * 1000,
    skip: skipRequest
  });

  // const {
  //   data: socialPackages,
  //   isFetching: isSocialPackagesFetching,
  //   error: socialPackagesError,
  //   refetch: refreshSocialPackages,
  // } = useGetSocialPackagesQuery(
  //   {
  //     status: [ESocialPackageStatus.Ready],
  //     resultType: ESocialPackageResultType.List,
  //   },
  //   {
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  // console.log('socialPackages', socialPackages);
  // console.log('isSocialPackagesFetching', isSocialPackagesFetching);
  // console.log('socialPackagesError', socialPackagesError);
  // console.log('refreshSocialPackages', refreshSocialPackages);

  const refreshBonusesBalance = useCallback(() => {
    if (!bonusesBalanceisUninitialized) {
      refreshBonusesBalanceInternal();
    }
  }, [bonusesBalanceisUninitialized, refreshBonusesBalanceInternal]);

  // ошибка получения баланса юзера
  useEffect(() => {
    if (bonusesFetchError) {
      const errorText = 'Error at request current user bonuses';
      console.error(errorText, bonusesFetchError);
    }
  }, [bonusesFetchError]);

  // обновление стейта баланса для оптимальности
  useEffect(() => {
    setContextValue({
      value: bonusesBalance?.balance ?? null,
      isFetching: bonusesBalanceIsFetching,
      refreshBalance: refreshBonusesBalance
    });
  }, [bonusesBalance, bonusesBalanceIsFetching, refreshBonusesBalance]);
  return _jsx(CurrentUserBalanceContext.Provider, {
    value: contextValue,
    children: children
  });
};
export default CurrentUserBalanceProvider;