import { Typography } from '@mui/material';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferCodeAdapter = () => {
  const {
    corpOffer
  } = useCorpOfferClaimContext();
  if (!corpOffer) {
    return null;
  }
  return _jsx(Wrapper, {
    children: _jsxs(Typography, {
      variant: "body2",
      children: ["\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435: ", corpOffer.code]
    })
  });
};
export default OfferCodeAdapter;