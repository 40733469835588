import useOrderDetailsData from './useOrderDetailsData';
const useOrderDetailsFetchingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isFetching: isOrdersFetching
  } = useOrderDetailsData(id);
  return isOrdersFetching;
};
export default useOrderDetailsFetchingStatus;