import { useContext, useEffect } from 'react';
import { useCreateOrderMutation, useGetBookingOfferDetailsQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
import { useUpdateCurrentUserMutation } from '../../../../../../data/api/user';
const useBookingOfferDetailsData = id => {
  const {
    setError
  } = useContext(RenderManagerErrorContext);
  const {
    data: bookingOffer,
    error: bookingOfferFetchError,
    isError: isBookingOfferError,
    isLoading: isBookingOfferLoading,
    isFetching: isBookingOfferFetching,
    isSuccess: isBookingOfferFetched
  } = useGetBookingOfferDetailsQuery(id, {
    refetchOnMountOrArgChange: true
  });
  const [updateCurrentUser
  // { error: updateCurrentUserError, isSuccess: isUpdated, isLoading: isUpdating, reset: updateCurrentUserReset },
  ] = useUpdateCurrentUserMutation();
  const [createOrder, {
    isLoading: isOrderCreating,
    error: errorCreateOrder
  }] = useCreateOrderMutation();
  useEffect(() => {
    if (bookingOfferFetchError) {
      ErrorHandler.handleHttpError(bookingOfferFetchError);
      setError(bookingOfferFetchError);
    }
  }, [setError, bookingOfferFetchError]);
  return {
    bookingOffer,
    bookingOfferFetchError,
    isBookingOfferError,
    isBookingOfferFetching,
    isBookingOfferLoading,
    isBookingOfferFetched,
    updateCurrentUser,
    createOrder,
    isOrderCreating,
    errorCreateOrder
  };
};
export default useBookingOfferDetailsData;