import { useGetCmsTradeOffersCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import React, { useEffect } from 'react';
import { CmsCollectionShowAllAdapter } from './adapters/showAll';
import OfferListItemContainer from '../../offer/listItem/container';
import { useAuth } from 'presentation/features/auth/provider/useAuth';

/**
 * фича отображения состава коллекции ТП
 */
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CmsTradeOfferCollection = props => {
  const {
    id,
    guid,
    header,
    footer: Footer,
    layout: Layout,
    stub,
    statuses,
    visibleCount,
    onLoadMore,
    onDataLoaded,
    onShowCard
  } = props;
  const {
    isAuthenticated
  } = useAuth();
  //подключились к хуку для отслеживания изменения данных пользователя, конкретно тут интересен город
  const {
    user
  } = useAuthUser();
  const args = {
    guid,
    id,
    city: user?.locality?.name ?? null,
    statuses
  };
  const {
    data: queryData,
    isFetching,
    isSuccess,
    error
  } = useGetCmsTradeOffersCollectionQuery(args);
  const data = queryData?.slice(0, visibleCount);
  const totalCount = queryData?.length ?? 0;
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  useEffect(() => {
    if (data && !isFetching || error) onDataLoaded?.();
  }, [data, isFetching, error, onDataLoaded]);
  const hasContent = data && data.length > 0;
  const sessionKey = JSON.stringify(args);
  return _jsxs(Layout, {
    header: hasContent && header,
    showAll: hasContent && totalCount > data.length && !onLoadMore && _jsx(CmsCollectionShowAllAdapter, {
      id: id
    }),
    footer: Footer && _jsx(Footer, {
      hasContent: hasContent
    }),
    children: [_jsx(CardPageableList, {
      totalCount: totalCount ?? 0,
      isFetching: isFetching,
      onLoadMore: onLoadMore,
      children: data?.map(item => _jsx(InView, {
        active: isSuccess && !isFetching,
        sessionKey: sessionKey,
        data: item,
        onShow: onShowCard,
        children: _jsx(OfferListItemContainer, {
          offer: item,
          favorite: isAuthenticated && {
            guid
          }
        }, item.id)
      }, item.id))
    }), !error && data?.length === 0 && stub]
  });
};