import 'overlayscrollbars/css/OverlayScrollbars.css';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CategorySelectItemWrapper = _ref => {
  let {
    children,
    onClick
  } = _ref;
  return _jsx(Wrapper, {
    onClick: onClick,
    underline: "none",
    children: children
  });
};
export default CategorySelectItemWrapper;