import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import { UserAvatar } from 'presentation/components/common/avatars';
import { getPersonFullName } from 'presentation/utils';
import { useState } from 'react';
import { MPChip } from 'theme/ui-kit/chip';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import { UserProfileEditAttributeCommonInfo } from '../../../components/attributeEdit/commonInfo';
import UserProfileViewAttribute from '../../../components/attributeView';
import { getUserGenderName } from '../../../utils';
import { useUserProfileCommonAttributesEdit } from '../hooks/useCommonAttributesEdit';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserProfileCommonAdapter = _ref => {
  let {
    user
  } = _ref;
  const [editMode, setEditMode] = useState(false);
  const {
    values,
    validation,
    isUpdating,
    update,
    reset,
    onChange
  } = useUserProfileCommonAttributesEdit({
    user
  });
  const {
    isCorpUser
  } = useAuthUser();
  const showEdit = () => setEditMode(true);
  const fullName = getPersonFullName(user);
  const hideEdit = () => {
    setEditMode(false);
    reset();
  };
  const onUpdateInternal = () => {
    update().then(result => result && hideEdit());
  };
  const fullNameNode = fullName ? _jsx(Typography, {
    variant: "body1",
    color: "text.primary",
    children: fullName
  }) : _jsx(Typography, {
    variant: "body1",
    color: "text.secondary",
    children: "\u0424\u0418\u041E"
  });
  const commonInfoNode = _jsxs(Typography, {
    component: "div",
    variant: "body2",
    color: "text.secondary",
    children: [user.gender ? _jsxs(_Fragment, {
      children: [getUserGenderName(user.gender), _jsx("br", {})]
    }) : _jsxs(_Fragment, {
      children: ["\u041F\u043E\u043B", _jsx("br", {})]
    }), user.birthDate ? `${moment(user.birthDate).format(EDateFormat.Human)} г.` : 'Дата рождения']
  });
  const avatarNode = _jsx(UserAvatar, {
    user: user,
    variant: "rounded",
    size: "large"
  });
  const infoNode = (!fullName || !user.gender || !user.birthDate) && _jsx(MPChip, {
    variant: "filled",
    color: "warning",
    label: "\u0414\u043E\u0431\u0430\u0432\u044C\u0442\u0435 \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044E \u043E \u0441\u0435\u0431\u0435",
    size: "micro"
  });
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: fullNameNode,
      value: commonInfoNode,
      icon: avatarNode,
      info: infoNode,
      onAction: showEdit
    }), editMode && _jsx(UserProfileEditAttributeCommonInfo, {
      label: "\u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435",
      user: values,
      isCorpUser: isCorpUser,
      isFetching: isUpdating,
      validation: validation,
      onChange: onChange,
      onClose: hideEdit,
      onSave: onUpdateInternal
    })]
  });
};