import { InputAdornment } from '@mui/material';
import { SearchIcon } from 'presentation/media/icons';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import { useInputDebounce } from '../../../../hooks/useInputDebounce';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskFilterPatternSearch = _ref => {
  let {
    value,
    onChange
  } = _ref;
  const [localSearchValue, setLocalSearchValue] = useInputDebounce({
    initialValue: value ?? null,
    nullIsValidValue: true,
    delay: 500,
    onChange
  });
  const onChangeSearch = event => {
    setLocalSearchValue(event.target.value ?? null);
  };
  return _jsx(MPFormInput, {
    label: "\u041F\u043E\u0438\u0441\u043A \u043F\u0430\u0440\u0442\u043D\u0451\u0440\u0430",
    size: "small",
    value: localSearchValue,
    InputProps: {
      endAdornment: _jsx(InputAdornment, {
        position: "end",
        children: _jsx(SearchIcon, {
          color: "secondary"
        })
      })
    },
    onChange: onChangeSearch
  });
};
export default PartnerDeskFilterPatternSearch;