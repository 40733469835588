import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingOrderItemSlotMultiple = _ref => {
  let {
    slots
  } = _ref;
  return _jsx(_Fragment, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      children: [_jsx(BookingSlotItem, {
        title: "\u0413\u043E\u0441\u0442\u0435\u0439",
        width: 2,
        children: slots.map(_ref2 => {
          let {
            qty,
            startDate
          } = _ref2;
          return _jsx("div", {
            children: qty
          }, `${startDate}-${qty}`);
        })
      }), _jsx(BookingSlotItem, {
        title: "\u0414\u0430\u0442\u0430",
        width: 10,
        children: slots.map(_ref3 => {
          let {
            startDate
          } = _ref3;
          return _jsx("div", {
            children: moment(startDate).format(EDateFormat.Human)
          }, startDate);
        })
      })]
    })
  });
};
export default BookingOrderItemSlotMultiple;