import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferPartnerInfoAdapter = _ref => {
  let {
    id
  } = _ref;
  const history = useHistory();
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  const {
    partnerDesk
  } = useAspOfferPartnerDeskData(id);
  const onPartnerClick = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({
        id: partnerDesk.id,
        tab: EPartnerDeskDetailsTab.AspOffers
      }));
    }
  };
  if (!aspOffer?.partner) {
    return null;
  }
  return _jsx(OfferPartnerInfo, {
    partner: aspOffer.partner,
    onClick: onPartnerClick
  });
};