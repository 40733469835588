import { AttributeButton } from '../controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Attribute = props => {
  const {
    selected,
    isOutOfStock,
    children,
    onClickAttribute
  } = props;
  return _jsx(AttributeButton, {
    onClick: onClickAttribute,
    variant: "outlined",
    isOutOfStock: isOutOfStock,
    selected: selected,
    color: "secondary",
    size: "small",
    children: children
  });
};
export default Attribute;