import { useHistory } from 'react-router';
import { getAspOfferDetailsRoute } from '../../routes';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';
import AspOfferTabs from '../tabs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferDetailsTabsAdapter = _ref => {
  let {
    tab,
    id,
    guid
  } = _ref;
  const history = useHistory();
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  const {
    partnerDesk
  } = useAspOfferPartnerDeskData(id);
  const onChangeTab = (event, newTab) => {
    history.replace(getAspOfferDetailsRoute({
      id,
      tab: newTab,
      guid
    }));
  };
  if (!aspOffer || !partnerDesk) {
    return null;
  }
  return _jsx(AspOfferTabs, {
    tab: tab,
    aspOffer: aspOffer,
    partnerDesk: partnerDesk,
    onChange: onChangeTab
  });
};