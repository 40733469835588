import { unstable_getScrollbarSize as getScrollbarSize } from '@mui/utils';
import { useHeaderParams } from '../../../../hooks/useHeaderParams';
import { Container, Drawer } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPTopPushDialog = props => {
  const {
    title,
    onHeader,
    children,
    ...others
  } = props;
  const {
    headerHeight
  } = useHeaderParams();
  const scrollbarSize = getScrollbarSize(window.document);
  return _jsx(Drawer, {
    variant: "temporary",
    elevation: 0,
    ...others,
    disableAutoFocus: true,
    disableEnforceFocus: true,
    anchor: "top",
    offsetTop: headerHeight,
    offsetRight: scrollbarSize,
    children: _jsx(Container, {
      children: children
    })
  });
};