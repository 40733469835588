import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useProductOfferFilterInLocation } from '../hooks/useProductOfferApplyFilter';
import { productOfferFilterValuesSelector } from '../store/selectors';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductFilterActionsAdapter = () => {
  const {
    onClearFilter
  } = useContextHandlers();
  const {
    onFilterApplyValues,
    onFilterClearValues
  } = useProductOfferFilterInLocation();
  const filterValues = useSelector(productOfferFilterValuesSelector);
  return useMemo(() => _jsxs(_Fragment, {
    children: [_jsx(Button, {
      variant: "contained",
      onClick: () => onFilterApplyValues(filterValues),
      children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B"
    }), _jsx(Button, {
      variant: "outlined",
      onClick: () => {
        onClearFilter();
        onFilterClearValues();
      },
      children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440\u044B"
    })]
  }), [onFilterApplyValues, filterValues, onClearFilter, onFilterClearValues]);
};
export default ProductFilterActionsAdapter;