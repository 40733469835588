import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import UserCorpLinkAcceptDialog from 'presentation/features/user/components/corpLinkAcceptDialog/component';
import { EUserProfileContainerDialogTag } from 'presentation/features/user/details/profile/utils';
import useDialogInHistory from 'presentation/hooks/useDialogInHistory';
import useUserBindCorpRole from 'presentation/hooks/useUserBindCorpRole';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useEffect } from 'react';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const UserProfileCorpLinkAdapter = () => {
  const {
    isCorpUser,
    bindRzhdProfile
  } = useAuthUser();
  const {
    refreshToken
  } = useAuthToken();
  const {
    isFetching: bindCorpRoleIsFetching,
    error: bindCorpRoleError,
    onBindCorpRole
  } = useUserBindCorpRole({
    refreshToken,
    bindRzhdProfile
  });
  const {
    open: isAcceptUserLinkDialogOpened,
    onOpen: onOpenAcceptUserLinkDialog,
    onClose: onCloseAcceptUserLinkDialog
  } = useDialogInHistory({
    tag: EUserProfileContainerDialogTag.AcceptUserLink
  });
  const onUserLinkAccept = () => {
    onCloseAcceptUserLinkDialog();
    onBindCorpRole();
  };
  const openUserLinkAcceptDialog = () => {
    onOpenAcceptUserLinkDialog();
  };
  useEffect(() => {
    if (bindCorpRoleError) {
      ErrorHandler.handleHttpError(bindCorpRoleError);
    }
  }, [bindCorpRoleError]);
  return _jsxs(_Fragment, {
    children: [!isCorpUser && _jsxs(MPButton, {
      fullWidth: true,
      size: "small",
      color: "brand",
      disabled: bindCorpRoleIsFetching,
      onClick: openUserLinkAcceptDialog,
      children: ["\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C, \u0447\u0442\u043E \u044F \u044F\u0432\u043B\u044F\u044E\u0441\u044C \u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A\u043E\u043C \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB", bindCorpRoleIsFetching && _jsx(ContentLoader, {
        size: 15
      })]
    }), _jsx(UserCorpLinkAcceptDialog, {
      open: isAcceptUserLinkDialogOpened,
      onAccept: onUserLinkAccept,
      onClose: onCloseAcceptUserLinkDialog
    })]
  });
};