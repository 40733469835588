import { useEffect } from 'react';
import ErrorHandler from 'data/network/errorHandler';
import CategorySelectRootContainer from 'presentation/features/category/select';
import CategorySelectCommonContainer from 'presentation/features/category/select/common';
import { ViewGridIcon } from 'presentation/media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CatalogOffersContainer = props => {
  const {
    selected,
    data,
    isFetching,
    error,
    onSelect
  } = props;
  const treeOut = [{
    id: '',
    name: 'Все предложения',
    parentId: null,
    image: _jsx(ViewGridIcon, {}),
    children: []
  }, ...(data ?? [])];
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  return _jsx(CategorySelectRootContainer, {
    showSelected: true,
    tree: treeOut,
    isFetching: isFetching,
    selected: selected,
    onSelect: onSelect,
    children: _ref => {
      let {
        childTree
      } = _ref;
      return childTree && _jsx(CategorySelectCommonContainer, {
        categories: childTree,
        selected: selected,
        onSelect: onSelect
      });
    }
  });
};