import UserFavoritesStubAdapter from 'presentation/features/user/details/favorites/adapters/stub';
import UserDetailsLayout from 'presentation/layouts/userDetails';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { getUserFavoritesRoute } from '../../entry';
import UserFavoritesLoaderAdapter from './adapters/loader';
import { useUserFavoritesTabs } from './hooks/useTabs';
import { UserFavoritesBookingsOffersContainer, UserFavoritesCorpOffersContainer, UserFavoritesProductOffersContainer, UserFavoritesTabs, UserFavoritesTradeOffersContainer } from './tabs';
import { EUserFavoritesTab } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = UserDetailsLayout;
const UserFavoritesContainer = props => {
  const history = useHistory();
  const {
    guid,
    tab,
    isCorpUser
  } = props;
  const [queryGuid] = useMemo(() => uuidv4(), []);
  const {
    tabList,
    tabToDisplay
  } = useUserFavoritesTabs({
    guid,
    tab,
    isCorpUser
  });
  const onChangeTab = useCallback(newTab => {
    history.replace(getUserFavoritesRoute({
      guid,
      tab: newTab
    }));
  }, [guid, history]);
  const tabsComponent = useMemo(() => _jsx(UserFavoritesTabs, {
    tab: tabToDisplay,
    tabs: tabList,
    onChangeTab: onChangeTab
  }), [tabToDisplay, tabList, onChangeTab]);
  return _jsxs(Layout, {
    header: "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435",
    toolbar: tabsComponent,
    loader: _jsx(UserFavoritesLoaderAdapter, {
      guid: queryGuid
    }),
    children: [tabToDisplay?.value === EUserFavoritesTab.ProductOffers && _jsx(UserFavoritesProductOffersContainer, {
      guid: guid,
      queryGuid: queryGuid
    }), tabToDisplay?.value === EUserFavoritesTab.TradeOffers && _jsx(UserFavoritesTradeOffersContainer, {
      guid: guid,
      queryGuid: queryGuid
    }), tabToDisplay?.value === EUserFavoritesTab.CorpOffers && _jsx(UserFavoritesCorpOffersContainer, {
      guid: guid,
      queryGuid: queryGuid
    }), tabToDisplay?.value === EUserFavoritesTab.BookingOffers && _jsx(UserFavoritesBookingsOffersContainer, {
      guid: guid,
      queryGuid: queryGuid
    }), _jsx(UserFavoritesStubAdapter, {
      countGuid: queryGuid,
      tabsCount: tabList.length
    })]
  });
};
export default UserFavoritesContainer;