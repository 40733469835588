import { Link } from '@mui/material';
import { getLegalPersonalDataAgreementRoute } from '../../../legal/entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutPersonalDataAgreementPublicLink = _ref => {
  let {
    children,
    ...others
  } = _ref;
  return _jsx(Link, {
    color: "inherit",
    underline: "none",
    href: getLegalPersonalDataAgreementRoute(),
    target: "_blank",
    ...others,
    children: children
  });
};
export default AboutPersonalDataAgreementPublicLink;