import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import { EGlobalUrlParam } from 'presentation/features/header/utils';
import TradeOfferListContainer from 'presentation/features/offer/trade/list/container';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOffersScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ETradeOfferUrlParam.Category) ?? null;
  const partnerId = searchParams.get(ETradeOfferUrlParam.PartnerId) ?? null;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(TradeOfferListContainer, {
        name: name,
        categoryId: categoryId,
        partnerId: partnerId,
        guid: guid
      })
    })
  });
};
export default TradeOffersScreen;