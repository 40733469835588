import { FormControlLabel, Radio } from '@mui/material';
import { useNsiCancelOrderCustomerTypesQuery } from 'data/api/nsi';
import ContentLoader from 'presentation/components/common/loader';
import { MPFormTextArea } from 'presentation/theme/ui-kit/input';
import { useEffect, useMemo, useState } from 'react';
import Splitter from '../../../components/common/splitter';
import { MPButton } from '../../../theme/ui-kit/button';
import { MPGrid } from '../../../theme/ui-kit/grid';
import { ModalContentWrapper } from './contentWrapper';
import { RadioHeader, RadioList } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CancelOrderModal = _ref => {
  let {
    isFetching,
    isOpen,
    offerType,
    onClose,
    onCancelOrder
  } = _ref;
  const [radioValue, setRadioValue] = useState(null);
  const [comment, setComment] = useState('');
  const {
    data: radioList
  } = useNsiCancelOrderCustomerTypesQuery();
  const filteredRadioList = radioList?.filter(_ref2 => {
    let {
      offerType: radioType
    } = _ref2;
    return !offerType || !radioType || radioType === offerType;
  });
  const isShowCommentInput = useMemo(() => {
    const index = filteredRadioList?.findIndex(radio => radio.id === radioValue);
    return filteredRadioList && index === filteredRadioList.length - 1;
  }, [radioValue, filteredRadioList]);
  const isCancelDisabled = !radioValue || isShowCommentInput && !comment || isFetching;
  const onRadioChange = e => {
    setRadioValue(e.target.value);
  };
  const onChangeComment = e => {
    setComment(e.target.value);
  };
  const onCancelOrderClick = () => {
    if (radioValue) onCancelOrder(radioValue, isShowCommentInput ? comment : '');
  };
  useEffect(() => {
    // clear state when modal closing
    if (!isOpen) {
      setRadioValue(null);
      setComment('');
    }
  }, [isOpen]);
  return _jsxs(ModalContentWrapper, {
    isOpen: isOpen,
    onClose: onClose,
    children: [_jsx(RadioHeader, {
      variant: "body1",
      children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043F\u0440\u0438\u0447\u0438\u043D\u0443 \u043E\u0442\u043C\u0435\u043D\u044B:"
    }), _jsx(RadioList, {
      value: radioValue,
      onChange: onRadioChange,
      id: "radio-error-text",
      children: filteredRadioList?.map(radio => _jsx(FormControlLabel, {
        control: _jsx(Radio, {
          color: "primary"
        }),
        value: radio.id,
        label: radio.name
      }, radio.id))
    }), isShowCommentInput && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 1
      }), _jsx(MPFormTextArea, {
        label: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043F\u0440\u0438\u0447\u0438\u043D\u0443",
        helperText: !comment ? 'Причина не может быть пустой' : '',
        inputProps: {
          maxLength: 1000
        },
        multiline: true,
        rows: 4,
        value: comment,
        onChange: onChangeComment
      })]
    }), _jsx(Splitter, {
      size: 4
    }), _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      flexDirection: "column",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsxs(MPButton, {
          fullWidth: true,
          size: "large",
          color: "brand",
          disabled: isCancelDisabled,
          onClick: onCancelOrderClick,
          children: ["\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u043E\u0442\u043C\u0435\u043D\u0443 \u0437\u0430\u043A\u0430\u0437\u0430", isFetching && _jsx(ContentLoader, {
            size: 20
          })]
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(MPButton, {
          fullWidth: true,
          size: "large",
          variant: "text",
          color: "brand",
          disabled: isFetching,
          onClick: onClose,
          children: "\u041D\u0435\u0442"
        })
      })]
    })]
  });
};