import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { ChildrenWrapper, Wrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const AttributeGroup = _ref => {
  let {
    group,
    children
  } = _ref;
  return _jsxs(Wrapper, {
    children: [_jsxs(Typography, {
      fontWeight: "bold",
      children: [group.attribute.name, ":"]
    }), _jsx(Splitter, {
      size: 1
    }), _jsx(ChildrenWrapper, {
      children: children(group)
    })]
  });
};
export default AttributeGroup;