import { Typography } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WarnMessage = _ref => {
  let {
    show,
    text,
    ...rest
  } = _ref;
  return _jsx(Typography, {
    color: "warning.main",
    variant: "body1",
    style: {
      visibility: show ? 'visible' : 'hidden'
    },
    ...rest,
    children: text
  });
};