import { getFileDownloadEndpoint } from 'data/openApi/utils';
import { CameraIcon, TrashIcon } from 'presentation/media/icons';
import Notifier from 'system/notifier';
import { convertBytesToUnitText } from 'utils/files';
import { Button, FileSelector, PhotoBorder, PhotoWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const fileTypes = ['image/png', 'image/jpeg'];
export const fileMaxSize = 1 * 1024 * 1024;
export const UserPhotoSelector = _ref => {
  let {
    src,
    onChange,
    onClear
  } = _ref;
  const source = src ? getFileDownloadEndpoint({
    id: src,
    private: true
  }) : null;
  const onChangeInternal = event => {
    const file = event.target.files?.[0] ?? null;
    if (!file) {
      onChange?.(null);
      return;
    }
    const fileType = file.type;
    if (fileTypes.length > 0 && !fileTypes.includes(fileType)) {
      Notifier.getInstance().addError(`Недопустимый тип файла. Разрешены к загрузке ${fileTypes.join(', ')}.`);
      return;
    }
    if (fileMaxSize && file.size > fileMaxSize) {
      Notifier.getInstance().addError(`Максимальный размер файла ${convertBytesToUnitText(fileMaxSize)}`);
      return;
    }
    onChange?.(file);
    event.target.setAttribute('type', 'text');
    event.target.setAttribute('type', 'file');
  };
  return _jsx(_Fragment, {
    children: _jsxs(Wrapper, {
      children: [onChange && _jsxs(Button, {
        color: "secondary",
        children: [_jsx(CameraIcon, {
          color: "primary"
        }), _jsx(FileSelector, {
          type: "file",
          onChange: onChangeInternal
        })]
      }), _jsx(PhotoBorder, {
        children: _jsx(PhotoWrapper, {
          children: source && _jsx("img", {
            src: source,
            alt: "profile_photo"
          })
        })
      }), onClear && _jsx(Button, {
        color: "secondary",
        onClick: onClear,
        children: _jsx(TrashIcon, {
          color: "error"
        })
      })]
    })
  });
};