import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ContentLimiter = props => {
  const {
    children,
    layout,
    layoutProps
  } = props;
  const Layout = layout;
  return _jsx(Layout, {
    ...layoutProps,
    children: children
  });
};