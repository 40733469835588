import useActivationsCounter from 'presentation/features/activation/counter/useCounter';
import { getUserActivationsRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemActivations } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuActivationsAdapter = () => {
  const history = useHistory();
  const count = useActivationsCounter();
  const onClick = () => {
    history.push(getUserActivationsRoute({}));
  };
  return _jsx(UserMenuItemActivations, {
    count: count,
    onClick: onClick
  });
};