import useOrdersCounter from 'presentation/features/order/counter/useCounter';
import { getUserOrdersRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemOrders } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuOrdersAdapter = () => {
  const history = useHistory();
  const count = useOrdersCounter();
  const onClick = () => {
    history.push(getUserOrdersRoute({}));
  };
  return _jsx(UserMenuItemOrders, {
    count: count,
    onClick: onClick
  });
};