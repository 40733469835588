import ContentLoader from 'presentation/components/common/loader';
import PartnerDeskDetailsFilterLayout from 'presentation/features/partnerDesk/filter/layout';
import { useCallback } from 'react';
import { alphabets } from '../../../utils/constants';
import PartnerDeskFilterAlphabeticalSearch from './alphabetical';
import { useAlphabet } from './alphabetical/hooks';
import PartnerDeskFilterPatternSearch from './pattern';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskDetailsFilterLayout;
const PartnerDesksFilterContainer = _ref => {
  let {
    value,
    onChange,
    isFetching
  } = _ref;
  const {
    digitsAndSpecialSymbols,
    isAlphabetLoading,
    localeAlphabet,
    currentLocale,
    onChangeLocale
  } = useAlphabet();
  const onChangePatternSearch = useCallback(patternSearch => {
    if (patternSearch) {
      onChange({
        ...value,
        firstCharacterSearch: null,
        patternSearch
      });
    } else {
      onChange({
        ...value,
        firstCharacterSearch: localeAlphabet,
        patternSearch: null
      });
    }
  }, [value, localeAlphabet, onChange]);
  const onChangeFirstCharacterSearch = useCallback(firstCharacterSearch => {
    onChange({
      ...value,
      patternSearch: null,
      firstCharacterSearch
    });
  }, [value, onChange]);
  return _jsx(Layout, {
    search: _jsx(PartnerDeskFilterPatternSearch, {
      value: value.patternSearch ?? null,
      onChange: onChangePatternSearch
    }),
    loader: isAlphabetLoading && isFetching && _jsx(ContentLoader, {
      position: "fixed",
      size: 16
    }),
    children: localeAlphabet.length > 0 && _jsx(PartnerDeskFilterAlphabeticalSearch, {
      allowedAlphabet: localeAlphabet,
      alphabet: alphabets[currentLocale],
      locale: currentLocale,
      specialSymbols: digitsAndSpecialSymbols,
      value: value.firstCharacterSearch ?? null,
      onChangeLocale: onChangeLocale,
      onChange: onChangeFirstCharacterSearch
    })
  });
};
export default PartnerDesksFilterContainer;