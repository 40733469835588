import OfferPromotionCopy from '../../../../components/promotionCopy';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimCodePromotion = props => {
  const {
    code,
    onCopied,
    onNeedCopy,
    copied,
    needCopy
  } = props;
  return _jsx(OfferPromotionCopy, {
    label: code,
    onCopy: onCopied,
    onNeedCopy: onNeedCopy,
    copied: copied,
    needCopy: needCopy
  });
};