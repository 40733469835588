import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from 'react';
import AppSvgIcon from '../icon';
import ContentLoader from '../loader';
import { Button, Input, Wrapper } from './controls';
import { filterValue } from './utils';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const CountInput = props => {
  const {
    size = 'medium',
    isEmpty = false,
    stayOpened = false,
    value,
    disabled = false,
    isInputDisabled = false,
    preserveValueOnChanges = false,
    loading = false,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    fullWidth = false,
    onBlur,
    onKeyPress,
    onChangeCount,
    onDecrement,
    onIncrement
  } = props;
  const [localValue, setLocalValue] = useState(value || 0);
  useEffect(() => {
    setLocalValue(value || 0);
  }, [value]);
  const handleChangeValue = newValue => {
    const numValue = filterValue(newValue);
    onChangeCount?.(numValue);
    setLocalValue(numValue);
  };
  const getChangedValue = newValue => {
    if (!preserveValueOnChanges) {
      return newValue;
    }
    return value && value !== newValue ? value : newValue;
  };
  const handleDecrementValue = () => {
    const numValue = filterValue(localValue - 1, {
      min,
      max
    });
    onDecrement?.(numValue);
    setLocalValue(getChangedValue(numValue));
  };
  const handleIncrementValue = () => {
    const numValue = filterValue(localValue + 1, {
      min,
      max
    });
    if (numValue !== value) {
      onIncrement?.(numValue);
      setLocalValue(getChangedValue(numValue));
    }
  };
  const handleOnBlur = () => {
    const numValue = filterValue(localValue, {
      min,
      max
    });
    if (numValue !== value) {
      onChangeCount?.(numValue);
      setLocalValue(numValue);
    }
    onBlur?.(numValue);
  };
  const handleKeyPress = e => {
    onKeyPress?.(e);
  };
  return _jsx(_Fragment, {
    children: (!isEmpty || stayOpened) && _jsxs(Wrapper, {
      "data-testid": 'countInput',
      isInputDisabled: isInputDisabled,
      children: [_jsx(Input, {
        disabled: disabled || isInputDisabled,
        fullWidth: fullWidth,
        value: localValue,
        onChange: e => handleChangeValue(e.target.value),
        size: size,
        onBlur: handleOnBlur,
        onKeyUp: handleKeyPress,
        InputProps: {
          startAdornment: _jsx(Button, {
            color: "primary",
            "data-testid": 'countInput-decrement',
            disabled: disabled || localValue <= min,
            onClick: handleDecrementValue,
            children: _jsx(AppSvgIcon, {
              icon: RemoveIcon,
              color: "inherit"
            })
          }),
          endAdornment: _jsx(Button, {
            color: "primary",
            "data-testid": 'countInput-increment',
            disabled: disabled || localValue >= max,
            onClick: handleIncrementValue,
            children: _jsx(AppSvgIcon, {
              icon: AddIcon,
              color: "inherit"
            })
          })
        }
      }), loading && _jsx(ContentLoader, {
        size: "1.5rem"
      })]
    })
  });
};
export default CountInput;