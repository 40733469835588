import ErrorHandler from 'data/network/errorHandler';
import { EFavoriteProcessAction } from 'domain/model/enums';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { favoritesProvideChangeProcess, favoritesProvideClear } from './store/slice';
/**
 * хук для работы с избранным общего назначения, нужен только для выполнения мутаций
 */
const useFavoritesMutation = props => {
  const {
    guid,
    payload,
    clearAfterUnmount,
    addMutation,
    removeMutation
  } = props;
  const dispatch = useDispatch();
  const {
    userId
  } = useAuthUser();
  const [addMutationExecute, addMutationResult] = addMutation;
  const [removeMutationExecute, removeMutationResult] = removeMutation;
  const action = useCallback((objectId, action) => {
    const offerType = payload.offerType;
    const processData = {
      action,
      id: objectId,
      offerType
    };
    const mutation = action === EFavoriteProcessAction.Add ? addMutationExecute : removeMutationExecute;
    dispatch(favoritesProvideChangeProcess({
      guid,
      process: {
        ...processData,
        isFetching: true,
        isFetched: false,
        isFailed: false,
        isCancelled: false
      }
    }));
    mutation({
      id: objectId,
      userId,
      ...payload,
      offerType
    }).unwrap().then(() => {
      dispatch(favoritesProvideChangeProcess({
        guid,
        process: {
          ...processData,
          isFetching: false,
          isFetched: true,
          isFailed: false,
          isCancelled: false
        }
      }));
    }).catch(() => {
      dispatch(favoritesProvideChangeProcess({
        guid,
        process: {
          ...processData,
          isFetching: false,
          isFetched: false,
          isFailed: true,
          isCancelled: false
        }
      }));
    });
  }, [payload, userId, addMutationExecute, removeMutationExecute, dispatch, guid]);
  const add = useCallback(objectId => action(objectId, EFavoriteProcessAction.Add), [action]);
  const remove = useCallback(objectId => action(objectId, EFavoriteProcessAction.Remove), [action]);
  useEffect(() => {
    const {
      originalArgs: args,
      isLoading,
      isSuccess,
      isError,
      error
    } = addMutationResult;
    if (args) {
      dispatch(favoritesProvideChangeProcess({
        guid,
        process: {
          action: EFavoriteProcessAction.Add,
          id: args.id,
          isFetching: isLoading,
          isFetched: isSuccess,
          isFailed: isError,
          isCancelled: false,
          offerType: args.offerType
        }
      }));
    }
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [dispatch, guid, addMutationResult]);
  useEffect(() => {
    const {
      originalArgs: args,
      isLoading,
      isSuccess,
      isError,
      error
    } = removeMutationResult;
    if (args) {
      dispatch(favoritesProvideChangeProcess({
        guid,
        process: {
          action: EFavoriteProcessAction.Remove,
          id: args.id,
          isFetching: isLoading,
          isFetched: isSuccess,
          isFailed: isError,
          isCancelled: false,
          offerType: args.offerType
        }
      }));
    }
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [dispatch, guid, removeMutationResult]);
  useEffect(() => {
    return () => {
      if (clearAfterUnmount) {
        dispatch(favoritesProvideClear({
          guid
        }));
      }
    };
  }, [dispatch, guid, clearAfterUnmount]);
  return {
    add,
    remove
  };
};
export default useFavoritesMutation;