import { useSelector } from 'react-redux';
import { searchOffersNoDataSelector } from '../store/selectors';
import SerarchOffersStub from '../stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchOffersStubAdapter = () => {
  const noData = useSelector(searchOffersNoDataSelector);
  if (noData) {
    return _jsx(SerarchOffersStub, {});
  }
  return null;
};
export default SearchOffersStubAdapter;