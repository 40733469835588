import TradeOfferScreen from 'presentation/screen/tradeOffer';
import TradeOffersScreen from 'presentation/screen/tradeOffers';
import TradeOffersSearchResultsScreen from 'presentation/screen/tradeOffersSearch';
import { Route, Switch } from 'react-router-dom';
import { routing } from './routes';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TradeOfferEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.list,
      component: TradeOffersScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.search,
      component: TradeOffersSearchResultsScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: TradeOfferScreen
    })]
  });
};
export default TradeOfferEntry;