import { partnerApi } from 'data/api/partner';
export const getPartnersNameMapLoadingSelector = store => {
  const {
    isLoading,
    isUninitialized
  } = partnerApi.endpoints.getPartnersNameMap.select()(store);
  return isLoading || isUninitialized;
};
export const getPartnerDesksLoadingSelector = args => store => {
  const {
    isLoading,
    isUninitialized
  } = partnerApi.endpoints.getPartnerDesks.select(args)(store);
  return isLoading || isUninitialized;
};