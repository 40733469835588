import { useAuthUser } from './useAuthUser';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoggedUserAdapter = props => {
  const {
    component: Component,
    fallback,
    children
  } = props;
  const {
    user
  } = useAuthUser();
  if (user) {
    return Component ? _jsx(Component, {
      user: user,
      children: children
    }) : children;
  } else {
    return fallback ?? null;
  }
};