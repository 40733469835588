import { EBalanceType } from 'domain/model/enums';
import { UserFinanceBalanceBonusesAdapter } from './balanceBonuses';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceAdapter = _ref => {
  let {
    balance
  } = _ref;
  switch (balance.type) {
    case EBalanceType.Bonuses:
      return _jsx(UserFinanceBalanceBonusesAdapter, {
        balance: balance
      });
    case EBalanceType.ASP:
      return _jsx(UserFinanceBalanceBonusesAdapter, {
        balance: balance
      });
  }
};