import { Typography } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import { pluralize } from 'utils/pluralize';
import { getOfferNotUsedCountText } from '../../../../utils';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { Wrapper } from './controls';
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferClaimLeftCodes = _ref => {
  let {
    codesCount,
    promotionType
  } = _ref;
  return _jsx(Typography, {
    variant: "body2",
    children: codesCount > 0 && _jsxs(_Fragment, {
      children: [pluralize(codesCount, ['Остался', 'Осталось', 'Осталось']), ' ', getOfferNotUsedCountText(codesCount, promotionType)]
    })
  });
};
export const CorpOfferClaimLeftCodesAdapter = () => {
  const {
    corpOffer
  } = useCorpOfferClaimContext();
  const notUsedOfferCount = corpOffer?.notUsedOfferCount ?? 0;
  return useMemo(() => corpOffer?.status === EOfferStatus.Active ? _jsx(Wrapper, {
    children: _jsx(CorpOfferClaimLeftCodes, {
      codesCount: notUsedOfferCount,
      promotionType: corpOffer.promotionType
    })
  }) : null, [corpOffer?.status, corpOffer?.promotionType, notUsedOfferCount]);
};