import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CmsCollectionSummaryLayout = props => {
  const {
    loader,
    children
  } = props;
  useVisibleSectionLinks();
  return _jsxs(DefaultContentWrapper, {
    children: [children, loader]
  });
};
export default CmsCollectionSummaryLayout;