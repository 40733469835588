import BookingOfferBreadcrumbs from '../../components/breadcrumbs';
import useBookingOfferCategoriesData from '../hooks/useBookingOfferCategoriesData';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferBreadcrumbAdapter = props => {
  const {
    id
  } = props;
  const {
    categories
  } = useBookingOfferCategoriesData();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  return _jsx(BookingOfferBreadcrumbs, {
    offerCategories: bookingOffer?.category ? [bookingOffer?.category] : null,
    dictionary: categories ?? null
  });
};
export default BookingOfferBreadcrumbAdapter;