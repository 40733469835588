import { Link, Typography } from '@mui/material';
import AppImage from 'presentation/components/common/images/common';
import { WarnMessage } from 'presentation/components/common/messages';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ButtonLink } from '../../../../components/common/buttons/link';
import AppSvgIcon from '../../../../components/common/icon';
import ContentLoader from '../../../../components/common/loader';
import { IconFavoriteFilled } from '../../../../media/icons';
import { getProductDisplayName } from '../../../offer/product/utils';
import CartProviderContainer from '../../cartProvider/container';
import CartRemoveButton from '../cartRemoveButton';
import CartCounter from '../counter';
import { ContentGrid, ContentGridArea, ECartItemGridAreas, PreviewWrapper, Wrapper } from './controls';
import CartListItemFinanceInfo from './financeInfo';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartListItem = props => {
  const {
    data,
    enabled,
    onOpenProductDetails,
    onAddOrRemoveFavorite,
    isFavoriteFetching
  } = props;
  const {
    offer
  } = data;
  const {
    images,
    price,
    originalPrice,
    salePercent,
    stock,
    favorite
  } = offer;
  const displayName = getProductDisplayName(offer);
  const preview = images?.[0] ?? null;
  const isFavoriteProcessing = isFavoriteFetching(offer.id);
  return _jsx(Wrapper, {
    children: _jsx(CartProviderContainer, {
      product: offer,
      children: _ref => {
        let {
          cartItem,
          isItemFetching,
          deleteItem,
          decrement,
          increment,
          updateItem,
          isOutOfQuantity
        } = _ref;
        return _jsxs(ContentGrid, {
          children: [_jsx(ContentGridArea, {
            area: ECartItemGridAreas.Preview,
            children: preview && _jsx(Link, {
              color: enabled ? 'textPrimary' : 'textSecondary',
              underline: "hover",
              onClick: onOpenProductDetails,
              children: _jsx(PreviewWrapper, {
                enabled: enabled,
                children: _jsx(AppImage, {
                  src: preview
                })
              })
            })
          }), _jsx(ContentGridArea, {
            area: ECartItemGridAreas.Name,
            children: _jsx(Link, {
              color: enabled ? 'textPrimary' : 'textSecondary',
              underline: "hover",
              onClick: onOpenProductDetails,
              children: displayName
            })
          }), _jsxs(ContentGridArea, {
            area: ECartItemGridAreas.Finance,
            children: [enabled && _jsx(CartListItemFinanceInfo, {
              price: price,
              originalPrice: originalPrice,
              salePercent: salePercent
            }), !enabled && _jsx(Typography, {
              children: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438"
            })]
          }), _jsx(ContentGridArea, {
            area: ECartItemGridAreas.Counter,
            flexDirection: "column",
            children: enabled && _jsxs(MPGrid, {
              container: true,
              spacing: 0.5,
              children: [_jsx(MPGrid, {
                item: true,
                children: _jsx(CartCounter, {
                  count: cartItem?.quantity ?? 0,
                  isEmpty: (cartItem?.quantity ?? 0) === 0,
                  loading: isItemFetching,
                  isWarn: isOutOfQuantity,
                  disabled: isItemFetching,
                  max: stock ?? 0,
                  size: "small",
                  onDecrement: decrement,
                  onIncrement: increment,
                  onChange: count => updateItem(count)
                })
              }), _jsx(MPGrid, {
                item: true,
                children: _jsx(WarnMessage, {
                  show: isOutOfQuantity,
                  text: `Есть: ${cartItem?.offer?.stock}`
                })
              })]
            })
          }), _jsx(ContentGridArea, {
            area: ECartItemGridAreas.Remove,
            children: _jsx(CartRemoveButton, {
              onClick: deleteItem
            })
          }), _jsx(ContentGridArea, {
            area: ECartItemGridAreas.Favorite,
            children: _jsxs(ButtonLink, {
              startIcon: _jsx(AppSvgIcon, {
                color: favorite ? 'primary' : 'secondary',
                icon: IconFavoriteFilled
              }),
              onClick: onAddOrRemoveFavorite,
              disabled: isFavoriteProcessing,
              children: [_jsx(Typography, {
                color: "textPrimary",
                children: favorite ? 'В избранном' : 'В избранное'
              }), isFavoriteProcessing && _jsx(ContentLoader, {
                size: 20
              })]
            })
          })]
        });
      }
    })
  });
};
export default CartListItem;