import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { useCallback } from 'react';
import { ENotificationMessageType } from '../../../notification/types';
import useNotificationSocket from '../../../notification/useNotificationSocket';
import { createEvent } from '../events/receivedActivation';
const CorpOffersNotificationsReceiver = () => {
  const {
    publish
  } = useSystemEventBus();
  const onMessageReceive = useCallback(msg => {
    switch (msg.type) {
      case ENotificationMessageType.OfferActivationChangedMessage:
        publish(createEvent({
          caption: msg.title,
          content: msg.description
        }));
        return;
      default:
        return;
    }
  }, [publish]);
  useNotificationSocket({
    onMessageReceive
  });
  return null;
};
export default CorpOffersNotificationsReceiver;