import { Fade, Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { StubWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartDetailsLayout = props => {
  const {
    title,
    help,
    sidebar,
    stub,
    loader,
    children
  } = props;
  const {
    x5
  } = useGridSpacing();
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return _jsx(Fade, {
    in: true,
    children: _jsxs(DefaultContentWrapper, {
      children: [_jsxs(Wrapper, {
        container: true,
        spacing: x5,
        children: [_jsx(MPGrid, {
          item: true,
          zero: 12,
          children: _jsx(Typography, {
            variant: "h3",
            children: title
          })
        }), _jsx(MPGrid, {
          item: true,
          zero: 12,
          md: true,
          children: children
        }), !isSmUp && _jsx(MPGrid, {
          item: true,
          zero: 12,
          children: help
        }), _jsx(MPGrid, {
          item: true,
          md: 4,
          children: sidebar
        })]
      }), stub && _jsx(StubWrapper, {
        children: stub
      }), loader]
    })
  });
};
export default CartDetailsLayout;