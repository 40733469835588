import { createSlice } from '@reduxjs/toolkit';
import { EHeaders } from 'data/network/types';
const slice = createSlice({
  name: 'previewMode',
  initialState: {
    targetParams: sessionStorage.getItem(EHeaders.TargetValue) ? JSON.parse(sessionStorage.getItem(EHeaders.TargetValue)) : null
  },
  reducers: {
    setTargetParams: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.targetParams = payload;
    }
  }
});
export const {
  setTargetParams
} = slice.actions;
export default slice.reducer;