import StubCommon from 'presentation/components/common/stub/common';
import { offersStub } from 'presentation/media/images';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BenefitTransactionsStub = _ref => {
  let {
    text = 'Здесь будут записи обо всех операциях баланса бонусного пакета'
  } = _ref;
  return _jsx(StubCommon, {
    title: text,
    image: offersStub
  });
};