import { CardPageableList } from 'components/common/cardPageableList';
import { BenefitTransactionItem, BenefitTransactionsStub } from '../../components/benefitTransaction';
import { getEqualDates } from '../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserFinancesBonusesContainer = props => {
  const {
    isEmpty,
    data,
    onLoadMore,
    totalCount,
    isFetching
  } = props;
  if (isEmpty) {
    return _jsx(BenefitTransactionsStub, {});
  }
  return _jsx(CardPageableList, {
    onLoadMore: onLoadMore,
    totalCount: totalCount,
    isFetching: isFetching,
    span: 12,
    smSpan: 12,
    mdSpan: 12,
    lgSpan: 12,
    children: data?.map((item, index) => _jsx(BenefitTransactionItem, {
      isGroup: index !== 0 && getEqualDates(item.date, data[index - 1].date),
      data: item
    }, item.transactionId))
  });
};
export default UserFinancesBonusesContainer;