import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferClaimContainer from '../../claim/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferSidebarAdapter = _ref => {
  let {
    id,
    guid
  } = _ref;
  const {
    tradeOffer
  } = useTradeOfferDetailsData(id);
  const {
    partnerDesk
  } = useTradeOfferPartnerDeskData(id);
  if (!tradeOffer) {
    return null;
  }
  return _jsx(TradeOfferClaimContainer, {
    guid: guid,
    partnerDesk: partnerDesk,
    tradeOffer: tradeOffer
  });
};
export default TradeOfferSidebarAdapter;