import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimPendingInfoMessage = props => {
  const {
    activationComment
  } = props;
  return _jsx(ClaimMessage, {
    type: "info",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: activationComment
    })
  });
};