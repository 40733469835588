import OfferListLayout from 'presentation/layouts/offerList';
import { TradeOfferListAsideAdapter, TradeOfferListBannersAdapter, TradeOfferListDataCommonAdapter, TradeOfferListDataUpcomingAdapter, TradeOfferListLoaderAdapter, TradeOfferListSortSelectAdapter, TradeOfferListStubAdapter, TradeOffersHeaderAdapter } from './adapters';
import { TradeOfferListContext } from './context';
import useTradeOfferListHandlers from './hooks/useTradeOfferList';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = OfferListLayout;
const Header = _jsx(TradeOffersHeaderAdapter, {});
const Aside = _jsx(TradeOfferListAsideAdapter, {});
const Stub = _jsx(TradeOfferListStubAdapter, {});
const Loader = _jsx(TradeOfferListLoaderAdapter, {});
const SortSelect = _jsx(TradeOfferListSortSelectAdapter, {});
const TradeOfferListContainer = props => {
  const {
    guid,
    name,
    categoryId,
    partnerId
  } = props;
  const handlers = useTradeOfferListHandlers({
    guid,
    name,
    categoryId,
    partnerId
  });
  return _jsx(TradeOfferListContext.Provider, {
    value: handlers,
    children: _jsxs(Layout, {
      banners: _jsx(TradeOfferListBannersAdapter, {
        guid: guid
      }),
      header: Header,
      aside: Aside,
      controls: SortSelect,
      stub: Stub,
      loader: Loader,
      children: [_jsx(TradeOfferListDataCommonAdapter, {
        guid: guid
      }), _jsx(TradeOfferListDataUpcomingAdapter, {
        guid: guid
      })]
    })
  });
};
export default TradeOfferListContainer;