import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { DocLink } from './link';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const SignAgreementsDialog = props => {
  const {
    agreements,
    acceptContent = 'Принять',
    rejectContent = 'Отклонить',
    open,
    isAccepting,
    isRejecting,
    onAccept,
    onReject
  } = props;
  return _jsxs(MPConfirmDialog, {
    title: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u0430\u0448\u0435 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u0441 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430\u043C\u0438",
    open: open,
    closeButtonVisible: false,
    maxWidth: 'xs',
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsxs(MPButton, {
        color: "brand",
        fullWidth: true,
        size: 'large',
        onClick: onAccept,
        disabled: isAccepting || isRejecting,
        children: [acceptContent, isAccepting && _jsx(ContentLoader, {})]
      }), _jsxs(MPButton, {
        color: "brand",
        variant: "text",
        size: 'large',
        fullWidth: true,
        disabled: isAccepting || isRejecting,
        onClick: onReject,
        children: [rejectContent, isRejecting && _jsx(ContentLoader, {})]
      })]
    }),
    children: ["\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u044C\u0442\u0435\u0441\u044C \u0441 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430\u043C\u0438 \u0438 \u0434\u0430\u0439\u0442\u0435 \u0441\u0432\u043E\u0451 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435, \u043D\u0430\u0436\u0430\u0432 \u043A\u043D\u043E\u043F\u043A\u0443 \xAB\u041F\u0440\u0438\u043D\u044F\u0442\u044C\xBB", !!agreements.length && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 2
      }), _jsx(MPGrid, {
        container: true,
        spacing: 2,
        wrap: "nowrap",
        direction: "column",
        children: agreements.map(a => _jsx(MPGrid, {
          item: true,
          children: _jsx(DocLink, {
            href: a.doc.url,
            underline: "hover",
            children: a.doc.name
          })
        }, a.doc.id))
      })]
    })]
  });
};
export default SignAgreementsDialog;