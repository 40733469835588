import ContentLoader from 'components/common/loader';
import Splitter from 'components/common/splitter';
import { SystemEventContentBuilderLayout } from 'features/systemEvent';
import { isValidPhoneFormat } from 'presentationUtils/phone';
import { useState } from 'react';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { MPPhoneInput } from 'theme/ui-kit/input';
import { InfoText } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ChangeStepAdapter = _ref => {
  let {
    isFetching,
    onSubmit
  } = _ref;
  const [localPhone, setLocalPhone] = useState('');
  const [error, setError] = useState(null);
  const onChangeInternal = value => {
    setError(null);
    setLocalPhone(value);
  };
  const onSubmitInternal = () => {
    const isValidPhone = localPhone && isValidPhoneFormat(localPhone);
    if (isValidPhone) {
      onSubmit(localPhone);
      setError(null);
    } else {
      setError('Введите корректный номер телефона');
    }
  };
  return _jsxs(SystemEventContentBuilderLayout, {
    title: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0441\u0432\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430",
    actions: _jsx(ConfirmGroupButtons, {
      children: _jsxs(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        disabled: isFetching,
        onClick: onSubmitInternal,
        children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
      })
    }),
    children: [_jsx(InfoText, {
      variant: "body2",
      children: "\u041D\u0443\u0436\u0435\u043D \u0434\u043B\u044F \u0441\u0432\u044F\u0437\u0438 \u0441 \u0412\u0430\u043C\u0438, \u0435\u0441\u043B\u0438 \u043F\u043E\u043D\u0430\u0434\u043E\u0431\u0438\u0442\u0441\u044F \u0443\u0442\u043E\u0447\u043D\u0438\u0442\u044C \u0434\u0435\u0442\u0430\u043B\u0438 \u043F\u043E \u0437\u0430\u043A\u0430\u0437\u0443"
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(MPPhoneInput, {
      label: 'Номер телефона',
      value: localPhone ?? '',
      helperText: error,
      error: !!error,
      disabled: isFetching,
      onChange: onChangeInternal
    })]
  });
};