import { formHelperTextClasses, getInputBaseUtilityClass, getInputLabelUtilityClasses, inputLabelClasses, outlinedInputClasses, selectClasses, svgIconClasses, typographyClasses } from '@mui/material';
import { disabled as disabledClassName, focused } from './constants';
const customLabelClasses = {
  sizeMicro: getInputLabelUtilityClasses('sizeMicro')
};
const customOutlinedClasses = {
  sizeMicro: getInputBaseUtilityClass('sizeMicro'),
  inputSizeMicro: getInputBaseUtilityClass('inputSizeMicro')
};
const textFields = baseTheme => ({
  MuiInputBase: {
    styleOverrides: {
      adornedEnd: {
        paddingRight: baseTheme.spacing(2)
      }
    }
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      margin: 'none',
      variant: 'outlined',
      color: 'primary'
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight,
        [`&.${inputLabelClasses.outlined}`]: {
          transform: `translate(${baseTheme.spacing(1.974)}, ${baseTheme.spacing(1.974)}) scale(1)`
        },
        //это отдельно, потому что не ложится на уровне класса выше наряду с sizeSmall
        [`&.${customLabelClasses.sizeMicro}`]: {
          [`&.${inputLabelClasses.outlined}`]: {
            transform: `translate(${baseTheme.spacing(1.474)}, ${baseTheme.spacing(0.75)}) scale(1)`,
            [`&.${inputLabelClasses.shrink}`]: {
              transform: `translate(${baseTheme.spacing(1.474)}, -${baseTheme.spacing(1.974 / 2)}) scale(0.85)`
            }
          },
          fontSize: baseTheme.typography.body2.fontSize,
          lineHeight: baseTheme.typography.body2.lineHeight,
          fontWeight: baseTheme.typography.body2.fontWeight
        }
      },
      shrink: {
        [`&.${inputLabelClasses.outlined}`]: {
          transform: `translate(${baseTheme.spacing(1.974)}, -${baseTheme.spacing(1.974 / 2)}) scale(0.885)`
        }
      },
      sizeSmall: {
        [`&.${inputLabelClasses.outlined}`]: {
          transform: `translate(${baseTheme.spacing(1.574)}, ${baseTheme.spacing(1.474)}) scale(1)`
        },
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        height: '56px',
        backgroundColor: baseTheme.palette.background.paper,
        [`:hover:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.focused})`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: baseTheme.palette.secondary.main
          }
        },
        [`&.${outlinedInputClasses.error}:hover:not(.${outlinedInputClasses.disabled})`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: baseTheme.palette.error.main
          }
        },
        [`&${focused}`]: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: '1px'
          }
        },
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: baseTheme.palette.secondary.A900,
          fontSize: baseTheme.typography.body1.fontSize,
          legend: {
            fontSize: '0.91em'
          }
        },
        //это отдельно, потому что не ложится на уровне класса выше наряду с sizeSmall
        [`&.${customOutlinedClasses.sizeMicro}`]: {
          height: '34px',
          borderRadius: baseTheme.shape.borderRadius * 1.2,
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            fontSize: baseTheme.typography.body2.fontSize,
            legend: {
              fontSize: '0.8em'
            }
          },
          //это отдельно, потому что не ложится на уровне класса выше наряду с input
          [`.${outlinedInputClasses.input}`]: {
            padding: baseTheme.spacing(0.6, 1.474),
            fontSize: baseTheme.typography.body2.fontSize,
            lineHeight: baseTheme.typography.body2.lineHeight,
            fontWeight: baseTheme.typography.body2.fontWeight,
            [`.${typographyClasses.root}`]: {
              fontSize: baseTheme.typography.body2.fontSize,
              lineHeight: baseTheme.typography.body2.lineHeight,
              fontWeight: baseTheme.typography.body2.fontWeight
            }
          },
          //это отдельно, потому что не ложится на уровне класса выше наряду с input
          [`& .${selectClasses.iconOutlined}`]: {
            width: baseTheme.spacing(2.5),
            height: baseTheme.spacing(2.5),
            right: baseTheme.spacing(0.974)
          }
        },
        [`& .${selectClasses.iconOutlined}`]: {
          width: baseTheme.spacing(3),
          height: baseTheme.spacing(3),
          right: baseTheme.spacing(1.974)
        }
      },
      adornedEnd: {
        paddingRight: baseTheme.spacing(2)
      },
      input: {
        padding: baseTheme.spacing(1.974),
        //отступ хитрый, потому что в макетах он без учёта рамки

        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight
      },
      inputMultiline: {
        padding: 0
      },
      sizeSmall: {
        height: '48px',
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          fontSize: baseTheme.typography.body2.fontSize,
          legend: {
            fontSize: '1.05em'
          }
        },
        [`& .${selectClasses.iconOutlined}`]: {
          width: '22px',
          height: '22px',
          right: baseTheme.spacing(1.474)
        }
      },
      inputSizeSmall: {
        padding: baseTheme.spacing(1.4, 1.974),
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight
      },
      multiline: {
        height: 'auto'
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      sizeSmall: {
        [`.${svgIconClasses.root}`]: {
          width: '1rem',
          height: '1rem'
        }
      },
      sizeMedium: {
        [`.${svgIconClasses.root}`]: {
          width: '1.25rem',
          height: '1.25rem'
        }
      },
      root: _ref => {
        let {
          ownerState
        } = _ref;
        const {
          checked,
          indeterminate
        } = ownerState;
        const rectColor = checked || indeterminate ? baseTheme.palette.secondary.main : baseTheme.palette.secondary.A900;
        return {
          [`&${disabledClassName}`]: {
            color: baseTheme.palette.secondary.main,
            [`.${svgIconClasses.root}`]: {
              rect: {
                fill: rectColor
              }
            }
          }
        };
      }
    }
  },
  MuiRadio: {
    styleOverrides: {
      sizeSmall: {
        [`.${svgIconClasses.root}`]: {
          width: '1rem',
          height: '1rem'
        }
      },
      root: _ref2 => {
        let {
          ownerState
        } = _ref2;
        const {
          checked,
          indeterminate
        } = ownerState;
        const rectColor = checked || indeterminate ? baseTheme.palette.secondary.main : baseTheme.palette.secondary.A900;
        return {
          [`&${disabledClassName}`]: {
            color: baseTheme.palette.secondary.main,
            [`.${svgIconClasses.root}`]: {
              ['> circle:first-child']: {
                fill: rectColor
              }
            }
          }
        };
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        [`&.${formHelperTextClasses.error}`]: {
          borderLeft: `1px solid ${baseTheme.palette.error.main}`
        },
        marginLeft: 0,
        paddingLeft: baseTheme.spacing(2)
      }
    }
  }
});
export default textFields;