import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { ContentWrapper, DataWrapper } from './controls';
import useVisibleSectionLinks from '../../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const ProductOfferListLayout = props => {
  const {
    search,
    banners,
    breadcrumbs,
    aside,
    header,
    children,
    stub,
    loader
  } = props;
  useVisibleSectionLinks();
  return _jsxs(_Fragment, {
    children: [search && _jsx(ScreenNavWrapper, {
      children: search
    }), banners, _jsx(Fade, {
      in: true,
      children: _jsxs(DefaultContentWrapper, {
        children: [breadcrumbs, header, _jsxs(ContentWrapper, {
          children: [aside, _jsx(DataWrapper, {
            children: children
          })]
        }), stub, loader]
      })
    })]
  });
};
export default ProductOfferListLayout;