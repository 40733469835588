import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { parseFiltersFromSearch } from '../utils';
const ProductFilterBehaviorAdapter = props => {
  const {
    onChangeFilter
  } = props;
  const location = useLocation();
  const {
    onSetFilter
  } = useContextHandlers();
  useEffect(() => {
    const filter = parseFiltersFromSearch(location.search);
    onSetFilter(filter);
    onChangeFilter(filter);
  }, [location.search, onSetFilter, onChangeFilter]);
  return null;
};
export default ProductFilterBehaviorAdapter;