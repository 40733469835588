import Splitter from 'presentation/components/common/splitter';
import OrderStub from '../stub';
import { Divider, Stack } from '@mui/material';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const NoAnyOrderStub = () => {
  return _jsxs(Stack, {
    width: "100%",
    children: [_jsx(Divider, {}), _jsx(Splitter, {
      size: 3
    }), _jsx(OrderStub, {
      alignCenter: true
    })]
  });
};
export default NoAnyOrderStub;