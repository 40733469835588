import useProductOfferDetailsData from '../../hooks/useProductOfferDetailsData';
import { useMemo, useState } from 'react';
import CarouselGallery from '../../../../../../components/common/carousels/gallery';
import AppImage from '../../../../../../components/common/images/common';
import MediaPreviewDialog from '../../../../product/details/mediaPreviewDialog';
import { useMediaQuery } from '@mui/material';
import { CarouselWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferCarouselAdapter = props => {
  const {
    id
  } = props;
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const onMediaClick = () => {
    setPreviewDialogOpen(true);
  };
  const onCloseDialog = () => {
    setPreviewDialogOpen(false);
  };
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  const images = productOffer?.images ? productOffer.images : [];
  const thumbChildren = images.map((image, i) => _jsx(AppImage, {
    onClick: () => {},
    src: image.path
  }, i));
  const thumbSwiperProps = useMemo(() => ({
    slidesPerView: 4,
    spaceBetween: 10,
    loop: true
  }), []);
  const mainSwiperProps = useMemo(() => ({
    pagination: !isSmUp,
    navigation: false,
    spaceBetween: 10,
    onClick: onMediaClick,
    loop: true
  }), [isSmUp]);
  if (!productOffer?.images?.length) {
    return null;
  }
  return _jsxs(CarouselWrapper, {
    children: [_jsx(CarouselGallery, {
      swipersGap: 10,
      initSlide: activeSlide,
      thumbSwiperContainerHeight: 112,
      thumbChildren: thumbChildren,
      showThumbs: true,
      onActiveIndexChange: index => setActiveSlide(index),
      thumbSwiperProps: thumbSwiperProps,
      mainSwiperProps: mainSwiperProps,
      children: images.map((image, i) => _jsx(AppImage, {
        onClick: () => {},
        src: image.path
      }, i))
    }), _jsx(MediaPreviewDialog, {
      imageLinks: images,
      initSlide: activeSlide,
      open: previewDialogOpen,
      onClose: onCloseDialog
    })]
  });
};
export default ProductOfferCarouselAdapter;