import { Caption } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsCategoryCaption = _ref => {
  let {
    children
  } = _ref;
  return _jsx(Caption, {
    variant: "subtitle1",
    align: "center",
    children: children
  });
};
export default CmsCategoryCaption;