import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferNdflHelpDialog = _ref => {
  let {
    open,
    description,
    onClose
  } = _ref;
  return _jsx(MPConfirmDialog, {
    open: open,
    maxWidth: "sm",
    title: "\u0412\u043E\u0437\u043C\u043E\u0436\u043D\u043E, \u043E\u043F\u043B\u0430\u0442\u0430 \u041D\u0414\u0424\u041B",
    onClose: onClose,
    buttons: _jsx(MPButton, {
      color: "brand",
      fullWidth: true,
      size: "large",
      onClick: onClose,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    }),
    children: _jsx(Typography, {
      variant: "body2",
      children: description
    })
  });
};
export default OfferNdflHelpDialog;