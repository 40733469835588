import { useSubscribeToUserOfferMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { useCallback, useEffect } from 'react';
import { isOfferSubscriptionSupportedByPromotionType } from '../utils';
const useOfferSubscription = props => {
  const {
    offer,
    offerType,
    promotionType
  } = props;
  const [subscribeToOffer, subscribeToOfferResult] = useSubscribeToUserOfferMutation();
  const {
    isLoading: isFetching,
    error
  } = subscribeToOfferResult;
  const onSubscribe = useCallback(() => {
    subscribeToOffer({
      offerId: offer?.id ?? '',
      offerType,
      subscribe: true
    });
  }, [offer?.id, offerType, subscribeToOffer]);
  const onUnSubscribe = useCallback(() => {
    subscribeToOffer({
      offerId: offer?.id ?? '',
      offerType,
      subscribe: false
    });
  }, [offer?.id, offerType, subscribeToOffer]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const isEnabled = offer?.subscribe ?? false;
  const isSupported = !promotionType || isOfferSubscriptionSupportedByPromotionType(promotionType);
  return {
    isEnabled,
    isFetching,
    isSupported,
    onSubscribe,
    onUnSubscribe
  };
};
export default useOfferSubscription;