import { Fade } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButtonsGroup } from 'presentation/theme/ui-kit/button/group';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { pluralize } from 'presentation/utils';
import OffersTextHeader from '../textHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OffersSearchHeader = props => {
  const {
    category,
    count,
    categories,
    onChangeCategory
  } = props;
  const {
    x3
  } = useGridSpacing();
  return _jsx(Fade, {
    in: true,
    children: _jsxs(MPGrid, {
      container: true,
      spacing: x3,
      justifyContent: "space-between",
      alignItems: "center",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsxs(OffersTextHeader, {
          children: [count === 1 ? 'Найден ' : 'Найдено ', " ", count, ' ', pluralize(count, ['результат', 'результата', 'результатов']), categories.length > 0 && ` в ${categories.length} ${pluralize(categories.length, ['категории', 'категориях', 'категориях'])}`, _jsx(Splitter, {
            size: 1
          })]
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(MPButtonsGroup, {
          color: "secondary",
          exclusive: true,
          items: categories,
          selected: category ? [category] : categories,
          onChange: _ref => {
            let [value] = _ref;
            return onChangeCategory(value);
          }
        })
      })]
    })
  });
};
export default OffersSearchHeader;