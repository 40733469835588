import { Hidden, useMediaQuery } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { SwiperSlide } from 'swiper/react';
import AppSvgIcon from '../../icon';
import { ButtonNext, ButtonPrev, StyledSwiper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Carousel3d = _ref => {
  let {
    children,
    ...swiperProps
  } = _ref;
  const ref = useRef();
  const isXsUp = useMediaQuery(theme => theme.breakpoints.up('xs'));
  const buttonPrevRef = useRef(null);
  const buttonNextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const childrenCount = React.Children.count(children);
  const onPrevClick = () => {
    swiperRef?.slidePrev();
  };
  const onNextClick = () => {
    swiperRef?.slideNext();
  };
  useEffect(() => {
    if (ref.current && swiperRef) {
      if (swiperRef.params.coverflowEffect) {
        const depth = ref.current?.offsetWidth * 0.45;
        swiperRef.params.coverflowEffect.stretch = depth * 1.2;
        swiperRef.params.coverflowEffect.depth = depth;
        swiperRef.update();
      }
    }
  }, [ref, swiperRef]);
  return _jsxs(Wrapper, {
    ref: ref,
    children: [_jsx(StyledSwiper, {
      ...swiperProps,
      effect: "coverflow",
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      runCallbacksOnInit: true,
      navigation: childrenCount ? {
        prevEl: isXsUp ? buttonPrevRef.current : null,
        nextEl: isXsUp ? buttonNextRef.current : null
      } : false,
      modules: [...(swiperProps.modules ?? []), Autoplay, Navigation, EffectCoverflow],
      coverflowEffect: {
        rotate: 0,
        modifier: 1,
        slideShadows: false
      },
      onSwiper: setSwiperRef,
      children: children?.map((child, index) => _jsx(SwiperSlide, {
        children: child
      }, index))
    }), childrenCount > 1 && _jsxs(Hidden, {
      smDown: true,
      children: [_jsx(ButtonPrev, {
        ref: buttonPrevRef,
        onClick: onPrevClick,
        children: _jsx(AppSvgIcon, {
          icon: ArrowLeftIcon
        })
      }), _jsx(ButtonNext, {
        ref: buttonNextRef,
        onClick: onNextClick,
        children: _jsx(AppSvgIcon, {
          icon: ArrowRightIcon
        })
      })]
    })]
  });
};
export default Carousel3d;