import { Fade } from '@mui/material';
import { Characteristics } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferDetailsTabCharacteristics = _ref => {
  let {
    text
  } = _ref;
  return _jsx(Fade, {
    in: true,
    children: _jsx(Characteristics, {
      children: text
    })
  });
};
export default OfferDetailsTabCharacteristics;