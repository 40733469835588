import { MPDialog } from '../../../../../theme/ui-kit/dialog';
import MediaPreviewCarousel from '../../../../../components/common/carousels/mediaPreview';
import { DialogWrapper, MediaPreviewDialogCloseButton, StyledDialogContent } from './controls';
import { CloseIcon } from '../../../../../media/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const MediaPreviewDialog = _ref => {
  let {
    open,
    onClose,
    initSlide = 0,
    imageLinks
  } = _ref;
  return _jsx(MPDialog, {
    PaperComponent: DialogWrapper,
    open: open,
    closeButton: _jsx(MediaPreviewDialogCloseButton, {
      size: "medium",
      variant: "circular",
      disableElevation: false,
      onClick: onClose,
      children: _jsx(CloseIcon, {})
    }),
    fullWidth: true,
    onClose: onClose,
    children: _jsx(StyledDialogContent, {
      children: _jsx(MediaPreviewCarousel, {
        images: imageLinks ?? [],
        initSlide: initSlide
      })
    })
  });
};
export default MediaPreviewDialog;