import { Divider, Typography } from '@mui/material';
import { EOrderByDateType, EOrderItemStatus } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { toRubCurrency } from 'presentation/utils/currency';
import OrderField from '../../../components/orderField';
import BookingOrderItemSlotRange from './slotRange';
import BookingOrderItemSlotMultiple from './slotMultiple';
import { Wrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOrderItem = _ref => {
  let {
    priceItem,
    status,
    slots,
    totalCost,
    qty
  } = _ref;
  if (!priceItem?.service) {
    return null;
  }
  const isCancelled = status === EOrderItemStatus.Cancelled;
  const cost = toRubCurrency(totalCost);
  return _jsxs(Wrapper, {
    isCancelled: isCancelled,
    children: [isCancelled && _jsx(Typography, {
      variant: "body1",
      mb: 1,
      children: "\u041E\u0442\u043C\u0435\u043D\u0435\u043D\u0430"
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(OrderField, {
      label: "\u0423\u0441\u043B\u0443\u0433\u0430",
      text: priceItem.name ?? ''
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(OrderField, {
      label: "\u0422\u0430\u0440\u0438\u0444",
      text: `${priceItem.service.name ?? ''} — ${toRubCurrency(priceItem.price)} ${priceItem.unit?.name ?? ''}`
    }), _jsx(Splitter, {
      size: 2
    }), priceItem.service.orderByDateType === EOrderByDateType.Period && slots?.length && _jsx(BookingOrderItemSlotRange, {
      slots: slots
    }), priceItem.service.orderByDateType === EOrderByDateType.Days && slots?.length && _jsx(_Fragment, {
      children: _jsx(BookingOrderItemSlotMultiple, {
        slots: slots
      })
    }), priceItem.service.orderByDateType === EOrderByDateType.None && typeof qty === 'number' && _jsx(OrderField, {
      label: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E",
      text: String(qty)
    }), _jsx(Splitter, {
      size: 1.75
    }), cost && _jsx(OrderField, {
      label: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C",
      text: cost
    }), _jsx(Splitter, {
      size: 3
    }), _jsx(Divider, {}), _jsx(Splitter, {
      size: 1
    })]
  });
};
export default BookingOrderItem;