import { LocationIcon } from 'presentation/media/icons';
import { UserMenuItem } from '../../../components/menuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemLocality = _ref => {
  let {
    locality,
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: locality,
    icon: _jsx(LocationIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};