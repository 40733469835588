const svgIcons = baseTheme => ({
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeHuge: {
        fontSize: baseTheme.spacing(5)
      },
      fontSizeLarge: {
        fontSize: baseTheme.spacing(4)
      },
      fontSizeMedium: {
        fontSize: baseTheme.spacing(3)
      },
      fontSizeSmall: {
        fontSize: baseTheme.spacing(2.5)
      },
      fontSizeMicro: {
        fontSize: baseTheme.spacing(2)
      }
    }
  }
});
export default svgIcons;