import { useCheckUserCurrentFetchQuery } from 'data/api/agreements';
import ErrorHandler from 'data/network/errorHandler';
import { ENoticeStatus } from 'domain/model/enums';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { isMissingUserAgreementError, isSecurityBusinessError } from 'presentation/utils/auth';
import { useEffect } from 'react';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const CheckSignsContainer = _ref => {
  let {
    children
  } = _ref;
  const {
    logOut
  } = useAuth();
  const {
    tokenParsed
  } = useAuthToken();
  const {
    data: user,
    error: userFetchError,
    isFetching
  } = useCheckUserCurrentFetchQuery({}, {
    skip: !tokenParsed
  });
  const isMissingUserAgreementsError = isMissingUserAgreementError(userFetchError?.data);

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (userFetchError && !isSecurityBusinessError(userFetchError?.data)) {
      ErrorHandler.handleHttpError(userFetchError, () => ({
        status: ENoticeStatus.Error,
        text: 'При получении информации о текущем пользователе произошла ошибка'
      }));
      logOut({
        withTimeout: true
      });
    }
  }, [logOut, userFetchError]);
  if (isFetching || !tokenParsed) {
    return null;
  }
  if (!user && isMissingUserAgreementsError) {
    return null;
  }
  return _jsx(_Fragment, {
    children: children
  });
};
export default CheckSignsContainer;