import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { useAuth } from '../../../../../auth/provider/useAuth';
import { OfferFavoriteCommonContainer } from '../../../../favorite';
import { EOfferType } from '../../../../../../../domain/model/enums';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SmartPhoneFixedBarAdapter = _ref => {
  let {
    id,
    guid
  } = _ref;
  const {
    isAuthenticated
  } = useAuth();
  const {
    onBack
  } = useContextHandlers();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  if (!bookingOffer) {
    return null;
  }
  return _jsx(OfferSmartPhoneFixedBar, {
    onBack: onBack,
    favorite: isAuthenticated && _jsx(OfferFavoriteCommonContainer, {
      guid: guid,
      offerId: id,
      offerType: EOfferType.Booking
    }),
    text: bookingOffer.name
  });
};
export default SmartPhoneFixedBarAdapter;