import { Link, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { CodeWrapper, StyledButton, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ErrorBoundaryContent = _ref => {
  let {
    message,
    stacktrace,
    onLogout
  } = _ref;
  return _jsx(Wrapper, {
    maxWidth: "md",
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      direction: "column",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          variant: "h5",
          gutterBottom: true,
          children: "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430!"
        })
      }), _jsxs(MPGrid, {
        item: true,
        container: true,
        spacing: 2,
        children: [_jsx(MPGrid, {
          item: true,
          children: _jsx(StyledButton, {
            component: Link,
            href: "/",
            children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E"
          })
        }), onLogout && _jsx(MPGrid, {
          item: true,
          children: _jsx(StyledButton, {
            onClick: () => onLogout(),
            children: "\u0412\u044B\u0439\u0442\u0438"
          })
        })]
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          variant: "overline",
          gutterBottom: true,
          children: message
        })
      }), stacktrace && _jsx(MPGrid, {
        item: true,
        children: _jsx(CodeWrapper, {
          children: stacktrace.split('\n').map((item, index) => _jsx("div", {
            children: item
          }, index))
        })
      })]
    })
  });
};
export default ErrorBoundaryContent;