import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AppSvgIcon = _ref => {
  let {
    icon,
    ...others
  } = _ref;
  const Component = icon;
  return _jsx(Component, {
    fontSize: "medium",
    ...others
  });
};
export default AppSvgIcon;