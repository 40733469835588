import { Fade } from '@mui/material';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferDetailsTabDescription = _ref => {
  let {
    description
  } = _ref;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DangerouslyHtmlWrapper, {
      children: description && _jsx("div", {
        dangerouslySetInnerHTML: {
          __html: description
        }
      })
    })
  });
};
export default OfferDetailsTabDescription;