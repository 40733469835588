import axios from 'axios';
import { EPaymentType } from 'domain/model/enums';
import { createCancelToken, getUserQueryParams } from '../utils';
const appendPaymentTypesFilter = params => {
  params.append('paymentType', EPaymentType.Free);
};
const tradeOffer = {
  all: props => {
    const {
      categories,
      statuses,
      partnerId,
      signal,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    if (categories) {
      categories.forEach(item => params.append('category', item));
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    appendPaymentTypesFilter(params);
    return {
      url: `/customers/current/trade-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  collection: props => {
    const {
      id,
      signal
    } = props;
    return {
      url: `/customers/current/trade-offers/collections/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  count: props => {
    const {
      categories,
      statuses,
      partnerId,
      signal,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    params.append('resultType', 'count');
    if (categories) {
      categories.forEach(item => params.append('category', item));
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    appendPaymentTypesFilter(params);
    return {
      url: `/customers/current/trade-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  one: _ref => {
    let {
      id
    } = _ref;
    return {
      url: `/customers/current/trade-offers/${id}`,
      method: 'GET'
    };
  },
  categoriesUsed: _ref2 => {
    let {
      query,
      statuses,
      partnerId,
      onlyLeafCategories,
      signal
    } = _ref2;
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }
    appendPaymentTypesFilter(params);
    return {
      url: '/customers/current/trade-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default tradeOffer;