import { StyledBreadcrumbs } from './controls';
import { BreadcrumbItem } from './item';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BreadcrumbChain = _ref => {
  let {
    items,
    hideRootCrumb
  } = _ref;
  return _jsx(StyledBreadcrumbs, {
    "aria-label": "breadcrumb",
    separator: null,
    gap: "dense",
    children: items.map((breadcrumb, i) => _jsx(BreadcrumbItem, {
      hidden: i === 0 && hideRootCrumb,
      ...breadcrumb
    }, breadcrumb.id))
  });
};