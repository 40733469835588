import { Fade } from '@mui/material';
import React from 'react';
import { Grid } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CardList = _ref => {
  let {
    span = 3,
    lgSpan,
    mdSpan,
    smSpan = 6,
    children
  } = _ref;
  return _jsx(Fade, {
    in: true,
    children: _jsx(Grid, {
      span: span,
      lgSpan: lgSpan,
      mdSpan: mdSpan,
      smSpan: smSpan,
      children: children
    })
  });
};
export default CardList;