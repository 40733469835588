import PromotionTypeText from 'presentation/features/offer/components/claim/obtainingMethod';
import { EOfferStatus } from '../../../../../../../domain/model/enums';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferPromotionTypeAdapter = () => {
  const {
    aspOffer,
    activation
  } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }
  const isVisible = (aspOffer.status === EOfferStatus.Active || aspOffer.status === EOfferStatus.Upcoming) && !activation.isLastActivationPending;
  if (!isVisible) {
    return null;
  }
  return _jsx(PromotionTypeText, {
    promotionType: aspOffer.promotionType
  });
};