import { Typography, useMediaQuery } from '@mui/material';
import AddressCitySingleSelector from 'components/common/address/singleSelector/city';
import { useCallback } from 'react';
import { MPGrid } from 'theme/ui-kit/grid';
import { AddressHelper } from 'utils/address';
import { useWrapper } from './wrapper';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ChangeCityEventContent = _ref => {
  let {
    onNext
  } = _ref;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    value,
    onChangeValue,
    onSaveValue
  } = useWrapper();
  const onChangeLocalCity = useCallback(address => {
    if (!address) {
      onChangeValue(null);
    } else {
      onChangeValue(address);
      if (isSmDown) {
        onSaveValue(address);
        onNext();
      }
    }
  }, [onChangeValue, isSmDown, onSaveValue, onNext]);
  const getOptionLabel = address => new AddressHelper(address).getLocalitySimpleName();
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    children: [_jsx(MPGrid, {
      item: true,
      zero: 12,
      children: _jsx(Typography, {
        variant: "body2",
        children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435, \u0433\u0434\u0435 \u0432\u044B \u043D\u0430\u0445\u043E\u0434\u0438\u0442\u0435\u0441\u044C \u0434\u043B\u044F \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E\u0433\u043E \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439"
      })
    }), _jsx(MPGrid, {
      item: true,
      zero: 12,
      children: _jsx(AddressCitySingleSelector, {
        value: value,
        noOptionsText: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435",
        label: "\u0413\u043E\u0440\u043E\u0434",
        getOptionLabel: getOptionLabel,
        onChange: onChangeLocalCity
      })
    })]
  });
};