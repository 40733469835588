import { useEffect } from 'react';
import AppSvgIcon from '../../icon';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../media/icons';
import { CarouselNavigateButtonNext, CarouselNavigateButtonPrev } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CarouselDefaultNavigation = props => {
  const {
    buttonPrevRef,
    onPrevClick,
    buttonNextRef,
    onNextClick,
    swiper
  } = props;
  useEffect(() => {
    if (!swiper) {
      return;
    }
    swiper.params.navigation.prevEl = buttonPrevRef.current;
    swiper.params.navigation.nextEl = buttonNextRef.current;
    swiper.update();
  }, [buttonNextRef, buttonPrevRef, swiper]);
  return _jsxs(_Fragment, {
    children: [_jsx(CarouselNavigateButtonPrev, {
      ref: buttonPrevRef,
      onClick: onPrevClick,
      children: _jsx(AppSvgIcon, {
        fontSize: "medium",
        icon: ArrowLeftIcon
      })
    }), _jsx(CarouselNavigateButtonNext, {
      ref: buttonNextRef,
      onClick: onNextClick,
      children: _jsx(AppSvgIcon, {
        fontSize: "medium",
        icon: ArrowRightIcon
      })
    })]
  });
};
export default CarouselDefaultNavigation;