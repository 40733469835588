import ContentLoader from 'presentation/components/common/loader';
import useTradeOfferDetailsFetchingStatus from '../hooks/useTradeOfferDetailsFetchingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferDetailsLoaderAdapter = _ref => {
  let {
    id
  } = _ref;
  const isFetching = useTradeOfferDetailsFetchingStatus({
    id
  });
  return isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null;
};
export default TradeOfferDetailsLoaderAdapter;