import { useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import AboutCollapseWrapper from '../components/collapse';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AboutTextSectionsComponent = _ref => {
  let {
    sections,
    expanded,
    isCollapsable,
    onToggle,
    setSectionRef
  } = _ref;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return _jsx(_Fragment, {
    children: sections.map(section => {
      const Component = section.component;
      return _jsxs("div", {
        ref: element => {
          setSectionRef(element, section.id);
        },
        children: [_jsx(Splitter, {
          size: isMobile ? 0 : 4
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(AboutCollapseWrapper, {
            id: section.id,
            title: section.name,
            titleRef: null,
            expanded: expanded?.id === section.id,
            isCollapsable: isCollapsable,
            onToggle: () => onToggle(section),
            children: _jsx(Component, {})
          })
        })]
      }, section.id);
    })
  });
};
export default AboutTextSectionsComponent;