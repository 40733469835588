import HttpClient from 'data/network/http';
import { useSelector } from 'react-redux';
import { authProviderIsAuthenticatedSelector, authProviderIsLoadingSelector } from './store/selectors';
export const useAuth = () => {
  const isLoading = useSelector(authProviderIsLoadingSelector);
  const isAuthenticated = useSelector(authProviderIsAuthenticatedSelector);
  const login = () => {
    if (!isAuthenticated) {
      HttpClient.getInstance().getAuthService()?.login();
    }
  };
  const logOut = props => {
    const propsInternal = {
      ...(props ?? {}),
      redirectToMain: true
    };
    const redirectUri = propsInternal.redirectToMain ? window.location.origin : `${window.location.origin}${window.location.pathname}`;
    const fn = () => HttpClient.getInstance().getAuthService()?.logout({
      redirectUri
    });
    setTimeout(fn, propsInternal.withTimeout ? propsInternal.timeoutMs ?? 5000 : 0);
  };
  return {
    isLoading,
    isAuthenticated,
    login,
    logOut
  };
};