import { useMemo } from 'react';
import PartnerDesksFilterContainer from '../../filter/container';
import usePartnerDeskList from '../provider/usePartnerDeskList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskListFilterAdapter = () => {
  const {
    currentFilter,
    onChangeFilter
  } = usePartnerDeskList();
  return useMemo(() => _jsx(PartnerDesksFilterContainer, {
    value: currentFilter,
    isFetching: false,
    onChange: onChangeFilter
  }), [currentFilter, onChangeFilter]);
};
export default PartnerDeskListFilterAdapter;