import { EOfferStatus } from 'domain/model/enums';
import { OfferActivationButton } from 'presentation/features/offer/components/activationButton/personal';
import { useMemo } from 'react';
import useTradeOfferClaimContext from '../../provider/useTradeOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimActivationAdapter = () => {
  const {
    tradeOffer,
    isActivationAvailable,
    activation,
    onActivate
  } = useTradeOfferClaimContext();
  return useMemo(() => {
    if (isActivationAvailable && tradeOffer && tradeOffer?.status === EOfferStatus.Active) {
      return activation.lastActivation ? null : _jsx(OfferActivationButton, {
        isFetching: activation.activationIsFetching,
        onActivate: onActivate
      });
    } else {
      return null;
    }
  }, [isActivationAvailable, tradeOffer, onActivate, activation.lastActivation, activation.activationIsFetching]);
};