import { useMemo } from 'react';
import HeaderOptions from '../components/options';
import { EHeaderOptionType } from '../components/options/types';
import useHeaderOptions from './useHeaderOptions';
import HeaderUserInfoAdapter from './userInfo';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderOptionsAdapter = () => {
  const options = useHeaderOptions();
  const desktopHeaderOptions = options.filter(o => [EHeaderOptionType.Privilege, EHeaderOptionType.Favorites].includes(o.type));
  desktopHeaderOptions.push({
    type: EHeaderOptionType.User,
    component: _jsx(HeaderUserInfoAdapter, {})
  });
  return useMemo(() => _jsx(HeaderOptions, {
    options: desktopHeaderOptions
  }), [desktopHeaderOptions]);
};
export default HeaderOptionsAdapter;