import PartnerDeskListLoaderAdapter from 'presentation/features/partnerDesk/list/adapters/loader';
import PartnerDeskListDataAdapter from './adapters/data';
import PartnerDeskListFilterAdapter from './adapters/filter';
import PartnerDeskListShowMoreAdapter from './adapters/showMore';
import PartnerDeskListStubAdapter from './adapters/stub';
import PartnerDeskListLayout from './layout';
import PartnerDeskListProvider from './provider/provider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Filter = _jsx(PartnerDeskListFilterAdapter, {});
const Stub = _jsx(PartnerDeskListStubAdapter, {});
const ShowMore = _jsx(PartnerDeskListShowMoreAdapter, {});
const Layout = PartnerDeskListLayout;
const PartnerDeskListContainer = _ref => {
  let {
    guid
  } = _ref;
  return _jsx(PartnerDeskListProvider, {
    guid: guid,
    children: _jsx(Layout, {
      filter: Filter,
      stub: Stub,
      loader: _jsx(PartnerDeskListLoaderAdapter, {
        guid: guid
      }),
      showMore: ShowMore,
      children: _jsx(PartnerDeskListDataAdapter, {})
    })
  });
};
export default PartnerDeskListContainer;