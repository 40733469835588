import { Fade, Typography, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import UserDetailsToolbarLayout from 'presentation/layouts/userDetailsToolbar';
import { ContentWrapper, ListWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserOrdersLayout = props => {
  const {
    header,
    toolbar,
    loader,
    stub,
    children
  } = props;
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      fixed: isLgUp,
      maxWidth: isLgUp ? 'sm' : undefined,
      children: _jsxs(Wrapper, {
        children: [_jsx(Typography, {
          variant: "h1",
          children: header
        }), _jsx(Splitter, {
          size: 3
        }), toolbar && _jsxs(UserDetailsToolbarLayout, {
          children: [toolbar, _jsx(Splitter, {
            size: 3
          })]
        }), _jsxs(ContentWrapper, {
          children: [stub, children && _jsx(ListWrapper, {
            children: children
          })]
        }), loader]
      })
    })
  });
};
export default UserOrdersLayout;