import { useGetCmsContainerBannersContentQuery } from 'data/api/cms';
import { ECmsBannerLinkObjectType, ECmsContainerType } from 'domain/model/enums';
import { buildLinkByProxy } from 'presentation/components/common/links/proxy';
import ContentLoader from 'presentation/components/common/loader';
import { getDataCmsCollectionRoute } from 'presentation/features/data/entry';
import { getCorpOfferDetailsRoute, getCorpOffersSearchRoute } from 'presentation/features/offer/corp/routes';
import { getTradeOfferDetailsRoute, getTradeOffersSearchRoute } from 'presentation/features/offer/trade/routes';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import useBusinessConfig from 'presentation/hooks/useBusinessConfig';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import CmsComponentBanner from '../components/banner';
import { CmsCarouselBanner } from '../components/containerWrapper/carousel/carouselBanner';
import CmsContainerWrapperGrid from '../components/containerWrapper/grid';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsBannersContainer = props => {
  const {
    components,
    header,
    footer,
    layout: Layout,
    containerType,
    offerType,
    showIcon,
    guid,
    onDataLoaded,
    context
  } = props;
  const {
    corpLinkProxyUrl
  } = useBusinessConfig();
  const history = useHistory();
  const bannerIds = useMemo(() => components?.filter(component => !!component.linkObjectId)?.map(component => component.linkObjectId) ?? [], [components]);
  const {
    data: banners,
    isFetching
  } = useGetCmsContainerBannersContentQuery({
    guid,
    bannerIds
  }, {
    skip: !bannerIds.length
  });
  const onClick = banner => {
    switch (banner.linkObjectType) {
      case ECmsBannerLinkObjectType.TradeOffer:
        history.push(getTradeOfferDetailsRoute({
          id: banner.linkObjectId
        }));
        break;
      case ECmsBannerLinkObjectType.TradeOfferCategory:
        history.push(getTradeOffersSearchRoute({
          categoryId: banner.linkObjectId,
          partnerId: context?.partner?.id
        }));
        break;
      case ECmsBannerLinkObjectType.Link:
        banner.url ? window.open(buildLinkByProxy(banner.url, corpLinkProxyUrl), '_blank') : null;
        break;
      case ECmsBannerLinkObjectType.Collection:
        history.push(getDataCmsCollectionRoute({
          id: banner.linkObjectId
        }));
        break;
      case ECmsBannerLinkObjectType.CorpOffer:
        history.push(getCorpOfferDetailsRoute({
          id: banner.linkObjectId
        }));
        break;
      case ECmsBannerLinkObjectType.CorpOfferCategory:
        history.push(getCorpOffersSearchRoute({
          categoryId: banner.linkObjectId,
          partnerId: context?.partner?.id
        }));
        break;
      case ECmsBannerLinkObjectType.Partner:
        history.push(getPartnerDeskDetailsRoute({
          id: banner.linkObjectId
        }));
        break;
      case ECmsBannerLinkObjectType.ProductOfferCategory:
      case ECmsBannerLinkObjectType.Video:
      case ECmsBannerLinkObjectType.ProductOffer:
      case ECmsBannerLinkObjectType.BookingOffer:
      case null:
        break;
    }
  };
  const Wrapper = useMemo(() => {
    switch (containerType) {
      case ECmsContainerType.Banner3Carousel:
        return CmsCarouselBanner;
      case ECmsContainerType.Banner2:
      case ECmsContainerType.Banner4:
      case ECmsContainerType.Banner4High:
        return props => _jsx(CmsContainerWrapperGrid, {
          mobileColumnsCount: 2,
          ...props
        });
      case ECmsContainerType.Banner3:
      case ECmsContainerType.Banner2MobileWide:
        return props => _jsx(CmsContainerWrapperGrid, {
          mobileColumnsCount: 1,
          ...props
        });
      default:
        return CmsContainerWrapperGrid;
    }
  }, [containerType]);
  useEffect(() => {
    if (banners && !isFetching) onDataLoaded?.();
  }, [banners, isFetching, onDataLoaded]);
  const Footer = footer;
  const hasContent = banners && banners.length > 0;
  if (!banners?.length && !isFetching) return null;
  return _jsx(Layout, {
    header: header,
    footer: Footer && _jsx(Footer, {
      hasContent: hasContent
    }),
    loader: isFetching && _jsx(ContentLoader, {}),
    children: hasContent && _jsx(Wrapper, {
      children: banners.map(banner => _jsx(CmsComponentBanner, {
        banner: banner,
        objectType: offerType,
        showIcon: showIcon,
        onClick: () => onClick(banner)
      }, banner.id))
    })
  });
};
export default CmsBannersContainer;