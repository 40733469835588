import axios from 'axios';
import { EOrderRequestDiscriminator } from 'domain/model/enums';
import { createCancelToken, getUserQueryParams } from '../utils';
const getBookingQueryParams = _ref => {
  let {
    minPrice,
    maxPrice,
    ...queryParams
  } = _ref;
  const params = getUserQueryParams(queryParams);
  if (minPrice) {
    params.append('minPrice', minPrice.toString());
  }
  if (maxPrice) {
    params.append('maxPrice', maxPrice.toString());
  }
  return params;
};
export const addAdditionalQueryParams = (params, query) => {
  if (query['query']) {
    params.append('q', query['query']);
  }
  if (query['categories']) {
    query['categories'].forEach(item => params.append('bookingOfferCategory', item));
  }
  if (query['partnerId']) {
    params.append('partnerId', query['partnerId']);
  }
};
const bookingOffer = {
  categories: _ref2 => {
    let {
      signal
    } = _ref2;
    return {
      url: '/customers/current/booking-offers/categories',
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  all: props => {
    const {
      signal,
      categories,
      partnerId,
      services,
      ...queryParams
    } = props;
    const params = getBookingQueryParams(queryParams);
    params.append('resultType', 'list');

    // TODO (@Protopopov Ruslan): это надо бы переписать, щас params мутируется из нижней функции, сложно, тревожно
    addAdditionalQueryParams(params, {
      categories,
      partnerId
    });
    if (services) {
      services.forEach(item => params.append('serviceCategory', item));
    }
    return {
      url: `/customers/current/booking-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  count: props => {
    const {
      signal,
      ...queryParams
    } = props;
    const params = getBookingQueryParams(queryParams);
    params.append('resultType', 'count');
    return {
      url: `/customers/current/booking-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  one: id => {
    return {
      url: `/customers/current/booking-offers/${id}`,
      method: 'GET'
    };
  },
  categoriesUsed: _ref3 => {
    let {
      query,
      partnerId,
      onlyLeafCategories,
      statuses,
      signal
    } = _ref3;
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, {
      partnerId,
      query
    });
    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    return {
      url: '/customers/current/booking-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  createOrder: _ref4 => {
    let {
      signal,
      ...data
    } = _ref4;
    return {
      url: `/customers/current/orders`,
      method: 'POST',
      data: {
        discriminator: EOrderRequestDiscriminator.BookingOrderRequest,
        ...data
      },
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default bookingOffer;