import useTradeOfferCategoriesData from './useTradeOfferCategoriesData';
import useTradeOfferDetailsData from './useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from './useTradeOfferPartnerDeskData';
const useTradeOfferDetailsLoadingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesLoading
  } = useTradeOfferCategoriesData();
  const {
    isTradeOfferLoading
  } = useTradeOfferDetailsData(id);
  const {
    isPartnerDeskLoading
  } = useTradeOfferPartnerDeskData(id);
  return isCategoriesLoading || isTradeOfferLoading || isPartnerDeskLoading;
};
export default useTradeOfferDetailsLoadingStatus;