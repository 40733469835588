import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import useProductOfferPartnerDeskData from 'presentation/features/offer/product/details/hooks/useProductOfferPartnerDeskData';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferPartnerInfoAdapter = _ref => {
  let {
    id
  } = _ref;
  const history = useHistory();
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  const {
    partnerDesk
  } = useProductOfferPartnerDeskData(id);
  const onPartnerClick = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({
        id: partnerDesk.id,
        tab: EPartnerDeskDetailsTab.ProductOffers
      }));
    }
  };
  if (!productOffer) {
    return null;
  }
  return _jsx(OfferPartnerInfo, {
    partner: productOffer.partner,
    onClick: onPartnerClick
  });
};
export default ProductOfferPartnerInfoAdapter;