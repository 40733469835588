import { Hidden, Typography } from '@mui/material';
import CodeTitle from 'presentation/components/common/codeTitle/title';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Bold } from '../../../../user/details/activations/controls';
import { SidebarWrapper } from '../controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemRejectedSidebar = props => {
  const {
    offerName,
    offerCode,
    comment,
    onOfferClick
  } = props;
  const {
    x2
  } = useGridSpacing();
  return _jsx(SidebarWrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: x2,
      direction: "column",
      children: [_jsx(Hidden, {
        smUp: true,
        children: _jsx(MPGrid, {
          item: true,
          children: _jsx(Bold, {
            onClick: onOfferClick,
            children: offerName
          })
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(CodeTitle, {
          code: offerCode
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          color: "primary",
          gutterBottom: true,
          children: "\u041E\u0442\u043A\u0430\u0437"
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          variant: "body2",
          children: comment ?? 'Причина не установлена'
        })
      })]
    })
  });
};
export default ActivationListItemRejectedSidebar;