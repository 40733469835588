import { useSelector } from 'react-redux';
import { aspOfferListNoDataSelector, aspOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import AspOffersStub from '../stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(aspOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(aspOfferListNoDataSelector);
  if (noData) {
    return _jsx(AspOffersStub, {
      isEmptySearch: isEmptySearch
    });
  }
  return null;
};