import OfferDetailsOnlyTitle from 'presentation/features/offer/components/detailsOnlyTitle';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferDetailsOnlyTitleAdapter = props => {
  const {
    id
  } = props;
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  if (!productOffer) {
    return null;
  }
  return _jsx(OfferDetailsOnlyTitle, {
    title: productOffer.name
  });
};
export default ProductOfferDetailsOnlyTitleAdapter;