import ProductFilterButton from 'presentation/features/offer/product/filter/button/component';
import ProductFilterDialog from 'presentation/features/offer/product/filter/dialog';
import { useState } from 'react';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductFilterButtonContainer = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const handleClickOpenFilterDialog = () => {
    setDialogVisible(true);
  };
  const handleClickCloseFilterDialog = () => {
    setDialogVisible(false);
  };
  return _jsxs(_Fragment, {
    children: [_jsx(ProductFilterButton, {
      onClick: handleClickOpenFilterDialog
    }), _jsx(ProductFilterDialog, {
      open: dialogVisible,
      onClose: handleClickCloseFilterDialog
    })]
  });
};
export default ProductFilterButtonContainer;