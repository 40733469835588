import IMask from 'imask';
const prefix = {
  common: '7',
  legacy: '8',
  view: '+7'
};
const length = 11;
const regexp = new RegExp(`^[${prefix.common}]{1}[\\d]{${length - 1}}$`);
const viewMask = `${prefix.view} (000) 000 00 00`;
const viewIMask = `+{${prefix.common}} (000) 000 00 00`;
const viewRegexp = new RegExp(`^(${prefix.common})([\\d]{3})([\\d]{3})([\\d]{2})([\\d]{2})$`);
export const phoneOptions = {
  length,
  regexp,
  viewMask,
  viewIMask,
  viewRegexp
};
export const isValidPhoneFormat = phone => {
  return phoneOptions.regexp.test(phone) && phone.length === phoneOptions.length;
};
export const phoneFormatView = phone => {
  const match = phone.match(phoneOptions.viewRegexp);
  if (match && match.length > 5) {
    return [prefix.view, ' (', match[2], ') ', match[3], ' ', match[4], ' ', match[5]].join('');
  }
  return phone;
};

/**
 * Маска для ввода номера телефона
 * @description Можем маску использовать вне компонента Input задавать значение через phoneMask.resolve(value)
 * вывести обработанное значение маской через phoneMask.value
 */
export const phoneMask = IMask.createMask({
  mask: phoneOptions.viewIMask,
  lazy: true,
  placeholderChar: ' ',
  prepare: (value, mask) => {
    if (!value) {
      return value;
    }
    // Если вставляем целиком номер с 8 то ее удаляем
    if (mask.unmaskedValue.length === 0 && value.startsWith(prefix.legacy)) {
      return prefix.common + value.slice(1);
    }
    return value;
  }
});