import SearchOffersHeaderAdapter from 'presentation/features/search/offers/adapters/header';
import SearchOffersLoaderAdapter from 'presentation/features/search/offers/adapters/loader';
import SearchOffersStubAdapter from 'presentation/features/search/offers/adapters/stub';
import SearchResultsLayout from '../../../layouts/searchResults';
import SearchOffersDataAdapter from './adapters/data';
import { SearchOffersContext } from './context';
import useSearchResultsHandlers from './hooks/useSearchOffers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = SearchResultsLayout;
const Header = _jsx(SearchOffersHeaderAdapter, {});
const Stub = _jsx(SearchOffersStubAdapter, {});
const Loader = _jsx(SearchOffersLoaderAdapter, {});
const SearchOffersContainer = props => {
  const {
    guid,
    query,
    offerType = []
  } = props;
  const handlers = useSearchResultsHandlers({
    guid,
    query,
    offerType
  });
  return _jsx(SearchOffersContext.Provider, {
    value: handlers,
    children: _jsx(Layout, {
      header: Header,
      stub: Stub,
      loader: Loader,
      children: _jsx(SearchOffersDataAdapter, {
        guid: guid
      })
    })
  });
};
export default SearchOffersContainer;