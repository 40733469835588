import { createSlice } from '@reduxjs/toolkit';
import { EOfferListType } from 'domain/model/enums';
import { productOffersDefaultParams } from 'presentation/features/offer/product/utils';
const initialState = {
  guid: null,
  common: productOffersDefaultParams,
  filter: null,
  processes: [],
  isNewFetching: true,
  isNewSearchFetching: true
};
const slice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    productOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.guid = payload;
    },
    productOffersSetPageSize: (state, _ref2) => {
      let {
        payload: pageSize
      } = _ref2;
      state.common.pageSize = pageSize;
    },
    productOffersSetPage: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.common.page = payload;
    },
    productOffersSetSort: (state, _ref4) => {
      let {
        payload: sort
      } = _ref4;
      state.common.sort = sort;
    },
    productOffersSetArgs: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      state.common = payload;
    },
    productOffersSetFavoritesChangeProcess: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      const process = state.processes.find(p => p.id === payload.id);
      if (process) {
        process.action = payload.action;
        process.id = payload.id;
        process.isFetching = payload.isFetching;
        process.isFetched = payload.isFetched;
        process.isFailed = payload.isFailed;
        process.isCancelled = payload.isCancelled;
      } else {
        state.processes.push(payload);
      }
    },
    productOffersClearFavoritesChangeProcess: state => {
      state.processes = [];
    },
    productOffersSetFilter: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      state.filter = payload;
    },
    productOffersSetIsNewFetching: (state, _ref8) => {
      let {
        payload
      } = _ref8;
      state.isNewFetching = payload;
    },
    productOffersSetIsNewSearchFetching: (state, _ref9) => {
      let {
        payload
      } = _ref9;
      state.isNewSearchFetching = payload;
    }
  }
});
export const {
  productOffersSetPageSize,
  productOffersSetPage,
  productOffersSetFavoritesChangeProcess,
  productOffersClearFavoritesChangeProcess,
  productOffersStartSession,
  productOffersSetSort,
  productOffersSetArgs,
  productOffersSetFilter,
  productOffersSetIsNewFetching,
  productOffersSetIsNewSearchFetching
} = slice.actions;
export default slice.reducer;