import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import UserOrderContainer from '../../features/user/details/order/container';
import { useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserOrderScreen = () => {
  const {
    id
  } = useParams();
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      children: _jsx(UserOrderContainer, {
        guid: guid,
        id: id
      })
    })
  });
};
export default UserOrderScreen;