import { Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPTab, MPTabs } from 'presentation/theme/ui-kit/tab';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFavoritesTabs = _ref => {
  let {
    tab,
    tabs,
    onChangeTab
  } = _ref;
  return _jsx(MPGrid, {
    container: true,
    spacing: 2,
    alignItems: "center",
    justifyContent: "space-between",
    children: _jsx(MPGrid, {
      item: true,
      zero: 12,
      md: true,
      children: _jsx(MPTabs, {
        value: tab?.value,
        textColor: "primary",
        variant: "scrollable",
        scrollButtons: false,
        onChange: (event, tab) => onChangeTab(tab),
        children: tabs.map(item => _jsx(MPTab, {
          label: _jsxs(Typography, {
            component: "div",
            children: [item.label, isNumber(item.count) && item.count > 0 && _jsxs(Typography, {
              color: "secondary",
              component: "span",
              children: ['\u00A0\u00A0', item.count]
            })]
          }),
          value: item.value
        }, item.value))
      })
    })
  });
};
export * from './corpOffers';
export * from './productOffers';
export * from './tradeOffers';
export * from './bookingsOffers';
export * from './offers';