import { EOrderDiscriminator } from 'domain/model/enums';
import SmartPhoneFixedBar from 'presentation/components/common/smartPhoneFixedBar';
import { useContextHandlers } from '../../../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferOrderSmartPhoneFixedBarAdapter = _ref => {
  let {
    order
  } = _ref;
  const {
    goBack
  } = useContextHandlers();
  if (!order || order?.discriminator !== EOrderDiscriminator.BookingOrder) {
    return null;
  }
  return _jsx(SmartPhoneFixedBar, {
    onBack: goBack,
    text: order?.offer?.name ? `${order.offer.name} – Заказ №: ${order.number}` : ''
  });
};
export default BookingOfferOrderSmartPhoneFixedBarAdapter;