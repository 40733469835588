import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCorpOffersContextHandlers } from '../../../hooks/useCorpOffersContextHandlers';
import { partnerDeskDetailsCorpOffersCommonArgsSelector, partnerDeskDetailsGuidSelector } from '../../../store/selectors';
import PartnerDeskCorpOffersDataContainer from '../dataContainer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Common;
const PartnerDeskCorpOffersCommonAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsCorpOffersCommonArgsSelector);
  const handlers = useCorpOffersContextHandlers();
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskCorpOffersDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangeCorpOffersPage,
    onShowCard: handlers.onShowCorpOffersCard
  }), [args, currentGuid, guid, handlers.onChangeCorpOffersPage, handlers.onShowCorpOffersCard]);
};
export default PartnerDeskCorpOffersCommonAdapter;