import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SearchOffersDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { searchOffersArgsSelector, searchOffersGuidSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SearchOffersDataAdapter = _ref => {
  let {
    guid
  } = _ref;
  const handlers = useContextHandlers();
  const currentGuid = useSelector(searchOffersGuidSelector);
  const args = useSelector(searchOffersArgsSelector);
  return useMemo(() =>
  //  проверка на разность guid при апдейте компонента
  //  если разные - не рендерим, чтобы не показывать старые данные
  guid !== currentGuid || !args ? null : _jsx(SearchOffersDataContainer, {
    guid: guid,
    args: args,
    onLoadMore: handlers.onLoadMore,
    onShowCard: handlers.onShowCard
  }), [guid, currentGuid, args, handlers.onLoadMore, handlers.onShowCard]);
};
export default SearchOffersDataAdapter;