import { Content } from './controls';
import { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ExpandableHTML } from 'features/offer/booking/components/serviceSelector/controls';
import { ExpandableText } from '../../../expandableText';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ContentLimiterTooltipLayout = props => {
  const {
    children,
    rows = 1,
    tooltipProps
  } = props;
  const refContent = useRef(null);
  const [expandable, setExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.clientHeight < refContent.current.scrollHeight) {
        setExpandable(false);
      } else {
        setExpandable(true);
      }
    }
  }, [refContent, children]);
  if (isMobile) {
    return _jsx(ExpandableText, {
      as: "div",
      lines: 1,
      withOutButton: true,
      expanded: isExpanded,
      onExpand: () => setIsExpanded(!isExpanded),
      children: _jsx(ExpandableHTML, {
        children: _jsx(Typography, {
          variant: 'body2',
          color: 'textSecondary',
          children: children
        })
      })
    });
  }
  return _jsx(Tooltip, {
    arrow: true,
    title: children,
    placement: 'bottom',
    disableFocusListener: expandable,
    disableHoverListener: expandable,
    ...tooltipProps,
    children: _jsx(Content, {
      rows: rows,
      ref: refContent,
      variant: 'body2',
      color: 'textSecondary',
      children: children
    })
  });
};