import { skipToken } from '@reduxjs/toolkit/query';
import { useGetPartnerDeskQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { useEffect } from 'react';
const useProductOfferPartnerDeskData = id => {
  const {
    productOffer,
    isProductOfferFetched
  } = useProductOfferDetailsData(id);
  const partnerDeskId = isProductOfferFetched ? productOffer?.partner.id : undefined;
  const {
    data: partnerDesk,
    error: partnerDeskFetchError,
    isError: isPartnerDeskError,
    isFetching: isPartnerDeskFetching
  } = useGetPartnerDeskQuery(partnerDeskId ?? skipToken);
  useEffect(() => {
    if (partnerDeskFetchError) {
      ErrorHandler.handleHttpError(partnerDeskFetchError);
    }
  }, [partnerDeskFetchError]);
  return {
    partnerDesk,
    partnerDeskFetchError,
    isPartnerDeskError,
    isPartnerDeskFetching
  };
};
export default useProductOfferPartnerDeskData;