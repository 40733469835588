import { useMemo } from 'react';
import { EPartnerDeskDetailsTab, getPartnerDeskDetailsFirstActiveTab } from '../utils';
import { useGetPartnerDeskData } from './useGetPartnerDeskData';
import { getPartnerDeskDetailsTabList } from '../utils/getPartnerDeskDetailsTabList';
export const usePartnerDeskTabInfo = props => {
  const {
    guid,
    tab,
    id,
    categoryId,
    isCorpUser,
    bonusUnavailableReason,
    isCorpOfferFavoritesAllowed,
    isProductOfferFavoritesAllowed,
    isBookingOfferFavoritesAllowed,
    isTradeOfferFavoritesAllowed
  } = props;
  const {
    isFetching,
    partnerWindowContainersCount,
    tradeOffersCount,
    aspOffersCount,
    corpOffersCount,
    productOffersCount,
    bookingOffersCount,
    partnerDesk,
    partnerWindow
  } = useGetPartnerDeskData({
    id,
    guid,
    categoryId,
    tab,
    isCorpUser,
    bonusUnavailableReason
  });
  const tabList = useMemo(() => partnerDesk ? getPartnerDeskDetailsTabList({
    partnerDesk,
    isFetching,
    partnerWindowContainersCount,
    tradeOffersCount,
    aspOffersCount,
    isCorpUser,
    corpOffersCount,
    productOffersCount,
    bookingOffersCount
  }) : [], [partnerDesk, isFetching, partnerWindowContainersCount, tradeOffersCount, aspOffersCount, isCorpUser, corpOffersCount, productOffersCount, bookingOffersCount]);
  const tabToDisplay = useMemo(() => getPartnerDeskDetailsFirstActiveTab(tab, tabList), [tabList, tab]);
  const favoritesAllowed = useMemo(() => {
    switch (tabToDisplay?.value ?? null) {
      case EPartnerDeskDetailsTab.TradeOffers:
        return isTradeOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.CorpOffers:
        return isCorpOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.ProductOffers:
        return isProductOfferFavoritesAllowed;
      case EPartnerDeskDetailsTab.BookingOffers:
        return isBookingOfferFavoritesAllowed;
      default:
        return false;
    }
  }, [isBookingOfferFavoritesAllowed, isCorpOfferFavoritesAllowed, isProductOfferFavoritesAllowed, isTradeOfferFavoritesAllowed, tabToDisplay?.value]);
  return {
    isFetching,
    corpOffersCount,
    tradeOffersCount,
    aspOffersCount,
    productOffersCount,
    bookingOffersCount,
    partnerWindowContainersCount,
    partnerWindow,
    partnerDesk,
    tabToDisplay,
    tabList,
    favoritesAllowed
  };
};