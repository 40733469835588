import { createAppTheme, MPThemeProvider, theme } from 'presentation/theme';
import useUserTheme from './useUserTheme';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserThemeProvider = _ref => {
  let {
    children
  } = _ref;
  const externalTheme = useUserTheme();
  let appTheme = theme;
  if (externalTheme) {
    appTheme = createAppTheme(externalTheme);
  }
  return _jsx(MPThemeProvider, {
    theme: appTheme,
    children: children
  });
};