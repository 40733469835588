import { Typography } from '@mui/material';
import ClaimMessage from 'presentation/features/offer/components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimPausedMessage = () => {
  return _jsx(ClaimMessage, {
    type: "info",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0432\u0440\u0435\u043C\u0435\u043D\u043D\u043E \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E"
    })
  });
};