import StubCommon from 'presentation/components/common/stub/common';
import { offersStub } from 'presentation/media/images';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOffersStub = props => {
  const {
    isEmptySearch
  } = props;
  return isEmptySearch ? _jsx(StubCommon, {
    title: "\u041A \u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, \u0434\u043B\u044F \u0432\u0430\u0441 \u043D\u0435\u0442 \u043F\u043E\u0434\u0445\u043E\u0434\u044F\u0449\u0438\u0445 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439 \u0431\u043E\u043D\u0443\u0441\u043D\u043E\u0433\u043E \u043F\u0430\u043A\u0435\u0442\u0430",
    text: "\u041D\u043E \u043C\u044B \u0441\u0442\u0430\u0440\u0430\u0435\u043C\u0441\u044F \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u0443 \u0441\u0438\u0442\u0443\u0430\u0446\u0438\u044E",
    image: offersStub
  }) : _jsx(StubCommon, {
    title: "\u041F\u043E \u0432\u0430\u0448\u0435\u043C\u0443 \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
    text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043E\u043A\u0440\u0430\u0442\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441 \u0438\u043B\u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u0433\u043E \u043F\u043E-\u0434\u0440\u0443\u0433\u043E\u043C\u0443",
    image: offersStub
  });
};
export default CorpOffersStub;