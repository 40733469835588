import { Typography } from '@mui/material';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CodeTitle = _ref => {
  let {
    code,
    text = 'Код предложения'
  } = _ref;
  return _jsxs(Typography, {
    variant: "body2",
    color: "textSecondary",
    gutterBottom: true,
    children: [text, ": ", code]
  });
};
export default CodeTitle;