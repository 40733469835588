import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserActivationsContainer from 'presentation/features/user/details/activations/container';
import ScreenLayout from 'presentation/layouts/screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserActivationsScreen = () => {
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(UserActivationsContainer, {
        guid: guid
      })
    })
  });
};
export default UserActivationsScreen;