import { cartApi, cartApiEndpointsList, useDeleteCartItemMutation, useGetCartQuery } from 'data/api/cart';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useWatchApiMutations from '../../../hooks/useWatchApiMutations';
import { getProductListRoute, getProductOfferDetailsRoute } from '../../offer/product/entry';
import CartEmpty from '../components/cartEmpty';
import CartItemsByPartners from '../components/cartPartnerItems';
import CartOrderCreateHelp from '../components/orderCreateHelp';
import CartSidebarContainer from '../sidebar/container';
import { isCartItemEnabled } from '../utils';
import CartDetailsLayout from './layout';
import { cartDetailsGuidSelector, cartDetailsSelectedSelector } from './store/selectors';
import { cartDetailsSelect, cartDetailsStartSession, cartDetailsToggleSelected } from './store/slice';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = CartDetailsLayout;
const CartDetailsContainer = _ref => {
  let {
    guid
  } = _ref;
  const history = useHistory();
  const dispatch = useDispatch();

  // единожды при монтировании смотрим нет ли действующих запросов со стороны cartApi
  const mutationsLoading = useWatchApiMutations({
    deps: [],
    api: cartApi,
    endpoints: cartApiEndpointsList
  });

  //ошибку обрабатывать ну нужно, потому что это делается на уровне главной кнопки, которая всегда видна
  const {
    data: cart,
    isFetching,
    isError,
    isSuccess
  } = useGetCartQuery(undefined, {
    refetchOnMountOrArgChange: false,
    skip: mutationsLoading
  });
  const [deleteCartItem, {
    error: deleteItemError,
    reset: resetDeleteItemError
  }] = useDeleteCartItemMutation();
  const currentGuid = useSelector(cartDetailsGuidSelector);
  const selected = useSelector(cartDetailsSelectedSelector);
  const totalCount = cart?.items?.length ?? 0;
  const isEmpty = cart && totalCount === 0 && !isFetching && !isError;
  const items = useMemo(() => cart?.items ?? [], [cart?.items]);
  const itemsToOrder = useMemo(() => items.filter(item => isCartItemEnabled(item) && selected.some(s => s.id === item.id)), [selected, items]);
  const onOpenProducts = () => {
    history.push(getProductListRoute());
  };
  const onOpenProductDetails = offer => {
    history.push(getProductOfferDetailsRoute({
      id: offer.id
    }));
  };
  const onRemoveItems = items => {
    items.map(item => deleteCartItem({
      id: item.id
    }));
  };
  const onToggleSelected = items => {
    dispatch(cartDetailsToggleSelected(items));
  };
  const onAddOrRemoveFavorite = (/*offer: CartItem['offer']*/
  ) => {
    /*addOrRemoveFavorite(EOfferType.Product, offer);*/
  };

  /*const { addOrRemove: addOrRemoveFavorite, isFetching: isFavoriteFetching } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
    },
  });*/

  useEffect(() => {
    if (deleteItemError) {
      ErrorHandler.handleHttpError(deleteItemError);
    }
  }, [deleteItemError]);

  //начало сеанса работы с фичей
  useEffect(() => {
    dispatch(cartDetailsStartSession({
      guid,
      items
    }));
  }, [dispatch, guid, items]);

  //выделение всех элементов при переходе в корзину
  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    //если выделенных совсем нет то считаем что только что накидали в корзину и выделим всё сразу
    if (!selected.length) {
      const enabledItems = items.filter(item => isCartItemEnabled(item));
      if (enabledItems.length) {
        dispatch(cartDetailsSelect(enabledItems));
      }
    }
    //удаляем items и selected из зависимостей чтобы работало только на mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSuccess]);

  //очистка ошибки мутации
  useEffect(() => {
    return () => {
      resetDeleteItemError();
    };
  }, [resetDeleteItemError]);

  //подписка на стороннее изменение корзины во время открытия, чтобы перезапросить её сразу
  /*useEffect(() => {
      Promise.all(dispatch(cartApi.util.getRunningMutationsThunk())).then(refetch);
    }, [dispatch, refetch]);*/

  if (currentGuid !== guid) {
    return null;
  }
  const showContent = totalCount > 0;
  return _jsx(Layout, {
    title: showContent && 'Корзина',
    sidebar: showContent && _jsx(CartSidebarContainer, {
      items: itemsToOrder
    }),
    help: showContent && _jsx(CartOrderCreateHelp, {}),
    stub: isEmpty && _jsx(CartEmpty, {
      onOpenProducts: onOpenProducts
    }),
    loader: isFetching && mutationsLoading && _jsx(ContentLoader, {}),
    children: showContent && _jsx(CartItemsByPartners, {
      isFavoriteFetching: () => false /*isFavoriteFetching*/,
      items: items,
      selected: selected,
      onToggleSelected: onToggleSelected,
      isCartItemEnabled: isCartItemEnabled,
      onRemoveItems: onRemoveItems,
      onOpenProductDetails: onOpenProductDetails,
      onAddOrRemoveFavorite: onAddOrRemoveFavorite
    })
  });
};
export default CartDetailsContainer;