import { Redirect, Route } from 'react-router';
import { Feature } from '../feature';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const RouteByFeature = _ref => {
  let {
    feature,
    defaultRoute = '/',
    ...routeProps
  } = _ref;
  return _jsx(Feature, {
    feature: feature,
    stubComponent: _jsx(Redirect, {
      to: defaultRoute
    }),
    children: _jsx(Route, {
      ...routeProps
    })
  });
};