import { MPConfirmDialog } from '../../../../theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutFeedbackLayout = props => {
  return _jsx(MPConfirmDialog, {
    maxWidth: "xs",
    fullScreenBreakpoint: "sm",
    scroll: "body",
    ...props
  });
};
export default AboutFeedbackLayout;