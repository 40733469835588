import { Typography } from '@mui/material';
import DottedButton from 'presentation/components/common/buttons/dotted';
import ContentLoader from 'presentation/components/common/loader';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { ButtonWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OfferActivationButton = props => {
  const {
    isFetching,
    onActivate
  } = props;
  return _jsx(MPGrid, {
    container: true,
    alignItems: "center",
    spacing: 1,
    children: _jsx(MPGrid, {
      item: true,
      zero: 12,
      children: _jsx(ButtonWrapper, {
        children: _jsxs(DottedButton, {
          fullWidth: true,
          disabled: isFetching,
          onClick: onActivate,
          children: [_jsx(Typography, {
            variant: "h2",
            children: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C"
          }), isFetching && _jsx(ContentLoader, {})]
        })
      })
    })
  });
};