import { Link } from 'react-router-dom';
import { SubCategoryLinkItem } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferSubCategoryLinkItem = props => {
  return _jsx(SubCategoryLinkItem, {
    variant: "body1",
    color: "text.primary",
    underline: 'none',
    component: Link,
    ...props
  });
};
export default OfferSubCategoryLinkItem;