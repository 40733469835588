import { useEffect, useState } from 'react';
export const useElementRect = _ref => {
  let {
    active = true,
    element
  } = _ref;
  const [elementRect, setElementRect] = useState(null);
  const [resizeWatcher, setResizeWatcher] = useState(Symbol());
  const elementNode = typeof element === 'string' ? document.querySelector(element) : element;
  useEffect(() => {
    if (!elementNode || !active) {
      return;
    }
    const rect = elementNode.getBoundingClientRect();
    setElementRect(rect);
  }, [elementNode, active, resizeWatcher]);
  useEffect(() => {
    const listener = () => setResizeWatcher(Symbol());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
  return {
    elementRect
  };
};