import { Typography } from '@mui/material';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserPrompt = _ref => {
  let {
    isVisible,
    onCancel,
    onProceed,
    title,
    text
  } = _ref;
  return _jsx(MPConfirmDialog, {
    open: isVisible,
    text: _jsx(Typography, {
      variant: "h2",
      component: "span",
      color: "textPrimary",
      mb: 2,
      children: title
    }),
    maxWidth: "xs",
    onClose: onCancel,
    buttons: _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      flexDirection: "column",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(MPButton, {
          fullWidth: true,
          size: "large",
          color: "brand",
          onClick: onProceed,
          children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(MPButton, {
          fullWidth: true,
          size: "large",
          variant: "text",
          color: "brand",
          onClick: onCancel,
          children: "\u041E\u0442\u043C\u0435\u043D\u0430"
        })
      })]
    }),
    children: text
  });
};
export default UserPrompt;