import { Container, Stack, Toolbar, useMediaQuery } from '@mui/material';
import FooterRegister from 'presentation/features/footerRegister/footerRegister';
import { useSelector } from 'react-redux';
import { bottomNavigationVisibleSelector, headerCatalogDialogVisibleSelector, headerVisibleSelector } from '../store/selectors';
import { BottomBar, BottomMenuWrapper, ContentWrapperArea, EHeaderGridAreas, TopBar, TopBarContainer, Wrapper } from './controls';
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const HeaderVisibleManager = _ref => {
  let {
    children
  } = _ref;
  const isVisible = useSelector(headerVisibleSelector);
  const isCatalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  return isVisible || isCatalogVisible ? _jsx(_Fragment, {
    children: children
  }) : null;
};
const MobileHeaderVisibleManager = _ref2 => {
  let {
    children
  } = _ref2;
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isVisible = useSelector(bottomNavigationVisibleSelector);
  const isCatalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  if (!isVisible) {
    return null;
  }
  if (isCatalogVisible && isSmDown) {
    return null;
  }
  return _jsx(_Fragment, {
    children: children
  });
};
const HeaderLayout = props => {
  const {
    logo,
    links,
    search,
    cancel,
    location,
    catalogButton,
    catalog,
    options,
    mobileOptions,
    dialogs,
    balances
  } = props;
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return _jsxs(Wrapper, {
    position: "sticky",
    color: "default",
    elevation: 0,
    children: [_jsxs(HeaderVisibleManager, {
      children: [isLgUp && _jsx(TopBar, {
        children: _jsxs(TopBarContainer, {
          children: [_jsx(ContentWrapperArea, {
            area: EHeaderGridAreas.Location,
            children: location
          }), _jsx(ContentWrapperArea, {
            area: EHeaderGridAreas.Links,
            children: links
          }), balances && _jsx(ContentWrapperArea, {
            area: EHeaderGridAreas.Balances,
            children: balances
          })]
        })
      }), _jsx(Toolbar, {
        disableGutters: true,
        children: _jsx(Container, {
          children: _jsx(Stack, {
            children: _jsxs(BottomBar, {
              children: [isLgUp && _jsx(ContentWrapperArea, {
                area: EHeaderGridAreas.Logo,
                children: logo
              }), isMdUp && _jsx(ContentWrapperArea, {
                area: EHeaderGridAreas.Catalog,
                children: catalogButton
              }), _jsx(ContentWrapperArea, {
                area: EHeaderGridAreas.Search,
                children: search
              }), cancel && isSmDown && _jsx(ContentWrapperArea, {
                area: EHeaderGridAreas.Cancel,
                children: cancel
              }), options && isLgUp && _jsx(ContentWrapperArea, {
                area: EHeaderGridAreas.Options,
                children: options
              })]
            })
          })
        })
      })]
    }), isLgDown && _jsx(MobileHeaderVisibleManager, {
      children: _jsx(FooterRegister, {
        name: "bottomHeaderMenu",
        children: footerRef => {
          return _jsx(BottomMenuWrapper, {
            ref: footerRef,
            role: "menubar",
            children: _jsx(Container, {
              fixed: true,
              children: mobileOptions
            })
          });
        }
      })
    }), dialogs, catalog]
  });
};
export default HeaderLayout;