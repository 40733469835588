import { SlotsGridArea } from '../layout/controls';
import { ECorpOfferClaimSlotNameArea } from '../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferClaimPromotionSlot = _ref => {
  let {
    children
  } = _ref;
  return _jsx(SlotsGridArea, {
    area: ECorpOfferClaimSlotNameArea.promotion,
    children: children
  });
};
export default CorpOfferClaimPromotionSlot;