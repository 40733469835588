import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { productOffersFilterClear, productOffersFilterSet, productOffersFilterSetPrice } from '../store/slice';
export const useProductOfferFilter = () => {
  const dispatch = useDispatch();
  const onChangePrice = useCallback(value => {
    dispatch(productOffersFilterSetPrice(value));
  }, [dispatch]);
  const onSetFilter = useCallback(filter => {
    dispatch(productOffersFilterSet(filter));
  }, [dispatch]);
  const onClearFilter = useCallback(() => {
    dispatch(productOffersFilterClear());
  }, [dispatch]);
  return {
    onChangePrice,
    onSetFilter,
    onClearFilter
  };
};