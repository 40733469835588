import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { historyExtensionsCollect } from './store/slice';
const HistoryExtensionContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(historyExtensionsCollect({
      location: history.location,
      action: 'PUSH'
    }));
    history?.listen((location, action) => {
      dispatch(historyExtensionsCollect({
        location,
        action
      }));
    });
  }, [dispatch, history]);
  return null;
};
export default HistoryExtensionContainer;