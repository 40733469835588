import { ChangeCityEventActions } from './actions';
import { ChangeCityEventTitle } from './title';
import { changeCityEventType } from './types';
import { ChangeCityEventWrapper } from './wrapper';
import { ChangeCityEventContent } from './content';
export const createEvent = payload => {
  return {
    required: true,
    type: changeCityEventType,
    wrapper: ChangeCityEventWrapper,
    title: ChangeCityEventTitle,
    content: ChangeCityEventContent,
    actions: ChangeCityEventActions,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};