import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CommonHelpDialog = props => {
  const {
    open,
    title,
    text,
    onClose
  } = props;
  return _jsx(MPConfirmDialog, {
    open: open,
    title: title,
    maxWidth: "xs",
    fullScreenBreakpoint: "sm",
    buttons: _jsx(MPButton, {
      fullWidth: true,
      size: "large",
      color: "brand",
      onClick: onClose,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    }),
    children: text
  });
};
export default CommonHelpDialog;