import { useMemo } from 'react';
import ContentLoader from '../../../../components/common/loader';
import { MPButton } from '../../../../theme/ui-kit/button';
import usePartnerDeskList from '../provider/usePartnerDeskList';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskListShowMoreAdapter = () => {
  const {
    isFetching,
    isShowMoreDisplayed,
    onLoadMore
  } = usePartnerDeskList();
  return useMemo(() => isShowMoreDisplayed ? _jsxs(MPButton, {
    fullWidth: true,
    color: "white",
    disabled: isFetching,
    onClick: onLoadMore,
    children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451", isFetching && _jsx(ContentLoader, {
      size: 20
    })]
  }) : null, [isFetching, isShowMoreDisplayed, onLoadMore]);
};
export default PartnerDeskListShowMoreAdapter;