import { useGetCmsContainerBannersContentQuery } from 'data/api/cms';
import { ECmsBannerLinkObjectType, ECmsContainerType } from 'domain/model/enums';
import CmsCategoryCaption from 'presentation/features/cms/components/categoryCaption';
import { getCorpOffersSearchRoute } from 'presentation/features/offer/corp/routes';
import { getTradeOffersSearchRoute } from 'presentation/features/offer/trade/routes';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import GlobalLoaderContainer from '../../header/globalLoader/container';
import CmsComponentBanner from '../components/banner';
import CmsContainerWrapperGrid from '../components/containerWrapper/grid';
import { useGetCmsCategoryBannerList } from '../hooks/useGetCategoryBannerList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CmsOfferCategories = _ref => {
  let {
    header,
    footer,
    layout: Layout,
    components,
    containerType,
    offerType,
    guid,
    onDataLoaded,
    context
  } = _ref;
  const history = useHistory();
  const bannerIds = useMemo(() => components?.filter(component => !!component.linkObjectId)?.map(component => component.linkObjectId) ?? [], [components]);
  const {
    data: banners = [],
    isFetching
  } = useGetCmsContainerBannersContentQuery({
    guid,
    bannerIds
  }, {
    skip: !bannerIds.length
  });
  const categoryBannerList = useGetCmsCategoryBannerList(banners);
  const onBannerClick = banner => {
    switch (banner.linkObjectType) {
      case ECmsBannerLinkObjectType.TradeOfferCategory:
        history.push(getTradeOffersSearchRoute({
          categoryId: banner.linkObjectId,
          partnerId: context?.partner?.id
        }));
        break;
      case ECmsBannerLinkObjectType.CorpOfferCategory:
        history.push(getCorpOffersSearchRoute({
          categoryId: banner.linkObjectId,
          partnerId: context?.partner?.id
        }));
        break;
      default:
        console.error(`Некорректный тип баннера - ${banner.linkObjectType}, ожидается ${ECmsBannerLinkObjectType.TradeOfferCategory} или ${ECmsBannerLinkObjectType.CorpOfferCategory}`);
        break;
    }
  };

  //for the future when CategoryOffer increased just add specific types
  const Wrapper = useMemo(() => {
    switch (containerType) {
      case ECmsContainerType.Category4Offer:
        return props => _jsx(CmsContainerWrapperGrid, {
          mobileColumnsCount: 2,
          ...props
        });
      default:
        return CmsContainerWrapperGrid;
    }
  }, [containerType]);
  useEffect(() => {
    if (banners && categoryBannerList && !isFetching) onDataLoaded?.();
  }, [banners, categoryBannerList, isFetching, onDataLoaded]);
  const Footer = footer;
  const hasContent = categoryBannerList && categoryBannerList.length > 0;
  if (!categoryBannerList.length) return null;
  return _jsx(Layout, {
    header: header,
    footer: Footer && _jsx(Footer, {
      hasContent: hasContent
    }),
    loader: isFetching && _jsx(GlobalLoaderContainer, {}),
    children: _jsx(Wrapper, {
      children: categoryBannerList?.map(banner => _jsx(CmsComponentBanner, {
        banner: banner,
        objectType: offerType,
        onClick: () => onBannerClick(banner),
        Caption: _jsx(CmsCategoryCaption, {
          children: banner.name
        })
      }, banner.id))
    })
  });
};