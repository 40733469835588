import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getBookingOffersListRoute, getBookingOffersSearchRoute } from '../../routes';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferBreadcrumbs = _ref => {
  let {
    offerCategories,
    dictionary
  } = _ref;
  const getBreadcrumbLink = category => getBookingOffersSearchRoute({
    categoryId: category.id
  });
  const startCategory = {
    id: '',
    name: EAppCategories.BookingOffers,
    parentId: null,
    link: getBookingOffersListRoute()
  };
  return _jsx(OfferBreadcrumbs, {
    startCategory: startCategory,
    offerCategories: offerCategories ?? null,
    dictionary: dictionary,
    linkFactory: getBreadcrumbLink
  });
};
export default BookingOfferBreadcrumbs;