import { Link } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DocLink = props => {
  const {
    children,
    target = '_blank',
    ...otherProps
  } = props;
  return _jsx(Link, {
    ...otherProps,
    target: target,
    children: children
  });
};