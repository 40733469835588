import { Fragment, useContext } from 'react';
import { MPCommonDialogContext } from 'theme/ui-kit/dialog/common/context';
import { StyledDialogTitle } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPDialogContentBuilder = _ref => {
  let {
    wrapper: Wrapper = Fragment,
    title,
    children
  } = _ref;
  const {
    onClose,
    closeButtonVisible,
    fullScreen
  } = useContext(MPCommonDialogContext);
  const isBackButtonVisible = closeButtonVisible && onClose && fullScreen;
  return _jsxs(Wrapper, {
    children: [title && _jsx(StyledDialogTitle, {
      compact: !!isBackButtonVisible,
      children: title
    }), children]
  });
};