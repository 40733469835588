import { Typography } from '@mui/material';
import React from 'react';
import { MPSelect } from 'presentation/theme/ui-kit/select';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function SortSelect(props) {
  const {
    value,
    items,
    defaultLabel = 'Отсортировать',
    renderValue,
    onChange
  } = props;
  return _jsx(MPSelect, {
    value: value ?? '',
    size: "micro",
    label: value ? '' : defaultLabel,
    onChange: onChange,
    renderValue: renderValue,
    children: items.map(_ref => {
      let {
        id,
        name
      } = _ref;
      return _jsx(MPSelect.Item, {
        value: id,
        children: _jsx(Typography, {
          children: name
        })
      }, id);
    })
  });
}
export default SortSelect;