import { Tab, useMediaQuery } from '@mui/material';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabAboutCompany } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { OfferTabs } from '../../../components/tabs/controls';
import { EAspOfferDetailsTab } from '../utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AspOfferTabs = props => {
  const {
    onChange,
    tab,
    partnerDesk,
    aspOffer
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return _jsxs(MPGrid, {
    container: true,
    spacing: isMdUp ? 3 : 2,
    children: [_jsx(MPGrid, {
      item: true,
      xs: true,
      children: _jsx(TabsWrapper, {
        children: _jsxs(OfferTabs, {
          value: tab,
          textColor: "secondary",
          variant: "scrollable",
          onChange: onChange,
          scrollButtons: false,
          children: [_jsx(Tab, {
            label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435",
            value: EAspOfferDetailsTab.Description
          }), partnerDesk.contacts && partnerDesk.contacts?.length > 0 && _jsx(Tab, {
            label: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B",
            value: EAspOfferDetailsTab.Contacts
          }), partnerDesk.aboutCompany && _jsx(Tab, {
            label: "\u041E \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438",
            value: EAspOfferDetailsTab.AboutCompany
          })]
        })
      })
    }), _jsxs(MPGrid, {
      item: true,
      zero: 12,
      children: [tab === EAspOfferDetailsTab.Description && _jsx(OfferDetailsTabDescription, {
        description: aspOffer.description
      }), tab === EAspOfferDetailsTab.Contacts && partnerDesk.contacts && _jsx(OfferDetailsTabContacts, {
        contacts: partnerDesk.contacts
      }), tab === EAspOfferDetailsTab.AboutCompany && partnerDesk.aboutCompany && _jsx(PartnerDeskDetailsTabAboutCompany, {
        about: partnerDesk.aboutCompany
      })]
    })]
  });
};
export default AspOfferTabs;