import { getSiteCaption } from '../../../../utils';
import { Text } from './controls';
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutCookiesCommonInfo = () => {
  return _jsx(_Fragment, {
    children: _jsxs(Text, {
      children: ["\u0414\u0430\u043D\u043D\u0430\u044F \u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u044F \u0444\u0430\u0439\u043B\u043E\u0432 cookie \u043E\u0442\u043D\u043E\u0441\u0438\u0442\u0441\u044F \u043A \u0441\u0430\u0439\u0442\u0443 ", getSiteCaption(), ", \u0434\u0430\u043B\u0435\u0435 \"\u0441\u0430\u0439\u0442\". \u0412\u0441\u043F\u043B\u044B\u0432\u0430\u044E\u0449\u0435\u0435 \u043E\u043A\u043D\u043E \u043F\u0440\u0435\u0434\u0443\u043F\u0440\u0435\u0436\u0434\u0430\u0435\u0442 \u043F\u043E\u0441\u0435\u0442\u0438\u0442\u0435\u043B\u0435\u0439 \u0421\u0430\u0439\u0442\u0430 \u043F\u0440\u0438 \u043F\u0435\u0440\u0432\u043E\u043C \u043F\u043E\u0441\u0435\u0449\u0435\u043D\u0438\u0438 \u0441 \u043F\u043E\u043C\u043E\u0449\u044C\u044E \u043D\u043E\u0432\u043E\u0433\u043E \u0431\u0440\u0430\u0443\u0437\u0435\u0440\u0430 \u0438\u043B\u0438 \u0432 \u0440\u0435\u0436\u0438\u043C\u0435 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u0430 \u043E\u0431 \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u0435\u043D\u0438\u0438 \u0441\u0431\u043E\u0440\u0430 \u0421ookies \u043D\u0430 \u0441\u0430\u0439\u0442\u0435. \u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0421\u0430\u0439\u0442\u043E\u043C, \u0432\u044B \u0434\u0430\u0451\u0442\u0435 \u0441\u0432\u043E\u0451 \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u0432\u0430\u0448\u0438\u0445 \u0421ookies."]
    })
  });
};
export default AboutCookiesCommonInfo;