import { MPButton } from 'theme/ui-kit/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SystemEventsDefaultActions = _ref => {
  let {
    onNext
  } = _ref;
  return _jsx(MPButton, {
    color: "brand",
    size: "large",
    fullWidth: true,
    onClick: onNext,
    children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
  });
};