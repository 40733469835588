import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    args: null
  },
  reducers: {
    searchOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid,
        args
      } = payload;
      state.guid = guid;
      state.isNewFetching = true;
      state.args = args;
    },
    searchOffersSetArgs: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.args = payload;
    },
    searchOffersSetPageSize: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      if (state.args) {
        state.args.pageSize = payload;
      }
    },
    searchOffersSetSort: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      if (state.args) {
        state.args.sort = payload;
      }
    },
    searchOffersSetPage: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      if (state.args) {
        state.args.page = payload;
      }
    },
    searchOffersSetIsNewFetching: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.isNewFetching = payload;
    }
  }
});
export const {
  searchOffersSetPageSize,
  searchOffersSetPage,
  searchOffersStartSession,
  searchOffersSetSort,
  searchOffersSetIsNewFetching,
  searchOffersSetArgs
} = slice.actions;
export default slice.reducer;