import { Divider } from '@mui/material';
import moment from 'moment-timezone';
import { isDefined } from 'utils/array';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPMultipleCalendarPicker } from 'presentation/theme/ui-kit/picker';
import CountInput from 'presentation/components/common/countInput';
import Splitter from 'presentation/components/common/splitter';
import { countWidth, TableHeader } from './constants';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const mapDates = _ref => {
  let {
    startDate
  } = _ref;
  return startDate;
};
const BookingOfferModalMultiple = _ref2 => {
  let {
    slots,
    setSlots
  } = _ref2;
  const dates = slots.map(mapDates);
  const handleChangeDates = dates => {
    setSlots(state => dates?.map(date => {
      const existedValue = state.find(_ref3 => {
        let {
          startDate
        } = _ref3;
        return moment(startDate).format(EDateFormat.Server) === moment(date).format(EDateFormat.Server);
      });
      return existedValue || {
        startDate: date,
        endDate: date,
        qty: 1
      };
    }) ?? []);
  };
  const handleChangeCount = index => {
    return qty => {
      setSlots(state => {
        return state.map((slot, stateIndex) => {
          if (stateIndex !== index) return slot;
          return qty ? {
            ...slot,
            qty
          } : null;
        }).filter(isDefined);
      });
    };
  };
  return _jsxs(_Fragment, {
    children: [_jsx(MPMultipleCalendarPicker, {
      limitPastSelection: true,
      showOtherDays: false,
      value: dates,
      onChange: handleChangeDates
    }), !!dates.length && _jsxs(_Fragment, {
      children: [TableHeader, slots?.map((_ref4, index) => {
        let {
          startDate,
          qty
        } = _ref4;
        const date = moment(startDate).format(EDateFormat.Human);
        const onChange = handleChangeCount(index);
        return _jsxs(_Fragment, {
          children: [_jsxs(MPGrid, {
            container: true,
            justifyContent: "space-between",
            alignItems: "center",
            children: [_jsx(MPGrid, {
              item: true,
              children: date
            }), _jsx(MPGrid, {
              item: true,
              width: countWidth,
              children: _jsx(CountInput, {
                isInputDisabled: true,
                min: 0,
                size: "micro",
                value: qty,
                onIncrement: onChange,
                onDecrement: onChange
              })
            })]
          }, date), _jsx(Splitter, {
            size: 1.5
          }), _jsx(MPGrid, {
            item: true,
            xs: 12,
            children: _jsx(Divider, {})
          }), index < slots.length && _jsx(Splitter, {
            size: 1.5
          })]
        });
      })]
    })]
  });
};
export default BookingOfferModalMultiple;