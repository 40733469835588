import { useSelector } from 'react-redux';
import { corpOfferListNoDataSelector, corpOfferListSearchByNameIsEmptySelector } from '../store/selectors';
import CorpOffersStub from '../stub/common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferListStubAdapter = () => {
  const isEmptySearch = useSelector(corpOfferListSearchByNameIsEmptySelector);
  const noData = useSelector(corpOfferListNoDataSelector);
  if (noData) {
    return _jsx(CorpOffersStub, {
      isEmptySearch: isEmptySearch
    });
  }
  return null;
};