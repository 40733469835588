import { useCallback, useMemo, useState } from 'react';
import { getCategoryPathById } from '../utils';
export const useCategorySelect = props => {
  const {
    tree,
    selected,
    showSelected
  } = props;
  const [expanded, setExpanded] = useState(() => showSelected && selected && tree ? getCategoryPathById(tree, selected)?.[0] ?? null : null);
  const selectedPath = useMemo(() => selected && tree && showSelected ? getCategoryPathById(tree, selected) : null, [selected, tree, showSelected]);
  const expandedPath = useMemo(() => expanded && tree ? getCategoryPathById(tree, expanded.id) : null, [expanded, tree]);
  const isExpanded = useCallback(category => expandedPath?.some(item => item.id === category.id) ?? false, [expandedPath]);
  const selectedCategory = selectedPath?.[selectedPath?.length - 1] ?? null;
  const expandedFirstLevel = expandedPath?.[0] ?? null;
  return {
    expanded,
    selectedCategory,
    expandedFirstLevel,
    expandedPath,
    setExpanded,
    isExpanded
  };
};