import { api, EAspOfferServicesTag, ECacheServicesTag, ECorpOfferServicesTag, ETradeOfferServicesTag, EUserServicesTag } from '../index';
import { userApi } from '../user';
import { isOfferActivation, isOfferActivationCollection, isOfferActivationPageable } from './utils';
//выполнение апдейтов в списках с активациями
export const createUserNewActivationPatches = _ref => {
  let {
    newActivation,
    state,
    dispatch
  } = _ref;
  const {
    id,
    offer: {
      id: offerId
    }
  } = newActivation;

  //формируем оптимальный набор тэгов
  const tags = [{
    type: EUserServicesTag.Activations,
    id
  }, {
    type: ECorpOfferServicesTag.Activations,
    id: offerId
  }, {
    type: ETradeOfferServicesTag.Activations,
    id: offerId
  }, {
    type: ECacheServicesTag.Common,
    id
  }];

  //создаём набор патчей
  return api.util.selectInvalidatedBy(state, tags).map(cache => {
    const {
      originalArgs,
      endpointName
    } = cache;
    return dispatch(userApi.util.updateQueryData(endpointName, originalArgs, draft => {
      const dataObject = draft;
      if (isOfferActivationCollection(dataObject)) {
        const changedOfferActivation = dataObject?.find(item => item.id === id);
        if (changedOfferActivation) {
          Object.assign(changedOfferActivation, newActivation);
        } else {
          dataObject.push(newActivation);
        }
      } else if (isOfferActivationPageable(dataObject)) {
        const changedOfferActivation = dataObject?.data?.find(item => item.id === id);
        if (changedOfferActivation) {
          Object.assign(changedOfferActivation, newActivation);
        } else {
          dataObject?.data?.push(newActivation);
        }
      } else if (isOfferActivation(dataObject) && dataObject?.id === id) {
        Object.assign(dataObject, newActivation);
      }
    }));
  });
};
//выполнение апдейтов в списках с активациями
export const createUserNewActivationTagsToInvalidate = _ref2 => {
  let {
    newActivation
  } = _ref2;
  const {
    offer: {
      id: offerId
    }
  } = newActivation;
  return [EUserServicesTag.Activations, {
    type: ECorpOfferServicesTag.Details,
    offerId
  }, {
    type: ETradeOfferServicesTag.Details,
    offerId
  }, {
    type: EAspOfferServicesTag.Details,
    offerId
  }];
};