import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { getCorpOffersListRoute } from 'features/offer/corp/routes';
import { SystemEventContentBuilderLayout } from 'features/systemEvent';
import welcomeBenefitBg from 'media/images/welcomeBenefitBg.png';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import { BenefitsCount, ImageWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const WelcomeBenefitsEventContainer = _ref => {
  let {
    payload: {
      count
    },
    onNext
  } = _ref;
  const history = useHistory();
  const countSymbols = [];
  const countStr = count.toString(10);
  const onNextInternal = () => {
    history.push(getCorpOffersListRoute({}));
    onNext();
  };
  for (let i = 0; i < countStr.length; i++) {
    countSymbols.push(countStr[i]);
  }
  return _jsxs(SystemEventContentBuilderLayout, {
    actions: _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: onNextInternal,
      children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F\u043C"
    }),
    children: [_jsxs(ImageWrapper, {
      children: [_jsx(BenefitsCount, {
        children: _jsx("div", {
          children: countSymbols.map(s => _jsx("span", {
            children: s
          }, s))
        })
      }), _jsx("img", {
        src: welcomeBenefitBg,
        alt: 'баллы за регистрацию'
      })]
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(Typography, {
      color: "textPrimary",
      variant: "h2",
      children: "\u0414\u043E\u0431\u0440\u043E \u043F\u043E\u0436\u0430\u043B\u043E\u0432\u0430\u0442\u044C \u043D\u0430 \u0412\u0438\u0442\u0440\u0438\u043D\u0443!"
    }), _jsx(Splitter, {
      size: 2
    }), _jsxs(Typography, {
      color: "textPrimary",
      variant: "body2",
      children: ["\u0417\u0430 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044E \u0438 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0441\u0442\u0430\u0442\u0443\u0441\u0430 \u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A\u0430 \u041E\u0410\u041E\xA0\xAB\u0420\u0416\u0414\xBB \u0412\u0430\u043C \u043D\u0430\u0447\u0438\u0441\u043B\u0435\u043D\u043E ", count, " \u0431\u0430\u043B\u043B\u043E\u0432 \u0411\u043E\u043D\u0443\u0441\u043D\u043E\u0433\u043E \u043F\u0430\u043A\u0435\u0442\u0430"]
    })]
  });
};