import { EOfferStatus } from '../../../../../../../domain/model/enums';
import OfferClaimHelp from '../../../../components/claim/help';
import useAspOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const NdflAdapter = () => {
  const {
    aspOffer
  } = useAspOfferClaimContext();
  const {
    openNdflDialog
  } = useAspOfferClaimDialogs();
  const isVisible = aspOffer?.status === EOfferStatus.Active;
  return isVisible ? _jsx(OfferClaimHelp, {
    text: 'Возможно, оплата НДФЛ',
    onClick: openNdflDialog
  }) : null;
};
export default NdflAdapter;