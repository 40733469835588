import ContentLoader from 'presentation/components/common/loader';
import useAspOfferDetailsFetchingStatus from '../hooks/useAspOfferDetailsFetchingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferDetailsLoaderAdapter = _ref => {
  let {
    id
  } = _ref;
  const isFetching = useAspOfferDetailsFetchingStatus({
    id
  });
  return isFetching ? _jsx(ContentLoader, {
    position: "fixed"
  }) : null;
};