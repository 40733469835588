import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAspOffersContextHandlers } from '../../../hooks/useAspOffersContextHandlers';
import { partnerDeskDetailsAspOffersCommonArgsSelector, partnerDeskDetailsGuidSelector } from '../../../store/selectors';
import PartnerDeskAspOffersDataContainer from '../dataContainer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Common;
const PartnerDeskAspOffersCommonAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsAspOffersCommonArgsSelector);
  const handlers = useAspOffersContextHandlers();
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskAspOffersDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangeAspOffersPage,
    onShowCard: handlers.onShowAspOffersCard
  }), [args, currentGuid, guid, handlers.onChangeAspOffersPage, handlers.onShowAspOffersCard]);
};
export default PartnerDeskAspOffersCommonAdapter;