import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import { useAuth } from '../../../../auth/provider/useAuth';
import { OfferFavoriteLinkContainer } from '../../../favorite';
import { EOfferType } from '../../../../../../domain/model/enums';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferDetailsFavoriteAdapter = _ref => {
  let {
    guid,
    id
  } = _ref;
  const {
    isAuthenticated
  } = useAuth();
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  if (!aspOffer || !isAuthenticated) {
    return null;
  }
  return _jsx(OfferFavoriteLinkContainer, {
    guid: guid,
    offerId: id,
    offerType: EOfferType.Trade
  });
};