import { useContext } from 'react';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
const useRenderManagerError = () => {
  const {
    error
  } = useContext(RenderManagerErrorContext);
  return {
    error
  };
};
export default useRenderManagerError;