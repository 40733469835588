import moment from 'moment-timezone';
import { pluralize } from './index';
export const getLeftHumanPeriod = function (date) {
  let today = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : moment();
  const endDate = typeof date === 'string' ? moment(date) : date;
  const leftDays = endDate.diff(today, 'days');
  if (leftDays > 0) {
    return `Осталось ${leftDays} ${pluralize(leftDays, ['день', 'дня', 'дней'])}`;
  }
  const leftHours = endDate.diff(today, 'hours');
  if (leftHours > 0) {
    return `Осталось ${leftHours} ${pluralize(leftHours, ['час', 'часа', 'часов'])}`;
  }
  const leftMinutes = endDate.diff(today, 'minutes');
  if (leftMinutes > 0) {
    return `Осталось ${leftMinutes} ${pluralize(leftMinutes, ['минута', 'минуты', 'минут'])}`;
  }
  return `Осталось менее 1 минуты`;
};
export const getLeftSeconds = function (date) {
  let today = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : moment();
  const endDate = typeof date === 'string' ? moment(date) : date;
  return endDate.diff(today, 'seconds');
};
export const utcToLocalTimeZone = dateTime => moment.utc(dateTime).local();
export const secondsToHms = totalSeconds => {
  const hours = Math.floor(totalSeconds / 60 / 60);
  const minutes = Math.floor(totalSeconds / 60) - hours * 60;
  const seconds = totalSeconds % 60;
  if (hours) {
    return `${hours.toString().padStart(1, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
};
export const convertSecondsToHumanView = seconds => {
  if (seconds === 24 * 60 * 60) {
    return 'сутки';
  }
  return `${seconds} секунд`;
};