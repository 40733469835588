import { useEffect, useRef, useState } from 'react';
import { CloseButton, Content, ContentWrapper, Link, Wrapper } from './controls';
import { Dialog, Typography } from '@mui/material';
import AppSvgIcon from '../../../icon';
import { CloseIcon } from '../../../../../media/icons';
import Splitter from '../../../splitter';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ContentLimiterDialogLayout = props => {
  const {
    children,
    title,
    rows = 5,
    moreText,
    contentProps
  } = props;
  const refContent = useRef(null);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const handleMoreClick = () => {
    setIsOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  // Инициализируем высоту блока и проверяем нужно ли его сворачивать
  useEffect(() => {
    if (refContent.current !== null) {
      if (refContent.current.clientHeight < refContent.current.scrollHeight) {
        setIsExpandable(true);
        refContent.current.style.display = '-webkit-box';
      }
    }
  }, [refContent]);
  const MoreComponent = _jsx(Link, {
    variant: 'body1',
    onClick: handleMoreClick,
    children: moreText ? moreText : 'Посмотреть полностью'
  });
  return _jsxs(Wrapper, {
    children: [_jsx(Content, {
      rows: rows,
      ref: refContent,
      variant: 'body2',
      ...contentProps,
      children: children
    }), isExpandable && moreText !== null && MoreComponent, isOpenDialog && _jsx(Dialog, {
      open: true,
      onClose: handleCloseDialog,
      children: _jsxs(ContentWrapper, {
        children: [_jsx(Typography, {
          variant: "h2",
          children: title
        }), _jsx(CloseButton, {
          onClick: handleCloseDialog,
          children: _jsx(AppSvgIcon, {
            icon: CloseIcon
          })
        }), _jsx(Splitter, {
          size: 3
        }), children, _jsx(Splitter, {
          size: 3
        }), _jsx(MPButton, {
          onClick: handleCloseDialog,
          fullWidth: true,
          color: 'brand',
          children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
        })]
      })
    })]
  });
};