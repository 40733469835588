import { LoggedUserAdapter } from 'presentation/features/auth/provider/loggedUserAdapter';
import { useMemo } from 'react';
import { EHeaderOptionType } from '../components/options/types';
import FavoritesOptionAdapter from './options/favorites';
import HomepageOptionAdapter from './options/homepage';
import PrivilegeOptionAdapter from './options/privilege';
import SearchOptionAdapter from './options/search';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const useHeaderOptions = () => {
  const options = useMemo(() => [{
    type: EHeaderOptionType.Homepage,
    component: _jsx(HomepageOptionAdapter, {}, EHeaderOptionType.Homepage)
  }, {
    type: EHeaderOptionType.Search,
    component: _jsx(SearchOptionAdapter, {}, EHeaderOptionType.Search)
  }, {
    type: EHeaderOptionType.Privilege,
    component: _jsx(LoggedUserAdapter, {
      component: PrivilegeOptionAdapter
    }, EHeaderOptionType.Privilege)
  }, {
    type: EHeaderOptionType.Favorites,
    component: _jsx(LoggedUserAdapter, {
      component: FavoritesOptionAdapter
    }, EHeaderOptionType.Favorites)
  }], []);
  return options;
};
export default useHeaderOptions;