import CorpOfferBreadcrumbs from '../../components/breadcrumbs';
import useCorpOfferCategoriesData from '../hooks/useCorpOfferCategoriesData';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferBreadcrumbAdapter = props => {
  const {
    id
  } = props;
  const {
    categories
  } = useCorpOfferCategoriesData();
  const {
    corpOffer
  } = useCorpOfferDetailsData(id);
  return _jsx(CorpOfferBreadcrumbs, {
    offerCategories: corpOffer?.categories ?? null,
    dictionary: categories ?? null
  });
};
export default CorpOfferBreadcrumbAdapter;