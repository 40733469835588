import { getAspOfferClaimDialogSelector } from '../../store/selectors';
import RequiredActionUpdateUserPhoneContainer from '../../../../../requiredAction/updateUserPhone/container';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { useSelector } from 'react-redux';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const visibleSelector = getAspOfferClaimDialogSelector('updateUserPhone');
export const AspOfferClaimUpdateUserPhoneDialogAdapter = () => {
  const {
    onActivate,
    onCloseDialog
  } = useAspOfferClaimContext();
  const visible = useSelector(visibleSelector);
  const onClose = () => onCloseDialog('updateUserPhone');
  return visible && _jsx(RequiredActionUpdateUserPhoneContainer, {
    onSuccess: onActivate,
    onClose: onClose
  });
};