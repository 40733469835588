import { useSelector } from 'react-redux';
import BookingOfferModal from '../../components/modal';
import { bookingOfferDetailsModalSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferDetailsModalsAdapter = () => {
  const modalItem = useSelector(bookingOfferDetailsModalSelector);
  const {
    onApplyModalChanges
  } = useContextHandlers();
  if (!modalItem) {
    return null;
  }
  return _jsx(BookingOfferModal, {
    ...modalItem,
    isVisible: !!modalItem,
    onClose: onApplyModalChanges
  });
};
export default BookingOfferDetailsModalsAdapter;