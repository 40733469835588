import { useGetAspOfferListQuery } from 'data/api/aspOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { ERenderSource } from 'presentation/types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OfferListItemContainer from '../../listItem/container';
import useCategoriesData from './hooks/useCategoriesData';
import { aspOfferListIsNewFetchingSelector } from './store/selectors';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AspOfferListDataContainer = props => {
  const {
    type,
    guid,
    args,
    header,
    onChangePage,
    onShowCard
  } = props;
  const isNewFetching = useSelector(aspOfferListIsNewFetchingSelector);
  const {
    page
  } = args;
  const {
    isAuthenticated
  } = useAuth();
  const {
    subCategories
  } = useCategoriesData();
  const {
    data,
    error,
    isSuccess,
    isFetching
  } = useGetAspOfferListQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const aspOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasSubCategories = !!subCategories?.length;
  const hasOffers = !!aspOffers && aspOffers.length > 0;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  const sessionKey = JSON.stringify(args);
  return _jsxs("div", {
    children: [header, _jsx(CardPageableList, {
      span: hasSubCategories ? 4 : 3,
      lgSpan: hasSubCategories ? 6 : 4,
      totalCount: totalCount,
      onLoadMore: onLoadMore,
      children: aspOffers.map(aspOffer => _jsx(InView, {
        active: isSuccess && !isFetching,
        sessionKey: sessionKey,
        data: aspOffer,
        onShow: onShowCard,
        children: _jsx(OfferListItemContainer, {
          offer: aspOffer,
          favorite: isAuthenticated && {
            guid
          },
          renderFrom: ERenderSource.OfferList
        }, aspOffer.id)
      }, aspOffer.id))
    })]
  });
};
export default AspOfferListDataContainer;