import { BreadcrumbTree } from 'presentation/components/common/breadcrumb/expanded';
import { getBreadcrumbChains } from 'presentation/components/common/breadcrumb/expanded/utils';
import useProductOfferDetailsCategoriesData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsCategoriesData';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { EAppCategories } from 'presentation/types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferDetailsBreadcrumbsAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  const {
    categories,
    isLoading
  } = useProductOfferDetailsCategoriesData(id);

  /*const onBreadcrumbClick: OnBreadcrumbClick = id => {
    typeof id === 'string' && history.push(getProductOffersSearchRoute({ categoryId: id }));
  };*/

  const breadcrumbsData = getBreadcrumbChains(productOffer?.categoryList ?? [], categories ?? []);
  if (isLoading) {
    return null;
  }
  return _jsx(BreadcrumbTree, {
    items: breadcrumbsData,
    title: EAppCategories.ProductOffers
  });
};
export default ProductOfferDetailsBreadcrumbsAdapter;