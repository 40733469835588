import axios from 'axios';
import { createCancelToken, getUserQueryParams } from '../utils';
const productOffer = {
  all: props => {
    const {
      categories,
      statuses,
      signal,
      maxPrice,
      minPrice,
      brands,
      partnerId,
      collectionId,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    if (brands) {
      brands.forEach(brand => params.append('brands', brand));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    if (maxPrice) {
      params.append('maxPrice', maxPrice.toString(10));
    }
    if (minPrice) {
      params.append('minPrice', minPrice.toString(10));
    }
    if (collectionId) {
      params.append('collectionId', collectionId);
    }
    if (categories) {
      categories.forEach(item => params.append('category', item));
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  variations: props => {
    const {
      productDeskId,
      signal
    } = props;
    const params = new URLSearchParams();
    if (productDeskId) {
      params.append('productDeskId', productDeskId);
    }
    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  count: props => {
    const {
      categories,
      statuses,
      signal,
      maxPrice,
      minPrice,
      brands,
      partnerId,
      collectionId,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    params.append('resultType', 'count');
    if (brands) {
      brands.forEach(brand => params.append('brands', brand));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    if (maxPrice) {
      params.append('maxPrice', maxPrice.toString(10));
    }
    if (minPrice) {
      params.append('minPrice', minPrice.toString(10));
    }
    if (collectionId) {
      params.append('collectionId', collectionId);
    }
    if (categories) {
      categories.forEach(item => params.append('category', item));
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  /** Получение товара по id **/
  one: _ref => {
    let {
      id
    } = _ref;
    return {
      url: `/customers/current/product-offers/${id}`,
      method: 'GET'
    };
  },
  /** Получение списка категорий товаров по фильтрам для покупателя **/
  categoriesUsed: _ref2 => {
    let {
      query,
      statuses,
      partnerId,
      city,
      onlyLeafCategories,
      signal
    } = _ref2;
    const params = new URLSearchParams();
    if (query) {
      params.append('q', query);
    }
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    if (partnerId) {
      params.append('partnerId', partnerId);
    }
    if (city) {
      params.append('places', city);
    }
    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }
    return {
      url: '/customers/current/product-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default productOffer;