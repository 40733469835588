import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback } from 'react';
import CartListItem from '../cartItem';
import { ItemWrapper, StyledCheckbox, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartItemsList = props => {
  const {
    items,
    selected,
    onToggleSelected,
    isCartItemEnabled,
    onOpenProductDetails,
    onAddOrRemoveFavorite,
    isFavoriteFetching
  } = props;
  const {
    x3
  } = useGridSpacing();
  const isSelected = useCallback(item => selected.some(s => s.id === item.id), [selected]);
  return _jsx(Wrapper, {
    children: items.map(item => _jsx(ItemWrapper, {
      children: _jsxs(MPGrid, {
        container: true,
        spacing: x3,
        children: [_jsx(MPGrid, {
          item: true,
          children: _jsx(StyledCheckbox, {
            color: "primary",
            disabled: !isCartItemEnabled(item),
            checked: isCartItemEnabled(item) && isSelected(item),
            onChange: () => onToggleSelected([item])
          })
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(CartListItem, {
            data: item,
            enabled: isCartItemEnabled(item),
            onOpenProductDetails: () => onOpenProductDetails(item.offer),
            onAddOrRemoveFavorite: () => onAddOrRemoveFavorite(item.offer),
            isFavoriteFetching: isFavoriteFetching
          })
        })]
      })
    }, item.id))
  });
};
export default CartItemsList;