import { useRouteMatch } from 'react-router';
import Option from './component';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderOption = props => {
  const {
    to
  } = props;
  const match = useRouteMatch(typeof to === 'string' ? to : to?.pathname);
  return _jsx(Option, {
    active: !!match?.isExact,
    ...props
  });
};
export default HeaderOption;