import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { CartIcon } from 'presentation/media/icons';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { Icon } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartEmpty = _ref => {
  let {
    onOpenProducts
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    spacing: 2,
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    children: [_jsx(MPGrid, {
      item: true,
      children: _jsx(Icon, {
        icon: CartIcon
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "subtitle1",
        children: "\u041A\u043E\u0440\u0437\u0438\u043D\u0430 \u043F\u0443\u0441\u0442\u0430"
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        align: "center",
        children: "\u0414\u043E\u0431\u0430\u0432\u044C\u0442\u0435 \u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u0443 \u0442\u043E\u0432\u0430\u0440\u044B \u0434\u043B\u044F \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u0438\u044F \u0437\u0430\u043A\u0430\u0437\u043E\u0432"
      })
    }), _jsxs(MPGrid, {
      item: true,
      children: [_jsx(Splitter, {
        size: 2
      }), _jsx(MPButton, {
        onClick: onOpenProducts,
        children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u0442\u043E\u0432\u0430\u0440\u0430\u043C"
      })]
    })]
  });
};
export default CartEmpty;