import ErrorHandler from 'data/network/errorHandler';
import { paginationSizeVariant } from 'domain/model/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';
import usePrevious from '../../../hooks/usePrevious';
import { EPaginationBehaviour } from './types';
/**
 * хук для работы с list страницами, обогащенными pageable свойствами
 * умеет кэшировать результаты запроса при помощи cacheProvider.
 *
 * при paginationBehaviour: EPaginationBehaviour.IncrementPage,
 * cacheProvider является обязательным атрибутом
 *
 * при EPaginationBehaviour.IncrementPageSize cacheProvider передавать не нужно
 *
 *
 * @example (EPaginationBehaviour.IncrementPage variant)
 * ```tsx
 * const {
 *     data,
 *     isFetching,
 *     totalCount,
 *     loadMore,
 *     isSuccess,
 *     error,
 *     changePageSize,
 * } = usePageableList<{ guid: UUID }, PartnerDesk>({
 *    guid,
 *     paginationBehaviour: EPaginationBehaviour.IncrementPage,
 *     cacheProvider: { // required
 *       cache: cache ?? null,
 *       setCache,
 *     },
 *     argsStorage,
 *     useQuery: useGetTradeOfferListQuery,
 *  });
 * ```
 *
 * @example (EPaginationBehaviour.IncrementPageSize variant)
 * ```tsx
 * const {
 *     data,
 *     isFetching,
 *     totalCount,
 *     loadMore,
 *     isSuccess,
 *     error,
 *     changePageSize,
 * } = usePageableList<{ guid: UUID }, PartnerDesk>({
 *    guid,
 *     paginationBehaviour: EPaginationBehaviour.IncrementPageSize,
 *     argsStorage,
 *     useQuery: useGetTradeOfferListQuery,
 *  });
 * ```
 */

//todo добавить рефетч в случае если error был зафиксирован на предыдущем запросе

const defaultPage = 1;
const defaultPageSize = paginationSizeVariant[0];
const usePageableList = props => {
  const {
    useQuery,
    queryOptions,
    guid,
    paginationBehaviour,
    argsStorage,
    cacheProvider: {
      cache,
      setCache,
      clearCache
    } = {},
    passErrors = false,
    debug
  } = props;
  const stateSave = argsStorage?.save;
  const {
    page: savedPage,
    pageSize: savedPageSize,
    ...payload
  } = argsStorage?.currentState ?? {
    page: defaultPage,
    pageSize: defaultPageSize
  };
  const pageSizeFromDefaultArgs = argsStorage?.defaultState?.pageSize ?? savedPageSize;
  const payloadString = JSON.stringify(payload);

  //предыдущий guid
  const prevGuid = usePrevious(guid);

  //текущие параметры пагинации, вынесены отдельно потому что хранения параметров вне данного хука вообще может не быть (если не передали stateProvider)
  const [pageableState, setPageableState] = useState({
    page: savedPage,
    pageSize: savedPageSize
  });

  //параметры запроса, используем pagination и payloadString (строку, чтобы не приходилось снаружи мемоизировать)
  const queryParams = useMemo(() => ({
    page: pageableState.page,
    pageSize: pageableState.pageSize,
    ...JSON.parse(payloadString)
  }), [pageableState.page, pageableState.pageSize, payloadString]);
  const isGuidChanged = prevGuid && prevGuid !== guid;
  const needReset = pageableState.page !== defaultPage && !cache && paginationBehaviour === EPaginationBehaviour.IncrementPage;

  //выполнение ключевого запроса, тут используем currentData а не data, потому что data будет возвращать старые данные даже если параметры поменялись
  const {
    currentData: resultCurrentData,
    //данные полученные по текущим параметрам
    data: resultData,
    //данные полученные в рамках подписки ранее, по любым параметрам
    isFetching,
    isSuccess,
    isLoading: isPreparing,
    error,
    refetch,
    fulfilledTimeStamp = 0,
    startedTimeStamp = 0,
    isUninitialized,
    originalArgs
  } = useQuery(queryParams, {
    ...queryOptions,
    skip: queryOptions?.skip || needReset
  });

  //определяем факт того что начался новый запрос, при этом данные из useQuery возвращаются от предыдущего запроса
  const isFetchingNewQuery = fulfilledTimeStamp && startedTimeStamp > fulfilledTimeStamp;

  /**
   * если пагинация по pageSize, то в качестве данных нужно использовать resultData
   * так как resultCurrentData будет null каждый следующий запрос и на экране всё пропадёт
   * при этом не забудем проверить что текущий pageSize не равен начальному, потому что если он равен, то данные рефрешатся по какой-то причине и нам не нужны последние полученные данные
   */
  const currentData = (paginationBehaviour === EPaginationBehaviour.IncrementPageSize && queryParams.pageSize !== pageSizeFromDefaultArgs ? resultData : resultCurrentData) ?? null;

  //источник данных кэш, если в нём ничего не - данные запроса
  let source = (cache?.data ? cache : currentData) ?? null;

  //если guid не соответствует предыдущему то обнуляем значение, потому что сейчас должна начаться загрузка данных и нельзя допустить чтобы невалидные данные вернулись
  if (isGuidChanged || isFetchingNewQuery) {
    source = null;
  }

  /**
   * определеяем данные для сохранения в кэш
   * если guid не соответствует предыдущему то обнуляем значение, потому что сейчас должна начаться загрузка данных и нельзя допустить чтобы невалидные данные сохранились в кэш
   * если выполняется загрузка данных то обнуляем значение, потому что какие данные сейчас в результате неизвестно - могут быть от другого guid-а
   * */
  const sourceToCache = isGuidChanged || isFetchingNewQuery ? null : currentData;

  //считаем параметры для возврата
  const data = source?.data ?? null;
  const totalCount = source?.totalCount ?? 0;
  const dataLength = data?.length ?? 0;
  const hasMore = dataLength < totalCount;
  const isEmpty = !isFetching && totalCount === 0;
  if (debug) {
    console.debug('execute usePageableList', `
  payloadString = ${payloadString}
  isUninitialized = ${isUninitialized}
  queryParams = ${JSON.stringify(queryParams)}
  queryOriginalArgs = ${JSON.stringify(originalArgs)}
  needReset = ${needReset}
  isFetching = ${isFetching}
  isPreparing = ${isPreparing}
  isGuidChanged = ${isGuidChanged}
  source = ${JSON.stringify(source)}
  startedTimeStamp = ${startedTimeStamp}
  fulfilledTimeStamp = ${fulfilledTimeStamp}
  cache.data = ${JSON.stringify(cache?.data)}
  currentData.data = ${JSON.stringify(currentData?.data)}
  resultCurrentData.data = ${JSON.stringify(resultCurrentData?.data)}
  data.data = ${JSON.stringify(resultData?.data)}
  sourceToCache = ${JSON.stringify(sourceToCache)}
  data = ${JSON.stringify(data)}
  `);
  }

  // reset location pagination state to initial on refresh page or on cache exceeded
  useEffect(() => {
    if (needReset) {
      setPageableState(prev => {
        const newPaginationState = {
          ...prev,
          page: defaultPage,
          pageSize: savedPageSize
        };
        stateSave?.({
          ...newPaginationState,
          ...JSON.parse(payloadString)
        });
        return newPaginationState;
      });
    }
  }, [payloadString, stateSave, savedPageSize, needReset]);
  const loadMore = useCallback(() => {
    if (paginationBehaviour === EPaginationBehaviour.IncrementPage) {
      setPageableState(prev => {
        const newPaginationState = {
          ...prev,
          page: prev.page + 1
        };
        stateSave?.({
          ...newPaginationState,
          ...JSON.parse(payloadString)
        });
        return newPaginationState;
      });
    } else {
      setPageableState(prev => {
        const newPaginationState = {
          page: 1,
          pageSize: prev.pageSize + pageSizeFromDefaultArgs
        };
        stateSave?.({
          ...newPaginationState,
          ...JSON.parse(payloadString)
        });
        return newPaginationState;
      });
    }
  }, [paginationBehaviour, stateSave, payloadString, pageSizeFromDefaultArgs]);
  const changePageSize = useCallback(pageSize => {
    setPageableState(() => {
      const newPaginationState = {
        page: 1,
        pageSize
      };
      stateSave?.({
        ...newPaginationState,
        ...JSON.parse(payloadString)
      });
      return newPaginationState;
    });
  }, [stateSave, payloadString]);

  //рефетч
  useEffectAfterMount(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [guid, isUninitialized]);

  //заливаем в кэш данные
  useEffect(() => {
    if (setCache && sourceToCache) {
      if (debug) {
        console.log('setCache called');
      }
      setCache(sourceToCache);
    }
  }, [debug, sourceToCache, setCache]);

  //изменение guid или savedPageableState или payload - обновляем параметры пагинации
  useEffectAfterMount(() => {
    const newPageableState = {
      page: savedPage,
      pageSize: savedPageSize
    };
    if (debug) {
      console.log('clearCache called');
      console.log('setPageableState', newPageableState);
    }
    clearCache?.();
    setPageableState(newPageableState);
  }, [debug, guid, savedPage, savedPageSize, payloadString]);

  //отображение ошибки фетчинга данных
  useEffect(() => {
    if (error && !passErrors) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error, passErrors]);
  return {
    data,
    isFetching,
    isPreparing,
    isEmpty,
    error,
    totalCount,
    isSuccess,
    loadMore: hasMore ? loadMore : null,
    changePageSize,
    pageableState: queryParams
  };
};
export default usePageableList;