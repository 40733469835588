const breakpoints = {
  values: {
    zero: 0,
    xs: 360,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1320
  }
};
export default breakpoints;