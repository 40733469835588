import { Wrapper, Text, AcceptCookieContainer } from './controls';
import useUserLocalSettings from '../../hooks/useUserLocalSettings';
import { MPButton } from '../../theme/ui-kit/button';
import { useSelector } from 'react-redux';
import { MPLink } from '../../theme/ui-kit/link';
import { footerRegisterAllHeightSelector } from '../footerRegister/store/selectors';
import { getLegalCookiesRoute } from '../legal/entry';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const CookiesAcceptContainer = _ref => {
  let {
    children
  } = _ref;
  const {
    setUserCookieAccepted,
    isCurrentUserAcceptedCookies
  } = useUserLocalSettings();
  const allHeight = useSelector(footerRegisterAllHeightSelector);
  return _jsxs(_Fragment, {
    children: [children, !isCurrentUserAcceptedCookies && _jsx(Wrapper, {
      bottomOffset: allHeight,
      children: _jsxs(AcceptCookieContainer, {
        children: [_jsxs(Text, {
          children: ["\u041C\u044B \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u043C", ' ', _jsx(MPLink, {
            href: getLegalCookiesRoute(),
            underline: "always",
            color: "textPrimary",
            target: "_blank",
            children: "cookies"
          }), ", \u0447\u0442\u043E\u0431\u044B \u0441\u0430\u0439\u0442 \u0440\u0430\u0431\u043E\u0442\u0430\u043B \u043B\u0443\u0447\u0448\u0435"]
        }), _jsx(MPButton, {
          color: "brand",
          onClick: () => setUserCookieAccepted(true),
          children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
        })]
      })
    })]
  });
};
export default CookiesAcceptContainer;