import { EUserServicesTag } from 'data/api';
import { useNsiPromotionTypesQuery } from 'data/api/nsi';
import { useGetUserActivationsQuery } from 'data/api/user';
import { ECorpOfferPromotionType, EOfferType, ETradeOfferPromotionType } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import ActivationsStub from 'presentation/features/activation/components/stub';
import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import useRtkQueryCachedPageableData from 'presentation/features/general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { EPaginationBehaviour } from 'presentation/features/general/pageable/types';
import usePageableList from 'presentation/features/general/pageable/usePageableList';
import { getAspOfferDetailsRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersDetailsRoute } from 'presentation/features/offer/booking/routes';
import CertificateDialog from 'presentation/features/offer/corp/details/dialogs/help/certificate';
import { getCorpOfferDetailsRoute } from 'presentation/features/offer/corp/routes';
import { getProductOfferDetailsRoute } from 'presentation/features/offer/product/entry';
import TradeOfferHelpDialog from 'presentation/features/offer/trade/components/dialogs/help';
import { getTradeOfferDetailsRoute } from 'presentation/features/offer/trade/routes';
import UserActivationsLayout from 'presentation/features/user/details/activations/layout';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useDialogInHistory from 'presentation/hooks/useDialogInHistory';
import { useHistory } from 'react-router';
import UserActivations from './component';
import { EUserActivationsDialogTag } from './utils';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const pageSize = 10;
const Layout = UserActivationsLayout;
const UserActivationsContainer = props => {
  const {
    guid
  } = props;
  const history = useHistory();
  const {
    data: promotionTypes,
    isFetching: isPromotionTypesFetching
  } = useNsiPromotionTypesQuery();
  const {
    webAnalytics
  } = useWebAnalytics();
  const {
    open: isAccessCodeHelpDialogOpened,
    onOpen: onOpenAccessCodeHelpDialog,
    onClose: onCloseAccessCodeHelpDialog
  } = useDialogInHistory({
    tag: EUserActivationsDialogTag.AccessCode
  });
  const {
    open: isPromoCodeHelpDialogOpened,
    onOpen: onOpenPromoCodeHelpDialog,
    onClose: onClosePromoCodeHelpDialog
  } = useDialogInHistory({
    tag: EUserActivationsDialogTag.PromoCode
  });
  const {
    open: isVoucherHelpDialogOpened,
    onOpen: onOpenVoucherHelpDialog,
    onClose: onCloseVoucherHelpDialog
  } = useDialogInHistory({
    tag: EUserActivationsDialogTag.Voucher
  });
  const {
    open: isCertificateHelpDialogOpened,
    onOpen: onOpenCertificateHelpDialog,
    onClose: onCloseCertificateHelpDialog
  } = useDialogInHistory({
    tag: EUserActivationsDialogTag.Certificate
  });
  const argsStorage = usePageableArgsInRedux({
    guid,
    defaultState: {
      guid,
      page: 1,
      pageSize
    }
  });
  const {
    data: activations,
    totalCount,
    isFetching: isActivationsFetching,
    isPreparing,
    isEmpty,
    loadMore
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    cacheProvider: useRtkQueryCachedPageableData({
      guid,
      tag: EUserServicesTag.Activations,
      payload: argsStorage.currentState
    }),
    argsStorage,
    useQuery: useGetUserActivationsQuery
  });
  const isFetching = isPromotionTypesFetching || isActivationsFetching;
  const onOpenHelpDialog = type => {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        onOpenAccessCodeHelpDialog();
        break;
      case ETradeOfferPromotionType.Promocode:
        onOpenPromoCodeHelpDialog();
        break;
      case ETradeOfferPromotionType.Voucher:
        onOpenVoucherHelpDialog();
        break;
      case ECorpOfferPromotionType.CorpCertificate:
      case ECorpOfferPromotionType.ExternalCorpCertificate:
      case ETradeOfferPromotionType.CorpCertificate:
      case ETradeOfferPromotionType.ExternalCorpCertificate:
        onOpenCertificateHelpDialog();
        break;
      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.ReferralLink:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        break;
    }
  };
  const getActivationCodeTypeName = type => {
    return promotionTypes?.find(p => p.id === type)?.name ?? 'Код';
  };
  const onOfferClick = _ref => {
    let {
      id,
      type
    } = _ref;
    switch (type) {
      case EOfferType.Asp:
        return history.push(getAspOfferDetailsRoute({
          id
        }));
      case EOfferType.Trade:
        return history.push(getTradeOfferDetailsRoute({
          id
        }));
      case EOfferType.Corp:
        return history.push(getCorpOfferDetailsRoute({
          id
        }));
      case EOfferType.Product:
        return history.push(getProductOfferDetailsRoute({
          id
        }));
      case EOfferType.Booking:
        return history.push(getBookingOffersDetailsRoute({
          id
        }));
    }
  };
  const onPartnerLinkClick = activation => {
    webAnalytics.openPartnerUrl(activation.offer.id);
    webAnalytics.offerJumpToPartnerSite(activation.offer.id);
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(Layout, {
      header: "\u041C\u043E\u0438 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u0438",
      loader: isFetching && !activations && _jsx(ContentLoader, {
        position: "fixed"
      }),
      children: [isEmpty && _jsx(ActivationsStub, {}), !isEmpty && activations && _jsx(UserActivations, {
        activations: activations,
        totalCount: totalCount,
        isFetching: isFetching || isPreparing,
        onLoadNextPage: loadMore,
        onOfferClick: onOfferClick,
        onPartnerLinkClick: onPartnerLinkClick,
        onOpenHelpDialog: onOpenHelpDialog,
        getActivationCodeTypeName: getActivationCodeTypeName
      })]
    }), _jsx(TradeOfferHelpDialog, {
      promotionType: ETradeOfferPromotionType.AccessCode,
      open: isAccessCodeHelpDialogOpened,
      onClose: onCloseAccessCodeHelpDialog
    }), _jsx(TradeOfferHelpDialog, {
      promotionType: ETradeOfferPromotionType.Promocode,
      open: isPromoCodeHelpDialogOpened,
      onClose: onClosePromoCodeHelpDialog
    }), _jsx(TradeOfferHelpDialog, {
      promotionType: ETradeOfferPromotionType.Voucher,
      open: isVoucherHelpDialogOpened,
      onClose: onCloseVoucherHelpDialog
    }), _jsx(CertificateDialog, {
      open: isCertificateHelpDialogOpened,
      onClose: onCloseCertificateHelpDialog
    })]
  });
};
export default UserActivationsContainer;