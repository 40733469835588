import { useEffect, useState } from 'react';
import axios from 'axios';
import Notifier from '../../system/notifier';
import ErrorHandler from 'data/network/errorHandler';
export const downloadURL = (data, fileName) => {
  const a = document.createElement('a');
  a.href = data;
  a.download = fileName;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  a.remove();
};
const axiosInstance = axios;
export const createDownloadRequest = (options, cancelCallback, accept) => {
  const {
    fileName,
    src
  } = options;
  const config = {
    responseType: 'blob',
    headers: {
      accept: accept ?? '*'
    },
    cancelToken: cancelCallback?.token
  };
  return axiosInstance.get(src, config).then(response => {
    const {
      headers,
      data
    } = response;
    if (accept) {
      const fileUrl = URL.createObjectURL(new Blob([data], {
        type: accept
      }));
      downloadURL(fileUrl, fileName || 'file');
    } else {
      const fName = decodeURI(headers['content-disposition'].split('filename=')[1]).replaceAll('"', '').replaceAll("'", '');
      const type = decodeURI(headers['content-type']);
      const fileUrl = URL.createObjectURL(new Blob([data], {
        type
      }));
      downloadURL(fileUrl, fileName || fName);
    }
  });
};
const useFileDownload = props => {
  const {
    fileName,
    src,
    accept,
    onDownload
  } = props;
  const [start, setStart] = useState(false);
  useEffect(() => {
    if (!start) return;
    const cancelCallback = axiosInstance.CancelToken.source();
    createDownloadRequest({
      src,
      fileName
    }, cancelCallback, accept).then(() => {
      onDownload?.(true);
    }).catch(error => {
      if (!axiosInstance.isCancel(error)) {
        console.error('Ошибка при загрузке файла', error);
        onDownload?.(false);
        Notifier.getInstance().addError(`Ошибка при загрузке файла`);
        ErrorHandler.captureException(error);
      }
    }).finally(() => {
      setStart(false);
    });
    return () => cancelCallback.cancel();
  }, [start, src, accept, fileName, onDownload]);
  return {
    start,
    setStart
  };
};
export default useFileDownload;