import { useState } from 'react';
import DangerouslyHtmlWrapper from '../../../../../components/common/wrappers/dangerouslyHtml';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import OfferConditions from '../../../components/conditions';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AspOfferConditionsAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (!aspOffer?.conditions) {
    return null;
  }
  return _jsxs(_Fragment, {
    children: [_jsx(OfferConditions, {
      title: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F",
      text: aspOffer.conditions,
      onClickExtend: handleOpen
    }), _jsx(MPConfirmDialog, {
      title: "\u0423\u0441\u043B\u043E\u0432\u0438\u044F",
      open: open,
      maxWidth: "sm",
      scroll: "paper",
      fullScreenBreakpoint: "sm",
      buttons: _jsx(MPButton, {
        fullWidth: true,
        color: "brand",
        size: "large",
        onClick: handleClose,
        children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
      }),
      onClose: handleClose,
      children: _jsx(DangerouslyHtmlWrapper, {
        children: _jsx("div", {
          dangerouslySetInnerHTML: {
            __html: aspOffer.conditions
          }
        })
      })
    })]
  });
};