import { Typography } from '@mui/material';
import TillDateCountdown from 'presentation/components/common/countdown/tillDate';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TradeOfferClaimReactivationMessage = props => {
  const {
    date,
    stop
  } = props;
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsxs(Typography, {
      variant: "body2",
      color: "black",
      children: ["\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u043C\u043E\u0436\u043D\u043E \u0431\u0443\u0434\u0435\u0442 \u0447\u0435\u0440\u0435\u0437", ' ', _jsx(TillDateCountdown, {
        date: date,
        active: true,
        stop: stop
      })]
    })
  });
};