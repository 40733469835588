import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import TradeOfferDetailsLoaderAdapter from 'presentation/features/offer/trade/details/adapters/loader';
import TradeOfferDetailStub from 'presentation/features/offer/trade/details/adapters/stub';
import OfferDetailsLayout from '../../../../layouts/offerDetail';
import TradeOfferBreadcrumbAdapter from './adapters/breadcrumb';
import TradeOfferCarouselAdapter from './adapters/carousel';
import TradeOfferCloseAdapter from './adapters/close';
import TradeOfferConditionsAdapter from './adapters/condition';
import TradeOfferDetailsFavoriteAdapter from './adapters/favorite';
import TradeOfferPartnerInfoAdapter from './adapters/partnerInfo';
import TradeOfferSidebarAdapter from './adapters/sidebar';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import TradeOfferDetailsTabsAdapter from './adapters/tabs';
import TradeOfferDetailsTitleAdapter from './adapters/title';
import { TradeOfferDetailContext } from './context';
import useTradeOfferDetails from './hooks/useTradeOfferDetails';
import useTradeOfferDetailsLoadingStatus from './hooks/useTradeOfferDetailsLoadingStatus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = OfferDetailsLayout;
const TradeOfferDetailsContainer = _ref => {
  let {
    id,
    tab,
    guid,
    from
  } = _ref;
  const handlers = useTradeOfferDetails({
    id,
    guid,
    from
  });
  return _jsx(TradeOfferDetailContext.Provider, {
    value: handlers,
    children: _jsx(RenderManagerErrorProvider, {
      children: _jsx(CommonRenderManager, {
        loader: _jsx(TradeOfferDetailsLoaderAdapter, {
          id: id
        }),
        stub: TradeOfferDetailStub,
        children: _jsx(Layout, {
          id: id,
          favorite: _jsx(TradeOfferDetailsFavoriteAdapter, {
            id: id,
            guid: guid
          }),
          description: _jsx(TradeOfferConditionsAdapter, {
            id: id
          }),
          sidebar: _jsx(TradeOfferSidebarAdapter, {
            guid: guid,
            id: id
          }),
          smartphoneFixedBar: _jsx(SmartPhoneFixedBarAdapter, {
            guid: guid,
            id: id
          }),
          tabs: _jsx(TradeOfferDetailsTabsAdapter, {
            guid: guid,
            tab: tab,
            id: id
          }),
          partnerInfo: _jsx(TradeOfferPartnerInfoAdapter, {
            id: id
          }),
          title: _jsx(TradeOfferDetailsTitleAdapter, {
            id: id
          }),
          breadcrumb: _jsx(TradeOfferBreadcrumbAdapter, {
            id: id
          }),
          image: _jsx(TradeOfferCarouselAdapter, {
            id: id
          }),
          close: _jsx(TradeOfferCloseAdapter, {}),
          useLoading: useTradeOfferDetailsLoadingStatus
        })
      })
    })
  });
};
export default TradeOfferDetailsContainer;