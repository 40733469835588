import { WelcomeBenefitsEventContainer } from './container';
import { welcomeBenefitsEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: welcomeBenefitsEventType,
    component: WelcomeBenefitsEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};