// берет атрибуты (с уже померженными значениями по group.id) и добавляет к ним productIds, valuesGroup с одним атрибутом
// также добавляет file атрибут если он есть
export const getAllVariantsMergedAttributesChainedWithProductId = variants => {
  const attributes = variants.map(v => {
    return v.mergedAttributes.map(ma => {
      const sameGroupFileAttribute = v.fileAttributes.find(fa => fa?.attribute?.group?.id === ma.attribute.id);
      return {
        ...ma,
        valuesGroup: ma?.values?.[0] ? [{
          productIds: [v.product.id],
          attribute: ma.attribute,
          values: [ma.values[0]],
          collection: ma.collection,
          required: ma.required
        }, ...(sameGroupFileAttribute ? [{
          ...sameGroupFileAttribute,
          productIds: [v.product.id]
        }] : [])] : []
      };
    });
  });
  return attributes.reduce((acc, a) => [...acc, ...a], []);
};

// берет атрибуты и мержит value по значению, а также добавляет в productIds id варианта, значение которого мы примержили
export const uniteByValueAttributes = attributes => {
  return attributes.reduce((acc, va) => {
    // все атрибуты уникальны, так что индекс может быть только один
    const prevAttributeSameCurrentAttributeIdIndex = acc.findIndex(a => {
      return a.attribute.id === va.attribute.id;
    });

    // если уже есть атрибут с таким id, то проверяет есть ли уже в этом атрибуте такое же значение в valuesGroup
    if (prevAttributeSameCurrentAttributeIdIndex !== -1) {
      const prevAttributeSameValueIndex = acc[prevAttributeSameCurrentAttributeIdIndex].valuesGroup.findIndex(vg => {
        return vg.values?.find(vv => vv?.value === va?.valuesGroup?.[0].values?.[0].value);
      });

      // уже есть такое же значение
      if (prevAttributeSameValueIndex !== -1) {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentAttributeIdIndex === aIndex) {
            return {
              ...aItem,
              valuesGroup: Object.assign([], aItem.valuesGroup, {
                [prevAttributeSameValueIndex]: {
                  ...aItem.valuesGroup[prevAttributeSameValueIndex],
                  productIds: [...aItem.valuesGroup[prevAttributeSameValueIndex].productIds, va?.valuesGroup[0]?.productIds[0]]
                }
              })
            };
          }
          return aItem;
        });
        return updatedAcc;
      } else {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentAttributeIdIndex === aIndex) {
            return {
              ...aItem,
              valuesGroup: [...aItem.valuesGroup, va?.valuesGroup[0]]
            };
          }
          return aItem;
        });
        return updatedAcc;
      }
    }
    return [...acc, va];
  }, []);
};
const makeGroupedByValueAttributes = variants => {
  if (!variants.length) {
    return [];
  }
  const allVariantsAttributes = getAllVariantsMergedAttributesChainedWithProductId(variants);

  // TODO(избавиться от push внутри, бесит и мешает
  const unitedByValueAttributes = uniteByValueAttributes(allVariantsAttributes);
  return unitedByValueAttributes;
};
export default makeGroupedByValueAttributes;