import { useContextHandlers } from '../hooks/useContextHandlers';
import useTradeOfferDetailsData from '../hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from '../hooks/useTradeOfferPartnerDeskData';
import TradeOfferTabs from '../tabs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferDetailsTabsAdapter = _ref => {
  let {
    tab,
    id
  } = _ref;
  const {
    onChangeTab
  } = useContextHandlers();
  const {
    tradeOffer
  } = useTradeOfferDetailsData(id);
  const {
    partnerDesk
  } = useTradeOfferPartnerDeskData(id);
  if (!tradeOffer || !partnerDesk) {
    return null;
  }
  return _jsx(TradeOfferTabs, {
    tab: tab,
    tradeOffer: tradeOffer,
    partnerDesk: partnerDesk,
    onChange: (event, newTab) => onChangeTab(newTab)
  });
};
export default TradeOfferDetailsTabsAdapter;