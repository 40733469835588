import BackButton from '../../../features/offer/components/back';
import { SmartPhoneFixedBarWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SmartPhoneFixedBar = props => {
  const {
    text,
    favorite,
    onBack
  } = props;
  return _jsxs(SmartPhoneFixedBarWrapper, {
    children: [_jsx(BackButton, {
      label: text,
      onClick: onBack
    }), favorite]
  });
};
export default SmartPhoneFixedBar;