import { Typography } from '@mui/material';
import { AddressHelper } from 'utils/address';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AddressFullView = _ref => {
  let {
    address,
    options,
    ...others
  } = _ref;
  return _jsx(Typography, {
    ...others,
    children: address ? new AddressHelper(address).getFullPath({
      options
    }) : '-'
  });
};
export default AddressFullView;