import * as React from 'react';
import { withRouter } from 'react-router-dom';
import ErrorBoundaryContent from './content';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class ErrorBoundaryCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }
  static getDerivedStateFromError(error) {
    console.error(error);
    return {
      error
    };
  }
  componentDidCatch(error) {
    const errorName = error.name;
    if (errorName === 'ChunkLoadError') {
      window.location.href = '/';
    }
  }
  onLogout = () => {
    this.props.logout({
      redirectUri: window.location.origin
    });
  };
  render() {
    if (this.state.error) {
      const message = this.state.error.message;
      const stacktrace = this.state.error.stack;
      return _jsx(ErrorBoundaryContent, {
        message: message,
        stacktrace: stacktrace,
        onLogout: this.onLogout
      });
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundaryCommon);