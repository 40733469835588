import { Typography } from '@mui/material';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferDetailsTitleAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    corpOffer
  } = useCorpOfferDetailsData(id);
  if (!corpOffer) {
    return null;
  }
  return _jsx(Typography, {
    variant: "h1",
    children: corpOffer.name
  });
};
export default CorpOfferDetailsTitleAdapter;