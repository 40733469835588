import AuthEntry from 'presentation/features/auth/entry';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AuthProtect = _ref => {
  let {
    ignore,
    fallback,
    children
  } = _ref;
  const {
    isAuthenticated
  } = useAuth();
  return ignore || isAuthenticated ? children : fallback ?? _jsx(AuthEntry, {});
};
export default AuthProtect;