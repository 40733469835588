import { useMemo } from 'react';
import ProductFilterContainer from '../../filter/container';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferListFilterAdapter = () => {
  const handlers = useContextHandlers();
  return useMemo(() => _jsx(ProductFilterContainer, {
    onChangeFilter: handlers.onChangeFilter
  }), [handlers.onChangeFilter]);
};
export default ProductOfferListFilterAdapter;