import ContentLoader from 'presentation/components/common/loader';
import PartnerDeskHeader from 'presentation/features/partnerDesk/components/header';
import { PartnerDeskDetailsLayout } from 'presentation/features/partnerDesk/details/layouts';
import { useMemo } from 'react';
import { getBonusConfig } from '../../../utils';
import { PartnerDeskDetailsContext } from './context';
import usePartnerDeskDetailsHandlers from './hooks/usePartnerDeskDetails';
import { usePartnerDeskTabInfo } from './hooks/usePartnerDeskTabInfo';
import { PartnerDeskDetailsTabAboutCompany, PartnerDeskDetailsTabAspOffers, PartnerDeskDetailsTabBookingOffers, PartnerDeskDetailsTabContacts, PartnerDeskDetailsTabCorpOffers, PartnerDeskDetailsTabPartnerWindow, PartnerDeskDetailsTabProductOffers, PartnerDeskDetailsTabs, PartnerDeskDetailsTabTradeOffers } from './tabs';
import { EPartnerDeskDetailsTab } from './utils';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskDetailsLayout;
const PartnerDeskDetailsContainer = props => {
  const {
    guid,
    id,
    tab,
    categoryId,
    isCorpUser = false,
    isTradeOfferFavoritesAllowed = false,
    isCorpOfferFavoritesAllowed = false,
    isProductOfferFavoritesAllowed = false,
    isBookingOfferFavoritesAllowed = false
  } = props;
  const handlers = usePartnerDeskDetailsHandlers({
    guid,
    partnerId: id,
    categoryId
  });
  const bonusUnavailableReason = getBonusConfig()?.unavailableReason;
  const {
    partnerDesk,
    partnerWindow,
    tabToDisplay,
    tabList,
    isFetching
  } = usePartnerDeskTabInfo({
    guid,
    bonusUnavailableReason,
    tab,
    id,
    categoryId,
    isCorpUser,
    isTradeOfferFavoritesAllowed,
    isCorpOfferFavoritesAllowed,
    isProductOfferFavoritesAllowed,
    isBookingOfferFavoritesAllowed
  });
  const tabsComponent = useMemo(() => _jsx(PartnerDeskDetailsTabs, {
    tab: tabToDisplay,
    tabs: tabList,
    onChangeTab: handlers.onChangeTab
  }), [tabToDisplay, tabList, handlers.onChangeTab]);
  return _jsx(PartnerDeskDetailsContext.Provider, {
    value: handlers,
    children: _jsx(Layout, {
      toolbar: tabToDisplay?.toolbar,
      header: partnerDesk && _jsx(PartnerDeskHeader, {
        partnerDesk: partnerDesk
      }),
      tabs: tabsComponent,
      loader: isFetching && _jsx(ContentLoader, {}),
      children: partnerDesk && tabToDisplay && _jsxs(_Fragment, {
        children: [tabToDisplay.value === EPartnerDeskDetailsTab.PartnerWindow && partnerWindow && _jsx(PartnerDeskDetailsTabPartnerWindow, {
          partnerWindow: partnerWindow,
          guid: guid,
          partnerId: id
        }), tabToDisplay.value === EPartnerDeskDetailsTab.TradeOffers && _jsx(PartnerDeskDetailsTabTradeOffers, {
          guid: guid
        }), tabToDisplay.value === EPartnerDeskDetailsTab.AspOffers && _jsx(PartnerDeskDetailsTabAspOffers, {
          guid: guid
        }), tabToDisplay.value === EPartnerDeskDetailsTab.CorpOffers && _jsx(PartnerDeskDetailsTabCorpOffers, {
          guid: guid
        }), tabToDisplay.value === EPartnerDeskDetailsTab.ProductOffers && _jsx(PartnerDeskDetailsTabProductOffers, {
          guid: guid
        }), tabToDisplay.value === EPartnerDeskDetailsTab.BookingOffers && _jsx(PartnerDeskDetailsTabBookingOffers, {
          guid: guid
        }), tabToDisplay.value === EPartnerDeskDetailsTab.AboutCompany && partnerDesk.aboutCompany && _jsx(PartnerDeskDetailsTabAboutCompany, {
          about: partnerDesk.aboutCompany
        }), tabToDisplay.value === EPartnerDeskDetailsTab.Contacts && partnerDesk.contacts && _jsx(PartnerDeskDetailsTabContacts, {
          contacts: partnerDesk.contacts
        })]
      })
    })
  });
};
export default PartnerDeskDetailsContainer;