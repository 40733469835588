import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'footerRegister',
  initialState: {},
  reducers: {
    registerFooter: (state, _ref) => {
      let {
        payload
      } = _ref;
      const isAlreadyRegistered = payload.name in state;
      if (isAlreadyRegistered) {
        return;
      }
      state[payload.name] = payload.footer;
    },
    unregisterFooter: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      delete state[payload];
    },
    recomputeBoundaries: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      delete state[payload.name];
      state[payload.name] = payload.footer;
    }
  }
});
export const {
  registerFooter,
  unregisterFooter,
  recomputeBoundaries
} = slice.actions;
export default slice.reducer;