import React from 'react';
import { BalanceWrapper, SlotsGrid } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinancesBalanceLayout = props => {
  const {
    slots
  } = props;
  return _jsx(BalanceWrapper, {
    children: slots?.length > 0 && _jsx(SlotsGrid, {
      children: slots.map((s, i) => {
        return s ? _jsx(React.Fragment, {
          children: s
        }, i) : null;
      })
    })
  });
};