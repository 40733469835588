import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { api } from 'data/api';
import { useActivateUserOfferMutation, useGetUserOfferActivationsQuery, useGetUserOfferAvailabilityQuery } from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { ECorpOfferPromotionType, EOfferActivateError, EOfferActivationStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { useAuth } from 'features/auth/provider/useAuth';
import { currentUserIsEmailVerifiedSelector, currentUserIsStatusEnabledSelector } from 'features/user/current/store/selectors';
import { createEvent as createEventConfirmEmail } from 'features/user/events/confirmEmail';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { useWebAnalytics } from 'features/webAnalytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import useCurrentUserBalanceByType from '../../user/current/useBalanceByType';
import { getOfferLastActivation } from '../utils';
const useOfferActivations = props => {
  const {
    offerId,
    offerPrice,
    balanceType,
    offerRefetch,
    isActivationAllowed = true,
    onTakeActivation
  } = props;
  const dispatch = useDispatch();
  const {
    publishFlow
  } = useSystemEventBus();
  const {
    isAuthenticated,
    login
  } = useAuth();
  const {
    webAnalytics
  } = useWebAnalytics();
  const [activationError, setActivationError] = useState(null);
  const balance = useCurrentUserBalanceByType(balanceType ?? null);
  const isUserEmailVerified = useSelector(currentUserIsEmailVerifiedSelector);
  const isUserStatusEnabled = useSelector(currentUserIsStatusEnabledSelector);
  const {
    data: activations = [],
    error: activationsError,
    isFetching: activationsIsFetching,
    refetch: activationsRefetch
  } = useGetUserOfferActivationsQuery({
    id: offerId
  }, {
    refetchOnMountOrArgChange: true,
    skip: !isActivationAllowed
  });
  const {
    data: unavailabilityReasonsData = [],
    error: availabilityError,
    isFetching: availabilityIsFetching,
    refetch: availabilityRefetch
  } = useGetUserOfferAvailabilityQuery({
    offerId
  }, {
    refetchOnMountOrArgChange: true,
    skip: !isActivationAllowed
  });
  const [activateOfferInternal, activateOfferResult] = useActivateUserOfferMutation();
  const unavailabilityReasons = useMemo(() => {
    const result = [...unavailabilityReasonsData];
    if (isUserEmailVerified === false) {
      result.push(EOfferActivateError.EmailNotVerified);
    }
    if (isUserStatusEnabled === false) {
      result.push(EOfferActivateError.InvalidUserStatus);
    }
    return result;
  }, [isUserEmailVerified, isUserStatusEnabled, unavailabilityReasonsData]);
  const {
    isLoading: activationIsFetching,
    data: lastReceivedActivation = null
  } = activateOfferResult;
  const lastActivation = getOfferLastActivation(activations) ?? null;
  const isLastActivationPending = lastActivation?.status === EOfferActivationStatus.Pending;
  const parseActivationError = useCallback(error => {
    const type = error.code;
    const message = error.message;
    switch (type) {
      case EOfferActivateError.PromotionFreshOut:
        ErrorHandler.handleBusinessError(error);
        offerRefetch();
        availabilityRefetch();
        break;
      default:
        setActivationError({
          type,
          message
        });
    }
  }, [availabilityRefetch, offerRefetch]);
  const parseActivationErrorResponse = useCallback(response => {
    switch (response.status) {
      case businessErrorCode:
        if (!response.data.code) {
          setActivationError({
            type: null
          });
          ErrorHandler.handleHttpError(response);
        } else {
          parseActivationError(response.data);
        }
        return;
      case 500:
        setActivationError({
          type: null
        });
        return;
      default:
        {
          ErrorHandler.handleHttpError(response);
        }
    }
  }, [parseActivationError]);
  const userActivationAttempt = useCallback(offer => {
    switch (offer.promotionType) {
      case ECorpOfferPromotionType.ExternalCorpCertificate:
        webAnalytics.openPartnerUrl(offer.partnerId);
        webAnalytics.offerJumpToPartnerSite(offer.id);
        webAnalytics.offerActivate(offer.id);
        break;
      case ECorpOfferPromotionType.CorpCertificate:
        webAnalytics.offerActivate(offer.id);
        break;
      case ETradeOfferPromotionType.AccessCode:
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.Voucher:
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        if (lastActivation) {
          webAnalytics.tradeOfferReactivate(offer.id);
        } else {
          webAnalytics.tradeOfferActivate(offer.id);
        }
        webAnalytics.offerActivate(offer.id);
        break;
      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        webAnalytics.tradeOfferActivate(offer.id);
        webAnalytics.offerActivate(offer.id);
        break;
    }
  }, [webAnalytics, lastActivation]);
  const activateOfferImmediately = useCallback(async offer => {
    let callActivate = false;
    switch (offer.promotionType) {
      case ECorpOfferPromotionType.ExternalCorpCertificate:
        callActivate = false;
        break;
      case ECorpOfferPromotionType.CorpCertificate:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.AccessCode:
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.Voucher:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        callActivate = true;
        break;
      case ETradeOfferPromotionType.Asp:
      case ETradeOfferPromotionType.Digift:
        callActivate = true;
        break;
    }
    let result = null;
    if (callActivate) {
      setActivationError(null);
      try {
        result = await activateOfferInternal({
          id: offer.id
        }).unwrap();
        if (result) {
          onTakeActivation(result);
        }
        offerRefetch();
        activationsRefetch();
      } catch (e) {
        parseActivationErrorResponse(e);
      }
    }
    return result;
  }, [activateOfferInternal, offerRefetch, activationsRefetch, onTakeActivation, parseActivationErrorResponse]);
  const checkActivationAvailability = useCallback(offer => {
    if (!unavailabilityReasons.length) {
      return true;
    }
    const isEmailNotVerified = unavailabilityReasons.indexOf(EOfferActivateError.EmailNotVerified) !== -1;
    const isInvalidUserStatus = unavailabilityReasons.indexOf(EOfferActivateError.InvalidUserStatus) !== -1;

    //признак тупика, то есть пользователь не сможет активировать, даже если все действия выполнит
    //заполнения профиля налету нет, поэтому данный ивент тупиковый
    const isDeadlock = isInvalidUserStatus;
    if (isEmailNotVerified || isInvalidUserStatus) {
      const events = [];
      if (isDeadlock) {
        //если сценарий тупиковый, то просто добавляем ивенты
        if (isEmailNotVerified) {
          events.push(createEventConfirmEmail({}));
        }
        if (isInvalidUserStatus) {
          events.push(createEventNeedFillProfile({}));
        }
      } else {
        //если сценарий не тупиковый

        /**
         * если будут добавляться другие проверки, то для [последней перед тупиковой] нужно всегда добавлять ожидание выполнения, чтобы после успеха выполнить автоматическую активацию
         */
        if (isEmailNotVerified) {
          //передаём калбэк для автоматической активации после всех ивентов
          const onSuccess = result => {
            if (result) {
              //дождемся пока отработают все активные запросы, которые могут быть связаны с ивентами
              Promise.all(dispatch(api.util.getRunningQueriesThunk())).then(() => {
                activateOfferImmediately(offer);
              });
            }
          };
          events.push(createEventConfirmEmail({}, onSuccess));
        }
        if (isInvalidUserStatus) {
          events.push(createEventNeedFillProfile({}));
        }
      }
      publishFlow(events);
      return false;
    }
    return true;
  }, [unavailabilityReasons, dispatch, activateOfferImmediately, publishFlow]);
  const beforeActivateOffer = useCallback(offer => {
    if (!isAuthenticated) {
      login();
      return false;
    }
    return checkActivationAvailability(offer);
  }, [checkActivationAvailability, isAuthenticated, login]);
  const activateOffer = useCallback(async offer => {
    const canContinue = beforeActivateOffer(offer);
    if (!canContinue) {
      return null;
    }
    return activateOfferImmediately(offer);
  }, [beforeActivateOffer, activateOfferImmediately]);
  const activationsReload = useCallback(() => {
    activationsRefetch();
    availabilityRefetch();
  }, [activationsRefetch, availabilityRefetch]);
  const isLoadActivationsFetching = activationsIsFetching || availabilityIsFetching;
  const isLoadActivationsFailed = !!activationsError || !!availabilityError;
  useEffect(() => {
    if (isLoadActivationsFailed) {
      Notifier.getInstance().addError('Ошибка при получении. Попробуйте ещё раз позже');
    }
  }, [isLoadActivationsFailed]);
  const isActivationAvailable = !unavailabilityReasons.some(reason => reason === EOfferActivateError.InappropriateTargeting) && activationError?.type !== EOfferActivateError.InappropriateTargeting;
  const isReactivationSupported = !unavailabilityReasons.some(reason => reason === EOfferActivateError.OfferActivationAlreadyExist);
  const isUserBalanceNotEnough = !balance?.balance || balance?.balance < (offerPrice ?? 0);
  return {
    isLoadActivationsFetching,
    isLoadActivationsFailed,
    isReactivationSupported,
    activationsReload,
    unavailabilityReasons,
    isActivationAvailable,
    isUserBalanceNotEnough,
    userActivationAttempt,
    activateOffer,
    activationError,
    activationIsFetching,
    activations,
    isLastActivationPending,
    lastActivation,
    lastReceivedActivation
  };
};
export default useOfferActivations;