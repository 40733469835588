import { useSelector } from 'react-redux';
import { pageContainerSelector } from './store/selectors';
/**
 * Wrapper для передачи размера подгруженного контента, возвращает размер
 */
export const LazyContentWrapper = _ref => {
  let {
    children,
    guid
  } = _ref;
  const containers = useSelector(pageContainerSelector(guid));
  const size = containers.length;
  return children(size);
};