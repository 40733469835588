import { useCallback, useEffect, useRef } from 'react';
import { IFrame, IFrameWrapper } from '../../claim/adapters/widget/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const minIframeHeight = 150;
const TradeOfferIframe = props => {
  const {
    wrapperRef: widgetWrapperRef,
    srcDoc
  } = props;
  const iframeWrapperRef = useRef();
  const iframeRef = useRef();
  const calculateIframeSize = useCallback(element => {
    if (element) {
      const iframeContentHeight = Math.max(parseFloat(getComputedStyle(element)?.height ?? 0) ?? element.offsetHeight ?? 0, minIframeHeight);
      if (iframeContentHeight > 0) {
        const newHeight = `calc(${iframeContentHeight}px + 10rem)`;
        if (widgetWrapperRef.current.style.detailSwiperHeight === newHeight) {
          return false;
        }
        widgetWrapperRef.current.style.detailSwiperHeight = newHeight;
        return true;
      }
    } else {
      widgetWrapperRef.current.style.detailSwiperHeight = `auto`;
      return true;
    }
    return false;
  }, [widgetWrapperRef]);
  useEffect(() => {
    if (iframeRef.current && widgetWrapperRef.current && iframeWrapperRef.current) {
      let notChangedIframeSizeCounter = 0;
      const interval = setInterval(() => {
        const documentElement = iframeRef.current?.contentDocument?.documentElement;
        const isChanged = calculateIframeSize(documentElement);
        if (!isChanged) {
          notChangedIframeSizeCounter++;
        }
        if (notChangedIframeSizeCounter >= 10) {
          clearInterval(interval);
        }
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
    if (!iframeRef.current) {
      calculateIframeSize(null);
    }
  }, [iframeRef, widgetWrapperRef, iframeWrapperRef, calculateIframeSize]);
  return _jsx(IFrameWrapper, {
    ref: iframeWrapperRef,
    item: true,
    role: "iframe",
    children: _jsx(IFrame, {
      ref: iframeRef,
      width: "100%",
      height: "100%",
      srcDoc: srcDoc ?? ''
    })
  });
};
export default TradeOfferIframe;