import { getPageableFromResponseHeaders } from 'data/openApi/utils';
import apiDefinition from '../openApi';
import { api, ESearchServicesTag } from './index';
export const searchApi = api.injectEndpoints({
  endpoints: builder => ({
    searchOffers: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref => {
        let {
          page,
          pageSize,
          offerType,
          query,
          sort
        } = _ref;
        return apiDefinition.user.search.offers({
          page: 1,
          pageSize: page * pageSize,
          offerTypes: offerType,
          query,
          sort
        });
      },
      providesTags: [ESearchServicesTag.Offers]
    })
  })
});
export const {
  useSearchOffersQuery
} = searchApi;