import { useState } from 'react';
import { Typography } from '@mui/material';
import { ArrowDownIcon, InfoHelpIcon } from '../../../media/icons';
import { MPAccordion, MPAccordionDetails, MPAccordionSummary } from '../../../theme/ui-kit/accordion';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const IconHelp = _jsx(InfoHelpIcon, {});
const IconUp = _jsx(ArrowDownIcon, {});
/**
 * Стилизованный аккордеон
 */
const AccordionWithIcon = _ref => {
  let {
    title,
    description,
    expandIcon = IconHelp,
    expandedIcon = IconUp
  } = _ref;
  const [isExpanded, setExpanded] = useState(false);
  return _jsxs(MPAccordion, {
    expanded: isExpanded,
    disableGutters: true,
    elevation: 0,
    onChange: () => setExpanded(isExpanded => !isExpanded),
    children: [_jsx(MPAccordionSummary, {
      expandIcon: isExpanded ? expandedIcon : expandIcon,
      children: _jsx(Typography, {
        variant: "subtitle2",
        children: title
      })
    }), _jsx(MPAccordionDetails, {
      children: _jsx(Typography, {
        variant: "body2",
        children: description
      })
    })]
  });
};
export default AccordionWithIcon;