import PartnerDeskScreen from 'presentation/screen/partnerDesk';
import PartnerDesksScreen from 'presentation/screen/partnerDesks';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { EPartnerDeskUrlParam } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.partner;
const routing = {
  list: root,
  details: `${root}/:id`
};
export const getPartnerDeskDetailsRoute = props => {
  const {
    guid,
    id,
    tab,
    categoryId
  } = props;
  const newGuid = guid ?? uuidv4();
  const searchParams = new URLSearchParams();
  if (tab) {
    searchParams.append(EPartnerDeskUrlParam.Tab, tab);
  }
  if (categoryId) {
    searchParams.append(EPartnerDeskUrlParam.Category, categoryId);
  }
  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
export const getPartnerDeskListRoute = props => {
  const {
    guid
  } = props;
  const newGuid = guid ?? uuidv4();
  const searchParams = new URLSearchParams();
  return {
    pathname: routing.list,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid
    },
    hash: ''
  };
};
const PartnerDeskEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.list,
      component: PartnerDesksScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: PartnerDeskScreen
    }), _jsx(Redirect, {
      to: routing.list
    })]
  });
};
export default PartnerDeskEntry;