import { EOfferStatus } from '../../../../../../../domain/model/enums';
import OfferClaimPriceInfo from '../../../../components/claim/priceInfo';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferClaimPriceAdapter = () => {
  const {
    aspOffer,
    activation
  } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }
  const isVisible = (aspOffer.status === EOfferStatus.Active || aspOffer.status === EOfferStatus.Upcoming) && !activation.isLastActivationPending;
  if (!isVisible) {
    return null;
  }
  return _jsx(OfferClaimPriceInfo, {
    paymentType: aspOffer.paymentType,
    price: aspOffer.price
  });
};