import { useGetAspOfferDetailsQuery } from 'data/api/aspOffer';
import { EBalanceType, EOfferType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { normalizedLink } from 'presentation/utils';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../auth/provider/useAuth';
import useOfferActivations from '../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../hooks/useOfferSubscription';
import useTakeOfferActivations from '../../../hooks/useTakeOfferActivations';
import { isOfferReceiptDateSupportedByPromotionType } from '../../../utils';
import { getAspOfferCodeAssignDurationInSeconds } from '../../utils';
import { aspOfferClaimChangeDialogState, aspOfferClaimClear } from '../store/slice';
import AspOfferClaimContext from './context';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferClaimProvider = props => {
  const {
    children,
    id,
    promotionType,
    onPartnerButtonClick,
    guid,
    isActivationAllowed = true
  } = props;
  const dispatch = useDispatch();
  const [timerActive, setTimerActive] = React.useState(false);
  const {
    isAuthenticated,
    login
  } = useAuth();
  const {
    data: aspOffer = null,
    refetch: aspOfferRefetch
  } = useGetAspOfferDetailsQuery({
    id
  });
  const {
    onTakeActivation,
    needCopy,
    isTaked,
    onNeedCopy,
    isTaking,
    onCopy
  } = useTakeOfferActivations();
  const activation = useOfferActivations({
    offerId: id,
    offerRefetch: aspOfferRefetch,
    balanceType: EBalanceType.ASP,
    isActivationAllowed,
    onTakeActivation
  });
  const {
    lastActivation,
    activationError,
    isLoadActivationsFetching,
    lastReceivedActivation
  } = activation;
  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe
  } = useOfferSubscription({
    offer: aspOffer,
    offerType: EOfferType.Trade,
    promotionType
  });
  const isReceiptDateSupported = isOfferReceiptDateSupportedByPromotionType(promotionType);
  const isActivationAvailable = activation.isActivationAvailable;
  const partnerLink = aspOffer?.partnerLink ? normalizedLink(aspOffer.partnerLink) : '';
  const endDate = aspOffer ? moment(aspOffer.endDate).endOf('day') : null;
  const isFetching = isLoadActivationsFetching;
  const isActivationReceivedNow = !!lastReceivedActivation;
  const onCheckAuthenticated = useCallback(() => {
    if (!isAuthenticated) {
      login();
      return false;
    }
    return true;
  }, [isAuthenticated, login]);
  const onShowDialog = useCallback(name => {
    dispatch(aspOfferClaimChangeDialogState({
      name,
      data: true
    }));
  }, [dispatch]);
  const onCloseDialog = useCallback(name => {
    dispatch(aspOfferClaimChangeDialogState({
      name,
      data: false
    }));
  }, [dispatch]);
  const onTryActivate = useCallback(() => {
    if (!onCheckAuthenticated()) {
      return;
    }
    onShowDialog('updateUserPhone');
  }, [onCheckAuthenticated, onShowDialog]);
  const onActivate = useCallback(async userPhone => {
    console.log('userPhone', userPhone);
    if (!aspOffer) {
      return null;
    }
    activation.userActivationAttempt(aspOffer);
    return await activation.activateOffer(aspOffer);
  }, [aspOffer, activation]);
  const stopTimer = useCallback(() => {
    setTimerActive(false);
  }, [setTimerActive]);
  useEffect(() => {
    const duration = lastActivation?.appointmentDate ? getAspOfferCodeAssignDurationInSeconds(lastActivation?.appointmentDate) : 0;
    setTimerActive(duration > 0);
  }, [lastActivation?.appointmentDate, activationError]);
  useEffect(() => {
    dispatch(aspOfferClaimClear());
  }, [dispatch]);
  const value = {
    aspOffer,
    partnerLink,
    endDate,
    activation,
    isFetching,
    guid,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isReceiptDateSupported,
    isActivationAvailable,
    isActivationReceivedNow,
    isTimerActive: timerActive,
    stopTimer,
    onShowDialog,
    onCloseDialog,
    onTakeActivation,
    onActivate,
    onTryActivate,
    onSubscribe,
    onUnSubscribe,
    onPartnerButtonClick,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking
  };
  return _jsx(AspOfferClaimContext.Provider, {
    value: value,
    children: children
  });
};
export default AspOfferClaimProvider;