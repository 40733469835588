import { AuthProvider, observables } from '@mp-npm/mp-auth-client';
import { useResetCurrentUserPasswordMutation } from 'data/api/user';
import store from 'data/store/store';
import SentryClient from 'integration/sentry/client';
import { getCheckSsoPath, tokenExpiredConfig } from 'presentation/utils/auth';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppConfigurator } from 'system/appConfigurator';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AppAuthProvider = _ref => {
  let {
    children
  } = _ref;
  const [resetCurrentUserPassword] = useResetCurrentUserPasswordMutation();
  const resetPassword = userId => {
    return resetCurrentUserPassword({
      id: userId
    }).unwrap().then(() => true).catch(e => {
      console.error(e);
      return false;
    });
  };
  const onAuthEvent = (eventType, error) => {
    /** use kc observers fire here **/
    switch (eventType) {
      // errors
      case 'onInitError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Init error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      case 'onAuthError':
        if (error) {
          alert('Авторизация невозможна, обратитесь к разработчикам');
          console.log('Auth error', error);
          SentryClient.getInstance().captureException(error.error);
        }
        break;
      // refresh token
      case 'onTokenExpired':
        break;
      // events
      case 'onReady':
        break;
      case 'onAuthLogout':
        break;
      case 'onAuthRefreshSuccess':
        break;
      case 'onAuthSuccess':
        break;
      case 'onAuthRefreshError':
        observables.onAuthRefreshErrorObservableSubject.fire();
        break;
    }
  };
  return _jsx(AuthProvider, {
    tokenExpiredConfig: tokenExpiredConfig,
    onResetPassword: resetPassword,
    config: AppConfigurator.getInstance().getApiAuthService(),
    initOptions: {
      silentCheckSsoRedirectUri: getCheckSsoPath()
    },
    onAuthEvent: onAuthEvent,
    children: children
  });
};
const AppProviders = _ref2 => {
  let {
    children
  } = _ref2;
  return _jsx(StoreProvider, {
    store: store,
    children: _jsx(BrowserRouter, {
      children: children
    })
  });
};
export default AppProviders;