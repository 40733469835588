import { useConfirmCodeMutation } from 'data/api/user';
import { EValidationType } from 'presentation/utils/validation';
import { useState } from 'react';
import validateObject from '../../../../hooks/validation/utils';
export const rules = {
  code: {
    required: true,
    requiredMessage: 'Необходимо указать корректный код'
  }
};
export const useCodeEdit = props => {
  const {
    source: {
      otpId,
      otpTtl,
      nextAttempt
    },
    onSuccess
  } = props;
  const [validation, setValidation] = useState(null);
  const [confirmCode, {
    isLoading: isFetching
  }] = useConfirmCodeMutation();
  const onSendCode = code => {
    const validation = validateObject({
      code
    }, rules);
    const isValid = validation.isValid;
    setValidation(validation.results);
    if (isValid && otpId) {
      return confirmCode({
        code,
        otpId
      }).unwrap().then(() => {
        onSuccess();
        return true;
      }).catch(error => {
        setValidation({
          code: {
            hasError: true,
            type: EValidationType.Error,
            message: error.data?.message || 'Произошла неизвестная ошибка'
          }
        });
        return Promise.resolve(false);
      });
    }
    return Promise.resolve(false);
  };
  return {
    codeTtl: otpTtl,
    codeRequestId: otpId,
    codeNextAttemptDate: nextAttempt,
    isFetching,
    validation,
    onSendCode
  };
};