import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerSetVisible } from '../store/slice';
const useHideHeader = stop => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (stop) {
      dispatch(headerSetVisible(false));
    } else {
      dispatch(headerSetVisible(true));
    }
    return () => {
      dispatch(headerSetVisible(true));
    };
  }, [dispatch, stop]);
};
export default useHideHeader;