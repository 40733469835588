import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { getUserActivationsRoute } from '../../../../user/entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferClaimLinkToActivations = props => {
  return _jsx(Link, {
    component: RouterLink,
    color: "primary",
    to: getUserActivationsRoute({}),
    ...props,
    children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \xAB\u041C\u043E\u0438 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u0438\xBB"
  });
};
export default OfferClaimLinkToActivations;