import axios from 'axios';
import { createCancelToken } from './utils';
/**
 * АПИ по работе с уведомлениями
 */
const notification = {
  all: _ref => {
    let {
      userId,
      roles,
      signal
    } = _ref;
    const params = new URLSearchParams();
    if (userId) params.append('userId', userId);
    if (roles) roles.forEach(role => params.append('role', role));
    return {
      url: '/notifications/options',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  update: _ref2 => {
    let {
      id,
      option,
      enabled
    } = _ref2;
    return {
      url: `/notifications/users/${id}/settings`,
      method: 'PATCH',
      data: {
        optionId: option.id,
        enabled
      }
    };
  }
};
export default notification;