import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import AboutEntry from 'presentation/features/about/entry';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import CartEntry from 'presentation/features/cart/entry';
import CmsEntry from 'presentation/features/cms/entry';
import CookiesAcceptContainer from 'presentation/features/cookiesAccept/container';
import DataEntry from 'presentation/features/data/entry';
import LocationDetector from 'presentation/features/general/locationDetector/container';
import { AppRoute } from 'presentation/features/general/routing';
import LandingEntry from 'presentation/features/landing/entry';
import AppNotificationsReceiver from 'presentation/features/notification/receiver';
import AspOfferEntry from 'presentation/features/offer/asp/entry';
import BookingOfferEntry from 'presentation/features/offer/booking/entry';
import CorpOfferEntry from 'presentation/features/offer/corp/entry';
import ProductOfferEntry from 'presentation/features/offer/product/entry';
import TradeOfferEntry from 'presentation/features/offer/trade/entry';
import PartnerDeskEntry from 'presentation/features/partnerDesk/entry';
import CurrentUserBalanceProvider from 'presentation/features/user/current/balance/provider';
import UserEntry from 'presentation/features/user/entry';
import { EAppFeature } from 'presentation/types';
import { Redirect, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import NoopDecorator from '../../../components/common/renderDecoratorContainer/noopDecorator';
import useTechConfig from '../../../hooks/useTechConfig';
import HeaderContainer from '../../header/container';
import SearchEntry from '../../search/entry';
import SectionLinksContainer from '../../sectionLinks/container';
import { WsClientProvider } from '../../wsClient/wsClientProvider';
import { Content, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const MainContainer = () => {
  const {
    user,
    defaultRoute,
    isCorpUser
  } = useAuthUser();
  const {
    hasFeature,
    isGuestModeDisabled
  } = useTechConfig();
  return _jsx(WsClientProvider, {
    user: user,
    children: _jsxs(CurrentUserBalanceProvider, {
      user: user,
      isCorpUser: isCorpUser,
      children: [_jsx(AppNotificationsReceiver, {}), _jsx(LocationDetector, {
        children: _jsx(RenderDecoratorContainer, {
          decorators: [hasFeature(EAppFeature.Cookies) ? _jsx(CookiesAcceptContainer, {}, 'cookiesAcceptContainer') : _jsx(NoopDecorator, {})],
          children: _jsxs(Wrapper, {
            children: [_jsx(HeaderContainer, {}), _jsx(SectionLinksContainer, {}), _jsx(Content, {
              children: _jsxs(Switch, {
                children: [_jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  path: rootRouting.partner,
                  component: PartnerDeskEntry
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  feature: EAppFeature.Trade,
                  path: rootRouting.tradeOffer,
                  component: TradeOfferEntry,
                  defaultRoute: defaultRoute
                }), _jsx(AppRoute, {
                  protect: true,
                  path: rootRouting.corpOffer,
                  component: CorpOfferEntry
                }), _jsx(AppRoute, {
                  protect: true,
                  path: rootRouting.user,
                  component: UserEntry
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  path: rootRouting.about,
                  component: AboutEntry
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  feature: EAppFeature.Product,
                  path: rootRouting.product,
                  component: ProductOfferEntry,
                  defaultRoute: defaultRoute
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  feature: EAppFeature.Product,
                  path: rootRouting.cart,
                  component: CartEntry,
                  defaultRoute: defaultRoute
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  feature: EAppFeature.Booking,
                  path: rootRouting.bookingOffer,
                  component: BookingOfferEntry,
                  defaultRoute: defaultRoute
                }), _jsx(AppRoute, {
                  protect: true,
                  feature: EAppFeature.Asp,
                  path: rootRouting.aspOffer,
                  component: AspOfferEntry,
                  defaultRoute: defaultRoute
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  path: rootRouting.data,
                  component: DataEntry
                }), _jsx(AppRoute, {
                  protect: true,
                  path: rootRouting.cms,
                  component: CmsEntry
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  path: rootRouting.search,
                  component: SearchEntry
                }), _jsx(AppRoute, {
                  protect: isGuestModeDisabled,
                  feature: EAppFeature.LandingWindow,
                  path: rootRouting.root,
                  component: LandingEntry,
                  defaultRoute: defaultRoute
                }), _jsx(Redirect, {
                  to: defaultRoute
                })]
              })
            })]
          })
        })
      })]
    })
  });
};
export default MainContainer;