import { Typography, useMediaQuery } from '@mui/material';
import { EOfferStatus, EOfferType } from 'domain/model/enums';
import { ProductAttributeType } from 'domain/model/productOffer';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import CodeTitle from '../../../../components/common/codeTitle/title';
import OfferClaimSubscription from '../../components/claim/subscription';
import useOfferSubscription from '../../hooks/useOfferSubscription';
import ProductOfferCartCounterContainer from '../cartCounter/container';
import ProductOfferCartButtonLabelForDetails from '../components/addToCartButton/labels/forDetails';
import makeMergedAttributes from '../components/attributes/utils/prepareModelData';
import makeGroupedByValueAttributes from '../components/attributes/utils/prepareViewData';
import SidebarAttributes from './components/attributes';
import SidebarInfo from './components/info';
import Prices from './components/prices';
import { BottomCartSection, Wrapper } from './controls';
import { formatOneVariantAttributes } from './utils';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferSidebar = props => {
  const {
    bottomMenuRect,
    product,
    nsi,
    onShowPaymentDialog,
    onShowDeliveryDialog
  } = props;
  const mergedAttributesCurrentVariant = useMemo(() => {
    if (!product) return null;
    return makeMergedAttributes([product]);
  }, [product]);
  const unitedByValueAttributesCurrentVariant = useMemo(() => {
    if (!mergedAttributesCurrentVariant) {
      return null;
    }
    return makeGroupedByValueAttributes(mergedAttributesCurrentVariant);
  }, [mergedAttributesCurrentVariant]);
  const formattedAttributes = formatOneVariantAttributes(unitedByValueAttributesCurrentVariant?.filter(a => a.attribute.type !== ProductAttributeType.file) ?? []);
  const {
    originalPrice,
    price,
    salePercent,
    producerSKU,
    status
  } = product;
  const isNotActive = status !== EOfferStatus.Active;
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const {
    x2
  } = useGridSpacing();
  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    onSubscribe,
    onUnSubscribe
  } = useOfferSubscription({
    offer: product,
    offerType: EOfferType.Product
  });
  const getCartButtonSize = () => {
    if (isMdUp) {
      return 'large';
    }
    if (isSmUp) {
      return 'medium';
    }
    return 'small';
  };
  const cartButtonSize = getCartButtonSize();
  const cartButton = useMemo(() => _jsx(ProductOfferCartCounterContainer, {
    stretch: true,
    product: product,
    size: cartButtonSize,
    label: ProductOfferCartButtonLabelForDetails
  }), [product, cartButtonSize]);
  const subscription = useMemo(() => _jsx(OfferClaimSubscription, {
    onSubscribe: onSubscribe,
    onUnSubscribe: onUnSubscribe,
    isFetching: isSubscriptionFetching,
    enabled: isSubscriptionEnabled
  }), [onSubscribe, onUnSubscribe, isSubscriptionFetching, isSubscriptionEnabled]);
  const notFound = useMemo(() => _jsx(Typography, {
    color: "warning.main",
    children: "\u041D\u0435\u0442 \u0432 \u043D\u0430\u043B\u0438\u0447\u0438\u0438"
  }), []);
  return _jsxs(Wrapper, {
    children: [_jsxs(MPGrid, {
      container: true,
      flexDirection: "column",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(CodeTitle, {
          text: "\u0410\u0440\u0442\u0438\u043A\u0443\u043B",
          code: producerSKU ?? ''
        })
      }), isMdUp && _jsxs(_Fragment, {
        children: [_jsx(Splitter, {
          size: isMdUp ? 1.5 : 3
        }), _jsx(SidebarAttributes, {
          attributes: formattedAttributes
        })]
      }), isNotActive ? null : _jsxs(_Fragment, {
        children: [_jsx(Splitter, {
          size: 3
        }), _jsx(Prices, {
          price: price,
          salePercent: salePercent,
          originalPrice: originalPrice
        })]
      }), isMdUp && _jsx(MPGrid, {
        item: true,
        flexDirection: "column",
        children: isNotActive ? _jsxs(_Fragment, {
          children: [notFound, _jsx(Splitter, {
            size: 2
          }), subscription]
        }) : _jsxs(_Fragment, {
          children: [_jsx(Splitter, {
            size: 5
          }), cartButton]
        })
      }), _jsx(Splitter, {
        size: 3
      }), _jsx(SidebarInfo, {
        product: product,
        paymentTypes: nsi.paymentTypes,
        deliveryTypes: nsi.deliveryTypes,
        onClickPayment: onShowPaymentDialog,
        onClickDelivery: onShowDeliveryDialog
      }), !isMdUp && _jsx(Splitter, {
        size: 2
      })]
    }), !isMdUp && _jsx(BottomCartSection, {
      offset: isSmUp ? null : bottomMenuRect?.height,
      children: isNotActive ? _jsxs(MPGrid, {
        container: true,
        spacing: x2,
        children: [_jsx(MPGrid, {
          item: true,
          children: notFound
        }), _jsx(MPGrid, {
          item: true,
          children: subscription
        })]
      }) : cartButton
    })]
  });
};
export default ProductOfferSidebar;