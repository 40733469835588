import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { ContentWrapper } from './controls';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import useVisibleSectionLinks from '../../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const PartnerDeskDetailsLayout = props => {
  const {
    header,
    tabs,
    toolbar: Toolbar,
    loader,
    children
  } = props;
  const {
    x2,
    x3,
    x4
  } = useGridSpacing();
  useVisibleSectionLinks();
  return _jsx(DefaultContentWrapper, {
    children: _jsxs(ContentWrapper, {
      children: [header, _jsx(Splitter, {
        size: x2
      }), tabs, _jsx(Splitter, {
        size: x4
      }), Toolbar && _jsx(Toolbar, {}), _jsx(Splitter, {
        size: x3
      }), children, loader]
    })
  });
};