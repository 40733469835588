import { api, ECacheServicesTag } from './index';
export const cacheApi = api.injectEndpoints({
  endpoints: builder => ({
    getCachedData: builder.query({
      queryFn: () => {
        return {
          data: null
        };
      },
      providesTags: (result, error, args) => {
        const tags = [];
        if (args.tag) {
          tags.push(args.tag);
        }
        result?.data?.forEach(item => tags.push({
          type: ECacheServicesTag.Common,
          id: item.id
        }));
        return tags;
      }
    })
  })
});
export const {
  useGetCachedDataQuery
} = cacheApi;