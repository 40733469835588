import { Typography } from '@mui/material';
import { InfoEmptyIcon } from 'presentation/media/icons';
import { StyledFab, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferClaimHelp = _ref => {
  let {
    text,
    onClick
  } = _ref;
  return _jsxs(Wrapper, {
    children: [_jsx(Typography, {
      variant: "body2",
      children: text
    }), _jsx(StyledFab, {
      variant: "squared",
      color: "secondaryLight",
      size: "medium",
      onClick: onClick,
      children: _jsx(InfoEmptyIcon, {})
    })]
  });
};
export default OfferClaimHelp;