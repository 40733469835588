import { BreadcrumbTree } from 'presentation/components/common/breadcrumb/expanded';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { useProductOfferListCategoriesData } from '../hooks/useProductOfferListCategoriesData';
import { EAppCategories } from 'presentation/types';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ProductOfferListBreadcrumbsAdapter = () => {
  const {
    categoriesToBreadcrumbs
  } = useProductOfferListCategoriesData();
  const {
    x1
  } = useGridSpacing();
  const isBreadcrumbsVisible = categoriesToBreadcrumbs.length > 0;
  return _jsx(_Fragment, {
    children: isBreadcrumbsVisible && _jsxs(_Fragment, {
      children: [_jsx(BreadcrumbTree, {
        items: categoriesToBreadcrumbs,
        title: EAppCategories.ProductOffers
      }), _jsx(Splitter, {
        size: x1
      })]
    })
  });
};
export default ProductOfferListBreadcrumbsAdapter;