import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, Typography, useMediaQuery } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from '../../../../components/common/splitter';
import { ContentWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AboutCollapseWrapper = _ref => {
  let {
    id,
    titleRef,
    children,
    expanded,
    isCollapsable,
    title,
    onToggle
  } = _ref;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  return isCollapsable ? _jsxs(_Fragment, {
    children: [isMobile && _jsx(Divider, {}), _jsxs(MPGrid, {
      container: true,
      justifyContent: "space-between",
      onClick: onToggle,
      children: [_jsx(Splitter, {
        size: 4
      }), _jsx(MPGrid, {
        item: true,
        xs: 10,
        children: _jsx(Typography, {
          id: id,
          ref: titleRef,
          color: 'textPrimary',
          variant: 'h2',
          children: title
        })
      }), _jsx(MPGrid, {
        item: true,
        xs: 1,
        children: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {})
      }), _jsx(Splitter, {
        size: expanded ? 2 : 4
      })]
    }), _jsxs(Collapse, {
      in: expanded,
      timeout: 0,
      unmountOnExit: true,
      children: [children, _jsx(Splitter, {
        size: isMobile ? 4 : 0
      })]
    })]
  }) : _jsxs(ContentWrapper, {
    children: [_jsx(Typography, {
      id: id,
      ref: titleRef,
      variant: 'h2',
      children: title
    }), _jsx(Splitter, {
      size: isMobile ? 0 : 2
    }), children]
  });
};
export default AboutCollapseWrapper;