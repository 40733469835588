import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'lazyWrapper',
  initialState: [],
  reducers: {
    pushLazyLoadedItem: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid,
        containerId
      } = payload;
      const currentPage = state.find(page => page.guid === guid);
      if (!currentPage) {
        state.push({
          guid,
          containers: [containerId]
        });
      } else {
        if (!currentPage.containers.find(id => id === containerId)) currentPage.containers.push(containerId);
      }
    },
    resetLazyLoadedCollection: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const newState = state.filter(item => item.guid !== payload);
      state = newState;
    }
  }
});
export const {
  pushLazyLoadedItem,
  resetLazyLoadedCollection
} = slice.actions;
export default slice.reducer;