import { Typography } from '@mui/material';
import LightButton from 'presentation/components/common/buttons/light';
import Splitter from 'presentation/components/common/splitter';
import { ShortCharacteristics, Wrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferShortCharacteristics = _ref => {
  let {
    text,
    onShowFullCharacteristics
  } = _ref;
  const isCharacteristicsLonger4Rows = text.trim().split('\n\n').length > 4;
  return _jsxs(Wrapper, {
    children: [_jsx(Typography, {
      variant: "body1",
      fontWeight: "bold",
      children: "\u041A\u043E\u0440\u043E\u0442\u043A\u043E \u043E \u0442\u043E\u0432\u0430\u0440\u0435:"
    }), _jsx(ShortCharacteristics, {
      children: text
    }), isCharacteristicsLonger4Rows && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 1
      }), _jsx(LightButton, {
        size: "small",
        onClick: onShowFullCharacteristics,
        children: _jsx(Typography, {
          variant: "body2",
          children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432\u0441\u0435 \u0445\u0430\u0440\u0430\u043A\u0442\u0435\u0440\u0438\u0441\u0442\u0438\u043A\u0438"
        })
      })]
    })]
  });
};
export default ProductOfferShortCharacteristics;