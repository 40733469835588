/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, createRequest } from './http-client';
export const CommonsApi = {
  /**
   * No description
   * * @tags Commons
   * @name UserFeedback
   * @summary Форма обратной связи пользователя
   * @request POST:/feedback
   * @secure
   */
  userFeedback: (baseURL, data, signal) => createRequest({
    baseURL,
    path: `/feedback`,
    method: 'POST',
    body: data,
    type: ContentType.Json,
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name ListOrgUnits
   * @summary Получение списка орг едениц
   * @request GET:/org-units
   * @secure
   */
  listOrgUnits: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/org-units`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetOrgUnitById
   * @summary Получение подразделения по id
   * @request GET:/org-units/{orgUnitId}
   * @secure
   */
  getOrgUnitById: (baseURL, orgUnitId, signal) => createRequest({
    baseURL,
    path: `/org-units/${orgUnitId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name ListTargetLocalities
   * @summary Получение списка дорог
   * @request GET:/target-localities
   * @secure
   */
  listTargetLocalities: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/target-localities`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name ListRoads
   * @summary Получение списка дорог
   * @request GET:/roads
   * @secure
   */
  listRoads: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/roads`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetRoadById
   * @summary Получение дороги по id
   * @request GET:/roads/{roadId}
   * @secure
   */
  getRoadById: (baseURL, roadId, signal) => createRequest({
    baseURL,
    path: `/roads/${roadId}`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetPartnerRejectionReasonTypes
   * @summary Получение списка типов причин отклонения заявки на партнёрство
   * @request GET:/partners/rejection-reasons
   * @secure
   */
  getPartnerRejectionReasonTypes: (baseURL, signal) => createRequest({
    baseURL,
    path: `/partners/rejection-reasons`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetOfferRejectionReasonTypes
   * @summary Получение списка типов причин отклонения предложения
   * @request GET:/offers/rejection-reasons
   * @secure
   */
  getOfferRejectionReasonTypes: (baseURL, signal) => createRequest({
    baseURL,
    path: `/offers/rejection-reasons`,
    method: 'GET',
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetOfferPausedReasonTypes
   * @summary Получение списка типов причин приостановки предложений
   * @request GET:/offers/paused-reasons
   * @secure
   */
  getOfferPausedReasonTypes: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/offers/paused-reasons`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetCustomerOrderCancellationReasonTypes
   * @summary Получение списка типов причин отмены заказа покупателем
   * @request GET:/customer/order/cancel-reason-types
   * @secure
   */
  getCustomerOrderCancellationReasonTypes: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/customer/order/cancel-reason-types`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetOrderCancellationReasonTypes
   * @summary Получение списка типов причин отмены заказа
   * @request GET:/orders/cancel-reason-types
   * @secure
   */
  getOrderCancellationReasonTypes: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/orders/cancel-reason-types`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetDictionaries
   * @summary Получение списка справочников
   * @request GET:/dictionaries
   * @secure
   */
  getDictionaries: (baseURL, query, signal) => createRequest({
    baseURL,
    path: `/dictionaries`,
    method: 'GET',
    query: query,
    format: 'json',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryById
   * @summary Получение справочника
   * @request GET:/dictionaries/{dictionaryId}
   * @secure
   */
  getDictionaryById: (baseURL, dictionaryId, signal) => createRequest({
    baseURL,
    path: `/dictionaries/${dictionaryId}`,
    method: 'GET',
    signal
  }),
  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryValues
   * @summary Получение списка значений справочника
   * @request GET:/dictionaries/{dictionaryId}/values
   * @secure
   */
  getDictionaryValues: (baseURL, _ref, signal) => {
    let {
      dictionaryId,
      ...query
    } = _ref;
    return createRequest({
      baseURL,
      path: `/dictionaries/${dictionaryId}/values`,
      method: 'GET',
      query: query,
      format: 'json',
      signal
    });
  },
  /**
   * No description
   * * @tags Commons
   * @name GetDictionaryValueById
   * @summary Получение значения справочника
   * @request GET:/dictionary-values/{dictionaryValueId}
   * @secure
   */
  getDictionaryValueById: (baseURL, dictionaryValueId, signal) => createRequest({
    baseURL,
    path: `/dictionary-values/${dictionaryValueId}`,
    method: 'GET',
    signal
  })
};