import { Fade, useMediaQuery, useTheme } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { DetailsGrid, DetailsGridArea, EProductDetailsGridArea } from 'presentation/features/offer/product/details/controls';
import useVisibleSectionLinks from '../../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const ProductOfferDetailsLayout = props => {
  const {
    breadcrumbs,
    toolbar,
    title,
    favorite,
    tabs,
    carousel,
    attributes,
    shortCharacteristics,
    partnerInfo,
    sidebar
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  useVisibleSectionLinks(downMd);
  return _jsxs(_Fragment, {
    children: [toolbar && _jsx(ScreenNavWrapper, {
      children: toolbar
    }), _jsx(Fade, {
      in: true,
      children: _jsx(DefaultContentWrapper, {
        children: _jsxs(DetailsGrid, {
          children: [_jsx(DetailsGridArea, {
            area: EProductDetailsGridArea.Breadcrumbs,
            children: breadcrumbs
          }), _jsx(DetailsGridArea, {
            area: EProductDetailsGridArea.Title,
            children: title
          }), _jsx(DetailsGridArea, {
            area: EProductDetailsGridArea.Favorite,
            container: true,
            alignItems: "flex-end",
            justifyContent: isMdUp ? 'flex-end' : 'flex-start',
            children: favorite
          }), _jsxs(DetailsGridArea, {
            area: EProductDetailsGridArea.Presentation,
            flexDirection: "column",
            children: [carousel, isMdUp && _jsx(Splitter, {
              size: 5
            }), isMdUp && tabs]
          }), _jsxs(DetailsGridArea, {
            area: EProductDetailsGridArea.Controls,
            flexDirection: "column",
            children: [attributes, isMdUp && _jsx(Splitter, {
              size: 4
            }), isMdUp && shortCharacteristics, !isMdUp && partnerInfo, !isMdUp && tabs]
          }), _jsxs(DetailsGridArea, {
            area: EProductDetailsGridArea.Sidebar,
            flexDirection: "column",
            children: [sidebar, isMdUp && partnerInfo]
          })]
        })
      })
    })]
  });
};
export default ProductOfferDetailsLayout;