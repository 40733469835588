import useUserLocalSettings from 'presentation/hooks/useUserLocalSettings';
import { useContextHandlers } from '../hooks/useContextHandlers';
const useProductOffersCatalog = () => {
  const {
    city
  } = useUserLocalSettings().settings;
  const handlers = useContextHandlers();
  return {
    city: city?.name ?? '',
    onChangeCategory: handlers.onSelectProductOffersCategory
  };
};
export default useProductOffersCatalog;