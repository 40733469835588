import store from 'data/store/store';
import { EOfferListType, EOfferStatus, EOfferType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getOffersListQueryParams } from '../../../utils';
import { getCorpOffersSearchRoute } from '../../routes';
import { corpOffersDefaultParams } from '../../utils';
import { corpOfferListSelector } from '../store/selectors';
import { corpOffersSetArgs, corpOffersSetIsNewFetching, corpOffersSetIsNewSearchFetching, corpOffersSetPage, corpOffersSetPageSize, corpOffersSetSort, corpOffersStartSession } from '../store/slice';
const emptyParams = corpOffersDefaultParams;
const useCorpOfferListHandlers = props => {
  const {
    guid,
    name,
    categoryId,
    partnerId
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    webAnalytics
  } = useWebAnalytics();
  const getCurrentState = useCallback(() => {
    return corpOfferListSelector(store.getState());
  }, []);
  const onChangeSort = useCallback(sort => {
    dispatch(corpOffersSetIsNewFetching(false));
    dispatch(corpOffersSetIsNewSearchFetching(false));
    dispatch(corpOffersSetSort({
      type: EOfferListType.Common,
      sort
    }));
    dispatch(corpOffersSetSort({
      type: EOfferListType.Upcoming,
      sort
    }));
  }, [dispatch]);
  const onChangePageSize = useCallback((type, pageSize) => {
    dispatch(corpOffersSetIsNewFetching(false));
    dispatch(corpOffersSetIsNewSearchFetching(false));
    dispatch(corpOffersSetPageSize({
      type,
      pageSize
    }));
  }, [dispatch]);
  const onChangePage = useCallback((type, page) => {
    dispatch(corpOffersSetIsNewFetching(false));
    dispatch(corpOffersSetIsNewSearchFetching(false));
    dispatch(corpOffersSetPage({
      type,
      page
    }));
  }, [dispatch]);
  const onChangeCategory = useCallback(category => {
    dispatch(corpOffersSetIsNewFetching(true));
    dispatch(corpOffersSetIsNewSearchFetching(false));
    history.push(getCorpOffersSearchRoute({
      name,
      categoryId: category.id,
      guid,
      partnerId
    }));
  }, [dispatch, history, name, guid, partnerId]);
  const onReturnToTopCategory = useCallback(id => {
    dispatch(corpOffersSetIsNewFetching(true));
    dispatch(corpOffersSetIsNewSearchFetching(false));
    history.push(getCorpOffersSearchRoute({
      name,
      categoryId: id,
      guid,
      partnerId: partnerId
    }));
  }, [dispatch, history, guid, name, partnerId]);
  const onShowCard = useCallback(corpOffer => {
    webAnalytics.offerShowInList(corpOffer.id);
  }, [webAnalytics]);
  useEffect(() => {
    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentName = state.common.search?.name;
    const currentCategory = state.common.search?.categories?.[0];
    const currentPartnerId = state.common.search?.partnerId;
    dispatch(corpOffersStartSession({
      guid
    }));
    const isGuidChanged = currentGuid !== guid;
    const isNameChanged = (currentName || null) !== (name || null);
    const isCategoryChanged = (currentCategory || null) !== (categoryId || null);
    const isPartnerIdChanged = (currentPartnerId || null) !== (partnerId || null);
    let commonArgs = state.common;
    let upcomingArgs = state.upcoming;
    if (isGuidChanged) {
      commonArgs = emptyParams;
      upcomingArgs = emptyParams;
    }
    dispatch(corpOffersSetArgs({
      type: EOfferListType.Common,
      args: getOffersListQueryParams({
        name,
        categories: categoryId ? [categoryId] : null,
        partnerId,
        sort: commonArgs.sort,
        page: commonArgs.page,
        pageSize: commonArgs.pageSize
      }, EOfferType.Corp)
    }));
    dispatch(corpOffersSetArgs({
      type: EOfferListType.Upcoming,
      args: getOffersListQueryParams({
        name,
        categories: categoryId ? [categoryId] : null,
        partnerId,
        sort: upcomingArgs.sort,
        page: upcomingArgs.page,
        pageSize: upcomingArgs.pageSize,
        statuses: [EOfferStatus.Upcoming]
      }, EOfferType.Corp)
    }));
    if (isGuidChanged || isCategoryChanged || isPartnerIdChanged) {
      dispatch(corpOffersSetIsNewFetching(true));
    } else {
      dispatch(corpOffersSetIsNewFetching(false));
    }
    if (isGuidChanged || isNameChanged) {
      dispatch(corpOffersSetIsNewSearchFetching(true));
    } else {
      dispatch(corpOffersSetIsNewSearchFetching(false));
    }
  }, [dispatch, categoryId, guid, name, getCurrentState, partnerId]);
  return {
    onChangeSort,
    onChangePage,
    onChangePageSize,
    onChangeCategory,
    onReturnToTopCategory,
    onShowCard
  };
};
export default useCorpOfferListHandlers;