import { AttributeText } from './controls';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SidebarAttribute = _ref => {
  let {
    name,
    value
  } = _ref;
  return _jsxs(AttributeText, {
    children: [name, ": ", value]
  });
};
export default SidebarAttribute;