import { useMediaQuery } from '@mui/material';
import HorizontalScroller, { HorizontalScrollerItem } from 'presentation/components/common/horizontalScroll';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferSubCategoryChipItem from './chipItem';
import { SubCategoryLinkWrapper } from './controls';
import OfferSubCategoryLinkItem from './linkItem';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OfferSubCategories = props => {
  const {
    categories,
    linkFactory
  } = props;
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  if (!categories) {
    return null;
  }
  return isMdDown ? _jsx(HorizontalScroller, {
    gap: 8,
    children: categories.map(category => _jsx(HorizontalScrollerItem, {
      children: _jsx(OfferSubCategoryChipItem, {
        label: category.name,
        to: linkFactory(category)
      })
    }, category.id))
  }) : _jsx(_Fragment, {
    children: _jsx(MPGrid, {
      container: true,
      direction: isMdDown ? 'row' : 'column',
      children: categories.map(category => _jsx(SubCategoryLinkWrapper, {
        item: true,
        children: _jsx(OfferSubCategoryLinkItem, {
          to: linkFactory(category),
          children: category.name
        })
      }, category.id))
    })
  });
};
export default OfferSubCategories;