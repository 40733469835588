import { Tab, useMediaQuery } from '@mui/material';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import PartnerDeskDetailsAboutLayout from '../../../../partnerDesk/details/tabs/aboutCompany/layout';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import { OfferTabs } from '../../../components/tabs/controls';
import BookingOfferServicesTabsAdapter from '../adapters/services';
import { EBookingOfferDetailsTab } from '../types';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOfferTabs = _ref => {
  let {
    onChange,
    tab,
    id,
    bookingOffer
  } = _ref;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return _jsxs(MPGrid, {
    container: true,
    spacing: isMdUp ? 3 : 2,
    children: [_jsx(MPGrid, {
      item: true,
      xs: true,
      children: _jsx(TabsWrapper, {
        children: _jsxs(OfferTabs, {
          value: tab,
          textColor: "secondary",
          variant: "scrollable",
          onChange: onChange,
          scrollButtons: false,
          children: [_jsx(Tab, {
            label: "\u0423\u0441\u043B\u0443\u0433\u0438",
            value: EBookingOfferDetailsTab.Services
          }), bookingOffer.description && _jsx(Tab, {
            label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435",
            value: EBookingOfferDetailsTab.Description
          }), bookingOffer.contacts && _jsx(Tab, {
            label: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B",
            value: EBookingOfferDetailsTab.Contacts
          })]
        })
      })
    }), _jsxs(MPGrid, {
      item: true,
      zero: 12,
      children: [tab === EBookingOfferDetailsTab.Services && _jsx(BookingOfferServicesTabsAdapter, {
        id: id
      }), tab === EBookingOfferDetailsTab.Description && bookingOffer.description && _jsx(PartnerDeskDetailsAboutLayout, {
        children: bookingOffer.description
      }), tab === EBookingOfferDetailsTab.Contacts && bookingOffer.contacts && _jsx(OfferDetailsTabContacts, {
        contacts: bookingOffer.contacts,
        descriptionTitle: "\u041A\u0430\u043A \u0434\u043E\u0431\u0440\u0430\u0442\u044C\u0441\u044F",
        description: bookingOffer.routeDescription
      })]
    })]
  });
};
export default BookingOfferTabs;