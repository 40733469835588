import { MPDialog } from 'presentation/theme/ui-kit/dialog';
import { TradeOfferClaimWidgetAdapter } from '../widget';
import { WidgetContent } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimWidgetDialogAdapter = props => {
  const {
    open,
    title,
    onClose,
    wrapperRef,
    srcDoc
  } = props;
  return _jsx(MPDialog, {
    open: open,
    title: title,
    closeButtonVisible: true,
    onClose: onClose,
    maxWidth: "xs",
    fullScreen: false,
    fullScreenBreakpoint: "sm",
    children: _jsx(WidgetContent, {
      children: _jsx(TradeOfferClaimWidgetAdapter, {
        srcDoc: srcDoc,
        wrapperRef: wrapperRef
      }, 'widget')
    })
  });
};
export default TradeOfferClaimWidgetDialogAdapter;