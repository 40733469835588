import ErrorBoundaryCommon from './common';
import ErrorBoundarySentry from './sentry';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const ErrorBoundary = _ref => {
  let {
    variant,
    onLogout,
    children
  } = _ref;
  if (variant === 'common') {
    return _jsx(ErrorBoundaryCommon, {
      onLogout: onLogout,
      children: children
    });
  }
  if (variant === 'sentry') {
    return _jsx(ErrorBoundarySentry, {
      onLogout: onLogout,
      children: children
    });
  }
  return _jsx(_Fragment, {
    children: children
  });
};
export default ErrorBoundary;