import AboutTextSectionsComponent from 'presentation/features/about/textSections/component';
import AboutSectionsLayout from 'presentation/layouts/aboutSections';
import AboutTabsComponent from '../components/tabs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = AboutSectionsLayout;
const AboutTextSectionsContainer = _ref => {
  let {
    title,
    sections,
    subText,
    close
  } = _ref;
  return _jsx(Layout, {
    title: title,
    description: subText,
    close: close,
    tabs: (current, onChangeTab) => _jsx(AboutTabsComponent, {
      value: current,
      tabs: sections,
      onChange: onChangeTab,
      title: title
    }),
    children: _ref2 => {
      let {
        expandedItem,
        setSectionRef,
        onSectionToggle,
        isCollapsable
      } = _ref2;
      return _jsx(AboutTextSectionsComponent, {
        sections: sections,
        expanded: expandedItem,
        isCollapsable: isCollapsable,
        onToggle: onSectionToggle,
        setSectionRef: setSectionRef
      });
    }
  });
};
export default AboutTextSectionsContainer;