import { EOfferStatus, EPaymentType } from 'domain/model/enums';
import { useMemo } from 'react';
import OfferClaimPriceInfo from '../../../../components/claim/priceInfo';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimPriceAdapter = () => {
  const {
    corpOffer,
    isActivationReceivedNow
  } = useCorpOfferClaimContext();
  return useMemo(() => corpOffer?.id && (corpOffer.status === EOfferStatus.Active || corpOffer.status === EOfferStatus.Upcoming || isActivationReceivedNow) ? _jsx(OfferClaimPriceInfo, {
    paymentType: EPaymentType.BenefitRzdPoint,
    price: corpOffer.price,
    originalPrice: corpOffer.originalPrice
  }) : null, [corpOffer?.id, corpOffer?.status, corpOffer?.price, corpOffer?.originalPrice, isActivationReceivedNow]);
};