import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getOffersListQueryParams } from 'presentation/features/offer/utils';
import { utcToLocalTimeZone } from '../../../utils/date';
export let EAspOfferUrlParam = function (EAspOfferUrlParam) {
  EAspOfferUrlParam["Tab"] = "tab";
  EAspOfferUrlParam[EAspOfferUrlParam["Category"] = ECatalogUrlParam.Category] = "Category";
  EAspOfferUrlParam["PartnerId"] = "partnerId";
  return EAspOfferUrlParam;
}({});
const aspOfferCodeAssignIntervalInSeconds = 300;
export const getAspOfferCodeAssignDurationInSeconds = appointmentDate => {
  const now = moment();
  const end = utcToLocalTimeZone(appointmentDate).add(aspOfferCodeAssignIntervalInSeconds, 'seconds');
  return Math.trunc(moment.duration(end.diff(now)).asSeconds());
};
export const aspOffersDefaultParams = getOffersListQueryParams({
  name: null,
  categories: null,
  partnerId: null,
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null
}, EOfferType.Trade);