import { typographyClasses, listClasses, menuItemClasses, paperClasses } from '@mui/material';
const menus = baseTheme => ({
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: `1px solid ${baseTheme.palette.secondary.A600}`,
        boxShadow: `0px 20px 30px -20px rgba(109, 121, 143, 0.35)`
      },
      list: {
        paddingTop: 0,
        paddingBottom: 0
      },
      root: {
        [`& .${typographyClasses.root}`]: {
          fontWeight: baseTheme.typography.body2.fontWeight,
          fontSize: baseTheme.typography.body2.fontSize,
          lineHeight: baseTheme.typography.body2.lineHeight
        },
        [`& .${listClasses.root}`]: {
          padding: baseTheme.spacing(1)
        },
        [`& .${paperClasses.root}`]: {
          transform: 'translateY(2px) !important'
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minHeight: 'auto',
        borderRadius: baseTheme.shape.borderRadius,
        [`&:hover, &.${menuItemClasses.selected}:hover`]: {
          backgroundColor: baseTheme.palette.secondary.A600
        },
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: baseTheme.palette.secondary.A600
        },
        [`&.${menuItemClasses.selected} .${typographyClasses.root}`]: {
          fontWeight: baseTheme.typography.fontWeightBold
        }
      }
    }
  }
});
export default menus;