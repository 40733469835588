import { Link } from '@mui/material';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimExtCertificateActivation = props => {
  const {
    url,
    onActivate,
    disabled
  } = props;
  return _jsx(CorpLinkProxy, {
    link: url ?? '',
    children: linkByProxy => _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      href: linkByProxy,
      component: Link,
      disabled: disabled,
      target: "_blank",
      onClick: onActivate,
      children: "\u041E\u0431\u043C\u0435\u043D\u044F\u0442\u044C \u043D\u0430 \u0421\u0435\u0440\u0432\u0438\u0441\u043D\u043E\u043C \u043F\u043E\u0440\u0442\u0430\u043B\u0435"
    })
  });
};