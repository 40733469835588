import { useGetPartnersNameMapQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { addStateToLocation } from 'routing';
import { ELocaleShort } from '../../../../types';
import { filterAlhpabetByLocale, filterDigitsAndSpecials } from './utils';
const defaultLocale = ELocaleShort.Ru;
export const useAlphabet = () => {
  const {
    data: alphabetMap,
    error: alphabetFetchError,
    isLoading: isAlphabetLoading
  } = useGetPartnersNameMapQuery();
  const history = useHistory();
  const location = useLocation();
  const currentLocationLocale = location.state.alphabetLocale;
  const currentLocale = currentLocationLocale || defaultLocale;
  const changeLocationLocale = useCallback(locale => {
    history.replace(addStateToLocation({
      location,
      state: {
        alphabetLocale: locale
      }
    }));
  }, [history, location]);
  useEffect(() => {
    if (!currentLocationLocale) {
      changeLocationLocale(defaultLocale);
    }
    if (currentLocale !== currentLocationLocale) {
      changeLocationLocale(currentLocale);
    }
  }, [changeLocationLocale, currentLocale, currentLocationLocale]);
  useEffect(() => {
    if (alphabetFetchError) {
      ErrorHandler.handleHttpError(alphabetFetchError);
    }
  }, [alphabetFetchError]);
  const localeAlphabet = useMemo(() => {
    if (!alphabetMap) {
      return [];
    }
    return alphabetMap.filter(filterAlhpabetByLocale(currentLocale));
  }, [alphabetMap, currentLocale]);
  const onChangeLocale = useCallback(locale => {
    changeLocationLocale(locale);
  }, [changeLocationLocale]);
  const digitsAndSpecialSymbols = useMemo(() => {
    return alphabetMap ? alphabetMap.filter(filterDigitsAndSpecials) : [];
  }, [alphabetMap]);
  return {
    isAlphabetLoading: isAlphabetLoading || !location.state.alphabetLocale,
    localeAlphabet,
    currentLocale,
    digitsAndSpecialSymbols,
    onChangeLocale
  };
};