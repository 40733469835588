import { Route, Switch } from 'react-router-dom';
import LoginScreen from 'presentation/screen/login';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AuthEntry = () => {
  return _jsx(Switch, {
    children: _jsx(Route, {
      component: LoginScreen
    })
  });
};
export default AuthEntry;