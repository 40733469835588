import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SubCategoryChip } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OfferSubCategoryChipItem = props => {
  const {
    label,
    ...otherProps
  } = props;
  return _jsx(SubCategoryChip, {
    component: Link,
    variant: 'filled',
    clickable: true,
    label: _jsx(Typography, {
      variant: "body1",
      children: label
    }),
    ...otherProps
  });
};
export default OfferSubCategoryChipItem;