import { HeaderWrapper, LoaderWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CmsBannersLayout = props => {
  const {
    header,
    footer,
    loader,
    children
  } = props;
  return _jsxs(_Fragment, {
    children: [header && _jsx(HeaderWrapper, {
      children: header
    }), children, footer, loader && _jsx(LoaderWrapper, {
      children: loader
    })]
  });
};
export default CmsBannersLayout;