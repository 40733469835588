import { useTheme } from '@mui/material';
import { StickyContainer } from 'presentation/components/common/wrappers/sticky';
import { useHeaderParams } from 'presentation/hooks/useHeaderParams';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductListSidebar = _ref => {
  let {
    children
  } = _ref;
  const {
    spacing
  } = useTheme();
  const {
    headerHeight
  } = useHeaderParams();
  return _jsx(Wrapper, {
    children: _jsx(StickyContainer, {
      top: headerHeight + parseInt(spacing(7), 10),
      children: children
    })
  });
};
export default ProductListSidebar;