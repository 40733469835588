import useFavoritesCounter from 'presentation/features/favorite/counter/useCounter';
import { getUserFavoritesRoute } from 'presentation/features/user/entry';
import { useHistory } from 'react-router';
import { UserMenuItemFavorites } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuFavoritesAdapter = () => {
  const history = useHistory();
  const {
    count
  } = useFavoritesCounter();
  const onClick = () => {
    history.push(getUserFavoritesRoute({}));
  };
  return _jsx(UserMenuItemFavorites, {
    count: count,
    onClick: onClick
  });
};