import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import useFavicon from 'presentation/hooks/useFavicon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const cache = createCache({
  key: 'mui',
  prepend: true
});
export const MPThemeProvider = _ref => {
  let {
    theme,
    children
  } = _ref;
  useFavicon(theme.favicon);
  return _jsx(CacheProvider, {
    value: cache,
    children: _jsx(StyledEngineProvider, {
      injectFirst: true,
      children: _jsxs(ThemeProvider, {
        theme: theme,
        children: [_jsx(CssBaseline, {}), children]
      })
    })
  });
};