import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryContent from './content';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ErrorBoundarySentry = _ref => {
  let {
    children,
    onLogout
  } = _ref;
  return _jsx(ErrorBoundary, {
    fallback: _ref2 => {
      let {
        error,
        componentStack
      } = _ref2;
      return _jsx(ErrorBoundaryContent, {
        message: error.toString(),
        stacktrace: componentStack,
        onLogout: onLogout
      });
    },
    children: children
  });
};
export default ErrorBoundarySentry;