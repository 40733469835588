import { FreeMode, Mousewheel, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper';
import { Swiper } from 'swiper/react';
import { DetailSwiperWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CarouselDefault = props => {
  const {
    swiperProps,
    thumbsSwiper,
    handlerChangeActiveIndex,
    onSwiper,
    children,
    controlsElement,
    aspectRatio
  } = props;
  return _jsxs(DetailSwiperWrapper, {
    aspectRatio: aspectRatio,
    children: [_jsx(Swiper, {
      ...swiperProps,
      navigation: false,
      loop: children?.length > 1 ? swiperProps.loop : false,
      thumbs: {
        swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
      },
      runCallbacksOnInit: true,
      modules: [...(swiperProps?.modules ?? []), FreeMode, Navigation, Thumbs, Pagination, Mousewheel, Scrollbar],
      onSwiper: onSwiper,
      onSlideChange: handlerChangeActiveIndex,
      children: children
    }), controlsElement]
  });
};
export default CarouselDefault;