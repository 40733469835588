import { bookingOfferSortType, EBookingOfferSortType } from 'domain/model/enums';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../components/sortSelect';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferListIsNewSearchFetchingSelector, bookingOfferListNoDataSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const items = Object.values(bookingOfferSortType);
export const BookingOfferListSortSelectAdapter = _ref => {
  let {
    sort,
    filterCount
  } = _ref;
  const {
    onChangeSort
  } = useContextHandlers();
  const noData = useSelector(bookingOfferListNoDataSelector);
  const isNewSearchFetching = useSelector(bookingOfferListIsNewSearchFetchingSelector);
  return noData && !filterCount || isNewSearchFetching ? null : _jsx(OffersSortSelect, {
    value: sort,
    items: items,
    defaultValue: EBookingOfferSortType.Default,
    onChange: onChangeSort
  });
};