import { EOfferStatus } from 'domain/model/enums';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EAspOfferServicesTag, EBookingOfferServicesTag, ECorpOfferServicesTag, EProductOfferServicesTag, ETradeOfferServicesTag } from './index';
const defaultPartnerOfferValidStatuses = [EOfferStatus.Active, EOfferStatus.OutOfStock];
export const partnerApi = api.injectEndpoints({
  endpoints: builder => ({
    getPartnerTradeOfferList: builder.query({
      query: _ref => {
        let {
          partnerId,
          categoryId,
          sort,
          page,
          pageSize,
          statuses = defaultPartnerOfferValidStatuses
        } = _ref;
        return apiDefinition.user.trade.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      providesTags: (result, error, args) => [{
        type: ETradeOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerTradeOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref2 => {
        let {
          partnerId,
          statuses
        } = _ref2;
        return apiDefinition.user.trade.categoriesUsed({
          partnerId,
          statuses
        });
      },
      providesTags: (result, error, args) => [{
        type: ETradeOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerAspOfferList: builder.query({
      query: _ref3 => {
        let {
          partnerId,
          categoryId,
          sort,
          page,
          pageSize,
          statuses = defaultPartnerOfferValidStatuses
        } = _ref3;
        return apiDefinition.user.asp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      providesTags: (result, error, args) => [{
        type: EAspOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerAspOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref4 => {
        let {
          partnerId,
          statuses
        } = _ref4;
        return apiDefinition.user.asp.categoriesUsed({
          partnerId,
          statuses
        });
      },
      providesTags: (result, error, args) => [{
        type: EAspOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerProductOfferList: builder.query({
      query: _ref5 => {
        let {
          partnerId,
          sort,
          page,
          pageSize,
          statuses = defaultPartnerOfferValidStatuses
        } = _ref5;
        return apiDefinition.user.product.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      providesTags: (result, error, args) => [{
        type: EProductOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerCorpOfferList: builder.query({
      query: _ref6 => {
        let {
          partnerId,
          categoryId,
          sort,
          page,
          pageSize,
          statuses = defaultPartnerOfferValidStatuses
        } = _ref6;
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          statuses,
          partnerId,
          categories: categoryId ? [categoryId] : null
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      providesTags: (result, error, args) => [{
        type: ECorpOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerCorpOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref7 => {
        let {
          partnerId,
          statuses
        } = _ref7;
        return apiDefinition.user.corp.categoriesUsed({
          partnerId,
          statuses
        });
      },
      providesTags: (result, error, args) => [{
        type: ECorpOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerBookingOfferList: builder.query({
      query: _ref8 => {
        let {
          categoryId,
          page,
          pageSize,
          ...bookingProps
        } = _ref8;
        return apiDefinition.user.booking.all({
          page: 1,
          pageSize: page * pageSize,
          categories: categoryId ? [categoryId] : null,
          minPrice: null,
          maxPrice: null,
          ...bookingProps
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      providesTags: (result, error, args) => [{
        type: EBookingOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerBookingOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref9 => {
        let {
          partnerId
        } = _ref9;
        return apiDefinition.user.booking.categoriesUsed({
          partnerId
        });
      },
      providesTags: (result, error, args) => [{
        type: EBookingOfferServicesTag.ByPartnerList,
        id: args.partnerId
      }]
    }),
    getPartnerDesksCollection: builder.query({
      query: id => apiDefinition.partner.desk.collection({
        id
      }),
      transformResponse: response => response.data
    }),
    getPartnerDesk: builder.query({
      query: id => apiDefinition.partner.desk.one({
        id
      }),
      transformResponse: response => response.data
    }),
    getPartnerDesks: builder.query({
      query: _ref10 => {
        let {
          page,
          pageSize,
          query,
          name
        } = _ref10;
        return apiDefinition.partner.desk.all({
          page,
          pageSize,
          query,
          name
        });
      },
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      }
    }),
    getPartnerPage: builder.query({
      query: partnerId => apiDefinition.partner.page({
        partnerId
      }),
      transformResponse: response => response.data
    }),
    getPartnerTestPage: builder.query({
      query: _ref11 => {
        let {
          partnerId
        } = _ref11;
        return apiDefinition.partner.pageTest({
          partnerId
        });
      },
      transformResponse: response => response.data
    }),
    getPartnersNameMap: builder.query({
      query: () => apiDefinition.partner.nameMap(),
      transformResponse: response => response.data
    })
  })
});
export const {
  useGetPartnerTradeOfferListQuery,
  useGetPartnerTradeOfferCategoriesUsedQuery,
  useGetPartnerAspOfferListQuery,
  useGetPartnerAspOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferCategoriesUsedQuery,
  useGetPartnerCorpOfferListQuery,
  useGetPartnerProductOfferListQuery,
  useGetPartnerBookingOfferListQuery,
  useGetPartnerBookingOfferCategoriesUsedQuery,
  useGetPartnerDeskQuery,
  useGetPartnerDesksQuery,
  useGetPartnerPageQuery,
  useGetPartnerDesksCollectionQuery,
  useGetPartnerTestPageQuery,
  useGetPartnersNameMapQuery
} = partnerApi;