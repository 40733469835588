import { EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { CorpOfferClaimArchivedMessage } from './archived';
import { CorpOfferClaimPausedMessage } from './paused';
import { CorpOfferClaimPendingInfoMessage } from './pendingInfo';
import { CorpOfferClaimRejectedInfoMessage } from './rejectedInfo/rejectedInfo';
import { CorpOfferClaimUnavailableMessage } from './unavailableInfo';
import { CorpOfferClaimUpcomingMessage } from './upcomingInfo';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimInfoMessagesAdapter = () => {
  const {
    corpOffer,
    isActivationReceivedNow,
    activation
  } = useCorpOfferClaimContext();
  const isActivationAvailable = activation.isActivationAvailable;
  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return _jsx(CorpOfferClaimUnavailableMessage, {});
      } else {
        return _jsxs(_Fragment, {
          children: [corpOffer.status === EOfferStatus.Upcoming && _jsx(CorpOfferClaimUpcomingMessage, {
            startDate: corpOffer.startDate
          }), corpOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && _jsx(CorpOfferClaimPausedMessage, {}), corpOffer.status === EOfferStatus.Archived && _jsx(CorpOfferClaimArchivedMessage, {}), activation.lastActivation?.status === EOfferActivationStatus.Pending && _jsx(CorpOfferClaimPendingInfoMessage, {
            activationComment: activation.lastActivation?.lastStatusComment || 'Ваша заявка будет рассмотрена '
          }), !isActivationReceivedNow && activation.lastActivation?.status === EOfferActivationStatus.Rejected && _jsx(CorpOfferClaimRejectedInfoMessage, {
            activationComment: activation.lastActivation?.lastStatusComment || 'Предложение отклонено'
          })]
        });
      }
    }
  }, [activation.lastActivation?.lastStatusComment, activation.lastActivation?.status, corpOffer, isActivationAvailable, isActivationReceivedNow]);
};