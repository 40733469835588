import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { UserMenuItemLogout } from '../../components/menuItems';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuLogoutAdapter = () => {
  const {
    logOut
  } = useAuth();
  return _jsx(UserMenuItemLogout, {
    onClick: () => logOut()
  });
};