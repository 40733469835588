import { useContext, useEffect } from 'react';
import ErrorHandler from 'data/network/errorHandler';
import { useGetUserOrderQuery } from 'data/api/user';
import RenderManagerErrorContext from 'presentation/components/common/renderManager/error/context';
const useBookingOfferOrderData = id => {
  const {
    setError
  } = useContext(RenderManagerErrorContext);
  const {
    data: order,
    isFetching,
    isLoading,
    error: orderFetchError
  } = useGetUserOrderQuery(id, {
    refetchOnMountOrArgChange: true
  });
  useEffect(() => {
    if (orderFetchError) {
      ErrorHandler.handleHttpError(orderFetchError);
      setError(orderFetchError);
    }
  }, [setError, orderFetchError]);
  return {
    order: order,
    isFetching,
    isLoading
  };
};
export default useBookingOfferOrderData;