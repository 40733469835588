import { ECmsPreviewUrlParam } from 'presentation/features/cms/utils';
import { useLocation } from 'react-router';
export const useCmsTargetSearchParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetParam = searchParams.get(ECmsPreviewUrlParam.Target) ?? '{}';
  const target = JSON.parse(targetParam);
  return target;
};
export const useCmsContextSearchParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contextParam = searchParams.get(ECmsPreviewUrlParam.Context) ?? '{}';
  const context = JSON.parse(contextParam);
  return context;
};