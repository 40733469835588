import { Container } from '@mui/material';
import React from 'react';
import { useHeaderParams } from '../../../../hooks/useHeaderParams';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ScreenNavWrapper = _ref => {
  let {
    children
  } = _ref;
  const {
    headerHeight
  } = useHeaderParams({
    allHeaders: false
  });
  return _jsx(Wrapper, {
    position: "sticky",
    offsetTop: headerHeight,
    children: _jsx(Container, {
      children: children
    })
  });
};
export default ScreenNavWrapper;