import { Hidden, Typography } from '@mui/material';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
// todo вызывается при клике на показать поностью из инфо мессадж
const OfferUnavailableDialog = _ref => {
  let {
    open,
    onShowOtherOffers,
    onClose
  } = _ref;
  const {
    x3
  } = useGridSpacing();
  return _jsx(MPDialog, {
    open: open,
    maxWidth: "xs",
    title: "\u041F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E",
    onClose: onClose,
    children: _jsx(MPGrid, {
      container: true,
      children: _jsxs(MPGrid, {
        item: true,
        zero: 12,
        container: true,
        direction: "column",
        spacing: x3,
        children: [_jsxs(MPGrid, {
          item: true,
          children: [_jsx(Typography, {
            children: "\u041A \u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, \u0434\u0430\u043D\u043D\u043E\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E."
          }), _jsx(Typography, {
            children: "\u041F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 \u043F\u043E \u0441\u0441\u044B\u043B\u043A\u0435, \u0447\u0442\u043E\u0431\u044B \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u0438\u0442\u044C\u0441\u044F \u0441 \u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u044B\u043C\u0438 \u0434\u043B\u044F \u0432\u0430\u0441 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F\u043C\u0438."
          })]
        }), _jsx(Hidden, {
          smUp: true,
          children: _jsx(MPGrid, {
            item: true,
            alignSelf: "center",
            children: _jsx(MPButton, {
              onClick: onShowOtherOffers,
              children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0434\u0440\u0443\u0433\u0438\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F"
            })
          })
        }), _jsx(Hidden, {
          smDown: true,
          children: _jsx(MPGrid, {
            item: true,
            children: _jsx(MPButton, {
              onClick: onShowOtherOffers,
              children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0434\u0440\u0443\u0433\u0438\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F"
            })
          })
        })]
      })
    })
  });
};
export default OfferUnavailableDialog;