import axios from 'axios';
import { createCancelToken, getUserQueryParams } from '../utils';
const order = {
  one: _ref => {
    let {
      id,
      signal
    } = _ref;
    return {
      url: `/customers/current/orders/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  all: props => {
    const {
      statuses,
      signal,
      offerType,
      ...queryParams
    } = props;
    const params = getUserQueryParams(queryParams);
    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }
    if (offerType) {
      offerType.forEach(item => params.append('offerType', item));
    }
    return {
      url: `/customers/current/orders`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal)
    };
  },
  create: data => {
    return {
      url: `/customers/current/orders`,
      method: 'POST',
      data
    };
  },
  cancel: _ref2 => {
    let {
      id,
      signal,
      cancellationType,
      comment
    } = _ref2;
    return {
      url: `/customers/current/orders/${id}`,
      method: 'POST',
      data: {
        cancellationType,
        comment,
        discriminator: 'CustomerCancelOrderCommand'
      },
      cancelToken: signal && createCancelToken(axios, signal)
    };
  }
};
export default order;