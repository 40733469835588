import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import { localeRu } from './locale';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const permanentOptions = {
  weekStartDayIndex: 1,
  headerOrder: ['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON'],
  locale: localeRu,
  monthYearSeparator: ' ',
  renderButton: (direction, handleClick) => {
    return _jsxs(_Fragment, {
      children: [direction === 'left' && _jsx(ArrowLeftIcon, {
        fontSize: "large",
        color: "primary",
        onClick: handleClick
      }), direction === 'right' && _jsx(ArrowRightIcon, {
        fontSize: "large",
        color: "primary",
        onClick: handleClick
      })]
    });
  }
};
export const regularOptions = {
  showOtherDays: true,
  shadow: false
};