import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import OfferClaimLinkToActivations from '../../../../components/claim/linkToActivations';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferClaimLinkToActivationsAdapter = () => {
  const {
    aspOffer,
    activation
  } = useAspOfferClaimContext();
  if (!aspOffer) {
    return null;
  }
  const isVisible = activation.isLastActivationPending;
  return isVisible ? _jsx(OfferClaimLinkToActivations, {}) : null;
};
export default AspOfferClaimLinkToActivationsAdapter;