import { useHeaderParams } from 'presentation/hooks/useHeaderParams';
import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserDetailsToolbarLayout = props => {
  const {
    children
  } = props;
  const {
    headerHeight
  } = useHeaderParams();
  return _jsx(Wrapper, {
    top: headerHeight,
    children: children
  });
};
export default UserDetailsToolbarLayout;