import { useGetAspOfferCategoriesUsedTreeQuery } from 'data/api/aspOffer';
import { ECatalogTab } from 'presentation/features/catalog/types';
import { useSelector } from 'react-redux';
import { CatalogOffersContainer } from '../components/catalogContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { catalogTabSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CatalogAspOffersTabAdapter = props => {
  const {
    selected
  } = props;
  const tab = useSelector(catalogTabSelector);
  const {
    onSelectAspOffersCategory
  } = useContextHandlers();
  const isActiveTab = tab === ECatalogTab.AspOffers;
  const response = useGetAspOfferCategoriesUsedTreeQuery({
    guid: 'catalog-asp-offers'
  }, {
    skip: !isActiveTab
  });
  return isActiveTab ? _jsx(CatalogOffersContainer, {
    ...response,
    onSelect: onSelectAspOffersCategory,
    selected: selected
  }) : null;
};