import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper } from '../controls';
import { pushLazyLoadedItem } from '../store/slice';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const options = {
  root: null,
  rootMargin: '20%',
  threshold: 0
};
export const LazyContentItem = props => {
  const {
    elementSize = 100,
    isLoaded,
    guid,
    containerId,
    children
  } = props;
  const [isVisible, setIsVisible] = useState(!!isLoaded);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const onIntersect = useCallback(entries => {
    const [entry] = entries;
    if (entry.isIntersecting && !isVisible) {
      setIsVisible(true);
    }
  }, [isVisible]);
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, options);
    const element = containerRef.current;
    if (element) {
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [onIntersect]);
  const onDataLoaded = () => {
    if (!isDataLoaded) dispatch(pushLazyLoadedItem({
      guid,
      containerId
    }));
    setIsDataLoaded(true);
  };
  return _jsx(Wrapper, {
    ref: containerRef,
    elementSize: elementSize,
    isContentLoaded: isDataLoaded,
    children: _jsx(_Fragment, {
      children: isVisible && children(onDataLoaded)
    })
  });
};