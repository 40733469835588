import { isValidPhoneFormat } from 'presentationUtils/phone';
import { ConfirmFlowAdapter, InvalidFlowAdapter } from './adapters';
import { useUpdateUserPhone } from './hooks/useUpdateUserPhone';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ConfirmPhoneEventContainer = _ref => {
  let {
    onClose,
    onNext
  } = _ref;
  const {
    phone,
    isFetching,
    onUpdate
  } = useUpdateUserPhone({
    onSuccess: onNext,
    onClose
  });
  const isValidPhone = isValidPhoneFormat(phone);
  return isValidPhone ? _jsx(ConfirmFlowAdapter, {
    phone: phone,
    isFetching: isFetching,
    onSubmit: onUpdate,
    onCancel: onNext
  }) : _jsx(InvalidFlowAdapter, {
    phone: phone,
    isFetching: isFetching,
    onSubmit: onUpdate
  });
};