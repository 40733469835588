import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import BookingOfferDetailsContainer from 'presentation/features/offer/booking/details/container';
import { EBookingOfferDetailsTab } from 'presentation/features/offer/booking/details/types';
import ScreenLayout from 'presentation/layouts/screen';
import { ERenderSource } from 'presentation/types';
import { useLocation, useParams } from 'react-router';
import { EBookingUrlParam } from '../../features/offer/booking/types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferScreen = () => {
  const location = useLocation();
  const from = location.state?.from ?? ERenderSource.Any;
  const {
    id
  } = useParams();
  const tab = new URLSearchParams(location.search).get(EBookingUrlParam.Tab) ?? EBookingOfferDetailsTab.Services;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(BookingOfferDetailsContainer, {
        guid: guid,
        id: id,
        tab: tab,
        from: from
      })
    })
  });
};
export default BookingOfferScreen;