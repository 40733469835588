import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import { useAlphabet } from 'presentation/features/partnerDesk/filter/alphabetical/hooks';
const usePartnerDesksPageableArgsInRedux = guid => {
  const {
    localeAlphabet
  } = useAlphabet();
  return usePageableArgsInRedux({
    guid,
    defaultState: {
      query: null,
      name: localeAlphabet ?? null,
      page: 1,
      pageSize: 100
    }
  });
};
export default usePartnerDesksPageableArgsInRedux;