import { ECorpOfferPromotionType, EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferClaimContext from '../../provider/useCorpOfferClaimContext';
import { CorpOfferClaimCertificateActivation } from './certificate';
import { CorpOfferClaimExtCertificateActivation } from './externalCertificate';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimActivationAdapter = () => {
  const {
    corpOffer,
    activation,
    onActivate
  } = useCorpOfferClaimContext();
  const {
    openCertificateDialog
  } = useCorpOfferClaimDialogs();
  const {
    isActivationAvailable,
    isUserBalanceNotEnough,
    activationIsFetching,
    isReactivationSupported
  } = activation;
  const isCodesEnded = (corpOffer?.notUsedOfferCount ?? 0) === 0;
  return useMemo(() => {
    if (corpOffer && activation.lastActivation?.status !== EOfferActivationStatus.Pending) {
      switch (corpOffer.promotionType) {
        case ECorpOfferPromotionType.CorpCertificate:
          {
            return isActivationAvailable && (corpOffer?.status === EOfferStatus.Active || corpOffer?.status === EOfferStatus.Upcoming) && !isCodesEnded && (!activation.lastActivation || activation?.lastActivation?.status === EOfferActivationStatus.Rejected && isReactivationSupported) ? _jsx(CorpOfferClaimCertificateActivation, {
              disabled: activationIsFetching || corpOffer?.status === EOfferStatus.Upcoming || isUserBalanceNotEnough,
              isFetching: activationIsFetching,
              onBuy: openCertificateDialog
            }) : null;
          }
        case ECorpOfferPromotionType.ExternalCorpCertificate:
          {
            return isActivationAvailable && (corpOffer?.status === EOfferStatus.Active || corpOffer?.status === EOfferStatus.Upcoming) && !isCodesEnded && (!activation.lastActivation || activation.isReactivationSupported) ? _jsx(CorpOfferClaimExtCertificateActivation, {
              disabled: !corpOffer.externalActivationLink || isUserBalanceNotEnough || corpOffer?.status === EOfferStatus.Upcoming,
              url: corpOffer.externalActivationLink,
              onActivate: onActivate
            }) : null;
          }
      }
    } else {
      return null;
    }
  }, [corpOffer, activation.lastActivation, activation.isReactivationSupported, isActivationAvailable, isCodesEnded, isReactivationSupported, activationIsFetching, isUserBalanceNotEnough, openCertificateDialog, onActivate]);
};