import { Fab, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { OptionItemWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Option = _ref => {
  let {
    icon,
    text,
    onClick
  } = _ref;
  return _jsx(_Fragment, {
    children: _jsxs(OptionItemWrapper, {
      children: [_jsx(Fab, {
        color: "secondaryLight",
        size: "medium",
        onClick: onClick,
        variant: "circular",
        children: icon
      }), _jsx(Splitter, {
        size: 1
      }), _jsx(Typography, {
        textAlign: "center",
        variant: "caption",
        children: text
      })]
    })
  });
};
export default Option;