import Splitter from 'presentation/components/common/splitter';
import { ContentWrapper, SearchWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskDetailsFilterLayout = props => {
  const {
    search,
    loader,
    children
  } = props;
  return _jsxs(Wrapper, {
    children: [_jsx(SearchWrapper, {
      children: search
    }), _jsx(Splitter, {
      size: 3
    }), _jsxs(ContentWrapper, {
      children: [children, loader]
    })]
  });
};
export default PartnerDeskDetailsFilterLayout;