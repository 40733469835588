import store from 'data/store/store';
import { EOfferType } from 'domain/model/enums';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getOffersListQueryParams } from '../../../utils';
import { getProductOffersSearchRoute } from '../../entry';
import { productOffersDefaultParams } from '../../utils';
import { productOfferListSelector } from '../store/selectors';
import { productOffersClearFavoritesChangeProcess, productOffersSetArgs, productOffersSetFilter, productOffersSetIsNewFetching, productOffersSetIsNewSearchFetching, productOffersSetPageSize, productOffersSetSort, productOffersStartSession } from '../store/slice';
const emptyParams = productOffersDefaultParams;
export const useProductOfferList = props => {
  const {
    name,
    categoryId,
    guid
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const getCurrentState = useCallback(() => {
    return productOfferListSelector(store.getState());
  }, []);
  const onChangeFilter = useCallback(filter => {
    dispatch(productOffersSetFilter(filter));
  }, [dispatch]);
  const onChangePageSize = useCallback(newPageSize => {
    dispatch(productOffersSetIsNewFetching(false));
    dispatch(productOffersSetIsNewSearchFetching(false));
    dispatch(productOffersSetPageSize(newPageSize));
  }, [dispatch]);
  const onChangeSort = useCallback(value => {
    dispatch(productOffersSetIsNewFetching(false));
    dispatch(productOffersSetIsNewSearchFetching(false));
    dispatch(productOffersSetSort(value));
  }, [dispatch]);
  const onChangeCategory = useCallback(id => {
    if (typeof id === 'string') {
      dispatch(productOffersSetIsNewFetching(true));
      dispatch(productOffersSetIsNewSearchFetching(false));
      history.push(getProductOffersSearchRoute({
        categoryId: id
      }));
    }
  }, [dispatch, history]);
  const onChangeSearchCategory = useCallback(category => {
    dispatch(productOffersSetIsNewFetching(true));
    dispatch(productOffersSetIsNewSearchFetching(false));
    history.push(getProductOffersSearchRoute({
      name,
      categoryId: category.id,
      guid
    }));
  }, [dispatch, history, name, guid]);
  useEffect(() => {
    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentName = state.common.search?.name;
    const currentCategory = state.common.search?.categories?.[0];
    dispatch(productOffersStartSession(guid));
    dispatch(productOffersClearFavoritesChangeProcess());
    const isGuidChanged = currentGuid !== guid;
    const isNameChanged = (currentName || null) !== (name || null);
    const isCategoryChanged = (currentCategory || null) !== (categoryId || null);
    let args = state.common;
    if (isGuidChanged) {
      args = emptyParams;
    }
    dispatch(productOffersSetArgs(getOffersListQueryParams({
      name,
      categories: categoryId ? [categoryId] : null,
      sort: args.sort,
      page: args.page,
      pageSize: args.pageSize
    }, EOfferType.Product)));
    if (isGuidChanged || isCategoryChanged) {
      dispatch(productOffersSetIsNewFetching(true));
    } else {
      dispatch(productOffersSetIsNewFetching(false));
    }
    if (isGuidChanged || isNameChanged) {
      dispatch(productOffersSetIsNewSearchFetching(true));
    } else {
      dispatch(productOffersSetIsNewSearchFetching(false));
    }
  }, [dispatch, guid, name, categoryId, getCurrentState]);
  return {
    onChangePageSize,
    onChangeSort,
    onChangeFilter,
    onChangeCategory,
    onChangeSearchCategory
  };
};