import { getPartnerDeskDetailsRoute } from '../../../partnerDesk/entry';
import { EPartnerDeskDetailsTab } from '../../../partnerDesk/details/utils';
import { useHistory } from 'react-router';
import { useWebAnalytics } from '../../../webAnalytics';
import TradeOfferClaimProviders from './providers';
import TradeOfferClaim from './component';
import { useAuth } from '../../../auth/provider/useAuth';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferClaimContainer = props => {
  const {
    tradeOffer,
    partnerDesk,
    guid
  } = props;
  const {
    isAuthenticated
  } = useAuth();
  const history = useHistory();
  const {
    webAnalytics
  } = useWebAnalytics();
  if (!tradeOffer) {
    return null;
  }
  const onPartnerButtonClick = () => {
    if (tradeOffer?.partnerId) {
      webAnalytics.openPartnerUrl(tradeOffer?.partnerId);
      webAnalytics.offerJumpToPartnerSite(tradeOffer.id);
    }
  };
  const onShowAllOffers = () => {
    if (partnerDesk) {
      history.push(getPartnerDeskDetailsRoute({
        id: partnerDesk.id,
        tab: EPartnerDeskDetailsTab.TradeOffers
      }));
    }
  };
  return _jsx(TradeOfferClaimProviders, {
    tradeOffer: tradeOffer,
    onShowAllOffers: onShowAllOffers,
    isActivationAllowed: isAuthenticated,
    onPartnerButtonClick: onPartnerButtonClick,
    guid: guid,
    children: _jsx(TradeOfferClaim, {})
  });
};
export default TradeOfferClaimContainer;