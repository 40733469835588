import { Typography } from '@mui/material';
import { ArrowRightIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../../components/common/icon';
import { ShowMoreWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CategorySelectShowMore = _ref => {
  let {
    expanded,
    onExpandOrCollapse
  } = _ref;
  return _jsxs(ShowMoreWrapper, {
    expanded: expanded,
    onClick: onExpandOrCollapse,
    children: [_jsx(Typography, {
      color: "secondary",
      children: expanded ? 'Свернуть' : 'Показать всё'
    }), _jsx(AppSvgIcon, {
      icon: ArrowRightIcon,
      color: "secondary"
    })]
  });
};
export default CategorySelectShowMore;