import { ConfirmCityEventActions } from './actions';
import { ConfirmCityEventTitle } from './title';
import { confirmCityEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: confirmCityEventType,
    title: ConfirmCityEventTitle,
    actions: ConfirmCityEventActions,
    mergeWithNext: true,
    mergeWithPrev: false,
    payload
  };
};