import { SupportIcon } from 'presentation/media/icons';
import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useState } from 'react';
import Option from './option';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Icon = _jsx(SupportIcon, {});
const SupportOption = () => {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  return _jsxs(_Fragment, {
    children: [_jsx(Option, {
      icon: Icon,
      text: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443",
      onClick: () => setFeedbackVisible(true)
    }), _jsx(AboutFeedbackContainer, {
      open: feedbackVisible,
      onClose: () => setFeedbackVisible(false)
    })]
  });
};
export default SupportOption;