import axios from 'axios';
import { getBaseEndpoint } from '../openApi/utils';
export const axiosBaseQuery = () => async (_ref, _ref2) => {
  let {
    baseURL,
    url,
    method,
    params,
    data,
    headers,
    transformResponse,
    signal: originalSignal
  } = _ref;
  let {
    signal
  } = _ref2;
  const baseUrl = baseURL ?? getBaseEndpoint();
  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      params,
      data,
      headers,
      transformResponse,
      signal: originalSignal ?? signal
    });
    return {
      data: result
    };
  } catch (axiosError) {
    const err = axiosError;
    // TODO(@Protopopov Ruslan): мб добавить return ошибки через самодельный класс AxiosException
    // чтобы на макро уровне обработки ошибок делать instanceof?
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data,
        request: {
          responseURL: err.request?.responseURL
        }
      }
    };
  }
};