import { StyledButton } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferOutOfStockCartButton = props => {
  const {
    label,
    ...others
  } = props;
  return _jsx(StyledButton, {
    color: 'secondary',
    fullWidth: false,
    ...others,
    children: label
  });
};
export default ProductOfferOutOfStockCartButton;