import { getTradeOfferCodeAssignDurationInSeconds } from 'presentation/features/offer/trade/utils';
import Countdown from '../common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TillDateCountdown = props => {
  const {
    date,
    active,
    stop
  } = props;
  const duration = getTradeOfferCodeAssignDurationInSeconds(date);
  return _jsx(Countdown, {
    seconds: duration,
    active: active,
    stop: stop
  });
};
export default TillDateCountdown;