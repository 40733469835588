import { useGetProductOfferListQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import ProductOfferCard from 'presentation/features/offer/product/components/card';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useContextHandlers } from './hooks/useContextHandlers';
import { productOffersSetPage } from './store/slice';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ProductOfferListDataContainer = props => {
  const {
    args,
    filterValues,
    isMoreColumnsDislayed,
    isFilterStubVisible
  } = props;
  const {
    page,
    pageSize
  } = args;
  const dispatch = useDispatch();
  const {
    onChangePageSize
  } = useContextHandlers();

  /*const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: onAddOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
    },
  });*/

  const {
    data: productQueryData,
    isFetching: isProductOffersFetching,
    error: productOffersFetchError
  } = useGetProductOfferListQuery({
    ...args,
    ...filterValues
  }, {
    refetchOnMountOrArgChange: true
  });
  const productOffers = productQueryData?.data;
  const totalCount = productQueryData?.totalCount ?? 0;
  useEffect(() => {
    if (productOffersFetchError) {
      ErrorHandler.handleHttpError(productOffersFetchError);
    }
  }, [productOffersFetchError]);
  const onLoadMore = useCallback(() => {
    dispatch(productOffersSetPage(page + 1));
  }, [dispatch, page]);
  return _jsxs(_Fragment, {
    children: [productOffers && totalCount !== 0 && _jsx(CardPageableList, {
      totalCount: totalCount,
      isFetching: isProductOffersFetching,
      smSpan: 6,
      span: isMoreColumnsDislayed ? 3 : 4,
      pageSize: pageSize,
      onChangePageSize: onChangePageSize,
      onLoadMore: onLoadMore,
      children: productOffers?.map(product => _jsx(ProductOfferCard, {
        product: product
        /*favorite={{
          showAlways: isLgDown,
          active: isFavorite(EOfferType.Product, product),
          inProgress: isAddToFavoritesFetching(product.id),
          onClick: () => onAddOrRemoveFavorite(EOfferType.Product, product),
        }}*/
      }, product.id))
    }), productOffers && totalCount === 0 && isFilterStubVisible && _jsx("div", {
      children: "toto"
    }) /*todo <ProductFilterStub />*/]
  });
};
export default ProductOfferListDataContainer;