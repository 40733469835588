import { useEffect, useState } from 'react';
export const useHeaderParams = function () {
  let {
    allHeaders = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    allHeaders: true
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const [resizeWatcher, setResizeWatcher] = useState(Symbol());
  const header = document.getElementsByTagName('header');
  useEffect(() => {
    if (!header) {
      return;
    }
    const header0OffsetHeight = header?.[0]?.offsetHeight ?? 0;
    const header1OffsetHeight = header?.[1]?.offsetHeight ?? 0;
    const header2OffsetHeight = header?.[2]?.offsetHeight ?? 0;
    if (allHeaders) {
      const height = header0OffsetHeight + header1OffsetHeight + header2OffsetHeight;
      setHeaderHeight(height);
    } else {
      setHeaderHeight(header0OffsetHeight);
    }
  }, [header, allHeaders, resizeWatcher]);
  useEffect(() => {
    const listener = () => setResizeWatcher(Symbol());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
  return {
    headerHeight
  };
};