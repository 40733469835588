import { Container } from '@mui/material';
import { ContentWrapper } from './controls';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
const BannersMainTopLayout = props => {
  const {
    loader,
    children
  } = props;
  return _jsx(Container, {
    children: _jsxs(ContentWrapper, {
      children: [children, loader]
    })
  });
};
export default BannersMainTopLayout;