import { BackgroundWrapper, Close, Inner } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BackgroundContainer = _ref => {
  let {
    children,
    close,
    variant = 'inner'
  } = _ref;
  return _jsxs(BackgroundWrapper, {
    variant: variant,
    children: [_jsx(Inner, {
      children: children
    }), close && _jsx(Close, {
      children: close
    })]
  });
};
export default BackgroundContainer;