import { createSlice } from '@reduxjs/toolkit';
import { EAssistantScenarioStatus } from '../types';
const slice = createSlice({
  name: 'provide',
  initialState: {
    open: false,
    scenarios: [],
    activeScenario: null
  },
  reducers: {
    assistantOpen: state => {
      state.open = true;
    },
    assistantClose: state => {
      state.open = false;
    },
    assistantToggle: state => {
      state.open = !state.open;
    },
    assistantSetScenarios: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.scenarios = payload.map(item => ({
        ...item,
        status: EAssistantScenarioStatus.Pending,
        changeTime: null
      }));
    },
    assistantActivateScenario: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        type,
        changeTime
      } = payload;
      const scenario = state.scenarios.find(s => s.type === type) ?? null;
      if (scenario) {
        scenario.status = EAssistantScenarioStatus.Ready;
        scenario.changeTime = changeTime;
      }
    },
    assistantStartScenario: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      state.activeScenario = state.scenarios.find(s => s.type === payload) ?? null;
    },
    assistantFinishScenario: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      state.activeScenario = null;
      const index = state.scenarios.findIndex(s => s.type === payload);
      if (index !== -1) {
        state.scenarios.splice(index, 1);
      }
      if (!state.scenarios.length) {
        state.open = false;
      }
    },
    assistantReadyScenariosUpTime: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      state.scenarios.forEach(s => {
        if (s.status === EAssistantScenarioStatus.Ready) {
          s.changeTime = payload;
        }
      });
    }
  }
});
export const {
  assistantClose,
  assistantOpen,
  assistantToggle,
  assistantSetScenarios,
  assistantActivateScenario,
  assistantStartScenario,
  assistantFinishScenario,
  assistantReadyScenariosUpTime
} = slice.actions;
export default slice.reducer;