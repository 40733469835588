import FilterBadge from 'presentation/components/common/badges/filter';
import { IconButton } from 'presentation/features/offer/product/filter/button/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductFilterButton = _ref => {
  let {
    active,
    onClick
  } = _ref;
  return _jsx(IconButton, {
    size: "medium",
    onClick: onClick,
    children: _jsx(FilterBadge, {
      active: active
    })
  });
};
export default ProductFilterButton;