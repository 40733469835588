import { EPaymentType } from 'domain/model/enums';
import { PaymentIcon } from 'presentation/components/common/paymentIcon';
import { toCurrency, toRubCurrency } from 'presentation/utils/currency';
import { OriginalPriceText, PriceText, SidebarPriceWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferClaimPriceInfo = _ref => {
  let {
    paymentType,
    price,
    originalPrice
  } = _ref;
  let func = toCurrency;
  switch (paymentType) {
    case EPaymentType.AspRzdRub:
    case EPaymentType.Rub:
      func = toRubCurrency;
      break;
    case EPaymentType.Free:
    case EPaymentType.BenefitRzdPoint:
      func = toCurrency;
      break;
  }
  return _jsxs(SidebarPriceWrapper, {
    children: [_jsx(PaymentIcon, {
      type: paymentType
    }), _jsx(PriceText, {
      children: func(price)
    }), originalPrice !== undefined && originalPrice !== null && originalPrice > price && _jsx(OriginalPriceText, {
      variant: "body1",
      color: "textSecondary",
      children: func(originalPrice)
    })]
  });
};
export default OfferClaimPriceInfo;