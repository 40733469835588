import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsProductOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskProductOffersAdapter from './adapters/data';
import PartnerDeskDetailsTabProductOffersNoData from './noData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskOffersTabLayout;
export const PartnerDeskDetailsTabProductOffers = props => {
  const {
    guid
  } = props;
  return _jsx(Layout, {
    stub: _jsx(PartnerDeskDetailsTabProductOffersNoData, {}),
    loader: _jsx(GeneralLoaderFixedAdapter, {
      selector: partnerDeskDetailsProductOffersIsFetchingSelector
    }),
    children: _jsx(PartnerDeskProductOffersAdapter, {
      guid: guid
    })
  });
};