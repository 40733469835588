import { CorpOfferReceivedActivationEventActions } from './actions';
import { CorpOfferReceivedActivationEventContent } from './content';
import { CorpOfferReceivedActivationEventTitle } from './title';
import { corpOfferReceivedActivationEventType } from './types';
export * from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: corpOfferReceivedActivationEventType,
    title: CorpOfferReceivedActivationEventTitle,
    content: CorpOfferReceivedActivationEventContent,
    actions: CorpOfferReceivedActivationEventActions,
    mergeWithNext: false,
    mergeWithPrev: false,
    payload
  };
};