import { useMediaQuery, useTheme } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import useScrollToSections from 'presentation/features/about/hooks/useScrollToSections';
import { useMemo, useState } from 'react';
import { useHeaderParams } from '../../hooks/useHeaderParams';
import BackgroundContainer from '../backgroundContainer';
import { EParentGridAreas } from '../bookingDetail/types';
import { Content, FitContainer, ParentLayoutGridArea, Sidebar, StickyTitle, Text, Wrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutSectionsLayout = props => {
  const {
    title,
    description,
    tabs,
    children,
    close = null
  } = props;
  const theme = useTheme();
  const {
    headerHeight
  } = useHeaderParams();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [stickyTitleRef] = useState(null);
  const scrollToSection = useScrollToSections({
    topOffset: stickyTitleRef?.offsetHeight ?? 0
  });
  const top = useMemo(() => {
    return headerHeight + +theme.spacing(isMobile ? 4 : 2).replace(/\D/g, '');
  }, [headerHeight, theme, isMobile]);
  const {
    current,
    sectionsRefs,
    setCurrentSection,
    scrollTo
  } = scrollToSection;
  const onChangeTab = tab => {
    setCurrentSection(tab);
    const currentEl = sectionsRefs.current.find(s => tab.id === s.id);
    scrollTo(currentEl?.element);
  };
  return _jsx(FitContainer, {
    disableGutters: isMobile,
    children: _jsx(ParentLayoutGridArea, {
      area: EParentGridAreas.Inner,
      children: _jsx(BackgroundContainer, {
        close: close,
        variant: "main",
        children: _jsxs(Wrapper, {
          children: [_jsxs(Content, {
            children: [_jsx(StickyTitle, {
              variant: "h1",
              top: headerHeight,
              children: title
            }), _jsx(Splitter, {
              size: isMobile ? theme.spacing(4) : 2
            }), description && _jsxs(_Fragment, {
              children: [_jsx(Splitter, {
                size: isMobile ? 1 : 3
              }), _jsx(Text, {
                children: description
              })]
            }), children({
              ...scrollToSection,
              isCollapsable: isMobile
            })]
          }), _jsx(Sidebar, {
            top: top,
            children: !isMobile && tabs && _jsx(MPGrid, {
              item: true,
              sm: 4,
              children: tabs(current, onChangeTab)
            })
          })]
        })
      })
    })
  });
};
export default AboutSectionsLayout;