import { EBalanceType } from 'domain/model';
import { BalanceTabs } from '../../../components/balanceTabs/controls';
import { MPTab } from 'theme/ui-kit/tab';
import { useAuthUser } from '../../../../auth/provider/useAuthUser';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { getUserFinancesRoute } from '../../../entry';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BalanceTabsAdapter = props => {
  const {
    tab
  } = props;
  const {
    isCorpUser
  } = useAuthUser();
  const history = useHistory();
  const onChangeTab = useCallback(newTab => {
    history.push(getUserFinancesRoute({
      type: newTab
    }));
  }, [history]);
  return _jsx(BalanceTabs, {
    value: tab,
    onChange: (event, tab) => onChangeTab(tab),
    children: isCorpUser && _jsx(MPTab, {
      label: "\u0411\u043E\u043D\u0443\u0441\u043D\u044B\u0439 \u043F\u0430\u043A\u0435\u0442",
      value: EBalanceType.Bonuses
    })
  });
};