import { useState } from 'react';
import { useAuthUser } from '../../provider/useAuthUser';
import { ChangeEmailDialog } from '../changeEmailDialog';
import { useUserProfileEmailEdit } from '../hooks/useEmailEdit';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const changeEmailLabel = 'Новая электронная почта';
export const ChangeEmailAdapter = _ref => {
  let {
    onClose,
    onNext
  } = _ref;
  const {
    validation,
    isFetching,
    onUpdate,
    setValidation
  } = useUserProfileEmailEdit();
  const {
    user
  } = useAuthUser();
  const email = user?.email ?? '';
  const [value, setValue] = useState(() => email);
  const setValuelInternal = newValue => {
    setValue(newValue);
    setValidation(null);
  };
  const updateEmailInternal = () => {
    onUpdate(value).then(result => {
      if (result) {
        onNext();
      }
    });
  };
  return _jsx(ChangeEmailDialog, {
    value: value,
    validation: validation?.email,
    label: changeEmailLabel,
    primaryText: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C",
    isFetching: isFetching,
    onChange: setValuelInternal,
    onClose: onClose,
    onSave: updateEmailInternal
  });
};