import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { EAppCategories } from 'presentation/types';
import OffersCategoryHeader from '../../../components/categoryHeader';
import OffersCommonHeader from '../../../components/commonHeader';
import OffersSearchHeader from '../../../components/searchHeader';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useTradeOffersHeader from '../hooks/useHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TradeOffersHeaderAdapter = () => {
  const {
    x3
  } = useGridSpacing();
  const handlers = useContextHandlers();
  const {
    isNewSearchFetching,
    isSearchHeaderVisible,
    isCommonHeaderVisible,
    isCategoryHeaderVisible,
    isAnyHeaderVisible,
    selectedCategory,
    categoriesOut,
    initialTotalCount
  } = useTradeOffersHeader();
  if (!isAnyHeaderVisible || isNewSearchFetching) {
    return null;
  }
  return _jsxs("div", {
    children: [isSearchHeaderVisible && _jsx(OffersSearchHeader, {
      category: selectedCategory,
      count: initialTotalCount,
      categories: categoriesOut,
      onChangeCategory: handlers.onChangeCategory
    }), isCategoryHeaderVisible && selectedCategory && _jsx(OffersCategoryHeader, {
      category: selectedCategory,
      onChangeCategory: handlers.onReturnToTopCategory
    }), isCommonHeaderVisible && _jsx(OffersCommonHeader, {
      icon: _jsx(PartitionIcon, {
        fontSize: "large",
        type: EAppCategories.TradeOffers
      }),
      children: EAppCategories.TradeOffers
    }), _jsx(Splitter, {
      size: x3
    })]
  });
};