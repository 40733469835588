import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPConfirmDialog } from '../../../../../../../theme/ui-kit/dialog';
import { ListCounter, NumberedList } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CertificateDialog = _ref => {
  let {
    open,
    onClose
  } = _ref;
  const {
    x2
  } = useGridSpacing();
  return _jsx(MPConfirmDialog, {
    open: open,
    maxWidth: "sm",
    title: "\u041A\u0430\u043A \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442",
    onClose: onClose,
    buttons: _jsx(MPButton, {
      size: "large",
      fullWidth: true,
      color: "brand",
      variant: "contained",
      onClick: onClose,
      children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E"
    }),
    children: _jsxs(NumberedList, {
      item: true,
      container: true,
      direction: "column",
      spacing: x2,
      children: [_jsxs(MPGrid, {
        item: true,
        children: [_jsx(ListCounter, {
          color: "textSecondary",
          children: "1."
        }), "\u041D\u0430\xA0\u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u043D\u0430 \u0432\u0441\u044F \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043F\u043E\xA0\u043F\u0440\u0438\u043E\u0431\u0440\u0435\u0442\u0451\u043D\u043D\u043E\u043C\u0443 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044E;"]
      }), _jsxs(MPGrid, {
        item: true,
        children: [_jsx(ListCounter, {
          color: "textSecondary",
          children: "2."
        }), "\u0421\u043A\u0430\u0447\u0430\u0439\u0442\u0435 \u0438\xA0\u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u0435 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u0432\xA0\u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u043C \u0432\u0438\u0434\u0435 \u0438\u043B\u0438 \u0440\u0430\u0441\u043F\u0435\u0447\u0430\u0442\u0430\u0439\u0442\u0435 \u0435\u0433\u043E;"]
      }), _jsxs(MPGrid, {
        item: true,
        children: [_jsx(ListCounter, {
          color: "textSecondary",
          children: "3."
        }), "\u0412\xA0\u0434\u0435\u043D\u044C, \u043A\u043E\u0433\u0434\u0430 \u0443\xA0\u0432\u0430\u0441 \u0437\u0430\u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u043E \u0441\u043E\u0431\u044B\u0442\u0438\u0435, \u0432\u043E\u0437\u044C\u043C\u0438\u0442\u0435 \u0440\u0430\u0441\u043F\u0435\u0447\u0430\u0442\u0430\u043D\u043D\u044B\u0439 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u0438\u043B\u0438 \u0435\u0433\u043E \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443\u044E \u043A\u043E\u043F\u0438\u044E \u0441\xA0\u0441\u043E\u0431\u043E\u0439 \u0438\xA0\u043F\u0440\u0435\u0434\u044A\u044F\u0432\u0438\u0442\u0435 \u0435\u0433\u043E \u043D\u0430\xA0\u043C\u0435\u0441\u0442\u0435."]
      })]
    })
  });
};
export default CertificateDialog;