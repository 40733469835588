import { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash.uniqby';
import { isDefined } from 'utils/array';
import BookingOfferDetailsTabServices from '../../components/detailsTabs';
import { bookingOfferDetailsCartSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const mapServices = _ref => {
  let {
    category
  } = _ref;
  if (!category) {
    return null;
  }
  const {
    id,
    name
  } = category;
  return {
    id,
    name
  };
};
const BookingOfferServicesTabsAdapter = _ref2 => {
  let {
    id
  } = _ref2;
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
  const cart = useSelector(bookingOfferDetailsCartSelector);
  const {
    onChangeServicesSelection
  } = useContextHandlers();
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const serviceCategories = uniqBy(bookingOffer?.services?.map(mapServices).filter(isDefined) ?? [], 'id');
  const activeServices = selectedServiceCategory ? bookingOffer?.services?.filter(_ref3 => {
    let {
      category
    } = _ref3;
    return category?.id === selectedServiceCategory;
  }) : bookingOffer?.services;
  return _jsx(BookingOfferDetailsTabServices, {
    services: activeServices,
    serviceCategories: serviceCategories,
    cart: cart?.[id] ?? null,
    selectedId: selectedServiceCategory,
    onSelect: setSelectedServiceCategory,
    onChangeServices: onChangeServicesSelection
  });
};
export default BookingOfferServicesTabsAdapter;