import { createSlice } from '@reduxjs/toolkit';
import { EOfferListType } from 'domain/model/enums';
import { corpOffersDefaultParams } from '../../utils';
const emptyParams = corpOffersDefaultParams;
const slice = createSlice({
  name: 'list',
  initialState: {
    guid: null,
    common: emptyParams,
    upcoming: emptyParams,
    isNewFetching: true,
    isNewSearchFetching: true
  },
  reducers: {
    corpOffersStartSession: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        guid
      } = payload;
      state.guid = guid;
    },
    corpOffersSetPageSize: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        type,
        pageSize
      } = payload;
      state[type].pageSize = pageSize;
    },
    corpOffersSetSort: (state, _ref3) => {
      let {
        payload
      } = _ref3;
      const {
        type,
        sort
      } = payload;
      state[type].sort = sort;
    },
    corpOffersSetArgs: (state, _ref4) => {
      let {
        payload
      } = _ref4;
      const {
        type,
        args
      } = payload;
      state[type] = args;
    },
    corpOffersSetPage: (state, _ref5) => {
      let {
        payload
      } = _ref5;
      const {
        type,
        page
      } = payload;
      state[type].page = page;
    },
    corpOffersSetIsNewFetching: (state, _ref6) => {
      let {
        payload
      } = _ref6;
      state.isNewFetching = payload;
    },
    corpOffersSetIsNewSearchFetching: (state, _ref7) => {
      let {
        payload
      } = _ref7;
      state.isNewSearchFetching = payload;
    }
  }
});
export const {
  corpOffersSetPageSize,
  corpOffersSetPage,
  corpOffersStartSession,
  corpOffersSetSort,
  corpOffersSetArgs,
  corpOffersSetIsNewFetching,
  corpOffersSetIsNewSearchFetching
} = slice.actions;
export default slice.reducer;