import { EOfferActivateError, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';
import { AspOfferClaimBalanceNotEnoughWarnMessage } from './balanceNotEnough';
import { AspOfferClaimErrorMessage } from './error';
import { AspOfferClaimPendingInfoMessage } from './pendingInfo';
import { AspOfferClaimPendingWaitingMessage } from './pendingWaiting';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AspOfferClaimCommonMessagesAdapter = () => {
  const {
    aspOffer,
    isActivationReceivedNow,
    activation
  } = useAspOfferClaimContext();
  const {
    isUserBalanceNotEnough,
    isLastActivationPending
  } = activation;
  return useMemo(() => {
    if (!aspOffer) {
      return null;
    } else {
      return _jsxs(_Fragment, {
        children: [activation.activationError && activation.activationError.type !== EOfferActivateError.InvalidUserStatus && _jsx(AspOfferClaimErrorMessage, {
          error: activation.activationError
        }), (aspOffer?.status === EOfferStatus.Active || isActivationReceivedNow) && isUserBalanceNotEnough && !isLastActivationPending && _jsx(AspOfferClaimBalanceNotEnoughWarnMessage, {
          paymentType: aspOffer.paymentType
        }), isLastActivationPending && _jsxs(_Fragment, {
          children: [_jsx(AspOfferClaimPendingWaitingMessage, {}), _jsx(AspOfferClaimPendingInfoMessage, {})]
        })]
      });
    }
  }, [aspOffer, activation.activationError, isActivationReceivedNow, isUserBalanceNotEnough, isLastActivationPending]);
};