import { createSlice } from '@reduxjs/toolkit';
const maxLifetime = 10000;
const maxCount = 5;
const removeOldNotices = notices => notices.filter(notice => notice.timestamp && notice.timestamp > new Date().getTime() - maxLifetime);
const sortPredicate = (n1, n2) => {
  if (n1.timestamp && n2.timestamp) {
    return n1.timestamp - n2.timestamp;
  }
  return 0;
};
const slice = createSlice({
  name: 'notifier',
  initialState: [],
  reducers: {
    addNotice(state, _ref) {
      let {
        payload
      } = _ref;
      const notices = removeOldNotices(state);
      const {
        status,
        text
      } = payload;
      if (!notices.some(n => n.status === status && n.text === text)) {
        if (notices.length >= maxCount) notices.splice(0, 1);
        notices.push({
          status,
          text,
          timestamp: new Date().getTime()
        });
      }
      state.length = 0;
      state.push(...notices.sort(sortPredicate));
    },
    closeNotice(state, _ref2) {
      let {
        payload
      } = _ref2;
      const {
        status,
        text,
        timestamp
      } = payload;
      const index = state.findIndex(n => n.status === status && n.text === text && n.timestamp === timestamp);
      if (index >= 0) {
        state.splice(index, 1);
      }
    }
  }
});
export const {
  addNotice,
  closeNotice
} = slice.actions;
export default slice.reducer;