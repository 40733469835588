import PartnerDeskDetailsAboutLayout from './layout';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskDetailsAboutLayout;
export const PartnerDeskDetailsTabAboutCompany = _ref => {
  let {
    about
  } = _ref;
  return _jsx(Layout, {
    children: about
  });
};