import { EBannerPartition } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BannersMainTopContainer from '../../../../banners/mainTop/container';
import { productOfferListSearchIsEmptySelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferListBannersAdapter = _ref => {
  let {
    guid
  } = _ref;
  const isEmptySearch = useSelector(productOfferListSearchIsEmptySelector);
  return useMemo(() => isEmptySearch ? _jsx(BannersMainTopContainer, {
    guid: guid,
    partition: EBannerPartition.ProductOffers
  }) : null, [guid, isEmptySearch]);
};
export default ProductOfferListBannersAdapter;