import { useMediaQuery } from '@mui/material';
import { CmsTradeOfferCollection } from '../tradeOffers';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * адаптер для коллекции ТП, открываемой в составе конструктора
 */
export const CmsTradeOfferCollectionSelfAdapter = props => {
  const {
    webAnalytics
  } = useWebAnalytics();
  const isBetweenMdLg = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
  const pageSize = isBetweenMdLg ? 3 : 4;
  const visibleCount = pageSize;
  const statuses = null;
  const onShowCard = useCallback(offer => webAnalytics.offerShow(offer.id), [webAnalytics]);
  return _jsx(CmsTradeOfferCollection, {
    ...props,
    statuses: statuses,
    visibleCount: visibleCount,
    onLoadMore: null,
    onShowCard: onShowCard
  });
};