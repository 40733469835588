import React from 'react';
import { ArrowDownIcon } from '../../../media/icons';
import { MPMenuItem } from '../menu/item';
import { MPSelectStyled } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPSelect = props => {
  const {
    fullWidth,
    size,
    label,
    helperText,
    onChange,
    error,
    className,
    children,
    ...selectProps
  } = props;
  return _jsx(MPSelectStyled, {
    label: label,
    size: size,
    error: error,
    helperText: helperText,
    fullWidth: fullWidth,
    value: selectProps.value,
    select: true,
    className: className,
    SelectProps: {
      IconComponent: ArrowDownIcon,
      ...selectProps,
      onChange: (event, node) => onChange(event.target.value, node)
    },
    children: children
  });
};
MPSelect.Item = MPMenuItem;