import { AnalyticsInitializer } from '@mp-npm/mp-analytics-client';
import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useWebAnalytics } from '../hooks/useWebAnalytics';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WebAnalyticsInitializer = () => {
  const {
    webAnalytics
  } = useWebAnalytics();
  const history = useHistory();
  const adapters = useRef(webAnalytics.getAdapters(history));
  return useMemo(() => _jsx(AnalyticsInitializer, {
    adapters: adapters.current
  }), []);
};