import { SettingsIcon } from 'presentation/media/icons';
import { UserMenuItem } from '../../../components/menuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemSettings = _ref => {
  let {
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438",
    icon: _jsx(SettingsIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};