import { Typography } from '@mui/material';
import AppImageLazy from 'presentation/components/common/images/lazy';
import { NoImageIcon } from 'presentation/media/icons';
import AppImage from '../common';
import { CoverImage, Image, ImageStubContent, LogoImage } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CoverImageView = props => {
  const {
    id,
    border = true,
    topRounded = true,
    bottomRounded = true,
    thumb = false,
    lazy,
    fit = 'cover',
    children
  } = props;
  const ImageComponent = lazy ? AppImageLazy : AppImage;
  return _jsxs(CoverImage, {
    border: border,
    topRounded: topRounded,
    bottomRounded: bottomRounded,
    thumb: thumb,
    fit: fit,
    children: [id ? _jsx(ImageComponent, {
      src: id,
      alt: ""
    }) : _jsxs(ImageStubContent, {
      children: [_jsx(NoImageIcon, {}), _jsx(Typography, {
        children: "\u0424\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044F \u0432\u0440\u0435\u043C\u0435\u043D\u043D\u043E \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442"
      })]
    }), children]
  });
};
export const LogoImageView = props => {
  const {
    id,
    stub
  } = props;
  return _jsxs(LogoImage, {
    children: [id && _jsx(Image, {
      src: id,
      alt: ""
    }), !id && stub && _jsx("img", {
      src: stub,
      alt: ""
    })]
  });
};