import { useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DetailSwiperWrapper, Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HorizontalScroller = props => {
  const {
    initialSelected,
    gap,
    gapSpacing,
    children
  } = props;
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (initialSelected) {
      swiper?.slideTo(initialSelected, 0);
    }
  }, [initialSelected, swiper]);
  return _jsx(Wrapper, {
    children: _jsx(DetailSwiperWrapper, {
      gap: gap,
      gapSpacing: gapSpacing,
      children: _jsx(Swiper, {
        freeMode: true,
        modules: [FreeMode],
        slidesPerView: "auto",
        runCallbacksOnInit: true,
        onSwiper: setSwiper,
        children: children
      })
    })
  });
};
export const HorizontalScrollerItem = SwiperSlide;
export default HorizontalScroller;