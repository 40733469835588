import { ECmsBannerLinkObjectType, ECmsCollectionLinkObjectType, EOfferType } from '../../../../domain/model/enums';
import { ECatalogTab } from '../../../features/catalog/types';
import { BagIcon, CertificateIcon, CorpOfferIcon, SeeAndSunIcon, TradeOfferIcon } from '../../../media/icons';
import { EAppCategories } from '../../../types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getPartitionIcon = type => {
  switch (type) {
    case EOfferType.Trade:
    case EAppCategories.TradeOffers:
    case ECmsCollectionLinkObjectType.TradeOffer:
    case ECmsBannerLinkObjectType.TradeOffer:
    case ECmsBannerLinkObjectType.TradeOfferCategory:
    case ECatalogTab.TradeOffers:
      return TradeOfferIcon;
    case EOfferType.Corp:
    case EAppCategories.CorpOffers:
    case ECmsCollectionLinkObjectType.CorpOffer:
    case ECmsBannerLinkObjectType.CorpOffer:
    case ECmsBannerLinkObjectType.CorpOfferCategory:
    case ECatalogTab.CorpOffers:
      return CorpOfferIcon;
    case EOfferType.Booking:
    case EAppCategories.BookingOffers:
    case ECatalogTab.BookingOffers:
      return SeeAndSunIcon;
    case EOfferType.Product:
    case EAppCategories.ProductOffers:
    case ECatalogTab.ProductOffers:
      return BagIcon;
    case EOfferType.Asp:
    case EAppCategories.AspOffers:
    case ECatalogTab.AspOffers:
      return CertificateIcon;
    case EAppCategories.TradeAndAspOffers:
    case ECmsCollectionLinkObjectType.Partner:
    case ECmsCollectionLinkObjectType.Video:
    case ECmsCollectionLinkObjectType.ProductOfferCategory:
    case ECmsCollectionLinkObjectType.CorpOfferCategory:
    case ECmsCollectionLinkObjectType.TradeOfferCategory:
    case ECmsCollectionLinkObjectType.BookingOffer:
    case ECmsCollectionLinkObjectType.ProductOffer:
    case ECmsBannerLinkObjectType.Partner:
    case ECmsBannerLinkObjectType.Collection:
    case ECmsBannerLinkObjectType.Link:
    case ECmsBannerLinkObjectType.BookingOffer:
    case ECmsBannerLinkObjectType.Video:
    case ECmsBannerLinkObjectType.ProductOfferCategory:
    case ECmsBannerLinkObjectType.ProductOffer:
      return null;
  }
};
export const PartitionIcon = _ref => {
  let {
    type,
    ...others
  } = _ref;
  const Component = getPartitionIcon(type);
  return Component ? _jsx(Component, {
    ...others
  }) : null;
};