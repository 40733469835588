import { useGetCmsPartnerDesksCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import PartnerDeskCard from 'presentation/features/partnerDesk/components/card';
import { PartnerGrid } from 'presentation/features/partnerDesk/components/grid';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { CmsPartnersCollectionShowAllAdapter } from './adapters/partnersShowAll';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CmsPartnerCollection = _ref => {
  let {
    guid,
    id,
    header,
    footer,
    layout: Layout,
    onDataLoaded
  } = _ref;
  const history = useHistory();
  const {
    data,
    error,
    isFetching
  } = useGetCmsPartnerDesksCollectionQuery({
    guid,
    id
  });
  const onPartnerClick = id => {
    history.push(getPartnerDeskDetailsRoute({
      id
    }));
  };
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  useEffect(() => {
    if (data?.length && !isFetching) onDataLoaded?.();
  }, [data, isFetching, onDataLoaded]);
  const Footer = footer;
  const hasContent = data && data.length > 0;
  if (!data?.length) return null;
  const dataOut = data;
  return _jsx(Layout, {
    header: hasContent && header,
    showAll: hasContent && _jsx(CmsPartnersCollectionShowAllAdapter, {}),
    footer: Footer && _jsx(Footer, {
      hasContent: hasContent
    }),
    children: _jsx(PartnerGrid, {
      children: dataOut?.map(partnerDesk => partnerDesk.partner ? _jsx(PartnerDeskCard, {
        title: partnerDesk.partner.name,
        image: partnerDesk.partner.logo,
        onClick: () => onPartnerClick(partnerDesk.id)
      }, partnerDesk.id) : null)
    })
  });
};