import { ProductAttributeType } from 'domain/model/productOffer';
export const mergedAttributeSeparator = '/';

/**
 * атрибут принадлежит группе
 * **/

/**
 * атрибуты которые принадлежит группе или не принадлежит
 * **/

/**
 * варианты товара со сгруппированными атрибутами (при наличии group.id), иначе атрибуты остаются как есть
 * **/

// получение варианта с атрибутами, обогащенными данными из категорий, здесь также задается порядок атрибутов
export const getVariantsWithExtendedAttributes = variants => {
  // чистим от вариантов без атрибутов
  const onlyWithAttributesVariants = variants.filter(v => v.attributes?.length);

  // вернет все атрибуты категорий для товара, с values
  const extendedVariants = onlyWithAttributesVariants.map(v => {
    const extendedByCategoryAttributes = v.category.attributes.map(ca => {
      const variantAttribute = v?.attributes?.find(va => {
        return va.attribute.id === ca.attribute.id;
      });
      if (variantAttribute) {
        return {
          ...ca,
          values: variantAttribute.values
        };
      }
      return {
        ...ca,
        values: []
      };
    });
    return {
      product: v,
      attributes: extendedByCategoryAttributes
    };
  });
  return extendedVariants;
};
export function isGroupedAttributeGuard(attribute) {
  return attribute.group !== undefined;
}

// обернет все атрибуты в группу, если у них есть group.id, если нет то оставит атрибут как есть (group.id === null)
export const getVariantsWithGroupedAttributes = variants => {
  return variants.map(v => {
    const groupedAttributes = v.attributes.reduce((acc, va) => {
      const prevAttributeSameCurrentGroupId = acc.findIndex(a => {
        if (isGroupedAttributeGuard(a)) {
          return a.group?.id === va.attribute?.group?.id;
        }
        if (!isGroupedAttributeGuard(a)) {
          return a.attribute.group?.id === va.attribute?.group?.id;
        }
      });

      // не найден атрибут с таким group.id или group отсутствует
      if (prevAttributeSameCurrentGroupId === -1) {
        const hasGroupId = !!va.attribute?.group?.id;
        if (hasGroupId) {
          return [...acc, {
            group: va.attribute.group,
            attributes: [va]
          }];
        }
        return [...acc, va];
      }

      // если найденный атрибут является группой
      if (isGroupedAttributeGuard(acc[prevAttributeSameCurrentGroupId])) {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentGroupId === aIndex) {
            return {
              ...aItem,
              group: va.attribute.group,
              attributes: [...aItem.attributes, va]
            };
          }
          return aItem;
        });
        return updatedAcc;
      } else {
        const updatedAcc = acc.map((aItem, aIndex) => {
          if (prevAttributeSameCurrentGroupId === aIndex) {
            return {
              ...aItem,
              group: va.attribute.group,
              attributes: [aItem, va]
            };
          }
          return aItem;
        });
        return updatedAcc;
      }
    }, []);
    return {
      product: v.product,
      groupedAttributes: groupedAttributes
    };
  });
};
function computeMergedValues(mainAttrValues, otherAttrValues) {
  const result = otherAttrValues.reduce((acc, oav) => {
    const res = [{
      dictionaryValue: mainAttrValues?.[0]?.dictionaryValue ? mainAttrValues[0].dictionaryValue : null,
      value: acc && acc[0]?.value ? acc[0]?.value + (oav?.[0]?.value ? `${mergedAttributeSeparator}${oav?.[0]?.value}` : '') : ''
    }];
    return res;
  }, mainAttrValues);
  return result;
}
export const getVariantsWithMergedByGroupAttributes = variants => {
  const withMergedAttributesVariants = variants.map(v => {
    // фильтруем от типов файл, чтобы не объединять name с ним
    const withoutFileTypeAttributes = v.groupedAttributes.map(vga => {
      if (isGroupedAttributeGuard(vga)) {
        return {
          ...vga,
          attributes: vga.attributes.filter(vgaf => vgaf.attribute.type !== ProductAttributeType.file)
        };
      }
      return vga;
    });

    /* извлекаем атрибуты из группы атрибутов файлов, id группа нам не нужна, иначе возникнет коллизия с одинаковыми id (мы используем group.id для примитивов mergedAttributes) */
    const fileTypeGroupedAndExtendedAttributes = v.groupedAttributes.map(vga => {
      if (isGroupedAttributeGuard(vga)) {
        return {
          ...vga,
          attributes: vga.attributes.filter(vgaf => vgaf.attribute.type === ProductAttributeType.file)
        };
      }
      return vga;
    });
    const fileAttributes = fileTypeGroupedAndExtendedAttributes.flatMap(fta => {
      if (isGroupedAttributeGuard(fta)) {
        return fta.attributes;
      } else {
        return fta;
      }
    }).filter(f => f.attribute.type === ProductAttributeType.file);
    /**/

    const primitiveMergedAttributes = withoutFileTypeAttributes.map(ga => {
      if (isGroupedAttributeGuard(ga)) {
        // берем ведущий required аттрибут либо первый если required аттрибуты отсутствуют
        const mainAttribute = ga.attributes.find(a => a.required) ?? ga.attributes[0];

        // фильтруем оставщиеся атрибуты от ведущего и типов файл
        const withoutMainAndFileAttributes = ga.attributes.filter(a => a.attribute.id !== mainAttribute.attribute.id);

        /** производный атрибут, имеющийся attribute.id будет перезаписан на group.id   **/
        const mergedAttribute = {
          ...mainAttribute,
          attribute: {
            ...mainAttribute.attribute,
            id: mainAttribute.attribute.group.id,
            name: ga?.group?.value ?? mainAttribute.attribute.name /*+ withoutMainAndFileAttributes.reduce((acc, wma) => acc + `/${wma.attribute.name}`, '')*/,
            description: mainAttribute.attribute.description + withoutMainAndFileAttributes.reduce((acc, wma) => acc + `/${wma.attribute.description}`, '')
          },
          values: computeMergedValues(mainAttribute.values, withoutMainAndFileAttributes.map(wma => wma.values))
        };
        return mergedAttribute;
      } else {
        return ga;
      }
    });
    const mergedAttributes = [...primitiveMergedAttributes];
    return {
      // id атрибута перезаписано значением из group.id
      mergedAttributes,
      // id атрибута НЕ перезаписано значением из group.id
      fileAttributes,
      groupedAttributes: v.groupedAttributes,
      product: v.product
    };
  });
  return withMergedAttributesVariants;
};
const makeMergedAttributes = variants => {
  const extended = getVariantsWithExtendedAttributes(variants);
  const grouped = getVariantsWithGroupedAttributes(extended);
  return getVariantsWithMergedByGroupAttributes(grouped);
};
export default makeMergedAttributes;