import { EUserRole } from 'domain/model/enums';
import ForbiddenComponent from 'presentation/components/common/forbidden';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import CmsPreviewContainer from 'presentation/features/cms/preview/container';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { useGlobalPreviewMode } from 'presentation/screen/cms/hooks/previewMode/useGlobalPreviewMode';
import { useCmsContextSearchParams } from './hooks/useCmsSearchParams';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsPreviewScreen = () => {
  const {
    roles
  } = useAuthUser();
  const canView = roles.some(r => [EUserRole.AdminMp, EUserRole.AdminPartner].includes(r));
  const context = useCmsContextSearchParams();
  useGlobalPreviewMode();
  if (!canView) {
    return _jsx(ForbiddenComponent, {});
  }
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      children: _jsx(CmsPreviewContainer, {
        guid: guid,
        context: context
      })
    })
  });
};
export default CmsPreviewScreen;