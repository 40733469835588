import { outlinedInputClasses, inputBaseClasses } from '@mui/material';
const select = baseTheme => ({
  MuiSelect: {
    defaultProps: {
      variant: 'outlined'
    },
    styleOverrides: {
      icon: {
        color: baseTheme.palette.secondary.main
      },
      select: {
        [`&.${inputBaseClasses.input}.${outlinedInputClasses.input}`]: {
          background: baseTheme.palette.white.main,
          padding: `${baseTheme.spacing(0.75)} ${baseTheme.spacing(4.5)} ${baseTheme.spacing(0.75)} ${baseTheme.spacing(1.25)}`
        },
        [`&.${inputBaseClasses.input}.${outlinedInputClasses.input}[aria-expanded="true"]`]: {
          background: baseTheme.palette.secondary.A600
        }
      },
      iconOutlined: {
        top: 'auto'
      }
    }
  }
});
export default select;