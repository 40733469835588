import { createSlice } from '@reduxjs/toolkit';
const limit = 100;
const slice = createSlice({
  name: 'historyExtensions',
  initialState: {
    prevLocation: null,
    collector: []
  },
  reducers: {
    historyExtensionsInit: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.prevLocation = payload;
      state.collector = [payload];
    },
    historyExtensionsReset: state => {
      state.prevLocation = null;
      state.collector = [];
    },
    historyExtensionsCollect: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        action,
        location
      } = payload;
      const internalLocation = {
        action,
        location
      };
      switch (action) {
        case 'POP':
          {
            // это возврат Назад, поэтому удаляем из истории то, через что перепрыгнули Назад
            const fromIndex = state.collector.findIndex(l => l.location.key === location.key);
            if (fromIndex !== -1) {
              state.collector.splice(fromIndex + 1);
            }
            if (state.collector.length > 1) {
              state.prevLocation = state.collector[state.collector.length - 2];
            } else {
              state.prevLocation = null;
            }
            break;
          }
        case 'REPLACE':
          {
            // это замена текущей локации, поэтому перезаписываем последнюю локацию
            state.collector.splice(state.collector.length - 1, 1);
            state.collector.push(internalLocation);
            break;
          }
        case 'PUSH':
          {
            // это переход, поэтому добавляем локацию
            state.prevLocation = state.collector[state.collector.length - 1];
            state.collector.push(internalLocation);
            break;
          }
      }
      if (state.collector.length > limit) {
        state.collector = state.collector.slice(1);
      }
    }
  }
});
export const {
  historyExtensionsInit,
  historyExtensionsReset,
  historyExtensionsCollect
} = slice.actions;
export default slice.reducer;