import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OfferClaimSubscriptionEnabled = _ref => {
  let {
    isFetching,
    onUnSubscribe
  } = _ref;
  const [hover, setHover] = useState(false);
  const setDefaultState = () => setHover(false);
  const setHoverState = () => setHover(true);
  const text = hover || isFetching ? 'Отписаться от уведомления' : 'Уведомление придёт на эл. почту';
  return _jsxs(MPButton, {
    disabled: isFetching,
    fullWidth: true,
    variant: "contained",
    color: "success",
    size: "large",
    onClick: onUnSubscribe,
    onFocus: setHoverState,
    onMouseEnter: setHoverState,
    onTouchStart: setHoverState,
    onTouchEnd: setDefaultState,
    onMouseLeave: setDefaultState,
    onBlur: setDefaultState,
    children: [_jsx(Typography, {
      variant: "body1",
      children: text
    }), isFetching && _jsx(ContentLoader, {
      size: 15
    })]
  });
};
export default OfferClaimSubscriptionEnabled;