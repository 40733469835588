import { Fade, useMediaQuery } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { useRef } from 'react';
import OfferListLayoutAsideBehavior from './asideBehavior';
import { AnyContentWrapper, AsideWrapper, BannersWrapper, BreadcrumbsWrapper, ContentWrapper, ControlsWrapper, ControlsWrapperDense, HeaderWrapper } from './controls';
import useVisibleSectionLinks from '../../features/sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OfferListLayout = props => {
  const {
    toolbar,
    breadcrumb,
    banners,
    header,
    stub,
    aside,
    controls,
    children,
    loader
  } = props;
  const pageRef = useRef(null);
  const contentRef = useRef(null);
  const asideRef = useRef(null);
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  useVisibleSectionLinks();
  return _jsxs("div", {
    ref: pageRef,
    children: [toolbar && _jsx(ScreenNavWrapper, {
      children: toolbar
    }), banners && _jsx(BannersWrapper, {
      children: banners
    }), _jsx(Fade, {
      in: true,
      children: _jsxs(DefaultContentWrapper, {
        children: [breadcrumb && _jsx(BreadcrumbsWrapper, {
          children: breadcrumb
        }), header && _jsx(_Fragment, {
          children: isMdUp && controls ? _jsxs(HeaderWrapper, {
            children: [header, _jsx(ControlsWrapperDense, {
              children: controls
            })]
          }) : _jsx(_Fragment, {
            children: header
          })
        }), _jsxs(ContentWrapper, {
          children: [_jsx(AsideWrapper, {
            children: _jsx("div", {
              ref: asideRef,
              children: aside
            })
          }), !isMdUp && controls && _jsx(ControlsWrapper, {
            children: controls
          }), _jsxs(AnyContentWrapper, {
            ref: contentRef,
            children: [children, stub]
          })]
        }), loader]
      })
    }), isMdUp && _jsx(OfferListLayoutAsideBehavior, {
      page: pageRef,
      content: contentRef,
      element: asideRef
    })]
  });
};
export default OfferListLayout;