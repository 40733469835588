import { selected } from './constants';
const list = baseTheme => ({
  MuiListItemButton: {
    styleOverrides: {
      root: {
        [`&${selected}`]: {
          backgroundColor: baseTheme.palette.secondary.A600
        },
        [`:hover, &${selected}:hover`]: {
          backgroundColor: baseTheme.palette.secondary.A600
        }
      }
    }
  }
});
export default list;