import { EOfferStatus } from 'domain/model/enums';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EBookingOfferServicesTag, EOrderServicesTag, EUserServicesTag } from './index';
const defaultBookingOfferValidStatuses = [EOfferStatus.Active, EOfferStatus.Upcoming];
export const bookingOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getBookingOfferCategories: builder.query({
      transformResponse: response => {
        // Возвращает только категории 1 уровня
        return Array.isArray(response.data) ? categoriesBuildTree(response.data.filter(_ref => {
          let {
            parentId
          } = _ref;
          return !parentId;
        })) : [];
      },
      query: () => {
        return apiDefinition.user.booking.categories({});
      }
    }),
    getBookingOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref2 => {
        let {
          name,
          onlyLeafCategories
        } = _ref2;
        return apiDefinition.user.booking.categoriesUsed({
          query: name,
          statuses: defaultBookingOfferValidStatuses,
          onlyLeafCategories
        });
      }
    }),
    getBookingOfferList: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          search,
          page,
          pageSize,
          ...bookingProps
        } = _ref3;
        const {
          name,
          categories,
          partnerId
        } = search;
        return apiDefinition.user.booking.all({
          page: 1,
          pageSize: page * pageSize,
          query: name,
          categories,
          partnerId,
          ...bookingProps
        });
      },
      providesTags: [EBookingOfferServicesTag.List]
    }),
    getBookingOffersCount: builder.query({
      transformResponse: response => {
        return response.data[0]?.count ?? 0;
      },
      query: _ref4 => {
        let {
          search
        } = _ref4;
        return apiDefinition.user.booking.count({
          query: search?.name,
          minPrice: null,
          maxPrice: null
        });
      }
    }),
    getBookingOfferDetails: builder.query({
      query: id => apiDefinition.user.booking.one(id),
      transformResponse: response => response.data,
      providesTags: (result, error, id) => [{
        type: EBookingOfferServicesTag.Details,
        id
      }, EBookingOfferServicesTag.Details]
    }),
    createOrder: builder.mutation({
      query: order => apiDefinition.user.booking.createOrder(order),
      transformResponse: response => response.data[0],
      invalidatesTags: [EUserServicesTag.OrdersCount, EOrderServicesTag.Orders]
    })
  })
});
export const {
  useGetBookingOfferListQuery,
  useGetBookingOffersCountQuery,
  useGetBookingOfferCategoriesQuery,
  useGetBookingOfferCategoriesUsedQuery,
  useGetBookingOfferDetailsQuery,
  useCreateOrderMutation
} = bookingOfferApi;