import { createSlice } from '@reduxjs/toolkit';
import { aspOffersDefaultParams } from '../../utils';
const emptyParams = aspOffersDefaultParams;
const slice = createSlice({
  name: 'relatedList',
  initialState: {
    args: emptyParams
  },
  reducers: {
    aspRelatedOffersSetArgs: (state, _ref) => {
      let {
        payload
      } = _ref;
      state.args = payload;
    }
  }
});
export const {
  aspRelatedOffersSetArgs
} = slice.actions;
export default slice.reducer;