import useAspOfferCategoriesData from './useAspOfferCategoriesData';
import useAspOfferDetailsData from './useAspOfferDetailsData';
import useAspOfferPartnerDeskData from './useAspOfferPartnerDeskData';
const useAspOfferDetailsLoadingStatus = _ref => {
  let {
    id
  } = _ref;
  const {
    isCategoriesLoading
  } = useAspOfferCategoriesData();
  const {
    isAspOfferLoading
  } = useAspOfferDetailsData(id);
  const {
    isPartnerDeskLoading
  } = useAspOfferPartnerDeskData(id);
  return isCategoriesLoading || isAspOfferLoading || isPartnerDeskLoading;
};
export default useAspOfferDetailsLoadingStatus;