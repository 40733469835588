import { Typography } from '@mui/material';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import PartnerDeskLogo from '../logo';
import { LogoWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskHeader = _ref => {
  let {
    partnerDesk
  } = _ref;
  const {
    x2
  } = useGridSpacing();
  return _jsxs(MPGrid, {
    container: true,
    spacing: x2,
    alignItems: "center",
    children: [_jsx(LogoWrapper, {
      item: true,
      children: _jsx(PartnerDeskLogo, {
        partnerDesk: partnerDesk
      })
    }), _jsx(MPGrid, {
      item: true,
      zero: true,
      children: _jsx(Typography, {
        variant: "h1",
        children: partnerDesk.partner?.name
      })
    })]
  });
};
export default PartnerDeskHeader;