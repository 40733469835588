import { createSelector } from '@reduxjs/toolkit';
import { partnerApi } from 'data/api/partner';
export const partnerDeskDetailsGuidSelector = store => store.partnerDesk.details.guid;
export const partnerDeskDetailsStateSelector = store => store.partnerDesk.details;

// trade offers

export const partnerDeskDetailsTradeOffersSortSelector = store => store.partnerDesk.details.tradeOffers.common?.sort ?? null;
export const partnerDeskDetailsTradeOffersCommonArgsSelector = store => store.partnerDesk.details.tradeOffers.common;
export const partnerDeskDetailsTradeOffersUpcomingArgsSelector = store => store.partnerDesk.details.tradeOffers.upcoming;
export const partnerDeskDetailsTradeOffersCommonCountSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsTradeOffersUpcomingCountSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsTradeOffersCountSelector = createSelector(partnerDeskDetailsTradeOffersCommonCountSelector, partnerDeskDetailsTradeOffersUpcomingCountSelector, (commonCount, upcomingCount) => commonCount + upcomingCount);
export const partnerDeskDetailsTradeOffersCommonIsErrorSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsTradeOffersUpcomingIsErrorSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsTradeOffersIsErrorSelector = createSelector(partnerDeskDetailsTradeOffersCommonIsErrorSelector, partnerDeskDetailsTradeOffersUpcomingIsErrorSelector, (commonIsError, upcomingIsError) => commonIsError || upcomingIsError);
export const partnerDeskDetailsTradeOffersCommonIsFetchingSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.common;
  return args ? partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsTradeOffersUpcomingIsFetchingSelector = store => {
  const args = store.partnerDesk.details.tradeOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerTradeOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsTradeOffersIsFetchingSelector = createSelector(partnerDeskDetailsTradeOffersCommonIsFetchingSelector, partnerDeskDetailsTradeOffersUpcomingIsFetchingSelector, (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching);
export const partnerDeskDetailsTradeOffersIsNewFetchingSelector = createSelector(partnerDeskDetailsTradeOffersIsFetchingSelector, store => store.partnerDesk.details.tradeOffers.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);

// asp offers

export const partnerDeskDetailsAspOffersSortSelector = store => store.partnerDesk.details.aspOffers.common?.sort ?? null;
export const partnerDeskDetailsAspOffersCommonArgsSelector = store => store.partnerDesk.details.aspOffers.common;
export const partnerDeskDetailsAspOffersUpcomingArgsSelector = store => store.partnerDesk.details.aspOffers.upcoming;
export const partnerDeskDetailsAspOffersCommonCountSelector = store => {
  const args = store.partnerDesk.details.aspOffers.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsAspOffersUpcomingCountSelector = store => {
  const args = store.partnerDesk.details.aspOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsAspOffersCountSelector = createSelector(partnerDeskDetailsAspOffersCommonCountSelector, partnerDeskDetailsAspOffersUpcomingCountSelector, (commonCount, upcomingCount) => commonCount + upcomingCount);
export const partnerDeskDetailsAspOffersCommonIsErrorSelector = store => {
  const args = store.partnerDesk.details.aspOffers.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsAspOffersUpcomingIsErrorSelector = store => {
  const args = store.partnerDesk.details.aspOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerAspOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsAspOffersIsErrorSelector = createSelector(partnerDeskDetailsAspOffersCommonIsErrorSelector, partnerDeskDetailsAspOffersUpcomingIsErrorSelector, (commonIsError, upcomingIsError) => commonIsError || upcomingIsError);
export const partnerDeskDetailsAspOffersCommonIsFetchingSelector = store => {
  const args = store.partnerDesk.details.aspOffers.common;
  return args ? partnerApi.endpoints.getPartnerAspOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsAspOffersUpcomingIsFetchingSelector = store => {
  const args = store.partnerDesk.details.aspOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerAspOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsAspOffersIsFetchingSelector = createSelector(partnerDeskDetailsAspOffersCommonIsFetchingSelector, partnerDeskDetailsAspOffersUpcomingIsFetchingSelector, (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching);
export const partnerDeskDetailsAspOffersIsNewFetchingSelector = createSelector(partnerDeskDetailsAspOffersIsFetchingSelector, store => store.partnerDesk.details.aspOffers.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);

// corp offers

export const partnerDeskDetailsCorpOffersSortSelector = store => store.partnerDesk.details.corpOffers.common?.sort ?? null;
export const partnerDeskDetailsCorpOffersCommonArgsSelector = store => store.partnerDesk.details.corpOffers.common;
export const partnerDeskDetailsCorpOffersUpcomingArgsSelector = store => store.partnerDesk.details.corpOffers.upcoming;
export const partnerDeskDetailsCorpOffersCommonCountSelector = store => {
  const args = store.partnerDesk.details.corpOffers.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsCorpOffersUpcomingCountSelector = store => {
  const args = store.partnerDesk.details.corpOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsCorpOffersCountSelector = createSelector(partnerDeskDetailsCorpOffersCommonCountSelector, partnerDeskDetailsCorpOffersUpcomingCountSelector, (commonCount, upcomingCount) => commonCount + upcomingCount);
export const partnerDeskDetailsCorpOffersCommonIsErrorSelector = store => {
  const args = store.partnerDesk.details.corpOffers.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsCorpOffersUpcomingIsErrorSelector = store => {
  const args = store.partnerDesk.details.corpOffers.upcoming;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsCorpOffersIsErrorSelector = createSelector(partnerDeskDetailsCorpOffersCommonIsErrorSelector, partnerDeskDetailsCorpOffersUpcomingIsErrorSelector, (commonIsError, upcomingIsError) => commonIsError || upcomingIsError);
export const partnerDeskDetailsCorpOffersCommonIsFetchingSelector = store => {
  const args = store.partnerDesk.details.corpOffers.common;
  return args ? partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsCorpOffersUpcomingIsFetchingSelector = store => {
  const args = store.partnerDesk.details.corpOffers.upcoming;
  return args ? partnerApi.endpoints.getPartnerCorpOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsCorpOffersIsFetchingSelector = createSelector(partnerDeskDetailsCorpOffersCommonIsFetchingSelector, partnerDeskDetailsCorpOffersUpcomingIsFetchingSelector, (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching);
export const partnerDeskDetailsCorpOffersIsNewFetchingSelector = createSelector(partnerDeskDetailsCorpOffersIsFetchingSelector, store => store.partnerDesk.details.corpOffers.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);

// product offers

export const partnerDeskDetailsProductOffersArgsSelector = store => store.partnerDesk.details.productOffers.common;
export const partnerDeskDetailsProductOffersSortSelector = store => store.partnerDesk.details.productOffers.common.sort ?? null;
export const partnerDeskDetailsProductOffersCountSelector = store => {
  const args = store.partnerDesk.details.productOffers.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerProductOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsProductOffersIsErrorSelector = store => {
  const args = store.partnerDesk.details.productOffers.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerProductOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsProductOffersIsFetchingSelector = store => {
  const args = store.partnerDesk.details.productOffers.common;
  return args ? partnerApi.endpoints.getPartnerProductOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsProductOffersIsNewFetchingSelector = createSelector(partnerDeskDetailsProductOffersIsFetchingSelector, store => store.partnerDesk.details.productOffers.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);

// booking offers

export const partnerDeskDetailsBookingOffersSortSelector = store => store.partnerDesk.details.bookingOffers.list?.sort ?? null;
export const partnerDeskDetailsBookingOffersArgsSelector = store => store.partnerDesk.details.bookingOffers.list;
export const partnerDeskDetailsBookingOffersCountSelector = store => {
  const args = store.partnerDesk.details.bookingOffers.list;
  if (!args) {
    return 0;
  }
  const {
    data
  } = partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const partnerDeskDetailsBookingOffersIsErrorSelector = store => {
  const args = store.partnerDesk.details.bookingOffers.list;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store);
  return isError;
};
export const partnerDeskDetailsBookingOffersIsFetchingSelector = store => {
  const args = store.partnerDesk.details.bookingOffers.list;
  return args ? partnerApi.endpoints.getPartnerBookingOfferList.select(args)(store).isLoading : false;
};
export const partnerDeskDetailsBookingOffersIsNewFetchingSelector = createSelector(partnerDeskDetailsBookingOffersIsFetchingSelector, store => store.partnerDesk.details.bookingOffers.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);