import { useMediaQuery, useTheme } from '@mui/material';
const useGridSpacing = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  if (downSm) {
    return {
      x1: 1,
      x2: 1,
      x3: 2,
      x4: 2,
      x5: 3,
      x6: 3,
      x7: 3
    };
  }
  if (downLg) {
    return {
      x1: 1,
      x2: 2,
      x3: 2,
      x4: 3,
      x5: 4,
      x6: 3,
      x7: 7
    };
  }
  return {
    x1: 1,
    x2: 2,
    x3: 3,
    x4: 4,
    x5: 5,
    x6: 6,
    x7: 7
  };
};
export default useGridSpacing;