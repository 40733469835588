import ProductOfferShortCharacteristics from 'presentation/features/offer/product/components/short';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferShortCharacteristicsAdapter = props => {
  const {
    onShowAllCharacteristics,
    id
  } = props;
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  if (!productOffer?.otherAttributes) {
    return null;
  }
  return _jsx(ProductOfferShortCharacteristics, {
    onShowFullCharacteristics: onShowAllCharacteristics,
    text: productOffer.otherAttributes
  });
};
export default ProductOfferShortCharacteristicsAdapter;