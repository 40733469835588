import { LocationIcon } from 'presentation/media/icons';
import { useState } from 'react';
import { AddressHelper } from 'utils/address';
import { UserProfileEditAttributeLocality } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { useUserProfileLocalityEdit } from '../hooks/useLocalityEdit';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const label = 'Город';
export const UserProfileLocalityAdapter = _ref => {
  let {
    user
  } = _ref;
  const [editMode, setEditMode] = useState(false);
  const {
    value,
    validation,
    update,
    reset,
    onChange
  } = useUserProfileLocalityEdit({
    user
  });
  const showEdit = () => setEditMode(true);
  const hideEdit = () => {
    setEditMode(false);
    reset();
  };
  const updateInternal = () => {
    update();
    hideEdit();
  };
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: label,
      value: user.locality ? new AddressHelper(user.locality).getLocalitySimpleName() : '-',
      icon: _jsx(LocationIcon, {
        color: "secondary"
      }),
      onAction: showEdit
    }), editMode && _jsx(UserProfileEditAttributeLocality, {
      label: label,
      value: value,
      validation: validation?.locality,
      onChange: onChange,
      onClose: hideEdit,
      onSave: updateInternal
    })]
  });
};