import { CrownIcon } from 'presentation/media/icons';
import { UserMenuItem } from '../../../components/menuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemActivations = _ref => {
  let {
    count,
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: "\u041C\u043E\u0438 \u043F\u0440\u0438\u0432\u0438\u043B\u0435\u0433\u0438\u0438",
    count: count,
    icon: _jsx(CrownIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};