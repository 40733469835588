import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CurrentActivateErrrorPausedOfferWarnMessage = () => {
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0438. \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0435\u0449\u0451 \u0440\u0430\u0437 \u043F\u043E\u0437\u0436\u0435"
    })
  });
};