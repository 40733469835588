import { createSelector } from '@reduxjs/toolkit';
import { tradeOfferApi } from 'data/api/tradeOffer';
export const tradeOfferListGuidSelector = store => store.tradeOffer.list.guid;
export const tradeOfferListSelector = store => store.tradeOffer.list;
export const tradeOfferListCommonArgsSelector = store => store.tradeOffer.list.common;
export const tradeOfferListUpcomingArgsSelector = store => store.tradeOffer.list.upcoming;
export const tradeOfferListNameSelector = store => store.tradeOffer.list.common?.search?.name ?? null;
export const tradeOfferListSortSelector = store => store.tradeOffer.list.common?.sort ?? null;
export const tradeOfferListCategoryIdSelector = store => store.tradeOffer.list.common?.search?.categories?.[0] ?? null;
export const tradeOfferListSearchIsEmptySelector = store => !store.tradeOffer.list.common?.search?.name && !store.tradeOffer.list.common?.search?.categories?.length;
export const tradeOfferListSearchByNameIsEmptySelector = store => !store.tradeOffer.list.common?.search?.name;
export const tradeOfferListCommonCountSelector = store => {
  const args = store.tradeOffer.list.common;
  if (!args) {
    return 0;
  }
  const {
    data
  } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const tradeOfferListUpcomingCountSelector = store => {
  const args = store.tradeOffer.list.upcoming;
  if (!args) {
    return 0;
  }
  const {
    data
  } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return data?.data?.length ?? 0;
};
export const tradeOfferListCountSelector = createSelector(tradeOfferListCommonCountSelector, tradeOfferListUpcomingCountSelector, (commonCount, upcomingCount) => commonCount + upcomingCount);
export const tradeOfferListCommonIsErrorSelector = store => {
  const args = store.tradeOffer.list.common;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return isError;
};
export const tradeOfferListUpcomingIsErrorSelector = store => {
  const args = store.tradeOffer.list.upcoming;
  if (!args) {
    return 0;
  }
  const {
    isError
  } = tradeOfferApi.endpoints.getTradeOfferList.select(args)(store);
  return isError;
};
export const tradeOfferListIsErrorSelector = createSelector(tradeOfferListCommonIsErrorSelector, tradeOfferListUpcomingIsErrorSelector, (commonIsError, upcomingIsError) => commonIsError || upcomingIsError);
export const tradeOfferListCommonIsFetchingSelector = store => {
  const args = store.tradeOffer.list.common;
  return args ? tradeOfferApi.endpoints.getTradeOfferList.select(args)(store).isLoading : false;
};
export const tradeOfferListUpcomingIsFetchingSelector = store => {
  const args = store.tradeOffer.list.upcoming;
  return args ? tradeOfferApi.endpoints.getTradeOfferList.select(args)(store).isLoading : false;
};
export const tradeOfferListIsFetchingSelector = createSelector(tradeOfferListCommonIsFetchingSelector, tradeOfferListUpcomingIsFetchingSelector, (commonIsFetching, upcomingIsFetching) => commonIsFetching || upcomingIsFetching);
export const tradeOfferListIsNewFetchingSelector = createSelector(tradeOfferListIsFetchingSelector, store => store.tradeOffer.list.isNewFetching, (isFetching, isNewFetching) => isNewFetching && isFetching);
export const tradeOfferListIsNewSearchFetchingSelector = createSelector(tradeOfferListIsFetchingSelector, store => store.tradeOffer.list.isNewSearchFetching, (isFetching, isNewSearchFetching) => isNewSearchFetching && isFetching);
export const tradeOfferListNoDataSelector = createSelector(tradeOfferListCountSelector, tradeOfferListIsFetchingSelector, tradeOfferListIsErrorSelector, (count, isFetching, isError) => count === 0 && !isFetching && !isError);