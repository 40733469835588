import { Typography } from '@mui/material';
import { toCurrency } from '../../../../utils/currency';
import { InputNumber, RangeInputsWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const styles = {
  variant: 'body2',
  color: 'secondary'
};
const inputFromProps = {
  startAdornment: _jsx(Typography, {
    ...styles,
    children: "\u043E\u0442"
  }),
  endAdornment: _jsx(Typography, {
    ...styles,
    children: "\u20BD"
  })
};
const inputToProps = {
  startAdornment: _jsx(Typography, {
    ...styles,
    children: "\u0434\u043E"
  }),
  endAdornment: inputFromProps.endAdornment
};
export const PriceRange = props => {
  const {
    isDisabled,
    from,
    to,
    valueFrom,
    valueTo,
    onChange,
    onBlur
  } = props;
  const onChangeMinValue = event => {
    const inputValue = Number(event.target.value);
    if (from && inputValue >= from || !from) onChange(inputValue, valueTo);
  };
  const onChangeMaxValue = event => {
    const inputValue = Number(event.target.value);
    if (to && inputValue <= to || !to) onChange(valueFrom, inputValue);
  };
  const handlePressEnter = e => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };
  return _jsxs(RangeInputsWrapper, {
    children: [_jsx(InputNumber, {
      disabled: isDisabled,
      value: toCurrency(valueFrom),
      size: "small",
      InputProps: inputFromProps,
      placeholder: from ? `${toCurrency(from)}` : undefined,
      onChange: onChangeMinValue,
      onBlur: onBlur,
      onKeyUp: handlePressEnter
    }), _jsx(InputNumber, {
      disabled: isDisabled,
      value: toCurrency(valueTo),
      size: "small",
      InputProps: inputToProps,
      placeholder: to ? `${toCurrency(to)}` : undefined,
      onChange: onChangeMaxValue,
      onKeyUp: handlePressEnter,
      onBlur: onBlur
    })]
  });
};