import OfferPartnerInfo from 'presentation/features/offer/components/partnerInfo';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useHistory } from 'react-router';
import useCorpOfferDetailsData from '../hooks/useCorpOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferDetailsPartnerInfoAdapter = _ref => {
  let {
    id
  } = _ref;
  const history = useHistory();
  const {
    corpOffer
  } = useCorpOfferDetailsData(id);
  const onPartnerClick = () => {
    if (corpOffer) {
      history.push(getPartnerDeskDetailsRoute({
        id: corpOffer.partner.id,
        tab: EPartnerDeskDetailsTab.CorpOffers
      }));
    }
  };
  if (!corpOffer) {
    return null;
  }
  return _jsx(OfferPartnerInfo, {
    partner: corpOffer.partner,
    onClick: onPartnerClick
  });
};
export default CorpOfferDetailsPartnerInfoAdapter;