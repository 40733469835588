import { getAspOfferSearchResultsRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOfferSearchResultsRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOfferSearchResultsRoute } from 'presentation/features/offer/corp/routes';
import { getTradeOfferSearchResultsRoute } from 'presentation/features/offer/trade/routes';
import { ESearchUrlParam } from 'presentation/types';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import rootRouting from 'routing';
import SearchInput from '../components/input';
import { getSearchResultsRoute } from '../entry';
import { useSelector } from 'react-redux';
import { headerCatalogDialogVisibleSelector } from '../../header/store/selectors';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const searchSections = [{
  sectionPath: rootRouting.tradeOffer,
  getSectionSearchRoute: getTradeOfferSearchResultsRoute
}, {
  sectionPath: rootRouting.corpOffer,
  getSectionSearchRoute: getCorpOfferSearchResultsRoute
}, {
  sectionPath: rootRouting.bookingOffer,
  getSectionSearchRoute: getBookingOfferSearchResultsRoute
}, {
  sectionPath: rootRouting.aspOffer,
  getSectionSearchRoute: getAspOfferSearchResultsRoute
}];
const SearchControlContainer = props => {
  const {
    suggestions: SuggestionsAdapter
  } = props;
  const isCatalog = useSelector(headerCatalogDialogVisibleSelector);
  const matchedPath = useRouteMatch({
    path: searchSections.map(s => s.sectionPath)
  });
  const history = useHistory();
  const initialQuery = new URLSearchParams(history.location.search).get(ESearchUrlParam.Query);
  const inputRef = useRef();
  const [query, setQuery] = useState(initialQuery);
  const onChange = event => {
    setQuery(event.target.value ?? '');
  };
  const onSearch = () => {
    if (query) {
      if (matchedPath) {
        const sectionSearchRoute = searchSections.find(s => s.sectionPath === matchedPath.path);
        if (!sectionSearchRoute) {
          return;
        }
        history.push(sectionSearchRoute.getSectionSearchRoute({
          query
        }));
      } else {
        history.push(getSearchResultsRoute({
          query
        }));
      }
    }
  };
  useEffect(() => {
    if (isCatalog) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isCatalog]);
  return _jsxs(_Fragment, {
    children: [_jsx(SearchInput, {
      ref: inputRef,
      ...props,
      value: query ?? '',
      showIcon: true,
      onChange: onChange,
      onSubmit: onSearch
    }), SuggestionsAdapter && _jsx(SuggestionsAdapter, {
      query: query,
      anchorEl: inputRef.current
    })]
  });
};
export default SearchControlContainer;