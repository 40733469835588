import { Badge } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const OptionBadge = _ref => {
  let {
    badgeContent,
    showZero,
    children
  } = _ref;
  return _jsx(Badge, {
    overlap: "circular",
    color: "brand",
    showZero: showZero,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right'
    },
    badgeContent: badgeContent,
    children: children
  });
};
export default OptionBadge;