import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { aspOfferRelatedListIsEmptySelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOffersRelatedHeaderAdapter = () => {
  const isEmpty = useSelector(aspOfferRelatedListIsEmptySelector);
  return !isEmpty && _jsx(Typography, {
    variant: "h3",
    component: "h4",
    children: "\u041F\u043E\u0445\u043E\u0436\u0438\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F"
  });
};