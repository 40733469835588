import { LogoImageView } from 'presentation/components/common/images/cover';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';
import { InfoWrapper, LogoWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AspOfferListItemInfo = props => {
  const {
    partner
  } = props;
  return partner && _jsxs(InfoWrapper, {
    children: [_jsx(CardCaption, {
      variant: "body2",
      color: "textSecondary",
      children: partner.name
    }), partner.logo && _jsx(LogoWrapper, {
      children: _jsx(LogoImageView, {
        id: partner.logo.path ?? null
      })
    })]
  });
};
export default AspOfferListItemInfo;