import { useGetProductOfferCategoriesUsedQuery } from 'data/api/productOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';
const useProductOfferDetailsCategoriesData = id => {
  const {
    isFetching: categoriesIsFetching,
    error: categoriesFetchError,
    data: categories,
    isLoading
  } = useGetProductOfferCategoriesUsedQuery({
    onlyLeafCategories: false,
    collectionId: id
  });
  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
    }
  }, [categoriesFetchError]);
  return {
    categoriesIsFetching,
    categoriesFetchError,
    categories,
    isLoading
  };
};
export default useProductOfferDetailsCategoriesData;