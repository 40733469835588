import { Divider, Typography, useTheme } from '@mui/material';
import { EOrderDiscriminator, EOrderStatus } from 'domain/model/enums';
import Splitter from 'presentation/components/common/splitter';
import { getBookingOffersDetailsRoute } from 'presentation/features/offer/booking/routes';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { phoneFormatView } from 'presentation/utils/phone';
import { getPersonShortName } from '../../../../../../../utils';
import { toRubCurrency } from '../../../../../../../utils/currency';
import { EBookingOfferDetailsTab } from '../../../../../../offer/booking/details/types';
import { DetailsWrapper } from '../../../components/details/controls';
import OrderImage from '../../../components/image';
import OrderField from '../../../components/orderField';
import BookingOrderItem from '../../components/orderItem';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const notConfirmedStatus = [EOrderStatus.New, EOrderStatus.Given];
const BookingOfferOrderDetailsAdapter = _ref => {
  let {
    order
  } = _ref;
  const theme = useTheme();
  if (!order || order?.discriminator !== EOrderDiscriminator.BookingOrder) {
    return null;
  }
  const {
    customer,
    id,
    offer,
    orderItems,
    status
  } = order;
  const cost = toRubCurrency(order.cost);
  const isNotConfirmedStatus = notConfirmedStatus.includes(status);
  return _jsxs(DetailsWrapper, {
    children: [_jsx(Typography, {
      fontWeight: theme.typography.fontWeightBold,
      children: offer?.name ?? ''
    }), _jsx(Splitter, {
      size: 1.25
    }), offer?.images?.[0] && id && _jsxs(_Fragment, {
      children: [_jsx(OrderImage, {
        to: getBookingOffersDetailsRoute({
          id: offer?.id,
          tab: EBookingOfferDetailsTab.Description
        }),
        fileLink: offer?.images?.[0]
      }), _jsx(Splitter, {
        size: 1.25
      })]
    }), customer && _jsx(OrderField, {
      label: "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C",
      text: getPersonShortName(customer)
    }), customer?.phone && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 2
      }), _jsx(OrderField, {
        label: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
        text: phoneFormatView(customer.phone)
      })]
    }), _jsx(Splitter, {
      size: 3
    }), _jsx(Divider, {}), _jsx(Splitter, {
      size: 1
    }), orderItems?.map(item => _jsx(BookingOrderItem, {
      ...item
    }, item.id)), _jsx(Splitter, {
      size: 2
    }), _jsxs(MPGrid, {
      container: true,
      justifyContent: "space-between",
      alignItems: "center",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsxs(Typography, {
          variant: "h3",
          children: [isNotConfirmedStatus ? 'Предварительная сумма' : 'Сумма', ":"]
        })
      }), _jsx(MPGrid, {
        item: true,
        children: _jsx(Typography, {
          variant: "h3",
          children: cost
        })
      })]
    })]
  });
};
export default BookingOfferOrderDetailsAdapter;