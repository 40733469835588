import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { historyExtensionsGetLocationCollector, historyExtensionsGetPrevIndependentLocationSelector } from '../features/general/historyExtension/store/selectors';
function useHistoryExtensions() {
  const history = useHistory();
  const locationCollector = useSelector(historyExtensionsGetLocationCollector);
  const prevIndependentLocation = useSelector(historyExtensionsGetPrevIndependentLocationSelector);
  const gotoPrevLocation = useCallback(function (prevLocation, fallback) {
    let fallbackAction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'REPLACE';
    if (!prevLocation) {
      switch (fallbackAction) {
        case 'PUSH':
          history.push(fallback);
          break;
        case 'POP':
        case 'REPLACE':
          history.replace(fallback);
          break;
        default:
          history.push(fallback);
          console.error('Fallback action must be value in [PUSH, REPLACE, POP]');
      }
      return;
    }
    let prevLocationIndex = -1;
    locationCollector.forEach((l, index) => {
      if (typeof prevLocation === 'string') {
        if (l.location.pathname === prevLocation) {
          prevLocationIndex = index;
        }
      } else {
        if (l.location.key === prevLocation.key && l.location.pathname === prevLocation.pathname) {
          prevLocationIndex = index;
        }
      }
    });
    if (prevLocationIndex !== -1) {
      const backSteps = prevLocationIndex - (locationCollector.length - 1);
      if (history.length > Math.abs(backSteps)) {
        history.go(backSteps);
      } else {
        history.replace(fallback);
      }
    } else {
      switch (fallbackAction) {
        case 'PUSH':
          history.push(fallback);
          break;
        case 'POP':
        case 'REPLACE':
          history.replace(fallback);
          break;
        default:
          history.push(fallback);
          console.error('Fallback action must be value in [PUSH, REPLACE, POP]');
      }
    }
  }, [history, locationCollector]);
  const gotoPrevIndependentLocation = useCallback(function (fallback) {
    let fallbackAction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'REPLACE';
    gotoPrevLocation(prevIndependentLocation, fallback, fallbackAction);
  }, [prevIndependentLocation, gotoPrevLocation]);
  return {
    locationCollector,
    prevIndependentLocation,
    gotoPrevIndependentLocation,
    hasPrevIndependentLocation: !!prevIndependentLocation
  };
}
export default useHistoryExtensions;