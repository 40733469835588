import { ConfirmEmailContainer } from './container';
import { confirmEmailEventType } from './types';
export const createEvent = (payload, onSuccess) => {
  return {
    required: true,
    type: confirmEmailEventType,
    feature: ConfirmEmailContainer,
    mergeWithNext: true,
    mergeWithPrev: true,
    payload,
    onSuccess
  };
};