import { breadcrumbsClasses, chipClasses } from '@mui/material';
import { chipCustomClasses } from './chip';
const breadcrumbs = baseTheme => ({
  MuiBreadcrumbs: {
    defaultProps: {
      gap: 'default'
    },
    styleOverrides: {
      root: _ref => {
        let {
          ownerState
        } = _ref;
        let styles = {};
        if (ownerState.gap === 'dense') {
          styles = {
            ...styles,
            [`& .${breadcrumbsClasses.separator}`]: {
              margin: baseTheme.spacing(0, 0.25)
            }
          };
        }
        return styles;
      },
      li: {
        [`& .${chipCustomClasses.colorDefault}`]: {
          ':hover': {
            background: baseTheme.palette.secondary.A900
          }
        },
        [`& .${chipClasses.labelSmall}`]: {
          padding: baseTheme.spacing(0.75, 1.5),
          fontSize: baseTheme.typography.caption.fontSize,
          lineHeight: baseTheme.typography.caption.lineHeight,
          fontWeight: baseTheme.typography.caption.fontWeight
        }
      }
    }
  }
});
export default breadcrumbs;