import { Fade } from '@mui/material';
import { ArrowLeftIcon } from 'presentation/media/icons';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OffersTextHeader from '../textHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OffersCategoryHeader = props => {
  const {
    category,
    onChangeCategory
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 1,
      justifyContent: "space-between",
      alignItems: "flex-start",
      children: [_jsx(MPGrid, {
        item: true,
        children: _jsx(MPFab, {
          variant: 'squared',
          size: 'micro',
          color: 'secondaryLight',
          onClick: () => onChangeCategory(category.parentId),
          children: _jsx(ArrowLeftIcon, {
            fontSize: "medium"
          })
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: true,
        children: _jsx(OffersTextHeader, {
          children: category.name
        })
      })]
    })
  });
};
export default OffersCategoryHeader;