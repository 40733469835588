import { Typography } from '@mui/material';
import { ExpandChip, Label } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BreadcrumbExpandChipContent = _ref => {
  let {
    title,
    isExpand,
    count,
    icon,
    onClick
  } = _ref;
  const label = _jsxs(Label, {
    children: [_jsx(Typography, {
      variant: "body2",
      children: isExpand ? title : `+${count}`
    }), icon]
  });
  return _jsx(ExpandChip, {
    size: "micro",
    label: label,
    onClick: onClick
  });
};