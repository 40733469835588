import { EOfferListType } from 'domain/model/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TradeOfferListDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { tradeOfferListCommonArgsSelector, tradeOfferListGuidSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const type = EOfferListType.Common;
export const TradeOfferListDataCommonAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(tradeOfferListGuidSelector);
  const args = useSelector(tradeOfferListCommonArgsSelector);
  const handlers = useContextHandlers();
  return useMemo(() =>
  //  проверка на разность guid при апдейте компонента
  //  если разные - не рендерим, чтобы не показывать старые данные
  guid !== currentGuid || !args ? null : _jsx(TradeOfferListDataContainer, {
    type: type,
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangePage,
    onShowCard: handlers.onShowCard
  }), [args, currentGuid, guid, handlers.onChangePage, handlers.onShowCard]);
};