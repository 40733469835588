import * as React from 'react';
import { StyledAvatar } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const MPAvatar = props => {
  const {
    url,
    autosize = true,
    selected = false,
    description,
    size = 'default',
    variant = 'square',
    children
  } = props;
  return _jsx(StyledAvatar, {
    size: size,
    autosize: autosize,
    selected: selected,
    alt: description,
    src: url ?? undefined,
    variant: variant,
    children: children
  });
};