import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ProgressWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ContentLoader = props => {
  const {
    size = '2rem',
    color = 'primary',
    alpha,
    position = 'absolute',
    justifyContent = 'center',
    progress
  } = props;
  return _jsxs(Wrapper, {
    alpha: alpha,
    size: size,
    position: position,
    justifyContent: justifyContent,
    "data-testid": 'content-loader',
    children: [_jsx(CircularProgress, {
      size: size,
      color: color
    }), (progress ?? 0) > 0 && _jsx(ProgressWrapper, {
      children: _jsxs(Typography, {
        noWrap: true,
        children: [progress, " %"]
      })
    })]
  });
};
export default ContentLoader;