import { StyledButtonLink } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ButtonLink = props => {
  return _jsx(StyledButtonLink, {
    ...props,
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    variant: "text"
  });
};