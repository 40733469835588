import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/changeCity';
import { MPButton } from 'presentation/theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { confirmCityEventType } from './types';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ConfirmCityEventActions = _ref => {
  let {
    onNext
  } = _ref;
  const {
    publish
  } = useSystemEventBus();
  const onChangeCity = () => {
    publish({
      ...createEvent({}),
      hardInsertAfterType: confirmCityEventType
    });
    onNext();
  };
  return _jsxs(ConfirmGroupButtons, {
    children: [_jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: onNext,
      children: "\u0414\u0430"
    }), _jsx(MPButton, {
      color: "brand",
      variant: "text",
      fullWidth: true,
      size: "large",
      onClick: onChangeCity,
      children: "\u041D\u0435\u0442"
    })]
  });
};