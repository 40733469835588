import GeneralLoaderFixedAdapter from 'presentation/features/general/adapters/loaderFixed/loader';
import PartnerDeskOffersTabLayout from 'presentation/layouts/partnerDeskOffersTab';
import { partnerDeskDetailsTradeOffersIsFetchingSelector } from '../../store/selectors';
import PartnerDeskTradeOffersCategoriesAdapter from './adapters/categories';
import PartnerDeskTradeOffersCommonAdapter from './adapters/dataCommon';
import PartnerDeskTradeOffersUpcomingAdapter from './adapters/dataUpcoming';
import PartnerDeskDetailsTabTradeOffersNoData from './noData';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = PartnerDeskOffersTabLayout;
export const PartnerDeskDetailsTabTradeOffers = props => {
  const {
    guid
  } = props;
  return _jsxs(Layout, {
    toolbar: _jsx(PartnerDeskTradeOffersCategoriesAdapter, {}),
    stub: _jsx(PartnerDeskDetailsTabTradeOffersNoData, {}),
    loader: _jsx(GeneralLoaderFixedAdapter, {
      selector: partnerDeskDetailsTradeOffersIsFetchingSelector
    }),
    children: [_jsx(PartnerDeskTradeOffersCommonAdapter, {
      guid: guid
    }), _jsx(PartnerDeskTradeOffersUpcomingAdapter, {
      guid: guid
    })]
  });
};