import { PhoneIcon } from 'presentation/media/icons';
import { phoneFormatView } from 'presentation/utils/phone';
import { useState } from 'react';
import { UserProfileEditAttributePhone } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { useUserProfileAttributeEdit } from '../hooks/useAttributeEdit';
import { MPChip } from 'theme/ui-kit/chip';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserProfilePhoneAdapter = _ref => {
  let {
    user
  } = _ref;
  const [editMode, setEditMode] = useState(false);
  const {
    value,
    validation,
    isUpdating,
    update,
    reset,
    onChange
  } = useUserProfileAttributeEdit({
    attribute: 'phone',
    user,
    initialValue: ''
  });
  const showEdit = () => setEditMode(true);
  const hideEdit = () => {
    setEditMode(false);
    reset();
  };
  const updateInternal = () => {
    update().then(result => result && hideEdit());
  };
  return _jsxs(_Fragment, {
    children: [_jsx(UserProfileViewAttribute, {
      label: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
      value: user.phone && phoneFormatView(user.phone),
      icon: _jsx(PhoneIcon, {
        color: "secondary"
      }),
      info: !user.phone && _jsx(MPChip, {
        variant: "filled",
        color: "warning",
        label: "\u0414\u043E\u0431\u0430\u0432\u044C\u0442\u0435 \u0442\u0435\u043B\u0435\u0444\u043E\u043D",
        size: "micro"
      }),
      onAction: showEdit
    }), editMode && _jsx(UserProfileEditAttributePhone, {
      label: "\u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430",
      value: value,
      validation: validation?.phone,
      isFetching: isUpdating,
      onChange: onChange,
      onClose: hideEdit,
      onSave: updateInternal
    })]
  });
};