import AspOfferScreen from 'presentation/screen/aspOffer';
import AspOffersScreen from 'presentation/screen/aspOffers';
import AspOffersSearchResultsScreen from 'presentation/screen/aspOffersSearch';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.aspOffer;
export const routing = {
  list: root,
  details: `${root}/:id`,
  search: `${root}/search`
};
const AspOfferEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.list,
      component: AspOffersScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.search,
      component: AspOffersSearchResultsScreen
    }), _jsx(Route, {
      exact: true,
      path: routing.details,
      component: AspOfferScreen
    })]
  });
};
export default AspOfferEntry;