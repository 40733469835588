import { useEffect, useState } from 'react';
export const useFixedFooterParams = function () {
  let {
    allFooters = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    allFooters: true
  };
  const [footerHeight, setFooterHeight] = useState(0);
  const [resizeWatcher, setResizeWatcher] = useState(Symbol());
  const footers = document.getElementsByTagName('footer');
  useEffect(() => {
    let fixedFooters = [];
    if (!footers.length) {
      setFooterHeight(0);
      return;
    }
    if (footers?.length) {
      for (let i = 0; i < footers?.length; i++) {
        const footer = footers.item(i);
        if (footer && footer.computedStyleMap().get('position')?.value === 'fixed') {
          fixedFooters.push(footer);
        }
      }
    } else {
      fixedFooters = [];
    }
    if (!fixedFooters.length) {
      setFooterHeight(0);
      return;
    }
    const footer0OffsetHeight = fixedFooters?.[0]?.offsetHeight ?? 0;
    const footer1OffsetHeight = fixedFooters?.[1]?.offsetHeight ?? 0;
    const footer2OffsetHeight = fixedFooters?.[2]?.offsetHeight ?? 0;
    if (allFooters) {
      const height = footer0OffsetHeight + footer1OffsetHeight + footer2OffsetHeight;
      setFooterHeight(height);
    } else {
      setFooterHeight(footer0OffsetHeight);
    }
  }, [footers, allFooters, resizeWatcher]);
  useEffect(() => {
    const listener = () => setResizeWatcher(Symbol());
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
  return {
    footerHeight
  };
};