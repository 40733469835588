import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import PartnerDeskContact from 'presentation/features/partnerDesk/components/contact';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContactsLayout from './layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = OfferDetailsTabContactsLayout;
const OfferDetailsTabContacts = _ref => {
  let {
    contacts,
    description,
    descriptionTitle
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    spacing: 3,
    children: [_jsx(Layout, {
      children: contacts.map((contact, index) => _jsxs(MPGrid, {
        item: true,
        xl: 6,
        lg: 6,
        sm: 12,
        children: [_jsx(PartnerDeskContact, {
          contact: contact
        }), _jsx(Splitter, {
          size: 2
        })]
      }, index))
    }), description && _jsx(Layout, {
      children: _jsxs(MPGrid, {
        item: true,
        children: [_jsx(Typography, {
          variant: "subtitle1",
          mb: 1.25,
          children: descriptionTitle
        }), _jsx(DangerouslyHtmlWrapper, {
          children: _jsx(Typography, {
            variant: "body1",
            children: _jsx("div", {
              dangerouslySetInnerHTML: {
                __html: description
              }
            })
          })
        })]
      })
    })]
  });
};
export default OfferDetailsTabContacts;