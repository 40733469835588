import store from 'data/store/store';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { searchOffersDefaultParams } from '../../utils';
import { searchOffersSelector } from '../store/selectors';
import { searchOffersSetArgs, searchOffersSetIsNewFetching, searchOffersSetPage, searchOffersSetPageSize, searchOffersStartSession } from '../store/slice';
const useSearchOffersHandlers = props => {
  const {
    guid,
    query,
    offerType
  } = props;
  const {
    webAnalytics
  } = useWebAnalytics();
  const dispatch = useDispatch();
  const getCurrentState = useCallback(() => {
    return searchOffersSelector(store.getState());
  }, []);
  const onLoadMore = useCallback(() => {
    const state = getCurrentState();
    dispatch(searchOffersSetIsNewFetching(false));
    dispatch(searchOffersSetPage((state.args?.page ?? 0) + 1));
  }, [dispatch, getCurrentState]);
  const onShowCard = useCallback(offer => webAnalytics.offerShowInSearch(offer.id), [webAnalytics]);
  const onChangePageSize = useCallback(pageSize => {
    dispatch(searchOffersSetIsNewFetching(false));
    dispatch(searchOffersSetPageSize(pageSize));
  }, [dispatch]);
  useEffect(() => {
    const emptyParams = searchOffersDefaultParams;
    const state = getCurrentState();
    const currentGuid = state.guid;
    const currentArgs = state.args ?? emptyParams;
    const currentQuery = currentArgs?.query;
    const isGuidChanged = currentGuid !== guid;
    const isQueryChanged = (currentQuery || null) !== (query || null);
    if (isGuidChanged) {
      const args = {
        ...emptyParams,
        query,
        offerType
      };
      dispatch(searchOffersStartSession({
        guid,
        args
      }));
    } else {
      const args = {
        ...currentArgs,
        query,
        offerType
      };
      dispatch(searchOffersSetArgs(args));
    }
    if (isGuidChanged || isQueryChanged) {
      dispatch(searchOffersSetIsNewFetching(true));
    } else {
      dispatch(searchOffersSetIsNewFetching(false));
    }
  }, [dispatch, query, guid, getCurrentState, offerType]);
  return {
    onLoadMore,
    onShowCard,
    onChangePageSize
  };
};
export default useSearchOffersHandlers;