import { Stack, Typography } from '@mui/material';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const OrderField = _ref => {
  let {
    text,
    label
  } = _ref;
  return _jsxs(Stack, {
    children: [_jsx(Typography, {
      variant: "body2",
      color: "textSecondary",
      children: label
    }), _jsx(Typography, {
      variant: "body1",
      children: text
    })]
  });
};
export default OrderField;