import { Link } from '@mui/material';
import AboutPrivacyPolicyLinkPublic from 'presentation/features/about/components/privacyPolicyLink/public';
import TermsOfUseLinkPublic from 'presentation/features/about/components/termsOfUseLink/public';
import AboutFeedbackContainer from 'presentation/features/about/feedBack/container';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import FooterCopyRight from 'presentation/features/footer/components/copyright';
import FooterGroup from 'presentation/features/footer/components/group';
import FooterLayout from 'presentation/features/footer/layout';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { getPartnerPersonalAccountUrl, getSupportEmail } from 'presentation/utils';
import { useState } from 'react';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = FooterLayout;
const MainFooterContainer = () => {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const {
    isAuthenticated
  } = useAuth();
  const {
    hasFeature
  } = useTechConfig();
  return _jsxs(Layout, {
    children: [_jsxs(FooterGroup, {
      label: "\u041F\u043E\u043A\u0443\u043F\u0430\u0442\u0435\u043B\u044F\u043C",
      children: [_jsx(Link, {
        color: "textPrimary",
        underline: "none",
        href: "/support/\u041A\u0430\u043A \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0412\u0438\u0442\u0440\u0438\u043D\u043E\u0439.pdf",
        target: "_blank",
        variant: "body2",
        children: "\u041A\u0430\u043A \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0412\u0438\u0442\u0440\u0438\u043D\u043E\u0439"
      }), hasFeature(EAppFeature.TermsOfUse) && _jsx(TermsOfUseLinkPublic, {
        underline: "none",
        variant: "body2",
        children: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u043E\u0435 \u0441\u043E\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u0435"
      }), hasFeature(EAppFeature.PersonalDataPolicy) && _jsx(AboutPrivacyPolicyLinkPublic, {
        underline: "none",
        variant: "body2",
        children: "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u0432\xA0\u041E\u0410\u041E\xA0\xAB\u0420\u0416\u0414\xBB"
      })]
    }), isAuthenticated && getPartnerPersonalAccountUrl() && _jsx(FooterGroup, {
      label: "\u041F\u0430\u0440\u0442\u043D\u0451\u0440\u0430\u043C",
      children: _jsx(Link, {
        color: "textPrimary",
        underline: "none",
        href: getPartnerPersonalAccountUrl(),
        target: "_blank",
        variant: "body2",
        children: "\u041B\u0438\u0447\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442 \u043F\u0430\u0440\u0442\u043D\u0451\u0440\u0430"
      })
    }), _jsxs(FooterGroup, {
      label: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B",
      footer: _jsx(FooterCopyRight, {}),
      children: [_jsx(Link, {
        color: "textPrimary",
        underline: "none",
        href: `mailto:${getSupportEmail()}`,
        variant: "body2",
        children: getSupportEmail()
      }), isAuthenticated && _jsx(Link, {
        component: "div",
        color: "textPrimary",
        underline: "none",
        onClick: () => setFeedbackVisible(true),
        variant: "body2",
        children: "\u0424\u043E\u0440\u043C\u0430 \u043E\u0431\u0440\u0430\u0442\u043D\u043E\u0439 \u0441\u0432\u044F\u0437\u0438"
      })]
    }), _jsx(AboutFeedbackContainer, {
      open: feedbackVisible,
      onClose: () => setFeedbackVisible(false)
    })]
  });
};
export default MainFooterContainer;