import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import TradeOfferDetailsContainer from 'presentation/features/offer/trade/details/container';
import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { ERenderSource } from 'presentation/types';
import { useLocation, useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const TradeOfferScreen = () => {
  const location = useLocation();
  const from = location.state?.from ?? ERenderSource.Any;
  const {
    id
  } = useParams();
  const tab = new URLSearchParams(location.search).get(ETradeOfferUrlParam.Tab) ?? ETradeOfferDetailsTab.Description;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(TradeOfferDetailsContainer, {
        guid: guid,
        id: id,
        tab: tab,
        from: from
      })
    })
  });
};
export default TradeOfferScreen;