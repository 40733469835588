import { IconButton } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const MPSwitchIconButton = props => {
  const {
    icons,
    active,
    inProgress,
    onClick,
    color = 'secondary',
    ...others
  } = props;
  const onInternalClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(event);
  };
  const IconOn = icons.on;
  const IconOff = icons.off;
  let icon;
  if (active) {
    icon = typeof IconOn === 'function' ? _jsx(IconOn, {}) : IconOn;
  } else {
    icon = typeof IconOff === 'function' ? _jsx(IconOff, {}) : IconOff;
  }
  return _jsxs(IconButton, {
    color: color,
    disabled: inProgress,
    ...others,
    onClick: onInternalClick,
    children: [icon, inProgress && _jsx(ContentLoader, {
      size: 20
    })]
  });
};
export default MPSwitchIconButton;