import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';
import { getBonusConfig } from 'presentation/utils';
import techStub from 'presentation/media/images/techStub.png';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferBonusUnavailableAdapter = _ref => {
  let {
    children
  } = _ref;
  const unavailableReason = getBonusConfig()?.unavailableReason;
  if (unavailableReason) {
    return _jsx(OfferDetailsErrorLayout, {
      text: unavailableReason,
      stubImage: techStub
    });
  }
  return children;
};
export default CorpOfferBonusUnavailableAdapter;