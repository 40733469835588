import { Wrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const LoginLayout = props => {
  const {
    children
  } = props;
  return _jsx(Wrapper, {
    children: children
  });
};
export default LoginLayout;