import { useFooterRegister } from './hooks/useFooterRegister';
const FooterRegister = _ref => {
  let {
    name,
    children
  } = _ref;
  const {
    footerRef
  } = useFooterRegister(name);
  return children(footerRef);
};
export default FooterRegister;