import { HeaderWrapper } from './controls';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CmsOfferCategoriesLayout = props => {
  const {
    footer,
    header,
    children,
    loader
  } = props;
  return _jsxs(_Fragment, {
    children: [header && _jsx(HeaderWrapper, {
      children: header
    }), children, footer, loader]
  });
};
export default CmsOfferCategoriesLayout;