import { NeedLoginEventContainer } from './container';
import { NeedLoginEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: NeedLoginEventType,
    feature: NeedLoginEventContainer,
    mergeWithNext: false,
    mergeWithPrev: false,
    payload
  };
};