import { Typography } from '@mui/material';
import { IconFavoriteFilled } from 'presentation/media/icons';
import { favoritesStub } from 'presentation/media/images';
import Splitter from '../../../../../components/common/splitter';
import { ImageWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const UserFavoritesStub = () => {
  return _jsxs(Wrapper, {
    children: [_jsx(Typography, {
      variant: "h2",
      gutterBottom: true,
      children: "\u0412 \u0438\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u043C \u043F\u0443\u0441\u0442\u043E"
    }), _jsxs(Typography, {
      children: ["\u0416\u043C\u0438\u0442\u0435 ", _jsx(IconFavoriteFilled, {
        fontSize: "small"
      }), " \u0447\u0442\u043E\u0431\u044B \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"]
    }), _jsx(Typography, {
      children: "\u043F\u043E\u043D\u0440\u0430\u0432\u0438\u0432\u0448\u0438\u0435\u0441\u044F \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F"
    }), _jsx(Splitter, {
      size: 5
    }), _jsx(ImageWrapper, {
      children: _jsx("img", {
        src: favoritesStub,
        alt: "favorites-stub-image"
      })
    })]
  });
};
export default UserFavoritesStub;