import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useContextHandlers } from '../hooks/useContextHandlers';
export const CatalogBehaviorAdapter = () => {
  const location = useLocation();
  const [startLocation, setStartLocation] = useState(() => location);
  const handlers = useContextHandlers();
  useEffect(() => {
    if (startLocation !== location) {
      handlers.onClose();
    }
  }, [location, startLocation, handlers]);
  useEffect(() => {
    setStartLocation(location);
  }, [location]);
  return null;
};