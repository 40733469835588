import { Fade } from '@mui/material';
import { PartitionIcon } from 'presentation/components/common/partitionIcon';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { EAppCategories } from 'presentation/types';
import OffersCategoryHeader from '../../../components/categoryHeader';
import OffersCommonHeader from '../../../components/commonHeader';
import OffersSearchHeader from '../../../components/searchHeader';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useCorpOffersHeader from '../hooks/useHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CorpOffersHeaderAdapter = () => {
  const {
    x3
  } = useGridSpacing();
  const handlers = useContextHandlers();
  const {
    isNewSearchFetching,
    isSearchHeaderVisible,
    isCommonHeaderVisible,
    isCategoryHeaderVisible,
    isAnyHeaderVisible,
    selectedCategory,
    categoriesOut,
    initialTotalCount
  } = useCorpOffersHeader();
  if (!isAnyHeaderVisible || isNewSearchFetching) {
    return null;
  }
  return _jsx(Fade, {
    in: true,
    children: _jsxs("div", {
      children: [isSearchHeaderVisible && _jsx(OffersSearchHeader, {
        category: selectedCategory,
        count: initialTotalCount,
        categories: categoriesOut,
        onChangeCategory: handlers.onChangeCategory
      }), isCategoryHeaderVisible && selectedCategory && _jsx(OffersCategoryHeader, {
        category: selectedCategory,
        onChangeCategory: handlers.onReturnToTopCategory
      }), isCommonHeaderVisible && _jsx(OffersCommonHeader, {
        icon: _jsx(PartitionIcon, {
          fontSize: "large",
          type: EAppCategories.CorpOffers
        }),
        children: EAppCategories.CorpOffers
      }), _jsx(Splitter, {
        size: x3
      })]
    })
  });
};