import { useCallback, useEffect } from 'react';
import { EEventType } from './types';
import { v4 as uuid } from 'uuid';
import { useEventBus } from './useEventBus';
/**
 * @description шина системных событий событий (см. {@link ../README.md})
 * @description добавлен для работы именно с системными событиями (для публикации любого типа, для подписки конкретного)
 * @description подписка/отписка выполняется автоматически
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * const createEvent = (payload: EventPayloadType) => {
 *   return {
 *     required: true,
 *     type: 'event_type',
 *     content: ContentComponent,
 *     mergeWithNext: true,
 *     mergeWithPrev: false,
 *     payload,
 *   };
 * }
 * ...
 * const { publish } = useSystemEventBus<EventPayloadType>();
 * ...
 * publish(createEvent({ text: 'hello' }));
 *
 * @example
 * type EventPayloadType = {
 *   text: string
 * }
 *
 * useSystemEventBus<EventPayloadType>(newEvent => {
 *   ...
 * });
 */
export const useSystemEventBus = callback => {
  const {
    publish: publishOwn,
    subscribe,
    unsubscribe
  } = useEventBus();
  const publish = useCallback(function () {
    for (var _len = arguments.length, events = new Array(_len), _key = 0; _key < _len; _key++) {
      events[_key] = arguments[_key];
    }
    publishOwn(EEventType.SystemEvent, ...events);
  }, [publishOwn]);
  const publishFlow = useCallback(events => {
    const flowId = uuid();
    events.forEach(e => {
      publishOwn(EEventType.SystemEvent, {
        ...e,
        flowId
      });
    });
  }, [publishOwn]);
  useEffect(() => {
    if (callback) {
      subscribe(EEventType.SystemEvent, callback);
      return () => unsubscribe(EEventType.SystemEvent);
    }
  }, [callback, subscribe, unsubscribe]);
  return {
    publish,
    publishFlow
  };
};