import HistoryExtensionContainer from 'features/general/historyExtension/container';
import Notifier from 'features/general/notifier/container';
import SystemEventsContainer from 'features/systemEvent';
import { GuestTargetContainer } from 'features/user/guestTarget/container';
import { WebAnalyticsInitializer } from 'features/webAnalytics';
import { EventBusContainer } from '@privilege-frontend/eventBus';
import { GlobalPreviewModeContainer } from 'screen/cms/globalPreviewMode/container';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AppInfrastructure = () => {
  return _jsxs(_Fragment, {
    children: [_jsx(EventBusContainer, {}), _jsx(WebAnalyticsInitializer, {}), _jsx(GlobalPreviewModeContainer, {}), _jsx(GuestTargetContainer, {}), _jsx(Notifier, {}), _jsx(HistoryExtensionContainer, {}), _jsx(SystemEventsContainer, {})]
  });
};
export default AppInfrastructure;