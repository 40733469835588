import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import LandingWindowContainer from 'presentation/features/landing/window/container';
import ScreenLayout from 'presentation/layouts/screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const LandingWindowScreen = () => {
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(LandingWindowContainer, {
        guid: guid
      })
    })
  });
};
export default LandingWindowScreen;