import { CloseIcon } from 'presentation/media/icons';
import Option from './option';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Icon = _jsx(CloseIcon, {});
const CancelOption = _ref => {
  let {
    onClick
  } = _ref;
  return _jsx(_Fragment, {
    children: _jsx(Option, {
      icon: Icon,
      text: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437",
      onClick: onClick
    })
  });
};
export default CancelOption;