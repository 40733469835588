import { DialogActions, DialogContent } from '@mui/material';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SystemEventContentBuilderLayout = _ref => {
  let {
    wrapper,
    title,
    actions,
    children: content
  } = _ref;
  return _jsxs(MPDialogContentBuilder, {
    wrapper: wrapper,
    title: title,
    children: [content && _jsx(DialogContent, {
      children: content
    }), actions && _jsx(DialogActions, {
      children: actions
    })]
  });
};