import Attributes from 'presentation/features/offer/product/components/attributes';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductAttributesAdapter = _ref => {
  let {
    id
  } = _ref;
  const {
    productOffer
  } = useProductOfferDetailsData(id);
  return productOffer?.productDesk?.id ? _jsx(Attributes, {
    deskId: productOffer.productDesk.id,
    productId: productOffer.id,
    selectedVariant: productOffer
  }) : null;
};
export default ProductAttributesAdapter;