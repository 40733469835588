import { Typography } from '@mui/material';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ProductFilterItem = _ref => {
  let {
    label,
    isActive,
    children
  } = _ref;
  return isActive ? _jsxs("div", {
    children: [_jsx(Typography, {
      children: label
    }), children]
  }) : null;
};