import OfferListAside from '../../../components/listAside';
import OfferSubCategories from '../../../components/subCategories';
import { getTradeOffersSearchRoute } from '../../routes';
import useCategoriesData from '../hooks/useCategoriesData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TradeOfferListAsideAdapter = () => {
  const {
    subCategories,
    isCategoriesFetching
  } = useCategoriesData();
  const linkFactory = category => getTradeOffersSearchRoute({
    categoryId: category.id
  });
  if (!subCategories?.length || isCategoriesFetching) {
    return null;
  }
  return _jsx(OfferListAside, {
    children: _jsx(OfferSubCategories, {
      categories: subCategories,
      linkFactory: linkFactory
    })
  });
};