import { EBalanceType } from 'domain/model';
import { UserFinanceHistoryBonusesAdapter } from './historyBonuses';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceHistoryAdapter = props => {
  const {
    tab,
    guid
  } = props;
  switch (tab) {
    case EBalanceType.Bonuses:
      return _jsx(UserFinanceHistoryBonusesAdapter, {
        guid: guid
      });
    case EBalanceType.ASP:
      return null;
  }
};