import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { ERenderSource } from 'presentation/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAspOffersListRoute } from '../../routes';
import { aspOfferDetailsStartSession } from '../store/slice';
const useAspOfferDetails = _ref => {
  let {
    id,
    guid,
    from
  } = _ref;
  const {
    webAnalytics
  } = useWebAnalytics();
  const dispatch = useDispatch();
  const {
    gotoPrevIndependentLocation
  } = useHistoryExtensions();
  useEffect(() => {
    switch (from) {
      case ERenderSource.OfferList:
        webAnalytics.offerViewInList(id);
        break;
      case ERenderSource.OfferSearch:
        webAnalytics.offerViewInSearch(id);
        break;
      case ERenderSource.Any:
        webAnalytics.offerView(id);
        break;
    }
  }, [webAnalytics, id, from]);
  useEffect(() => {
    dispatch(aspOfferDetailsStartSession({
      guid
    }));
  }, [dispatch, guid]);
  const onBack = () => {
    gotoPrevIndependentLocation(getAspOffersListRoute());
  };
  return {
    onBack
  };
};
export default useAspOfferDetails;