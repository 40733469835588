import { useGetPartnerProductOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProductOfferCard from '../../../../offer/product/components/card';
import { partnerDeskDetailsProductOffersIsNewFetchingSelector } from '../../store/selectors';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskProductOffersDataContainer = props => {
  const {
    args,
    header,
    onChangePage
  } = props;
  const isNewFetching = useSelector(partnerDeskDetailsProductOffersIsNewFetchingSelector);
  const {
    page
  } = args;
  const {
    data,
    error
  } = useGetPartnerProductOfferListQuery(args, {
    refetchOnMountOrArgChange: true
  });
  const productOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  /*const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
      partnerId: args.partnerId,
    },
  });*/

  const onLoadMore = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  const hasOffers = !!productOffers && productOffers.length > 0;
  if (!hasOffers || isNewFetching) {
    return null;
  }
  return _jsxs("div", {
    children: [header, _jsx("div", {
      children: _jsx(CardPageableList, {
        totalCount: totalCount,
        onLoadMore: onLoadMore,
        children: productOffers.map(productOffer => _jsx(ProductOfferCard, {
          product: productOffer
          /*favorite={{
            showAlways: isDownLg,
            active: isFavorite(EOfferType.Product, productOffer),
            inProgress: isAddToFavoritesFetching(productOffer.id),
            onClick: () => addOrRemoveFavorite(EOfferType.Product, productOffer),
          }}*/
        }, productOffer.id))
      })
    })]
  });
};
export default PartnerDeskProductOffersDataContainer;