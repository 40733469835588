import { EOrderDiscriminator } from '../../../../../domain/model/enums';
import BookingOfferOrder from './booking';
import useOrderDetailsData from './hooks/useOrderDetailsData';
import { OrderDetailContext } from './context';
import useOrderDetails from './hooks/useOrderDetails';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import OrderDetailsLoaderAdapter from './adapters/loader';
import OrderDetailStub from './adapters/stub';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserOrderContainer = props => {
  const {
    id
  } = props;
  const handlers = useOrderDetails();
  const {
    order
  } = useOrderDetailsData(id);
  return _jsx(OrderDetailContext.Provider, {
    value: handlers,
    children: _jsx(RenderManagerErrorProvider, {
      children: _jsx(CommonRenderManager, {
        loader: _jsx(OrderDetailsLoaderAdapter, {
          id: id
        }),
        stub: OrderDetailStub,
        children: order?.discriminator === EOrderDiscriminator.BookingOrder && _jsx(BookingOfferOrder, {
          order: order
        })
      })
    })
  });
};
export default UserOrderContainer;