import { Fade } from '@mui/material';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskDetailsAboutLayout = props => {
  const {
    children
  } = props;
  return _jsx(Fade, {
    in: true,
    children: _jsx(DangerouslyHtmlWrapper, {
      children: children && _jsx("div", {
        dangerouslySetInnerHTML: {
          __html: children
        }
      })
    })
  });
};
export default PartnerDeskDetailsAboutLayout;