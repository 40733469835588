import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ChangeEmailSuccessDialog = props => {
  const {
    email,
    open,
    onClose
  } = props;
  return _jsxs(MPConfirmDialog, {
    title: "\u042D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430\u044F \u043F\u043E\u0447\u0442\u0430 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0430",
    open: open,
    maxWidth: "xs",
    onClose: onClose,
    buttons: _jsx(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      onClick: onClose,
      children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
    }),
    children: ["\u042D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430\u044F \u043F\u043E\u0447\u0442\u0430 \u0432\u0430\u0448\u0435\u0433\u043E \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430 \u0431\u044B\u043B\u0430 \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0430 \u043D\u0430", ' ', _jsx(Typography, {
      variant: "subtitle1",
      component: "span",
      children: email
    })]
  });
};
export default ChangeEmailSuccessDialog;