import { useMediaQuery } from '@mui/material';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import AppImage from 'presentation/components/common/images/common';
import { useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Leveler, MediaPreviewCarouselsSeparator, MediaPreviewCarouselWrapper, MediaPreviewDefaultCarouselWrapper, MediaPreviewThumbCarouselWrapper } from './controls';
import CarouselDefault from '../carouselDefault';
import { SwiperSlide } from 'swiper/react';
import useGetDefaultCarouselNavigation from '../defaultNavigation/useGetDefaultCarouselNavigation';
import CarouselThumbs from '../thumbs';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const THRESHOLD = 2;
const MediaPreviewCarousel = _ref => {
  let {
    images,
    initSlide,
    onMediaItemClick
  } = _ref;
  const zoomInstances = useRef([]);
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const mainSwiperProps = {
    effect: 'slide',
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    spaceBetween: 10,
    navigation: isMdUp,
    noSwiping: true,
    initialSlide: initSlide,
    pagination: false,
    threshold: THRESHOLD,
    noSwipingClass: 'swiper-slide',
    onSlideChange: () => {
      zoomInstances.current.forEach(i => {
        i.resetTransform();
      });
    },
    ...(!isMdUp && {
      direction: 'vertical',
      centeredSlides: false,
      loop: false
    })
  };
  const thumbSwiperProps = {
    threshold: THRESHOLD,
    spaceBetween: 8,
    initialSlide: initSlide,
    watchSlidesProgress: true,
    slidesPerView: isLgUp ? 10 : 6
  };
  const controlsElement = useGetDefaultCarouselNavigation({
    navigationEnabled: mainSwiperProps.navigation,
    childrenCount: images.length,
    swiper
  });
  const defaultSlides = useMemo(() => images.map((image, i) => {
    return isMdUp ? _jsx(SwiperSlide, {
      children: _jsx(TransformWrapper, {
        ref: instance => {
          if (instance && !zoomInstances.current.includes(instance)) {
            zoomInstances.current.push(instance);
          }
        },
        children: _jsx(TransformComponent, {
          contentStyle: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
          },
          wrapperStyle: {
            width: '100%',
            height: '100%'
          },
          children: _jsx(AppImage, {
            onClick: onMediaItemClick && onMediaItemClick,
            src: image.path
          })
        })
      })
    }, i) : _jsx(SwiperSlide, {
      children: _jsx(AppImage, {
        onClick: onMediaItemClick && onMediaItemClick,
        src: image.path
      })
    }, i);
  }), [images, isMdUp, onMediaItemClick]);
  return _jsxs(MediaPreviewCarouselWrapper, {
    children: [_jsx(Leveler, {
      children: _jsx(MediaPreviewDefaultCarouselWrapper, {
        children: _jsx(CarouselDefault, {
          controlsElement: controlsElement,
          swiperProps: mainSwiperProps,
          onSwiper: setSwiper,
          handlerChangeActiveIndex: () => {},
          thumbsSwiper: thumbsSwiper,
          children: defaultSlides
        })
      })
    }), isMdUp && _jsxs(_Fragment, {
      children: [_jsx(MediaPreviewCarouselsSeparator, {}), _jsx(MediaPreviewThumbCarouselWrapper, {
        children: _jsx(CarouselThumbs, {
          onSwiper: setThumbsSwiper,
          thumbScrollMode: true,
          swiperProps: thumbSwiperProps,
          children: images.map((image, i) => _jsx(SwiperSlide, {
            children: _jsx(AppImage, {
              onClick: onMediaItemClick && onMediaItemClick,
              src: image.path
            })
          }, i))
        })
      })]
    })]
  });
};
export default MediaPreviewCarousel;