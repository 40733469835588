import { toRubCurrency } from '../../../../../utils/currency';
import { Price } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferListItemPrice = props => {
  const {
    price
  } = props;
  return _jsx(Price, {
    variant: "h3",
    component: "div",
    children: toRubCurrency(price)
  });
};
export default AspOfferListItemPrice;