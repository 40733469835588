import CmsCollectionSummaryContainer from 'presentation/features/cms/collectionSummary/container';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CmsCollectionScreen = () => {
  const {
    id
  } = useParams();
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(CmsCollectionSummaryContainer, {
        id: id,
        guid: guid
      })
    })
  });
};
export default CmsCollectionScreen;