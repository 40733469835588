import { Typography } from '@mui/material';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferReceivedActivationEventContent = _ref => {
  let {
    payload: {
      content
    }
  } = _ref;
  return _jsx(Typography, {
    variant: "body2",
    dangerouslySetInnerHTML: {
      __html: content
    }
  });
};