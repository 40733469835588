import { NeedFillProfileEventActions } from './actions';
import { NeedFillProfileEventContent } from './content';
import { NeedFillProfileEventTitle } from './title';
import { needFillProfileEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: needFillProfileEventType,
    title: NeedFillProfileEventTitle,
    content: NeedFillProfileEventContent,
    actions: NeedFillProfileEventActions,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};