import { Fade, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { Children } from 'react';
import { ActionsWrapper, ContentWrapper, SubtitleWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const UserProfileLayout = props => {
  const {
    title,
    subTitle,
    corpLinked,
    actions,
    loader,
    notifications,
    children
  } = props;
  const childrenArray = Children.toArray(children);
  return _jsx(Fade, {
    in: true,
    children: _jsx(DefaultContentWrapper, {
      children: _jsxs(ContentWrapper, {
        children: [_jsx(Typography, {
          variant: "h1",
          children: title
        }), _jsx(Splitter, {
          size: 3
        }), _jsxs(SubtitleWrapper, {
          children: [_jsx(Typography, {
            variant: "h2",
            children: subTitle
          }), corpLinked]
        }), actions && _jsx(ActionsWrapper, {
          children: actions
        }), childrenArray && childrenArray.length > 0 && _jsx(MPGrid, {
          container: true,
          spacing: 1,
          direction: "column",
          children: childrenArray?.map((child, index) => _jsx(MPGrid, {
            item: true,
            children: child
          }, index))
        }), notifications && _jsxs(_Fragment, {
          children: [_jsx(Splitter, {
            size: 3
          }), notifications]
        }), loader]
      })
    })
  });
};
export default UserProfileLayout;