import { useMemo } from 'react';
import HeaderTitle from '../components/title';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderLogoAdapter = () => {
  const handlers = useContextHandlers();
  return useMemo(() => _jsx(HeaderTitle, {
    onClickLogo: handlers.onLogoClick
  }), [handlers.onLogoClick]);
};
export default HeaderLogoAdapter;