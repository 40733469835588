import { useEffect } from 'react';
import { AboutTextSimpleLayout } from '../../../layouts/aboutSimple';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutTextSimpleContainer = _ref => {
  let {
    title,
    close,
    children
  } = _ref;
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);
  const Layout = AboutTextSimpleLayout;
  return _jsx(Layout, {
    title: title,
    close: close,
    children: children
  });
};
export default AboutTextSimpleContainer;