import AspOfferClaimContainer from 'presentation/features/offer/asp/claim/container';
import useAspOfferDetailsData from '../hooks/useAspOfferDetailsData';
import useAspOfferPartnerDeskData from '../hooks/useAspOfferPartnerDeskData';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferDetailsSidebarAdapter = _ref => {
  let {
    guid,
    id
  } = _ref;
  const {
    aspOffer
  } = useAspOfferDetailsData(id);
  const {
    partnerDesk
  } = useAspOfferPartnerDeskData(id);
  if (!aspOffer) {
    return null;
  }
  return _jsx(AspOfferClaimContainer, {
    guid: guid,
    aspOffer: aspOffer,
    partnerDesk: partnerDesk
  });
};