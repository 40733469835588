import { Hidden } from '@mui/material';
import { CountryTabs } from 'presentation/components/common/countrySelector';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback, useMemo } from 'react';
import { CountryWrapper, LettersWrapper, NumericItemWrapper } from './controls';
import AlphabeticalSearchItem from './item';
import { charIsAllowed, specialCharactersAndDigitsRegEx } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskFilterAlphabeticalSearch = _ref => {
  let {
    value,
    onChange,
    allowedAlphabet,
    locale,
    onChangeLocale,
    alphabet,
    specialSymbols
  } = _ref;
  const currentChar = useMemo(() => value && value?.length !== allowedAlphabet?.length ? value : null, [value, allowedAlphabet]);
  const onChangeInternal = useCallback(char => () => {
    if (char === currentChar?.[0]) {
      onChange(null);
    } else {
      onChange(char ? [char] : null);
    }
  }, [currentChar, onChange]);
  const digitsAndSpecialSymbolsEnabled = !!specialSymbols.length;
  return _jsxs(MPGrid, {
    container: true,
    alignItems: "flex-start",
    spacing: 3,
    children: [_jsx(MPGrid, {
      item: true,
      zero: true,
      sm: "auto",
      children: _jsx(CountryWrapper, {
        children: _jsx(CountryTabs, {
          value: locale,
          variant: "standard",
          onChange: onChangeLocale
        })
      })
    }), _jsx(Hidden, {
      smDown: true,
      children: _jsx(Splitter, {
        variant: "vertical",
        size: 2
      })
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(AlphabeticalSearchItem, {
        selected: value?.length === allowedAlphabet?.length,
        onClick: () => onChange(allowedAlphabet),
        children: "\u0412\u0441\u0435"
      })
    }), _jsx(MPGrid, {
      item: true,
      zero: 12,
      sm: true,
      children: _jsxs(LettersWrapper, {
        children: [alphabet.map(char => {
          const enabled = charIsAllowed(char, allowedAlphabet);
          return _jsx(AlphabeticalSearchItem, {
            disabled: !enabled,
            selected: char === currentChar?.[0],
            onClick: enabled ? onChangeInternal(char) : undefined,
            children: char
          }, char);
        }), _jsx(NumericItemWrapper, {
          children: _jsx(AlphabeticalSearchItem, {
            disabled: !digitsAndSpecialSymbolsEnabled,
            selected: currentChar?.[0] && specialCharactersAndDigitsRegEx.test(currentChar?.[0]),
            onClick: digitsAndSpecialSymbolsEnabled ? () => onChange(specialSymbols) : undefined,
            children: "0-9"
          })
        })]
      })
    })]
  });
};
export default PartnerDeskFilterAlphabeticalSearch;