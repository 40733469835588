import StubCommon from 'presentation/components/common/stub/common';
import { searchStub } from 'presentation/media/images';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SerarchOffersStub = () => {
  return _jsx(StubCommon, {
    title: "\u041F\u043E \u0432\u0430\u0448\u0435\u043C\u0443 \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
    text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043E\u043A\u0440\u0430\u0442\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441 \u0438\u043B\u0438 \u0437\u0430\u0434\u0430\u0442\u044C \u0435\u0433\u043E \u043F\u043E-\u0434\u0440\u0443\u0433\u043E\u043C\u0443",
    image: searchStub
  });
};
export default SerarchOffersStub;