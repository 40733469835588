import { Text } from '../../components/controls';
// import { useMemo } from 'react';
// import { getSupportInfo } from '../../../../utils';
// import { Link } from '@mui/material';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AboutAspCommonInfo = () => {
  // const serviceLink = useMemo(() => {
  //   const email = getSupportInfo()?.email;
  //   return (
  //     <Link
  //       color='primary'
  //       underline='none'
  //       target='_blank'
  //       href={`mailto:${email}`}
  //     >
  //       {email}
  //     </Link>
  //   );
  // }, []);

  return _jsx(_Fragment, {
    children: _jsx(Text, {
      children: "\u0410\u0421\u041F (\u0410\u0432\u0430\u043D\u0441\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u0441\u043E\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u043F\u0430\u043A\u0435\u0442) \u2014 \u044D\u0442\u043E \u0432\u0438\u0434 \u0441\u043E\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0439 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0438 \u0434\u043B\u044F \u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A\u043E\u0432 \u041E\u0410\u041E \xAB\u0420\u0416\u0414\xBB. \u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A \u043C\u043E\u0436\u0435\u0442 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u0410\u0421\u041F \u0434\u043B\u044F \u043F\u043E\u043A\u0443\u043F\u043A\u0438 \u0441\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0432 \u043D\u0430 \u0442\u043E\u0432\u0430\u0440\u044B \u0438 \u0443\u0441\u043B\u0443\u0433 \u0447\u0435\u0440\u0435\u0437 \u0441\u0435\u0440\u0432\u0438\u0441 \xAB\u0412\u0438\u0442\u0440\u0438\u043D\u0430\xBB \u0432 \u043F\u0440\u0435\u0434\u0435\u043B\u0430\u0445 \u0443\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u043E\u0433\u043E \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043B\u0438\u043C\u0438\u0442\u0430."
    })
  });
};
export default AboutAspCommonInfo;