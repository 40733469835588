import { productOfferAddToCartLabel, productOfferInCartLabel } from '../constants';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductOfferCartButtonLabelForDetails = _ref => {
  let {
    isEmpty
  } = _ref;
  return _jsx(_Fragment, {
    children: isEmpty ? productOfferAddToCartLabel : productOfferInCartLabel
  });
};
export default ProductOfferCartButtonLabelForDetails;