import { createSlice } from '@reduxjs/toolkit';
import { priceRangeMaxValue, priceRangeMinValue, productOfferEmptyFilter } from '../utils';
const initialState = {
  guid: null,
  filter: productOfferEmptyFilter,
  priceRangeFrom: priceRangeMinValue,
  priceRangeTo: priceRangeMaxValue
};
const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    productOffersFilterSetPrice: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        minPrice,
        maxPrice
      } = payload;
      state.filter.minPrice = minPrice;
      state.filter.maxPrice = maxPrice;
    },
    productOffersFilterSet: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      state.filter = payload;
    },
    productOffersFilterClear: state => {
      state.filter = initialState.filter;
    }
  }
});
export const {
  productOffersFilterSetPrice,
  productOffersFilterClear,
  productOffersFilterSet
} = slice.actions;
export default slice.reducer;