import AppSvgIcon from '../../../../components/common/icon';
import { MPFab } from '../../../../theme/ui-kit/button';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderAction = _ref => {
  let {
    icon,
    active,
    onClick
  } = _ref;
  return _jsx(MPFab, {
    color: "secondaryLight",
    size: "medium",
    variant: "squared",
    onClick: onClick,
    children: _jsx(AppSvgIcon, {
      icon: icon,
      fontSize: "medium",
      color: active ? 'primary' : 'inherit'
    })
  });
};
export default HeaderAction;