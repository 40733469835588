import { Button } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { StyledToggleButton } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function MPButtonsGroup(props) {
  const {
    items,
    selected,
    color = 'standard',
    size = 'small',
    exclusive = false,
    disabled = false,
    onChange
  } = props;
  const getSelectedItemIndex = item => selected.findIndex(s => s.id === item.id);
  const onChangeSelectedItem = item => {
    const selectedIndex = getSelectedItemIndex(item);
    if (selectedIndex !== -1 && !exclusive) {
      const result = [...selected];
      result.splice(selectedIndex, 1);
      onChange(result);
      return;
    }
    if (!exclusive) {
      onChange([...selected, item]);
    } else {
      onChange([item]);
    }
  };
  return _jsx(MPGrid, {
    container: true,
    spacing: 1,
    children: items.map(item => _jsx(MPGrid, {
      item: true,
      children: _jsx(StyledToggleButton, {
        component: Button,
        variant: "contained",
        size: size,
        color: color,
        value: item.id,
        disabled: disabled,
        selected: getSelectedItemIndex(item) !== -1,
        onClick: () => onChangeSelectedItem(item),
        children: item.name
      })
    }, item.id))
  });
}