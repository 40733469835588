import { Link, Typography } from '@mui/material';
import AppImage from 'presentation/components/common/images/common';
import { toRubCurrency } from '../../../../utils/currency';
import { ContentGrid, ContentGridArea, ECartItemGridAreas, PreviewWrapper, ShortFinanceBlock, ShortPrice, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CartListItemShort = props => {
  const {
    data,
    onOpenProductDetails
  } = props;
  const {
    image,
    name,
    cost,
    qty
  } = data;
  return _jsx(Wrapper, {
    children: _jsxs(ContentGrid, {
      children: [_jsx(ContentGridArea, {
        area: ECartItemGridAreas.Preview,
        children: image && _jsx(Link, {
          color: 'textPrimary',
          underline: "hover",
          onClick: onOpenProductDetails,
          children: _jsx(PreviewWrapper, {
            enabled: true,
            children: _jsx(AppImage, {
              src: image
            })
          })
        })
      }), _jsx(ContentGridArea, {
        area: ECartItemGridAreas.Name,
        children: _jsx(Link, {
          color: 'textPrimary',
          underline: "hover",
          onClick: onOpenProductDetails,
          children: name
        })
      }), _jsx(ContentGridArea, {
        area: ECartItemGridAreas.Finance,
        children: _jsxs(ShortFinanceBlock, {
          children: [_jsxs(Typography, {
            children: [" ", qty, "\u0448\u0442."]
          }), _jsx(ShortPrice, {
            children: toRubCurrency(cost)
          })]
        })
      })]
    })
  });
};
export default CartListItemShort;