import { CloseIcon } from 'presentation/media/icons';
import { MPIconButton } from 'presentation/theme/ui-kit/button';
import AppSvgIcon from '../../../../../../components/common/icon';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspOfferCloseAdapter = () => {
  const {
    onBack
  } = useContextHandlers();
  return _jsx(MPIconButton, {
    color: "secondary",
    onClick: onBack,
    children: _jsx(AppSvgIcon, {
      icon: CloseIcon
    })
  });
};