import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'pageable/cache',
  initialState: {},
  reducers: {
    pageableCachePut: (state, _ref) => {
      let {
        payload
      } = _ref;
      const {
        key,
        tag,
        data
      } = payload;
      state[key] = {
        tag,
        data
      };
    },
    pageableCacheClearByTag: (state, _ref2) => {
      let {
        payload
      } = _ref2;
      const {
        tag
      } = payload;
      Object.keys(state).forEach(key => {
        if (state[key].tag === tag) {
          delete state[key];
        }
      });
    }
  }
});
export const {
  pageableCachePut,
  pageableCacheClearByTag
} = slice.actions;
export default slice.reducer;