import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import AspOfferDetailsContainer from 'presentation/features/offer/asp/details/container';
import { EAspOfferDetailsTab } from 'presentation/features/offer/asp/details/utils';
import { EAspOfferUrlParam } from 'presentation/features/offer/asp/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { ERenderSource } from 'presentation/types';
import { useLocation, useParams } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferScreen = () => {
  const location = useLocation();
  const from = location.state?.from ?? ERenderSource.Any;
  const {
    id
  } = useParams();
  const tab = new URLSearchParams(location.search).get(EAspOfferUrlParam.Tab) ?? EAspOfferDetailsTab.Description;
  return _jsx(RequiredGuidInLocationContainer, {
    children: guid => _jsx(ScreenLayout, {
      footer: true,
      children: _jsx(AspOfferDetailsContainer, {
        guid: guid,
        id: id,
        tab: tab,
        from: from
      })
    })
  });
};
export default AspOfferScreen;