import { Fade, useMediaQuery, useTheme } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import useHideHeader from '../../features/header/hooks/useHideHeader';
import useVisibleSectionLinks from '../../features/sectionLinks/hooks/useVisibleSectionLinks';
import BackgroundContainer from '../backgroundContainer';
import { FitContainer, InnerLayoutGrid, InnerLayoutGridArea, ParentLayoutGrid, ParentLayoutGridArea, RelatedWrapper } from './controls';
import { EInnerGridAreas, EParentGridAreas } from './types';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OfferDetailsLayout = props => {
  const {
    id,
    image,
    toolbar,
    breadcrumb,
    sidebar,
    tabs,
    partnerInfo,
    title,
    description,
    close,
    smartphoneFixedBar,
    favorite,
    related,
    useLoading
  } = props;
  const isLoading = useLoading({
    id
  });
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  useVisibleSectionLinks(isDownMd);
  useHideHeader(isDownMd);
  useVisibleSectionLinks(isDownMd);
  return _jsxs(_Fragment, {
    children: [_jsx(ScreenNavWrapper, {
      children: toolbar
    }), _jsx(Fade, {
      in: true,
      children: _jsx(DefaultContentWrapper, {
        children: _jsx(FitContainer, {
          children: isLoading ? null : _jsx(ParentLayoutGrid, {
            children: _jsxs(ParentLayoutGridArea, {
              area: EParentGridAreas.Inner,
              children: [_jsx(BackgroundContainer, {
                close: close,
                children: _jsxs(InnerLayoutGrid, {
                  children: [_jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.SmartphoneFixedBar,
                    children: smartphoneFixedBar
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Title,
                    children: title
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Favorite,
                    children: favorite
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Breadcrumbs,
                    children: breadcrumb
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Image,
                    children: image
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Description,
                    children: description
                  }), _jsxs(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Sidebar,
                    children: [sidebar, partnerInfo]
                  }), _jsx(InnerLayoutGridArea, {
                    area: EInnerGridAreas.Tabs,
                    children: tabs
                  })]
                })
              }), related && _jsx(RelatedWrapper, {
                children: related
              })]
            })
          })
        })
      })
    })]
  });
};
export default OfferDetailsLayout;