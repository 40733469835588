import { useSystemEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/user/events/welcomeBenefits';
import { useCallback } from 'react';
import useCurrentUserBalance from '../../../hooks/useCurrentUserBalance';
import { ENotificationMessageType } from '../../notification/types';
import useNotificationSocket from '../../notification/useNotificationSocket';
const UserNotificationsReceiver = () => {
  const {
    publish
  } = useSystemEventBus();
  const {
    refreshBalance
  } = useCurrentUserBalance();
  const onMessageReceive = useCallback(msg => {
    switch (msg.type) {
      case ENotificationMessageType.UserBalanceChange:
        refreshBalance();
        return;
      case ENotificationMessageType.BenefitWellcomeCredit:
        publish(createEvent({
          count: msg.amount
        }));
        return;
      default:
        return;
    }
  }, [publish, refreshBalance]);
  useNotificationSocket({
    onMessageReceive
  });
  return null;
};
export default UserNotificationsReceiver;