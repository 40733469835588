import { SeeAndSunIcon } from 'presentation/media/icons';
import OrderLayout from '../layout';
import CloseAdapter from '../adapters/close';
import BookingOfferOrderSmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import BookingOfferOrderOptionsAdapter from './adapters/options';
import BookingOfferOrderDetailsAdapter from './adapters/details';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Close = _jsx(CloseAdapter, {});
const BookingOfferOrder = props => {
  return _jsx(OrderLayout, {
    smartphoneFixedBar: _jsx(BookingOfferOrderSmartPhoneFixedBarAdapter, {
      ...props
    }),
    close: Close,
    options: _jsx(BookingOfferOrderOptionsAdapter, {
      ...props
    }),
    icon: SeeAndSunIcon,
    details: _jsx(BookingOfferOrderDetailsAdapter, {
      ...props
    })
  });
};
export default BookingOfferOrder;