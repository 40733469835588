import { getUserProfileRoute } from 'features/user/entry';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useHistory } from 'react-router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NeedFillProfileEventActions = _ref => {
  let {
    onNext
  } = _ref;
  const history = useHistory();
  const onFill = () => {
    history.push(getUserProfileRoute());
    onNext();
  };
  return _jsx(MPButton, {
    fullWidth: true,
    color: "brand",
    size: "large",
    onClick: onFill,
    children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u0444\u0438\u043B\u044C"
  });
};