import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useProductOffersContextHandlers } from '../../../hooks/useProductOffersContextHandlers';
import { partnerDeskDetailsGuidSelector, partnerDeskDetailsProductOffersArgsSelector } from '../../../store/selectors';
import PartnerDeskProductOffersDataContainer from '../dataContainer';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PartnerDeskProductOffersAdapter = _ref => {
  let {
    guid
  } = _ref;
  const currentGuid = useSelector(partnerDeskDetailsGuidSelector);
  const args = useSelector(partnerDeskDetailsProductOffersArgsSelector);
  const handlers = useProductOffersContextHandlers();
  return useMemo(() => !currentGuid || guid !== currentGuid || !args ? null : _jsx(PartnerDeskProductOffersDataContainer, {
    guid: currentGuid,
    args: args,
    onChangePage: handlers.onChangeProductOffersPage
  }), [args, currentGuid, guid, handlers.onChangeProductOffersPage]);
};
export default PartnerDeskProductOffersAdapter;