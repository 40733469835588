import { useMemo } from 'react';
import { AppConfigurator } from 'system/appConfigurator';
import BusinessConfigContext from '../contexts/businessConfigContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BusinessConfigProvider = _ref => {
  let {
    children
  } = _ref;
  const state = useMemo(() => {
    const configurator = AppConfigurator.getInstance();
    return {
      corpEarningsSystemUrl: configurator.getCorpEarningsSystemUrl(),
      corpLinkProxyUrl: configurator.getCorpLinkProxyUrl(),
      supportInfo: configurator.getSupportInfo()
    };
  }, []);
  return _jsx(BusinessConfigContext.Provider, {
    value: state,
    children: children
  });
};
export default BusinessConfigProvider;