import { CorpOfferIcon } from '../../../../../../media/icons';
import BannerBadge from '../../../../../../components/common/badges/banner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CorpOfferBadge = _ref => {
  let {
    children
  } = _ref;
  return _jsx(BannerBadge, {
    icon: CorpOfferIcon,
    children: children
  });
};
export default CorpOfferBadge;