import { EPaginationBehaviour } from '../types';
export const pageableDataProcessChunk = props => {
  const {
    chunk,
    currentCachePage,
    currentCacheData,
    behaviour,
    upsertCallback
  } = props;
  if (!chunk?.data || chunk.data === currentCacheData) {
    return;
  }

  // срабатывает при вызове changePageSize в usePageableList например
  // при изменении pageSize мы сбрасываем страницу в начальное состояние
  // и ловим это тут
  // TODO(@Protopopov Ruslan): ну костыль, надо иначе
  if (chunk.page === 0) {
    upsertCallback(chunk);
    return;
  }
  let cacheToUpdate;

  // исплоьзуем запрос который инкрементит страницу
  if (behaviour === EPaginationBehaviour.IncrementPage) {
    // если page нового ответа совпадает с предыдущим, то делаем replace данных на новые
    if (currentCachePage === chunk?.page) {
      cacheToUpdate = {
        ...chunk,
        data: currentCacheData?.slice(0, currentCacheData.length - chunk.data.length).concat(chunk.data) ?? []
      };
    } else {
      const isIncrementedByOnePage = typeof currentCachePage === 'number' ? chunk.page - currentCachePage === 1 : false;
      const isDecrementedByAnyPage = typeof currentCachePage === 'number' ? Math.sign(chunk.page - currentCachePage) === -1 : false;

      // увеличили qs на 1 страницу
      if (isIncrementedByOnePage) {
        cacheToUpdate = {
          ...chunk,
          data: [...(currentCacheData ? currentCacheData : []), ...(chunk?.data ? chunk.data : [])]
        };
        // уменьшили qs на какое то кол-во страниц
      } else if (isDecrementedByAnyPage) {
        cacheToUpdate = {
          ...chunk,
          data: currentCacheData?.slice(0, (chunk.page - 1) * chunk.data.length).concat(chunk.data) ?? []
        };
        // инициализируем cache
      } else {
        cacheToUpdate = chunk;
      }
    }
    // исплоьзуем запрос который умножает размер страницы
  } else {
    cacheToUpdate = chunk;
  }

  //deepEqual используется так как строгое сравнение не поможет
  if (cacheToUpdate && !deepEqual(currentCacheData, cacheToUpdate.data)) {
    upsertCallback(cacheToUpdate);
  }
};
const deepEqual = function (x, y) {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;
    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }
    return true;
  } else return false;
};