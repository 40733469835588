import { useMediaQuery } from '@mui/material';
import CatalogButton from 'presentation/features/catalog/components/button';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useSelector } from 'react-redux';
import { headerCatalogDialogVisibleSelector } from '../store/selectors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CatalogButtonAdapter = () => {
  const handlers = useContextHandlers();
  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);
  const isXlDown = useMediaQuery(theme => theme.breakpoints.down('xl'));
  return _jsx(CatalogButton, {
    open: catalogVisible,
    showIconOnly: isXlDown,
    onClick: handlers.toggleCatalog
  });
};
export default CatalogButtonAdapter;