import { Typography } from '@mui/material';
import ClaimMessage from '../../../../components/claim/message';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CorpOfferClaimPendingWarnMessage = () => {
  return _jsx(ClaimMessage, {
    type: "warn",
    children: _jsx(Typography, {
      variant: "body2",
      color: "black",
      children: "\u0417\u0430\u044F\u0432\u043A\u0430 \u043E\u0431\u0440\u0430\u0431\u0430\u0442\u044B\u0432\u0430\u0435\u0442\u0441\u044F"
    })
  });
};