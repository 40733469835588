import { getDataCmsCollectionRoute } from '../../../data/entry';
import { CmsContainerShowAllLink } from '../../components/showAllLink';

/**
 * адаптер который добавляет в хедер кнопку "Посмотреть всё"
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const CmsCollectionShowAllAdapter = props => {
  const {
    id
  } = props;
  const link = getDataCmsCollectionRoute({
    id
  });
  return _jsx(CmsContainerShowAllLink, {
    to: link
  });
};