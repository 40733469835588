import { useCallback, useEffect, useState } from 'react';
import validateObject from './utils';
function useValidation(props) {
  const {
    object,
    rules
  } = props;
  const [result, setResult] = useState(null);
  const resetValidationResult = attribute => {
    if (!attribute) {
      setResult(null);
      return;
    }
    if (result === null || !result[attribute]) {
      return;
    }
    const temp = {
      ...result
    };
    delete temp[attribute];
    setResult(temp);
  };
  const validate = useCallback(() => {
    if (object === null) {
      setResult(null);
      return true;
    }
    const validateObjectResult = validateObject(object, rules);
    setResult(validateObjectResult.results);
    return validateObjectResult.isValid;
  }, [object, rules]);
  const applyExternalValidationResult = (attribute, externalResult) => {
    setResult({
      ...result,
      [attribute]: externalResult
    });
  };
  const isValid = result === null || Object.entries(result).filter(_ref => {
    let [, value] = _ref;
    return value.hasError;
  }).length === 0;
  useEffect(() => {
    if (!isValid) validate();
  }, [object, validate, isValid]);
  return {
    validationResult: result,
    isValid,
    validate,
    resetValidationResult,
    applyExternalValidationResult
  };
}
export default useValidation;