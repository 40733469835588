import OfferRelatedListLayout from 'presentation/layouts/offerRelatedList';
import { AspOfferRelatedListDataAdapter, AspOffersRelatedHeaderAdapter } from './adapters';
import { AspOfferRelatedListContext } from './context';
import useAspOfferRelatedListHandlers from './hooks/useAspRelatedOfferList';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = OfferRelatedListLayout;
const Header = _jsx(AspOffersRelatedHeaderAdapter, {});
const Loader = null;
const AspOfferRelatedListContainer = _ref => {
  let {
    source
  } = _ref;
  const handlers = useAspOfferRelatedListHandlers({
    source
  });
  return _jsx(AspOfferRelatedListContext.Provider, {
    value: handlers,
    children: _jsx(Layout, {
      header: Header,
      loader: Loader,
      children: _jsx(AspOfferRelatedListDataAdapter, {})
    })
  });
};
export default AspOfferRelatedListContainer;