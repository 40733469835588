import { CartIcon } from 'presentation/media/icons';
import { UserMenuItem } from '../../../components/menuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserMenuItemCart = _ref => {
  let {
    count,
    onClick
  } = _ref;
  return _jsx(UserMenuItem, {
    label: "\u041A\u043E\u0440\u0437\u0438\u043D\u0430",
    count: count,
    icon: _jsx(CartIcon, {
      color: "black"
    }),
    onClick: onClick
  });
};