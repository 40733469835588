import { StompClientProvider } from '@mp-npm/mp-stomp-client';
import HttpClient from 'data/network/http';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { useCallback } from 'react';
import { ENoticeStatus } from '../../../domain/model/enums';
import Notifier from '../../../system/notifier';
import useWsClient from './useWsClient';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WsClientProvider = _ref => {
  let {
    children,
    user
  } = _ref;
  const {
    token
  } = useAuthToken();
  const {
    apiWs,
    debug,
    isWsEnabled,
    wsEndpoint
  } = useWsClient();
  const onStompConnectionFailure = useCallback(message => {
    Notifier.getInstance().addNotice(ENoticeStatus.Error, `Ошибка подключения websocket: ${message ?? 'неизвестная ошибка'}`);
  }, []);
  return _jsx(StompClientProvider, {
    stop: !isWsEnabled || !user || !token,
    endpoint: wsEndpoint,
    wsPath: apiWs?.path,
    sockjsPath: apiWs?.sockjsPath,
    debug: debug,
    getToken: () => HttpClient.getInstance().getAuthService()?.token || token || '',
    onConnectionFailure: onStompConnectionFailure,
    children: children
  });
};