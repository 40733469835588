import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ActivationListItemExpireDate = _ref => {
  let {
    expireDate
  } = _ref;
  return _jsxs(Typography, {
    variant: "body2",
    children: ["\u0414\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0434\u043E ", moment(expireDate).local().format(EDateFormat.Human)]
  });
};
export default ActivationListItemExpireDate;