import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getAspOffersListRoute, getAspOffersSearchRoute } from '../../routes';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AspOfferBreadcrumbs = props => {
  const {
    offerCategories,
    dictionary
  } = props;
  const getBreadcrumbLink = category => getAspOffersSearchRoute({
    categoryId: category.id
  });
  const startCategory = {
    id: '',
    name: EAppCategories.AspOffers,
    parentId: null,
    link: getAspOffersListRoute()
  };
  return _jsx(OfferBreadcrumbs, {
    startCategory: startCategory,
    offerCategories: offerCategories ?? null,
    dictionary: dictionary,
    linkFactory: getBreadcrumbLink
  });
};
export default AspOfferBreadcrumbs;