import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useTradeOfferDetailsData from '../../hooks/useTradeOfferDetailsData';
import { useAuth } from '../../../../../auth/provider/useAuth';
import { OfferFavoriteCommonContainer } from '../../../../favorite';
import { EOfferType } from '../../../../../../../domain/model/enums';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SmartPhoneFixedBarAdapter = _ref => {
  let {
    id,
    guid
  } = _ref;
  const {
    isAuthenticated
  } = useAuth();
  const {
    onBack
  } = useContextHandlers();
  const {
    tradeOffer
  } = useTradeOfferDetailsData(id);
  if (!tradeOffer) {
    return null;
  }
  return _jsx(OfferSmartPhoneFixedBar, {
    text: tradeOffer.name,
    favorite: isAuthenticated && _jsx(OfferFavoriteCommonContainer, {
      guid: guid,
      offerId: id,
      offerType: EOfferType.Trade
    }),
    onBack: onBack
  });
};
export default SmartPhoneFixedBarAdapter;