import { EOfferStatus, ETradeOfferSortType } from 'domain/model/enums';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EAspOfferServicesTag } from './index';
const defaultAspOfferActiveStatuses = [EOfferStatus.Active];
const defaultAspOfferValidStatuses = [EOfferStatus.Active, EOfferStatus.Upcoming];
export const aspOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getAspOfferCategories: builder.query({
      transformResponse: response => response.data,
      query: _ref => {
        let {
          ids
        } = _ref;
        return apiDefinition.aspOffer.categories({
          ids
        });
      }
    }),
    getAspOfferCategoriesUsed: builder.query({
      transformResponse: response => response.data,
      query: _ref2 => {
        let {
          name,
          onlyLeafCategories
        } = _ref2;
        return apiDefinition.user.asp.categoriesUsed({
          query: name,
          statuses: defaultAspOfferValidStatuses,
          onlyLeafCategories
        });
      }
    }),
    getAspOfferCategoriesUsedTree: builder.query({
      transformResponse: response => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.asp.categoriesUsed({
          statuses: defaultAspOfferValidStatuses,
          onlyLeafCategories: false
        });
      }
    }),
    getAspOfferList: builder.query({
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref3 => {
        let {
          search,
          page,
          pageSize,
          sort = []
        } = _ref3;
        const {
          name,
          categories,
          statuses = defaultAspOfferActiveStatuses,
          partnerId
        } = search;
        return apiDefinition.user.asp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId
        });
      },
      providesTags: [EAspOfferServicesTag.List]
    }),
    getAspOffersCount: builder.query({
      transformResponse: response => {
        return response.data[0]?.count ?? 0;
      },
      query: _ref4 => {
        let {
          search
        } = _ref4;
        const {
          name,
          categories
        } = search;
        return apiDefinition.user.asp.count({
          query: name,
          statuses: defaultAspOfferValidStatuses,
          categories
        });
      }
    }),
    getAspOfferSearch: builder.query({
      keepUnusedDataFor: 0,
      transformResponse: response => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page
        };
      },
      query: _ref5 => {
        let {
          search,
          pageSize
        } = _ref5;
        const {
          name
        } = search;
        return apiDefinition.user.asp.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultAspOfferValidStatuses,
          sort: [ETradeOfferSortType.Default]
        });
      }
    }),
    getAspOfferDetails: builder.query({
      query: _ref6 => {
        let {
          id
        } = _ref6;
        return apiDefinition.user.asp.one({
          id
        });
      },
      transformResponse: response => response.data,
      providesTags: (result, error, args) => [{
        type: EAspOfferServicesTag.Details,
        id: args.id
      }, EAspOfferServicesTag.Details]
    })
  })
});
export const {
  useGetAspOfferListQuery,
  useGetAspOffersCountQuery,
  useGetAspOfferSearchQuery,
  useGetAspOfferCategoriesQuery,
  useGetAspOfferCategoriesUsedQuery,
  useGetAspOfferCategoriesUsedTreeQuery,
  useGetAspOfferDetailsQuery
} = aspOfferApi;