import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPButton } from 'presentation/theme/ui-kit/button';
import React from 'react';
import CardList from '../cardList';
import ContentLoader from '../loader';
import PageSizeSelect from '../selects/pageSize';
import Splitter from '../splitter';
import { LoaderWrapper, PageSizeSelectWrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const CardPageableList = props => {
  const {
    children,
    totalCount,
    isFetching,
    disableLoadMore,
    onLoadMore,
    pageSize,
    onChangePageSize,
    ...listProps
  } = props;
  const {
    x3
  } = useGridSpacing();
  const currentLength = React.Children.count(children);
  return _jsxs("div", {
    children: [_jsx(CardList, {
      ...listProps,
      children: children
    }), totalCount > currentLength && onLoadMore && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        variant: "horizontal",
        size: x3
      }), _jsxs(MPButton, {
        fullWidth: true,
        color: "white",
        disabled: disableLoadMore || isFetching,
        onClick: onLoadMore,
        children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451", isFetching && _jsx(ContentLoader, {
          size: 20
        })]
      })]
    }), props.onChangePageSize && _jsx(PageSizeSelect, {
      totalCount: totalCount,
      value: props.pageSize,
      onChange: props.onChangePageSize,
      children: content => _jsx(PageSizeSelectWrapper, {
        children: content
      })
    }), !currentLength && isFetching && _jsx(LoaderWrapper, {
      children: _jsx(ContentLoader, {})
    })]
  });
};